import { IBounds } from '@app/models/bounds.model';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { IPoint } from 'pixi.js';
import { ImageContainer } from './image-container';
import { InfoPanelPosition } from '@app/models/study-setting.model';

export class ImageInfoPanel {
  public infoHtmlRef: HTMLDivElement;
  private imageRelativePos;

  private readonly fontSize = {
    title: 18,
    fieldName: 16,
    fieldValue: 18
  };

  public wrapperHeight: number;
  public wrapperWidth: number;
  private position: InfoPanelPosition = InfoPanelPosition.Left;
  private isHalfSize: boolean = false;

  constructor(image: ImageContainer, position: InfoPanelPosition, isHalfSize: boolean) {
    this.position = position;
    this.isHalfSize = isHalfSize;
    this.infoHtmlRef = document.createElement('div');
    this.infoHtmlRef.classList.add('imageInfoPanel');
    this.infoHtmlRef.classList.add(this.position);
    getMatrixOverlayContainer().appendChild(this.infoHtmlRef);
    this.updateText(image);
  }

  delete() {
    const overlay = getMatrixOverlayContainer();
    if (overlay.contains(this.infoHtmlRef)) getMatrixOverlayContainer().removeChild(this.infoHtmlRef);
    delete this.infoHtmlRef;
  }

  updateText(image: ImageContainer) {
    let innerHTML = '<div class="imageInfoPanel-wrapper">';
    innerHTML += `<p class="title">${image.getDisplayName()}</p>`;
    const data = image.getData();

    if (data.hoverInfo === null) {
      innerHTML += `
          <p class="calculating-message">Calculating...</p>
        `;
    } else if (data.hoverInfo.length > 0) {
      data.hoverInfo.slice(0, 2).forEach(info => {
        innerHTML += `<div class='field'><p class="field-name">${info.fieldName}</p>`;
        info.fieldValues.forEach(val => {
          innerHTML += `<p class="field-value">${val}</p>`;
        });
        innerHTML += '</div>';
      });
    }

    innerHTML += '<p class="field-value"></p></div>';

    this.infoHtmlRef.innerHTML = innerHTML;

    this.updateWrapperbaseSizes();
  }

  updateWrapperbaseSizes() {
    const infoWrapper = this.infoHtmlRef.querySelector(`.imageInfoPanel-wrapper`) as HTMLDivElement;
    infoWrapper.style.height = 'fit-content';
    this.wrapperHeight = infoWrapper.offsetHeight;
    this.wrapperWidth = infoWrapper.offsetWidth;
    infoWrapper.style.height = '100%';
  }

  move(imageMatrixPosition: IBounds | IPoint) {
    switch (this.position) {
      case InfoPanelPosition.Left: {
        this.infoHtmlRef.style.left = `${
          this.imageRelativePos.x + imageMatrixPosition.x - this.infoHtmlRef.offsetWidth - 5
        }px`;
        this.infoHtmlRef.style.top = `${this.imageRelativePos.y + imageMatrixPosition.y}px`;
        break;
      }
      case InfoPanelPosition.Right: {
        this.infoHtmlRef.style.left = `${
          this.imageRelativePos.x +
          imageMatrixPosition.x +
          (this.isHalfSize ? this.infoHtmlRef.offsetWidth : this.infoHtmlRef.offsetWidth / 2) +
          5
        }px`;
        this.infoHtmlRef.style.top = `${this.imageRelativePos.y + imageMatrixPosition.y}px`;
        break;
      }
      case InfoPanelPosition.Bottom: {
        this.infoHtmlRef.style.left = `${this.imageRelativePos.x + imageMatrixPosition.x}px`;
        this.infoHtmlRef.style.top = `${
          this.imageRelativePos.y + imageMatrixPosition.y + this.infoHtmlRef.offsetWidth + 5
        }px`;
        break;
      }
    }
  }

  resize(imageMatrixBounds: IBounds, imageBounds: IBounds) {
    this.setImageRelativePos(imageMatrixBounds, imageBounds);
    this.infoHtmlRef.style.width = `${this.isHalfSize ? imageBounds.width : imageBounds.width * 2}px`;
    this.infoHtmlRef.style.height = `${imageBounds.height}px`;
  }

  setImageRelativePos(imageMatrixBounds: IBounds, imageBounds: IBounds) {
    this.imageRelativePos = {
      x: imageBounds.x - imageMatrixBounds.x,
      y: imageBounds.y - imageMatrixBounds.y
    };
  }

  public scaleWrapper(infoPanelWrapperMaxWidth: number, infoPanelWrapperMaxHeight: number): void {
    const widthScale = this.infoHtmlRef.offsetWidth / infoPanelWrapperMaxWidth;
    const heightScale = this.infoHtmlRef.offsetHeight / infoPanelWrapperMaxHeight;
    if (widthScale < 1 || heightScale < 1) {
      const scale = widthScale < heightScale ? widthScale : heightScale;
      this.setTextScale(scale);
    } else {
      this.setTextScale();
    }
  }

  private setTextScale(scale: number = 1): void {
    const title = this.infoHtmlRef.querySelector('.title') as HTMLParagraphElement;
    const field = this.infoHtmlRef.querySelectorAll('.field') as NodeListOf<HTMLParagraphElement>;
    const fieldName = this.infoHtmlRef.querySelectorAll('.field-name') as NodeListOf<HTMLParagraphElement>;
    const fieldValue = this.infoHtmlRef.querySelectorAll('.field-value') as NodeListOf<HTMLParagraphElement>;
    const calculatingMessage = this.infoHtmlRef.querySelectorAll(
      '.calculating-message'
    ) as NodeListOf<HTMLParagraphElement>;

    title.style.fontSize = `${this.fontSize.title * scale}px`;
    field.forEach(item => {
      item.style.marginTop = `${4 * scale}px`;
    });
    fieldName.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldName * scale}px`;
    });
    fieldValue.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldValue * scale}px`;
    });
    calculatingMessage.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldValue * scale}px`;
    });
  }
}
