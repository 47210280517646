import { Injectable } from '@angular/core';
import { dropShadowPadding } from '@app/shared/constants/viewport';
import { filters, Renderer, RenderTexture, Sprite, Texture } from 'pixi.js';

@Injectable({
  providedIn: 'root'
})
export class DropShadowService {
  private renderer: Renderer;

  private imageDropShadowTexture: RenderTexture;
  private imageSelectedDropShadowTexture: RenderTexture;

  private readonly shadowSize = 256;
  private readonly blurX = 6;
  private readonly blurY = 8;
  private readonly blurXOffset = 4;
  private readonly blurYOffset = 6;
  private readonly blurAlpha = 0.25;
  private readonly blurAlphaSelected = 0.5;
  private readonly shadowColor = 0x000000;
  private readonly shadowColorSelected = 0x04d7b9;

  private blurFilter: any; // BlurFilter
  private dropShadowRef: Sprite;

  constructor() {}

  public setRenderer(renderer: Renderer) {
    this.renderer = renderer;

    this.blurFilter = new filters.BlurFilter();
    this.blurFilter.blurX = this.blurX;
    this.blurFilter.blurY = this.blurY;
    this.dropShadowRef = new Sprite(Texture.WHITE);
    this.dropShadowRef.filters = [this.blurFilter];

    this.createImageDropShadowTextures();
  }

  private createImageDropShadowTextures() {
    this.dropShadowRef.width = this.shadowSize;
    this.dropShadowRef.height = this.shadowSize;
    this.dropShadowRef.position.set(dropShadowPadding + this.blurXOffset, dropShadowPadding + this.blurYOffset);

    this.imageDropShadowTexture = RenderTexture.create({
      width: this.shadowSize + 2 * dropShadowPadding,
      height: this.shadowSize + 2 * dropShadowPadding
    });

    this.dropShadowRef.alpha = this.blurAlpha;
    this.dropShadowRef.tint = this.shadowColor;

    this.renderer.render(this.dropShadowRef, { renderTexture: this.imageDropShadowTexture, clear: false });

    this.imageSelectedDropShadowTexture = RenderTexture.create({
      width: this.shadowSize + 2 * dropShadowPadding,
      height: this.shadowSize + 2 * dropShadowPadding
    });

    this.dropShadowRef.alpha = this.blurAlphaSelected;
    this.dropShadowRef.tint = this.shadowColorSelected;

    this.renderer.render(this.dropShadowRef, {
      renderTexture: this.imageSelectedDropShadowTexture,
      clear: false
    });
  }

  public getImageDropShadows(): { shadow: RenderTexture; selectedShadow: RenderTexture } {
    return {
      shadow: this.imageDropShadowTexture,
      selectedShadow: this.imageSelectedDropShadowTexture
    };
  }
}
