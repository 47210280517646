<div class="toolbox">
  <div class="toolbox-content" *ngIf="toolbox$ | async as toolbox">
    <button
      class="cursor"
      matTooltip="Select"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.cursor === 'hidden'"
      [disabled]="toolbox.cursor === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.Cursor }"
      (click)="onToolSelect(MatrixTool.Cursor)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-cursor"></svg-icon>
    </button>
    <button
      #image
      class="image"
      matTooltip="Swap images"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.image === 'hidden'"
      [disabled]="toolbox.image === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.Image }"
      (click)="onToolSelect(MatrixTool.Image, $event)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-image"></svg-icon>
    </button>
    <button
      class="magnify"
      matTooltip="Magnify"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.magnify === 'hidden'"
      [disabled]="toolbox.magnify === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.Magnify }"
      (click)="onToolSelect(MatrixTool.Magnify)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-magnify"></svg-icon>
    </button>
    <button
      class="highlighter"
      matTooltip="Highlight"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.highlighter === 'hidden'"
      [disabled]="toolbox.highlighter === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.Highlighter }"
      (click)="onToolSelect(MatrixTool.Highlighter)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-highlighter"></svg-icon>
      <div *ngIf="isHighlightFiltersActive" class="badge"></div>
    </button>
    <button
      class="hoverInfoConfig"
      matTooltip="Statistics"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.hoverInfoConfig === 'hidden'"
      [disabled]="toolbox.hoverInfoConfig === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.HoverInfoConfig }"
      (click)="onToolSelect(MatrixTool.HoverInfoConfig)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-quick-stats"></svg-icon>
    </button>
    <button
      class="frame"
      matTooltip="Create custom attribute"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.frame === 'hidden'"
      [disabled]="toolbox.frame === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.Frame }"
      (click)="onToolSelect(MatrixTool.Frame, $event)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-frame"></svg-icon>
    </button>
    <button
      class="presentationMode"
      matTooltip="Presentation mode"
      matTooltipPosition="right"
      matTooltipClass="right"
      matRipple
      [matRippleCentered]="true"
      [hidden]="toolbox.presentationMode === 'hidden'"
      [disabled]="toolbox.presentationMode === 'disabled'"
      [ngClass]="{ selected: selectedTool === MatrixTool.PresentationMode }"
      (click)="onToolSelect(MatrixTool.PresentationMode, $event)"
    >
      <svg-icon svgClass="icon" name="icon-leftPrimary-presentation-mode"></svg-icon>
    </button>
  </div>

  <div
    #quickStatsSubMenu
    class="quick-stats-sub-menu"
    [@openCloseWidthFadeScale]="showQuickStatsSubMenu ? 'openWithFlex' : 'closed'"
  >
    <app-quick-stats-sub-menu [isMenuOpen]="showQuickStatsSubMenu"></app-quick-stats-sub-menu>
  </div>
  <div
    #imageViewSubMenu
    [style.top.px]="imageSubMenuTop"
    class="image-view-selector"
    [@openCloseWidthFadeScale]="showImageViewSelector ? 'openWithFlex' : 'closed'"
  >
    <div *ngIf="images" class="content scrollable">
      <div
        class="image-button"
        matRipple
        [matRippleCentered]="true"
        *ngFor="let image of images"
        (click)="onImageViewSelect(image.view)"
      >
        <img [src]="image.url" alt="" [ngClass]="{ active: selectedImageView === image.view }" />
        <!-- app-loader
          *ngIf="selectedImageView === image.view"
          class="indicator"
          [subjects]="['resourceManager']"
          [isOverlay]="false"
        ></app-loader -->
      </div>
    </div>
  </div>
  <div #frameSubMenu class="frame-sub-menu" [@openCloseWidthFadeScale]="showFrameSubMenu ? 'openWithFlex' : 'closed'">
    <div class="bottom">
      <form class="frame-feature-name-selector-form">
        <mat-form-field class="frame-feature-name-selector-form-field" appearance="fill">
          <svg-icon svgClass="icon" name="icon-filter-arrow"></svg-icon>
          <mat-label>Feature Name</mat-label>
          <input
            type="text"
            placeholder="Name"
            aria-label="Feature Name"
            matInput
            #autoCompleteInput
            [formControl]="frameFeatureNameForm"
            [matAutocomplete]="frameFeatureNameSelector"
            required
            (keydown.enter)="onFeatureFrameNameInput()"
          />
          <mat-autocomplete #frameFeatureNameSelector="matAutocomplete">
            <mat-option
              *ngFor="let name of frameFeatureFilteredNames$ | async"
              [value]="name"
              (onSelectionChange)="onFeatureFrameNameSelect(name, $event)"
            >
              {{ name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>

<div #highlighterFilterBoxSubMenu class="highlight-sub-menu" [class.active]="showHighlighterFilterBoxSubMenu">
  <app-highlighter-filter-box
    [filters]="filtersList"
    [settings]="matrixSettings?.highlighterComponent"
    [showFilterBox]="showHighlighterFilterBoxSubMenu"
    (filterBoxToggled)="setHighlighterFilterBoxToggled($event)"
  ></app-highlighter-filter-box>
</div>
