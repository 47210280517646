import * as XLSX from 'xlsx';
/* load the codepage support library for extended support with older formats  */
// @ts-ignore
// eslint-disable-next-line import/extensions
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

export type CodePageType = {
  codepage: number;
  encoding: string;
  displayName: string;
};

export const DefaultEncoding: CodePageType = {
  codepage: 65001,
  encoding: 'utf-8',
  displayName: 'Unicode (UTF-8)'
};

// --------------------------------------------------------------------------------------------------------------------
// CodePage + Encodings
// see: https://docs.microsoft.com/ru-ru/dotnet/api/system.text.encodinginfo.getencoding?view=net-6.0
// --------------------------------------------------------------------------------------------------------------------

// List of known codepages (See also SupportedCodePageList)
export const CodePageList: Array<CodePageType> = [
  { codepage: 37, encoding: 'IBM037', displayName: 'IBM EBCDIC (US-Canada)' },
  { codepage: 437, encoding: 'IBM437', displayName: 'OEM United States' },
  { codepage: 500, encoding: 'IBM500', displayName: 'IBM EBCDIC (International)' },
  { codepage: 708, encoding: 'ASMO-708', displayName: 'Arabic (ASMO 708)' },
  { codepage: 720, encoding: 'DOS-720', displayName: 'Arabic (DOS)' },
  { codepage: 737, encoding: 'ibm737', displayName: 'Greek (DOS)' },
  { codepage: 775, encoding: 'ibm775', displayName: 'Baltic (DOS)' },
  { codepage: 850, encoding: 'ibm850', displayName: 'Western European (DOS)' },
  { codepage: 852, encoding: 'ibm852', displayName: 'Central European (DOS)' },
  { codepage: 855, encoding: 'IBM855', displayName: 'OEM Cyrillic' },
  { codepage: 857, encoding: 'ibm857', displayName: 'Turkish (DOS)' },
  { codepage: 858, encoding: 'IBM00858', displayName: 'OEM Multilingual Latin I' },
  { codepage: 860, encoding: 'IBM860', displayName: 'Portuguese (DOS)' },
  { codepage: 861, encoding: 'ibm861', displayName: 'Icelandic (DOS)' },
  { codepage: 862, encoding: 'DOS-862', displayName: 'Hebrew (DOS)' },
  { codepage: 863, encoding: 'IBM863', displayName: 'French Canadian (DOS)' },
  { codepage: 864, encoding: 'IBM864', displayName: 'Arabic (864)' },
  { codepage: 865, encoding: 'IBM865', displayName: 'Nordic (DOS)' },
  { codepage: 866, encoding: 'cp866', displayName: 'Cyrillic (DOS)' },
  { codepage: 869, encoding: 'ibm869', displayName: 'Greek, Modern (DOS)' },
  { codepage: 870, encoding: 'IBM870', displayName: 'IBM EBCDIC (Multilingual Latin-2)' },
  { codepage: 874, encoding: 'windows-874', displayName: 'Thai (Windows)' },
  { codepage: 875, encoding: 'cp875', displayName: 'IBM EBCDIC (Greek Modern)' },
  { codepage: 932, encoding: 'shift_jis', displayName: 'Japanese (Shift-JIS)' },
  { codepage: 936, encoding: 'gb2312', displayName: 'Chinese Simplified (GB2312)' },
  { codepage: 949, encoding: 'ks_c_5601-1987', displayName: 'Korean' },
  { codepage: 950, encoding: 'big5', displayName: 'Chinese Traditional (Big5)' },
  { codepage: 1026, encoding: 'IBM1026', displayName: 'IBM EBCDIC (Turkish Latin-5)' },
  { codepage: 1047, encoding: 'IBM01047', displayName: 'IBM Latin-1' },
  { codepage: 1140, encoding: 'IBM01140', displayName: 'IBM EBCDIC (US-Canada-Euro)' },
  { codepage: 1141, encoding: 'IBM01141', displayName: 'IBM EBCDIC (Germany-Euro)' },
  { codepage: 1142, encoding: 'IBM01142', displayName: 'IBM EBCDIC (Denmark-Norway-Euro)' },
  { codepage: 1143, encoding: 'IBM01143', displayName: 'IBM EBCDIC (Finland-Sweden-Euro)' },
  { codepage: 1144, encoding: 'IBM01144', displayName: 'IBM EBCDIC (Italy-Euro)' },
  { codepage: 1145, encoding: 'IBM01145', displayName: 'IBM EBCDIC (Spain-Euro)' },
  { codepage: 1146, encoding: 'IBM01146', displayName: 'IBM EBCDIC (UK-Euro)' },
  { codepage: 1147, encoding: 'IBM01147', displayName: 'IBM EBCDIC (France-Euro)' },
  { codepage: 1148, encoding: 'IBM01148', displayName: 'IBM EBCDIC (International-Euro)' },
  { codepage: 1149, encoding: 'IBM01149', displayName: 'IBM EBCDIC (Icelandic-Euro)' },
  { codepage: 1200, encoding: 'utf-16', displayName: 'Unicode' },
  { codepage: 1201, encoding: 'utf-16BE', displayName: 'Unicode (Big-Endian)' },
  { codepage: 1250, encoding: 'windows-1250', displayName: 'Central European (Windows)' },
  { codepage: 1251, encoding: 'windows-1251', displayName: 'Cyrillic (Windows)' },
  { codepage: 1252, encoding: 'Windows-1252', displayName: 'Western European (Windows)' },
  { codepage: 1253, encoding: 'windows-1253', displayName: 'Greek (Windows)' },
  { codepage: 1254, encoding: 'windows-1254', displayName: 'Turkish (Windows)' },
  { codepage: 1255, encoding: 'windows-1255', displayName: 'Hebrew (Windows)' },
  { codepage: 1256, encoding: 'windows-1256', displayName: 'Arabic (Windows)' },
  { codepage: 1257, encoding: 'windows-1257', displayName: 'Baltic (Windows)' },
  { codepage: 1258, encoding: 'windows-1258', displayName: 'Vietencodingse (Windows)' },
  { codepage: 1361, encoding: 'Johab', displayName: 'Korean (Johab)' },
  { codepage: 10000, encoding: 'macintosh', displayName: 'Western European (Mac)' },
  { codepage: 10001, encoding: 'x-mac-japanese', displayName: 'Japanese (Mac)' },
  { codepage: 10002, encoding: 'x-mac-chinesetrad', displayName: 'Chinese Traditional (Mac)' },
  { codepage: 10003, encoding: 'x-mac-korean', displayName: 'Korean (Mac)' },
  { codepage: 10004, encoding: 'x-mac-arabic', displayName: 'Arabic (Mac)' },
  { codepage: 10005, encoding: 'x-mac-hebrew', displayName: 'Hebrew (Mac)' },
  { codepage: 10006, encoding: 'x-mac-greek', displayName: 'Greek (Mac)' },
  { codepage: 10007, encoding: 'x-mac-cyrillic', displayName: 'Cyrillic (Mac)' },
  { codepage: 10008, encoding: 'x-mac-chinesesimp', displayName: 'Chinese Simplified (Mac)' },
  { codepage: 10010, encoding: 'x-mac-romanian', displayName: 'Romanian (Mac)' },
  { codepage: 10017, encoding: 'x-mac-ukrainian', displayName: 'Ukrainian (Mac)' },
  { codepage: 10021, encoding: 'x-mac-thai', displayName: 'Thai (Mac)' },
  { codepage: 10029, encoding: 'x-mac-ce', displayName: 'Central European (Mac)' },
  { codepage: 10079, encoding: 'x-mac-icelandic', displayName: 'Icelandic (Mac)' },
  { codepage: 10081, encoding: 'x-mac-turkish', displayName: 'Turkish (Mac)' },
  { codepage: 10082, encoding: 'x-mac-croatian', displayName: 'Croatian (Mac)' },
  { codepage: 12000, encoding: 'utf-32', displayName: 'Unicode (UTF-32)' },
  { codepage: 12001, encoding: 'utf-32BE', displayName: 'Unicode (UTF-32 Big-Endian)' },
  { codepage: 20000, encoding: 'x-Chinese-CNS', displayName: 'Chinese Traditional (CNS)' },
  { codepage: 20001, encoding: 'x-cp20001', displayName: 'TCA Taiwan' },
  { codepage: 20002, encoding: 'x-Chinese-Eten', displayName: 'Chinese Traditional (Eten)' },
  { codepage: 20003, encoding: 'x-cp20003', displayName: 'IBM5550 Taiwan' },
  { codepage: 20004, encoding: 'x-cp20004', displayName: 'TeleText Taiwan' },
  { codepage: 20005, encoding: 'x-cp20005', displayName: 'Wang Taiwan' },
  { codepage: 20105, encoding: 'x-IA5', displayName: 'Western European (IA5)' },
  { codepage: 20106, encoding: 'x-IA5-German', displayName: 'German (IA5)' },
  { codepage: 20107, encoding: 'x-IA5-Swedish', displayName: 'Swedish (IA5)' },
  { codepage: 20108, encoding: 'x-IA5-Norwegian', displayName: 'Norwegian (IA5)' },
  { codepage: 20127, encoding: 'us-ascii', displayName: 'US-ASCII' },
  { codepage: 20261, encoding: 'x-cp20261', displayName: 'T.61' },
  { codepage: 20269, encoding: 'x-cp20269', displayName: 'ISO-6937' },
  { codepage: 20273, encoding: 'IBM273', displayName: 'IBM EBCDIC (Germany)' },
  { codepage: 20277, encoding: 'IBM277', displayName: 'IBM EBCDIC (Denmark-Norway)' },
  { codepage: 20278, encoding: 'IBM278', displayName: 'IBM EBCDIC (Finland-Sweden)' },
  { codepage: 20280, encoding: 'IBM280', displayName: 'IBM EBCDIC (Italy)' },
  { codepage: 20284, encoding: 'IBM284', displayName: 'IBM EBCDIC (Spain)' },
  { codepage: 20285, encoding: 'IBM285', displayName: 'IBM EBCDIC (UK)' },
  { codepage: 20290, encoding: 'IBM290', displayName: 'IBM EBCDIC (Japanese katakana)' },
  { codepage: 20297, encoding: 'IBM297', displayName: 'IBM EBCDIC (France)' },
  { codepage: 20420, encoding: 'IBM420', displayName: 'IBM EBCDIC (Arabic)' },
  { codepage: 20423, encoding: 'IBM423', displayName: 'IBM EBCDIC (Greek)' },
  { codepage: 20424, encoding: 'IBM424', displayName: 'IBM EBCDIC (Hebrew)' },
  { codepage: 20833, encoding: 'x-EBCDIC-KoreanExtended', displayName: 'IBM EBCDIC (Korean Extended)' },
  { codepage: 20838, encoding: 'IBM-Thai', displayName: 'IBM EBCDIC (Thai)' },
  { codepage: 20866, encoding: 'koi8-r', displayName: 'Cyrillic (KOI8-R)' },
  { codepage: 20871, encoding: 'IBM871', displayName: 'IBM EBCDIC (Icelandic)' },
  { codepage: 20880, encoding: 'IBM880', displayName: 'IBM EBCDIC (Cyrillic Russian)' },
  { codepage: 20905, encoding: 'IBM905', displayName: 'IBM EBCDIC (Turkish)' },
  { codepage: 20924, encoding: 'IBM00924', displayName: 'IBM Latin-1' },
  { codepage: 20932, encoding: 'EUC-JP', displayName: 'Japanese (JIS 0208-1990 and 0212-1990)' },
  { codepage: 20936, encoding: 'x-cp20936', displayName: 'Chinese Simplified (GB2312-80)' },
  { codepage: 20949, encoding: 'x-cp20949', displayName: 'Korean Wansung' },
  { codepage: 21025, encoding: 'cp1025', displayName: 'IBM EBCDIC (Cyrillic Serbian-Bulgarian)' },
  { codepage: 21866, encoding: 'koi8-u', displayName: 'Cyrillic (KOI8-U)' },
  { codepage: 28591, encoding: 'iso-8859-1', displayName: 'Western European (ISO)' },
  { codepage: 28592, encoding: 'iso-8859-2', displayName: 'Central European (ISO)' },
  { codepage: 28593, encoding: 'iso-8859-3', displayName: 'Latin 3 (ISO)' },
  { codepage: 28594, encoding: 'iso-8859-4', displayName: 'Baltic (ISO)' },
  { codepage: 28595, encoding: 'iso-8859-5', displayName: 'Cyrillic (ISO)' },
  { codepage: 28596, encoding: 'iso-8859-6', displayName: 'Arabic (ISO)' },
  { codepage: 28597, encoding: 'iso-8859-7', displayName: 'Greek (ISO)' },
  { codepage: 28598, encoding: 'iso-8859-8', displayName: 'Hebrew (ISO-Visual)' },
  { codepage: 28599, encoding: 'iso-8859-9', displayName: 'Turkish (ISO)' },
  { codepage: 28603, encoding: 'iso-8859-13', displayName: 'Estonian (ISO)' },
  { codepage: 28605, encoding: 'iso-8859-15', displayName: 'Latin 9 (ISO)' },
  { codepage: 29001, encoding: 'x-Europa', displayName: 'Europa' },
  { codepage: 38598, encoding: 'iso-8859-8-i', displayName: 'Hebrew (ISO-Logical)' },
  { codepage: 50220, encoding: 'iso-2022-jp', displayName: 'Japanese (JIS)' },
  { codepage: 50221, encoding: 'csISO2022JP', displayName: 'Japanese (JIS-Allow 1 byte Kana)' },
  { codepage: 50222, encoding: 'iso-2022-jp', displayName: 'Japanese (JIS-Allow 1 byte Kana - SO/SI)' },
  { codepage: 50225, encoding: 'iso-2022-kr', displayName: 'Korean (ISO)' },
  { codepage: 50227, encoding: 'x-cp50227', displayName: 'Chinese Simplified (ISO-2022)' },
  { codepage: 51932, encoding: 'euc-jp', displayName: 'Japanese (EUC)' },
  { codepage: 51936, encoding: 'EUC-CN', displayName: 'Chinese Simplified (EUC)' },
  { codepage: 51949, encoding: 'euc-kr', displayName: 'Korean (EUC)' },
  { codepage: 52936, encoding: 'hz-gb-2312', displayName: 'Chinese Simplified (HZ)' },
  { codepage: 54936, encoding: 'GB18030', displayName: 'Chinese Simplified (GB18030)' },
  { codepage: 57002, encoding: 'x-iscii-de', displayName: 'ISCII Devanagari' },
  { codepage: 57003, encoding: 'x-iscii-be', displayName: 'ISCII Bengali' },
  { codepage: 57004, encoding: 'x-iscii-ta', displayName: 'ISCII Tamil' },
  { codepage: 57005, encoding: 'x-iscii-te', displayName: 'ISCII Telugu' },
  { codepage: 57006, encoding: 'x-iscii-as', displayName: 'ISCII Assamese' },
  { codepage: 57007, encoding: 'x-iscii-or', displayName: 'ISCII Oriya' },
  { codepage: 57008, encoding: 'x-iscii-ka', displayName: 'ISCII Kannada' },
  { codepage: 57009, encoding: 'x-iscii-ma', displayName: 'ISCII Malayalam' },
  { codepage: 57010, encoding: 'x-iscii-gu', displayName: 'ISCII Gujarati' },
  { codepage: 57011, encoding: 'x-iscii-pa', displayName: 'ISCII Punjabi' },
  { codepage: 65000, encoding: 'utf-7', displayName: 'Unicode (UTF-7)' },
  { codepage: 65001, encoding: 'utf-8', displayName: 'Unicode (UTF-8)' }
];

export function encodingToCodepage(encoding: string): number {
  return findCodePageByEncoding(encoding)?.codepage;
}

export function findCodePageByEncoding(encoding: string): CodePageType {
  return CodePageList.find(item => item.encoding.toLowerCase() === encoding.toLowerCase());
}

// ---------------------------------------------------------------------------------
// Determine which codepage/encoding is supported by XLSX.cptable and TextEncoder
// ---------------------------------------------------------------------------------
export const SupportedCodePageList = []; // supported by XLSX.cptable
export const UnsupportedCodePageList = []; // unsupported by XLSX.cptable

export const SupportedCodePageList_forTextDecoder = []; // supported by TextEncoder

CodePageList.forEach(item => {
  const text = 'Kick Turn II Pant'; // text to test encodings
  try {
    cptable.utils.decode(item.codepage, cptable.utils.encode(item.codepage, text));
    try {
      new TextDecoder(item.encoding.toLowerCase()).decode(new TextEncoder().encode(text));
      SupportedCodePageList_forTextDecoder.push(item);
    } catch {
      // do nothing
    }
    SupportedCodePageList.push(item);
  } catch {
    UnsupportedCodePageList.push(item);
    try {
      new TextDecoder(item.encoding.toLowerCase()).decode(new TextEncoder().encode(text));
      SupportedCodePageList_forTextDecoder.push(item);
    } catch {
      // do nothing
    }
  }
});

//----------------------------------------------
// User's Default Encoding
//----------------------------------------------
export function setUserDefaultEncoding(encoding: CodePageType | string) {
  if (typeof encoding === 'string') {
    encoding = findCodePageByEncoding(encoding) || DefaultEncoding;
  }
  localStorage.setItem('default-encoding', JSON.stringify(encoding));
}

export function getUserDefaultEncoding(): CodePageType {
  const saved = localStorage.getItem('default-encoding');
  return saved ? JSON.parse(saved) : DefaultEncoding;
}
