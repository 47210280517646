import { IHoverInfoField, IImageData } from '@app/models/image.model';
import {
  HoverInfoAggregationTypeEnum,
  IHoverInfoFieldModel,
  IMetricCustomFormatting
} from '@app/models/study-setting.model';
import { customFormatBigNumber } from './string';
import { FiltersTypeEnum, IHoverInfoFilterModel } from '../models/filters.model';
import { getCalcResult, getWeightOperation, ICalcArgument } from '../models/study-setting.model';
import { IValuesMap } from '../models/image.model';

export interface IImageAggregatedHoverInfoResult {
  [id: string]: IImageData;
}

function collectAllAttributes(config: IHoverInfoFieldModel): any {
  let children = null;
  if (config.children?.length > 0) {
    children = {};
    config.children.forEach(child => {
      children = {
        ...children,
        ...collectAllAttributes(child)
      };
    });
  } else if (!config.isMeasure && config.type !== FiltersTypeEnum.Calculated) {
    children = {
      [config.name]: new Set()
    };
  }
  return {
    [config.name]: children || (config.type === FiltersTypeEnum.Calculated ? {} : config.isMeasure ? [] : new Set())
  };
}

// TODO: move to the Firebase Functions API - to avoid support identical code in two places
export function getResultForCalcArguments(argumentsToCalc: Array<ICalcArgument>): number {
  const calcArguments = [...argumentsToCalc];
  if (calcArguments.length === 0) {
    return NaN;
  }
  if (calcArguments.length === 1) {
    return calcArguments[0].value;
  }
  if (calcArguments.length > 1) {
    for (let index = 0; index < calcArguments.length - 1; index++) {
      if (!calcArguments[index].argument || !calcArguments[index].argument.calcOperation) {
        return NaN; // one of operations doesn't exist, so expression cannot be calculated
      }
    }
  }
  while (calcArguments.length > 1) {
    const calcOperations = calcArguments.map(item => getWeightOperation(item.argument?.calcOperation));
    calcOperations.pop(); // remove last operation, because it not uses in calculation
    const maxOperationWeight = getMax(calcOperations);
    let argument1 = null;
    for (let index = 0; index < calcArguments.length; index++) {
      const argument2 = calcArguments[index];
      if (argument1 && getWeightOperation(argument1.argument.calcOperation) === maxOperationWeight) {
        argument1 = {
          value: getCalcResult(argument1.value, argument2.value, argument1.argument.calcOperation),
          argument: {
            calcOperation: argument2.argument.calcOperation
          }
        };
        calcArguments.splice(index, 1); // remove argument2 from array of Arguments
        calcArguments[index - 1] = argument1;
        break;
      } else {
        argument1 = argument2;
      }
    }
  }
  return calcArguments[0]?.value;
}

function getAggregationTypeText(aggregationType: HoverInfoAggregationTypeEnum): string {
  switch (aggregationType) {
    case HoverInfoAggregationTypeEnum.Average:
      return 'Average';
    case HoverInfoAggregationTypeEnum.Median:
      return 'Median';
    case HoverInfoAggregationTypeEnum.MinMax:
      return 'Min/Max';
    case HoverInfoAggregationTypeEnum.Sum:
      return 'Sum';
  }
  return '';
}

function getAggregatedFieldValue(
  aggregationType: HoverInfoAggregationTypeEnum,
  values: number[],
  customFormatting?: IMetricCustomFormatting,
  isHideAggregationType: boolean = false
): string {
  values = removeNaN(stringsToNumbers(values));
  let fieldValue = '';
  switch (aggregationType) {
    case HoverInfoAggregationTypeEnum.Average:
      fieldValue = isHideAggregationType
        ? `${customFormatBigNumber(getAverage(values), customFormatting)}`
        : `Average: ${customFormatBigNumber(getAverage(values), customFormatting)}`;
      break;
    case HoverInfoAggregationTypeEnum.Median:
      fieldValue = isHideAggregationType
        ? `${customFormatBigNumber(getMedian(values), customFormatting)}`
        : `Median: ${customFormatBigNumber(getMedian(values), customFormatting)}`;
      break;
    case HoverInfoAggregationTypeEnum.MinMax:
      fieldValue = isHideAggregationType
        ? `${customFormatBigNumber(getMin(values), customFormatting)}, ${customFormatBigNumber(
            getMax(values),
            customFormatting
          )}`
        : `Min: ${customFormatBigNumber(getMin(values), customFormatting)}, Max: ${customFormatBigNumber(
            getMax(values),
            customFormatting
          )}`;
      break;
    case HoverInfoAggregationTypeEnum.Sum:
      fieldValue = isHideAggregationType
        ? `${customFormatBigNumber(getSum(values), customFormatting)}`
        : `Sum: ${customFormatBigNumber(getSum(values), customFormatting)}`;
  }
  return fieldValue;
}

export function getAggregatedFieldValueResults(
  aggregationType: HoverInfoAggregationTypeEnum,
  values: number[]
): number[] {
  values = stringsToNumbers(values);
  switch (aggregationType) {
    case HoverInfoAggregationTypeEnum.Average:
      return [getAverage(values)];
    case HoverInfoAggregationTypeEnum.Median:
      return [getMedian(values)];
    case HoverInfoAggregationTypeEnum.MinMax:
      return [getMin(values), getMax(values)];
    case HoverInfoAggregationTypeEnum.Sum:
      return [getSum(values)];
  }
  return [];
}

// TODO: add to Firebase Functions?
function removeNaN(values: number[]) {
  return values.filter(val => !Number.isNaN(val));
}

export function stringsToNumbers(values: string[] | number[]): number[] {
  return values.map(val => stringToNumber(val));
}

export function stringToNumber(val: string | number): number {
  return (val.toString().match(/\(\$?\d+\.?\d+\)/) ? -1 : 1) * parseFloat(val.toString().replaceAll(/[^\d.-]/g, ''));
}

// TODO: update in Firebase Functions?
export function parseNumberValue(value: string | number | null): number {
  if (typeof value === 'number') {
    return value;
  }
  if (value !== null && value !== undefined) {
    const asNumber = Number.parseFloat(value);
    if (!Number.isNaN(asNumber)) {
      return asNumber;
    }
    const asPercent = Number.parseFloat(value.replace(/%/g, ''));
    if (!Number.isNaN(asPercent)) {
      return asPercent;
    }
    if (typeof value === 'string') {
      value = value[0] === '-' ? `-${value.slice(2)}` : value.slice(1);
    }
    const asCurrency = Number.parseFloat(value.replace(/,/g, ''));
    if (!Number.isNaN(asCurrency)) {
      return asCurrency;
    }
  }
  return 0; // convert null, undefined, NaN or '' to the zero
}

export function getAverage(nums: number[]): number {
  return roundToFourDecimal(getSum(nums) / nums.length || 0);
}

export function getSum(nums: number[]): number {
  return roundToFourDecimal(nums.reduce((prev, curr) => prev + curr, 0));
}

export function getMin(nums: number[]): number {
  return roundToFourDecimal(Math.min(...nums));
}

export function getMax(nums: number[]): number {
  return roundToFourDecimal(Math.max(...nums));
}

export function getMedian(nums: number[]): number {
  const sortedArray = [...nums].sort((a, b) => a - b);
  const { length } = sortedArray;
  return roundToFourDecimal(
    length % 2 === 1 ? sortedArray[Math.floor(length / 2)] : (sortedArray[length / 2 - 1] + sortedArray[length / 2]) / 2
  );
}

function roundToFourDecimal(num) {
  return parseFloat(num.toFixed(2));
}
