import { FiltersTypeEnum, IFiltersModel, IHoverInfoFilterModel } from './filters.model';
import { shortUid } from '@app/shared/utils/short-uid';

export interface IStudySettingsStateModel {
  hoverInfoConfig: Array<IHoverInfoFieldModel>;
  rankInfoPanelConfig?: IRankInfoPanelConfig;
  groupOrientation?: GroupOrientation;
  metricsCustomFormattings?: IMetricsCustomFormattings;
}

export interface IHoverInfoFieldModel {
  name: string;
  type: FiltersTypeEnum;
  isMeasure: boolean;
  aggregationType?: HoverInfoAggregationTypeEnum;

  // new fields
  calcOperation?: CalcOperationType;
  calcArguments?: Array<IHoverInfoFieldModel>; // can have (theoretically, N-deep levels of calcGroups)
  isCalcGroup?: boolean; // if null or false, calcArguments has only one deep level (current)
  calcId: string;
  children?: Array<IHoverInfoFieldModel>; // can have only one deep level of children
  isDistinctCount: boolean;

  // Support Rank absolute mode: when need to get Custom Calc values for sortByValue, but don't show in the HoverInfo
  isDontShowHoverInfo?: boolean; // Aggregate data, but don't show in hover info
}

export interface IMetricsCustomFormattings {
  [key: string]: IMetricCustomFormatting;
}

export interface IMetricCustomFormatting {
  prefix: string;
  suffix: string;
  multiplier: number;
  fractionDigit: number;
}

export interface IRankInfoPanelConfig {
  showState: string; // TODO: add enum
  // !!! Need to check this var before accessing it, and default to 'left' for old studies
  position: InfoPanelPosition;
}

export enum InfoPanelPosition {
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom'
}

export interface ICalcArgument {
  value: number;
  argument: IHoverInfoFilterModel;
  valueText?: string;
}

export enum GroupOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum DockingSide {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}

export enum HoverInfoAggregationTypeEnum {
  Average = 'Avg',
  Sum = 'Sum',
  Median = 'Median',
  MinMax = 'Min/Max' // ??? should be split for Min and Max
}

export type CalcOperationType = '+' | '-' | '*' | '/';

export function getCalcResult(value1: number, value2: number, operand: CalcOperationType): number {
  switch (operand) {
    case '+':
      return value1 + value2;
    case '-':
      return value1 - value2;
    case '*':
      return value1 * value2;
    case '/':
      return value1 / value2;
  }
  return NaN;
}

export function getWeightOperation(operation: CalcOperationType): number {
  switch (operation) {
    case '+':
    case '-':
      return 1;
    case '*':
    case '/':
      return 2;
  }
  return 0;
}

export function compareWeightOperations(arg1: CalcOperationType, arg2: CalcOperationType): number {
  const result = getWeightOperation(arg1) - getWeightOperation(arg2);
  if (result > 0) return 1;
  if (result < 0) return -1;
  return 0;
}

/**
 * Convert Hover Info Filter (format, which uses to display values in the HoverInfo panel and HoverInfoConfig dialog)
 * to the format, which uses by Store
 * @param value hover info filter
 */
export function hoverInfoFilter2hoverInfoField(value: IHoverInfoFilterModel): IHoverInfoFieldModel {
  let children = null;
  if (value.children) {
    children = [];
    value.children.forEach(item => {
      const child = hoverInfoFilter2hoverInfoField(item);
      children.push(child);
    });
  }
  return {
    name: value.name,
    type: value.type,
    isMeasure: value.isMeasure || null,
    aggregationType: value.aggregationType || null,
    isDontShowHoverInfo: value.isDontShowHoverInfo || null,
    // save additional data for Custom Calc Fields
    calcId: value.calcId || null,
    isCalcGroup: value.isCalcGroup || null,
    calcOperation: value.calcOperation || null,
    isDistinctCount: value.isDistinctCount || false,
    calcArguments:
      value.calcArguments?.map(argument => {
        if (argument.isCalcGroup) {
          return hoverInfoFilter2hoverInfoField(argument);
        }
        return {
          name: argument.name,
          type: argument.type,
          isMeasure: argument.isMeasure || null,
          isDistinctCount: argument.isDistinctCount || false,
          aggregationType: argument.aggregationType || null,
          calcId: argument.calcId || null,
          isCalcGroup: argument.isCalcGroup || null,
          calcOperation: argument.calcOperation || null,
          calcArguments: null,
          children: null // second level cannot have children
        } as IHoverInfoFieldModel;
      }) || null,
    children
  } as IHoverInfoFieldModel;
}

/**
 * Convert Store format of HoverInfoField to HoverInfoFilter, which uses to edit them in HoverInfoConfig dialog
 * Also function check is HoverInfoField really present in current list of Filters.
 * Filters collected from Business/Image data, so HoverInfoField should be present in filters.
 * Confirmed Fields will be returned as IHoverInfoFilterModel
 * @param value hover info field (config), stored in Store format
 * @param filtersList array of known filters
 */
export function hoverInfoField2hoverInfoFilter(
  value: IHoverInfoFieldModel,
  filtersList: Array<IFiltersModel>
): IHoverInfoFilterModel {
  if (!value || !filtersList) {
    return null;
  }
  let infoField = null;
  if (value.type === FiltersTypeEnum.Calculated) {
    infoField = hoverInfoCalcField2hoverInfoCalcFilter(value, filtersList);
  } else {
    // other types
    const foundFilter = filtersList.find(item => item.name === value.name);
    if (!foundFilter) return null;

    let confirmedChildren = null;
    if (value.children) {
      confirmedChildren = [];
      value.children.forEach(child => {
        const foundChildFilter = hoverInfoField2hoverInfoFilter(child, filtersList);
        if (foundChildFilter) {
          confirmedChildren.push(foundChildFilter);
        }
      });
    }

    infoField = {
      ...foundFilter,
      type: foundFilter.type || '',
      aggregationType: value.aggregationType,
      isDistinctCount: value.isDistinctCount || false,
      children: confirmedChildren
    };
  }
  if (value.isDontShowHoverInfo) {
    infoField.isDontShowHoverInfo = value.isDontShowHoverInfo;
  }
  return infoField;
}

function hoverInfoCalcField2hoverInfoCalcFilter(
  value: IHoverInfoFieldModel,
  filtersList: Array<IFiltersModel>
): IHoverInfoFilterModel {
  const calcArguments: Array<IHoverInfoFilterModel> = [];
  value.calcArguments?.forEach(argument => {
    if (argument.isCalcGroup) {
      calcArguments.push(hoverInfoCalcField2hoverInfoCalcFilter(argument, filtersList));
    } else {
      const foundFilter = filtersList.find(item => item.name === argument.name);
      if (foundFilter) {
        calcArguments.push({
          ...foundFilter,
          type: argument.type,
          isMeasure: !argument.isDistinctCount,
          isDistinctCount: argument.isDistinctCount || false,
          aggregationType: argument.aggregationType,
          calcOperation: argument.calcOperation,
          calcArguments: null,
          calcId: argument.calcId,
          children: []
        });
      }
    }
  });
  return {
    ...value,
    type: value.type,
    calcArguments,
    calcId: value.calcId,
    children: []
  } as IHoverInfoFilterModel;
}
