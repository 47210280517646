import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { InsightsPanelBase } from '../insights-panel-base.directive';
import { IRankGroupModel, IGroupImageModel } from '@app/models/group-model';
import { DisplayDataState } from '@app/state/display-data/display-data.state';
import { StudyState } from '@app/state/study/study.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { MatrixService } from '@app/services/matrix/matrix.service';

@UntilDestroy()
@Component({
  selector: 'app-rank-insights-panel',
  templateUrl: './rank-insights-panel.component.html',
  styleUrls: ['./rank-insights-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankInsightsPanelComponent extends InsightsPanelBase implements OnInit {
  @Select(DisplayDataState.displayData)
  private displayData$: Observable<Array<IRankGroupModel>>;
  @Select(StudyState.getN)
  private numEntries$: Observable<number>;
  @Select(StudyState.getRankDirectionTop)
  private rankDirectionTop$: Observable<boolean>;

  public selectedGroupingName: string = null;
  public allHighlightedPercent: number = null;
  public insightsByGrouping: { [name: string]: Array<{ name: string; value: number }> } = null;
  public insightsForHighlights: Array<{ name: string; value: number }> = null;
  public metric: string;
  public rankDirectionTop: boolean;
  public numEntries: number;
  public groupings: Array<string> = [];

  constructor(private matrixService: MatrixService, private store: Store, private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.subRankMatrix();
    this.subHighlightedProducts();
  }

  private subRankMatrix() {
    combineLatest([this.displayData$, this.numEntries$, this.rankDirectionTop$])
      .pipe(untilDestroyed(this))
      .subscribe(([displayData, numEntries, rankDirectionTop]) => {
        this.getRankInsights(displayData, numEntries, rankDirectionTop);
      });
  }

  private subHighlightedProducts() {
    this.matrixService.highlightedProductsSubject
      .pipe(untilDestroyed(this))
      .subscribe((highlightedProducts: Set<string>) => {
        this.getRankHighlightedInsights(highlightedProducts);
      });
  }

  public getRankInsights(displayData: Array<IRankGroupModel>, numEntries: number, rankDirectionTop: boolean): void {
    this.loading = true;
    this.cdr.detectChanges();

    this.numEntries = numEntries;
    this.rankDirectionTop = rankDirectionTop;

    const sorting = this.store.selectSnapshot(StudyState.getAxisX);
    const grouping = this.store.selectSnapshot(StudyState.getAxisY);

    this.metric = sorting[0].name;
    this.groupings = grouping.map(item => item.name);

    const groupModelsByLevels = this.getGroupModelsByLevels(displayData);

    const allImageModels = displayData.reduce(
      (acc: Array<IGroupImageModel>, item: IRankGroupModel) => [...acc, ...this.getAllImageModels(item)],
      []
    );
    const sumOfAllMetrics = this.getSumOfRankImageMetrics(allImageModels);

    const groupImagesByLevel: Array<{ [name: string]: Array<IGroupImageModel> }> = [];
    groupModelsByLevels.forEach((levelGroups: Array<IRankGroupModel>) => {
      const levelResult: { [name: string]: Array<IGroupImageModel> } = {};
      levelGroups.forEach((group: IRankGroupModel) => {
        if (group.name?.value) {
          const images = this.getAllImageModels(group);
          if (levelResult[group.name.value]) {
            levelResult[group.name.value] = [...levelResult[group.name.value], ...images];
          } else {
            levelResult[group.name.value] = [...images];
          }
        }
      });
      groupImagesByLevel.push(levelResult);
    });

    this.insightsByGrouping = {};
    groupImagesByLevel.forEach((level: { [name: string]: Array<IGroupImageModel> }, index: number) => {
      this.insightsByGrouping[grouping[index].name] = Object.keys(level).map((name: string) => {
        const allGroupImages = level[name];
        const sumOfGroupImageMetrics = this.getSumOfRankImageMetrics(allGroupImages);
        return {
          name,
          value: this.getPercentValue(sumOfGroupImageMetrics, sumOfAllMetrics)
        };
      });
    });

    if (
      this.selectedGroupingName === null ||
      grouping.findIndex(item => item.name === this.selectedGroupingName) === -1
    ) {
      this.selectedGroupingName = this.groupings[0];
    }

    this.loading = false;
    this.cdr.detectChanges();
  }

  public getRankHighlightedInsights(highlightedProducts: Set<string>): void {
    this.loading = true;
    this.cdr.detectChanges();

    if (highlightedProducts.size > 0) {
      this.rankDirectionTop = this.store.selectSnapshot(StudyState.getRankDirectionTop);
      this.numEntries = this.store.selectSnapshot(StudyState.getN);

      const displayData: Array<IRankGroupModel> = this.store.selectSnapshot(DisplayDataState.displayData);
      const allImageModels = displayData.reduce(
        (acc: Array<IGroupImageModel>, item: IRankGroupModel) => [...acc, ...this.getAllImageModels(item)],
        []
      );
      const sumOfAllMetrics = this.getSumOfRankImageMetrics(allImageModels);
      const imagesByGroupName: { [name: string]: Array<IGroupImageModel> } = {};
      displayData.forEach((group: IRankGroupModel) => {
        const images = this.getAllImageModels(group);
        imagesByGroupName[group.name.value] = [...images];
      });

      const allHighlightedImageModels = allImageModels.filter(image => highlightedProducts.has(image.prodId));
      this.allHighlightedPercent = this.getPercentValue(allHighlightedImageModels.length, allImageModels.length);
      const sumOfAllHighlightedImageMetrics = this.getSumOfRankImageMetrics(allHighlightedImageModels);
      this.insightsForHighlights = [
        {
          name: 'All',
          value: this.getPercentValue(sumOfAllHighlightedImageMetrics, sumOfAllMetrics)
        }
      ];
      Object.keys(imagesByGroupName).forEach((name: string) => {
        const groupImages = imagesByGroupName[name];
        const highlightedGroupImages = groupImages.filter((item: IGroupImageModel) =>
          highlightedProducts.has(item.prodId)
        );
        const sumOfImageMetricsInGroup = this.getSumOfRankImageMetrics(groupImages);
        const sumOfHighlightedImageMetricsInGroup = this.getSumOfRankImageMetrics(highlightedGroupImages);
        this.insightsForHighlights.push({
          name,
          value: this.getPercentValue(sumOfHighlightedImageMetricsInGroup, sumOfImageMetricsInGroup)
        });
      });
    } else {
      this.allHighlightedPercent = null;
      this.insightsForHighlights = null;
    }

    this.loading = false;
    this.cdr.detectChanges();
  }

  private getSumOfRankImageMetrics(images: Array<IGroupImageModel>): number {
    return images.reduce((result, image) => result + (image.data.sortByValue || 0), 0);
  }

  public getGroupModelsByLevels(displayData: Array<IRankGroupModel>): Array<Array<IRankGroupModel>> {
    if (!displayData || displayData.length === 0) return [];
    const result = [[...displayData]];
    const subGroupModels: Array<IRankGroupModel> = displayData.reduce((acc, curr) => {
      if (!curr.groups || curr.groups.length === 0) return acc;
      return [...acc, ...curr.groups];
    }, []);
    if (subGroupModels.length === 0) return result;
    return [...result, ...this.getGroupModelsByLevels(subGroupModels)];
  }

  private getAllImageModels(group: IRankGroupModel): Array<IGroupImageModel> {
    if (group.groups?.length > 0)
      return group.groups.reduce((acc, curr) => [...acc, ...this.getAllImageModels(curr)], []);
    if (group.images?.length > 0) {
      const nImages = this.getNImages(group.images);
      return nImages;
    }
    return [];
  }

  private getNImages(images: Array<IGroupImageModel>): Array<IGroupImageModel> {
    const items = images.sort((imageA, imageB) => imageB.data.sortByValue - imageA.data.sortByValue);
    // eslint-disable-next-line no-nested-ternary
    return this.rankDirectionTop
      ? items.slice(0, this.numEntries)
      : items.length > this.numEntries
      ? items.slice(items.length - this.numEntries)
      : [...items];
  }

  public trackByName(index: number, item: { name: string; value: number }) {
    return item.name;
  }
}
