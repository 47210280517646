<app-study-synchronizer></app-study-synchronizer>
<app-matrix-canvas></app-matrix-canvas>
<app-loader
  [subjects]="['data', 'highlight', 'aggregation', 'workspace', 'images', 'resourceManager', 'workspaceSelector']"
  [isShowTitle]="isShowLoaderTitle"
></app-loader>
<app-popper-menu app-fade-in-out fadeOutEvent="LeavePage" fadeInEvent="ChangedPage"></app-popper-menu>
<app-harvest-toolbar app-fade-in-out fadeOutEvent="LeavePage" fadeInEvent="ChangedPage"></app-harvest-toolbar>
<div class="left-sidebar sidebar">
  <app-left-header></app-left-header>
  <app-left-menu app-fade-in-out fadeOutEvent="LeavePage" fadeInEvent="ChangedPage"></app-left-menu>
  <app-toolbox-left-primary
    app-fade-in-out
    fadeOutEvent="LeavePage"
    fadeInEvent="ChangedPage"
    [fadeIn]="filtersSelected"
    *ngIf="filtersSelected"
  ></app-toolbox-left-primary>
</div>
<div class="right-sidebar sidebar">
  <app-right-header></app-right-header>
</div>
<div class="sidebar bottom-sidebar" *ngIf="collage$ | async">
  <app-explore-bar
    app-fade-in-out
    fadeOutEvent="LeavePage"
    fadeInEvent="ChangedPage"
    [filtersSelected]="filtersSelected"
  ></app-explore-bar>
</div>
<div class="sidebar statsbar" *ngIf="collage$ | async">
  <app-stats-bar app-fade-in-out fadeOutEvent="LeavePage" fadeInEvent="ChangedPage"></app-stats-bar>
</div>
<div class="overlay-container" id="matrix-overlay-container"></div>
<div
  class="magnify-container"
  id="matrix-magnify-container"
  app-fade-in-out
  fadeOutEvent="LeavePage"
  fadeInEvent="ChangedPage"
></div>
<app-workspace-selector></app-workspace-selector>
