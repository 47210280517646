const actionsHeight = 53;
const wrapperPaddingTop = 36;
const contentPadding = { top: 20, right: 24, bottom: 20, left: 24 };
export const slideRatio = {
  width: 16,
  height: 9
};

export const yOffset = actionsHeight + wrapperPaddingTop + contentPadding.top + contentPadding.bottom;
export const xOffset = contentPadding.left + contentPadding.right;
