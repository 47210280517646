import { Component, Inject } from '@angular/core';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICollage } from '../../../state/collage/collage.model';
import { IUserData } from '../../../models/user-data.model';

@UntilDestroy()
@Component({
  selector: 'app-create-new-study-dialog',
  templateUrl: './create-new-study-dialog.component.html',
  styleUrls: ['./create-new-study-dialog.component.scss']
})
export class CreateNewStudyDialogComponent {
  public readonly PortalMenuItem = PortalMenuItem;

  constructor(
    public dialogRef: MatDialogRef<CreateNewStudyDialogComponent, ICollage>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentUserData: IUserData | null;
    }
  ) {}

  onClose(collage?: ICollage) {
    this.dialogRef.close(collage);
  }
}
