<div class="container">
  <app-insights-panel
    class="insights-panel-component"
    [@moveInsightsPanelAboveCompassAnimation]="compassComponent?.expanded ? 'expanded' : 'closed'"
    [matrixType]="matrixType$ | async"
  ></app-insights-panel>
  <app-compass *ngIf="(matrixType$ | async) || (hasWorkspaces$ | async)" class="compass"></app-compass>
  <div class="aside toolbox">
    <ng-container *ngIf="matrixType$ | async as matrixType; else selection">
      <div
        class="bar-content"
        (app-delayed-hover)="(null)"
        [delayLeave]="1000"
        [menuOpened]="matrixTypeMenuOpened || filterBoxState !== null"
      >
        <mat-menu #matrixTypeMenu="matMenu" class="mat-menu-matrix-types-box no-padding">
          <div class="matrix-type-selection">
            <svg-icon
              *ngFor="let icon of matrixIcons"
              matRipple
              (click)="onSelectMatrixType(icon.value)"
              [matTooltip]="icon.value | matrixIconTooltip"
              matTooltipPosition="above"
              matTooltipClass="above"
              svgClass="matrix-type-icon"
              [name]="icon.iconName"
            ></svg-icon>
          </div>
        </mat-menu>
        <ng-container *ngIf="currentMatrixIcon">
          <div
            class="matrix-type"
            matRipple
            [matTooltip]="currentMatrixIcon?.value | matrixIconTooltip"
            matTooltipPosition="above"
            matTooltipClass="above"
            [matMenuTriggerFor]="matrixTypeMenu"
            (menuOpened)="setMatrixTypeMenuOpened(true)"
            (menuClosed)="setMatrixTypeMenuOpened(false)"
          >
            <svg-icon svgClass="icon matrix-type-icon" [name]="currentMatrixIcon.iconName"></svg-icon>
          </div>

          <div class="divider"></div>

          <app-explore-bar-filtering
            *ngIf="matrixSettings?.filterComponent"
            [filters]="filters"
            [settings]="matrixSettings?.filterComponent"
            [showFilterBox]="filterBoxState === 'filtering'"
            (filterBoxToggled)="setFilterBoxState($event)"
          ></app-explore-bar-filtering>

          <!-- div class="title">show</div -->

          <app-explore-bar-sorting
            *ngIf="matrixSettings?.sortingComponent"
            [filters]="filters"
            [settings]="matrixSettings?.sortingComponent"
            [showFilterBox]="filterBoxState === 'sorting'"
            (filterBoxToggled)="setFilterBoxState($event)"
          ></app-explore-bar-sorting>

          <app-explore-bar-grouping
            *ngIf="matrixSettings?.groupingComponent"
            [filters]="filters"
            [settings]="matrixSettings?.groupingComponent"
            [showFilterBox]="filterBoxState === 'grouping'"
            (filterBoxToggled)="setFilterBoxState($event)"
          ></app-explore-bar-grouping>
        </ng-container>
        <app-multi-handle-slider *ngIf="matrixType && matrixType === 'pareto'"></app-multi-handle-slider>

        <button
          class="plotting-toggle-icon"
          *ngIf="matrixType && matrixType === 'rank' && matrixRendered"
          [matTooltip]="absolutePlotting ? 'Absolute distribution' : 'Relative distribution'"
          matTooltipPosition="above"
          matTooltipClass="above"
          matRipple
          (click)="onPlottingToggleClick()"
        >
          <svg-icon
            *ngIf="absolutePlotting"
            [svgStyle]="{ 'width.px': 36, 'height.px': 36, 'margin-top.px': 1 }"
            svgClass="icon"
            name="icon-rank-plotting-absolute"
          ></svg-icon>
          <svg-icon
            *ngIf="!absolutePlotting"
            [svgStyle]="{ 'width.px': 36, 'height.px': 36, 'margin-top.px': 1 }"
            svgClass="icon"
            name="icon-rank-plotting-relative"
          ></svg-icon>
        </button>

        <div
          class="group-orientation-icon"
          *ngIf="matrixType && (matrixType === 'rank' || matrixType === 'pareto') && matrixRendered"
          [class.horizontal]="groupOrientation === 'horizontal'"
          matTooltip="Orientation"
          matTooltipPosition="above"
          matTooltipClass="above"
          matRipple
          (click)="onGroupOrientationChange()"
        >
          <svg-icon
            [svgStyle]="{ 'width.px': 36, 'height.px': 36, 'margin-top.px': 6 }"
            svgClass="icon"
            name="icon-rank-group-orientation"
          ></svg-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #selection>
  <app-toolbox-matrix-type (selectType)="onSelectMatrixType($event)"></app-toolbox-matrix-type>
</ng-template>
