import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { CdkDragDrop, DragDrop, DragRef, DropListRef, Point } from '@angular/cdk/drag-drop';
import { AdaptiveToolbarItemComponent } from './adaptive-toolbar-item/adaptive-toolbar-item.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { WINDOW } from '@app/shared/utils/window';
import { IFiltersModel } from '@app/models/filters.model';

export declare type ToolbarOrientation = 'top' | 'bottom';

export interface IToolbarItemSubscription {
  click: Subscription;
}

export interface IMouseDragEvent {
  source: DragRef;
  pointerPosition: { x: number; y: number };
  event: MouseEvent | TouchEvent;
  distance: Point;
  delta: { x: -1 | 0 | 1; y: -1 | 0 | 1 };
}

export interface ICustomDragDrop extends CdkDragDrop<any> {
  isPointerOverFilterContainer?: boolean;
}

@Component({
  selector: 'app-adaptive-toolbar',
  templateUrl: './adaptive-toolbar.component.html',
  styleUrls: ['./adaptive-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdaptiveToolbarComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @Input()
  public orientation: ToolbarOrientation = 'bottom';

  // works only for orientation='top', if true, the dropdown items shown from bottom to the top
  @Input()
  public reverseDropDownItems: boolean = true;

  @Input()
  public canDragDrop: boolean = false;

  @Output()
  public dropped = new EventEmitter<ICustomDragDrop>();

  @ContentChild(TemplateRef, { static: false }) dynamicContent: TemplateRef<unknown>;

  @ViewChild('toolbarWrapper', { static: false }) toolbarWrapper: ElementRef;
  @ViewChild('buttonMore', { static: false }) buttonMore: ElementRef;

  @ViewChild('toolbarContainer', { static: false }) toolbarContainer: ElementRef;
  @ViewChild('dropdownContainer', { static: false }) dropdownContainer: ElementRef;

  @ContentChildren(AdaptiveToolbarItemComponent, { descendants: true })
  toolbarItems: QueryList<AdaptiveToolbarItemComponent>;

  @ContentChildren(AdaptiveToolbarItemComponent, { descendants: true })
  dropdownItems: QueryList<AdaptiveToolbarItemComponent>;

  public hasDropdownItems: boolean = false;
  public isShowDropdownMenu: boolean = false;
  public isDragging: boolean = false;

  public buttonMoreLeftMargin = 15;

  public hostPortal: TemplatePortal<unknown>;

  private isDragNDropInitialized: boolean = false;
  private dragRefs1: Array<DragRef> = new Array<DragRef>();
  private dragRefs2: Array<DragRef> = new Array<DragRef>();
  private toolbarDropListRef: DropListRef<any>;
  private dropdownDropListRef: DropListRef<any>;
  private indexesOfItems: Array<number> = [];

  private resizeObserver: ResizeObserver;
  private childrenVisibility = new Map<AdaptiveToolbarItemComponent, boolean>();
  private toolbarItemsClickSubscriptions = new Map<AdaptiveToolbarItemComponent, IToolbarItemSubscription>();

  protected readonly outOfDropdownPanelClickListenerRef = this.onDocumentClick.bind(this);

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    protected readonly cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private dragDropService: DragDrop,
    private renderer: Renderer2
  ) {
    this.dragDropService = dragDropService;
  }

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.cdr.markForCheck();
      this.prepareToolbar();
    });
  }

  ngOnDestroy() {
    this.childrenVisibility.clear();
    this.resizeObserver.unobserve(this.toolbarWrapper.nativeElement);
    this.toolbarItemsClickSubscriptions.forEach(item => {
      item.click.unsubscribe();
    });
    this.toolbarItemsClickSubscriptions.clear();
  }

  ngAfterViewInit() {
    this.hostPortal = new TemplatePortal(this.dynamicContent, this.viewContainerRef);
    this.cdr.detectChanges();

    this.childrenVisibility.clear();
    this.resizeObserver.observe(this.toolbarWrapper.nativeElement); // repaint toolbar if toolbar resized

    setTimeout(() => {
      this.cdr.markForCheck();

      if (this.canDragDrop && !this.isDragNDropInitialized) {
        this.initDragNDrop();
        this.updateDragNDropForToolbar();
        this.updateDragNDropForDropdown();
      }

      this.cdr.markForCheck();
      this.prepareToolbar();
    }, 200); // process toolbar items after rendering
  }

  ngAfterViewChecked() {
    if (this.isDragging) {
      return;
    }

    this.updateOrientation();
    this.updateIsCanDragNDrop();

    // check if any toolbar item has changed visibility (for example, by *ngIf or *ngFor expression)
    const isNeedRebuild = this.toolbarItems.length !== this.childrenVisibility.size;
    if (isNeedRebuild) {
      // toolbarItems have been changed, so need to subscribe to the new list of items
      this.childrenVisibility.clear();
      this.toolbarItemsClickSubscriptions.forEach(item => {
        item.click.unsubscribe();
      });
      this.toolbarItemsClickSubscriptions.clear();

      this.updateDragNDropForToolbar();
      this.updateDragNDropForDropdown();
    }

    let isNeedRepaint = false;
    this.toolbarItems.forEach((item: AdaptiveToolbarItemComponent) => {
      const newVisibility = this.isItemVisibleInToolbar(item);
      if (this.childrenVisibility.has(item)) {
        if (this.childrenVisibility.get(item) !== newVisibility) {
          this.childrenVisibility.set(item, newVisibility);
          isNeedRepaint = true;
        }
      } else {
        this.childrenVisibility.set(item, newVisibility);
        const click = item.clicked.subscribe(() => {
          this.hideDropdownMenu();
        });
        this.toolbarItemsClickSubscriptions.set(item, { click });
        isNeedRepaint = true;
      }
    });

    if (isNeedRebuild || isNeedRepaint) {
      setTimeout(() => {
        this.prepareToolbar();
      }, 0); // just added items must be prepared after rendering
    }
  }

  hideDropdownMenu(): void {
    if (this.isShowDropdownMenu) {
      this.toggleDropdown(null);
    }
  }

  toggleDropdown($event: any): void {
    $event?.stopPropagation();
    if (this.hasDropdownItems) {
      this.isShowDropdownMenu = !this.isShowDropdownMenu;
    } else {
      this.isShowDropdownMenu = false;
    }
    if (this.hasDropdownItems && this.isShowDropdownMenu) {
      this.renderer.addClass(this.toolbarWrapper.nativeElement.parentElement, 'show-dropdown-panel');
      this.renderer.removeClass(this.dropdownContainer.nativeElement, 'hidden');
      this.window.document.addEventListener('click', this.outOfDropdownPanelClickListenerRef);
    } else {
      this.renderer.removeClass(this.toolbarWrapper.nativeElement.parentElement, 'show-dropdown-panel');
      this.renderer.addClass(this.dropdownContainer.nativeElement, 'hidden');
      this.window.document.removeEventListener('click', this.outOfDropdownPanelClickListenerRef);
    }
  }

  isItemVisibleInToolbar(item: AdaptiveToolbarItemComponent): boolean {
    const toolbarWidth = this.toolbarWrapper.nativeElement.clientWidth;
    const buttonMoreWidth = this.buttonMore.nativeElement.clientWidth;
    return item.isOutOfBounds(toolbarWidth - buttonMoreWidth - this.buttonMoreLeftMargin);
  }

  private moveDragItem(event: IMouseDragEvent) {
    const element = this.window.document.querySelector('app-adaptive-toolbar-item.cdk-drag-preview');
    if (element && this.isOutOfToolbar(event.event.target) && this.isOutOfFilter(event.event.target)) {
      this.renderer.addClass(element, 'out-of-list');
    } else {
      this.renderer.removeClass(element, 'out-of-list');
    }
    if (element && !this.isOutOfFilter(event.event.target)) {
      this.renderer.addClass(element, 'over-filtering');
    } else {
      this.renderer.removeClass(element, 'over-filtering');
    }
  }

  // check that target not out of component bounds
  isOutOfToolbar(target: EventTarget | null): boolean {
    if (target === this.toolbarWrapper.nativeElement.parentElement) {
      return false;
    }
    if (!target || target === window.document.body) {
      return true;
    }
    return this.isOutOfToolbar((target as any).parentElement);
  }

  isOutOfFilter(target: EventTarget | null): boolean {
    const filterElement = this.window.document.querySelector('.explore-bar-filtering-container__filter-button');
    if (target === filterElement) {
      return false;
    }
    if (!target || target === window.document.body) {
      return true;
    }
    return this.isOutOfFilter((target as any).parentElement);
  }

  drop(event: any): void {
    this.isDragging = false;

    // bugfix: if dropped on empty area on toolbar wrapper - it's not out, so need to correct
    if (!event.isPointerOverContainer) {
      const rect = this.toolbarWrapper.nativeElement.getBoundingClientRect();
      if (
        rect.left <= event.dropPoint.x &&
        rect.right >= event.dropPoint.x &&
        rect.top <= event.dropPoint.y &&
        rect.bottom >= event.dropPoint.y
      ) {
        event.isPointerOverContainer = true;
      } else {
        const filterElement = this.window.document.querySelector('.explore-bar-filtering-container__filter-button');
        if (filterElement) {
          const filterRect = filterElement.getBoundingClientRect();
          if (
            filterRect.left <= event.dropPoint.x &&
            filterRect.right >= event.dropPoint.x &&
            filterRect.top <= event.dropPoint.y &&
            filterRect.bottom >= event.dropPoint.y
          ) {
            event.isPointerOverFilterContainer = true;
          }
        }
      }
    }

    if (this.orientation === 'top' && this.reverseDropDownItems) {
      if (event.previousContainer === this.dropdownDropListRef) {
        // need correct indexes before sending event out
        // need reverse indexes of items
        if (event.container === event.previousContainer) {
          // moving between dropdown items only
          event.currentIndex = this.indexesOfItems.findIndex(value => value === event.currentIndex);
          event.previousIndex = this.indexesOfItems.findIndex(value => value === event.previousIndex);
        } else {
          // moved from dropdown to the toolbar
          event.previousIndex = this.indexesOfItems.findIndex(value => value === event.previousIndex);
        }
      }
    }

    if (event.previousIndex === event.currentIndex && event.isPointerOverContainer) {
      this.isDragging = false;
      return; // don't send event, because of nothing changed
    }

    if (event.previousContainer !== event.container) {
      // bugfix: always return one container (from toolbar)
      // - external code should not know that we have two containers
      this.dropped.emit({
        ...event,
        previousContainer: this.toolbarDropListRef,
        container: this.toolbarDropListRef
      });
    } else {
      this.dropped.emit(event);
    }
  }

  initDragNDrop(): void {
    this.toolbarDropListRef = this.dragDropService.createDropList(this.toolbarContainer);
    this.dropdownDropListRef = this.dragDropService.createDropList(this.dropdownContainer);

    this.toolbarDropListRef.withOrientation('horizontal');
    this.dropdownDropListRef.withOrientation('vertical');

    // connectTo
    this.toolbarDropListRef.connectedTo([this.toolbarDropListRef, this.dropdownDropListRef]);
    this.dropdownDropListRef.connectedTo([this.toolbarDropListRef, this.dropdownDropListRef]);

    this.toolbarDropListRef.beforeStarted.subscribe(event => {
      this.isDragging = true;
      this.indexesOfItems = this.getReversedIndexesOfItems();
      this.renderer.addClass(this.toolbarContainer.nativeElement, 'cdk-drop-list-dragging');
      this.renderer.addClass(this.toolbarWrapper.nativeElement.parentElement, 'dragging');
    });
    this.dropdownDropListRef.beforeStarted.subscribe(event => {
      this.isDragging = true;
      this.indexesOfItems = this.getReversedIndexesOfItems();
      this.renderer.addClass(this.dropdownContainer.nativeElement, 'cdk-drop-list-dragging');
      this.renderer.addClass(this.toolbarWrapper.nativeElement.parentElement, 'dragging');
    });

    this.toolbarDropListRef.dropped.subscribe(event => {
      this.drop(event);
      this.indexesOfItems = [];
      this.renderer.removeClass(this.toolbarContainer.nativeElement, 'cdk-drop-list-dragging');
      this.renderer.removeClass(this.toolbarWrapper.nativeElement.parentElement, 'dragging');
    });
    this.dropdownDropListRef.dropped.subscribe(event => {
      this.drop(event);
      this.indexesOfItems = [];
      this.renderer.removeClass(this.dropdownContainer.nativeElement, 'cdk-drop-list-dragging');
      this.renderer.removeClass(this.toolbarWrapper.nativeElement.parentElement, 'dragging');
    });

    this.isDragNDropInitialized = true;
  }

  updateIsCanDragNDrop() {
    if (this.isDragNDropInitialized) {
      this.toolbarDropListRef.disabled = !this.canDragDrop;
      this.dropdownDropListRef.disabled = !this.canDragDrop;
    }
  }

  updateDragNDropForToolbar(): void {
    if (!this.toolbarDropListRef) {
      return;
    }

    const items = this.toolbarItems.toArray();
    const itemsInToolbar = this.toolbarItems.toArray().slice(0, items.length / 2);

    const newDragRefs: DragRef<any>[] = [];
    itemsInToolbar.forEach(component => {
      let dragRef = this.dragRefs1.find(ref => ref.getRootElement() === component.element.nativeElement);
      if (!dragRef) {
        dragRef = this.dragDropService.createDrag<IFiltersModel>(component.element);
        dragRef.moved.subscribe(event => {
          this.moveDragItem(event);
        });
      }
      newDragRefs.push(dragRef);
    });
    this.dragRefs1 = newDragRefs;

    this.toolbarDropListRef.data = {
      refData: this.dragRefs1,
      itemData: this.toolbarItems
    };

    this.toolbarDropListRef.withItems(this.dragRefs1);
  }

  updateDragNDropForDropdown(): void {
    if (!this.dropdownDropListRef) {
      return;
    }

    const items = this.toolbarItems.toArray();
    const itemsInDropDown = this.toolbarItems.toArray().slice(items.length / 2);

    const newDragRefs: DragRef<any>[] = [];
    itemsInDropDown.forEach(component => {
      let dragRef = this.dragRefs2.find(ref => ref.getRootElement() === component.element.nativeElement);
      if (!dragRef) {
        dragRef = this.dragDropService.createDrag(component.element);
        dragRef.moved.subscribe(event => {
          this.moveDragItem(event);
        });
      }
      newDragRefs.push(dragRef);
    });
    this.dragRefs2 = newDragRefs;

    this.dropdownDropListRef.data = {
      refData: this.dragRefs2,
      itemData: this.dropdownItems
    };

    this.dropdownDropListRef.withItems(this.dragRefs2);
  }

  prepareToolbar(): void {
    this.renderer.addClass(this.dropdownContainer.nativeElement, 'hidden');
    this.renderer.addClass(this.buttonMore.nativeElement, 'hidden');
    this.hasDropdownItems = false;
    this.isShowDropdownMenu = false;

    const toolbarWidth = this.toolbarWrapper.nativeElement.clientWidth;
    const buttonMoreWidth = this.buttonMore.nativeElement.clientWidth;
    let maxItemWidth = 0;
    let hasHiddenItems = false;
    const items = this.toolbarItems.toArray();
    const itemsInToolbar = this.toolbarItems.toArray().slice(0, items.length / 2);
    const itemsInDropDown = this.toolbarItems.toArray().slice(items.length / 2);
    for (let index = 0; index < itemsInToolbar.length; index++) {
      const componentInToolbar = itemsInToolbar[index];
      const componentInDropdown = itemsInDropDown[index];
      if (componentInToolbar.isOutOfBounds(toolbarWidth - buttonMoreWidth - this.buttonMoreLeftMargin)) {
        maxItemWidth = Math.max(maxItemWidth, componentInToolbar.element.nativeElement.clientWidth);
        this.hasDropdownItems = true;
        this.renderer.removeClass(this.buttonMore.nativeElement, 'hidden');
        this.renderer.setAttribute(componentInToolbar.element.nativeElement, 'in-drop-down', 'true');
        this.renderer.removeClass(componentInDropdown.element.nativeElement, 'hidden');
        hasHiddenItems = true;
      } else {
        this.renderer.removeAttribute(componentInToolbar.element.nativeElement, 'in-drop-down');
        this.renderer.addClass(componentInDropdown.element.nativeElement, 'hidden');
      }
      // mark item as Prepared - so, ready to be shown/hidden in toolbar/dropdown
      // by default not prepared items are hidden - to avoid lags in rendering just added items
      this.renderer.addClass(componentInToolbar.element.nativeElement, 'prepared');
      this.renderer.addClass(componentInDropdown.element.nativeElement, 'prepared');
    }
    if (this.hasDropdownItems) {
      this.renderer.setStyle(this.dropdownContainer.nativeElement, 'width', `${maxItemWidth}px`);
      this.renderer.setStyle(this.dropdownContainer.nativeElement, 'right', `${buttonMoreWidth}px`);
      this.renderer.removeClass(this.buttonMore.nativeElement, 'hidden');
    }
    if (this.isShowDropdownMenu) {
      this.renderer.addClass(this.buttonMore.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.buttonMore.nativeElement, 'active');
    }
    if (this.toolbarItems.length === 0) {
      this.renderer.addClass(this.toolbarWrapper.nativeElement, 'empty');
    } else {
      this.renderer.removeClass(this.toolbarWrapper.nativeElement, 'empty');
    }
    if (hasHiddenItems) {
      this.renderer.addClass(this.toolbarWrapper.nativeElement, 'has-hidden-items');
    } else {
      this.renderer.removeClass(this.toolbarWrapper.nativeElement, 'has-hidden-items');
    }
  }

  private updateOrientation(): void {
    if (this.orientation === 'top') {
      this.renderer.addClass(this.dropdownContainer.nativeElement, 'orientation-top');
      const toolbarHeight = 0; // this.toolbarWrapper.nativeElement.clientHeight;
      const dropdownHeight = this.dropdownContainer.nativeElement.clientHeight;
      this.renderer.setStyle(
        this.dropdownContainer.nativeElement,
        'margin-top',
        `-${toolbarHeight + dropdownHeight + 10}px`
      );
      if (this.reverseDropDownItems) {
        this.renderer.addClass(this.dropdownContainer.nativeElement, 'reverse-items');
      }
    } else {
      this.renderer.removeClass(this.dropdownContainer.nativeElement, 'orientation-top');
      this.renderer.removeClass(this.dropdownContainer.nativeElement, 'reverse-items');
      this.renderer.removeStyle(this.dropdownContainer.nativeElement, 'margin-top');
    }
  }

  private onDocumentClick(event: any): void {
    const panel = this.dropdownContainer.nativeElement;
    if (panel !== event.target && !panel.contains(event.target)) {
      this.hideDropdownMenu();
      window.document.removeEventListener('click', this.outOfDropdownPanelClickListenerRef);
    }
  }

  /**
   * Return array of indexes, first items from 0 to maxVisibleItem are from toolbar,
   * next items are from the dropdown list and reversed
   * i.e. if total items = 8 and last 3 items are hidden in dropdown panel,
   * result will be:
   * [0, 1, 2, 3, 4, 7, 6, 5]  (last three items reversed)
   */
  private getReversedIndexesOfItems() {
    // figure out, which items in toolbar and which in dropdown
    const toolbarWidth = this.toolbarWrapper.nativeElement.clientWidth;
    const buttonMoreWidth = this.buttonMore.nativeElement.clientWidth;
    const items = this.toolbarItems.toArray();
    const itemsInToolbar = this.toolbarItems.toArray().slice(0, items.length / 2);
    const firstInDropdownIndex = itemsInToolbar.findIndex(item =>
      item.isOutOfBounds(toolbarWidth - buttonMoreWidth - this.buttonMoreLeftMargin)
    );
    const maxDropdownIndex = items.length / 2;
    // build reversed array of indexes
    let reversedIndexes = [];
    for (let index = firstInDropdownIndex; index < maxDropdownIndex; index++) {
      reversedIndexes.push(index);
    }
    reversedIndexes = reversedIndexes.reverse();
    // indexes: concat reversed items with items from toolbar
    const indexes = [];
    for (let index = 0; index < firstInDropdownIndex; index++) {
      indexes.push(index);
    }
    return indexes.concat(reversedIndexes);
  }
}
