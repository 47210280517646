import { Container, Graphics, InteractionEvent, Point, Polygon, Rectangle, Sprite, Texture } from 'pixi.js';
import { ImageContainer } from './image-container';
import { CursorStates } from '@app/shared/enums/cursor-styles.enum';
import gsap from 'gsap/all';
import { IHoverInfoField } from '../models/image.model';
import { FrameTitle } from './frame-title-container';
import { FrameInfoOverlay } from './frame-info-overlay';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { IFrameTitleChangeEvent } from '@app/models/frame-title-change-event.model';
import { IBounds } from '@app/models/bounds.model';
import { PublishIconStates } from '@app/shared/enums/publish-icon-states.enum';
import { IPosition } from '@app/models/workspace-list.model';
import { DropShadowService } from '@app/services/drop-shadow/drop-shadow.service';
import { FriendlyNameService } from '../services/friendly-name/friendly-name.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BlurFilter } from '@pixi/filter-blur';
import { shortUid } from '@app/shared/utils/short-uid';
import { DataService } from '../services/data/data.service';
import { IHoverInfoFieldModel, IMetricsCustomFormattings } from '../models/study-setting.model';

export class FrameContainer extends Container {
  private featureName: string;
  private featureValue: string;

  private title: FrameTitle;
  private borderContainer: Container;
  private borderRect: Graphics;
  private infoIconRef: HTMLDivElement;
  public publishIconRef: HTMLDivElement;
  private topLeftCorner: Graphics;
  private topRightCorner: Graphics;
  private bottomRightCorner: Graphics;
  private bottomLeftCorner: Graphics;
  private bg: Container;

  private infoOverlay: FrameInfoOverlay;
  private infoShown: boolean = false;
  private infoLocked: boolean = false;
  private restoreInfo: boolean = false;
  private infoIconActiveState: boolean = false;
  private hoverInfo: Array<IHoverInfoField> = [];

  // Last values, used to Calculate HoverInfo (if still the same, don't call API method to recalculate HoverInfo)
  private lastHoverInfoConfig: Array<IHoverInfoFieldModel>;
  private lastCustomFormattings: string;
  private lastImagesDataIDs: string;

  private images: ImageContainer[] = [];
  private frames: FrameContainer[] = [];

  private borderWidth: number;
  private numImagesOnRow = 1;
  private numImagesOnCol = 1;
  private imageSize: number;
  private distanceBetweenImages: number;

  private numCells = 1;
  private numCellsOnRow = 1;
  private numCellsOnCol = 1;
  private pad;
  private marginTop = 0;
  public imageCellX = 0;
  public imageCellY = 0;

  private cursorPositionFromAnchor;
  private savedFrameBoundsOnMatrix;
  private posFromWorldCenter: IPosition;

  private lastResizedXAxis = true;

  public active = false;
  public resizeEnabled = true;
  public draggingEnabled = true;
  public isParentFrame = true;
  public hasUnpublishedChanges = true;
  public publishIconVisible = false;
  public publishIconActive = false;
  public publishIconState: PublishIconStates = PublishIconStates.Default;
  public htmlElementsVisible = true;
  public htmlElementsVisibleTemp = true;

  public initialHeightForGroupBy;
  public initialYPosForGroupBy;
  public numFramesOnTopInGroupBy = 0;

  public zoomFactor: number = 1;
  public dropshadow: Graphics;
  public lastShadowSize: { width: number; height: number; isActive: boolean } = null;

  private imageReorderAnimationDuration = 0.1;

  public mouseMoveActions = {
    Drag: this.onDrag.bind(this),
    FrameInfo: this.onDrag.bind(this),
    PublishIcon: this.onDrag.bind(this),
    LeftResize: this.onLeftResize.bind(this),
    TopResize: this.onTopResize.bind(this),
    RightResize: this.onRightResize.bind(this),
    BottomResize: this.onBottomResize.bind(this)
  };

  private snapToFitFunctions = {
    LeftResize: this.snapToFitLeft.bind(this),
    TopResize: this.snapToFitTop.bind(this),
    RightResize: this.snapToFitRight.bind(this),
    BottomResize: this.snapToFitBottom.bind(this)
  };

  // Uses in GroupBy mode
  public filteredRows?: any[];

  constructor(
    private readonly id = shortUid(),
    width: number,
    height: number,
    borderWidth: number,
    marginTop: number,
    imageSize: number,
    distanceBetweenImages: number,
    private readonly friendlyNameService: FriendlyNameService,
    private readonly dataService: DataService
  ) {
    super();

    this.borderWidth = borderWidth;
    this.marginTop = marginTop;

    this.imageSize = imageSize;
    this.distanceBetweenImages = distanceBetweenImages;

    this.createBorderAndCorners(width, height);
    this.createBackground(width, height);
    this.updateHitArea(width, height);
  }

  getFriendlyName(value: string): string {
    return this.friendlyNameService.getFriendlyName(value);
  }

  setZoomFactor(value: number) {
    this.zoomFactor = value;
    this.frames.forEach(frame => frame.setZoomFactor(value));
  }

  createTitle(featureName: string, featureValue: string) {
    this.featureName = featureName;
    this.featureValue = featureValue;
    const friendlyFeatureName = this.getFriendlyName(this.featureName);
    this.title = new FrameTitle(
      friendlyFeatureName,
      this.getNumImages(),
      featureValue,
      this.updateFrameInfoTitle.bind(this),
      this.updateFrameTitleState.bind(this)
    );
    this.title.updateMaxWidth(this.getWidth());
    this.updateHitArea(this.getWidth(), this.getHeight());
  }

  refreshTitle() {
    const friendlyFeatureName = this.getFriendlyName(this.featureName);
    this.title?.updateTitle(friendlyFeatureName);
  }

  createInfoIcon() {
    this.infoIconRef = document.createElement('div');
    getMatrixOverlayContainer().appendChild(this.infoIconRef);
    this.infoIconRef.classList.add('frame-info-icon');
    this.infoIconRef.innerHTML = `
      <img src="assets/icons/icon-frame-info.svg" alt="">
      <img src="assets/icons/icon-frame-info-locked.svg" alt="">
    `;
    this.infoIconRef.style.display = 'none';
  }

  createPublishIcon() {
    this.publishIconRef = document.createElement('div');
    getMatrixOverlayContainer().appendChild(this.publishIconRef);
    this.publishIconRef.classList.add(...['frame-publish-icon', 'default']);
    this.publishIconRef.innerHTML = `
      <img class="default" src="assets/icons/icon-publish-frame-default.svg" alt="">
      <img class="hover" src="assets/icons/icon-publish-frame-hover.svg" alt="">
      <img class="success" src="assets/icons/icon-publish-frame-success.svg" alt="">
      <img class="fail" src="assets/icons/icon-publish-frame-fail.svg" alt="">
      <img class="retry" src="assets/icons/icon-publish-frame-retry.svg" alt="">
    `;
    this.updatePublishIcon();
  }

  createInfoOverlay() {
    this.infoOverlay = new FrameInfoOverlay(this);
  }

  removeInfoOverlay() {
    this.infoOverlay?.remove();
    this.infoOverlay = null;
  }

  removeInfoIcon() {
    const overlay = getMatrixOverlayContainer();
    if (overlay.contains(this.infoIconRef)) overlay.removeChild(this.infoIconRef);
    this.infoIconRef = null;
  }

  removePubishIcon() {
    const overlay = getMatrixOverlayContainer();
    if (overlay.contains(this.publishIconRef)) overlay.removeChild(this.publishIconRef);
    this.publishIconRef = null;
  }

  resizeWithFactor(
    factor,
    resourceManager,
    dropShadowService: DropShadowService,
    updatePos = true,
    updateSubFrames = true
  ) {
    (this.bg.children[1] as Sprite).width *= factor;
    (this.bg.children[1] as Sprite).height *= factor;
    if (updatePos) {
      this.x *= factor;
      this.y *= factor;
    }
    this.imageSize *= factor;
    this.distanceBetweenImages *= factor;
    this.images.forEach(image => image.resize(resourceManager, this.imageSize, factor));
    if (updateSubFrames)
      this.frames.forEach(frame => frame.resizeWithFactor(factor, resourceManager, dropShadowService));
  }

  makeGroupByGrid() {
    this.updatePad();
    if (this.images.length > 0) {
      this.numCells = 1;
      this.numCellsOnRow = 1;
      this.numCellsOnCol = 1;
      this.numImagesOnRow = Math.ceil(Math.sqrt(this.images.length)) || 1;
      this.numImagesOnCol = Math.ceil(this.images.length / this.numImagesOnRow) || 1;
      this.grid(false);
    } else {
      const width = this.width + this.pad;
      const height = this.height + this.pad;
      this.updateBackground(width, height);
      this.updateFrameShadow(width, height);
      this.updateHitArea(width, height);
      this.drawBordersAndCorners(width, height);
      this.updateNumImagesOnTitle();
      this.updateTitleMaxWidth();
    }
  }

  makeSquareGrid(childrenFramesUpdate = true, imageReorderAnimationData?) {
    this.calculateNumCellsOnRowAndCol();
    this.grid(childrenFramesUpdate, imageReorderAnimationData);
  }

  makeGrid(childrenFramesUpdate = true, imageReorderAnimationData?) {
    if (this.frames.length === 0) {
      this.numCells = 1;
      this.numCellsOnRow = 1;
      this.numCellsOnCol = 1;

      if (this.lastResizedXAxis) {
        if (this.numImagesOnRow > this.images.length) this.numImagesOnRow = this.images.length || 1;
        this.numImagesOnCol = Math.ceil(this.images.length / this.numImagesOnRow) || 1;
      } else {
        if (this.numImagesOnCol > this.images.length) this.numImagesOnCol = this.images.length || 1;
        this.numImagesOnRow = Math.ceil(this.images.length / this.numImagesOnCol) || 1;
      }
      this.grid(childrenFramesUpdate, imageReorderAnimationData);
    } else {
      this.makeSquareGrid(childrenFramesUpdate, imageReorderAnimationData);
    }
  }

  public grid(childrenFramesUpdate = true, imageReorderAnimationData?) {
    this.updatePad();

    if (!this.numImagesOnRow) this.numImagesOnRow = 1;
    if (!this.numImagesOnCol) this.numImagesOnCol = 1;

    const imageCellWidth =
      2 * this.pad + this.numImagesOnRow * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages;
    const imageCellHeight =
      2 * this.pad + this.numImagesOnCol * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages;

    let cellWidth = imageCellWidth;
    let cellHeight = imageCellHeight;

    this.frames.forEach(frame => {
      if (childrenFramesUpdate) frame.makeGrid(childrenFramesUpdate, imageReorderAnimationData);
      if (frame.getWidth() + 2 * this.pad > cellWidth) cellWidth = frame.getWidth() + 2 * this.pad;
      if (frame.getHeight() + 2 * this.pad > cellHeight) cellHeight = frame.getHeight() + 2 * this.pad;
    });

    const topPad = this.frames.length > 0 ? (this.marginTop - this.pad < 0 ? 0 : this.marginTop - this.pad) : 0;

    const width = this.numCellsOnRow * cellWidth;
    const height = this.numCellsOnCol * cellHeight + topPad;

    this.gridSubFrames(cellWidth, cellHeight, topPad);
    this.gridImages(imageCellWidth, imageCellHeight, cellWidth, cellHeight, topPad, imageReorderAnimationData);

    this.updateBackground(width, height);
    this.updateFrameShadow(width, height);
    this.updateHitArea(width, height);
    this.drawBordersAndCorners(width, height);
    this.updateNumImagesOnTitle();
    this.updateTitleMaxWidth();
  }

  private gridSubFrames(cellWidth, cellHeight, topPad) {
    this.frames.forEach((frameCont: FrameContainer, i) => {
      const x = (i % this.numCellsOnRow) * cellWidth + (cellWidth - frameCont.getWidth()) / 2;
      const y = Math.floor(i / this.numCellsOnRow) * cellHeight + (cellHeight - frameCont.getHeight()) / 2 + topPad;
      this.addChild(frameCont);
      frameCont.position.set(x, y);
      frameCont.updateFrameTitlePosition();
    });
  }

  private gridImages(imageCellW, imageCellH, cellWidth, cellHeight, topPad, imageReorderAnimationData?) {
    const imageCellIndex = this.numCells - 1;
    this.imageCellX = (imageCellIndex % this.numCellsOnRow) * cellWidth + (cellWidth - imageCellW) / 2;
    this.imageCellY = Math.floor(imageCellIndex / this.numCellsOnRow) * cellHeight + (cellHeight - imageCellH) / 2;

    const animate = imageReorderAnimationData && imageReorderAnimationData.frameId === this.getId();
    if (animate) {
      // Hide (fade-Out) Image Previews
      const { tl } = imageReorderAnimationData;
      this.children.forEach(item => {
        if ((item as any).remove) {
          (item as any).remove = false;
          tl.to(
            item,
            {
              duration: this.imageReorderAnimationDuration,
              alpha: 0,
              onComplete: () => {
                this.removeChild(item);
              }
            },
            tl.time()
          );
        }
      });
    }

    this.getImages().forEach((imageCont, i) => {
      if (!imageCont) return;
      if (!this.children.includes(imageCont)) this.addChild(imageCont);

      const x =
        this.imageCellX +
        this.pad +
        (i % this.numImagesOnRow) * (this.imageSize + this.distanceBetweenImages) +
        this.imageSize / 2;
      const y =
        this.imageCellY +
        this.pad +
        Math.floor(i / this.numImagesOnRow) * (this.imageSize + this.distanceBetweenImages) +
        this.imageSize / 2 +
        topPad;

      if (animate && (imageCont as any).add) {
        // Add (Fade-In) Image Previews
        const { tl } = imageReorderAnimationData;
        (imageCont as any).add = false;
        imageCont.position.set(x, y);
        imageCont.alpha = 0;
        tl.to(
          imageCont,
          {
            duration: this.imageReorderAnimationDuration,
            alpha: 0.3
          },
          tl.time()
        );
      } else if (animate && (imageCont.x !== x || imageCont.y !== y)) {
        // Move Images and Image Previews with Animation
        const { tl } = imageReorderAnimationData;
        tl.to(
          imageCont,
          {
            duration: this.imageReorderAnimationDuration,
            x,
            y
          },
          tl.time()
        );
      } else {
        // Grid Images without Animation
        imageCont.position.set(x, y);
      }
    });
  }

  gridParent() {
    if (this.parent instanceof FrameContainer) {
      this.parent.makeGrid(false);
      this.parent.gridParent();
    }
  }

  restoreGrid() {
    this.getImages().forEach((imageCont, i) => {
      const { x, y } = imageCont.position;
      this.addChild(imageCont);
      imageCont.position.set(x, y);
    });
    this.updateTitleMaxWidth();
    this.updateNumImagesOnTitle();
    if (this.frames.length === 0) {
      this.calculateNumImagesOnRowAndCol();
    } else {
      this.calculateNumCellsOnRowAndCol();
    }
  }

  calculateNumCellsOnRowAndCol() {
    this.numCells = this.frames.length;
    if (this.images.length > 0) this.numCells++;
    if (!this.numCells) this.numCells = 1;
    this.numCellsOnRow = Math.ceil(Math.sqrt(this.numCells));
    this.numCellsOnCol = Math.ceil(this.numCells / this.numCellsOnRow);

    this.numImagesOnRow = Math.ceil(Math.sqrt(this.images.length)) || 1;
    this.numImagesOnCol = Math.ceil(this.images.length / this.numImagesOnRow) || 1;
  }

  calculateNumImagesOnRowAndCol() {
    this.updatePad();
    this.numImagesOnRow =
      (this.getWidth() - 2 * this.pad + this.distanceBetweenImages) / (this.imageSize + this.distanceBetweenImages);
    this.numImagesOnCol =
      (this.getHeight() - 2 * this.pad + this.distanceBetweenImages) / (this.imageSize + this.distanceBetweenImages);
    this.numImagesOnRow = Math.round(this.numImagesOnRow) || 1;
    this.numImagesOnCol = Math.round(this.numImagesOnCol) || 1;
  }

  addImage(image: ImageContainer) {
    this.images.push(image);
  }

  addImages(images: Array<ImageContainer>) {
    this.images.push(...images);
  }

  setImages(images: Array<ImageContainer>) {
    this.images = [...images];
  }

  addFrame(frame: FrameContainer) {
    this.frames.push(frame);
    frame.isParentFrame = false;
    frame.updatePublishIcon();
  }

  addFrames(frames: Array<FrameContainer>) {
    this.frames.push(...frames);
  }

  removeFrame(frame: FrameContainer) {
    const index = this.frames.indexOf(frame);
    this.frames.splice(index, 1);
    this.removeChild(frame);
  }

  changeInfoIconTexture() {
    if (this.infoLocked) {
      this.infoIconRef.classList.add('locked');
    } else {
      this.infoIconRef.classList.remove('locked');
    }
  }

  showInfo(hoverInfoConfig: IHoverInfoFieldModel[], customFormattings: IMetricsCustomFormattings, animate: boolean) {
    this.infoShown = true;
    this.updateFrameInfoText(hoverInfoConfig, customFormattings);
    this.images.forEach(image => {
      image.interactive = false;
    });
    this.frames.forEach(frame => {
      frame.interactive = false;
      frame.interactiveChildren = false;
    });
    gsap.to(this.borderRect, { duration: 0, pixi: { saturation: 0 } });
    this.infoOverlay.updateText(this.hoverInfo); // to calculate correct scale for labels
    this.infoOverlay.show(animate);
  }

  hideInfo(animate: boolean) {
    this.infoShown = false;
    this.images.forEach(image => {
      image.interactive = true;
    });
    this.frames.forEach(frame => {
      frame.interactive = true;
      frame.interactiveChildren = true;
    });
    gsap.to(this.borderRect, { duration: 0, pixi: { saturation: 1 } });
    this.infoOverlay.hide(animate);
  }

  showBordersAndCorners() {
    this.addChild(this.borderContainer);
    const bounds = this.getFrameBounds();
    this.updateInfoIconPosition(bounds);
    this.updateInfoIconVisibility();
  }

  hideBordersAndCorners() {
    this.removeChild(this.borderContainer);
    this.updateInfoIconVisibility();
    this.infoIconRef.style.left = `-1000px`;
    this.infoIconRef.style.top = `-1000px`;
  }

  showTitle() {
    this.title.move(this.getFrameBounds());
    this.title.show();
  }

  hideTitle() {
    this.title.hide();
  }

  updateFrameTitlePosition() {
    const bounds = this.getFrameBounds();
    this.title.move(bounds);

    this.frames.forEach(frame => frame.updateFrameTitlePosition());

    this.updateInfoIconPosition(bounds);
    if (this.publishIconRef) this.updatePublishIconPosition(bounds);
  }

  updateInfoIconPosition(bounds) {
    this.infoIconRef.style.left = `${bounds.x}px`;
    this.infoIconRef.style.top = `${bounds.y}px`;
  }

  updatePublishIconPosition(bounds) {
    if (this.publishIconRef) {
      this.publishIconRef.style.left = `${bounds.x + bounds.width}px`;
      this.publishIconRef.style.top = `${bounds.y}px`;
    }
  }

  updateInfoOverlay() {
    if (this.infoShown || this.infoLocked) this.infoOverlay.move();
    this.frames.forEach(frame => frame.updateInfoOverlay());
  }

  updateOnZoom(borderWidth: number, marginTop: number, resizeInfo = true) {
    this.borderWidth = borderWidth;
    this.marginTop = marginTop;
    this.frames.forEach(frame => frame.updateOnZoom(borderWidth, marginTop));
    if (this.infoShown && resizeInfo) this.infoOverlay.resize();
  }

  redraw(width = this.getWidth(), height = this.getHeight()) {
    this.updateBackground(width, height);
    this.updateFrameShadow(width, height);
    this.drawBordersAndCorners(width, height);
    this.updateHitArea(width, height);
  }

  saveInitialSizeAndPosForGroupBy() {
    this.initialHeightForGroupBy = this.getHeight();
    this.initialYPosForGroupBy = this.position.y;
  }

  checkNumImagesChanged(): boolean {
    let numImagesChanged = false;
    for (let i = 0; i < this.images.length; i++) {
      if (!this.images[i]) {
        this.images.splice(i, 1);
        numImagesChanged = true;
        i--;
      }
    }

    return numImagesChanged;
  }

  checkNumFramesChanged(): boolean {
    let numFramesChanged = false;
    for (let i = 0; i < this.frames.length; i++) {
      if (!this.frames[i]) {
        this.frames.splice(i, 1);
        numFramesChanged = true;
        i--;
      }
    }

    return numFramesChanged;
  }

  updateTitleMaxWidth() {
    this.title.updateMaxWidth(this.getFrameBounds().width);
  }

  onPublishIconClick() {
    this.publishIconRef.classList.add('spin');
  }

  onPublishResponse(success: boolean) {
    if (success) {
      this.publishIconState = PublishIconStates.Success;
      this.publishIconRef.classList.remove(...['spin', 'hover', 'default']);
      this.publishIconRef.classList.add(...['success', 'fade-in']);
      setTimeout(() => {
        this.publishIconRef.classList.remove(...['success', 'fade-in']);
        this.publishIconRef.classList.add('fade-out');
        setTimeout(() => {
          this.publishIconState = PublishIconStates.Default;
          this.publishIconRef.classList.remove('fade-out');
          this.publishIconRef.classList.add('default');

          [this, ...this.getAllFrames()].forEach(f => {
            f.hasUnpublishedChanges = false;
          });
          this.updatePublishIcon();
        }, 100);
      }, 1000);
    } else {
      this.publishIconState = PublishIconStates.Fail;
      this.publishIconRef.classList.remove(...['spin', 'hover', 'default']);
      this.publishIconRef.classList.add(...['fail', 'fade-in']);
      setTimeout(() => {
        this.publishIconState = PublishIconStates.Retry;
        this.publishIconRef.classList.remove(...['fail', 'fade-in']);
        this.publishIconRef.classList.add(...['retry', 'fade-in']);
      }, 1000);
    }
  }

  saveCusrosPositionFromAnchor(coordinates) {
    this.cursorPositionFromAnchor = {
      x: coordinates.x - this.savedFrameBoundsOnMatrix.x,
      y: coordinates.y - this.savedFrameBoundsOnMatrix.y
    };
  }

  saveBounds() {
    this.savedFrameBoundsOnMatrix = this.getCustomFrameBounds();
  }

  onResizeEnd(mouseMoveState: CursorStates) {
    this.updateLastResizedAxis(mouseMoveState);
    this.snapToFitFunctions[mouseMoveState]();
    this.resizeParentFrame();
    this.updateHitArea(this.getWidth(), this.getHeight());
    this.updateFrameTitlePositionOnResize();
  }

  checkFramesForImageCollision(imageBounds: Rectangle): FrameContainer {
    let frameCollided;
    if (this.imageCollision(imageBounds)) {
      frameCollided = this;
      this.frames.forEach(frame => {
        const subFrameCollided = frame.checkFramesForImageCollision(imageBounds);
        if (subFrameCollided) frameCollided = subFrameCollided;
      });
    }
    return frameCollided;
  }

  checkFramesForMouseCollision(coordinates: Point): FrameContainer {
    let frameCollided;
    if (this.mouseCollision(coordinates)) {
      frameCollided = this;
      this.frames.forEach(frame => {
        const subFrameCollided = frame?.checkFramesForMouseCollision(coordinates);
        if (subFrameCollided) frameCollided = subFrameCollided;
      });
    }
    return frameCollided;
  }

  checkIfFrameIsSubFrame(id: string): boolean {
    return this.frames.some(item => {
      return item.getId() === id || item.checkIfFrameIsSubFrame(id);
    });
  }

  private imageCollision(imageBounds: Rectangle) {
    const frameBorderBounds = this.getFrameBounds();
    return (
      imageBounds.x + imageBounds.width / 2 >= frameBorderBounds.x &&
      imageBounds.x + imageBounds.width / 2 <= frameBorderBounds.x + frameBorderBounds.width &&
      imageBounds.y + imageBounds.height / 2 >= frameBorderBounds.y &&
      imageBounds.y + imageBounds.height / 2 <= frameBorderBounds.y + frameBorderBounds.height
    );
  }

  public mouseCollision(coordinates: Point) {
    const bounds = this.getFrameBounds();
    return (
      coordinates.x >= bounds.x &&
      coordinates.x <= bounds.x + bounds.width &&
      coordinates.y >= bounds.y &&
      coordinates.y <= bounds.y + bounds.height
    );
  }

  private updateLastResizedAxis(mouseMoveState: CursorStates) {
    this.lastResizedXAxis = mouseMoveState === CursorStates.LeftResize || mouseMoveState === CursorStates.RightResize;
  }

  private createBorderAndCorners(width: number, height: number) {
    this.borderContainer = new Container();
    this.borderRect = new Graphics();
    this.topLeftCorner = new Graphics();
    this.topRightCorner = new Graphics();
    this.bottomRightCorner = new Graphics();
    this.bottomLeftCorner = new Graphics();

    this.borderContainer.addChild(
      this.borderRect,
      this.topLeftCorner,
      this.topRightCorner,
      this.bottomRightCorner,
      this.bottomLeftCorner
    );

    this.addChild(this.borderContainer);

    this.borderContainer.zIndex = 1;

    this.drawBordersAndCorners(width, height);
  }

  private createBackground(width: number, height: number) {
    const bgSprite = new Sprite(Texture.WHITE);
    bgSprite.tint = 0xf3f3f3;
    const bgBorder = new Graphics();
    this.dropshadow = new Graphics();
    this.bg = new Container();
    this.bg.addChild(this.dropshadow, bgSprite, bgBorder);
    this.updateBackground(width, height);
    this.updateFrameShadow(width, height);
    this.addChild(this.bg);
  }

  private updateFrameShadow(width: number, height: number, isActive: boolean = false) {
    this.lastShadowSize = { width, height, isActive };
    this.dropshadow.clear();

    const s = 8; // shift

    this.dropshadow.beginFill(0x000020);
    this.dropshadow.drawPolygon([s, s, width, s, width, height, s, height, s, s]);
    this.dropshadow.endFill();
    this.dropshadow.alpha = 0.5;

    const blurFilter = new BlurFilter();
    let blurValue = isActive ? 6 : 2;
    let qualityValue = 2;
    if (this.zoomFactor > 1) {
      blurValue = Math.round(blurValue * this.zoomFactor);
      if (blurValue < 2) blurValue = 2;
      if (blurValue > 50) blurValue = 50;

      qualityValue = blurValue / 2;
      if (qualityValue < 3) qualityValue = 3;
      if (qualityValue > 20) qualityValue = 20;
    }
    blurFilter.quality = qualityValue;
    blurFilter.blur = blurValue;

    // @ts-ignore
    this.dropshadow.filters = [blurFilter];
  }

  public updateDropShadowOnDragStart() {
    this.updateFrameShadow(this.getWidth(), this.getHeight(), true);
  }

  public updateDropShadowOnDragEnd() {
    this.updateFrameShadow(this.getWidth(), this.getHeight());
  }

  public updateDropShadowOnResizeEnd(dropShadowService: DropShadowService) {
    this.updateDropShadowOnDragEnd();
  }

  private onDrag(coordinates) {
    this.position.set(coordinates.x - this.cursorPositionFromAnchor.x, coordinates.y - this.cursorPositionFromAnchor.y);

    this.updateFrameTitlePosition();
  }

  private onLeftResize(coordinates) {
    const newWidth = Math.max(
      this.savedFrameBoundsOnMatrix.x + this.savedFrameBoundsOnMatrix.width - coordinates.x,
      this.imageSize + this.pad * 2
    );
    coordinates.x = this.savedFrameBoundsOnMatrix.xEnd - newWidth;

    const pos = this.getCoordinatesOnMatrix();
    this.updatePositionOnResize(coordinates.x - pos.x, 0);
    this.resize(newWidth, this.savedFrameBoundsOnMatrix.height, true);
    this.updateHeightAfterResize(newWidth);
    this.resizeParentFrame();
  }

  private onTopResize(coordinates) {
    const newHeight = Math.max(
      this.savedFrameBoundsOnMatrix.y + this.savedFrameBoundsOnMatrix.height - coordinates.y,
      this.imageSize + this.pad * 2
    );
    coordinates.y = this.savedFrameBoundsOnMatrix.yEnd - newHeight;

    const pos = this.getCoordinatesOnMatrix();
    this.updatePositionOnResize(0, coordinates.y - pos.y);
    this.resize(this.savedFrameBoundsOnMatrix.width, newHeight, true, true);
    this.updateWidthAfterResize(newHeight);
    this.resizeParentFrame();
  }

  private onRightResize(coordinates) {
    const offset = coordinates.x - this.savedFrameBoundsOnMatrix.x - this.cursorPositionFromAnchor.x;
    const newWidth = Math.max(this.savedFrameBoundsOnMatrix.width + offset, this.imageSize + this.pad * 2);

    this.position.set(this.savedFrameBoundsOnMatrix.x, this.savedFrameBoundsOnMatrix.y);
    this.resize(newWidth, this.savedFrameBoundsOnMatrix.height, true);
    this.updateHeightAfterResize(newWidth);
    this.updateFrameTitlePositionOnResize();
    this.resizeParentFrame();
  }

  private onBottomResize(coordinates) {
    const offset = coordinates.y - this.savedFrameBoundsOnMatrix.y - this.cursorPositionFromAnchor.y;
    const newHeight = Math.max(this.savedFrameBoundsOnMatrix.height + offset, this.imageSize + this.pad * 2);

    this.position.set(this.savedFrameBoundsOnMatrix.x, this.savedFrameBoundsOnMatrix.y);
    this.resize(this.savedFrameBoundsOnMatrix.width, newHeight, true, true);
    this.updateWidthAfterResize(newHeight);
    this.updateFrameTitlePositionOnResize();
    this.resizeParentFrame();
  }

  public resize(width: number, height: number, updateImages: boolean, yAxis?: boolean) {
    this.drawBordersAndCorners(width, height);
    this.updateHitArea(width, height);
    this.updateBackground(width, height);
    this.updateFrameShadow(width, height);
    this.updateTitleMaxWidth();
    if (updateImages) this.updateImageCoordinatesOnGrid(yAxis);
  }

  private resizeParentFrame() {
    if (this.parent instanceof FrameContainer) {
      this.parent.grid(false);
      this.parent.resizeParentFrame();
    }
  }

  private updatePositionOnResize(offsetX: number, offsetY: number) {
    if (this.parent instanceof FrameContainer) {
      this.parent.updatePositionOnResize(offsetX, offsetY);
    } else {
      this.x += offsetX;
      this.y += offsetY;
      this.updateFrameTitlePosition();
    }
  }

  private updateFrameTitlePositionOnResize() {
    if (this.parent instanceof FrameContainer) {
      this.parent.updateFrameTitlePositionOnResize();
    } else {
      this.updateFrameTitlePosition();
    }
  }

  private snapToFitLeft() {
    this.updatePad();
    if (this.numImagesOnRow > this.images.length) this.numImagesOnRow = this.images.length || 1;
    const width = Math.max(
      2 * this.pad + this.numImagesOnRow * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages,
      this.imageSize + this.pad * 2
    );
    this.updatePositionOnResize(this.getWidth() - width, 0);
    this.resize(width, this.getHeight(), false);
  }

  private snapToFitRight() {
    this.updatePad();
    if (this.numImagesOnRow > this.images.length) this.numImagesOnRow = this.images.length || 1;
    const width = Math.max(
      2 * this.pad + this.numImagesOnRow * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages,
      this.imageSize + this.pad * 2
    );
    this.resize(width, this.getHeight(), false);
    this.updateFrameTitlePositionOnResize();
  }

  private snapToFitTop() {
    this.updatePad();
    if (this.numImagesOnCol > this.images.length) this.numImagesOnCol = this.images.length || 1;
    const height = Math.max(
      2 * this.pad + this.numImagesOnCol * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages,
      this.imageSize + this.pad * 2
    );
    this.updatePositionOnResize(0, this.getHeight() - height);
    this.resize(this.getWidth(), height, false, true);
  }

  private snapToFitBottom() {
    this.updatePad();
    if (this.numImagesOnCol > this.images.length) this.numImagesOnCol = this.images.length || 1;
    const height = Math.max(
      2 * this.pad + this.numImagesOnCol * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages,
      this.imageSize + this.pad * 2
    );
    this.resize(this.getWidth(), height, false, true);
    this.updateFrameTitlePositionOnResize();
  }

  private drawBordersAndCorners(width: number, height: number) {
    this.borderRect.clear().lineStyle(0, 0x04d7b9).drawRect(0, 0, width, height);

    const strokeWidth = 2 * this.borderWidth;
    const halfStrokeWidth = 1.5 * this.borderWidth;
    const strokeLength = 8 * this.borderWidth;

    this.topLeftCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(-halfStrokeWidth, strokeLength - halfStrokeWidth)
      .lineTo(-halfStrokeWidth, -halfStrokeWidth)
      .lineTo(strokeLength - halfStrokeWidth, -halfStrokeWidth);
    this.topRightCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(width - strokeLength + halfStrokeWidth, -halfStrokeWidth)
      .lineTo(width + halfStrokeWidth, -halfStrokeWidth)
      .lineTo(width + halfStrokeWidth, strokeLength - halfStrokeWidth);
    this.bottomRightCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(width + halfStrokeWidth, height - strokeLength + halfStrokeWidth)
      .lineTo(width + halfStrokeWidth, height + halfStrokeWidth)
      .lineTo(width - strokeLength + halfStrokeWidth, height + halfStrokeWidth);
    this.bottomLeftCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(strokeLength - halfStrokeWidth, height + halfStrokeWidth)
      .lineTo(-halfStrokeWidth, height + halfStrokeWidth)
      .lineTo(-halfStrokeWidth, height - strokeLength + halfStrokeWidth);
  }

  private updateBackground(width: number, height: number) {
    (this.bg.children[1] as Sprite).width = width;
    (this.bg.children[1] as Sprite).height = height;
    (this.bg.children[2] as Graphics).clear().lineStyle(0, 0x000000).drawRect(0, 0, width, height);
    (this.bg.children[2] as Graphics).alpha = 0.2;
    this.bg.position.set(0, 0);
  }

  public updateHitArea(width: number, height: number) {
    const offset = 2.5 * this.borderWidth;
    const halfWidth = 8 * this.borderWidth;

    const titleW = this.title?.titleHtmlRef ? (this.title.titleHtmlRef.clientWidth / 1.5) * this.borderWidth : 0;
    const titleH = this.title?.titleHtmlRef ? (this.title.titleHtmlRef.clientHeight / 1.5) * this.borderWidth : 0;

    this.hitArea = this.publishIconVisible
      ? new Polygon(
          halfWidth,
          -offset,
          width - halfWidth,
          -offset,
          width - halfWidth,
          -halfWidth,
          width + halfWidth,
          -halfWidth,
          width + halfWidth,
          +halfWidth,
          width + offset,
          +halfWidth,
          width + offset,
          height + offset,
          -offset,
          height + offset,
          -offset,
          halfWidth,
          -halfWidth,
          halfWidth,
          -halfWidth,
          -halfWidth,
          halfWidth,
          -halfWidth,
          halfWidth,
          -titleH,
          halfWidth + titleW,
          -titleH,
          halfWidth + titleW,
          -offset
        )
      : new Polygon(
          halfWidth,
          -offset,
          width + offset,
          -offset,
          width + offset,
          height + offset,
          -offset,
          height + offset,
          -offset,
          halfWidth,
          -halfWidth,
          halfWidth,
          -halfWidth,
          -halfWidth,
          halfWidth,
          -halfWidth,
          halfWidth,
          -titleH,
          halfWidth + titleW,
          -titleH,
          halfWidth + titleW,
          -offset
        );
  }

  public updateFrameInfoText(hoverInfoConfig: IHoverInfoFieldModel[], customFormattings: IMetricsCustomFormattings) {
    const imagesData = this.getAllImages().map(imageFrame => imageFrame.getData());
    const friendlyNameMap = this.friendlyNameService.isShowOriginalHeaders
      ? new Map()
      : this.friendlyNameService.friendlyNamesMap;

    const imagesDataIDs = imagesData.map(item => item.prodId).join('|');
    const customFormattingsJSON = JSON.stringify(customFormattings);

    if (
      this.lastHoverInfoConfig !== hoverInfoConfig ||
      this.lastCustomFormattings !== customFormattingsJSON ||
      this.lastImagesDataIDs !== imagesDataIDs ||
      !this.hoverInfo
    ) {
      this.lastCustomFormattings = customFormattingsJSON;
      this.lastImagesDataIDs = imagesDataIDs;
      if (hoverInfoConfig.length === 0) {
        this.updateHoverInfo([]);
      } else {
        this.updateHoverInfo(null);
        this.dataService.getFrameAggregatedHoverInfo(imagesData, 0, hoverInfo => {
          if (hoverInfo !== null) {
            this.lastHoverInfoConfig = hoverInfoConfig;
          }
          this.updateHoverInfo(hoverInfo);
        });
      }
    } else {
      this.refreshInfoOverlay();
    }
  }

  // update InfoOverlay with correct scale for labels
  private refreshInfoOverlay() {
    if (this.infoShown) {
      // Hide -> Resize -> Show :: to show HoverInfo with correct Scale
      this.infoOverlay.hide(false);
      this.infoOverlay.resize();
      this.infoOverlay.show(false);
    }
  }

  private updateHoverInfo(hoverInfo: IHoverInfoField[]) {
    this.hoverInfo = hoverInfo;
    this.infoOverlay.updateText(hoverInfo);
    this.refreshTitle();
    this.refreshInfoOverlay();
  }

  private updateFrameInfoTitle(event: IFrameTitleChangeEvent) {
    this.infoOverlay.updateText(this.hoverInfo, event);
    if (this.infoShown) {
      this.infoOverlay.show(false);
    }
    this.updateHitArea(this.getWidth(), this.getHeight());
  }

  private updateFrameTitleState(event: IFrameTitleChangeEvent) {
    this.featureName = event.featureName;
    this.featureValue = event.featureValue;
    this.emit('TitleChange', {
      id: this.id,
      featureName: this.featureName,
      featureValue: this.featureValue
    });
  }

  private updateNumImagesOnTitle() {
    if (this.title) {
      this.title.updateNumImagesText(this.getNumImages());
    }
  }

  public saveHtmlElementsVisibleState() {
    this.htmlElementsVisibleTemp = this.htmlElementsVisible;
  }

  public updateHtmlElements(visible: boolean) {
    this.htmlElementsVisible = visible;
    this.updateTitleVisibility();
    this.updatePublishIconVisibility();
    this.updateInfoIconVisibility();

    this.frames.forEach(f => f?.updateHtmlElements(visible));
  }

  private updateTitleVisibility() {
    this.title.titleHtmlRef.style.display = this.htmlElementsVisible ? 'flex' : 'none';
  }

  private updateInfoIconVisibility() {
    this.infoIconRef.style.display = this.active && this.htmlElementsVisible ? 'flex' : 'none';
    this.topLeftCorner.alpha = this.active && this.htmlElementsVisible ? 0 : 1;
  }

  private updatePublishIconVisibility() {
    if (this.publishIconRef)
      this.publishIconRef.style.display = this.publishIconVisible && this.htmlElementsVisible ? 'flex' : 'none';
    this.topRightCorner.alpha = this.publishIconVisible && this.htmlElementsVisible ? 0 : 1;
  }

  public updatePublishIcon() {
    this.publishIconVisible = this.isParentFrame && this.hasUnpublishedChanges && this.checkAllFramesForFeatureValue();
    this.updatePublishIconVisibility();
    this.updateHitArea(this.getWidth(), this.getHeight());
  }

  public checkAllFramesForFeatureValue() {
    return this.frames.length > 0
      ? !!this.featureValue && this.frames.every(f => f.checkAllFramesForFeatureValue())
      : !!this.featureValue;
  }

  public checkUnpublishedChanges(data: any) {
    this.frames.forEach(f => f.checkUnpublishedChanges(data));
    const childrenHaveUnpublishedChanges = this.frames.some(f => f.hasUnpublishedChanges);
    this.hasUnpublishedChanges = true;
    let containsAll = false;
    const images = this.getAllImages();
    const prodIDs = data[this.featureName] && data[this.featureName][this.featureValue];
    if (prodIDs) {
      containsAll = images
        .map(image => image.getId())
        .every(id => {
          return prodIDs.includes(id);
        });
    }
    this.hasUnpublishedChanges = !containsAll || childrenHaveUnpublishedChanges;
    this.updatePublishIcon();
  }

  private updateImageCoordinatesOnGrid(yAxis?: boolean) {
    this.updatePad();

    const frameWidth = (this.bg.children[1] as Sprite).width;
    const frameHeight = (this.bg.children[1] as Sprite).height;

    if (yAxis) {
      this.numImagesOnCol =
        Math.floor(
          (frameHeight - 2 * this.pad + this.distanceBetweenImages) / (this.imageSize + this.distanceBetweenImages)
        ) || 1;
      this.numImagesOnRow = Math.ceil(this.images.length / this.numImagesOnCol) || 1;
    } else {
      this.numImagesOnRow =
        Math.floor(
          (frameWidth - 2 * this.pad + this.distanceBetweenImages) / (this.imageSize + this.distanceBetweenImages)
        ) || 1;
      this.numImagesOnCol = Math.ceil(this.images.length / this.numImagesOnRow) || 1;
    }

    this.images.forEach((imageCont, i) => {
      this.addChild(imageCont);
      let x;
      let y;
      if (yAxis) {
        y = this.pad + (i % this.numImagesOnCol) * (this.imageSize + this.distanceBetweenImages) + this.imageSize / 2;
        x =
          this.pad +
          Math.floor(i / this.numImagesOnCol) * (this.imageSize + this.distanceBetweenImages) +
          this.imageSize / 2;
      } else {
        x = this.pad + (i % this.numImagesOnRow) * (this.imageSize + this.distanceBetweenImages) + this.imageSize / 2;
        y =
          this.pad +
          Math.floor(i / this.numImagesOnRow) * (this.imageSize + this.distanceBetweenImages) +
          this.imageSize / 2;
      }

      imageCont.position.set(x, y);
    });
  }

  private updateHeightAfterResize(newWidth: number) {
    this.updatePad();
    const newHeight =
      2 * this.pad + this.numImagesOnCol * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages;

    this.drawBordersAndCorners(newWidth, newHeight);
    this.updateBackground(newWidth, newHeight);
    this.updateFrameShadow(newWidth, newHeight);
    this.updateHitArea(newWidth, newHeight);
    this.updateTitleMaxWidth();
  }

  private updateWidthAfterResize(newHeight: number) {
    this.updatePad();
    const newWidth =
      2 * this.pad + this.numImagesOnRow * (this.imageSize + this.distanceBetweenImages) - this.distanceBetweenImages;

    this.drawBordersAndCorners(newWidth, newHeight);
    this.updateBackground(newWidth, newHeight);
    this.updateFrameShadow(newWidth, newHeight);
    this.updateHitArea(newWidth, newHeight);
    this.updateTitleMaxWidth();
  }

  getTitleHtmlRef() {
    return this.title.getHtmlRef();
  }

  getBackgroundSprite() {
    return this.bg.children[1] as Sprite;
  }

  getFrameBounds() {
    return this.bg.getBounds();
  }

  getWidth() {
    return (this.bg.children[1] as Sprite).width;
  }

  getHeight() {
    return (this.bg.children[1] as Sprite).height;
  }

  getCustomFrameBounds() {
    const posOnMatrix = this.getCoordinatesOnMatrix();
    return {
      x: posOnMatrix.x,
      y: posOnMatrix.y,
      width: this.bg.width,
      height: this.bg.height,
      xEnd: posOnMatrix.x + this.bg.width,
      yEnd: posOnMatrix.y + this.bg.height
    };
  }

  getInfoOverlay() {
    return this.infoOverlay;
  }

  getRestoreInfoState() {
    return this.restoreInfo;
  }

  getInfoIconActiveState() {
    return this.infoIconActiveState;
  }

  getImages() {
    return this.images || [];
  }

  getAllImages() {
    const images = [...this.images];
    this.frames.forEach(item => {
      images.push(...item.getAllImages());
    });
    return images;
  }

  getNumImages(): number {
    let numImages = this.images.length;
    this.frames.forEach(frame => {
      numImages += frame.getNumImages();
    });
    return numImages;
  }

  getFrames(): FrameContainer[] {
    return this.frames;
  }

  getAllFrames(): FrameContainer[] {
    const frames = [...this.frames];
    this.frames.forEach(frame => {
      frames.push(...frame.getAllFrames());
    });
    return frames;
  }

  getId() {
    return this.id;
  }

  getFeatureName() {
    return this.featureName;
  }

  getFeatureValue() {
    return this.featureValue;
  }

  getPositionFromWorldCenter(): IPosition {
    return this.posFromWorldCenter;
  }

  getInfoLockedState() {
    return this.infoLocked;
  }

  getInfoShownState() {
    return this.infoShown;
  }

  getMouseMoveState(x, y): CursorStates {
    const frameBounds = this.getFrameBounds();
    const pad = 6;
    const iconRad = 12;

    const top = frameBounds.y + pad;
    const right = frameBounds.x + frameBounds.width - pad;
    const bottom = frameBounds.y + frameBounds.height - pad;
    const left = frameBounds.x + pad;

    if (this.frames.length > 0 || !this.resizeEnabled) {
      return x < left - pad + iconRad && y < top - pad + iconRad && this.htmlElementsVisible
        ? CursorStates.FrameInfo
        : x > right + pad - iconRad && y < top - pad + iconRad && this.publishIconVisible && this.htmlElementsVisible
        ? CursorStates.PublishIcon
        : CursorStates.Drag;
    }

    return x < left - pad + iconRad && y < top - pad + iconRad && this.htmlElementsVisible
      ? CursorStates.FrameInfo
      : x > right + pad - iconRad && y < top - pad + iconRad && this.publishIconVisible && this.htmlElementsVisible
      ? CursorStates.PublishIcon
      : y < top - pad - 5
      ? CursorStates.Drag
      : x < left
      ? CursorStates.LeftResize
      : y < top
      ? CursorStates.TopResize
      : x > right
      ? CursorStates.RightResize
      : y > bottom
      ? CursorStates.BottomResize
      : /* inside frame */ CursorStates.Drag;
  }

  checkForInfoClick(event: InteractionEvent, frameBounds: IBounds) {
    const iconRad = 12;
    const { x } = event.data.global;
    const { y } = event.data.global;

    return x < frameBounds.x + iconRad && y < frameBounds.y + iconRad;
  }

  checkForPublishClick(event: InteractionEvent, frameBounds: IBounds) {
    if (!this.publishIconVisible) return false;

    const iconRad = 12;
    const { x } = event.data.global;
    const { y } = event.data.global;

    return x > frameBounds.x + frameBounds.width - iconRad && y < frameBounds.y + iconRad;
  }

  checkForTitleClick(event: InteractionEvent, frameBounds: IBounds) {
    const { x } = event.data.global;
    const { y } = event.data.global;

    if (
      x > frameBounds.x + 12 &&
      x < frameBounds.x + 12 + this.title.featureNameHtmlRef.clientWidth &&
      y > frameBounds.y - this.title.featureNameHtmlRef.clientHeight &&
      y < frameBounds.y
    ) {
      this.title.featureNameHtmlRef.focus();
      return;
    }

    if (
      x > frameBounds.x + 12 + this.title.featureValueHtmlRef.offsetLeft &&
      x < frameBounds.x + 12 + this.title.featureValueHtmlRef.offsetLeft + this.title.featureValueHtmlRef.clientWidth &&
      y > frameBounds.y - this.title.featureValueHtmlRef.clientHeight &&
      y < frameBounds.y
    ) {
      this.title.featureValueHtmlRef.focus();
    }
  }

  getCoordinatesOnMatrix(): { x: number; y: number } {
    const pos = { x: this.x, y: this.y };
    if (this.parent instanceof FrameContainer) {
      const parentPos = this.parent.getCoordinatesOnMatrix();
      pos.x += parentPos.x;
      pos.y += parentPos.y;
    }
    return pos;
  }

  setPositionFromWorldCenter(x, y) {
    this.posFromWorldCenter = { x, y };
  }

  setRestoreInfoState(restore: boolean) {
    this.restoreInfo = restore;
  }

  setInfoIconActiveState(active: boolean) {
    this.infoIconActiveState = active;
    if (this.infoIconActiveState) {
      this.infoIconRef.classList.add('active');
    } else {
      this.infoIconRef.classList.remove('active');
    }
  }

  setInfoShownState(show: boolean) {
    this.infoShown = show;
  }

  setInfoLockedState(locked: boolean) {
    this.infoLocked = locked;
  }

  setInteractivity(interactive: boolean) {
    this.interactive = interactive;
    this.interactiveChildren = interactive;
    this.frames.forEach(frame => frame.setInteractivity(interactive));
  }

  updatePad() {
    this.pad = (this.imageSize + this.distanceBetweenImages) / 2;
  }

  delete() {
    this.removeAllListeners();
    this.removeChildren();
    this.deleteHtmlElements();
    this.borderContainer = null;
    this.borderRect = null;
    this.topRightCorner = null;
    this.bottomRightCorner = null;
    this.bottomLeftCorner = null;
    this.bg = null;
    this.images = null;
  }

  deleteHtmlElements() {
    this.removeInfoOverlay();
    this.removeInfoIcon();
    this.removePubishIcon();
    this.title?.delete();
    this.title = null;
  }

  public savedPropertiesForDrag;
  savePropertiesForImageDrag() {
    const posOnMatrix = this.getCoordinatesOnMatrix();
    this.savedPropertiesForDrag = {
      numImagesOnRow: this.numImagesOnRow,
      numImagesOnCol: this.numImagesOnCol,
      pad: this.pad,
      imageCellX: this.imageCellX,
      imageCellY: this.imageCellY,
      xOnMatrix: posOnMatrix.x,
      yOnMatrix: posOnMatrix.y
    };
  }
}
