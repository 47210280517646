import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigDialogComponent } from '@components/build/config-dialog/config-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { matExpantionPanelAnimation } from '@app/components/animations/animations';
import { MatExpansionPanel } from '@angular/material/expansion';
import { WINDOW } from '@app/shared/utils/window';
import { Select, Store } from '@ngxs/store';
import { ICollage } from '@app/state/collage/collage.model';
import { Observable } from 'rxjs';
import { IStudy } from '@app/state/study/study.model';
import { filter, switchMap, take } from 'rxjs/operators';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { StudyState } from '@app/state/study/study.state';
import { CollageState } from '@app/state/collage/collage.state';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CreateCollage } from '@app/state/collage/collage.actions';
import { ResetActiveStudy } from '@app/state/study/study.actions';
import { StudyUtils } from '@app/utils/study.utils';
import { AuthService } from '@app/services/auth/auth.service';
import { IUserData } from '@app/models/user-data.model';
import { CreateNewStudyDialogComponent } from '../create-new-study-dialog/create-new-study-dialog.component';
import { User } from '@angular/fire/auth';
import { shortUid } from '../../../shared/utils/short-uid';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [matExpantionPanelAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  @ViewChild('expansionPanel', { static: true })
  public expansionPanel: MatExpansionPanel;

  @ViewChild('menuContent', { static: true })
  private readonly menuContent: ElementRef;

  public showLeftmenu = false;

  @Select(StudyState.getActiveStudyId)
  public activeStudyId$: Observable<string | null>;
  @Select(CollageState.getActiveCollageId)
  public activeCollageId$: Observable<string | null>;

  public collageList$: Observable<Array<ICollage>>;
  public studyList$: Observable<Array<IStudy>>;

  private currentUser: User;

  private readonly documentClickRef = this.onDocumentClick.bind(this);

  constructor(
    private readonly dialog: MatDialog,
    @Inject(WINDOW)
    private readonly window: Window,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private projectDataService: ProjectDataService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.studyList$ = this.projectDataService.getFiveLastStudies();
    this.collageList$ = this.projectDataService.getCollageList();
    this.route.queryParams.pipe(take(1)).subscribe((queryParams: Params) => {
      if (Object.keys(queryParams).length === 0) {
        this.router.navigate(['/home']);
      } else if (queryParams.createCollage) {
        this.addCollage(true);
      } else if (queryParams.createStudy) {
        this.addStudy(true);
      }
    });
    this.currentUser = this.authService.currentUser;
  }

  public ngOnDestroy(): void {
    this.close();
  }

  public selectStudy(study: IStudy): void {
    this.close();
    this.store
      .dispatch(new ResetActiveStudy())
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate([], { queryParams: { studyId: study.id } });
      });
  }

  public selectCollage(collage: ICollage): void {
    this.close();
    // the same logic, as for HomeCollageCardComponent
    const newStudy: IStudy = StudyUtils.create(collage, this.currentUser);
    this.projectDataService.createStudy(newStudy).then(() => {
      this.router.navigate(['/build'], { queryParams: { studyId: newStudy.id } });
    });
  }

  private onDocumentClick(e: MouseEvent) {
    if (this.menuContent.nativeElement !== e.target && !this.menuContent.nativeElement.contains(e.target)) {
      this.close();
    }
  }

  public onOpened() {
    setTimeout(() => {
      // timeout is workaround
      this.window.document.addEventListener('click', this.documentClickRef);
    });
  }

  public addStudy(isNavigateToHomeForBack: boolean = false): void {
    this.close();
    this.projectDataService
      .getCurrentUserData()
      .pipe(
        filter(Boolean),
        take(1),
        switchMap((currentUserData: IUserData) => {
          const dialogRef = this.dialog.open(CreateNewStudyDialogComponent, {
            panelClass: 'wide-dialog',
            disableClose: true,
            data: {
              currentUserData
            }
          });
          return dialogRef.afterClosed();
        }),
        take(1)
      )
      .subscribe((collage: ICollage) => {
        if (collage) {
          this.selectCollage(collage);
        } else if (isNavigateToHomeForBack) {
          this.router.navigate(['/home']);
        }
      });
  }

  public addCollage(isNavigateToHomeForBack: boolean = false): void {
    this.close();
    this.projectDataService
      .getCurrentUserData()
      .pipe(
        filter(Boolean),
        take(1),
        switchMap((currentUserData: IUserData) => {
          const dialogRef = this.dialog.open(ConfigDialogComponent, {
            panelClass: 'wide-dialog',
            disableClose: true,
            data: {
              currentUserData
            }
          });
          return dialogRef.afterClosed();
        }),
        take(1)
      )
      .subscribe((collage: ICollage) => {
        if (collage) {
          // if current page Build - we need create pair: Collage + Study
          // for Home page we create only Collage
          const isCreateNewStudy = this.router.url.indexOf('/build') === 0;
          this.store.dispatch(new CreateCollage(collage, isCreateNewStudy));
        } else if (isNavigateToHomeForBack) {
          this.router.navigate(['/home'], { queryParams: { menu: 'Collages', studyId: null, query: null } });
        }
      });
  }

  public close(): void {
    this.expansionPanel.close();
    this.showLeftmenu = false;
    if (this.documentClickRef) {
      this.window.document.removeEventListener('click', this.documentClickRef);
    }
  }
}
