import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import gsap from 'gsap/all';
import { IHoverInfoField } from '@app/models/image.model';
import { IBounds } from '@app/models/bounds.model';

export abstract class InfoOverlayBase {
  private readonly fontSize = {
    title: 20,
    fieldName: 16,
    fieldValue: 18
  };

  private readonly lineHeight = {
    title: 23.5,
    fieldName: 19,
    fieldValue: 21
  };

  protected infoHtmlRef: HTMLDivElement;

  protected wrapperHeight: number;
  protected wrapperWidth: number;

  constructor(private cssClass: string) {
    this.infoHtmlRef = document.createElement('div');
    this.infoHtmlRef.classList.add(this.cssClass);
    this.hide(false);
    getMatrixOverlayContainer().appendChild(this.infoHtmlRef);
  }

  protected abstract getBounds(): IBounds;
  public abstract updateText(hoverInfo: Array<IHoverInfoField>, ...args): void;
  public abstract resize(infoShown?: boolean, imageSize?: number): void;

  public show(animate: boolean): void {
    gsap.killTweensOf(this.infoHtmlRef);
    const bounds = this.getBounds();
    this.setSizeAndPosition(bounds);
    this.infoHtmlRef.style.display = 'flex';
    const infoWrapper = this.infoHtmlRef.querySelector(`.${this.cssClass}-wrapper`) as HTMLDivElement;
    this.wrapperHeight = infoWrapper.offsetHeight;
    this.wrapperWidth = infoWrapper.offsetWidth;
    this.scaleWrapper();
    if (animate) {
      gsap.to(this.infoHtmlRef, { duration: 0.25, opacity: 1 });
    } else {
      this.infoHtmlRef.style.opacity = '1';
    }
  }

  public hide(animate: boolean): void {
    gsap.killTweensOf(this.infoHtmlRef);
    if (animate) {
      gsap.to(this.infoHtmlRef, {
        duration: 0.25,
        opacity: 0,
        onComplete: () => {
          this.moveOutOfScreen();
          this.infoHtmlRef.style.display = `none`;
        }
      });
    } else {
      this.infoHtmlRef.style.display = `none`;
      this.infoHtmlRef.style.opacity = '0';
      this.moveOutOfScreen();
    }
  }

  public move(): void {
    const bounds = this.getBounds();
    this.infoHtmlRef.style.left = `${bounds.x}px`;
    this.infoHtmlRef.style.top = `${bounds.y}px`;
  }

  public remove(): void {
    const overlay = getMatrixOverlayContainer();
    if (overlay.contains(this.infoHtmlRef)) overlay.removeChild(this.infoHtmlRef);
  }

  private moveOutOfScreen() {
    this.infoHtmlRef.style.width = `0px`;
    this.infoHtmlRef.style.height = `0px`;
    this.infoHtmlRef.style.left = `-1000px`;
    this.infoHtmlRef.style.top = `-1000px`;
  }

  protected scaleWrapper(): void {
    const widthScale = this.wrapperWidth === 0 ? 0 : this.infoHtmlRef.offsetWidth / this.wrapperWidth - 0.1;
    const heightScale = this.wrapperHeight === 0 ? 0 : this.infoHtmlRef.offsetHeight / this.wrapperHeight - 0.1;
    const scale = widthScale < heightScale ? widthScale : heightScale;
    const frameInfoWrapper = this.infoHtmlRef.querySelector(`.${this.cssClass}-wrapper`) as HTMLDivElement;
    if (scale < 1) {
      if (this.fontSize.fieldName * scale < 5) {
        frameInfoWrapper.style.display = 'none';
      } else {
        frameInfoWrapper.style.display = 'flex';
        this.setTextScale(scale);
      }
    } else {
      frameInfoWrapper.style.display = 'flex';
      this.setTextScale();
    }
  }

  protected setSizeAndPosition(bounds: IBounds): void {
    this.infoHtmlRef.style.width = `${bounds.width}px`;
    this.infoHtmlRef.style.height = `${bounds.height}px`;
    this.infoHtmlRef.style.left = `${bounds.x}px`;
    this.infoHtmlRef.style.top = `${bounds.y}px`;
  }

  private setTextScale(scale: number = 1): void {
    const title = this.infoHtmlRef.querySelector('.title') as HTMLParagraphElement;
    const fieldName = this.infoHtmlRef.querySelectorAll('.field-name') as NodeListOf<HTMLParagraphElement>;
    const fieldValue = this.infoHtmlRef.querySelectorAll('.field-value') as NodeListOf<HTMLParagraphElement>;
    const calculatingMessage = this.infoHtmlRef.querySelectorAll(
      '.calculating-message'
    ) as NodeListOf<HTMLParagraphElement>;

    title.style.fontSize = `${this.fontSize.title * scale}px`;
    title.style.lineHeight = `${this.lineHeight.title * scale}px`;
    fieldName.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldName * scale}px`;
      item.style.lineHeight = `${this.lineHeight.fieldName * scale}px`;
    });
    fieldValue.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldValue * scale}px`;
      item.style.lineHeight = `${this.lineHeight.fieldValue * scale}px`;
    });
    calculatingMessage.forEach(item => {
      item.style.fontSize = `${this.fontSize.fieldName * scale}px`;
      item.style.lineHeight = `${this.lineHeight.fieldName * scale}px`;
    });
  }
}
