import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ILoaderEvent {
  subject: string; // Subject of Event - for example, 'ResourceImages' or 'FilterData'
  isShow: boolean; // show/hide loader
  isShowTitleImmediately: boolean; // usually, need to wait timeout 3s and only then show the Title. If this flag = true, the Title will be shown immediately
  title?: string; // show loader with some title
  closeAfter?: number; // if present, loader will be automatically closed after specified time (in ms, 1000 = 1 sec)
}

export interface ILoader {
  subject: string;
  title: string;
  firstStart: number; // DateTime in ms
  startedAt: number; // DateTime in ms
  // must be closed automatically after specified time (ms),
  // i.e. startedAt + closeAfter = timeToStop
  closeAfter: number;
  timer?: any; // handle for setTimeout() to clear previous timer
}

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  private showLoaderSubject = new BehaviorSubject({
    isShow: false,
    title: null,
    closeAfter: null
  } as ILoaderEvent);
  showLoader$ = this.showLoaderSubject.asObservable();

  activeLoaders: Array<ILoader> = [];

  constructor() {}

  getActiveLoaders(): Array<ILoader> {
    return [...this.activeLoaders];
  }

  isActiveLoader(subject: string): boolean {
    return !!this.activeLoaders.find(item => item.subject === subject);
  }

  getLoader(subject: string): ILoader {
    return this.activeLoaders.find(item => item.subject === subject);
  }

  getLoaders(subjects: string[]): Array<ILoader> {
    return this.activeLoaders.filter(item => subjects.some(subject => subject === item.subject)) || [];
  }

  setShowLoader(
    subject: string,
    isShow: boolean,
    title: string = null,
    closeAfter: number = null,
    isShowTitleImmediately: boolean = false
  ) {
    const event: ILoaderEvent = {
      subject,
      isShow,
      title,
      closeAfter,
      isShowTitleImmediately
    };
    const now = new Date().getTime();

    let activeLoader = this.activeLoaders.find(item => item.subject === event.subject);
    if (isShow) {
      // show loader(s)
      if (activeLoader) {
        // update only title for existing Loader
        activeLoader.title = event.title;

        if (!event.closeAfter) {
          this.showLoaderSubject.next(event); // update only title in the Loader component
          return;
        }

        if (
          event.closeAfter &&
          activeLoader.closeAfter &&
          activeLoader.timer &&
          activeLoader.startedAt + activeLoader.closeAfter > now + event.closeAfter
        ) {
          // previous timer has bigger timeout, so waiting for previous timer instead of new
          this.showLoaderSubject.next(event); // update only title in the Loader component
          return;
        }

        // need to use new timer, so remember new Start...
        activeLoader.startedAt = now;
        activeLoader.closeAfter = event.closeAfter || null;
        if (activeLoader.timer) {
          clearTimeout(activeLoader.timer); // clear old timer
        }
      } else {
        // add new active Loader
        activeLoader = {
          subject: event.subject,
          title: event.title,
          firstStart: now,
          startedAt: now,
          closeAfter: event.closeAfter || null
        };
        this.activeLoaders.push(activeLoader);
      }

      // -- good for debug
      // --> console.log('Loader.activeLoader', event, activeLoader);

      if (closeAfter) {
        activeLoader.timer = setTimeout(() => {
          this.closeLoader(event);
        }, closeAfter);
      } else {
        activeLoader.timer = null;
      }
      this.showLoaderSubject.next(event);
    } else {
      this.closeLoader(event);
    }
  }

  private closeLoader(event: ILoaderEvent) {
    // close only loader with Subject
    const activeLoader = this.activeLoaders.find(item => item.subject === event.subject);
    if (activeLoader) {
      this.removeActiveLoader(activeLoader);
    }

    // send Event that loader closed
    this.showLoaderSubject.next({
      ...event,
      isShow: false
    });
  }

  private removeActiveLoader(loader: ILoader) {
    // -- good for debug
    // --> console.log('Loader.closeLoader', loader, new Date().getTime() - loader.startedAt); // how long time loader was visible
    const index = this.activeLoaders.findIndex(item => item.subject === loader.subject);
    if (index >= 0) {
      this.activeLoaders.splice(index, 1);
    }
  }
}
