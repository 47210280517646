import { IFiltersModel } from '@app/models/filters.model';
import {
  GroupOrientation,
  IHoverInfoFieldModel,
  IMetricsCustomFormattings,
  IRankInfoPanelConfig
} from '@app/models/study-setting.model';
import {
  IWorkspace,
  IAxesShelfModel,
  IFrameStateModel,
  IImageStateModel,
  IMatrixStateModel,
  IPosition,
  IViewportData,
  IGroupStateModel
} from '@app/models/workspace-list.model';
import { ICollage } from '@app/state/collage/collage.model';
import { IStudy } from '@app/state/study/study.model';
import { User } from '@angular/fire/auth';
import { ValidMatrix } from '../tools/tools.model';
import { FrameContainer } from '@app/custom-pixi-containers/frame-container';

export class CreateStudy {
  static readonly type = '[Study] Create Study';
  constructor(public collage: ICollage, public currentUser: Partial<User>) {}
}

export class SetActiveStudy {
  static readonly type = '[Study] Set Active Study';
  constructor(public study: IStudy | null) {}
}

export class SetActiveStudyName {
  static readonly type = '[Study] Set Active Study Name';
  constructor(public name: string) {}
}

export class ResetActiveStudy {
  static readonly type = '[Study] Reset Active Study';
}

export class AddWorkspace {
  static readonly type = '[Study] Add Workspace';
  constructor(readonly payload: string) {}
}

export class SetActiveWorkspaceId {
  static readonly type = '[Study] Set Active Workspace Id';
  constructor(readonly payload: string | null) {}
}

export class SetWorkspace {
  static readonly type = '[Study] Set Workspace';
  constructor(public payload: IWorkspace) {}
}

export class SetAxesXY {
  static readonly type = '[Study] Set Axes XY';
  constructor(public payload: Partial<IAxesShelfModel>) {}
}

export class SetSpacing {
  static readonly type = '[Study] Set Spacing';
  constructor(public payload: IPosition) {}
}

export class SetN {
  static readonly type = '[Study] Set N';
  constructor(public payload: number) {}
}

export class SetIsAggregating {
  static readonly type = '[Workspace] Set is Aggregating';
  constructor(public payload: boolean) {}
}

export class SetRankDirection {
  static readonly type = '[Workspace List] Set Rank Direction';
  constructor(public payload: boolean) {}
}
export class SetRankAbsolutePlotting {
  static readonly type = '[Workspace List] Set Rank Absolute Plotting';
  constructor(public payload: boolean) {}
}
export class SetRankAbsolutePlottingLabelSize {
  static readonly type = '[Workspace List] Set Rank Absolute Plotting Label Size';
  constructor(public payload: number) {}
}
export class SetParetoColumnSize {
  static readonly type = '[Workspace List] Set Pareto Column Size';
  constructor(public payload: number) {}
}
export class SetParetoGroupBoundIndexes {
  static readonly type = '[Workspace List] Set Pareto Group Bound Indexes';
  constructor(public payload: number[]) {}
}
export class ResetParetoGroupBoundIndexes {
  static readonly type = '[Workspace List] Reset Pareto Group Bound Indexes';
}
export class SetShowImagePercentages {
  static readonly type = '[Workspace List] Set Show Image Percentages';
  constructor(public payload: boolean) {}
}

export class ResetNeedRebuildMatrix {
  static readonly type = '[Active Workspace] Reset Need Rebuild Matrix';
}

export class SetViewport {
  static readonly type = '[Study] Set Viewport';
  constructor(public payload: { workspaceId: string; viewport: IViewportData; resolution }) {}
}

export class SetFrameFeatureName {
  static readonly type = '[Study] Set Frame Feature Name';
  constructor(public payload: string) {}
}

export class AddFrame {
  static readonly type = '[Study] Add Frame';
  constructor(
    public payload: {
      frame: IFrameStateModel;
      created: boolean;
    }
  ) {}
}

export class UpdateFramesAndImages {
  static readonly type = '[Study] Update Frames And Images';
  constructor(public payload: { frames: Array<IFrameStateModel>; images: Array<IImageStateModel> }) {}
}

export class RemoveFrame {
  static readonly type = '[Study] Remove Frame';
  constructor(public payload: string) {}
}

export class RemoveFrameFromDatabase {
  static readonly type = '[Study] Remove Frame From Database';
  constructor(public payload: string) {}
}

export class UpdateFrame {
  static readonly type = '[Study] Update Frame';
  constructor(
    public payload: {
      id: string;
      update: Partial<IFrameStateModel>;
    }
  ) {}
}

export class UpdateFrames {
  static readonly type = '[Study] Update Frames';
  constructor(public payload: Array<IFrameStateModel>) {}
}

export class UpdateImages {
  static readonly type = '[Study] Update Images';
  constructor(public payload: Array<IImageStateModel>) {}
}

export class UpdateImage {
  static readonly type = '[Study] Update Image';
  constructor(
    public payload: {
      uid: string;
      update: IImageStateModel;
      parentFrame?: FrameContainer;
    }
  ) {}
}

export class UpdateMatrixState {
  static readonly type = '[Study] Update Matrix State';
  constructor(public payload: Partial<IMatrixStateModel>) {}
}

export class SetMatrixState {
  static readonly type = '[Study] Set Matrix State';
  constructor(public payload: IMatrixStateModel) {}
}

export class SetMatrixType {
  static readonly type = '[Study] Set Matrix Type';
  constructor(public payload: ValidMatrix | null) {}
}

export class SetActiveWorkspaceSelectedView {
  static readonly type = '[Study] Set Active Workspace Selected View';
  constructor(public payload: string) {}
}

export class SetHoverInfoConfig {
  static readonly type = '[Study] Set Hover Info Config';
  constructor(public payload: Array<IHoverInfoFieldModel>) {}
}

export class SetMetricsCustomFormattings {
  static readonly type = '[Study] Set Metrics Custom Formattings';
  constructor(public payload: IMetricsCustomFormattings) {}
}

export class SetCustomAttributes {
  static readonly type = '[Study] Set Custom Attributes';
  constructor(public payload: any) {}
}

export class SetCustomAttributeFilters {
  static readonly type = '[Study] Set Custom Attribute Filters';
  constructor(public payload: IFiltersModel[]) {}
}

// the same as SetCustomAttributeFilters, but uses after BackwardFiltering to update field productsLength for each discrete value in Custom Attribute Filters
export class UpdateCustomAttributeFilters {
  static readonly type = '[Study] Update Custom Attribute Filters';
  constructor(public payload: IFiltersModel[]) {}
}

export class UpdateCustomAttributeFilter {
  static readonly type = '[Study] Update Custom Attribute Filter';
  constructor(public payload: IFiltersModel) {}
}

export class SetCustomCalculationFilters {
  static readonly type = '[Study] Set Custom Calculation Filters';
  constructor(public payload: IFiltersModel[]) {}
}

export class SetHighlightFilters {
  static readonly type = '[Study] Set Highlight Filters';
  constructor(public payload: IFiltersModel[]) {}
}

export class SetRankInfoPanelConfig {
  static readonly type = '[Study] Set Rank Info Panel Config';
  constructor(public payload: IRankInfoPanelConfig) {}
}

export class SetGroupOrientation {
  static readonly type = '[Study] Set Group Orientation';
  constructor(public payload: GroupOrientation) {}
}

export class SetParetoDetailsBarFixedPosition {
  static readonly type = '[Study] Set Pareto Details Bar Fixed Position';
  constructor(public payload: boolean) {}
}

export class DuplicateWorkspace {
  static readonly type = '[Study] Duplicate Workspace';
  constructor(public workspace: IWorkspace) {}
}

export class DeleteWorkspace {
  static readonly type = '[Study] Delete Workspace';
  constructor(public workspaceId: string) {}
}

export class DeleteActiveWorkspace {
  static readonly type = '[Study] Delete Active Workspace';
}

export class UpdateCustomCalculationFiltersInWorkspaces {
  static readonly type = '[Study] Update Custom Calculation Filters In Workspaces';
  constructor(public changedFilters: IFiltersModel[]) {}
}

export class SetShowOriginalHeaders {
  static readonly type = '[Study] SetShowOriginalHeaders';
  constructor(public payload: boolean) {}
}
export class SetFriendlyHeaderTitle {
  static readonly type = '[Study] SetFriendlyHeaderTitle';
  constructor(public payload: { header: string; headerTitle: string }) {}
}

export class UpdateGroup {
  static readonly type = '[Study] Update Group';
  constructor(public payload: { id: string; update: Partial<IGroupStateModel> }) {}
}

export class SetGroups {
  static readonly type = '[Study] Set Groups';
  constructor(public payload: Array<IGroupStateModel>) {}
}

export class SetStudyEdited {
  static readonly type = '[Study] Set Study Edited';
  constructor(public payload: boolean) {}
}
