import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CompassService } from '@app/services/compass/compass.service';

@Component({
  selector: 'app-compass-canvas',
  templateUrl: './compass-canvas.component.html',
  styleUrls: ['./compass-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompassCanvasComponent implements OnInit {
  @ViewChild('canvasWrapper', { static: true })
  private readonly canvasWrapper: ElementRef;

  constructor(private compassService: CompassService) {}

  ngOnInit(): void {
    (this.canvasWrapper.nativeElement as HTMLDivElement).appendChild(this.compassService.getCanvas());
  }
}
