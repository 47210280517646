import { AfterContentChecked, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive to change input attributes "autocomplete" and "id" to prevent browser autofill
 * Both attributes will be replaced by unique value, based on "name + uniq Number"
 *
 * Notes:
 * - if external code uses static id, the conflict can be solved by using ElementRef instead of document.getById()
 * - for input type="password" such uniq value doesn't work, but works "new-password" key
 * - see HTML standards: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-name
 *
 * Examples:
 * <input matInput formControlName="user" app-disable-autocomplete-input />
 * <input matInput type="password" formControlName="password" app-disable-autocomplete-input />
 */
@Directive({
  selector: '[app-disable-autocomplete-input]'
})
export class DisableAutocompleteInputDirective implements OnInit, AfterContentChecked {
  uniqKey: number;
  name: string;
  isPassword: boolean;

  constructor(private readonly element: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.uniqKey = new Date().getTime();
    this.isPassword = this.element.nativeElement.getAttribute('type') === 'password';
    this.name =
      this.element.nativeElement.getAttribute('formcontrolname') || this.element.nativeElement.getAttribute('name');
    this.updateAttributes();
  }

  ngAfterContentChecked(): void {
    this.updateAttributes();
  }

  updateAttributes(): void {
    if (this.isPassword) {
      this.renderer.setAttribute(this.element.nativeElement, 'autocomplete', 'new-password');
    } else {
      this.renderer.setAttribute(this.element.nativeElement, 'autocomplete', `${this.name}-${this.uniqKey}`);
    }
    this.renderer.setAttribute(this.element.nativeElement, 'id', `${this.name}-${this.uniqKey}`);
  }
}
