import { ConnectionType } from '@app/state/connection/connection.model';

export enum PortalMenuItem {
  Projects = 'Projects',
  Studies = 'Studies',
  Collages = 'Collages',
  Connections = 'Connections',
  Insights = 'Insights'
}

export const PortalMenuItemCollorMapper = {
  [PortalMenuItem.Studies]: 'rgba(103, 113, 220, 0.5)',
  [PortalMenuItem.Collages]: 'rgba(255, 168, 27, 0.5)',
  [PortalMenuItem.Connections]: {
    [ConnectionType.Image]: 'rgba(160, 188, 103, 0.5)',
    [ConnectionType.Business]: 'rgba(199, 103, 220, 0.5)'
  }
};
