<div class="unverified">
  <p class="unverified__message">Please verify your email</p>
  <p class="unverified__submessage">
    You're almost there! We sent an email to <b>{{ currentUser?.email }}</b>
  </p>
  <p class="unverified__submessage">
    Just click on the link in that email to complete your signup.<br />
    If you don't see it, you may need to <b>check your spam folder</b>.
  </p>
  <p class="unverified__submessage">Still can't find the email?</p>
  <div class="unverified__actions">
    <button mat-stroked-button color="accent" [routerLink]="['/auth', 'update-email']">CHANGE EMAIL</button>
    <button mat-flat-button color="accent" (click)="onSendVerificationEmail()">RESEND EMAIL</button>
  </div>
</div>
