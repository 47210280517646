import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

export interface ICSVHeaderData {
  title: string;
  column: string;
  dataIndex: number;
  columnIndex: number;
  isShown: boolean;
  uniqValues: Map<string, any>;
  isAllUnique: boolean;
}

@Component({
  selector: 'app-csv-preview',
  templateUrl: './csv-preview.component.html',
  styleUrls: ['./csv-preview.component.scss']
})
export class CSVPreviewComponent implements OnInit, OnChanges {
  @Input() containerHeight: number = 300;
  @Input() csvSettings: any;
  @Input() csvData: Array<any> = [];
  @Input() csvHeaders: Array<string> = [];
  @Input() productIdColumnName: string = 'ProductID';

  headers: Array<ICSVHeaderData> = [];
  rows: any[][] = [];

  isHasSomeUniqueColumn: boolean = false;

  displayedColumns: Array<ICSVHeaderData> = [];

  dataSource = new TableVirtualScrollDataSource();

  public get displayedColumnDefs(): Array<string> {
    return this.displayedColumns.map(item => item.column);
  }

  public get lineBreak(): string {
    if (this.csvSettings?.linebreak === '\n') {
      return 'LF (\\n)';
    }
    if (this.csvSettings?.linebreak === '\r') {
      return 'CR (\\r)';
    }
    if (this.csvSettings?.linebreak === '\r\n') {
      return 'CRLF (\\r\\n)';
    }
    return '';
  }

  constructor(public element: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.processData();
  }

  private processData() {
    if (!this.csvData) {
      this.headers = [];
      this.rows = [];
      return;
    }
    this.processHeaders();

    this.rows = this.csvData;
    this.updateVisibleColumns();

    this.dataSource.data = this.rows;
  }

  private processHeaders() {
    const headers = [];
    for (let colIndex = 0; colIndex < this.csvHeaders.length; colIndex++) {
      headers.push({
        title: this.csvHeaders[colIndex],
        column: this.csvHeaders[colIndex],
        dataIndex: colIndex,
        columnIndex: colIndex,
        uniqValues: new Map(),
        isAllUnique: false,
        isShown: true
      });
    }
    for (let rowIndex = 0; rowIndex < this.csvData.length; rowIndex++) {
      for (let colIndex = 0; colIndex < headers.length; colIndex++) {
        headers[colIndex].uniqValues.set(this.csvData[rowIndex][colIndex], null);
      }
    }
    const rowCount = this.csvData.length - 1;
    this.isHasSomeUniqueColumn = true;
    for (let colIndex = 0; colIndex < headers.length; colIndex++) {
      headers[colIndex].isAllUnique = headers[colIndex].uniqValues.size === rowCount;
      if (!headers[colIndex].isAllUnique) {
        this.isHasSomeUniqueColumn = false;
      }
    }
    this.headers = headers;
  }

  updateVisibleColumns() {
    this.displayedColumns = this.headers.filter(item => item.isShown);
  }
}
