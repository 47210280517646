<div *ngIf="showInsightsPanel" class="insights" [@expendInsightsPanelAnimation]="expanded ? 'expanded' : 'closed'">
  <div class="insights__wrapper scrollable" [@animateInsightsPanel]="expanded ? 'expanded' : 'closed'">
    <div class="insights__panel">
      <ng-container [ngSwitch]="matrixType">
        <ng-container *ngSwitchCase="ValidMatrix.Rank">
          <app-rank-insights-panel></app-rank-insights-panel>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="insights__actions">
    <button
      [ngSwitch]="expanded"
      class="insights__actions__button"
      [@animateInsightsPanelButton]="expanded ? 'expanded' : 'closed'"
      (click)="expanded = !expanded"
    >
      <svg-icon *ngSwitchCase="true" svgClass="icon" name="icon-compass-minimize"></svg-icon>
      <svg-icon *ngSwitchCase="false" svgClass="icon" name="icon-insights"></svg-icon>
    </button>
  </div>
</div>
