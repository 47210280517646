import { uuid } from '@app/shared/utils/uuid';
import { ICollage } from '@app/state/collage/collage.model';
import { IStudy } from '@app/state/study/study.model';
import { User } from '@angular/fire/auth';
import { Timestamp } from '@angular/fire/firestore';
import { CURRENT_STUDY_DATA_VERSION } from '@services/project-data/project-data.service';

export class StudyUtils {
  public static create(collage: ICollage, currentUser: Partial<User>): IStudy {
    return {
      id: uuid(),
      metadata: {
        name: 'Untitled Study',
        authorName: currentUser.displayName,
        collageName: collage.metadata.name
      },
      owner: currentUser.uid,
      collageId: collage.id,
      workspaceList: {
        activeWorkspaceId: null,
        countUpdatingWorkspace: 0,
        ids: []
      },
      created: Timestamp.now(),
      lastViewed: Timestamp.now(),
      studySettings: {
        hoverInfoConfig: []
      },
      friendlyName: {
        isShowOriginalHeaders: false,
        headerTitles: []
      },
      customAttributes: {},
      customAttributeFilters: [],
      customCalculationFilters: [],
      favorite: false,
      version: CURRENT_STUDY_DATA_VERSION,
      isEdited: false
    };
  }
}
