<div class="wrapper">
  <div class="carousel-wrapper" [ngStyle]="{ 'width.px': carouselWidth, 'height.px': cellHeight + 35 }">
    <div
      class="carousel"
      [ngStyle]="{ 'margin-left.px': imagesToShow.length <= 2 ? cellWidth + cellGap : 0 }"
      #carousel
    >
      <div
        class="cell"
        [ngStyle]="cellStyles"
        *ngFor="let image of imagesToShow; let i = index"
        [ngClass]="{ active: i === centerCell }"
      >
        <img [@carouselCellImgAnimation]="getAnimationState(i)" [src]="image.url" />
        <span [@carouselCellTitleAnimation]="getAnimationState(i)" class="title">{{ displayName || image.id }}</span>
      </div>
    </div>
    <div class="centerCellBox" [ngStyle]="{ 'width.px': cellWidth, 'height.px': cellHeight }"></div>
  </div>
  <button
    class="prev button"
    (click)="prev()"
    [disabled]="imagesToShow.length < 2 || (imagesToShow.length === 2 && centerCell === 0)"
  >
    <svg-icon name="icon-carousel-arrow"></svg-icon>
  </button>
  <button
    class="next button"
    (click)="next()"
    [disabled]="imagesToShow.length < 2 || (imagesToShow.length === 2 && centerCell === 1)"
  >
    <svg-icon name="icon-carousel-arrow"></svg-icon>
  </button>
</div>
