import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { IMatrixIcon, matrixIcons } from '@app/shared/constants/matrix-types';
import { ValidMatrix } from '@app/state/tools/tools.model';

@Component({
  selector: 'app-toolbox-matrix-type',
  templateUrl: './toolbox-matrix-type.component.html',
  styleUrls: ['./toolbox-matrix-type.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolboxMatrixTypeComponent {
  public matrixIcons: IMatrixIcon[] = [...matrixIcons];

  @Output()
  public selectType = new EventEmitter<ValidMatrix>();

  onSelect(icon: IMatrixIcon) {
    this.selectType.emit(icon.value);
  }
}
