import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { openCloseAnimation } from '@app/components/animations/animations';
import { ICompassWorkspaceModel } from '@app/models/matrix.model';
import { iconsMap } from '@app/shared/constants/matrix-types';
import { WINDOW } from '@app/shared/utils/window';
import {
  DeleteActiveWorkspace,
  DeleteWorkspace,
  DuplicateWorkspace,
  SetActiveWorkspaceId
} from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { WorkspaceSelectorService } from '@app/services/workspace-selector/workspace-selector.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
  animations: [openCloseAnimation]
})
export class WorkspaceSelectorComponent implements OnInit {
  public readonly icons = iconsMap;

  @Select(StudyState.getActiveWorkspaceId)
  public readonly activeWorkspaceId$: Observable<string | null>;
  @Select(StudyState.canAddWorkspace)
  public readonly canAddWorkspace$: Observable<boolean>;

  show = false;
  workspaces: ICompassWorkspaceModel[] = [];

  @ViewChild('wrapper', { static: true })
  public readonly wrapper: ElementRef;

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private store: Store,
    private workspaceSelectorService: WorkspaceSelectorService
  ) {}

  ngOnInit(): void {
    this.workspaceSelectorService.workspaceSelectorOpenSubject.pipe(untilDestroyed(this)).subscribe(workspaces => {
      this.show = true;
      this.workspaces = workspaces;
    });
  }

  public onSelectWorkspace(workspace: ICompassWorkspaceModel): void {
    this.hide();
    this.activeWorkspaceId$.pipe(take(1)).subscribe(activeWorkspaceId => {
      if (activeWorkspaceId !== workspace.id) {
        Sentry.addBreadcrumb({ level: 'log', message: 'Click select Workspace', data: { workspace: workspace.id } });
        this.store.dispatch(new SetActiveWorkspaceId(workspace.id));
      }
    });
  }

  public onDuplicate($event, workspace: ICompassWorkspaceModel): void {
    $event.stopPropagation();
    this.hide();
    Sentry.addBreadcrumb({ level: 'log', message: 'Click duplicate Workspace', data: { workspace: workspace.id } });
    const workspaceListInStore = this.store.selectSnapshot(StudyState.getWorkspaceList);
    const workspaceToDuplicate = workspaceListInStore.find(w => w.id === workspace.id);
    if (workspaceToDuplicate) this.store.dispatch(new DuplicateWorkspace(workspaceToDuplicate));
  }

  public onDelete($event, workspaceId: string): void {
    $event.stopPropagation();
    this.hide();
    this.activeWorkspaceId$.pipe(take(1)).subscribe(activeWorkspaceId => {
      if (workspaceId === activeWorkspaceId) {
        Sentry.addBreadcrumb({
          level: 'log',
          message: 'Click delete active Workspace',
          data: { workspace: workspaceId }
        });
        this.store.dispatch(new DeleteActiveWorkspace());
      } else {
        Sentry.addBreadcrumb({ level: 'log', message: 'Click delete Workspace', data: { workspace: workspaceId } });
        this.store.dispatch(new DeleteWorkspace(workspaceId));
      }
    });
  }

  onBackdropClick() {
    this.hide();
  }

  private hide() {
    this.show = false;
  }
}
