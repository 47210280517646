import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { IParetoAdditionalDataModel } from '@app/models/pareto-model';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { SetParetoGroupBoundIndexes } from '@app/state/study/study.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-multi-handle-slider',
  templateUrl: './multi-handle-slider.component.html',
  styleUrls: ['./multi-handle-slider.component.scss']
})
export class MultiHandleSliderComponent implements OnInit, OnDestroy {
  private readonly MAX_SLIDER_INPUTS = 6;

  additionalData: IParetoAdditionalDataModel;

  @ViewChildren('thumbs') thumbs: QueryList<ElementRef>;
  @ViewChildren('sliderInput') sliderInputs: QueryList<ElementRef>;
  @ViewChildren('tooltip') tooltips: QueryList<MatTooltip>;
  @ViewChildren('tooltipElement') tooltipElements: QueryList<ElementRef<HTMLDivElement>>;
  inputValues: string[] = [];
  numInputs = 0;
  activeValue = 0;

  addButtonsDisabled = false;
  showSnapPoints = false;
  snapPointSize = 6;

  activeInput: HTMLInputElement = null;
  activeInputIndex: number = -1;
  prevValue: string;

  constructor(private matrixService: MatrixService, private store: Store, private cdr: ChangeDetectorRef) {}

  @HostListener('mousemove', ['$event'])
  onMouseMove({ clientX, clientY }: MouseEvent) {
    if (this.activeInputIndex === -1) {
      this.tooltipElements.forEach((tooltipEl, index) => {
        const { x, y, width, height } = tooltipEl.nativeElement.getBoundingClientRect();
        const tooltip = this.tooltips.get(index);
        if (clientX > x && clientY > y && clientX < x + width && clientY < y + height) {
          tooltip.show();
        } else {
          tooltip.hide();
        }
      });
    }
  }

  @HostListener('mouseleave', [])
  onMouseLeave() {
    this.hideTooltips();
  }

  ngOnInit(): void {
    this.matrixService.paretoAditionalDataSubject.pipe(untilDestroyed(this)).subscribe(data => {
      this.additionalData = data;
      this.cdr.detectChanges();
      this.inputValues = this.sliderInputs
        ? this.sliderInputs.map((input: ElementRef) => input.nativeElement.value)
        : [];
      this.numInputs = this.inputValues.length;
      this.updateSnapPointsSize();
      this.cdr.detectChanges();
      if (this.additionalData?.snapPoints) this.updatePlusButtons();
    });
  }

  ngOnDestroy(): void {
    this.additionalData = null;
    this.inputValues = [];
    this.numInputs = this.inputValues.length;
  }

  onInputMouseMove(input: HTMLInputElement, index: number) {
    const closest = this.getClosestValue(input);
    this.inputValues[index] = `${closest}`;
    this.activeValue = closest;
  }

  onInputMouseDown(input: HTMLInputElement, index: number) {
    this.hideTooltips();
    this.showSnapPoints = true;
    const closest = this.getClosestValue(input);
    this.activeValue = closest;
    this.activeInput = input;
    this.activeInputIndex = index;
    this.prevValue = `${closest}`;
  }

  onInputMouseUp(input: HTMLInputElement, index: number) {
    this.showSnapPoints = false;
    const closest = this.getClosestValue(input);
    const removeThumb = this.checkForThumbRemove(closest, index);
    if (removeThumb) {
      this.removeInputAndHandler(index);
    } else {
      input.value = `${closest}`;
      this.inputValues[index] = input.value;
    }

    this.activeValue = -1;
    this.activeInput = null;
    this.activeInputIndex = -1;
    this.prevValue = '';

    setTimeout(() => {
      this.updateParetoGroupBoundIndexes();
    });
  }

  private getClosestValue(input) {
    const inputVal = parseFloat(input.value);
    return this.additionalData.snapPoints.reduce((prev, curr) => {
      return Math.abs(curr - inputVal) < Math.abs(prev - inputVal) ? curr : prev;
    });
  }

  onPlusLeftButtonClick() {
    const index = this.additionalData.snapPoints.findIndex(point => !this.inputValues.some(val => val === `${point}`));

    if (index > -1) this.createInputAndHandler(index);
    setTimeout(() => {
      this.updatePlusButtons();
      this.updateParetoGroupBoundIndexes();
    });
  }

  onPlusRightButtonClick() {
    const index = [...this.additionalData.snapPoints]
      .reverse()
      .findIndex(point => !this.inputValues.some(val => val === `${point}`));

    if (index > -1) this.createInputAndHandler(this.additionalData.snapPoints.length - index - 1);
    setTimeout(() => {
      this.updatePlusButtons();
      this.updateParetoGroupBoundIndexes();
    });
  }

  createInputAndHandler(index) {
    this.additionalData.groupBoundIndexes.push(index);
    this.additionalData.groupBounds.push(this.additionalData.snapPoints[index]);
    this.inputValues.push(`${this.additionalData.snapPoints[index]}`);
    this.numInputs = this.inputValues.length;
  }

  updatePlusButtons() {
    if (
      (this.sliderInputs.length === this.additionalData.snapPoints.length ||
        this.sliderInputs.length === this.MAX_SLIDER_INPUTS) &&
      !this.addButtonsDisabled
    )
      this.addButtonsDisabled = true;

    if (
      this.addButtonsDisabled &&
      this.sliderInputs.length < this.additionalData.snapPoints.length &&
      this.sliderInputs.length < this.MAX_SLIDER_INPUTS
    )
      this.addButtonsDisabled = false;
  }

  checkForThumbRemove(closest, index) {
    return this.inputValues.some((val, i) => i !== index && val === `${closest}`);
  }

  removeInputAndHandler(index) {
    this.additionalData.groupBoundIndexes.splice(index, 1);
    this.additionalData.groupBounds.splice(index, 1);
    this.inputValues.splice(index, 1);
    this.numInputs = this.inputValues.length;
    setTimeout(() => {
      this.updatePlusButtons();
    });
  }

  updateParetoGroupBoundIndexes() {
    const indexes = this.inputValues
      .map(val => this.additionalData.snapPoints.indexOf(parseFloat(val)))
      .sort((a, b) => a - b);
    this.store.dispatch(new SetParetoGroupBoundIndexes(indexes));
  }

  /**
   * Calculates Dynamic Size for Snap points
   * Max size is 6px, for 10 and less snap points
   * Min size is 4px, for 30 and more snap points
   * Everything in between will be calculated dynamically
   */
  private updateSnapPointsSize() {
    const minSize = 4;
    const maxSize = 6;
    const minSnapCount = 10;
    const maxSnapCount = 30;
    const snapPointsCount = this.additionalData?.snapPoints?.length || 0;

    if (snapPointsCount <= minSnapCount) {
      this.snapPointSize = maxSize;
      return;
    }

    if (snapPointsCount >= maxSnapCount) {
      this.snapPointSize = minSize;
      return;
    }

    this.snapPointSize =
      minSize + (maxSize - minSize) * ((snapPointsCount - minSnapCount) / (maxSnapCount - minSnapCount));
  }

  private hideTooltips() {
    this.tooltips.forEach(tooltip => {
      tooltip.hide();
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeyPress(event: KeyboardEvent) {
    if (this.activeInput) {
      this.activeInput.value = this.prevValue;
      this.onInputMouseUp(this.activeInput, this.activeInputIndex);
    }
  }
}
