<app-dialog-base-component (close)="dialogRef.close()">
  <mat-dialog-content class="presentation-mode">
    <div class="presentation-mode__wrapper">
      <div #matrix class="presentation-mode__matrix"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="back-btn" mat-button mat-dialog-close>BACK</button>
    <button class="export-btn" mat-button (click)="exportMatrixAsPptx()">EXPORT .PPTX</button>
  </mat-dialog-actions>
</app-dialog-base-component>
