import { Injectable } from '@angular/core';
import { IDisplayDataModel } from '@app/state/display-data/display-data.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResetDisplayData, SetDisplayData } from './display-data.action';

@State<IDisplayDataModel>({
  name: 'displayData',
  defaults: {
    productsToShow: []
  }
})
@Injectable()
export class DisplayDataState {
  @Selector([DisplayDataState])
  static displayData(state: IDisplayDataModel): any[] {
    return state.productsToShow;
  }

  @Action(SetDisplayData)
  private setDisplayData({ patchState }: StateContext<IDisplayDataModel>, { payload }: SetDisplayData) {
    patchState({ productsToShow: payload });
  }

  @Action(ResetDisplayData)
  private resetDisplayData({ patchState }: StateContext<IDisplayDataModel>) {
    patchState({ productsToShow: [] });
  }
}
