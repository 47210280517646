<div class="wrapper">
  <app-home-card-header
    [type]="PortalMenuItem.Connections"
    [connectionType]="connection.type"
    [name]="connection.metadata.name"
    [id]="connection.id"
    [favorite]="connection.favorite"
    [owner]="connection.owner"
  ></app-home-card-header>
  <app-home-card-body
    [type]="PortalMenuItem.Connections"
    [id]="connection.id"
    [connectionType]="connection.type"
    [authorName]="connection.metadata.authorName"
    [createdDate]="connection.created"
    [isDefault]="connection.owner === '*'"
  >
    <div class="connection">
      <div class="connection__type">
        <svg-icon
          svgClass="icon"
          [name]="connection.type === ConnectionType.Image ? 'icon-image-connection' : 'icon-business-connection'"
        ></svg-icon>
        <span class="connection__type__name" [matTooltip]="urlPath" matTooltipPosition="below" matTooltipClass="below">
          {{ urlPath }}
        </span>
      </div>
      <div class="connection__border-box"></div>
    </div>
  </app-home-card-body>
</div>
