import { IImageConnection } from '@app/state/connection/image/image-connection.model';

export class ResetActiveImageConnection {
  static readonly type = '[Connection] Reset Active Image Connection';
}

export class SetActiveImageConnection {
  static readonly type = '[Connection] Set Active Image Connection';
  constructor(public connection: IImageConnection | null) {}
}

export class SetActiveImageConnectionName {
  static readonly type = '[Connection] Set Active Image Connection Name';
  constructor(public name: string) {}
}

export class UpdateImageConnection {
  static readonly type = '[Connection] Update Image Connection';
  constructor(readonly payload: IImageConnection) {}
}

export class LoadImageConnection {
  static readonly type = '[Connection] Load Image Connection';
  constructor(readonly connectionId: string) {}
}
