import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { ThemeService } from '@services/theme/theme.service';
import { DataService } from '@services/data/data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BuildModule } from './views/build/build.module';
import { AuthenticationModule } from './views/authentication/authentication.module';

// Views
import { AppComponent } from './app.component';

// Shared

// State Store
import { NgxsModule } from '@ngxs/store';
import { ToolsState } from '@app/state/tools/tools.state';
import { UserDataState } from './state/user-data/user-data.state';
import { environment } from '../environments/environment';
import { DisplayDataState } from './state/display-data/display-data.state';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { StudyState } from './state/study/study.state';
import { CollageState } from './state/collage/collage.state';
import { HomeModule } from './views/home/home.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ImageConnectionState } from '@app/state/connection/image/image-connection.state';
import { BusinessConnectionState } from '@app/state/connection/business/business-connection.state';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/components/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BuildModule,
    AuthenticationModule,
    HomeModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [
        UserDataState,
        ToolsState,
        DisplayDataState,
        StudyState,
        CollageState,
        ImageConnectionState,
        BusinessConnectionState
      ],
      {
        selectorOptions: {
          injectContainerState: false
        }
        // developmentMode: !environment.production // TODO: fix state mutation issues first
      }
    ),
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/preload-icons.json'
    }),
    environment.plugins,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule
  ],
  providers: [
    ThemeService,
    DataService,
    // Register Sentry.TraceService with Router dependency to track routing changes
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    // force instantiate Tracing, when Application started
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
