import { IBusinessConnection } from '@app/state/connection/business/business-connection.model';

export class ResetActiveBusinessConnection {
  static readonly type = '[Connection] Reset Active Business Connection';
}

export class SetActiveBusinessConnection {
  static readonly type = '[Connection] Set Active Business Connection';
  constructor(public connection: IBusinessConnection | null) {}
}

export class SetActiveBusinessConnectionName {
  static readonly type = '[Connection] Set Active Business Connection Name';
  constructor(public name: string) {}
}

export class UpdateBusinessConnection {
  static readonly type = '[Connection] Update Business Connection';
  constructor(readonly payload: IBusinessConnection) {}
}

export class LoadBusinessConnection {
  static readonly type = '[Connection] Load Business Connection';
  constructor(readonly connectionId: string) {}
}
