<div class="adaptive-toolbar-wrapper" #toolbarWrapper>
  <div class="adaptive-toolbar-container drop-box" #toolbarContainer>
    <ng-template [cdkPortalOutlet]="hostPortal" #toolbarItems></ng-template>
  </div>
  <div #buttonMore class="button-more" (click)="toggleDropdown($event)">...</div>
</div>

<div class="dropdown-container drop-box" #dropdownContainer>
  <ng-template [cdkPortalOutlet]="hostPortal" #dropdownItems></ng-template>
</div>
