import { Injectable } from '@angular/core';
import { MatrixService } from '../matrix/matrix.service';
import { Store } from '@ngxs/store';
import { StudyState } from '@app/state/study/study.state';
import { ICompassWorkspaceModel } from '@app/models/matrix.model';
import { Subject } from 'rxjs';
import { AppLoaderService } from '@app/shared/components/loader/app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceSelectorService {
  public workspaceSelectorOpenSubject: Subject<ICompassWorkspaceModel[]> = new Subject();

  constructor(private store: Store, private matrixService: MatrixService, private appLoaderService: AppLoaderService) {}

  public openWorkspaceSelector() {
    /**
     * Image extraction from canvas is async functoin and might take some time if viewports are significant size
     * So, need to show loader during image creation for better ux
     */
    this.appLoaderService.setShowLoader('workspaceSelector', true);
    setTimeout(() => {
      const workspacesInStore = this.store.selectSnapshot(StudyState.getWorkspaceList);
      const workspaces = this.matrixService.getWorkspaceDataForWorkspaceSelector();
      workspaces.forEach(workspace => {
        workspace.isNeedRebuild = workspacesInStore.find(w => w.id == workspace.id)?.isNeedRebuildMatrix;
      });
      this.appLoaderService.setShowLoader('workspaceSelector', false);
      this.workspaceSelectorOpenSubject.next(workspaces);
    });
  }
}
