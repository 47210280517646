<div
  #filterBoxRef
  class="filterBox"
  [@openCloseWidthFadeScale]="showFilterBox ? 'open' : 'closed'"
  mwlResizable
  [enableGhostResize]="true"
  [validateResize]="validateResize || onValidateResize.bind(this)"
  (resizing)="onResizing($event)"
  (resizeStart)="onResizeStart($event)"
  (resizeEnd)="onResizeEnd($event)"
  [style.width]="currentWidth + 'px'"
  [style.height]="currentHeight + 'px'"
  [class.resizing]="isResizing"
>
  <div
    *ngIf="resizeCorners.leftTop"
    class="resize-handle-top-left"
    mwlResizeHandle
    [resizeEdges]="{ top: true, left: true }"
  ></div>
  <div
    *ngIf="resizeCorners.rightTop"
    class="resize-handle-top-right"
    mwlResizeHandle
    [resizeEdges]="{ top: true, right: true }"
  ></div>
  <div
    *ngIf="resizeCorners.leftBottom"
    class="resize-handle-bottom-left"
    mwlResizeHandle
    [resizeEdges]="{ bottom: true, left: true }"
  ></div>
  <div
    *ngIf="resizeCorners.rightBottom"
    class="resize-handle-bottom-right"
    mwlResizeHandle
    [resizeEdges]="{ bottom: true, right: true }"
  ></div>

  <div class="top">
    <div class="search">
      <input type="text" (keyup)="onSearchKeyUp($event)" />
      <svg-icon svgClass="icon" name="icon-user-search"></svg-icon>
    </div>
    <div class="filters" [class.disabled]="settings && !settings.enableFiltersToggle">
      <svg-icon
        svgClass="icon"
        matRipple
        [matRippleCentered]="true"
        [matRippleUnbounded]="true"
        name="icon-frame-sub-menu-data"
        [ngClass]="{ active: showBusinessFilters }"
        (click)="onToggleFilterType.emit('business')"
      ></svg-icon>
      <svg-icon
        svgClass="icon"
        matRipple
        [matRippleCentered]="true"
        [matRippleUnbounded]="true"
        name="icon-frame-sub-menu-image"
        [ngClass]="{ active: showVisualFilters }"
        (click)="onToggleFilterType.emit('visual')"
      ></svg-icon>
    </div>
  </div>
  <div class="content scrollable">
    <ng-template
      ngFor
      let-filter
      let-i="index"
      [ngForOf]="filtersToShow | searchFilter : filterSearchValue"
      [ngForTrackBy]="filtersTrackBy"
    >
      <app-filter-box-item
        [filter]="filter"
        [filtering]="filtering"
        [prefix]="prefix"
        [index]="i"
        [expanded]="i === expandedFilterIndex"
        [enableExpansion]="settings?.enableExpansion"
        [searchValue]="filterSearchValue"
        (onExpand)="expandFilter($event)"
        (onSelectAll)="onSelectAll.emit($event)"
        (onCheckboxChange)="onCheckboxChange.emit($event)"
        (onMinMaxChange)="onMinMaxChange.emit($event)"
      ></app-filter-box-item>
    </ng-template>
  </div>
  <div *ngIf="showActions" class="actions" #actionButtonsContainerRef>
    <button
      *ngIf="showActions.clearAll"
      mat-button
      class="clear-all-btn"
      (click)="$event.stopPropagation(); onClearAll.emit()"
    >
      Clear All
    </button>
    <button *ngIf="showActions.done" mat-button class="done-btn" (click)="onDone.emit()">Done</button>
  </div>
</div>
