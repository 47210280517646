import { Injectable } from '@angular/core';

export interface IFriendlyName {
  header: string;
  friendlyName: string;
}

@Injectable({
  providedIn: 'root'
})
export class FriendlyNameService {
  public isShowOriginalHeaders: boolean = false;

  private allHeaderFriendlyNames: Map<string, string> = new Map();

  get isNotEmpty(): boolean {
    return this.allHeaderFriendlyNames.size > 0;
  }

  get friendlyNamesMap(): Map<string, string> {
    return this.allHeaderFriendlyNames;
  }

  constructor() {}

  public clear() {
    this.allHeaderFriendlyNames = new Map();
  }

  public getAllFriendlyNames(): Array<IFriendlyName> {
    return Array.from(this.allHeaderFriendlyNames).map(item => {
      return {
        header: item[0],
        friendlyName: item[1]
      };
    });
  }

  public setAllFriendlyNames(values: Array<IFriendlyName>) {
    this.clear();
    values.forEach(item => {
      this.allHeaderFriendlyNames.set(item.header, item.friendlyName);
    });
  }

  public getFriendlyName(headerName: string): string {
    return this.isShowOriginalHeaders ? headerName : this.allHeaderFriendlyNames.get(headerName) || headerName;
  }

  public getOriginalName(headerName: string): string {
    let found = null;
    this.allHeaderFriendlyNames.forEach((value, key) => {
      if (value === headerName) {
        found = key;
      }
    });
    return this.isShowOriginalHeaders ? headerName : found || headerName;
  }

  public setFriendlyName(headerName: string, newFriendlyName: string): boolean {
    const isFriendlyNameAlreadyExists = Array.from(this.allHeaderFriendlyNames).some(
      item => item[1] === newFriendlyName
    );
    if (isFriendlyNameAlreadyExists) {
      return false; // cannot change: such name already uses
    }
    const newValue = newFriendlyName.trim();
    if (newValue === '' || newValue === headerName) {
      // remove value from Map
      this.allHeaderFriendlyNames.delete(headerName);
    } else {
      // update value in Map
      this.allHeaderFriendlyNames.set(headerName, newValue);
    }
    return true;
  }

  public generateFriendlyName(headerName: string): string {
    const friendlyName = this.allHeaderFriendlyNames.get(headerName);
    if (friendlyName) {
      // friendly name already generated before for this header; so, just return existing
      return friendlyName;
    }
    // generate first time
    const allOtherFriendlyNames = Array.from(this.allHeaderFriendlyNames).map(item => item[1]);
    const newFriendlyName = this.getTitleFromHeader(headerName, allOtherFriendlyNames);
    this.allHeaderFriendlyNames.set(headerName, newFriendlyName);
    return newFriendlyName;
  }

  /**
   * Return Human readable text from string (remove "_" and use Camel text style)
   * use it get some unique title for any column name
   * if generated title already exists, added next index to the end of name
   * @param header column name
   * @param allTitles all already existing titles, uses to compare new title with existing titles to generate uniq value
   */
  private getTitleFromHeader(header: string, allTitles: Array<string>): string {
    let title = '';
    let index = 0;
    do {
      title = header
        .replace(/_/g, ' ')
        .trim()
        .replace(/([^ \t]+)/g, (_, word) => {
          return word ? word[0].toUpperCase() + word.substring(1) : '';
        })
        .replace(' Y/n', ' y/n');
      if (index > 0) {
        title = `${title} ${index}`; // add index for equal titles
      }
      index++;
    } while (allTitles.indexOf(title) >= 0); // continue to get uniq title
    return title;
  }
}
