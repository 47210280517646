import {
  DatabaseConnectionType,
  IBusinessConnection,
  ICSVConnectionSettings,
  ISnowflakeSettings
} from '@app/state/connection/business/business-connection.model';
import { ConnectionType } from '@app/state/connection/connection.model';
import { IImageConnection } from '@app/state/connection/image/image-connection.model';

export class ConnectionUtils {
  public static getConnectionUrlPath(connection: IImageConnection | IBusinessConnection): string {
    return connection.type === ConnectionType.Image
      ? this.getImageConnectionUrlPath(connection as IImageConnection)
      : this.getBusinessConnectionUrlPath(connection as IBusinessConnection);
  }

  public static getImageConnectionUrlPath(imageConnection: IImageConnection): string {
    let { host, path } = imageConnection.settings.fields;
    host = host.lastIndexOf('/') === host.length - 1 ? host.slice(0, -1) : host;
    path = path.indexOf('/') === 0 ? path.slice(1) : path;
    return `${host}/${path}`;
  }

  public static getBusinessConnectionUrlPath(businessConnection: IBusinessConnection): string {
    const { databaseType, fields } = businessConnection.settings;
    if (databaseType === DatabaseConnectionType.CSV) {
      return (fields as ICSVConnectionSettings).csvFile.file.name;
    }
    return (fields as ISnowflakeSettings).host;
  }
}
