<div class="wrapper" *ngIf="currColumnSize || maxColumnSize || minColumnSize">
  <input
    #columnSizeInput
    type="text"
    (blur)="onColumnSizeChange()"
    (keyup.enter)="onColumnSizeChange()"
    [value]="currColumnSize"
  />
  <p>Please choose column size from {{ minColumnSize }} to {{ maxColumnSize }}</p>
</div>
