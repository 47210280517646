import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IFiltersModel } from '@app/models/filters.model';
import { IAxesShelfModel } from '@app/models/workspace-list.model';
import { WINDOW } from '@app/shared/utils/window';
import { SetAxesXY, SetN, SetRankDirection, SetShowImagePercentages } from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ExploreBarBase } from '../explore-bar-base.component';
import { LookupTableService } from '../../../lookup-table';
import { ExploreBarType } from '../../../../models/filters.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@UntilDestroy()
@Component({
  selector: 'app-explore-bar-sorting',
  templateUrl: './explore-bar-sorting.component.html',
  styleUrls: ['./explore-bar-sorting.component.scss']
})
export class ExploreBarSortingComponent extends ExploreBarBase implements OnInit, OnChanges {
  public readonly filterIdPrefix: ExploreBarType = 'sorting';

  @ViewChild('numEntriesInput', { static: true })
  private readonly numEntriesInputRef: ElementRef;

  @Select(StudyState.getRankDirectionTop)
  public readonly rankDirection$: Observable<boolean>;
  @Select(StudyState.getN)
  public readonly numEntries$: Observable<number>;
  @Select(StudyState.getShowImagePercentages)
  public readonly showImagePercentages$: Observable<boolean>;

  public rankDirectionTop = true;
  public numEntries = 0;
  public showImagePercentages = false;
  public sortByFilter: IFiltersModel = null;

  public get maxNumEntries(): number {
    return this.lookupTable.getTotalUniqProductIDs();
  }

  public get numEntriesWidth(): number {
    return this.maxNumEntries.toString().length * 10 + 25;
  }

  constructor(
    protected override store: Store,
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override readonly cdr: ChangeDetectorRef,
    private lookupTable: LookupTableService,
    private snackBar: MatSnackBar
  ) {
    super(store, window, cdr);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.rankDirection$.pipe(untilDestroyed(this)).subscribe((direction: boolean) => {
      this.rankDirectionTop = direction;
    });

    this.numEntries$.pipe(untilDestroyed(this)).subscribe((num: number) => {
      this.numEntries = num;
      this.numEntriesInputRef.nativeElement.value = `${this.numEntries}`;
    });

    this.showImagePercentages$.pipe(untilDestroyed(this)).subscribe((show: boolean) => {
      this.showImagePercentages = show;
    });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  protected override initUserFilters(data: IAxesShelfModel) {
    this.userFilters = [...data.x];
    this.sortByFilter = this.userFilters[0] ? { ...this.userFilters[0] } : null;
  }

  public onDirectionIndicatorClick() {
    this.rankDirectionTop = !this.rankDirectionTop;
    this.store.dispatch(new SetRankDirection(this.rankDirectionTop));
  }

  public onNumEntriesChange() {
    if (!this.numEntriesInputRef) return;
    this.numEntries = parseInt(this.numEntriesInputRef.nativeElement.value.trim(), 10) || 0;
    if (this.numEntries < 1) this.numEntries = 1;
    if (this.numEntries > this.maxNumEntries) this.numEntries = this.maxNumEntries;
    this.numEntriesInputRef.nativeElement.value = `${this.numEntries}`;

    this.store.dispatch(new SetN(this.numEntries));
  }

  protected setAxis() {
    if (
      !this.selectedFilters[0].minMaxValue ||
      this.selectedFilters[0].minMaxValue.min === this.selectedFilters[0].minMaxValue.max
    ) {
      this.selectedFilters = [];
      this.snackBar.open('Metric Not Supported', 'OK', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'start'
      });
    }
    this.store.dispatch(new SetAxesXY({ x: this.selectedFilters || null }));
  }

  onToggleAdditionalInfoClick() {
    this.store.dispatch(new SetShowImagePercentages(!this.showImagePercentages));
  }
}
