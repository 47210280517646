import { Component } from '@angular/core';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { SelectRowsService } from '../../select-rows.service';

@Component({
  selector: 'app-custom-row-index',
  templateUrl: './custom-row-index.component.html',
  styleUrls: ['./custom-row-index.component.scss']
})
export class CustomRowIndexComponent {
  public isLoading: boolean;
  public title: string;
  public rowIndex: number;
  private api: GridApi;

  constructor(private selection: SelectRowsService) {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.isLoading = isNaN(params.value);
    this.title = (Number(params.value) + 1).toString() || '';
    if (this.selection.getRowSelection(this.rowIndex)) {
      const node = this.api.getRenderedNodes().find(item => item.rowIndex === this.rowIndex);
      if (node) {
        node.setSelected(true);
      }
    }
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.title = (Number(params.value) + 1).toString() || '';
    return true;
  }

  isRowSelected(): boolean {
    return this.selection.getRowSelection(this.rowIndex);
    // const node = this.api.getRenderedNodes().find(item => item.rowIndex === this.rowIndex);
    // return node?.isSelected() || false;
  }

  toggleRow() {
    const isSelected = this.selection.getRowSelection(this.rowIndex);
    const node = this.api.getRenderedNodes().find(item => item.rowIndex === this.rowIndex);
    if (node) {
      // if (node.isSelected()) {
      if (isSelected) {
        node.setSelected(false);
        this.selection.unselectRow(this.rowIndex);
      } else {
        node.setSelected(true);
        this.selection.selectRow(this.rowIndex);
      }
    }
  }

  isHoveredRow(): boolean {
    return true;
  }

  isShowCheckbox(): boolean {
    return true;
  }

  protected readonly NaN = NaN;
}
