import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragExit,
  CdkDragStart,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { IHoverInfoFilterModel, FiltersTypeEnum, IFiltersModel, filterType2Number } from '@app/models/filters.model';
import {
  ICalcArgument,
  HoverInfoAggregationTypeEnum,
  IMetricsCustomFormattings,
  IRankInfoPanelConfig,
  IHoverInfoFieldModel,
  hoverInfoField2hoverInfoFilter,
  hoverInfoFilter2hoverInfoField,
  InfoPanelPosition
} from '@app/models/study-setting.model';
import { DataService } from '@app/services/data/data.service';
import { uuid } from '@app/shared/utils/uuid';
import {
  SetHoverInfoConfig,
  SetMetricsCustomFormattings,
  SetRankInfoPanelConfig
} from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { stringToNumber, getResultForCalcArguments } from '@app/utils/image-data.utils';
import { customFormatBigNumber, formatBigNumber } from '@app/utils/string';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';
import { Observable, asapScheduler, combineLatest, filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { shortUid } from '../../../../shared/utils/short-uid';

@UntilDestroy()
@Component({
  selector: 'app-quick-stats-sub-menu',
  templateUrl: './quick-stats-sub-menu.component.html',
  styleUrls: ['./quick-stats-sub-menu.component.scss']
})
export class QuickStatsSubMenuComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() isMenuOpen: boolean;
  // @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  // @ViewChildren('argumentPlaceholder') argumentPlaceholders: Array<ElementRef>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  @ViewChildren(CdkDropList)
  private dlq: QueryList<CdkDropList>; // all existing drop-lists in template
  public dls: CdkDropList[] = []; // filtered/allowed drop-lists by some logic for current moment

  @Select(StudyState.getMatrixType)
  public readonly matrixType$: Observable<ValidMatrix>;

  @Select(UserDataState.getDimensionsAndMeasures)
  public readonly filters$: Observable<IFiltersModel[]>;

  @Select(StudyState.getCustomAttributeFilters)
  public readonly customAttributes$: Observable<IFiltersModel[]>;

  @Select(StudyState.getCustomCalculationFilters)
  public readonly customCalculations$: Observable<IFiltersModel[]>;

  @Select(StudyState.getRankInfoPanelConfig)
  private readonly rankInfoPanelConfig$: Observable<IRankInfoPanelConfig>;

  @Select(StudyState.getMetricsCustomFormattings)
  private readonly metricsCustomFilterings$: Observable<IMetricsCustomFormattings>;

  public readonly FiltersTypeEnum = FiltersTypeEnum;
  public readonly InfoPanelPositionEnum = InfoPanelPosition;

  filtersList: Array<IFiltersModel> = [];
  infoFieldsList: Array<IHoverInfoFilterModel> = [];
  filterSearchValue: string;
  filterTooltipValue: string;

  isHasAnyInvalidField: boolean;

  isDragNumericalStarted: boolean = false; // if true, simple Measure field item started to drag
  isDragCalcGroupStarted: boolean = false; // if true, user started to drag Part of the Calc item - allow to change order in the same Calc item or remove
  isDragCalcStarted: boolean = false; // if true, don't allow to drop CALC item into the other CALC

  draggingField: IHoverInfoFieldModel = null;
  draggingStartContainer: CdkDropList = null;
  isFiltersListEntered: boolean;

  public target: CdkDropList;
  public source: CdkDropList;

  rankInfoShowState = 'hover';
  rankInfoShowPosition: InfoPanelPosition = InfoPanelPosition.Left;

  expandedFiltersListChipIndex = -1;
  expandedFiltersListChipName = '';
  formattings: IMetricsCustomFormattings = {};
  formatingDisplayNumber: number;
  formattingPrefixes = ['$', '€'];
  formattingSuffixes = ['K', 'M', 'B', '%', '% (Mult)'];
  formattingSuffixesDisplay = {
    K: 'K',
    M: 'M',
    B: 'B',
    '%': '%',
    '% (Mult)': '%'
  };
  formattingMultipliers = {
    K: 0.001,
    M: 0.000001,
    B: 0.000000001,
    '%': 1,
    '% (Mult)': 100
  };
  private formattingFractionIndicatorSizeTester: HTMLParagraphElement;

  private isFiltersTouched: boolean = false;

  constructor(private store: Store, private dataService: DataService) {}

  ngOnInit(): void {
    combineLatest([this.filters$, this.customAttributes$, this.customCalculations$])
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(
        ([filters, customFilters, customCulculationFilters]: [IFiltersModel[], IFiltersModel[], IFiltersModel[]]) => {
          const data = [];
          if (filters && filters.length > 0) data.push(...filters);
          if (customFilters && customFilters.length > 0) data.push(...customFilters);
          if (customCulculationFilters && customCulculationFilters.length > 0) data.push(...customCulculationFilters);
          this.filtersList = [];
          this.infoFieldsList = [];
          data.forEach(filterData => {
            if (this.infoFieldsList.findIndex(field => field.name === filterData.name) === -1) {
              filterData.children = [];
              this.filtersList.push(filterData);
            }
          });

          // build Hover Info Config
          // convert Store format into the UI filtersConfig list
          const hoverInfoConfig: Array<IHoverInfoFieldModel> = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
          hoverInfoConfig?.forEach((config: IHoverInfoFieldModel) => {
            const confirmedFilter = hoverInfoField2hoverInfoFilter(config, this.filtersList);
            if (confirmedFilter) {
              if (confirmedFilter.children) {
                confirmedFilter.children.forEach(item => {
                  if (item.minMaxValue === undefined) this.getCalcMinMaxValues(item);
                });
              }
              this.infoFieldsList.push(confirmedFilter);
            }
          });

          // show all filters sorted by type + name
          this.filtersList = this.filtersList.sort((a, b) => {
            if (a.type === b.type) {
              return a.name.localeCompare(b.name);
            }
            return filterType2Number(a.type) - filterType2Number(b.type);
          });

          // refresh list of drop lists after rendering changes
          setTimeout(() => {
            this.findAllDropLists();
          }, 200);
        }
      );

    this.rankInfoPanelConfig$.subscribe(config => {
      this.rankInfoShowState = config?.showState || 'hover';
      this.rankInfoShowPosition = config?.position ? config.position : InfoPanelPosition.Left;
    });

    this.metricsCustomFilterings$.subscribe(formattings => {
      this.formattings = formattings ? formattings : {};
    });

    this.createFormattingFractionIndicatorSizeTester();
  }

  ngAfterViewInit() {
    this.findAllDropLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isMenuOpen && !changes.isMenuOpen.firstChange) {
      if (changes.isMenuOpen.currentValue) {
        this.expandedFiltersListChipIndex = -1;
        this.expandedFiltersListChipName = '';
        this.checkCalcForErrors();
        this.isFiltersTouched = false;
      } else {
        /**
         * Currently isFiltersTouched is set to true any time user makes change
         * Even if at the end nothing was changes
         * F.e.: user changes Aggregations from Avg to Sum and to Avg again
         *
         * Need to write better logic for checking toucked state or changes in general
         */
        if (this.isFiltersTouched) {
          this.removeDuplicatedFilters();
          this.setQuickStats();
          this.setMetricsCustomFormatting();
        }
      }
      setTimeout(() => {
        this.findAllDropLists();
      });
    }
  }

  // save edited Hover Info Config
  setQuickStats() {
    const config = this.infoFieldsList
      .filter(field => !field.isInvalid) // don't prepare invalid filters
      .map(field => {
        // convert IHoverInfoFilterModel into IHoverInfoFieldModel
        return hoverInfoFilter2hoverInfoField(field);
      });
    this.store.dispatch(new SetHoverInfoConfig(config));
    this.dataService.apiCreateFiltersFromCustomCalculations().pipe(take(1)).subscribe();
    // this.dataService.createFiltersFromCustomCalculations();
  }

  setMetricsCustomFormatting() {
    this.store.dispatch(new SetMetricsCustomFormattings({ ...this.formattings }));
  }

  findAllDropLists() {
    if (!this.dlq) {
      return; // for case, when component not rendered yet and dlq is undefined
    }

    let ldls: CdkDropList[] = [];

    this.dlq.forEach(dl => {
      ldls.push(dl);
    });

    ldls = ldls.reverse();

    // it takes time to complete logic and then all found drop lists will work correctly during dragging
    asapScheduler.schedule(() => {
      this.dls = ldls;
    });
  }

  public onFiltersSearchKeyUp(searchValue: string) {
    this.filterSearchValue = searchValue;
  }

  onFilterMouseOver(textElementRef: HTMLElement) {
    this.filterTooltipValue = textElementRef.scrollWidth > textElementRef.clientWidth ? textElementRef.innerText : '';
  }

  public onDragEntered(event: CdkDragEnter) {
    this.enforceDragToSelf(event.container);
  }

  public onDragStarted(event: CdkDragStart) {
    this.isFiltersTouched = true;

    const dragField = event.source.data;
    this.draggingField = dragField;
    this.draggingStartContainer = event.source.dropContainer;

    this.findAllDropLists();

    // is started to move Number or Currency?
    this.isDragNumericalStarted = dragField?.isMeasure;

    // is started to move Calc Group?
    this.isDragCalcGroupStarted = dragField?.isCalcGroup;

    // Don't allow to use non-arithmetic argument for any Custom calc
    // Don't allow to use Custom Calc as argument for another Custom calc
    this.isDragCalcStarted = false;
    if (
      dragField === null || // new Custom Calc
      dragField?.type === FiltersTypeEnum.Calculated || // some created Custom Calc
      dragField?.customCalcField // some part of the Calc
    ) {
      this.isDragCalcStarted = true;
      // restrict drop lists where item can be dropped
      this.dls = this.dls.filter(item => item.id === 'fieldsConfig' || item.id === 'filtersList');
    }

    this.dls = this.dls.reverse(); // parent drop-lists should be at the end of array

    this.enforceDragToSelf(event.source.dropContainer);
  }

  private enforceDragToSelf(dl: CdkDropList) {
    const siblings = dl.connectedTo as CdkDropList<any>[];

    const ref = dl._dropListRef;
    asapScheduler.schedule(() => {
      ref.connectedTo(siblings.map(list => list._dropListRef));
    });
  }

  onRankInfoShowStateChange(event) {
    this.updateRankInfoConfig();
  }

  onRankInfoPositionToggle(pos: InfoPanelPosition) {
    this.rankInfoShowPosition = pos;
    this.updateRankInfoConfig();
  }

  private updateRankInfoConfig() {
    const config: IRankInfoPanelConfig = {
      showState: this.rankInfoShowState,
      position: this.rankInfoShowPosition
    };

    this.store.dispatch(new SetRankInfoPanelConfig(config));
  }

  private isCorrectArgumentsNumber(calc: IHoverInfoFilterModel): boolean {
    if (!calc.calcArguments) {
      return true; // seems it's not a Calc item, so return true - everything OK
    }
    let result = true;
    let count = 0;
    calc.calcArguments.forEach(arg => {
      if (arg.isCalcGroup) {
        result = result && this.isCorrectArgumentsNumber(arg);
      }
      count++;
    });
    return result && (count >= 2 || (count == 1 && calc.calcArguments[0].isCalcGroup));
  }

  checkCalcForErrors() {
    this.isFiltersTouched = true;
    this.infoFieldsList.forEach(field => {
      field.isInvalid = false;
      field.errors = null;
      if (field.type === FiltersTypeEnum.Calculated || field.children?.length > 0) {
        let testField = field;
        if (field.children?.length > 0) {
          testField = field.children[0];
        }
        const nameRequired = testField.name.trim() === '';
        const equalName = field.children?.length > 0 ? false : this.isEqualFieldName(testField);
        const needArgument = !this.isCorrectArgumentsNumber(testField);
        field.errors = {
          nameRequired,
          equalName: !nameRequired && equalName,
          needArgument: !nameRequired && !equalName && needArgument
        };
        field.isInvalid = nameRequired || equalName || needArgument;

        // fix: if first Argument not initialized yet, do it now
        const firstArg = testField.calcArguments && testField.calcArguments[0];
        if (firstArg && !firstArg.calcOperation) {
          firstArg.calcOperation = '+';
        }

        // check that all arguments have no "MinMax" type, if yes - set it to default "Sum"
        field.calcArguments?.forEach(arg => {
          arg.aggregationType =
            arg.aggregationType !== HoverInfoAggregationTypeEnum.MinMax
              ? arg.aggregationType
              : HoverInfoAggregationTypeEnum.Sum;
        });
      }
    });
    this.updateCalcForChildren();
    this.isHasAnyInvalidField = this.infoFieldsList.some(item => item.isInvalid);
  }

  /**
   * If some Calc changed on the top level,
   * the same Calc should be updated in other Fields, which uses this Calc as a child item
   */
  private updateCalcForChildren() {
    const calcs = this.infoFieldsList.filter(item => item.calcId);
    const otherFields = this.infoFieldsList.filter(item => !item.calcId);
    calcs.forEach(calc => {
      otherFields.forEach(field => {
        // check only first item (current version has only one element in children)
        if (field.children?.length && field.children[0].calcId === calc.calcId) {
          if (!calc.minMaxValue) {
            this.getCalcMinMaxValues(calc);
          }
          // copy updated Calc into the child element
          field.children[0] = {
            ...calc,
            aggregationType:
              field.children[0].aggregationType || calc.aggregationType || HoverInfoAggregationTypeEnum.Sum
          };
        }
      });
    });
  }

  private isEqualFieldName(field: IHoverInfoFilterModel): boolean {
    if (this.infoFieldsList.some(item => item.name.trim() === field.name.trim() && item !== field)) {
      return true;
    }
    const foundCalc: any = this.filtersList.find(item => item.name.trim() === field.name.trim() && item !== field);
    if (foundCalc && foundCalc.customCalcField?.calcId !== field.calcId) {
      return true;
    }
    return false;
  }

  isNonMeasureDropListDisabled(field: IHoverInfoFilterModel) {
    if (field.isDistinctCount) {
      return true;
    }
    if (this.draggingField === null) {
      return false; // dragging not started yet
    }
    if (this.isDragCalcGroupStarted) {
      return true; // dragging started for CalcGroup, so can be enabled only for Calc items
    }
    if (!this.isDragNumericalStarted && !this.isDragCalcStarted) {
      return true; // dragging started, but for text item
    }
    if ((this.isDragNumericalStarted || this.isDragCalcStarted) && field.children?.length === 0) {
      return false; // dragging started, for numerical item, and current drop list empty (ready to accept drop item)
    }
    if (this.draggingField === field?.children[0]) {
      return false; // dragging started, for numerical item, and current drop list is owner of dragging item, so ready to accept it back
    }
    return true; // drop list disabled for other cases
  }

  // ---- Two methods to provide CDK Drag&Drop: Don't move source item
  onDropListExited($event: CdkDragExit<any>) {
    this.isFiltersListEntered = false;
    const filterName = $event.item.data?.name;
    const currentIdx = $event.container.data.findIndex(f => f.name === filterName);
    if (this.draggingStartContainer.id === 'filtersList') {
      this.filtersList.splice(currentIdx + 1, 0, {
        ...$event.item.data,
        temp: true
      });
    }
  }

  // ---- Two methods to provide CDK Drag&Drop: Don't move source item
  onDropListEntered() {
    this.isFiltersListEntered = true;
    this.cleanupSourceFiltersList();
  }

  onFieldItemDrop(event: CdkDragDrop<any>) {
    this.draggingField = null;
    this.draggingStartContainer = null;
    this.isDragNumericalStarted = false;
    this.isDragCalcGroupStarted = false;

    const isCalcDisabled = this.isDragCalcStarted;
    this.isDragCalcStarted = false;

    this.cleanupSourceFiltersList();

    setTimeout(() => {
      this.findAllDropLists();
      this.checkCalcForErrors();
    });

    if (!this.dls.find(item => item.id === event.container.id)) {
      return; // Case 1: such item cannot be used in selected container (see onDragEntered())
    }

    const correctPreviousIndex = event.previousContainer.data?.findIndex(item => item === event.item.data);
    event.previousIndex = correctPreviousIndex;

    // fix correct index for Filters list
    // shift indexes, because of first item is an external item "+ Custom Calc", it should be always on the top position
    if (event.previousContainer.id === 'filtersList') {
      event.previousIndex = event.previousIndex === 0 ? 0 : event.previousIndex - 1;
    }
    if (event.container.id === 'filtersList') {
      event.currentIndex = event.currentIndex === 0 ? 0 : event.currentIndex - 1;
    }

    // Case 2: just Ordering, change order of items for any List
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.checkCalcForErrors();
      return;
    }

    // Case 3: add New or remove Calc item
    if (
      event.item.data === null ||
      event.item.data?.type === FiltersTypeEnum.Calculated ||
      event.item.data?.customCalcField
    ) {
      // Custom Calc Item
      if (event.previousContainer.id === 'filtersList') {
        let item: IHoverInfoFilterModel;
        if (event.item.data?.customCalcField) {
          // edit Custom Calc Item
          const calcField = event.item.data.customCalcField;
          // copy all data for edit
          item = {
            name: calcField.name,
            type: FiltersTypeEnum.Calculated,
            // two or more arguments
            // isCustomCalc: true,
            calcArguments: calcField.calcArguments.map(arg => {
              return { ...arg };
            }),
            calcId: calcField.calcId,
            isCalcGroup: calcField.isCalcGroup,
            isMeasure: calcField.isMeasure,
            aggregationType: calcField.aggregationType || HoverInfoAggregationTypeEnum.Sum
          };
          // item.isCustomCalc = true;
          item.calcId = item.calcId || uuid();
          this.getCalcMinMaxValues(item);
        } else {
          // create new Custom Calc Item
          item = {
            name: 'Untitled Custom Calculation',
            type: FiltersTypeEnum.Calculated,
            // two or more arguments
            calcArguments: [],
            calcId: uuid(),
            aggregationType: HoverInfoAggregationTypeEnum.Sum
          };
        }
        // Check: don't allow to put two copies of one Custom Calc
        if (!event.container.data.some(f => f.calcId === item.calcId)) {
          // insert new item into the position
          event.container.data.splice(event.currentIndex, 0, item);
        }
      } else {
        // remove Custom Calc item from the previous location
        const item = event.previousContainer.data.splice(event.previousIndex, 1)[0];

        // check, if Calc item was moved to another list
        if (event.container.id !== 'filtersList') {
          // Check: don't allow to put two copies of one Custom Calc
          if (!event.container.data.some(f => f.calcId === item.calcId)) {
            item.aggregationType = item.aggregationType || HoverInfoAggregationTypeEnum.Sum;
            // insert new item into the position
            event.container.data.splice(event.currentIndex, 0, item);
          }
        }
      }
      this.checkCalcForErrors();
      return;
    }

    // Case 4: selected item copied from filtersList to any other (filtersList always static)
    if (event.previousContainer.id === 'filtersList') {
      // from 'filtersList' to 'fieldsConfig' OR 'custom Calc list'
      event.previousIndex = this.filtersList.findIndex(item => item.name === event.item.data.name);
      const prevItem = event.previousContainer.data[event.previousIndex]; // ALWAYS copy from old to the new

      const item = {
        ...prevItem,
        calcOperation: null,
        aggregationType: null,
        isDistinctCount: event.container.id === 'fieldsConfig' ? prevItem.isDistinctCount : !prevItem.isMeasure,
        children: []
      };

      if (event.container.id !== 'filtersList' && (item.isMeasure || item.isDistinctCount)) {
        item.calcOperation = '+';
        // Set aggregation type to "Sum" by default
        item.aggregationType = HoverInfoAggregationTypeEnum.Sum;
      }

      // insert new item
      event.container.data.splice(event.currentIndex, 0, item);
      this.checkCalcForErrors();
      return;
    }

    // from 'fieldsConfig' or any custom calc list
    if (isCalcDisabled && event.container.id !== 'filtersList' && event.container.id !== 'fieldsConfig') {
      this.checkCalcForErrors();
      return; // don't allow to add non-number item for Custom Calc
    }

    // extract item from old list
    const prevItem = event.previousContainer.data.splice(event.previousIndex, 1)[0];

    // Case 5: item moved to the base list filtersList
    if (event.container.id === 'filtersList') {
      this.checkCalcForErrors();
      return; // base list already have all items, don't change it
    }

    // Default: add item to the destination list
    const item = {
      ...prevItem,
      isDistinctCount: event.container.id === 'fieldsConfig' ? prevItem.isDistinctCount : !prevItem.isMeasure,
      children: event.container.id === 'fieldsConfig' ? [] : null
    };

    // Default: operation '+' for numeric argument
    if (prevItem.isMeasure || prevItem.isDistinctCount) {
      item.calcOperation = prevItem.calcOperation || '+';
    }

    event.container.data.splice(event.currentIndex, 0, item);
    this.checkCalcForErrors();
  }

  // method to remove temporal item which was added during the dragging to make effect "Don't move source item"
  cleanupSourceFiltersList() {
    this.filtersList = this.filtersList.filter(f => !f.temp);
  }

  isHighlightSource(filterModel: IFiltersModel): boolean {
    return (
      this.isFiltersListEntered &&
      this.draggingStartContainer?.id !== 'filtersList' &&
      this.draggingField?.name === filterModel.name
    );
  }

  // get hash for filter to identify unique id, based on filterName + childrenNames + aggregationType
  getFilterHash(item: IHoverInfoFilterModel): string {
    let id = `${item.name}|${item.isDistinctCount ? 'DistinctCount' : item.aggregationType}`;
    if (item.children) {
      item.children.forEach(child => {
        id += `|child(${this.getFilterHash(child)})`;
      });
    }
    if (item.type === FiltersTypeEnum.Calculated) {
      item.calcArguments.forEach(arg => {
        id += `|calc(${this.getFilterHash(arg)}, ${arg.aggregationType}, ${arg.calcOperation})`;
      });
    }
    return id;
  }

  removeDuplicatedFilters() {
    // mark all filters as Not duplicated
    this.infoFieldsList.forEach(f => {
      f.isDuplicate = false;
    });

    for (let i = 0; i < this.infoFieldsList.length; i++) {
      const item = this.infoFieldsList[i];
      const itemId = this.getFilterHash(item);
      // --> console.log('>>> ID:', itemId);
      if (item.isDuplicate) {
        // eslint-disable-next-line no-continue
        continue; // ignore already marked items
      }
      if (item.type === FiltersTypeEnum.Calculated && item.calcArguments.length === 0) {
        item.isDuplicate = true; // Calc is empty, so remove it
        // eslint-disable-next-line no-continue
        continue;
      }
      for (let j = i + 1; j < this.infoFieldsList.length; j++) {
        const anotherItem = this.infoFieldsList[j];
        const anotherId = this.getFilterHash(anotherItem);
        if (itemId === anotherId) {
          anotherItem.isDuplicate = true; // mark as duplicated
        }
      }
    }

    // remove duplicated items
    this.infoFieldsList = this.infoFieldsList.filter(f => !f.isDuplicate);
    this.checkCalcForErrors(); // check Calc errors after removing duplicated items
  }

  /**
   * Calculate min-max values for Preview mode
   */
  private getCalcMinMaxValues(field: IHoverInfoFilterModel) {
    field.calcArguments?.forEach(arg => {
      if (arg.isCalcGroup) {
        this.getCalcMinMaxValues(arg);
      } else {
        if (arg.calcArguments) {
          arg.calcArguments.forEach(item => this.getCalcMinMaxValues(item));
        } else {
          // just simple Numeric field
          arg.minMaxValue = this.filtersList.find(f => f.name === arg.name)?.minMaxValue || null;
        }
      }
    });
    if (field.calcArguments) {
      const result = this.calcResult(field);
      field.minMaxValue = {
        min: result,
        max: result
      };
    }
  }

  private calcResult(field: IHoverInfoFilterModel): number {
    const preparedArguments: ICalcArgument[] = [];
    for (let index = 0; index < field.calcArguments.length; index++) {
      const argument = field.calcArguments[index];
      let value;
      if (argument.isCalcGroup) {
        if (argument.calcArguments.length > 1) {
          value = this.calcResult(argument);
        } else {
          value = NaN;
        }
      } else if (argument.isDistinctCount) {
        value = argument.discreteValues?.length || 0;
      } else {
        value = stringToNumber(argument.minMaxValue?.max?.toString() || '');
      }
      preparedArguments.push({
        value,
        argument
      });
    }
    return getResultForCalcArguments(preparedArguments);
  }

  getPreviewFieldValue(field: IHoverInfoFilterModel): string {
    if (field.type === FiltersTypeEnum.Calculated) {
      const result = this.calcResult(field);
      return `${customFormatBigNumber(result, this.formattings[field.name + field.calcId])}`;
    }

    if (field.discreteValues) {
      // Discrete value with children?
      if (field.children?.length > 0) {
        let result = '';

        // current implementation support only one child
        const child = field.children[0];

        // show only first two items as example
        // we don't know more or less values, because child is Number with Aggregation type,
        // and we know only min and max values here
        // also sometimes Discrete Values can have only one value in the list
        // So, one or two pairs enough to show example
        for (let i = 0; i < Math.min(field.discreteValues.length, 2); i++) {
          const discreteName = field.discreteValues[i].name;
          const value = i === 0 ? child.minMaxValue?.min : child.minMaxValue?.max;
          const discreteValue = field.isInvalid
            ? 'NaN'
            : child.type === FiltersTypeEnum.Date
            ? moment(value).format('YYYY-MM-DD')
            : formatBigNumber(value as number);
          result += `
            <div class="field-value-item">
              <div class="field-name">${discreteName}:</div>
              <div class="field-value ${field.isInvalid ? 'invalid' : ''}">${discreteValue}</div>
            </div>`;
        }
        return result;
      }

      if (field.isDistinctCount) {
        return `
        <div class="field-value-item">
          <div class="field-name">Distinct:</div>
          <div class="field-value ${field.isInvalid ? 'invalid' : ''}">${field.discreteValues.length}</div>
        </div>`;
      }

      // Discrete value without children filters
      return field.discreteValues[0].name;
    }

    // Aggregation type
    return field.aggregationType === HoverInfoAggregationTypeEnum.MinMax
      ? `Min:${
          field.type === FiltersTypeEnum.Date
            ? moment(field.minMaxValue.min).format('YYYY-MM-DD')
            : this.dataService.getCustomFormattedNumber(field.minMaxValue.min as number, this.formattings[field.name])
        }; Max:${
          field.type === FiltersTypeEnum.Date
            ? moment(field.minMaxValue.max).format('YYYY-MM-DD')
            : this.dataService.getCustomFormattedNumber(field.minMaxValue.max as number, this.formattings[field.name])
        }`
      : `${field.aggregationType}: ${
          field.type === FiltersTypeEnum.Date
            ? moment(field.minMaxValue.max).format('YYYY-MM-DD')
            : this.dataService.getCustomFormattedNumber(
                field.minMaxValue.max as number, // ((field.minMaxValue.min as number) + (field.minMaxValue.max as number)) / 2,
                this.formattings[field.name]
              )
        }`;
  }

  onFiltersListChipClick(filterModel: IFiltersModel, i: number, event) {
    if (!filterModel.isMeasure || filterModel.type !== FiltersTypeEnum.Continuous) return;
    if (this.expandedFiltersListChipIndex === i) {
      this.expandedFiltersListChipIndex = -1;
      this.expandedFiltersListChipName = '';
    } else {
      const name = filterModel.customCalcField
        ? filterModel.customCalcField.name + filterModel.customCalcField.calcId
        : filterModel.name;
      if (!this.formattings[name]) {
        this.formattings[name] = {
          prefix: '',
          suffix: '',
          multiplier: 1,
          fractionDigit: 2
        };
      }

      this.expandedFiltersListChipIndex = i;
      this.expandedFiltersListChipName = name;
      this.formatingDisplayNumber = filterModel.minMaxValue?.max as number;
    }
  }

  onFormattingPrefixChange(event: MatSelectChange) {
    this.isFiltersTouched = true;
    this.formattings[this.expandedFiltersListChipName].prefix = event.value ? event.value : '';
  }

  onFormattingSuffixChange(event: MatSelectChange) {
    this.isFiltersTouched = true;
    this.formattings[this.expandedFiltersListChipName].suffix = event.value
      ? this.formattingSuffixesDisplay[event.value]
      : '';
    this.formattings[this.expandedFiltersListChipName].multiplier = event.value
      ? this.formattingMultipliers[event.value]
      : 1;
    this.formattings[this.expandedFiltersListChipName].fractionDigit = 2;
  }

  onFormattingFractionLeft() {
    this.isFiltersTouched = true;
    if (this.formattings[this.expandedFiltersListChipName].fractionDigit == undefined)
      this.formattings[this.expandedFiltersListChipName].fractionDigit = 2;
    this.formattings[this.expandedFiltersListChipName].fractionDigit--;
    if (this.formattings[this.expandedFiltersListChipName].fractionDigit < 0)
      this.formattings[this.expandedFiltersListChipName].fractionDigit = 0;
  }

  onFormattingFractionRight() {
    this.isFiltersTouched = true;
    if (this.formattings[this.expandedFiltersListChipName].fractionDigit == undefined)
      this.formattings[this.expandedFiltersListChipName].fractionDigit = 2;
    this.formattings[this.expandedFiltersListChipName].fractionDigit++;
    if (this.formattings[this.expandedFiltersListChipName].fractionDigit > 2)
      this.formattings[this.expandedFiltersListChipName].fractionDigit = 2;
  }

  isFormattingFractionLeftDisabled() {
    return this.formattings[this.expandedFiltersListChipName].fractionDigit == 0;
  }

  isFormattingFractionRightDisabled() {
    return this.formattings[this.expandedFiltersListChipName].fractionDigit == 2;
  }

  isChipFormatted(filterModel: IFiltersModel) {
    const name = filterModel.customCalcField
      ? filterModel.customCalcField.name + filterModel.customCalcField.calcId
      : filterModel.name;
    return (
      this.formattings[name] &&
      (this.formattings[name].prefix ||
        this.formattings[name].suffix ||
        (!this.formattings[name].suffix &&
          (this.formattings[name].fractionDigit === 0 || this.formattings[name].fractionDigit === 1)))
    );
  }

  getFormattedDisplayNumber() {
    return this.dataService.getCustomFormattedNumber(
      this.formatingDisplayNumber,
      this.formattings[this.expandedFiltersListChipName]
    );
  }

  getFractionPickerDisplayNumber() {
    return formatBigNumber(
      this.formattings[this.expandedFiltersListChipName].multiplier
        ? this.formatingDisplayNumber * this.formattings[this.expandedFiltersListChipName].multiplier
        : this.formatingDisplayNumber
    );
  }

  getFormattingIndicatorLeftPos() {
    const fractionPickerDisplayNum = this.getFractionPickerDisplayNumber();
    const num1 = fractionPickerDisplayNum.slice(
      0,
      fractionPickerDisplayNum.length - (2 - this.formattings[this.expandedFiltersListChipName].fractionDigit)
    );
    const num2 = num1.slice(0, num1.length - 1);
    document.body.appendChild(this.formattingFractionIndicatorSizeTester);
    this.formattingFractionIndicatorSizeTester.innerText = num1;
    const w1 = this.formattingFractionIndicatorSizeTester.offsetWidth;
    this.formattingFractionIndicatorSizeTester.innerText = num2;
    const w2 = this.formattingFractionIndicatorSizeTester.offsetWidth;
    this.formattingFractionIndicatorSizeTester.innerText = '';
    document.body.removeChild(this.formattingFractionIndicatorSizeTester);
    return w2 + (w1 - w2) / 2 - 5;
  }

  createFormattingFractionIndicatorSizeTester() {
    this.formattingFractionIndicatorSizeTester = document.createElement('p');
    this.formattingFractionIndicatorSizeTester.style.fontSize = '13px';
    this.formattingFractionIndicatorSizeTester.style.width = 'fit-content';
  }

  onAggregationTypeChange(field: IHoverInfoFilterModel) {
    this.isFiltersTouched = true;
  }

  addCustomCalcGroup(field: IHoverInfoFilterModel) {
    field.calcArguments.push({
      name: '()',
      type: FiltersTypeEnum.Calculated,
      isMeasure: true,
      // discreteValues?: Array<IFilterDiscreteValue>;
      minMaxValue: {
        min: 0,
        max: 0
      },
      // aggregationType?: HoverInfoAggregationTypeEnum;
      // preview?: string; // text preview for field

      // two or more arguments
      calcOperation: '+',
      calcArguments: [],
      calcId: shortUid(),
      isCalcGroup: true, // if true, this element accepting children calcArguments, which should be calculated before using with other external arguments
      aggregationType: HoverInfoAggregationTypeEnum.Sum,

      // children?: Array<IHoverInfoFilterModel>;

      // fields to show errors
      isInvalid: true,
      errors: {
        needArgument: true
      }
    } as IHoverInfoFilterModel);

    // refresh list of drop lists after rendering changes
    setTimeout(() => {
      this.findAllDropLists();
      this.checkCalcForErrors();
    }, 200);
  }
}
