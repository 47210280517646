<div class="upload-file-wrapper" [class.disabled]="disabled">
  <div class="selected-files" *ngIf="files?.length > 0">
    <div *ngFor="let file of files; let i = index" class="file">
      <div class="file-wrapper">
        <span class="number">{{ i + 1 }}.</span>
        <span class="name">{{ file.file.name }}</span>
        <span class="size">{{ file.file.size | fileSize }}</span>
        <button
          class="delete-button"
          [disabled]="isDisableDelete"
          (click)="deleteFile(file, i)"
          matTooltip="Delete"
          matTooltipPosition="above"
          matTooltipClass="above"
          matRipple
        >
          <svg-icon svgClass="icon" name="icon-remove-filter"></svg-icon>
        </button>
      </div>
      <div class="file-label-wrapper">
        <span class="label">File</span>
      </div>
      <mat-progress-bar
        *ngIf="isNeedUpload"
        mode="determinate"
        class="file-upload-progress-bar"
        [value]="file.progress"
        [ngClass]="{ uploaded: file.isUploaded }"
      ></mat-progress-bar>
      <div class="errors" *ngIf="showError">
        <div class="error" *ngIf="isNeedUpload && !file.isUploaded && file.progress > 0">Uploading...</div>
        <div class="error" *ngIf="maxFileLength && file.file.size > maxFileLength">
          File size exceeds the maximum limit ({{ maxFileLength | fileSize }})
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!files || files?.length < 1 || isMultiple"
    draggable="true"
    class="upload-area"
    [class.has-selected-files]="files?.length > 0"
    ngClass="{{ dragAreaClass }}"
  >
    <div class="errors" *ngIf="showError">
      <div class="error" *ngFor="let error of errors">{{ error }}</div>
    </div>

    <div class="upload-icon" *ngIf="!files || files?.length < 1">
      <svg-icon svgClass="icon" name="icon-upload"></svg-icon>
    </div>

    <div class="upload-message">
      <span class="upload-message__text">{{ messageTitle }}</span>
      <span class="upload-message__text">or</span>
      <button mat-flat-button matSuffix [disabled]="disabled" (click)="fileInput.click()">
        {{ buttonTitle }}
      </button>
      <input
        hidden
        #fileInput
        [disabled]="disabled"
        (change)="onChangeSelectFiles($event)"
        type="file"
        id="file"
        [multiple]="isMultiple"
        [attr.accept]="accept"
      />
    </div>
  </div>
</div>
