import { IImageData } from '@app/models/image.model';
import {
  animationFrameInMS,
  imageBaseSize,
  imageMinSpeed,
  imagePosChangeInTime,
  stageFriction
} from '@app/shared/constants/viewport';
import { Container, Graphics, Rectangle, RenderTexture, Sprite, Texture } from 'pixi.js';
import { ResourceManagerService } from '../services/resource-manager/resource-manager.service';
import { ImageInfoPanel } from './image-info-panel';
import gsap from 'gsap/all';
import { shortUid } from '@app/shared/utils/short-uid';
import { IPosition } from '@app/models/workspace-list.model';

export enum ImagePlaceholder {
  DEFAULT, // placeholder-collagia image by default
  COLUMBIA,
  NIKE
}

export class ImageContainer extends Container {
  private id: string = '';
  private displayName: string = '';
  private size: number = imageBaseSize;
  private view: string;
  private bg: Container;
  private imageSprite: Sprite;
  private placeholderImage: string;
  private tempSprite: Sprite;
  private shadowTexture: RenderTexture;
  private selectedShadowTexture: RenderTexture;
  private shadowSprite: Sprite;
  public shadow: Container;

  private mouseOver: boolean = false;
  private selected: boolean = false;
  private positionOnDragStart;

  private velocityX = 0;
  private velocityY = 0;
  private percentChangeX = 0;
  private percentChangeY = 0;
  private timeSinceRelease = 0;

  private data: IImageData;

  public infoPanel: ImageInfoPanel;

  private viewUpdateAnimation: gsap.core.Timeline;
  private overlappedBy: Array<string> = [];

  private contextMenuLocalPosition: IPosition;

  constructor(
    private readonly uid = shortUid(),
    id: string,
    displayName: string,
    size: number,
    view: string,
    position: { x: number; y: number },
    resourceManager: ResourceManagerService,
    placeholder: ImagePlaceholder = ImagePlaceholder.DEFAULT,
    dropShadowTextures: { shadow: RenderTexture; selectedShadow: RenderTexture },
    isPlaceholderMode = false
  ) {
    super();
    this.id = id;
    this.displayName = displayName;
    this.size = size;
    this.view = view;
    this.selected = false;
    this.placeholderImage = resourceManager.getPlaceholderImageName(placeholder);
    this.shadowTexture = dropShadowTextures.shadow;
    this.selectedShadowTexture = dropShadowTextures.selectedShadow;
    this.createDropShadow();
    this.createBackground();
    this.createSprite(resourceManager, isPlaceholderMode);
    this.updatePosition(position);
    this.updateHitArea();

    this.interactive = true;
  }

  private createDropShadow() {
    this.shadowSprite = new Sprite(this.shadowTexture);
    this.shadowSprite.anchor.set(0.5);
    this.shadow = new Container();
    this.shadow.addChild(this.shadowSprite);
    this.addChild(this.shadow);
    this.updateDropShadow();
  }

  private updateDropShadow() {
    this.shadowSprite.scale.set(this.size / 256);
  }

  private createBackground() {
    const bgSprite = new Sprite(Texture.WHITE);
    bgSprite.width = this.size;
    bgSprite.height = this.size;
    bgSprite.anchor.set(0.5);
    bgSprite.tint = 0xffffff;

    this.bg = new Container();
    this.bg.addChild(bgSprite);
    this.addChild(this.bg);
  }

  private createSprite(resourceManager: ResourceManagerService, isPlaceholderMode = false) {
    this.imageSprite = new Sprite();
    this.updateSprite(resourceManager, isPlaceholderMode);
    this.imageSprite.anchor.set(0.5);
    this.addChild(this.imageSprite);
  }

  public updateSprite(resourceManager: ResourceManagerService, isPlaceholderMode = false) {
    if (isPlaceholderMode) {
      this.updateSpriteTexture(resourceManager, null);
      return;
    }

    let texture = resourceManager.checkAndGetTexture(`${this.id}_${this.view}-${this.size}`);
    if (!texture && this.size !== imageBaseSize)
      texture = resourceManager.checkAndGetTexture(`${this.id}_${this.view}-${imageBaseSize}`);

    this.updateSpriteTexture(resourceManager, texture);
  }

  private updateSpriteTexture(resourceManager: ResourceManagerService, texture: Texture) {
    this.imageSprite.transform.scale.set(1);
    if (!texture) texture = resourceManager.checkAndGetTexture(this.placeholderImage);
    this.imageSprite.texture = texture;
    const spriteSize = Math.max(texture.width, texture.height);
    this.imageSprite.transform.scale.set(this.size / spriteSize);
  }

  private createTempSprite(resourceManager: ResourceManagerService) {
    this.tempSprite = new Sprite(Texture.WHITE);
    this.tempSprite.anchor.set(0.5);
    this.tempSprite.width = this.size;
    this.tempSprite.height = this.size;
    this.tempSprite.alpha = 0;
    this.tempSprite.transform.scale.set(1);
    this.addChildAt(this.tempSprite, this.children.indexOf(this.imageSprite));

    let texture = resourceManager.checkAndGetTexture(`${this.id}_${this.view}-${this.size}`);
    if (!texture && this.size !== imageBaseSize)
      texture = resourceManager.checkAndGetTexture(`${this.id}_${this.view}-${imageBaseSize}`);

    if (texture) {
      this.tempSprite.texture = texture;
      const spriteSize = Math.max(texture.width, texture.height);
      this.tempSprite.transform.scale.set(this.size / spriteSize);
    } else {
      this.tempSprite.texture = resourceManager.checkAndGetTexture(this.placeholderImage);
      this.tempSprite.transform.scale.set((this.size * 0.8) / this.tempSprite.width);
    }
  }

  private swapImageAndTempSpriteTextures() {
    this.imageSprite.texture = this.tempSprite.texture;
    this.imageSprite.width = this.tempSprite.width;
    this.imageSprite.height = this.tempSprite.height;
    this.imageSprite.scale.set(this.tempSprite.scale.x);
    this.imageSprite.alpha = 1;
    this.removeChild(this.tempSprite);
    this.tempSprite = null;
  }

  private updatePosition(position: { x: number; y: number }) {
    const factor = this.size / 256;
    this.position?.set(position.x * factor, position.y * factor);
    this.alpha = 0;
  }

  private updateHitArea() {
    this.hitArea = new Rectangle(-this.size / 2, -this.size / 2, this.size, this.size);
  }

  updateView(resourceManager: ResourceManagerService, view: string, renderFuncRef) {
    if (this.view === view) return;
    this.view = view;

    this.viewUpdateAnimation?.kill();
    if (this.tempSprite && this.children.includes(this.tempSprite)) this.removeChild(this.tempSprite);

    this.viewUpdateAnimation = gsap.timeline({
      ease: 'power1.inOut',
      onUpdate: () => {
        renderFuncRef();
      },
      onComplete: () => {
        this.swapImageAndTempSpriteTextures();
        renderFuncRef();
      }
    });
    this.viewUpdateAnimation.addLabel('animationStart', 0);
    this.viewUpdateAnimation.killTweensOf(this.tempSprite);
    this.viewUpdateAnimation.killTweensOf(this.imageSprite);

    this.createTempSprite(resourceManager);

    this.viewUpdateAnimation.to(
      this.tempSprite,
      {
        duration: 0.28,
        alpha: 1
      },
      'animationStart'
    );
    this.viewUpdateAnimation.to(
      this.imageSprite,
      {
        duration: 0.28,
        alpha: 0
      },
      'animationStart'
    );
  }

  updateResolution(resourceManager: ResourceManagerService, size: number) {
    if (this.viewUpdateAnimation?.isActive()) this.viewUpdateAnimation.totalProgress(1).kill();
    this.size = size;
    this.updateDropShadow();
    this.updateSprite(resourceManager);
  }

  resize(resourceManager: ResourceManagerService, size: number, resizeFactor: number, updatePosition = true) {
    if (this.viewUpdateAnimation?.isActive()) this.viewUpdateAnimation.totalProgress(1).kill();
    this.size = size;

    (this.bg.children[0] as Sprite).width = this.size;
    (this.bg.children[0] as Sprite).height = this.size;

    this.imageSprite.scale.x *= resizeFactor;
    this.imageSprite.scale.y *= resizeFactor;

    this.updateDropShadow();

    const texture = resourceManager.checkAndGetTexture(`${this.id}_${this.view}-${this.size}`);
    if (texture) {
      this.imageSprite.texture = texture;
      this.imageSprite.transform.scale.set(1);
      const spriteSize = Math.max(this.imageSprite.width, this.imageSprite.height);
      this.imageSprite.transform.scale.set((this.size * this.imageSprite.scale.x) / spriteSize);
    }

    if (updatePosition) {
      this.position.x *= resizeFactor;
      this.position.y *= resizeFactor;
    }

    this.updateHitArea();
  }

  updateDecelerateProperties(coordinates, time) {
    this.velocityX = (this.position.x - coordinates.x) / time;
    this.velocityY = (this.position.y - coordinates.y) / time;
    this.percentChangeX = stageFriction;
    this.percentChangeY = stageFriction;
    this.timeSinceRelease = 0;
  }

  clearDecelerateProperties() {
    this.velocityX = 0;
    this.velocityY = 0;
    this.percentChangeX = 0;
    this.percentChangeY = 0;
    this.timeSinceRelease = 0;
  }

  decelerate(worldWidth, worldHeight) {
    const ti = this.timeSinceRelease;
    const tf = this.timeSinceRelease + imagePosChangeInTime;

    if (this.velocityX) {
      const k = this.percentChangeX;
      const lnk = Math.log(k);
      let offset =
        ((this.velocityX * animationFrameInMS) / lnk) *
        (Math.pow(k, tf / animationFrameInMS) - Math.pow(k, ti / animationFrameInMS));
      if (this.x + this.parent.x + offset <= 10) {
        if (offset < 0) offset *= -1;
        if (this.velocityX < 0) this.velocityX *= -1;
      } else if (this.x + this.parent.x + offset >= worldWidth - 10) {
        if (offset > 0) offset *= -1;
        if (this.velocityX > 0) this.velocityX *= -1;
      }
      this.x += offset;
      this.velocityX *= Math.pow(this.percentChangeX, imagePosChangeInTime / animationFrameInMS);
    }

    if (this.velocityY) {
      const k = this.percentChangeY;
      const lnk = Math.log(k);
      let offset =
        ((this.velocityY * animationFrameInMS) / lnk) *
        (Math.pow(k, tf / animationFrameInMS) - Math.pow(k, ti / animationFrameInMS));
      if (this.y + this.parent.y + offset <= 10) {
        if (offset < 0) offset *= -1;
        if (this.velocityY < 0) this.velocityY *= -1;
      } else if (this.y + this.parent.y + offset >= worldHeight - 10) {
        if (offset > 0) offset *= -1;
        if (this.velocityY > 0) this.velocityY *= -1;
      }
      this.y += offset;
      this.velocityY *= Math.pow(this.percentChangeY, imagePosChangeInTime / animationFrameInMS);
    }

    this.timeSinceRelease += imagePosChangeInTime;

    if (this.velocityX && this.velocityY) {
      if (Math.abs(this.velocityX) < imageMinSpeed && Math.abs(this.velocityY) < imageMinSpeed) {
        this.velocityX = 0;
        this.velocityY = 0;
      }
    } else {
      if (Math.abs(this.velocityX || 0) < imageMinSpeed) {
        this.velocityX = 0;
      }
      if (Math.abs(this.velocityY || 0) < imageMinSpeed) {
        this.velocityY = 0;
      }
    }
  }

  changeOverlappedState(show: boolean, groupLabelId: string): void {
    const prevState = this.overlappedBy.length;
    if (show) {
      if (this.overlappedBy.findIndex(item => item === groupLabelId) === -1) {
        this.overlappedBy.push(groupLabelId);
      }
    } else {
      this.overlappedBy = this.overlappedBy.filter(item => item !== groupLabelId);
    }
    const currentState = this.overlappedBy.length;
    if (this.infoPanel) {
      if (prevState === 0 && currentState > 0) {
        this.infoPanel.infoHtmlRef.style.opacity = '0.7';
        this.infoPanel.infoHtmlRef.style.filter = 'blur(5px)';
      } else if (prevState > 0 && currentState === 0) {
        this.infoPanel.infoHtmlRef.style.opacity = '1';
        this.infoPanel.infoHtmlRef.style.filter = 'unset';
      }
    }
  }

  moved(): number {
    return this.velocityX || this.velocityY;
  }

  select() {
    this.setSelectedState(true);
    this.shadowSprite.texture = this.selectedShadowTexture;
  }

  deselect() {
    this.setSelectedState(false);
    this.shadowSprite.texture = this.shadowTexture;
  }

  savePositionOnDragStart() {
    this.positionOnDragStart = { x: this.position.x, y: this.position.y };
  }

  moveSelected(posChange) {
    this.position.x = this.positionOnDragStart.x + posChange.x;
    this.position.y = this.positionOnDragStart.y + posChange.y;
  }

  getDragStartPosition() {
    return this.positionOnDragStart;
  }

  setSelectedState(selected: boolean): void {
    this.selected = selected;
  }

  getSelectedState(): boolean {
    return this.selected;
  }

  setMouseOverState(mouseOver) {
    this.mouseOver = mouseOver;
  }

  getMouseOverState(): boolean {
    return this.mouseOver;
  }

  getId(): string {
    return this.id;
  }

  getUid(): string {
    return this.uid;
  }

  setDisplayName(value: string) {
    this.displayName = value;
  }

  getDisplayName(): string {
    return this.displayName;
  }

  getImageBounds() {
    return this.bg.getBounds();
  }

  getImageSize() {
    return this.bg.width;
  }

  setContextMenuLocalPosition(contextMenuLocalPosition: IPosition): void {
    this.contextMenuLocalPosition = contextMenuLocalPosition;
  }

  getContextMenuGlobalPosition(): IPosition {
    const imageGlobalPosition: IPosition = this.getGlobalPosition();
    const { width } = this.getImageBounds();
    const imageSize = this.getImageSize();
    const scale = width / imageSize;
    return {
      x: imageGlobalPosition.x + this.contextMenuLocalPosition.x * scale,
      y: imageGlobalPosition.y + this.contextMenuLocalPosition.y * scale
    };
  }

  setData(data) {
    this.data = data;
    this.setDisplayName(data?.displayName);
  }

  getData(): IImageData {
    return this.data;
  }

  getView() {
    return this.view;
  }

  getSize() {
    return this.size;
  }

  getPreview() {
    const preview = new Container();
    const bg = new Sprite(Texture.WHITE);
    bg.width = this.size;
    bg.height = this.size;
    bg.anchor.set(0.5);
    bg.tint = 0xffffff;
    const shadow = new Sprite(this.shadowTexture);
    shadow.anchor.set(0.5);
    shadow.scale.set(this.size / 256);
    const image = new Sprite();
    image.texture = this.imageSprite.texture;
    image.scale.set(this.imageSprite.scale.x);
    image.anchor.set(0.5);
    preview.addChild(shadow, bg, image);
    return preview;
  }
}
