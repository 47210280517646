import { LookupTableService } from '@components/lookup-table';
import { Select, Store } from '@ngxs/store';
import { Viewport } from 'pixi-viewport';
import { Container, Graphics, ILoaderResource, InteractionEvent, Loader, Sprite, Texture } from 'pixi.js';
import { BehaviorSubject, Observable, Subject, Subscription, race, timer, of } from 'rxjs';
import { FrameContainer } from '../../custom-pixi-containers/frame-container';
import { ImageContainer, ImagePlaceholder } from '../../custom-pixi-containers/image-container';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import gsap, { PixiPlugin } from 'gsap/all';
import * as PIXI from 'pixi.js';
import { MagnifyTool } from './magnify-tool';
import { MatrixToolEnum, ValidMatrix } from '@app/state/tools/tools.model';
import { animationFrameInMS, imageBaseSize } from '@app/shared/constants/viewport';
import { IFiltersModel } from '@app/models/filters.model';
import { SetSelectedTool } from '@app/state/tools/tools.actions';
import { CursorStates, CursorStyles } from '@app/shared/enums/cursor-styles.enum';
import {
  IFrameStateModel,
  IGroupStateModel,
  IImageStateModel,
  IMatrixStateModel,
  IPosition,
  ISavedPosition,
  IViewportData
} from '@app/models/workspace-list.model';
import { DataService } from '../data/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IImageData } from '@app/models/image.model';
import {
  UpdateFrame,
  AddFrame,
  RemoveFrame,
  RemoveFrameFromDatabase,
  SetViewport,
  UpdateImages,
  UpdateImage,
  SetCustomAttributes,
  UpdateMatrixState,
  SetMatrixState,
  UpdateFramesAndImages,
  UpdateFrames,
  UpdateGroup,
  SetGroups
} from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import {
  DockingSide,
  GroupOrientation,
  IHoverInfoFieldModel,
  IRankInfoPanelConfig,
  InfoPanelPosition
} from '@app/models/study-setting.model';
import { GroupLabel } from '@app/custom-pixi-containers/group-label';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { GroupContainer } from '@app/custom-pixi-containers/group-container';
import { IFrameTitleChangeEvent } from '@app/models/frame-title-change-event.model';
import { PublishIconStates } from '@app/shared/enums/publish-icon-states.enum';
import { IBounds } from '@app/models/bounds.model';
import { debounce, filter, map, take, tap } from 'rxjs/operators';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { GroupLabelsContainer } from '@app/custom-pixi-containers/group-labels-container';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDimensions, IWorkspaceTools, IZoomSettings } from '../../models/workspace-list.model';
import { Inject, ViewContainerRef } from '@angular/core';
import { WINDOW } from '../../shared/utils/window';
import { CustomPluginDrag } from '@app/custom-pixi-containers/custom-viewport/CustomPluginDrag';
import { SetToolboxState } from '../../state/tools/tools.actions';
import { ToolsState } from '../../state/tools/tools.state';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import {
  IFramePresentationState,
  IGroupPresentationState,
  IImageInfoPanelPresentationState,
  IImagePresentationState,
  IPresentationState
} from '@app/models/presentation.models';
import { FriendlyNameService } from '../friendly-name/friendly-name.service';
import { SetHighlightFilters } from '../../state/study/study.actions';
import { UserDataState } from '../../state/user-data/user-data.state';
import { CollageState } from '../../state/collage/collage.state';
import { clone } from '../../shared/utils/object';
import { FiltersService } from '../filters/filters.service';
import { IContextMenuOpenCloseSubject, IImagePopperMenuSubject } from '@app/models/matrix.model';

export const LOW_IMAGE_SIZE_RESOURCE = 256;
export const MIDDLE_IMAGE_SIZE_RESOURCE = 512;
export const HIGH_IMAGE_SIZE_RESOURCE = 1024;

@UntilDestroy()
export abstract class BaseMatrix {
  @Select(StudyState.getMatrixStateModel)
  public currentMatrix$: Observable<IMatrixStateModel>;

  // @Select(StudyState.getHighlightFilters)
  // public highlightFilters$: Observable<IFiltersModel[]>;

  /**
   * Need to be used to block opening of workspace selector
   * Anytime matrix is not ready (images are loading, animation is happening) this should be true
   */
  public isUpdating: Subject<boolean> = new Subject();
  public clearCompass: Subject<boolean> = new Subject();

  public renderNewMatrix: Subject<{ restore: boolean }>;
  public resizeImageMatrix: Subject<{ instantResizeMatrix: boolean }>;
  public recenterMatrix: Subject<IPosition>;
  public matrix: Container;
  public imageMatrix: Container;

  protected displayData: Array<any> = [];

  protected imageMatrixPaddings = { top: 150, bottom: 150, leftRight: 100 };
  protected matrixResizerSpriteRef: Sprite;

  public imageDesiredSizes = { width: LOW_IMAGE_SIZE_RESOURCE, height: LOW_IMAGE_SIZE_RESOURCE };
  protected distanceBetweenImages = 32;
  protected imageSize = LOW_IMAGE_SIZE_RESOURCE;
  protected resolution = 1;

  protected frameBorderWidth: number;
  protected frameBorderBaseWidth = 20;
  protected frameMarginTop: number;

  protected viewportBaseScale = 1;
  public zoomFactor = 1;

  protected selectedTool: MatrixToolEnum;
  protected selectedView: string;
  protected imageViewOverridesWithId = {};
  protected imageViewOverridesWithIndex = {};
  protected defaultView: string;

  public imagePopperMenu: Subject<IImagePopperMenuSubject>;
  public imagePopperMenuShown = false;

  protected imageHoverTimeout;
  protected imageInfoShown = false;

  protected imageDragging = false;
  protected imageDragMoveListenerRef;
  protected imageDragSavedPositions: Array<ISavedPosition>;
  protected imageDragStartPositionFromAnchor;
  protected imageMovePrevPosition: IPosition;
  protected imageMoved = false;
  private imageDragSourceFrames: FrameContainer[] = [];
  private draggingImageRefs: ImageContainer[] = [];
  protected imageMouseDownPosition = undefined;
  private imageReorderAnimationTl: gsap.core.Timeline;
  protected frameCollidedOnImageDrag: FrameContainer;
  private imageIndexInFrameOnImageDrag;
  private imagePreviewsOnImageDrag = [];

  private imageBaseScale = 1;
  private imageHoverScale = 1.03;
  private imageDragScale = 1.05;
  private imageShadowBaseAlpha = 1;
  private imageShadowHoverAlpha = 0.7;
  private imageShadowDragAlpha = 0.5;
  private imageScaleAnimationDuration = 0.1;

  protected multiSelectActive = false;
  private multiSelectRect = new Graphics();
  private multiSelectMoved = false;

  private numImgesDecelerating = 0;

  protected frameFeatureName: string;
  private framingStarted;
  private startCoordinates;
  private frameHoverTimeout;
  protected frameDragging = false;
  protected frameHover = true;
  protected frameMouseMoveState: CursorStates = CursorStates.Drag;
  protected matrixMouseMoveListenerRef;
  private matrixMouseOutListenerRef;
  private matrixClickDuringHighlightRef;
  private activeFrameRef: FrameContainer;
  protected draggingFrameRef: FrameContainer;
  private frameDragSourceFrame: FrameContainer = undefined;

  protected frameMovePrevPosition: IPosition;
  protected frameMoved = false;

  protected frameTitles: { [key: string]: { name: string; value: string } } = {};
  private frameConstructorRect = new Graphics();

  protected id: string;
  protected active: boolean;
  protected matrixState: IMatrixStateModel;
  protected matrixType: ValidMatrix;
  protected productsToShow: Array<IImageData> = [];
  protected imageRefs: ImageContainer[] = [];
  protected frames: FrameContainer[] = [];
  protected allFrames: FrameContainer[] = [];
  protected numImageContainer = 0;
  protected groupContainerRefs: Array<GroupContainer> = [];
  protected groupContainerRefsByLevels: Array<Array<GroupContainer>> = [];
  protected groupLabelRefs: Array<GroupLabel> = [];
  private groupHoverTimer: Subscription;
  private groupClickTimer: Subscription;
  private groupClickTimeoutCompleted: boolean = false;
  protected selectedImages: ImageContainer[] = [];
  private groupInfoMouseDown: boolean = false;
  private groupQuickStatsMouseDown: boolean = false;
  private groupMovePrevPosition: IPosition;
  private groupMoved = false;
  private groupDragging = false;
  private groupDraggingLimits: {
    min: number;
    max: number;
  } = null;
  protected groupOrientation: GroupOrientation = GroupOrientation.Vertical;
  protected infoPanelShowState: string = 'hover';
  private groupId: string = '';
  private groupLabelMouseDownListenerRef;
  private groupLabelMouseUpListenerRef;
  private activeGroupZIndexTemp;
  private toggleLockedStateimmediately$: Subject<void> = new Subject();

  protected savedImageMatrixWidth;
  protected savedImageMatrixHeight;

  private onMagnifyMouseWheelRef;
  private magnifyTool: MagnifyTool;

  public userInteraction = false;
  public animation = false;

  protected panningActive = false;

  protected selectedToolsStartStopFunctions = {
    magnify: {
      start: this.startMagnification.bind(this),
      stop: this.stopMagnification.bind(this)
    },
    frame: {
      start: this.setupFrameDrawing.bind(this),
      stop: this.removeFrameDrawingListeners.bind(this)
    }
  };

  public productsToShow$: Subject<Set<string>> = new Subject();
  public highlightedProducts$: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
  public selectedProducts$: Subject<Set<string>> = new Subject();

  public groupLabelsContainer: GroupLabelsContainer;

  public readonly contextMenuOpened$: Subject<IContextMenuOpenCloseSubject> = new Subject();
  public readonly contextMenuClosed$: Subject<void> = new Subject();
  public imageRightDownPosition: IPosition = null;

  protected onKeyDownRef;
  protected highlightFilters: IFiltersModel[] = [];
  protected highlitedProductIDs: Set<string> = new Set();
  protected multiHightlightActive: boolean = false;
  protected imageRefsForHighlight: ImageContainer[] = [];

  protected windowDimensions: IDimensions;

  /**
   * For performance reasons only certain number of actual product images will be allowed on matrix.
   * If there's more than allowed number of images, placeholders will be rendered.
   * In "Placeholder Mode" all images will be rendered as placeholders
   * and low resolution images will be downloaded when user zoomes on them
   * High resolution change will be blocked in 'Placeholder Mode'
   */
  protected isPlaceholderMode: boolean = false;
  protected imageTresholdForPlaceholderMode: number = 3000;

  protected loader: Loader;

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    protected stage: PIXI.Container,
    protected renderer: PIXI.Renderer,
    protected viewport: Viewport,
    protected imageInfoOverlayRef: ImageInfoOverlay,
    protected store: Store,
    protected snackBar: MatSnackBar,
    protected resourceManagerService: ResourceManagerService,
    protected dataService: DataService,
    protected lookupTable: LookupTableService,
    protected appLoaderService: AppLoaderService,
    protected dropShadowService: DropShadowService,
    protected friendlyNameService: FriendlyNameService,
    protected filterService: FiltersService,
    protected viewContainerRef: ViewContainerRef
  ) {
    this.loadIcons();
    this.matrix = new Container();
    this.imageMatrix = new Container();
    this.imageMatrix.sortableChildren = true;
    this.imagePopperMenu = new Subject();
    this.renderNewMatrix = new Subject();
    this.resizeImageMatrix = new Subject();

    this.matrixResizerSpriteRef = new Sprite(Texture.EMPTY);
    this.matrixResizerSpriteRef.width = 0;
    this.matrixResizerSpriteRef.height = 0;
    this.matrix.addChild(this.matrixResizerSpriteRef, this.imageMatrix);

    this.subscribeHighlightFilters();
    this.subscribeProductsToShow();

    this.initMagnificationTool();
    this.registerGSAPPixiPlugin();
    this.initCatchWindowDimensions();
  }

  private initCatchWindowDimensions() {
    this.windowDimensions = {
      width: this.window.innerWidth || null,
      height: this.window.innerHeight || null
    };
    this.window.addEventListener('resize', () => {
      this.windowDimensions = {
        width: this.window.innerWidth || null,
        height: this.window.innerHeight || null
      };
      // Viewport must be refreshed each time, when Window dimensions changed
      this.updateViewportInCanvasState();
    });
  }

  // TODO create proper model
  public abstract setDisplayData(data: any, restore: boolean): void;
  public abstract updateImageData(data: Array<IHoverInfoFieldModel>): void;
  protected abstract createMatrix(): void;
  protected abstract restoreMatrix(): void;

  public clearMatrix(removeContainers = true): void {
    this.clearCompass.next(true);
    this.productsToShow = [];
    this.productsToShow$.next(new Set());
    this.highlitedProductIDs = new Set();
    this.imageRefsForHighlight = [];
    this.highlightedProducts$.next(new Set());
    this.imageViewOverridesWithId = {};
    this.imageViewOverridesWithIndex = [];
    if (removeContainers) {
      this.imageRefs = [];
      this.imageMatrix.removeChildren();
    }
  }

  /**
   * Restores Viewport scale/zoom and center position.
   * Useful to fix matrix visual when opened on different window dimensions
   */
  public abstract restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools);

  public setNumEntries?(numEnrteis: number, updateMatrix?: boolean): void;
  public setRankDirection?(dockingBottomOrLeft: boolean, updateMatrix?: boolean): void;
  public setGroupOrientation?(groupOrientation: GroupOrientation, updateMatrix?: boolean): void;
  public setInfoPanelConfig?(rankInfoPanelConfig: IRankInfoPanelConfig, updateMatrix?: boolean): void;
  public setFrameFeatureName?(name: string): void;
  public abstract getImagePosInWorld(image: ImageContainer): IPosition;
  public abstract onIndividualImageViewChange(image: ImageContainer): void;
  public onResolutionChange?(zoomFactor, viewportBaseScale): void;
  public setColumnSize?(num: number): void;
  public setRankAbsolutePlotting?(absolute: boolean): void;
  public abstract getStateForPresentationMode(): IPresentationState;
  public abstract onHarvestModeInit(): void;
  public abstract onHarvestModeClose(): void;

  public getGroups(): Array<GroupContainer> {
    return this.groupContainerRefsByLevels[0];
  }

  public setHighlighter(doStart: boolean) {
    if (this.selectedTool === MatrixToolEnum.Highlighter && doStart) {
      this.startHightlighter();
    } else {
      this.stopHighlighter();
    }
  }

  public setSelectedTool(tool: MatrixToolEnum) {
    if (this.selectedTool === MatrixToolEnum.Highlighter && tool !== MatrixToolEnum.Highlighter) {
      this.clearHighlights();
    }

    if (tool === MatrixToolEnum.Highlighter && this.selectedTool !== MatrixToolEnum.Highlighter) {
      const filters = this.filterService.preparedHighlightFilters$.getValue();
      this.highlightFilters = filters ? filters : [];
      this.buildHighlights();
    }

    this.selectedToolsStartStopFunctions[this.selectedTool]?.stop();
    this.selectedToolsStartStopFunctions[tool]?.start();

    if (this.matrixType === ValidMatrix.Freestyle) {
      if (this.selectedTool === MatrixToolEnum.Highlighter && tool === MatrixToolEnum.Cursor) {
        // copy Highlight selection to the Selected images array
        setTimeout(() => {
          this.getHighlightedIDsFromStore()
            .pipe(take(1))
            .subscribe(selectedIDs => {
              const selectedImagesRef = this.imageRefsForHighlight.filter(item => selectedIDs.has(item.getId()));
              selectedImagesRef.forEach(image => image.select());
              this.selectedImages = [...selectedImagesRef];
              this.selectedProducts$.next(selectedIDs);
              this.render();
            });
        }, 100);
      }
    }

    this.selectedTool = tool;
    this.initCursorForSelectedTool();
  }

  public initCursorForSelectedTool() {
    if (this.selectedTool === MatrixToolEnum.Cursor) document.body.style.cursor = CursorStyles.Default;
    if (this.selectedTool === MatrixToolEnum.Magnify) document.body.style.cursor = CursorStyles.Default;
    if (this.selectedTool === MatrixToolEnum.Frame && !this.isFrameFeatureNameEmpty()) {
      document.body.style.cursor = CursorStyles.Crosshair;
    }
    if (this.selectedTool === MatrixToolEnum.Highlighter) document.body.style.cursor = CursorStyles.Highlighter;
  }

  private subscribeHighlightFilters() {
    this.filterService.preparedHighlightFilters$.pipe(untilDestroyed(this)).subscribe(filters => {
      if (this.matrixType === this.matrixType) {
        this.highlightFilters = filters ? filters : [];
        this.buildHighlights();
      }
    });
  }

  private subscribeProductsToShow() {
    this.productsToShow$
      .pipe(
        debounce(() => this.lookupTable.created$.pipe(filter(Boolean), take(1))),
        untilDestroyed(this)
      )
      .subscribe(productsToShow => {
        this.lookupTable.setViewPlaceholderImages(productsToShow);
      });
  }

  private updateImageInHighlightFilters(imageId: string, isInclude: boolean) {
    // --> console.log('>> updateImageInHighlightFilters 1', imageId, isInclude, this.highlightFilters);

    // check that filterBID present in the filters list, if not - add it
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    let filterBID = this.highlightFilters.find(item => item.name === businessConnectionField);
    if (this.highlightFilters.length === 0 || !filterBID) {
      filterBID = this.getBIDFilter();
      filterBID.discreteValues.forEach(value => (value.checked = false));
      this.highlightFilters.push(filterBID);
    }
    // Include/Exclude imageID in filterBID
    const index = filterBID.discreteValues.findIndex(item => item.name === imageId);
    if (index >= 0) {
      if (!isInclude) {
        filterBID.discreteValues.splice(index, 1);
      } else {
        filterBID.discreteValues[index].checked = true;
      }
    } else {
      if (isInclude) {
        filterBID.discreteValues.push({
          name: imageId,
          checked: true
        });
      }
    }
    // save updated filter in Store
    // --> console.log('>> updateImageInHighlightFilters 2', this.highlightFilters, isInclude);
    let filters = clone(this.highlightFilters || []);
    filters.forEach(f => (f.discreteValues = f.discreteValues.filter(value => value.checked)));
    this.store.dispatch(new SetHighlightFilters(filters));
  }

  protected getHighlightedIDsFromStore(): Observable<Set<string>> {
    if (this.imageRefsForHighlight.length == 0) {
      return of(new Set([]));
    }

    this.appLoaderService.setShowLoader('highlight', true, 'Filtering highlights');
    const shownProdIds = new Set(this.imageRefsForHighlight.map(imageData => imageData.getId()));
    this.allFrames.forEach(frame => {
      const imageIds = frame.getImages().map(image => image.getId());
      imageIds.forEach(id => shownProdIds.add(id));
    });
    return this.dataService.apiGetHighlightedProducts(this.highlightFilters, Array.from(shownProdIds)).pipe(
      take(1),
      tap(() => {
        this.appLoaderService.setShowLoader('highlight', false);
      })
    );
  }

  protected buildHighlights() {
    this.getHighlightedIDsFromStore()
      .pipe(take(1))
      .subscribe(ids => {
        this.highlitedProductIDs = ids;
        this.updateHighlights();
      });
  }

  protected updateHighlights() {
    if (this.highlitedProductIDs.size === 0) {
      this.imageRefsForHighlight.forEach(image => {
        gsap.killTweensOf(image);
        gsap.to(image, { pixi: { saturation: 1 }, duration: 0, alpha: 1 });
      });
      const bids: Set<string> = new Set();
      this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
      this.productsToShow$.next(bids);
      this.highlightedProducts$.next(new Set());
      this.render();
      return;
    }

    this.imageRefsForHighlight.forEach(image => {
      gsap.killTweensOf(image);
      gsap.to(image, { pixi: { saturation: 0 }, duration: 0, alpha: 0.3 });
    });
    this.imageRefsForHighlight
      .filter(image => this.highlitedProductIDs.has(image.getId()))
      .forEach(image => {
        gsap.to(image, { pixi: { saturation: 1 }, duration: 0, alpha: 1 });
      });
    const bids: Set<string> = new Set(this.highlitedProductIDs);
    this.productsToShow$.next(bids);
    this.highlightedProducts$.next(bids);
    this.render();
  }

  /**
   * Clear all Highlight filters and current Highlight selection
   */
  clearHighlightFilters() {
    this.highlightFilters = [];
    this.store.dispatch(new SetHighlightFilters([]));
    this.clearHighlights();
  }

  protected clearHighlights() {
    this.highlitedProductIDs = new Set();
    this.updateHighlights();
  }

  protected updateBaseRefs() {
    this.imageSize = LOW_IMAGE_SIZE_RESOURCE;
    this.resolution = 1;
    this.distanceBetweenImages = 32;
    this.imageDesiredSizes = { width: this.imageSize, height: this.imageSize };
  }

  private loadIcons() {
    this.resourceManagerService.stopActiveLoaders();
    const resources = [
      { name: 'placeholder-collagia', url: '/assets/images/collagia-logo-placeholder.svg' },
      { name: 'placeholder-columbia', url: '/assets/images/columbia-logo-placeholder.svg' },
      { name: 'placeholder-nike', url: '/assets/images/nike-logo-placeholder.svg' }
    ];
    this.resourceManagerService.loadResources(resources, null, null, null);
  }

  public sendDataLoadingCompleted() {
    // send event to close Main Loader, because of everything for Collage/Study/Data loaded, prepared and completed
    this.appLoaderService.setShowLoader('data', false);
  }

  protected sendLowResImageLoadingStart() {
    /**
     * Need to do this action in matrix services
     * instead of in Loader service
     * to avoid problems when loading placeholders or high resolution images
     */
    this.appLoaderService.setShowLoader('resourceManager', true, 'Loading Images...');
  }

  protected sendLowResImageLoadingCompleted() {
    this.appLoaderService.setShowLoader('resourceManager', false);
  }

  public getImageSize(): number {
    return this.imageSize;
  }

  public setId(id: string) {
    this.id = id;
  }

  public getId(): string {
    return this.id;
  }

  public setActiveState(active: boolean) {
    this.active = active;
  }

  public getActiveState(): boolean {
    return this.active;
  }

  public setMatrixState(state: IMatrixStateModel | undefined) {
    this.matrixState = state;
  }

  public getMatrixState(): IMatrixStateModel {
    return this.matrixState;
  }

  public setMatrixType(type: ValidMatrix) {
    this.matrixType = type;
  }

  public getMatrixType() {
    return this.matrixType;
  }

  public setDefaultView(view: string) {
    this.defaultView = view;
  }

  public changeImageView(view: string) {
    this.setSelectedView(view);
    this.imageViewOverridesWithId = {};
    this.imageViewOverridesWithIndex = [];
    this.resourceManagerService.stopActiveLoaders();
    if (this.isPlaceholderMode) {
      return;
    }
    const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);
    this.resourceManagerService.loadResources(
      resources,
      this.onProductViewLoad.bind(this),
      null,
      this.onProductViewLoadComplete.bind(this)
    );
  }

  public setSelectedView(view: string): void {
    this.selectedView = view;
  }

  private getProductView(prodId, index) {
    return this.imageViewOverridesWithId[prodId]
      ? this.imageViewOverridesWithId[prodId]
      : this.imageViewOverridesWithIndex[index]
      ? this.imageViewOverridesWithIndex[index]
      : this.selectedView
      ? this.selectedView
      : this.defaultView;
  }

  protected createLoaderResources(size: number, images?: ImageContainer[]) {
    const products = images
      ? images.map(image => {
          return {
            id: image.getId(),
            view: image.getView()
          };
        })
      : this.productsToShow.map((product, i) => {
          return {
            id: product.prodId,
            view: this.getProductView(product.prodId, i)
          };
        });

    let resources = products.map((product, i) => {
      let url = this.lookupTable.getProductImage(product.id, product.view, size);
      if (url === '' && this.imageRefs.length > 0) {
        const image = this.imageRefs.find(item => item.getId() === product.id);
        if (image) {
          product.view = image.getView();
          url = this.lookupTable.getProductImage(product.id, product.view, size);
        }
      }
      return {
        name: `${product.id}_${product.view}-${size}`,
        url,
        crossOrigin: true,
        loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
        xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB
      };
    });

    resources = resources.filter((item, i, ar) => ar.findIndex(l => l.name === item.name) === i);
    return resources;
  }

  private onProductViewLoad(loader, resource) {
    this.updateImageView(resource.name);
  }

  protected abstract updateImageView(name: string): void;

  private onProductViewLoadComplete() {
    this.updateFramesAndImagesInCanvasState();
    this.onZoomAndMoveEnd();
  }

  private onProductHigherResLoad(loader, resource: ILoaderResource) {
    const size = resource.name.slice(resource.name.lastIndexOf('-') + 1);
    if (String(this.imageSize) === size) this.updateImageResolution(resource.name);
    if (this.selectedTool === MatrixToolEnum.Magnify) this.updateImageResolutionForMagnify(resource.name);
  }

  private updateImageResolution(name: string) {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    const images: Array<ImageContainer> = [
      ...this.frames.reduce((res, frame) => res.concat(frame.getAllImages()), []),
      ...this.imageRefs
    ];
    const index = images.findIndex(imageCont => imageCont.getId() === prodId);
    if (index < 0) return;

    images[index].updateResolution(this.resourceManagerService, this.imageSize);
    this.render();
  }

  private updateImageResolutionForMagnify(name: string) {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    if (this.magnifyTool.imageContainer?.getId() === prodId)
      this.updateImageTextureForMagnify(this.magnifyTool.imageContainer);
  }

  public onZoomChange(zoomFactor: number, instantResizeMatrix: boolean = false): void {
    this.zoomFactor = zoomFactor;

    if (!this.isPlaceholderMode) this.checkResolutionChangeOnZoom(instantResizeMatrix);

    this.updateMagnifyOnZoom();
    this.updateFrameShadowsOnZoom();
  }

  private checkResolutionChangeOnZoom(instantResizeMatrix: boolean = false) {
    const imageWidth = this.viewport ? this.viewport.scale.x * this.imageDesiredSizes.width : -1;
    if (imageWidth < 384 && this.imageSize !== LOW_IMAGE_SIZE_RESOURCE) {
      this.imageSize = LOW_IMAGE_SIZE_RESOURCE;
      this.resizeImageMatrix.next({ instantResizeMatrix });
    } else if (
      imageWidth >= 384 &&
      imageWidth < HIGH_IMAGE_SIZE_RESOURCE &&
      this.imageSize !== MIDDLE_IMAGE_SIZE_RESOURCE
    ) {
      this.imageSize = MIDDLE_IMAGE_SIZE_RESOURCE;
      this.resizeImageMatrix.next({ instantResizeMatrix });
    } else if (imageWidth >= HIGH_IMAGE_SIZE_RESOURCE && this.imageSize !== HIGH_IMAGE_SIZE_RESOURCE) {
      if (this.lookupTable.getImagePlaceholder() === ImagePlaceholder.NIKE) {
        // Nike Dam provides only 750px images which looks same for both resolution 2 and 4
        // So, we dont allow resolution 4 for Nike.
        this.imageSize = MIDDLE_IMAGE_SIZE_RESOURCE;
        this.resizeImageMatrix.next({ instantResizeMatrix });
      } else {
        this.imageSize = HIGH_IMAGE_SIZE_RESOURCE;
        this.resizeImageMatrix.next({ instantResizeMatrix });
      }
    }
  }

  public onViewportMove(): void {
    this.hideImagePopper();
    this.contextMenuClosed$.next();
    if (this.imageInfoShown) this.imageInfoOverlayRef.updateSizeAndPosition();
  }

  protected calculateResolution() {
    this.resolution = this.imageSize / LOW_IMAGE_SIZE_RESOURCE;
  }

  /**
   * Updates Container Sizes
   * Updates Image Sprites with Higher Resolution image if possible
   * Does not download Higher Res image sprites
   */
  public updateResolution() {
    this.calculateResolution();
    const resizeFactor = this.imageSize / this.imageDesiredSizes.width;
    this.updateImageSizes(resizeFactor);
    this.updateFrameSizes(resizeFactor);
    return resizeFactor;
  }

  public onZoomEnd() {
    this.frames.forEach(frame => this.moveImagesUnderFrame(frame));
  }

  /**
   * In Placeholder Mode:
   * Loads Low Resolution images for ImageContainers in View
   *
   * Outside Placeholder Mode:
   * Loads Higer Res images for ImageContainers in View
   *
   */
  public onZoomAndMoveEnd() {
    const imageWidth = this.viewport ? this.viewport.scale.x * this.imageDesiredSizes.width : -1;
    if (this.resolution === 1) {
      if (this.isPlaceholderMode) {
        // Number 100 is arbitrary, can be modified for different results
        if (imageWidth > 100) this.loadImagesInView(LOW_IMAGE_SIZE_RESOURCE);
      }
      return;
    }

    // Nike Dam provides only one size of image
    // So, we dont need to re-download same images.
    // Resolution change will automatically cause imageContainer to update it's size and scale original Nike image texture
    if (this.lookupTable.getImagePlaceholder() === ImagePlaceholder.NIKE) return;

    this.loadImagesInView(this.resolution === 2 ? MIDDLE_IMAGE_SIZE_RESOURCE : HIGH_IMAGE_SIZE_RESOURCE);
  }

  private loadImagesInView(size) {
    const resources = this.createLoaderResources(size, this.getImagesInView());
    this.resourceManagerService.loadResources(resources, this.onProductHigherResLoad.bind(this), null, null);
  }

  private getImagesInView(): ImageContainer[] {
    const imagesInView = [];
    const left = this.viewport.left - this.viewport.screenWidthInWorldPixels;
    const top = this.viewport.top - this.viewport.screenHeightInWorldPixels;
    const right = this.viewport.left + 2 * this.viewport.screenWidthInWorldPixels;
    const bottom = this.viewport.top + 2 * this.viewport.screenHeightInWorldPixels;
    const images: Array<ImageContainer> = [
      ...this.frames.reduce((res, frame) => res.concat(frame.getAllImages()), []),
      ...this.imageRefs
    ];
    images.forEach(image => {
      const pos = this.getImagePosInMatrix(image);
      if (pos.x >= left && pos.x <= right && pos.y >= top && pos.y <= bottom) imagesInView.push(image);
    });
    return imagesInView;
  }

  protected getImagePosInMatrix(image: ImageContainer): IPosition {
    const globalPos = image.toGlobal(this.matrix.position);
    const localPos = image.toLocal(globalPos, this.matrix);
    return {
      x: globalPos.x - localPos.x,
      y: globalPos.y - localPos.y
    };
  }

  protected updateImageSizes(resizeFactor: number) {
    this.imageDesiredSizes = { width: this.imageSize, height: this.imageSize };
    this.distanceBetweenImages *= resizeFactor;

    this.matrixResizerSpriteRef.width *= resizeFactor;
    this.matrixResizerSpriteRef.height *= resizeFactor;

    this.imageMatrix.position.x *= resizeFactor;
    this.imageMatrix.position.y *= resizeFactor;

    this.imageRefs?.forEach(image => this.resizeImage(image, resizeFactor));
    if (this.imageDragging) this.draggingImageRefs.forEach(image => this.resizeDraggingImage(image, resizeFactor));
    this.groupContainerRefs?.forEach(group => {
      group.position.x *= resizeFactor;
      group.position.y *= resizeFactor;
    });
  }

  protected resizeDraggingImage(imageCont: ImageContainer, resizeFactor) {
    imageCont.resize(this.resourceManagerService, this.imageSize, resizeFactor, false);
    imageCont.scale.set(imageCont.scale.x / resizeFactor);
  }

  protected resizeImage(imageCont: ImageContainer, resizeFactor) {
    imageCont.resize(this.resourceManagerService, this.imageSize, resizeFactor);
  }

  protected updateFrameSizes(resizeFactor: number) {
    this.frameBorderBaseWidth *= resizeFactor;
    this.frames?.forEach(frame =>
      frame.resizeWithFactor(resizeFactor, this.resourceManagerService, this.dropShadowService)
    );
  }

  public setImageSize(size) {
    this.imageSize = size;
  }

  public updateBaseSizes(viewportBaseScale) {
    this.viewportBaseScale = viewportBaseScale;
    this.frameBorderBaseWidth = 1.5 / viewportBaseScale;
    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    this.frameMarginTop = 30 / this.viewport.scale.x;
    this.updateMagnifyOnZoom();
  }

  protected onImageClick(image: ImageContainer) {
    if (this.selectedTool !== MatrixToolEnum.Highlighter) {
      if (this.imageInfoShown) {
        this.imageInfoShown = false;
        this.imageInfoOverlayRef.hide();
      }
      this.showImagePopperMenu(image);
      return;
    }
    this.highlightImage(image);
  }

  private showImagePopperMenu(image: ImageContainer) {
    const bounds = image.getBounds();
    const coordinates = {
      x: bounds.x + bounds.width / 2,
      y: bounds.y
    };
    this.imagePopperMenuShown = true;
    this.imagePopperMenu.next({
      show: true,
      coordinates,
      image,
      imageDeselectRef: this.onImageDeselect.bind(this),
      renderRef: this.render.bind(this)
    });
  }

  private isBIDFilter(compareFilter: IFiltersModel): boolean {
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    return compareFilter?.name === businessConnectionField;
  }

  private getBIDFilter(): IFiltersModel {
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const allFilters: IFiltersModel[] = this.store.selectSnapshot(UserDataState.getDimensionsAndMeasures);
    return allFilters.find(item => item.name === businessConnectionField);
  }

  /**
   * Manual toggle selection of Image for Highlight images list
   * @param image
   * @private
   */
  private highlightImage(image: ImageContainer) {
    const id = image.getId();

    // check if some highlight filters were active,
    // clear all of them and move ids of current Highlighted images into the one BIDs filter
    if (this.highlightFilters.length > 1 || !this.isBIDFilter(this.highlightFilters[0])) {
      // TODO:
      const filterBID = this.getBIDFilter();
      filterBID.discreteValues.forEach(value => (value.checked = false));
      this.highlightFilters = [filterBID];
      this.highlitedProductIDs.forEach(highlightedId => {
        const index = filterBID.discreteValues.findIndex(value => value.name === highlightedId);
        if (index > -1) {
          filterBID.discreteValues[index].checked = true;
        }
      });
    }

    // Update image selection in BID filter and highlitedProductIDs
    if (this.multiHightlightActive) {
      // this.highlitedProductIDs.has(id) ? this.highlitedProductIDs.delete(id) : this.highlitedProductIDs.add(id);
      this.updateImageInHighlightFilters(id, !this.highlitedProductIDs.has(id));
    } else {
      this.highlightFilters = [];
      this.highlitedProductIDs = new Set([id]);
      this.updateImageInHighlightFilters(id, true);
    }
    this.updateHighlights();
  }

  protected onImageRightDown(event: InteractionEvent): void {
    this.imageRightDownPosition = { ...event.data.global };
  }

  protected onImageRightUp(image: ImageContainer, event: InteractionEvent): void {
    if (this.imageRightDownPosition === null) return;
    const currentPos = { ...event.data.global };
    if (
      Math.abs(this.imageRightDownPosition.x - currentPos.x) <= 1 &&
      Math.abs(this.imageRightDownPosition.y - currentPos.y) <= 1
    ) {
      this.imageRightDownPosition = null;
      const selectedImages = image.getSelectedState() ? this.selectedImages : [image];
      image.setContextMenuLocalPosition(event.data.getLocalPosition(image));
      this.contextMenuOpened$.next({
        items: selectedImages,
        image
      });
    }
  }

  protected onImageMouseOver(image: ImageContainer, event?) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOverWithMagnify(image, event);
      return;
    }
    if (this.imageDragging) return;

    image.setMouseOverState(true);
    this.animateImageHover(image);

    if (this.imageHoverTimeout) {
      clearTimeout(this.imageHoverTimeout);
    }
    this.imageHoverTimeout = setTimeout(() => {
      if (!this.imageDragging && !this.imagePopperMenuShown) {
        this.imageInfoShown = true;
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    }, 250);
  }

  protected onImageMouseOut(image: ImageContainer) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOutWithMagnify(image);
      return;
    }
    if (this.imageDragging) return;

    image.setMouseOverState(false);
    this.animateImageHover(image);

    clearTimeout(this.imageHoverTimeout);
    if (this.imageInfoShown) {
      this.imageInfoShown = false;
      this.imageInfoOverlayRef.hide();
    }
  }

  protected animateImageHover(image: ImageContainer): gsap.core.Timeline {
    const tl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      }
    });
    tl.addLabel('animationStart', 0);
    tl.killTweensOf(image);
    tl.killTweensOf(image.scale);
    tl.killTweensOf(image.shadow);
    tl.killTweensOf(image.shadow.scale);
    tl.to(
      image.scale,
      {
        duration: this.imageScaleAnimationDuration,
        x: image.getMouseOverState() ? this.imageHoverScale : this.imageBaseScale,
        y: image.getMouseOverState() ? this.imageHoverScale : this.imageBaseScale
      },
      'animationStart'
    );
    tl.to(
      image.shadow,
      {
        duration: this.imageScaleAnimationDuration,
        alpha: image.getMouseOverState() ? this.imageShadowHoverAlpha : this.imageShadowBaseAlpha
      },
      'animationStart'
    );
    tl.to(
      image.shadow.scale,
      {
        duration: this.imageScaleAnimationDuration,
        x: image.getMouseOverState() ? this.imageHoverScale : this.imageBaseScale,
        y: image.getMouseOverState() ? this.imageHoverScale : this.imageBaseScale
      },
      'animationStart'
    );

    return tl;
  }

  protected onImageMouseOverWithMagnify(image: ImageContainer, event) {
    if (!event) return;

    this.viewport.plugins.pause('wheel');
    (this.viewport.plugins.get('drag') as unknown as CustomPluginDrag).wheelPaused = true;
    this.onMagnifyMouseWheelRef = this.onMagnifyMouseWheel.bind(this);
    document.addEventListener('wheel', this.onMagnifyMouseWheelRef);

    const texture = this.getImageTextureForMagnify(image);
    image.on('mousemove', this.onImageMouseMoveWithMagnify.bind(this, image));
    this.magnifyTool.onImageMouseOver(image, texture, event.data.global);
  }

  protected onImageMouseOutWithMagnify(image: ImageContainer) {
    this.viewport.plugins.resume('wheel');
    (this.viewport.plugins.get('drag') as unknown as CustomPluginDrag).wheelPaused = false;
    document.removeEventListener('wheel', this.onMagnifyMouseWheelRef);

    image.off('mousemove');
    this.magnifyTool.onImageMouseOut();
  }

  protected onImageMouseMoveWithMagnify(image: ImageContainer, event) {
    this.magnifyTool.updatePosition(event.data.getLocalPosition(this.stage));
    this.magnifyTool.onImageMouseMove(image, event.data.global);
  }

  private imageDragScaleAnimationTl: gsap.core.Timeline;

  private createImageDragScaleTimeline() {
    this.imageDragScaleAnimationTl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      }
    });
    this.imageDragScaleAnimationTl.addLabel('animationStart', 0);
  }

  private animateImageDragScale(image: ImageContainer) {
    const isDragging = this.imageDragging || this.groupDragging;
    const tl = this.imageDragScaleAnimationTl;
    tl.killTweensOf(image);
    tl.killTweensOf(image.scale);
    tl.killTweensOf(image.shadow);
    tl.killTweensOf(image.shadow.scale);
    const baseScale = image.scale.x;
    tl.to(
      image.scale,
      {
        duration: this.imageScaleAnimationDuration,
        x: isDragging ? baseScale * this.imageDragScale : this.imageBaseScale,
        y: isDragging ? baseScale * this.imageDragScale : this.imageBaseScale
      },
      'animationStart'
    );
    tl.to(
      image.shadow,
      {
        duration: this.imageScaleAnimationDuration,
        alpha: isDragging ? this.imageShadowDragAlpha : this.imageShadowBaseAlpha
      },
      'animationStart'
    );
    tl.to(
      image.shadow.scale,
      {
        duration: this.imageScaleAnimationDuration,
        x: isDragging ? this.imageDragScale : this.imageBaseScale,
        y: isDragging ? this.imageDragScale : this.imageBaseScale
      },
      'animationStart'
    );
  }

  private animateGroupLabelDragScale(groupLabel: GroupLabel, callback?: () => void) {
    const tl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      },
      onComplete: callback
    });
    tl.addLabel('animationStart', 0);
    tl.killTweensOf(groupLabel.getWrapper());
    tl.to(
      groupLabel.getWrapper(),
      {
        duration: this.imageScaleAnimationDuration,
        scale: this.groupDragging ? this.imageDragScale : this.imageBaseScale,
        opacity: this.groupDragging ? '1' : '',
        zIndex: this.groupDragging ? '85' : ''
      },
      'animationStart'
    );
    tl.to(
      groupLabel.getLabel(),
      {
        duration: this.imageScaleAnimationDuration,
        boxShadow: this.groupDragging
          ? `0px 8px 8px 0px rgba(0, 0, 0, 0.2509803922)`
          : `0px ${4 * this.groupLabelsContainer.scale}px ${
              4 * this.groupLabelsContainer.scale
            }px 0px rgba(0, 0, 0, 0.2509803922)`
      },
      'animationStart'
    );
  }

  protected onImageMouseDown(image: ImageContainer, event: InteractionEvent) {
    event.stopPropagation();
    if (this.imageDragging) return;
    this.viewport.pause = true;
    this.userInteraction = true;
    this.imageDragging = true;
    this.imageDragMoveListenerRef = this.onImageDragMove.bind(this);
    this.imageDragSourceFrames = [];

    this.allFrames.forEach(f => f.saveHtmlElementsVisibleState());

    clearTimeout(this.imageHoverTimeout);
    if (this.imageInfoShown) {
      this.imageInfoShown = false;
      this.imageInfoOverlayRef.hide();
    }

    this.imageDragStartPositionFromAnchor = { matrix: {}, stage: {} };
    const coordinates = event.data.getLocalPosition(image);
    this.imageDragStartPositionFromAnchor.matrix = {
      x: coordinates.x,
      y: coordinates.y
    };

    const bounds = image.getImageBounds();

    const stageCoordinates = event.data.getLocalPosition(this.stage);
    this.imageDragStartPositionFromAnchor.stage = {
      x: stageCoordinates.x - bounds.x - bounds.width / 2,
      y: stageCoordinates.y - bounds.y - bounds.height / 2
    };

    this.imageMovePrevPosition = stageCoordinates;
    this.imageMoved = false;

    this.imageDragSavedPositions = [];
    image.clearDecelerateProperties();

    image.on('mousemove', this.imageDragMoveListenerRef);
  }

  protected onImageMouseUp(image: ImageContainer, event: InteractionEvent) {
    event.stopPropagation();
    if (!this.imageDragging) return;
    this.viewport.pause = false;
    this.userInteraction = false;
    this.imageDragging = false;
    this.draggingImageRefs = [];
    image.off('mousemove', this.imageDragMoveListenerRef);

    if (!this.imageMoved) {
      this.imageMovePrevPosition = null;

      setTimeout(() => {
        if (this.multiSelectActive) {
          if (image.getSelectedState()) {
            this.onImageDeselect(image);
          } else {
            this.onImageSelect(image);
          }
        } else {
          this.onImageClick(image);
        }
      });
      if (image.getMouseOverState()) this.onImageMouseOver(image);
      return;
    }

    this.imageMatrix.addChild(image);
    this.imageRefs.push(image);

    const imagePos = this.viewport.toWorld(image.position);
    image.position.set(imagePos.x - this.imageMatrix.x, imagePos.y - this.imageMatrix.y);
    image.scale.set(this.imageDragScale);
    this.imageDragScaleAnimationTl?.kill();
    this.createImageDragScaleTimeline();
    this.animateImageDragScale(image);

    if (image.getSelectedState()) {
      this.selectedImages.forEach(selectedImage => {
        if (selectedImage.getId() !== image.getId()) {
          const selectedImagePos = this.viewport.toWorld(selectedImage.position);
          this.imageMatrix.addChild(selectedImage);
          this.imageRefs.push(selectedImage);
          selectedImage.position.set(selectedImagePos.x - this.imageMatrix.x, selectedImagePos.y - this.imageMatrix.y);
          selectedImage.scale.set(this.imageDragScale);
          this.animateImageDragScale(selectedImage);
        }
      });
    }

    const imageInFrame = this.frameCollidedOnImageDrag;
    if (this.frameCollidedOnImageDrag) {
      const images = image.getSelectedState() ? this.selectedImages : [image];
      images.forEach(img => {
        const index = this.imageRefs.indexOf(img);
        if (index > -1) this.imageRefs.splice(index, 1);
      });
      let frameImages = this.frameCollidedOnImageDrag.getImages();
      frameImages.forEach((item: any) => {
        if (!(item instanceof ImageContainer)) {
          item.parent.removeChild(item);
          item = null;
        }
      });
      this.imagePreviewsOnImageDrag = [];
      frameImages = frameImages.filter(item => item instanceof ImageContainer);
      frameImages.splice(this.imageIndexInFrameOnImageDrag, 0, ...images);
      this.frameCollidedOnImageDrag.setImages(frameImages);
      const firstAncestor = this.getFirstFrameAncestor(this.frameCollidedOnImageDrag);

      if (this.matrixType === ValidMatrix.GroupBy) {
        this.frameCollidedOnImageDrag.makeGrid();
        this.updateFrameInCanvasState(firstAncestor);
      } else {
        this.imageDragSourceFrames.push(this.frameCollidedOnImageDrag);
        this.updateImageDragSourceFrame();
      }

      if (this.frameCollidedOnImageDrag.getInfoLockedState()) {
        this.hideFrameInfo(this.frameCollidedOnImageDrag, false);
        this.showFrameInfo(this.frameCollidedOnImageDrag, false);
      }
      if (this.frameCollidedOnImageDrag.getId() !== firstAncestor.getId() && firstAncestor.getInfoLockedState()) {
        this.hideFrameInfo(firstAncestor, false);
        this.showFrameInfo(firstAncestor, false);
      }
      this.render();

      this.moveImagesUnderFrame(firstAncestor);
      this.updateImagesInCanvasState();
    }

    this.allFrames.forEach(item => {
      if (item.getRestoreInfoState()) {
        item.setRestoreInfoState(false);
        this.showFrameInfo(item, false);
      }
    });

    this.updateAllFrameHtmlElements();

    if (imageInFrame) return;

    this.updateImageDragSourceFrame();
    this.render();

    const now = Date.now();
    for (const save of this.imageDragSavedPositions) {
      if (save.time >= now - 100) {
        const time = now - save.time;
        const coordinates = this.viewport.toWorld(save.x, save.y);
        coordinates.x -= this.imageMatrix.x;
        coordinates.y -= this.imageMatrix.y;

        image.updateDecelerateProperties(coordinates, time);

        this.animation = true;
        this.numImgesDecelerating++;

        if (image.getSelectedState()) {
          this.selectedImages.forEach(selectedImage => selectedImage.savePositionOnDragStart());
        }
        this.decelerate(image);
        return;
      }
    }
    this.updateImagesInCanvasState();

    if (image.getMouseOverState()) this.onImageMouseOver(image);
  }

  private onImageDragMove(event: InteractionEvent) {
    if (this.imageDragging) {
      event.stopPropagation();
      const coordinates = event.data.getLocalPosition(this.stage);

      if (
        Math.abs(coordinates.x - this.imageMovePrevPosition.x) > 1 ||
        Math.abs(coordinates.y - this.imageMovePrevPosition.y) > 1
      ) {
        if (!this.imageMoved) this.onImageDragStart(event);
        this.imageMovePrevPosition = coordinates;
        this.imageMoved = true;
        event.currentTarget.position.set(
          coordinates.x - this.imageDragStartPositionFromAnchor.stage.x,
          coordinates.y - this.imageDragStartPositionFromAnchor.stage.y
        );
        this.imageDragSavedPositions.push({
          x: event.currentTarget.position.x,
          y: event.currentTarget.position.y,
          time: Date.now()
        });

        if ((event.currentTarget as ImageContainer).getSelectedState()) {
          this.onSelectedImageMove(event.currentTarget as ImageContainer);
        }

        this.updateFramesOnImageDrag(event);
        this.updateFrameHtmlElements(event.currentTarget as ImageContainer);
      }
    }
  }

  private updateFramesOnImageDrag(event: InteractionEvent) {
    const frameCollided =
      this.matrixType === ValidMatrix.GroupBy ? this.imageDragSourceFrames[0] : this.getFrameCollidedOnImageDrag(event);

    if (frameCollided) {
      const { imageCellX, imageCellY, pad, numImagesOnRow, numImagesOnCol, xOnMatrix, yOnMatrix } =
        frameCollided.savedPropertiesForDrag;

      const imageCelPos = {
        x: xOnMatrix + imageCellX,
        y: yOnMatrix + imageCellY
      };
      const coordinatesOnMatrix = event.data.getLocalPosition(this.matrix);
      const imageSize = this.imageDesiredSizes.width + this.distanceBetweenImages;
      let xIndex = 0;
      let yIndex = 0;
      if (coordinatesOnMatrix.x - imageCelPos.x > pad) {
        xIndex = Math.floor((coordinatesOnMatrix.x - imageCelPos.x - pad) / imageSize);
      }
      if (coordinatesOnMatrix.y - imageCelPos.y > pad) {
        yIndex = Math.floor((coordinatesOnMatrix.y - imageCelPos.y - pad) / imageSize);
      }
      if (xIndex > numImagesOnRow - 1) xIndex = numImagesOnRow - 1;
      if (yIndex > numImagesOnCol - 1) yIndex = numImagesOnCol - 1;
      const imageIndex = numImagesOnRow * yIndex + xIndex;

      if (frameCollided.getId() === this.frameCollidedOnImageDrag?.getId()) {
        this.moveImagePreviewsInFrame(frameCollided, imageIndex);
      } else {
        this.addImagePreviewsInFrame(frameCollided, imageIndex);
      }
      this.frameCollidedOnImageDrag = frameCollided;
    } else if (this.frameCollidedOnImageDrag) {
      this.removeImagePreviewsInFrame();
      this.render();
      this.frameCollidedOnImageDrag = null;
    }
  }

  private addImagePreviewsInFrame(frameCollided, imageIndex) {
    this.removeImagePreviewsInFrame();
    const imageReorderAnimationData = {
      frameId: frameCollided.getId(),
      tl: this.imageReorderAnimationTl
    };

    const frameImages = frameCollided.getImages();
    if (imageIndex > frameImages.length + this.imagePreviewsOnImageDrag.length)
      imageIndex = frameImages.length + this.imagePreviewsOnImageDrag.length;

    this.imagePreviewsOnImageDrag.forEach(item => {
      item.add = true;
    });
    frameImages.splice(imageIndex, 0, ...this.imagePreviewsOnImageDrag);
    frameCollided.setImages(frameImages);
    if (this.matrixType === ValidMatrix.GroupBy) {
      frameCollided.makeGrid(false, imageReorderAnimationData);
    } else {
      const firstAncestor = this.getFirstFrameAncestor(frameCollided);
      firstAncestor.makeGrid(true, imageReorderAnimationData);
    }

    this.imageIndexInFrameOnImageDrag = imageIndex;
  }

  private removeImagePreviewsInFrame() {
    if (this.frameCollidedOnImageDrag) {
      const imageReorderAnimationData = {
        frameId: this.frameCollidedOnImageDrag.getId(),
        tl: this.imageReorderAnimationTl
      };
      let frameImages = this.frameCollidedOnImageDrag.getImages();
      frameImages.forEach((image: any) => {
        if (!(image instanceof ImageContainer)) image.remove = true;
      });
      frameImages = frameImages.filter(image => image instanceof ImageContainer);
      this.frameCollidedOnImageDrag.setImages(frameImages);
      if (this.matrixType === ValidMatrix.GroupBy) {
        this.frameCollidedOnImageDrag.makeGrid(false, imageReorderAnimationData);
      } else {
        const firstAncestor = this.getFirstFrameAncestor(this.frameCollidedOnImageDrag);
        firstAncestor.makeGrid(true, imageReorderAnimationData);
      }
    }
  }

  private moveImagePreviewsInFrame(frameCollided, imageIndex) {
    const imageReorderAnimationData = {
      frameId: frameCollided.getId(),
      tl: this.imageReorderAnimationTl
    };

    let frameImages = frameCollided.getImages();
    if (imageIndex > frameImages.length) imageIndex = frameImages.length;

    frameImages = frameImages.filter(image => image instanceof ImageContainer);
    frameImages.splice(imageIndex, 0, ...this.imagePreviewsOnImageDrag);
    frameCollided.setImages(frameImages);
    if (this.matrixType === ValidMatrix.GroupBy) {
      frameCollided.makeGrid(false, imageReorderAnimationData);
    } else {
      const firstAncestor = this.getFirstFrameAncestor(frameCollided);
      firstAncestor.makeGrid(true, imageReorderAnimationData);
    }
    this.imageIndexInFrameOnImageDrag = imageIndex;
  }

  private getFrameCollidedOnImageDrag(event) {
    const coordinates = event.data.getLocalPosition(this.stage);
    for (const frame of this.frames) {
      const frameCollided = frame.checkFramesForMouseCollision(coordinates);
      if (frameCollided) return frameCollided;
    }
    return null;
  }

  private createImagePreviewsOnImageDrag(image: ImageContainer) {
    this.imagePreviewsOnImageDrag = [];
    const images = image.getSelectedState() ? this.selectedImages : [image];
    images.forEach(item => {
      const copy = item.getPreview();
      gsap.to(copy, { pixi: { saturation: 0 }, duration: 0, alpha: 0.3 });
      this.imagePreviewsOnImageDrag.push(copy);
    });
  }

  private createImageReorderAnimationTimeline() {
    this.imageReorderAnimationTl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      }
    });
    this.imageReorderAnimationTl.addLabel('animationStart', 0);
  }

  private onSelectedImageMove(imageDragging: ImageContainer) {
    const positionChange = {
      x: imageDragging.x - imageDragging.getDragStartPosition().x,
      y: imageDragging.y - imageDragging.getDragStartPosition().y
    };

    this.selectedImages.forEach(image => {
      if (image.getId() !== imageDragging.getId()) image.moveSelected(positionChange);
    });
  }

  private onImageDragStart(event: InteractionEvent) {
    this.hideImagePopper();
    this.contextMenuClosed$.next();

    const image = event.currentTarget as ImageContainer;
    gsap.killTweensOf(image);
    gsap.killTweensOf(image.scale);
    image.scale.set(this.imageHoverScale);
    this.imageDragScaleAnimationTl?.kill();
    this.createImageDragScaleTimeline();

    if (!image.getSelectedState()) this.deselectAllImages();
    if (image.parent instanceof FrameContainer && image.parent.active) this.onFrameMouseOut(image.parent, event);

    if (image.getSelectedState()) {
      image.scale.set(this.imageBaseScale);
      const selectedImageBounds = {};
      this.selectedImages.forEach(selectedImage => {
        selectedImageBounds[selectedImage.getId()] = selectedImage.getImageBounds();
        if (selectedImage.parent instanceof FrameContainer) this.imageDragSourceFrames.push(selectedImage.parent);
      });
      this.removeImagesFromRefs(this.selectedImages);
      this.selectedImages.forEach((selectedImage: ImageContainer) => {
        const bounds = selectedImageBounds[selectedImage.getId()];
        this.draggingImageRefs.push(selectedImage);
        this.stage.addChild(selectedImage);
        selectedImage.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        selectedImage.scale.set(bounds.width / this.imageDesiredSizes.width);
        selectedImage.savePositionOnDragStart();
        this.render();
        this.animateImageDragScale(selectedImage);
      });
    } else {
      if (image.parent instanceof FrameContainer) this.imageDragSourceFrames.push(image.parent);
      const bounds = image.getImageBounds();
      this.removeImageFromRefs(image);
      this.draggingImageRefs.push(image);
      this.stage.addChild(image);
      image.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
      image.scale.set(bounds.width / this.imageDesiredSizes.width);
      this.animateImageDragScale(image);
    }

    this.allFrames.forEach(item => {
      if (item.getInfoShownState()) {
        this.hideFrameInfo(item, false);
        if (item.getInfoLockedState()) item.setRestoreInfoState(true);
      }
    });

    this.createImagePreviewsOnImageDrag(event.currentTarget as ImageContainer);
    this.createImageReorderAnimationTimeline();
    this.saveFramePropertiesForImageDrag();
  }

  /**
   * pre-Adds dragging images to each frame and Pre-Grids them
   * to calculate and save necessary properties for calculations
   * on actual image drag
   */
  private saveFramePropertiesForImageDrag() {
    if (this.matrixType === ValidMatrix.GroupBy) {
      const frame = this.imageDragSourceFrames[0];
      frame.savePropertiesForImageDrag();
      frame.savedPropertiesForDrag.xOnMatrix += this.imageMatrix.x;
      frame.savedPropertiesForDrag.yOnMatrix += this.imageMatrix.y;
    } else {
      // eslint-disable-next-line prefer-spread
      const emptyArray = Array.apply(null, { length: this.imagePreviewsOnImageDrag.length });
      this.frames.forEach(frame => {
        [...frame.getAllFrames(), frame].forEach(subFrame => {
          subFrame.setImages([...subFrame.getImages(), ...emptyArray]);
          frame.makeGrid();
          subFrame.savePropertiesForImageDrag();
          subFrame.setImages(subFrame.getImages().filter(item => item));
          frame.makeGrid();
        });
      });
    }
  }

  protected onFrameMouseDown(frame: FrameContainer, event: InteractionEvent) {
    event.stopPropagation();
    this.draggingFrameRef = frame;
    if (this.selectedTool === MatrixToolEnum.Frame || this.selectedTool === MatrixToolEnum.Highlighter) return;
    this.viewport.pause = true;

    this.imageMatrix.interactive = false;
    this.imageMatrix.interactiveChildren = false;
    frame.interactiveChildren = false;

    this.frameMoved = false;
    frame.off('mousemove');

    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    this.matrixMouseMoveListenerRef = this.onFrameMouseMoveForDrag.bind(this, frame);
    this.matrix.on('mousemove', this.matrixMouseMoveListenerRef);

    const coordinates = event.data.getLocalPosition(this.matrix);
    this.frameMovePrevPosition = coordinates;

    this.updateCursorOnFrameMouseMove(frame, event);
  }

  private isFrameFeatureNameEmpty(): boolean {
    return !this.frameFeatureName || this.frameFeatureName.trim() === '';
  }

  protected onFrameMouseUp(event: InteractionEvent) {
    event.stopPropagation();
    const frame = this.draggingFrameRef;
    this.draggingFrameRef = null;

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      document.body.style.cursor = CursorStyles.Highlighter;
      return;
    }

    if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
      return;
    }

    this.viewport.pause = false;
    this.imageMatrix.interactive = true;
    this.imageMatrix.interactiveChildren = true;
    frame.interactiveChildren = true;

    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    frame.off('mousemove');
    this.frameDragging = false;
    this.userInteraction = false;
    document.body.style.cursor = CursorStyles.Default;

    if (!frame.active) frame.hideBordersAndCorners();

    if (this.frameHover) {
      this.activeFrameRef.off('mousemove');
      this.activeFrameRef.on('mousemove', this.onFrameMouseMove.bind(this, this.activeFrameRef));
      this.activeFrameRef.showBordersAndCorners();
    }

    if (this.frameMoved) {
      this.frameMoved = false;
      const wasSubFrame = this.frameDragSourceFrame;
      if (
        this.frameMouseMoveState !== CursorStates.Drag &&
        this.frameMouseMoveState !== CursorStates.FrameInfo &&
        this.frameMouseMoveState !== CursorStates.PublishIcon
      ) {
        frame.onResizeEnd(this.frameMouseMoveState);
        frame.updateDropShadowOnResizeEnd(this.dropShadowService);
      } else {
        this.checkFrameForFrameCollision(frame, event);
        frame.updateDropShadowOnDragEnd();
      }
      this.updateAllFrameHtmlElements();
      this.frameDragSourceFrame = undefined;
      this.render();

      this.moveImagesUnderFrame(frame);

      this.allFrames.forEach(item => {
        if (item.getRestoreInfoState()) {
          item.setRestoreInfoState(false);
          if (item.htmlElementsVisible) {
            if (item.parent instanceof FrameContainer) {
              const parent = item.parent as FrameContainer;
              if (!parent || (parent.getInfoShownState && !parent.getInfoShownState())) {
                this.showFrameInfo(item, false);
              }
            } else {
              this.showFrameInfo(item, false);
            }
          } else {
            this.hideFrameInfo(item, false);
            item.setInfoLockedState(false);
            item.changeInfoIconTexture();
          }
        }
      });

      if (!(frame.parent instanceof FrameContainer)) {
        if (wasSubFrame) {
          this.addFrameInCanvasState(frame);
        } else {
          this.updateFrameInCanvasState(frame);
        }
      } else {
        // Necessary to ensure frame resize is correctly updated for chained frames
        this.updateFrameInCanvasState(this.getFirstFrameAncestor(frame));
      }

      return;
    }

    const bounds = frame.getFrameBounds();
    if (frame.checkForInfoClick(event, bounds)) this.onFrameInfoClick(frame);
    if (frame.checkForPublishClick(event, bounds)) this.onFramePublishIconClick(frame);
    frame.checkForTitleClick(event, bounds);
  }

  private onFrameMouseMoveForDrag(frame: FrameContainer, event: InteractionEvent) {
    event.stopPropagation();

    const coordinates = event.data.getLocalPosition(this.matrix);
    if (
      Math.abs(coordinates.x - this.frameMovePrevPosition.x) < 1 &&
      Math.abs(coordinates.y - this.frameMovePrevPosition.y) < 1
    ) {
      return;
    }

    if (!this.frameMoved) this.onFrameDragStart(frame, coordinates);
    this.frameMovePrevPosition = coordinates;
    this.frameMoved = true;
    if (this.frameDragging) {
      frame.mouseMoveActions[this.frameMouseMoveState](coordinates);
      this.updateAllFrameHtmlElements();
    }
  }

  private onFrameDragStart(frame: FrameContainer, coordinates: IPosition) {
    this.hideImagePopper();
    this.contextMenuClosed$.next();

    this.allFrames.forEach(item => {
      if (item.getInfoShownState()) {
        this.hideFrameInfo(item, false);
        if (item.getInfoLockedState()) item.setRestoreInfoState(true);
      }
    });

    this.frameDragging = true;
    this.userInteraction = true;

    if (
      frame.parent instanceof FrameContainer &&
      (this.frameMouseMoveState === CursorStates.Drag || this.frameMouseMoveState === CursorStates.FrameInfo)
    ) {
      this.frameDragSourceFrame = frame.parent;
      const indexInFrame = frame.parent.getFrames().indexOf(frame);
      if (indexInFrame > -1) {
        frame.parent.getFrames()[indexInFrame] = null;
      }

      const framePosOnMatrix = frame.getCoordinatesOnMatrix();
      this.matrix.addChild(frame);
      this.frames.push(frame);
      frame.position.set(framePosOnMatrix.x, framePosOnMatrix.y);
    } else if (this.frameMouseMoveState === CursorStates.Drag || this.frameMouseMoveState === CursorStates.FrameInfo) {
      this.matrix.removeChild(frame);
      this.matrix.addChild(frame);
      const index = this.frames.indexOf(frame);
      this.frames.splice(index, 1);
      this.frames.push(frame);
    } else {
      const firstAncestor = this.getFirstFrameAncestor(frame);
      const index = this.frames.indexOf(firstAncestor);
      if (index > -1) {
        this.matrix.removeChild(firstAncestor);
        this.matrix.addChild(firstAncestor);
        this.frames.splice(index, 1);
        this.frames.push(firstAncestor);
      }
    }

    frame.updateHtmlElements(true);
    frame.updateDropShadowOnDragStart();

    frame.saveBounds();
    frame.saveCusrosPositionFromAnchor(coordinates);
  }

  protected onFrameMouseOver(frame: FrameContainer, event: InteractionEvent) {
    event?.stopPropagation();
    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      document.body.style.cursor = CursorStyles.Highlighter;
      return;
    }
    if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = CursorStyles.NotAllowed;
      return;
    }
    if (this.imageDragging) return;
    if (frame.getFrames().some(item => item?.active)) return;
    frame.active = true;
    if (frame.parent instanceof FrameContainer && frame.parent.active) {
      this.onFrameMouseOut(frame.parent, event);
    }

    if (this.frameHover && this.activeFrameRef) {
      // bugfix: mouse can move quickly and jump to another frame
      // so, need to clear previous active frame before selecting new one
      this.activeFrameRef.active = false;
      this.activeFrameRef.off('mousemove');
      this.activeFrameRef.hideBordersAndCorners();
    }

    this.activeFrameRef = frame;
    this.frameHover = true;
    if (this.frameDragging) return;

    this.updateCursorOnFrameMouseMove(frame, event);

    frame.off('mousemove');
    frame.on('mousemove', event2 => {
      this.onFrameMouseMove(frame, event2);
    });
    frame.showBordersAndCorners();
    this.render();
  }

  protected onFrameMouseOut(frame: FrameContainer, event?: InteractionEvent) {
    event?.stopPropagation();
    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      document.body.style.cursor = CursorStyles.Highlighter;
      return;
    }
    if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
      const coordinates = event?.data.getLocalPosition(this.stage);
      if (frame.parent instanceof FrameContainer && coordinates && frame.parent.mouseCollision(coordinates)) {
        this.onFrameMouseOver(frame.parent, event);
      }
      return;
    }
    if (!frame.active) return;
    frame.active = false;
    if (!frame.getFrames().some(item => item?.active)) {
      const coordinates = event?.data.getLocalPosition(this.stage);
      if (
        frame.parent instanceof FrameContainer &&
        coordinates &&
        frame.parent.mouseCollision(coordinates) &&
        !this.imageDragging &&
        !this.frameDragging
      ) {
        this.onFrameMouseOver(frame.parent, event);
      } else {
        this.activeFrameRef = null;
        this.frameHover = false;
      }
    }
    if (this.frameDragging) return;

    if (frame.publishIconActive) this.onFramePublishIconMouseOut(frame);

    frame.off('mousemove');
    document.body.style.cursor = CursorStyles.Default;
    frame.hideBordersAndCorners();
    this.render();
  }

  protected onFrameMouseMove(frame: FrameContainer, event: InteractionEvent) {
    if (this.panningActive) return;
    event.stopPropagation();
    this.updateCursorOnFrameMouseMove(frame, event);

    if (this.frameMouseMoveState === CursorStates.FrameInfo && !frame.getInfoShownState()) {
      this.onFrameInfoMouseOver(frame);
    }

    if (frame.getInfoIconActiveState() && this.frameMouseMoveState !== CursorStates.FrameInfo) {
      this.onFrameInfoMouseOut(frame, event);
    }

    if (this.frameMouseMoveState === CursorStates.PublishIcon && !frame.publishIconActive) {
      this.onFramePublishIconMouseOver(frame);
    }

    if (frame.publishIconActive && this.frameMouseMoveState !== CursorStates.PublishIcon) {
      this.onFramePublishIconMouseOut(frame);
    }
  }

  protected onFrameInfoMouseOver(frame: FrameContainer) {
    document.body.style.cursor = CursorStyles.Pointer;
    frame.setInfoIconActiveState(true);
    this.render();
    if (this.frameDragging || frame.getInfoLockedState()) return;
    if (this.frameHoverTimeout) {
      clearTimeout(this.frameHoverTimeout);
    }
    this.frameHoverTimeout = setTimeout(() => {
      if (!this.frameDragging) this.showFrameInfo(frame);
    }, 250);
  }

  protected onFrameInfoMouseOut(frame: FrameContainer, event: InteractionEvent) {
    document.body.style.cursor = CursorStyles.Default;

    if (this.selectedTool === MatrixToolEnum.Highlighter) document.body.style.cursor = CursorStyles.Highlighter;
    if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
      const coordinates = event?.data.getLocalPosition(this.stage);
      if (frame.parent instanceof FrameContainer && coordinates && frame.parent.mouseCollision(coordinates)) {
        this.onFrameMouseOver(frame.parent, event);
      }
    }

    this.render();
    frame.setInfoIconActiveState(false);
    if (frame.getInfoLockedState()) return;
    clearTimeout(this.frameHoverTimeout);
    if (frame.getInfoShownState()) this.hideFrameInfo(frame);
  }

  protected onFrameInfoClick(frame: FrameContainer) {
    clearTimeout(this.frameHoverTimeout);
    frame.setInfoLockedState(!frame.getInfoLockedState());
    if (!frame.getInfoLockedState()) {
      this.hideFrameInfo(frame);
    } else {
      this.showFrameInfo(frame);
    }

    frame.changeInfoIconTexture();
    this.render();
  }

  protected updateFrameHtmlElements(image: ImageContainer) {
    const bounds = image.getImageBounds();
    this.frames.forEach(f => {
      f?.updateHtmlElements(
        f.htmlElementsVisibleTemp && !this.detectFramesCollision(image, bounds, f, f.getFrameBounds())
      );
    });
  }

  protected updateAllFrameHtmlElements() {
    this.frames.forEach((frame, i) => {
      const bounds = frame.getFrameBounds();
      const detect = this.frames
        .slice(i)
        .filter(f => f.getId() !== frame.getId())
        .some(f => {
          return this.detectFramesCollision(frame, bounds, f, f.getFrameBounds());
        });
      frame.updateHtmlElements(!detect);
    });
    this.frames[this.frames.length - 1]?.updateHtmlElements(true);
  }

  protected setupFrameDrawing() {
    this.imageMatrix.interactive = false;
    this.imageMatrix.interactiveChildren = false;
    this.matrix.interactive = true;
    this.matrix
      .on('mousedown', this.onFrameDrawStart.bind(this))
      .on('mouseup', this.onFrameDrawEnd.bind(this))
      .on('mouseupoutside', this.onFrameDrawEnd.bind(this))
      .on('mousemove', this.onFrameDrawMove.bind(this));
  }

  protected removeFrameDrawingListeners() {
    this.matrix.interactive = false;
    this.matrix.removeAllListeners();
    this.imageMatrix.interactive = true;
    this.imageMatrix.interactiveChildren = true;
    this.setupMatrixHoverListeners();
  }

  protected onFrameDrawStart(event) {
    if (document.body.style.cursor === CursorStyles.NotAllowed || this.isFrameFeatureNameEmpty()) return;
    this.viewport.pause = true;
    this.framingStarted = true;
    this.userInteraction = true;

    this.startCoordinates = { ...event.data.getLocalPosition(this.matrix) };
    this.frameConstructorRect.position.set(this.startCoordinates.x, this.startCoordinates.y);
    this.frameConstructorRect.clear().lineStyle(this.frameBorderWidth, 0x04d7b9).drawRect(0, 0, 0, 0);
    this.matrix.addChild(this.frameConstructorRect);

    event.stopPropagation();
  }

  protected onFrameDrawEnd(event) {
    if (this.framingStarted) {
      this.viewport.pause = false;
      document.body.style.cursor = CursorStyles.Default;
      this.framingStarted = false;
      this.userInteraction = false;

      const coordinates = event.data.getLocalPosition(this.matrix);

      if (coordinates.x < this.startCoordinates.x)
        [coordinates.x, this.startCoordinates.x] = [this.startCoordinates.x, coordinates.x];
      if (coordinates.y < this.startCoordinates.y)
        [coordinates.y, this.startCoordinates.y] = [this.startCoordinates.y, coordinates.y];
      const width = coordinates.x - this.startCoordinates.x;
      const height = coordinates.y - this.startCoordinates.y;

      if (width < this.imageDesiredSizes.width || height < this.imageDesiredSizes.height) {
        this.removeFrameInConstruction();
        return;
      }

      this.frameConstructorRect.clear();
      this.matrix.removeChild(this.frameConstructorRect);

      const { x, y } = this.startCoordinates;
      const model = {
        dimensions: { width, height },
        position: { x, y },
        title: { featureName: this.frameFeatureName.trim(), featureValue: undefined }
      };
      const frame = this.createFrame(model, true);
      frame.active = false;
      frame.hideBordersAndCorners();
      this.activeFrameRef = null;
      this.frameHover = false;

      this.addFrameInCanvasState(frame, true);
      this.updateAllFrameHtmlElements();
      this.store.dispatch(new SetSelectedTool(MatrixToolEnum.Cursor));
    } else if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
    }
  }

  protected onFrameDrawMove(event) {
    if (this.framingStarted) {
      const coordinates = event.data.getLocalPosition(this.matrix);
      const w = coordinates.x - this.startCoordinates.x;
      const h = coordinates.y - this.startCoordinates.y;
      this.frameConstructorRect.clear().lineStyle(this.frameBorderWidth, 0x04d7b9).drawRect(0, 0, w, h);
    }
    event.stopPropagation();
  }

  private removeImageFromRefs(image: ImageContainer) {
    if (image.parent instanceof FrameContainer) {
      const frame = image.parent;
      const indexInFrame = frame.getImages().indexOf(image);
      if (indexInFrame > -1) {
        const frameImages = frame.getImages();
        frameImages.splice(indexInFrame, 1);
        frame.setImages(frameImages);
        frame.removeChild(image);
        frame.makeGrid();
        this.render();
      }
    } else {
      const indexOnMatrix = this.imageRefs.indexOf(image);
      if (indexOnMatrix > -1) {
        this.imageRefs.splice(indexOnMatrix, 1);
      }
    }
  }

  private removeImagesFromRefs(images: ImageContainer[]) {
    let frames = [];
    images.forEach(image => {
      if (image.parent instanceof FrameContainer) {
        const frame = image.parent;
        const indexInFrame = frame.getImages().indexOf(image);
        if (indexInFrame > -1) {
          const frameImages = frame.getImages();
          frameImages.splice(indexInFrame, 1);
          frame.setImages(frameImages);
          frame.removeChild(image);
          frames.push(frame);
        }
      } else {
        const indexOnMatrix = this.imageRefs.indexOf(image);
        if (indexOnMatrix > -1) {
          this.imageRefs.splice(indexOnMatrix, 1);
        }
      }
    });

    if (frames.length > 0) {
      frames = frames.filter((item, i, ar) => ar.findIndex(l => l.getId() === item.getId()) === i);
      let framesToUpdate = [];
      frames.forEach(frame => {
        framesToUpdate.push(this.getFirstFrameAncestor(frame));
      });
      framesToUpdate = framesToUpdate.filter((item, i, ar) => ar.findIndex(l => l.getId() === item.getId()) === i);
      framesToUpdate.forEach(frame => {
        frame.makeGrid();
      });
    }
    this.render();
  }

  private checkFrameForFrameCollision(frameToTest: FrameContainer, event: InteractionEvent): boolean {
    if (!this.frames || this.frames.length === 0) return false;
    const coordinates = event.data.getLocalPosition(this.stage);
    for (const frame of this.frames) {
      const frameCollided = frame.checkFramesForMouseCollision(coordinates);
      if (frameCollided) {
        if (
          frameCollided.getId() === frameToTest.getId() ||
          frameToTest.checkIfFrameIsSubFrame(frameCollided.getId())
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const index = this.frames.indexOf(frameToTest);
        if (index > -1) {
          this.frames.splice(index, 1);
          this.removeFrameFromCanvasState(frameToTest.getId());
        }
        const indexOfRemovedFrame = frameCollided.getFrames().findIndex(item => item == null);
        if (indexOfRemovedFrame > -1) {
          frameCollided.getFrames()[indexOfRemovedFrame] = frameToTest;
        } else {
          frameCollided.addFrame(frameToTest);
        }

        if (frameToTest.active) this.onFrameMouseOut(frameToTest, event);

        this.updateFrameDragSourceFrame();
        frame.makeGrid();
        frame.updateFrameTitlePosition();
        frame.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes));
        frame.updatePublishIcon();
        if (frameCollided.getInfoLockedState()) {
          this.hideFrameInfo(frameCollided, false);
          this.showFrameInfo(frameCollided, false);
        }
        if (frameCollided.getId() !== frame.getId() && frame.getInfoLockedState()) {
          this.hideFrameInfo(frame, false);
          this.showFrameInfo(frame, false);
        }
        this.render();

        this.moveImagesUnderFrame(frame);
        return true;
      }
    }
    frameToTest.isParentFrame = true;
    frameToTest.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes));
    frameToTest.updatePublishIcon();
    this.updateFrameDragSourceFrame();
    return false;
  }

  private updateImageDragSourceFrame() {
    if (this.imageDragSourceFrames.length === 0) return;
    let framesToUpdate = [];
    this.imageDragSourceFrames.forEach(frame => {
      framesToUpdate.push(this.getFirstFrameAncestor(frame));
    });
    this.imageDragSourceFrames = [];
    framesToUpdate = framesToUpdate.filter((item, i, ar) => ar.findIndex(l => l.getId() === item.getId()) === i);

    framesToUpdate.forEach(frame => {
      frame.makeGrid();
      frame.updateFrameTitlePosition();
      frame.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes));
      frame.updatePublishIcon();
    });
    this.updateFramesInCanvasState(framesToUpdate);
  }

  private updateFrameDragSourceFrame() {
    if (this.frameDragSourceFrame && this.frameDragSourceFrame.checkNumFramesChanged()) {
      this.frameDragSourceFrame.makeGrid();
      this.frameDragSourceFrame.gridParent();
      const firstAncestor = this.getFirstFrameAncestor(this.frameDragSourceFrame);
      firstAncestor.updateFrameTitlePosition();
      firstAncestor.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes));
      firstAncestor.updatePublishIcon();
      this.updateFrameInCanvasState(firstAncestor);
      this.frameDragSourceFrame = undefined;
    }
  }

  private decelerate(image: ImageContainer) {
    if (!image.moved()) {
      this.updateImagesInCanvasState();
      this.numImgesDecelerating--;
      if (this.numImgesDecelerating <= 0) this.animation = false;
      if (image.getMouseOverState()) this.onImageMouseOver(image);
      return;
    }

    setTimeout(() => {
      this.decelerate(image);
    }, animationFrameInMS);

    image.decelerate(this.viewport.worldWidth, this.viewport.worldHeight);

    if (image.getSelectedState()) {
      this.onSelectedImageMove(image);
    }
  }

  private detectMouseCollision(bounds, coordinates): boolean {
    return (
      coordinates.x >= bounds.x &&
      coordinates.x <= bounds.x + bounds.width &&
      coordinates.y >= bounds.y &&
      coordinates.y <= bounds.y + bounds.height
    );
  }

  protected detectCollision(frameBorderBounds, imageBounds): boolean {
    return (
      imageBounds.x + imageBounds.width / 2 >= frameBorderBounds.x &&
      imageBounds.x + imageBounds.width / 2 <= frameBorderBounds.x + frameBorderBounds.width &&
      imageBounds.y + imageBounds.height / 2 >= frameBorderBounds.y &&
      imageBounds.y + imageBounds.height / 2 <= frameBorderBounds.y + frameBorderBounds.height
    );
  }

  protected detectFramesFramed(bounds1: IBounds, bounds2: IBounds) {
    return (
      bounds2.x + bounds2.width / 2 >= bounds1.x &&
      bounds2.x + bounds2.width / 2 <= bounds1.x + bounds1.width &&
      bounds2.y + bounds2.height / 2 >= bounds1.y &&
      bounds2.y + bounds2.height / 2 <= bounds1.y + bounds1.height
    );
  }

  protected detectFramesCollision(f1: FrameContainer | ImageContainer, b1: IBounds, f2: FrameContainer, b2: IBounds) {
    const frameTitleHeight = 23;
    const iconHalfSize = 12;
    if (f1 instanceof FrameContainer) {
      b1.y -= frameTitleHeight;
      b1.height += frameTitleHeight;
      if (f1.active) {
        b1.x -= iconHalfSize;
        b1.width += iconHalfSize;
      }
      if (f1.publishIconVisible) {
        b1.width += iconHalfSize;
      }
    }

    b2.y -= frameTitleHeight;
    b2.height += frameTitleHeight;
    if (f2.active) {
      b2.x -= iconHalfSize;
      b2.width += iconHalfSize;
    }
    if (f2.publishIconVisible) {
      b2.width += iconHalfSize;
    }

    return b1.x < b2.x + b2.width && b1.x + b1.width > b2.x && b1.y < b2.y + b2.height && b1.y + b1.height > b2.y;
  }

  protected getImagePosRelativeToImageMatrix(image: ImageContainer): IPosition {
    const globalPos = image.toGlobal(this.matrix.position);
    const localPos = image.toLocal(globalPos, this.imageMatrix);
    return {
      x: (globalPos.x - localPos.x) / this.resolution,
      y: (globalPos.y - localPos.y) / this.resolution
    };
  }

  private getImagePosRelativeToFrame(image): IPosition {
    return { x: image.x / this.resolution, y: image.y / this.resolution };
  }

  protected getFramePosition(frame: FrameContainer): IPosition {
    return {
      x: frame.x / this.resolution,
      y: frame.y / this.resolution
    };
  }
  // TODO: uncomment it if needed in future
  // private onGroupLabelRightClick(groupLabel: GroupLabel, event: MouseEvent) {
  //   event.preventDefault();
  //   const selectedImages = groupLabel.getGroupContainer().getAllImages();
  //   this.contextMenuOpened$.next({ items: selectedImages, position: { x: event.clientX, y: event.clientY } });
  // }

  private onGroupInfoMouseOver(groupLabel: GroupLabel) {
    groupLabel.setInfoHoverState(true);
    groupLabel.showTooltip();
    if (groupLabel.getInfoLockedState() || groupLabel.getInfoShownState()) return;
    if (this.groupHoverTimer) {
      this.groupHoverTimer.unsubscribe();
    }
    this.groupHoverTimer = timer(250)
      .pipe(take(1))
      .subscribe(() => {
        this.showGroupInfo(groupLabel);
      });
  }

  private onGroupInfoMouseOut(groupLabel: GroupLabel) {
    groupLabel.setInfoHoverState(false);
    groupLabel.hideTooltip();
    if (this.groupInfoMouseDown) {
      this.groupInfoMouseDown = false;
      if (this.groupClickTimer) {
        this.groupClickTimer.unsubscribe();
        if (!this.groupClickTimeoutCompleted) {
          this.toggleGroupLabelLockedState(groupLabel);
        }
      }
    }
    if (groupLabel.getInfoLockedState()) return;
    if (this.groupHoverTimer) this.groupHoverTimer.unsubscribe();
    if (groupLabel.getInfoShownState()) this.hideGroupInfo(groupLabel);
  }

  private onGroupLabelMouseOver(group: GroupContainer, groupLabel: GroupLabel, event: InteractionEvent) {
    groupLabel.setCustomWrapperOpacity(1);
    this.addGroupLabelMouseListeners(group, groupLabel);
  }

  private onGroupLabelMouseOut(groupLabel: GroupLabel) {
    groupLabel.setCustomWrapperOpacity();
    this.removeGroupLabelMouseListeners(groupLabel.getGroupContainer());
  }

  private onGroupInfoMouseDown(groupLabel: GroupLabel) {
    this.groupInfoMouseDown = true;
    if (this.groupClickTimer) {
      this.groupClickTimer.unsubscribe();
      this.groupClickTimeoutCompleted = false;
    }
    this.groupClickTimer = timer(500)
      .pipe(take(1))
      .subscribe(() => {
        this.toggleAllGroupInfoByLevel(groupLabel);
        this.groupClickTimeoutCompleted = true;
      });
  }

  private onGroupInfoMouseUp(groupLabel: GroupLabel) {
    if (this.groupInfoMouseDown) {
      this.groupInfoMouseDown = false;
      this.groupClickTimer.unsubscribe();
      if (!this.groupClickTimeoutCompleted && !this.groupDragging) {
        this.toggleGroupLabelLockedState(groupLabel);
      }
    }
  }

  private onGroupQuickStatsIconMouseDown() {
    this.groupQuickStatsMouseDown = true;
  }

  private onGroupQuickStatsIconMouseUp(level: number) {
    if (this.groupQuickStatsMouseDown) {
      this.groupQuickStatsMouseDown = false;
      if (!this.groupDragging) {
        const shownState = this.groupLabelsContainer.labelsByLevel[level].every(groupLabel =>
          groupLabel.getQuickStatsShownState()
        );
        if (shownState) {
          this.groupLabelsContainer.hideGroupsQuickStats(level, true);
        } else {
          this.groupLabelsContainer.showGroupsQuickStats(
            level,
            true,
            this.store.selectSnapshot(StudyState.getHoverInfoConfig),
            this.store.selectSnapshot(StudyState.getMetricsCustomFormattings)
          );
        }
      }
    }
  }

  private onGroupLabelMouseDown(group: GroupContainer, groupLabel: GroupLabel, event: InteractionEvent): void {
    event.stopPropagation();
    this.imageMatrix.off('mousemove');
    if (this.groupContainerRefs.findIndex(item => item.getId() === group.getId()) === -1) {
      group.delete();
      group = null;
      groupLabel = null;
      return;
    }
    if (groupLabel.isMouseOverInfo(event)) {
      this.onGroupInfoMouseDown(groupLabel);
    }
    if (groupLabel.isMouseOverQuickStats(event)) {
      this.onGroupQuickStatsIconMouseDown();
    }

    this.viewport.pause = true;
    this.imageMatrix.interactive = false;
    this.imageMatrix.interactiveChildren = false;
    group.interactiveChildren = false;

    this.groupMoved = false;

    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    this.matrix.off('mouseout', this.matrixMouseOutListenerRef);
    this.matrixMouseMoveListenerRef = this.onGroupMouseMoveForDrag.bind(this, group, groupLabel);
    this.matrixMouseOutListenerRef = this.onGroupLabelMouseUp.bind(this, group, groupLabel);
    this.matrix.on('mousemove', this.matrixMouseMoveListenerRef);
    this.matrix.on('mouseout', this.matrixMouseOutListenerRef);

    const coordinates = event.data.getLocalPosition(this.matrix);
    this.groupMovePrevPosition = coordinates;
  }

  private onGroupMouseMoveForDrag(group: GroupContainer, groupLabel: GroupLabel, event: InteractionEvent) {
    event.stopPropagation();

    const coordinates = event.data.getLocalPosition(this.matrix);
    if (
      (this.groupOrientation === GroupOrientation.Vertical &&
        Math.abs(coordinates.x - this.groupMovePrevPosition.x) < 1) ||
      (this.groupOrientation === GroupOrientation.Horizontal &&
        Math.abs(coordinates.y - this.groupMovePrevPosition.y) < 1)
    ) {
      return;
    }
    this.toggleLockedStateimmediately$.next();
    if (!this.groupMoved) this.onGroupDragStart(group, coordinates);
    this.onGroupInfoMouseUp(groupLabel);
    this.onGroupQuickStatsIconMouseUp(groupLabel.getLevel());
    if (groupLabel.getInfoHoverState()) {
      this.onGroupInfoMouseOut(groupLabel);
    }
    this.setGroupMovingCursor();
    this.groupMovePrevPosition = coordinates;
    this.groupMoved = true;
    if (this.groupDragging) {
      group.onDrag(coordinates, this.groupOrientation, this.groupDraggingLimits);
      this.moveGroupsAndSubgroupsLabels(group);
      this.moveGroupsInfoPanelsOnCanvas(group);
      const neighbors = this.getGroupNeighborsByIndex(group);
      for (const neighbor of neighbors) {
        const swapped = this.swapGroupWithNeighbor(group, neighbor);
        if (swapped) break;
      }
      this.render();
    }
  }

  private swapGroupWithNeighbor(group: GroupContainer, neighbor: GroupContainer): boolean {
    const groupBounds = {
      coordinate: this.groupOrientation === GroupOrientation.Vertical ? group.x : group.y,
      size:
        this.groupOrientation === GroupOrientation.Vertical
          ? group.getSavedDimensions().width
          : group.getSavedDimensions().height
    };
    const neighborBounds = {
      coordinate: this.groupOrientation === GroupOrientation.Vertical ? neighbor.x : neighbor.y,
      size: this.groupOrientation === GroupOrientation.Vertical ? neighbor.getSavedDimensions().width : neighbor.height
    };
    if (
      (this.activeGroupZIndexTemp > neighbor.zIndex &&
        groupBounds.coordinate < neighborBounds.coordinate + neighborBounds.size / 2) ||
      (this.activeGroupZIndexTemp < neighbor.zIndex &&
        groupBounds.coordinate + groupBounds.size > neighborBounds.coordinate + neighborBounds.size / 2)
    ) {
      const savedPosition = group.getSavedPosition();
      const newPosition = { x: neighbor.x, y: neighbor.y };
      if (this.activeGroupZIndexTemp > neighbor.zIndex) {
        if (this.groupOrientation === GroupOrientation.Vertical) {
          neighbor.position.set(savedPosition.x + groupBounds.size - neighborBounds.size, savedPosition.y);
        } else {
          neighbor.position.set(savedPosition.x, savedPosition.y + groupBounds.size - neighborBounds.size);
        }
        group.savePosition(newPosition);
      } else {
        if (this.groupOrientation === GroupOrientation.Vertical) {
          group.savePosition({ x: newPosition.x + neighborBounds.size - groupBounds.size, y: newPosition.y });
        } else {
          group.savePosition({ x: newPosition.x, y: newPosition.y + neighborBounds.size - groupBounds.size });
        }
        neighbor.position.set(savedPosition.x, savedPosition.y);
      }
      this.moveGroupsAndSubgroupsLabels(neighbor);
      this.moveGroupsInfoPanelsOnCanvas(neighbor);
      [this.activeGroupZIndexTemp, neighbor.zIndex] = [neighbor.zIndex, this.activeGroupZIndexTemp];
      group.parent.swapChildren(group, neighbor);
      this.resetGroupContainerRefsByLevels(group.getLevel());
      return true;
    }
    return false;
  }

  private resetGroupContainerRefsByLevels(level: number) {
    if (this.groupContainerRefsByLevels[level]) {
      if (level > 0) {
        this.groupContainerRefsByLevels[level] = this.groupContainerRefsByLevels[level - 1].reduce(
          (result, group) => [...result, ...group.getGroups()],
          []
        );
      }
      this.resetGroupContainerRefsByLevels(level + 1);
    }
  }

  private getGroupNeighborsByIndex(group: GroupContainer): Array<GroupContainer> {
    group.zIndex = this.activeGroupZIndexTemp;
    group.parent.sortChildren();
    const index = group.parent.getChildIndex(group);
    const neighbors = [];
    if (index > 0) neighbors.push(group.parent.getChildAt(index - 1));
    if (index < group.parent.children.length - 1) neighbors.push(group.parent.getChildAt(index + 1));
    group.zIndex = 1000;
    return neighbors;
  }

  private onGroupDragStart(group: GroupContainer, coordinates: IPosition) {
    this.hideImagePopper();
    this.contextMenuClosed$.next();

    this.storeAllGroupsInfoLockedStateAndHide();

    this.groupDragging = true;
    this.userInteraction = true;
    this.groupDraggingLimits = {
      min: null,
      max: null
    };
    const neighbors =
      group.parent instanceof GroupContainer ? group.parent.getAllGroups() : this.groupContainerRefsByLevels[0];
    neighbors.forEach(item => {
      if (group.getId() !== item.getId()) {
        [...item.getAllGroups(), item].forEach(anotherGroup => {
          this.groupLabelsContainer.labels[anotherGroup.getId()].setCustomWrapperOpacity(0.5);
        });
      }
      const coordinate = this.groupOrientation === GroupOrientation.Vertical ? item.x : item.y;
      if (this.groupDraggingLimits.min === null || this.groupDraggingLimits.min > coordinate) {
        this.groupDraggingLimits.min = coordinate;
      }
      if (this.groupDraggingLimits.max === null || this.groupDraggingLimits.max < coordinate) {
        this.groupDraggingLimits.max = coordinate + item.width - group.width;
      }
    });
    group.savePosition();
    group.saveDimensions();
    group.saveCusrosPositionFromAnchor(coordinates);
    this.activeGroupZIndexTemp = group.zIndex;
    group.zIndex = 1000;

    this.imageDragScaleAnimationTl?.kill();
    this.createImageDragScaleTimeline();
    group.getAllImages().forEach(image => {
      this.animateImageDragScale(image);
    });
    [...group.getAllGroups(), group].forEach(subGroup => {
      const subGroupLabel = this.groupLabelsContainer.labels[subGroup.getId()];
      this.animateGroupLabelDragScale(subGroupLabel);
    });
  }

  private onGroupLabelMouseUp(group: GroupContainer, groupLabel: GroupLabel, event: InteractionEvent) {
    event.stopPropagation();
    this.onGroupInfoMouseUp(groupLabel);
    this.onGroupQuickStatsIconMouseUp(groupLabel.getLevel());

    this.viewport.pause = false;
    this.imageMatrix.interactive = true;
    this.imageMatrix.interactiveChildren = true;
    group.interactiveChildren = true;

    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    this.matrix.off('mouseout', this.matrixMouseOutListenerRef);
    group.off('mousemove');
    group.on('mousemove', this.onGroupMouseMove.bind(this, group, this.groupLabelsContainer.labels[group.getId()]));
    this.groupDragging = false;
    this.userInteraction = false;

    if (this.groupMoved) {
      this.groupMoved = false;
      const savedPosition = group.getSavedPosition();
      group.position.set(savedPosition.x, savedPosition.y);
      this.render();
      this.moveGroupsAndSubgroupsLabels(group);
      this.moveGroupsInfoPanelsOnCanvas(group);
      group.zIndex = this.activeGroupZIndexTemp;
      group.parent.sortChildren();
      this.imageDragScaleAnimationTl?.kill();
      this.createImageDragScaleTimeline();
      group.getAllImages().forEach(image => {
        this.animateImageDragScale(image);
      });
      [...group.getAllGroups(), group].forEach((subGroup, index, array) => {
        const subGroupLabel = this.groupLabelsContainer.labels[subGroup.getId()];
        this.animateGroupLabelDragScale(subGroupLabel, () => {
          if (index === array.length - 1) {
            this.moveGroupsAndSubgroupsLabels(group);
            this.moveGroupsInfoPanelsOnCanvas(group);
            if (group.isFirst) {
              this.setGroupsInCanvasState(this.groupContainerRefsByLevels[0]);
            } else {
              const firstAncestor = this.getFirstGroupAncestor(group);
              this.updateGroupSubgroupsInCanvasState(firstAncestor);
            }
            this.updateImagesInCanvasState();
            this.restoreAllGroupsInfoLockedState();
          }
        });
      });
      this.groupContainerRefsByLevels[group.getLevel()].forEach(item => {
        if (group.getId() !== item.getId()) {
          [...item.getAllGroups(), item].forEach(anotherGroup => {
            this.groupLabelsContainer.labels[anotherGroup.getId()].setCustomWrapperOpacity();
          });
        }
      });
      return;
    }
  }

  protected getImageMatrixBounds() {
    const b = {
      x:
        (this.groupOrientation === GroupOrientation.Horizontal
          ? this.imageMatrix.x - (this.imageDesiredSizes.width + this.distanceBetweenImages) / 2 - this.viewport.left
          : this.imageMatrix.x - this.viewport.left) * this.viewport.scale.x,
      y:
        (this.groupOrientation === GroupOrientation.Vertical
          ? this.imageMatrix.y - (this.imageDesiredSizes.width + this.distanceBetweenImages) / 2 - this.viewport.top
          : this.imageMatrix.y - this.viewport.top) * this.viewport.scale.x,
      width: this.savedImageMatrixWidth * this.viewport.scale.x * this.resolution,
      height: this.savedImageMatrixHeight * this.viewport.scale.x * this.resolution
    };
    return b;
  }

  protected getGroupPosInImageMatrix(group: GroupContainer) {
    const pos = { x: group.x, y: group.y };
    if (group.parent instanceof GroupContainer) {
      const parentPos = this.getGroupPosInImageMatrix(group.parent as GroupContainer);
      pos.x += parentPos.x;
      pos.y += parentPos.y;
    }

    return pos;
  }

  protected updateGroupLocalBounds() {
    this.groupContainerRefs.forEach(group => {
      const posInImageMatrix = this.getGroupPosInImageMatrix(group);
      const customLocalBounds = {
        x: posInImageMatrix.x - 20 * this.resolution,
        y: posInImageMatrix.y - 20 * this.resolution,
        width: group.width,
        height: group.height
      };
      group.setCustomLocalBounds(customLocalBounds);
    });
  }

  protected updateGroupBounds(imageMatrixBounds: IBounds) {
    this.groupContainerRefs.forEach(group => {
      const localBounds = group.getCustomLocalBounds();
      const customBounds = {
        x: imageMatrixBounds.x + localBounds.x * this.viewport.scale.x,
        y: imageMatrixBounds.y + localBounds.y * this.viewport.scale.x,
        width: localBounds.width * this.viewport.scale.x,
        height: localBounds.height * this.viewport.scale.x
      };
      group.setCustomBounds(customBounds);
    });
  }

  private moveGroupsAndSubgroupsLabels(group: GroupContainer) {
    this.updateGroupLocalBounds();
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.updateGroupBounds(imageMatrixBounds);
    this.groupLabelsContainer.moveLabel(group);
    group.getAllGroups().forEach(subGroup => {
      this.groupLabelsContainer.moveLabel(subGroup);
    });
  }

  private moveGroupsInfoPanelsOnCanvas(group: GroupContainer): void {
    if (this.infoPanelShowState !== 'canvas') return;
    const imageMatrixBounds = this.imageMatrix.getBounds();
    group.getAllImages().forEach(image => {
      if (!image.infoPanel) return;
      image.infoPanel.setImageRelativePos(imageMatrixBounds, image.getImageBounds());
      image.infoPanel.move(imageMatrixBounds);
    });
  }

  protected updateCursorOnFrameMouseMove(frame: FrameContainer, event: InteractionEvent) {
    this.frameMouseMoveState = frame.getMouseMoveState(event.data.global.x, event.data.global.y);

    if (
      frame.getInfoIconActiveState() &&
      this.frameMouseMoveState !== CursorStates.Drag &&
      this.frameMouseMoveState !== CursorStates.FrameInfo
    ) {
      this.frameMouseMoveState = CursorStates.Drag;
    }
    document.body.style.cursor = CursorStyles[this.frameMouseMoveState];
  }

  private onFramePublishIconMouseOver(frame: FrameContainer) {
    frame.publishIconActive = true;
    document.body.style.cursor = CursorStyles.Pointer;
    if (frame.publishIconState === PublishIconStates.Default) {
      frame.publishIconRef.classList.remove('default');
      frame.publishIconRef.classList.add('hover');
    }
  }
  private onFramePublishIconMouseOut(frame: FrameContainer) {
    frame.publishIconActive = false;
    document.body.style.cursor = CursorStyles.Default;
    if (frame.publishIconState === PublishIconStates.Default) {
      frame.publishIconRef.classList.remove('hover');
      frame.publishIconRef.classList.add('default');
    }
  }

  private onFramePublishIconClick(frame: FrameContainer) {
    if (frame.getFeatureValue() && !this.frameMoved) {
      this.addCustomAttributes(frame);
      frame.onPublishResponse(true);
      this.dataService.createFiltersFromCustomAttr();

      this.snackBar.open('Attribute Created', 'OK', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'start'
      });

      // To test the animation comment everything above, comment out below:

      // frame.onPublishIconClick();
      // setTimeout(() => {
      //   // We get responce from backend:
      //   const success = false;
      //   if (success) {
      //     this.addCustomAttributes(frame);
      //     this.dataService.createFiltersFromCustomAttr();
      //   }
      //   frame.onPublishResponse(success);
      //   this.snackBar.open(success ? 'Attribute Created' : 'Could Not create Attributes', 'OK', {
      //     duration: 3000,
      //     verticalPosition: 'bottom',
      //     horizontalPosition: 'start'
      //   });
      // }, 1000);
    }
  }

  private addCustomAttributes(frame: FrameContainer) {
    const allFrames = [frame, ...frame.getAllFrames()];
    let data = this.store.selectSnapshot(StudyState.getCustomAttributes);
    if (!data) data = {};
    allFrames.forEach((fr: FrameContainer) => {
      if (!fr.getFeatureValue()) return;
      const attributeInfo = {
        name: fr.getFeatureName(),
        value: fr.getFeatureValue()
      };
      const allImages = fr.getAllImages();
      if (!data[attributeInfo.name]) data[attributeInfo.name] = {};
      if (data[attributeInfo.name][attributeInfo.value]) {
        data[attributeInfo.name][attributeInfo.value].push(...allImages.map(image => image.getId()));
        data[attributeInfo.name][attributeInfo.value] = data[attributeInfo.name][attributeInfo.value].filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
      } else {
        data[attributeInfo.name][attributeInfo.value] = allImages.map(image => image.getId());
      }
    });
    this.store.dispatch(new SetCustomAttributes(data));
    this.dataService.createFiltersFromCustomAttr(); // CustomAttrFilters always should be updated after SetCustomAttributes
  }

  protected showFrameInfo(frame: FrameContainer, animate = true) {
    frame.showInfo(
      this.store.selectSnapshot(StudyState.getHoverInfoConfig),
      this.store.selectSnapshot(StudyState.getMetricsCustomFormattings),
      animate
    );
    this.hideSubFramesInfoInstantly(frame);
    this.hideSubFramesTitle(frame);
    this.render();
  }

  protected hideFrameInfo(frame: FrameContainer, animate = true) {
    frame.hideInfo(animate);
    this.showSubFramesTitle(frame);
    this.render();
  }

  private toggleAllGroupInfoByLevel(groupLabel: GroupLabel): void {
    const lockedState = !groupLabel.getInfoLockedState();
    const groupLabelsWithSameLevel: Array<GroupLabel> = this.groupContainerRefsByLevels[groupLabel.getLevel()]
      .map(group => this.groupLabelsContainer.labels[group.getId()])
      .filter(item => item.getInfoLockedState() !== lockedState);
    const groupIndexInLevel = groupLabelsWithSameLevel.findIndex(item => item.getLabelId() === groupLabel.getLabelId());
    let i = 0;
    while (groupLabelsWithSameLevel[groupIndexInLevel + i] || groupLabelsWithSameLevel[groupIndexInLevel - i]) {
      if (i === 0) {
        this.toggleGroupLabelLockedState(groupLabelsWithSameLevel[groupIndexInLevel], lockedState);
      } else {
        const prev = groupLabelsWithSameLevel[groupIndexInLevel - i];
        const next = groupLabelsWithSameLevel[groupIndexInLevel + i];
        race(timer(250 * i), this.toggleLockedStateimmediately$)
          .pipe(take(1))
          .subscribe(() => {
            if (prev) this.toggleGroupLabelLockedState(prev, lockedState);
            if (next) this.toggleGroupLabelLockedState(next, lockedState);
          });
      }
      i++;
    }
  }

  private toggleGroupLabelLockedState(groupLabel: GroupLabel, force: boolean = null) {
    if (force === null) {
      groupLabel.setInfoLockedState();
    } else {
      groupLabel.setInfoLockedState(force);
    }
    if (groupLabel.getInfoLockedState()) {
      this.showGroupInfo(groupLabel);
    } else {
      this.hideGroupInfo(groupLabel);
    }
  }

  public storeAllGroupsInfoLockedStateAndHide() {
    this.groupLabelRefs.forEach(item => {
      if (item.getInfoShownState()) {
        this.hideGroupInfo(item, false, false, true);
        if (item.getInfoLockedState()) item.setRestoreInfoState(true);
      }
    });
  }

  public restoreAllGroupsInfoLockedState() {
    this.groupLabelRefs.forEach(item => {
      if (item.getRestoreInfoState()) {
        item.setRestoreInfoState(false);
        this.showGroupInfo(item, false, false, true);
      }
    });
  }

  // public storeAllGroupsQuickStatsStateAndHide(): void {
  //   const restoreQuickStatsState = [];
  //   this.groupLabelsContainer.labelsByLevel.forEach((labels, level) => {
  //     if (labels.every(label => label.getQuickStatsShownState())) {
  //       this.groupLabelsContainer.hideGroupsQuickStats(level, false);
  //       restoreQuickStatsState.push(level);
  //     }
  //   });
  //   this.groupLabelsContainer.setRestoreQuickStatsState(restoreQuickStatsState);
  // }

  // public restoreAllGroupsQuickStatsState() {
  //   const restoreQuickStatsState = this.groupLabelsContainer.getRestoreQuickStatsState()
  //   if (restoreQuickStatsState.length > 0) {
  //     this.restoreQuickStatsState.forEach(level => {
  //       this.groupLabelsContainer.showGroupsQuickStats(level, false);
  //     });
  //     this.restoreQuickStatsState = [];
  //   }
  // }

  protected showGroupInfo(
    groupLabel: GroupLabel,
    animate = true,
    updateOverlapLevel = true,
    forceChangeOverlappedState = false
  ) {
    groupLabel.showInfo(
      this.store.selectSnapshot(StudyState.getHoverInfoConfig),
      this.store.selectSnapshot(StudyState.getMetricsCustomFormattings),
      animate,
      forceChangeOverlappedState
    );
    if (updateOverlapLevel) {
      this.groupLabelsContainer?.updateGroupInfosOverlapLevel(groupLabel);
    } else {
      groupLabel.restoreOverlapLevel();
    }
  }

  protected hideGroupInfo(
    groupLabel: GroupLabel,
    animate = true,
    updateOverlapLevel = true,
    forceChangeOverlappedState = false
  ) {
    groupLabel.hideInfo(animate, forceChangeOverlappedState);
    if (updateOverlapLevel) this.groupLabelsContainer.updateGroupInfosOverlapLevel(groupLabel);
  }

  private showSubFramesTitle(frame: FrameContainer) {
    frame.getFrames().forEach(item => {
      item.showTitle();
      this.showSubFramesTitle(item);
    });
  }

  private hideSubFramesTitle(frame: FrameContainer) {
    frame.getFrames().forEach(item => {
      item.hideTitle();
      this.hideSubFramesTitle(item);
    });
  }

  private hideSubFramesInfoInstantly(frame: FrameContainer) {
    frame.getFrames().forEach(item => {
      if (item.getInfoShownState() || item.getInfoLockedState()) {
        this.hideFrameInfo(item, false);
        item.setInfoLockedState(false);
        item.changeInfoIconTexture();
      }
      this.hideSubFramesInfoInstantly(item);
    });
  }

  protected moveImagesUnderFrame(frame: FrameContainer) {
    const frameBounds = {
      x: frame.x - this.imageMatrix.x,
      y: frame.y - this.imageMatrix.y,
      width: frame.getWidth(),
      height: frame.getHeight(),
      centerX: frame.x + frame.getWidth() / 2 - this.imageMatrix.x,
      centerY: frame.y + frame.getHeight() / 2 - this.imageMatrix.y
    };

    const imagesUnderFrame: ImageContainer[] = [];

    this.imageRefs.forEach(imageCont => {
      const imageBounds = {
        x: imageCont.x,
        y: imageCont.y,
        width: this.imageDesiredSizes.width,
        height: this.imageDesiredSizes.height
      };

      if (
        imageBounds.x + imageBounds.width / 2 >= frameBounds.x &&
        imageBounds.x - imageBounds.width / 2 <= frameBounds.x + frameBounds.width &&
        imageBounds.y + imageBounds.height / 2 >= frameBounds.y &&
        imageBounds.y - imageBounds.height / 2 <= frameBounds.y + frameBounds.height
      ) {
        imagesUnderFrame.push(imageCont);
      }
    });

    if (imagesUnderFrame.length === 0) return;

    const tl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      },
      onComplete: () => {
        this.updateImagesInCanvasState();
        this.render();
      }
    });
    tl.addLabel('animationStart', 0);
    tl.killTweensOf(imagesUnderFrame);

    imagesUnderFrame.forEach((imageCont, i) => {
      const xOffset = imageCont.x - frameBounds.centerX;
      const yOffset = imageCont.y - frameBounds.centerY;

      const factorX = Math.abs(xOffset) / (frameBounds.width / 2) / 10;
      const factorY = Math.abs(yOffset) / (frameBounds.height / 2) / 10;

      let relativeX;
      let relativeY;

      if (factorX > factorY) {
        relativeX = frameBounds.width / 2 + (1 + factorX) * this.imageDesiredSizes.width;
        relativeY = (Math.abs(yOffset) * relativeX) / Math.abs(xOffset);
      } else {
        relativeY = frameBounds.height / 2 + (1 + factorY) * this.imageDesiredSizes.height;
        relativeX = (Math.abs(xOffset) * relativeY) / Math.abs(yOffset);
      }

      let newXoffset = relativeX - Math.abs(xOffset);
      let newYoffset = relativeY - Math.abs(yOffset);
      newXoffset *= xOffset >= 0 ? 1 : -1;
      newYoffset *= yOffset >= 0 ? 1 : -1;

      const newX = imageCont.x + newXoffset;
      const newY = imageCont.y + newYoffset;

      /**
       * Last parameter in tl.to function controls the start of animation relative to other animations or custom labels
       * By default animation starts at the end of previous animation
       * tl.addLabel adds custom label or waypoint in timeline so we can set animation times relative to it
       * we are marking begining of timeline with 'animationStart' label
       * and settinig all animations to start at that label, i.e. at the same time, in the begining of timeline
       */

      tl.to(
        imageCont,
        {
          duration: 0.25,
          x: newX,
          y: newY
        },
        'animationStart'
      );
    });
  }

  protected createFrame(model: Partial<IFrameStateModel>, checkCollision = false): FrameContainer {
    const frame = new FrameContainer(
      model.id,
      model.dimensions.width,
      model.dimensions.height,
      this.frameBorderWidth,
      this.frameMarginTop,
      this.imageSize,
      this.distanceBetweenImages,
      this.friendlyNameService,
      this.dataService
    );
    this.frames.push(frame);
    this.allFrames.push(frame);
    frame.position.set(model.position.x, model.position.y);
    frame.sortableChildren = true;
    this.matrix.addChild(frame);

    frame.createInfoIcon();
    frame.createInfoOverlay();
    frame.createTitle(model.title?.featureName, model.title?.featureValue);
    frame.showTitle();
    frame.createPublishIcon();
    this.addFrameEventListenersAll(frame);
    if (checkCollision) {
      this.checkFrameForImagesCollision(frame);
      this.checkFrameForFramesCollision(frame);
      frame.makeSquareGrid();
      this.render();
    }
    frame.updateFrameTitlePosition();
    this.moveImagesUnderFrame(frame);
    return frame;
  }

  protected restoreFrame(model: Partial<IFrameStateModel>): FrameContainer {
    const frame = new FrameContainer(
      model.id,
      model.dimensions.width,
      model.dimensions.height,
      this.frameBorderWidth,
      this.frameMarginTop,
      this.imageSize,
      this.distanceBetweenImages,
      this.friendlyNameService,
      this.dataService
    );
    frame.alpha = 0;
    frame.position.set(model.position.x, model.position.y);
    frame.sortableChildren = true;
    this.allFrames.push(frame);
    model.frames.forEach(frameModel => {
      const subFrame = this.restoreFrame(frameModel);
      frame.addFrame(subFrame);
      frame.addChild(subFrame);
    });

    frame.createInfoIcon();
    frame.createInfoOverlay();
    frame.createTitle(model.title?.featureName, model.title?.featureValue);
    frame.hideTitle();
    frame.createPublishIcon();
    this.frameTitles[model.id] = { name: model.title.featureName, value: model.title.featureValue };
    this.addFrameEventListenersAll(frame);
    frame.active = false;
    frame.hideBordersAndCorners();
    return frame;
  }

  protected restoreImagesInFrame(model: Partial<IFrameStateModel>, frame: FrameContainer) {
    model.images.forEach(image => {
      const idx = this.imageRefs.findIndex(ref => ref.getId() === image.id);
      if (idx === -1) {
        return;
      }
      const [imageContainer] = this.imageRefs.splice(idx, 1);
      this.imageMatrix.removeChild(imageContainer);
      frame.addImage(imageContainer);
    });
    model.frames.forEach(frameModel => {
      const frameContainer = frame.getFrames().find(item => item.getId() === frameModel.id);
      this.restoreImagesInFrame(frameModel, frameContainer);
    });
    frame.restoreGrid();
    frame.alpha = 1;
    frame.showTitle();
    frame.updateHitArea(frame.getWidth(), frame.getHeight());
  }

  protected addFrameEventListenersAll(frame: FrameContainer) {
    this.addFrameDragEventListeners(frame);
    this.addFrameHoverEventListeners(frame);
    this.addFrameTitleChangeListener(frame);
  }

  protected addFrameDragEventListeners(frame: FrameContainer) {
    frame.interactive = true;
    frame.interactiveChildren = true;

    frame
      .on('mousedown', this.onFrameMouseDown.bind(this, frame))
      .on('mouseup', this.onFrameMouseUp.bind(this))
      .on('mouseupoutside', this.onFrameMouseUp.bind(this));
  }

  protected addFrameHoverEventListeners(frame: FrameContainer) {
    frame.interactive = true;
    frame.interactiveChildren = true;
    frame
      .on('mouseover', event => {
        this.onFrameMouseOver(frame, event);
      })
      .on('mouseout', event => {
        this.onFrameMouseOut(frame, event);
      })
      .on('mouseout', event => {
        this.onFrameInfoMouseOut(frame, event);
      });
  }

  protected addFrameTitleChangeListener(frame: FrameContainer) {
    frame.on('TitleChange', (data: IFrameTitleChangeEvent) => {
      this.onFrameTitleChange(data);
    });
  }

  protected addFrameInfoClickListener(frame: FrameContainer) {
    frame.on('click', (event: InteractionEvent) => {
      if (frame.checkForInfoClick(event, frame.getFrameBounds())) this.onFrameInfoClick(frame);
    });
  }

  protected addGroupEventListeners(group: GroupContainer) {
    group.interactive = true;
    group.interactiveChildren = true;
    const groupLabel = this.groupLabelsContainer.labels[group.getId()];
    group.on('mousemove', this.onGroupMouseMove.bind(this, group, groupLabel));
  }

  private onGroupMouseMove(group: GroupContainer, groupLabel: GroupLabel, event: InteractionEvent) {
    if (groupLabel.isMouseOverLabelRect(event, this.groupLabelsContainer.scale)) {
      if (!groupLabel.getMouseWasOverLabelRectState()) {
        groupLabel.setMouseWasOverLabelRectState(true);
        groupLabel.showIcons();
        // if (!groupLabel.getInfoActiveState()) {
        //   groupLabel.setInfoActiveState(true);
        // }
      }
      if (
        !groupLabel.getMouseWasOverLabelState() &&
        (groupLabel.isMouseOverLabel(event) ||
          groupLabel.isMouseOverHandler(event) ||
          groupLabel.isMouseOverInfo(event) ||
          groupLabel.isMouseOverQuickStats(event))
      ) {
        this.onGroupLabelMouseOver(group, groupLabel, event);
      }
      if (!groupLabel.getInfoHoverState() && groupLabel.isMouseOverInfo(event)) {
        document.body.style.cursor = CursorStyles.Pointer;
        this.onGroupInfoMouseOver(groupLabel);
      } else if (groupLabel.getInfoHoverState() && !groupLabel.isMouseOverInfo(event)) {
        this.setGroupMovingCursor();
        this.onGroupInfoMouseOut(groupLabel);
      }
      if (!groupLabel.getQuickStatsHoverState() && groupLabel.isMouseOverQuickStats(event)) {
        document.body.style.cursor = CursorStyles.Pointer;
        groupLabel.setQuickStatsHoverState(true);
        groupLabel.onQuickStatsIconHover();
      } else if (groupLabel.getQuickStatsHoverState() && !groupLabel.isMouseOverQuickStats(event)) {
        this.setGroupMovingCursor();
        groupLabel.setQuickStatsHoverState(false);
        groupLabel.onQuickStatsIconLeave();
      }
      if (groupLabel.isMouseOverLabel(event) || groupLabel.isMouseOverHandler(event)) {
        this.setGroupMovingCursor();
      }
    } else if (
      groupLabel.getMouseWasOverLabelRectState() &&
      !groupLabel.isMouseOverLabelRect(event, this.groupLabelsContainer.scale)
    ) {
      groupLabel.setMouseWasOverLabelRectState(false);
      groupLabel.hideIcons();
      groupLabel.setQuickStatsHoverState(false);
      groupLabel.onQuickStatsIconLeave();
      // if (groupLabel.getInfoActiveState()) {
      //   groupLabel.setInfoActiveState(false);
      // }
      document.body.style.cursor = CursorStyles.Auto;
      if (this.selectedTool === MatrixToolEnum.Highlighter) document.body.style.cursor = CursorStyles.Highlighter;
      this.onGroupLabelMouseOut(groupLabel);
      if (groupLabel.getInfoHoverState()) {
        this.onGroupInfoMouseOut(groupLabel);
      }
    }
  }

  private setGroupMovingCursor() {
    document.body.style.cursor =
      this.groupOrientation === GroupOrientation.Vertical ? CursorStyles.LeftResize : CursorStyles.TopResize;
  }

  private addGroupLabelMouseListeners(group: GroupContainer, groupLabel: GroupLabel) {
    this.groupId = group.getId();
    this.matrix.interactive = true;
    this.matrix.off('mousedown', this.groupLabelMouseDownListenerRef);
    this.matrix.off('mouseup', this.groupLabelMouseUpListenerRef);
    this.groupLabelMouseDownListenerRef = this.onGroupLabelMouseDown.bind(this, group, groupLabel);
    this.groupLabelMouseUpListenerRef = this.onGroupLabelMouseUp.bind(this, group, groupLabel);
    this.matrix.on('mousedown', this.groupLabelMouseDownListenerRef);
    this.matrix.on('mouseup', this.groupLabelMouseUpListenerRef);
  }

  private removeGroupLabelMouseListeners(group: GroupContainer) {
    if (this.groupId === group.getId()) {
      this.matrix.interactive = false;
      this.matrix.off('mousedown', this.groupLabelMouseDownListenerRef);
      this.matrix.off('mouseup', this.groupLabelMouseUpListenerRef);
    }
  }

  private checkFrameForImagesCollision(frame: FrameContainer) {
    const frameBounds = frame.getFrameBounds();

    this.imageRefs.forEach(imageCont => {
      if (frame.getImages().indexOf(imageCont) === -1) {
        const imageBounds = imageCont.getImageBounds();
        if (this.detectCollision(frameBounds, imageBounds)) {
          imageCont.clearDecelerateProperties();
          frame.addImage(imageCont);
        }
      }
    });

    if (frame.getImages().length > 0) {
      frame.getImages().forEach(imageCont => {
        const index = this.imageRefs.indexOf(imageCont);
        this.imageRefs.splice(index, 1);
      });
    }
  }

  private checkFrameForFramesCollision(frame: FrameContainer) {
    const bounds = frame.getFrameBounds();
    this.frames
      .filter(f => f.getId() !== frame.getId())
      .forEach(f => {
        if (this.detectFramesFramed(bounds, f.getFrameBounds())) {
          frame.addFrame(f);
        }
      });

    if (frame.getFrames().length > 0) {
      frame.getFrames().forEach(f => {
        const index = this.frames.indexOf(f);
        if (index > -1) {
          this.frames.splice(index, 1);
        }
      });
    }
  }

  public onDeleteKeyPress(event: KeyboardEvent) {
    if (this.framingStarted) this.removeFrameInConstruction();
    if (this.activeFrameRef) this.removeActiveFrame();
  }

  private removeFrameInConstruction() {
    this.framingStarted = false;
    this.userInteraction = false;
    this.frameConstructorRect.clear();
    this.matrix.removeChild(this.frameConstructorRect);
    document.body.style.cursor = CursorStyles.Crosshair;
    this.render();
  }

  private removeActiveFrame() {
    this.frameHover = false;
    this.frameDragging = false;
    this.userInteraction = false;
    this.removeFrame(this.activeFrameRef);
    this.activeFrameRef = null;
    document.body.style.cursor = CursorStyles.Default;
    this.render();
  }

  protected removeFrame(frame: FrameContainer) {
    if (frame.parent instanceof FrameContainer) {
      const parent = frame.parent as FrameContainer;
      frame.getImages().forEach(imageCont => parent.addImage(imageCont));
      frame.getFrames().forEach(frameCont => parent.addFrame(frameCont));
      parent.removeFrame(frame);
      parent.makeSquareGrid();
      parent.updateFrameTitlePosition();
      const index = this.allFrames.indexOf(frame);
      this.allFrames.splice(index, 1);
      this.removeFrameFromDatabase(frame);
      this.updateFrameInCanvasState(this.getFirstFrameAncestor(parent));
    } else {
      frame.getImages().forEach(imageCont => {
        imageCont.interactive = true;
        const x = frame.x + imageCont.x - this.imageMatrix.x;
        const y = frame.y + imageCont.y - this.imageMatrix.y;
        this.imageMatrix.addChild(imageCont);
        this.imageRefs.push(imageCont);
        imageCont.position.set(x, y);
      });
      frame.getFrames().forEach(frameCont => {
        frameCont.interactive = true;
        const x = frame.x + frameCont.x;
        const y = frame.y + frameCont.y;
        this.matrix.addChild(frameCont);
        this.frames.push(frameCont);
        frameCont.position.set(x, y);
        frameCont.isParentFrame = true;
        frameCont.updatePublishIcon();
      });
      const index = this.frames.indexOf(frame);
      this.frames.splice(index, 1);
      const index2 = this.allFrames.indexOf(frame);
      this.allFrames.splice(index2, 1);
      this.matrix.removeChild(frame);
      this.removeFrameFromDatabase(frame);
      this.updateFramesAndImagesInCanvasState();
    }

    if (this.frameTitles[frame.getId()]) {
      delete this.frameTitles[frame.getId()];
    }

    frame.delete();
    frame = null;
  }

  protected clearFrameAndSubFrames(frame: FrameContainer) {
    frame.getFrames().forEach(item => this.clearFrameAndSubFrames(item));
    frame.parent.removeChild(frame);
    frame.delete();
    frame = null;
  }

  protected clearFrameAndSubFramesHtmlElements(frame: FrameContainer) {
    frame.getFrames().forEach(item => this.clearFrameAndSubFrames(item));
    frame.deleteHtmlElements();
  }

  public onFrameTitleChange(data: IFrameTitleChangeEvent) {
    if (!this.frameTitles || !this.allFrames || this.allFrames.length === 0) return;

    const frameIndex = this.allFrames.findIndex(frame => frame.getId() === data.id);
    if (frameIndex > -1) {
      const frame = this.allFrames[frameIndex];
      frame.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes));
      if (frame.parent instanceof FrameContainer) {
        this.getFirstFrameAncestor(frame.parent).updatePublishIcon();
      } else {
        frame.updatePublishIcon();
      }
      this.updateFrameTitleInCanvasState(frame);
    }
  }

  protected createImage(
    uid: string,
    prodId: string,
    displayName: string,
    position: IPosition,
    parent: Container,
    imageData,
    placeholder?: ImagePlaceholder
  ): ImageContainer {
    const imageContainer = new ImageContainer(
      uid,
      prodId,
      displayName,
      this.imageSize,
      this.getProductView(prodId, this.imageRefs.length),
      position,
      this.resourceManagerService,
      placeholder,
      this.dropShadowService.getImageDropShadows(),
      this.isPlaceholderMode
    );
    imageContainer.setData(imageData);
    this.imageRefs.push(imageContainer);
    parent?.addChild(imageContainer);
    return imageContainer;
  }

  protected addImageEventListenersAll(image: ImageContainer) {
    this.addImageDragAndClickListeners(image);
    this.addImageHoverListeners(image);
  }

  protected addImageDragAndClickListeners(image: ImageContainer) {
    image
      .on('mousedown', this.onImageMouseDown.bind(this, image))
      .on('mouseup', this.onImageMouseUp.bind(this, image))
      .on('mouseupoutside', this.onImageMouseUp.bind(this, image))
      .on('rightdown', this.onImageRightDown.bind(this))
      .on('rightup', this.onImageRightUp.bind(this, image))
      .on('rightupoutside', this.onImageRightUp.bind(this, image));
  }

  protected addImageClickListeners(image: ImageContainer) {
    image
      .on('mousedown', (event: InteractionEvent) => {
        event.stopPropagation();
        this.hideImagePopper();
        this.contextMenuClosed$.next();
        this.imageMouseDownPosition = { ...event.data.global };
      })
      .on('mouseup', (event: InteractionEvent) => {
        event.stopPropagation();
        const pos = { ...event.data.global };
        if (this.imageMouseDownPosition) {
          if (
            Math.abs(pos.x - this.imageMouseDownPosition.x) <= 1 ||
            Math.abs(pos.y - this.imageMouseDownPosition.y) <= 1
          ) {
            this.onImageClick(image);
          }
          this.imageMouseDownPosition = undefined;
        }
      })
      .on('rightdown', this.onImageRightDown.bind(this))
      .on('rightup', this.onImageRightUp.bind(this, image))
      .on('rightupoutside', this.onImageRightUp.bind(this, image));
  }

  protected addImageHoverListeners(image: ImageContainer) {
    image
      .on('mouseover', this.onImageMouseOver.bind(this, image))
      .on('mouseout', this.onImageMouseOut.bind(this, image));
  }

  protected hideImagePopper() {
    if (this.imagePopperMenuShown) {
      this.imagePopperMenuShown = false;
      this.imagePopperMenu.next({ show: false });
    }
  }

  protected onImageSelect(image: ImageContainer) {
    image.select();
    this.selectedImages.push(image);
    this.selectedProducts$.next(new Set(this.selectedImages.map(item => item.getId())));
    this.render();
  }

  protected onImageDeselect(image: ImageContainer) {
    image.deselect();
    const index = this.selectedImages.indexOf(image);
    this.selectedImages.splice(index, 1);
    this.selectedProducts$.next(new Set(this.selectedImages.map(item => item.getId())));
    this.render();
  }

  protected deselectAllImages() {
    this.selectedImages.forEach(image => {
      image.deselect();
    });

    this.selectedImages = [];
    this.selectedProducts$.next(new Set());
  }

  protected onCanvasClick(event: MouseEvent) {
    if (this.imageDragging) return;
    if (this.selectedImages.length > 0 && !this.multiSelectActive && event.button !== 2) {
      this.deselectAllImages();
      this.render();
    }
  }

  public setControlCommandKeyState(active: boolean) {
    this.multiHightlightActive = active;
  }

  protected startMultiSelectRectTool() {
    this.matrix.interactive = true;
    this.matrix
      .on('mousedown', this.onMultiSelectStart.bind(this))
      .on('mouseup', this.onMultiSelectEnd.bind(this))
      .on('mouseupoutside', this.onMultiSelectEnd.bind(this));
  }

  protected endMultiSelectRectTool() {
    this.matrix.interactive = false;
    this.matrix.removeAllListeners();
    this.setupMatrixHoverListeners();
  }

  private onMultiSelectStart(event) {
    event.stopPropagation();
    this.viewport.pause = true;
    this.userInteraction = true;
    this.imageMatrix.interactive = false;
    this.imageMatrix.interactiveChildren = false;
    this.frames.forEach(frame => frame.setInteractivity(false));
    this.matrix.on('mousemove', this.onMultiSelectMouseMove.bind(this));

    this.startCoordinates = { ...event.data.getLocalPosition(this.matrix) };
    this.multiSelectRect.position.set(this.startCoordinates.x, this.startCoordinates.y);
    this.multiSelectRect.clear().lineStyle(this.frameBorderWidth, 0x04d7b9).drawRect(0, 0, 0, 0);
    this.multiSelectMoved = false;
    this.matrix.addChild(this.multiSelectRect);
  }

  private onMultiSelectEnd(event) {
    if (!this.userInteraction) {
      // bugfix:
      // sometimes, ShowBar also can drag-n-drop filter elements, and drop them on the canvas (out of own container)
      // - in this case don't need to render or updateImagesInCanvasState
      return;
    }
    event.stopPropagation();
    this.viewport.pause = false;
    this.userInteraction = false;
    this.imageMatrix.interactive = true;
    this.imageMatrix.interactiveChildren = true;
    this.frames.forEach(frame => frame.setInteractivity(true));
    this.matrix.off('mousemove');

    if (this.startCoordinates) {
      const coordinates = event.data.getLocalPosition(this.matrix);
      if (coordinates.x < this.startCoordinates.x)
        [coordinates.x, this.startCoordinates.x] = [this.startCoordinates.x, coordinates.x];
      if (coordinates.y < this.startCoordinates.y)
        [coordinates.y, this.startCoordinates.y] = [this.startCoordinates.y, coordinates.y];
      const w = coordinates.x - this.startCoordinates.x;
      const h = coordinates.y - this.startCoordinates.y;
      if (w === 0 || h === 0) {
        this.multiSelectRect.clear();
        this.matrix.removeChild(this.multiSelectRect);
        this.render();
        return;
      }
      // Necessary to make sure getBounds() returns correct result
      this.multiSelectRect.position.set(this.startCoordinates.x, this.startCoordinates.y);
      this.multiSelectRect.clear().lineStyle(this.frameBorderWidth, 0x04d7b9).drawRect(0, 0, w, h);

      this.startCoordinates = null;

      const rectBounds = this.multiSelectRect.getBounds();
      this.imageRefs.forEach(imageCont => {
        const imageBounds = imageCont.getImageBounds();
        if (this.detectCollision(rectBounds, imageBounds) && !imageCont.getSelectedState()) {
          imageCont.select();
          this.selectedImages.push(imageCont);
        }
      });

      if (this.selectedImages.length > 0)
        this.selectedProducts$.next(new Set(this.selectedImages.map(item => item.getId())));

      this.multiSelectRect.clear();
      this.matrix.removeChild(this.multiSelectRect);
      this.render();
    }
  }

  private onMultiSelectMouseMove(event) {
    event.stopPropagation();
    if (!this.multiSelectMoved) {
      this.deselectAllImages();
      this.multiSelectMoved = true;
    }
    const coordinates = event.data.getLocalPosition(this.matrix);
    const w = coordinates.x - this.startCoordinates.x;
    const h = coordinates.y - this.startCoordinates.y;
    this.multiSelectRect.clear().lineStyle(this.frameBorderWidth, 0x04d7b9).drawRect(0, 0, w, h);
  }

  protected startHightlighter() {
    this.matrix.interactive = true;
    this.matrixClickDuringHighlightRef = this.onMatrixClickDuringHighlight.bind(this);
    this.matrix.on('mouseup', this.matrixClickDuringHighlightRef);
  }

  protected stopHighlighter() {
    this.matrix.interactive = false;
    this.matrix.off('mouseup', this.matrixClickDuringHighlightRef);
  }

  protected onMatrixClickDuringHighlight(event) {
    event.stopPropagation();
    if (!this.multiHightlightActive && (event.target === this.matrix || event.target === this.imageMatrix)) {
      this.clearHighlightFilters();
    }
  }

  private initMagnificationTool() {
    this.magnifyTool = new MagnifyTool(this.window, this.viewportBaseScale * this.zoomFactor);
  }

  public updateFrameShadowsOnZoom() {
    this.frames.forEach(frame => frame.setZoomFactor(this.zoomFactor));
  }

  private updateMagnifyOnZoom() {
    this.magnifyTool.updateScale(this.viewportBaseScale * this.zoomFactor);
    this.magnifyTool.updateContainer();
    this.checkMagnifyForScale();
  }

  private checkMagnifyForScale() {
    const scale = this.viewportBaseScale * this.zoomFactor;
    const currentImageSize = scale * this.imageSize;
    const maxMagnifySize = this.window.innerWidth * 0.8; // 80% of screen width size
    const toolboxState = this.store.selectSnapshot(ToolsState.getToolbox);
    if (currentImageSize >= maxMagnifySize) {
      // disable Magnify tool
      this.store.dispatch(new SetToolboxState({ ...toolboxState, magnify: 'disabled' }));
    } else {
      // enable Magnify tool
      this.store.dispatch(new SetToolboxState({ ...toolboxState, magnify: undefined }));
    }
  }

  protected startMagnification() {
    this.userInteraction = true;

    this.allFrames.forEach(frame => {
      frame.interactive = false;
      frame.interactiveChildren = true;
      if (frame.getInfoShownState() || frame.getInfoLockedState()) {
        this.hideFrameInfo(frame, false);
      }
    });
    this.magnifyTool.init();
  }

  protected stopMagnification() {
    this.userInteraction = false;

    this.magnifyTool.clearMagnifyImage();
    this.magnifyTool.destroy();

    this.allFrames?.forEach(frame => {
      frame.interactive = true;
    });
    this.allFrames?.forEach(frame => {
      if (frame.getInfoLockedState()) {
        this.showFrameInfo(frame, false);
      }
    });

    this.render();
  }

  protected onMagnifyMouseWheel(event: WheelEvent) {
    this.magnifyTool.onMouseWheel(event.deltaY > 0 ? -1 : 1, event.ctrlKey);
  }

  protected updateImageTextureForMagnify(image: ImageContainer) {
    const texture = this.getImageTextureForMagnify(image);
    this.magnifyTool.updateImageTexture(texture);
  }

  protected getImageTextureForMagnify(image: ImageContainer): Texture {
    let texture = this.resourceManagerService.checkAndGetTexture(
      `${image.getId()}_${image.getView()}-${HIGH_IMAGE_SIZE_RESOURCE}`
    );
    if (!texture) {
      const resources = this.createLoaderResources(HIGH_IMAGE_SIZE_RESOURCE, [image]);
      this.resourceManagerService.loadResources(resources, this.onProductHigherResLoad.bind(this), null, null);
    }
    if (!texture) {
      texture = this.resourceManagerService.checkAndGetTexture(
        `${image.getId()}_${image.getView()}-${MIDDLE_IMAGE_SIZE_RESOURCE}`
      );
    }
    if (!texture) {
      texture = this.resourceManagerService.checkAndGetTexture(
        `${image.getId()}_${image.getView()}-${LOW_IMAGE_SIZE_RESOURCE}`
      );
    }
    if (!texture) {
      texture = this.resourceManagerService.checkAndGetTexture(
        this.resourceManagerService.getPlaceholderImageName(this.lookupTable.getImagePlaceholder())
      );
    }
    return texture;
  }

  public setupMatrixHoverListeners() {
    this.matrix.on('mouseover', () => {
      if (this.selectedTool === MatrixToolEnum.Frame) {
        document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
      }
    });

    this.matrix.on('mouseout', () => {
      if (this.selectedTool === MatrixToolEnum.Frame) {
        document.body.style.cursor = this.isFrameFeatureNameEmpty() ? CursorStyles.NotAllowed : CursorStyles.Crosshair;
      } else {
        document.body.style.cursor = CursorStyles.Auto;
        if (this.selectedTool === MatrixToolEnum.Highlighter) document.body.style.cursor = CursorStyles.Highlighter;
      }
    });
  }

  protected getFirstFrameAncestor(frame: FrameContainer): FrameContainer {
    let ancestor = frame;
    if (frame.parent instanceof FrameContainer) {
      ancestor = this.getFirstFrameAncestor(frame.parent);
    }
    return ancestor;
  }

  protected getFirstGroupAncestor(group: GroupContainer): GroupContainer {
    let ancestor = group;
    if (group.parent instanceof GroupContainer) {
      ancestor = this.getFirstGroupAncestor(group.parent);
    }
    return ancestor;
  }

  public setPanningState(active: boolean) {
    this.panningActive = active;
  }

  private constainsPoint(bounds, point: IPosition) {
    return (
      point.x >= bounds.x &&
      point.x <= bounds.x + bounds.width &&
      point.y >= bounds.y &&
      point.y <= bounds.y + bounds.height
    );
  }

  protected checkForPlaceholderMode(products: Array<string>): void {
    const uniqueProdIDs = products.filter((item, index, arr) => arr.indexOf(item) === index);
    this.isPlaceholderMode = uniqueProdIDs.length > this.imageTresholdForPlaceholderMode;
  }

  protected getCanvasState(): IMatrixStateModel {
    return {
      matrix: {
        dimensions: {
          width: this.matrixResizerSpriteRef.width,
          height: this.matrixResizerSpriteRef.height
        }
      },
      imageMatrix: {
        position: {
          x: this.imageMatrix.x,
          y: this.imageMatrix.y
        },
        dimensions: {
          width: this.imageMatrix.width,
          height: this.imageMatrix.height
        },
        images: this.imageRefs.map(image => this.createImageStateModel(image))
      }
    };
  }

  /**
   * State Updates
   */
  protected updateCanvasState(withFrames: boolean) {
    this.store.dispatch(
      new UpdateMatrixState(
        withFrames
          ? {
              ...this.getCanvasState(),
              frames: this.frames.map(frame => this.createFrameStateModel(frame))
            }
          : this.getCanvasState()
      )
    );
  }

  protected setCanvasState(withFrames: boolean) {
    this.store.dispatch(
      new SetMatrixState(
        withFrames
          ? {
              ...this.getCanvasState(),
              frames: this.frames.map(frame => this.createFrameStateModel(frame))
            }
          : this.getCanvasState()
      )
    );
  }
  protected updateImagesInCanvasState() {
    this.store.dispatch(new UpdateImages(this.imageRefs.map(image => this.createImageStateModel(image))));
  }

  protected updateImageInCanvasState(image: ImageContainer) {
    this.store.dispatch(
      new UpdateImage({
        uid: image.getUid(),
        update: this.createImageStateModel(image),
        parentFrame: image.parent instanceof FrameContainer ? image.parent : undefined
      })
    );
  }

  protected updateFramesAndImagesInCanvasState(): void {
    this.store.dispatch(
      new UpdateFramesAndImages({
        frames: this.frames.map(item => this.createFrameStateModel(item)),
        images: this.imageRefs.map(image => this.createImageStateModel(image))
      })
    );
  }

  protected updateFramesInCanvasState(frames: Array<FrameContainer>): void {
    this.store.dispatch(new UpdateFrames(frames.map(item => this.createFrameStateModel(item))));
  }

  protected updateFrameInCanvasState(frame: FrameContainer) {
    this.store.dispatch(
      new UpdateFrame({
        id: frame.getId(),
        update: this.createFrameStateModel(frame)
      })
    );
  }

  protected updateAllFramesInCanvasState() {
    this.store.dispatch(
      new UpdateMatrixState({
        frames: this.frames.map(frame => this.createFrameStateModel(frame))
      })
    );
  }

  private updateFrameTitleInCanvasState(frame: FrameContainer) {
    this.store.dispatch(
      new UpdateFrame({
        id: frame.getId(),
        update: {
          title: {
            featureName: frame.getFeatureName(),
            featureValue: frame.getFeatureValue()
          }
        }
      })
    );
  }

  protected addFrameInCanvasState(frame: FrameContainer, created = false) {
    const frameState = this.createFrameStateModel(frame);
    this.store.dispatch(
      new AddFrame({
        frame: frameState,
        created
      })
    );
  }

  protected removeFrameFromCanvasState(frameId: string) {
    this.store.dispatch(new RemoveFrame(frameId));
  }

  private removeFrameFromDatabase(frame: FrameContainer) {
    this.store.dispatch(new RemoveFrameFromDatabase(frame.getId()));
  }

  private createFrameStateModel(frame: FrameContainer): IFrameStateModel {
    const frameState: IFrameStateModel = {
      id: frame.getId(),
      position: this.getFramePosition(frame),
      dimensions: { width: frame.getWidth() / this.resolution, height: frame.getHeight() / this.resolution },
      title: {
        featureName: frame.getFeatureName(),
        featureValue: frame.getFeatureValue() || null
      },
      images: frame.getImages().map(image => this.createImageStateModel(image)),
      frames: frame.getFrames().map(item => this.createFrameStateModel(item))
    };

    return frameState;
  }

  protected createImageStateModel(image: ImageContainer): IImageStateModel {
    return {
      uid: image.getUid(),
      id: image.getId(),
      position:
        image.parent instanceof FrameContainer
          ? this.getImagePosRelativeToFrame(image)
          : this.getImagePosRelativeToImageMatrix(image),
      view: image.getView(),
      metric: image.getData().sortByValue || null
    };
  }

  public updateViewportInCanvasState() {
    if (!this.viewport || !this.viewport?.center?.x || !this.viewport?.center?.y) return;
    const viewportData: IViewportData = {
      center: this.viewport.center,
      scale: this.viewport.scale,
      zoom: {
        zoomFactor: this.zoomFactor,
        baseScale: this.viewportBaseScale,
        resolution: this.resolution
      },
      dimensions: {
        width: this.viewport.worldWidth,
        height: this.viewport.worldHeight
      },
      windowDimensions: this.windowDimensions
    };
    this.store
      .select(StudyState.getActiveWorkspaceId)
      .pipe(
        debounce(() =>
          this.store.select(StudyState.isUpdatingWorkspace).pipe(
            filter(val => val === false),
            take(1)
          )
        ),
        take(1)
      )
      .subscribe((workspaceId: string) => {
        this.store.dispatch(new SetViewport({ workspaceId, viewport: viewportData, resolution: this.resolution }));
      });
  }

  private registerGSAPPixiPlugin() {
    PixiPlugin.registerPIXI(PIXI);
    gsap.registerPlugin(PixiPlugin);
  }

  public render() {
    this.renderer.render(this.stage);
  }

  public getResolution(): number {
    return this.resolution;
  }

  private updateGroupSubgroupsInCanvasState(group: GroupContainer): void {
    this.store.dispatch(
      new UpdateGroup({
        id: group.getId(),
        update: {
          groups: group.getGroups().map(subgroup => this.createGroupStateModel(subgroup))
        }
      })
    );
  }

  private setGroupsInCanvasState(groups: Array<GroupContainer>): void {
    const groupsState = groups.map(group => this.createGroupStateModel(group));
    this.store.dispatch(new SetGroups(groupsState));
  }

  protected createGroupStateModel(group: GroupContainer): IGroupStateModel {
    return {
      id: group.getId(),
      position: {
        x: group.x / this.resolution,
        y: group.y / this.resolution
      },
      dimensions: { width: group.width / this.resolution, height: group.width / this.resolution },
      label: group.getLabel(),
      level: group.getLevel(),
      order: group.zIndex,
      images: group.getImages().map(image => image.getUid()),
      groups: group.getGroups().map(item => this.createGroupStateModel(item))
    };
  }

  protected getFramePresentationState(frame: FrameContainer): IFramePresentationState {
    const { x, y } = frame.getCoordinatesOnMatrix();
    return {
      position: {
        x: x / this.resolution,
        y: y / this.resolution
      },
      dimensions: { width: frame.getWidth() / this.resolution, height: frame.getHeight() / this.resolution },
      title: {
        featureName: frame.getFeatureName(),
        featureValue: frame.getFeatureValue() || null
      },
      numImages: frame.getAllImages().length,
      fontSize: 16,
      images: frame.getImages().map(image => this.getImagePresentationState(image)),
      frames: frame.getFrames().map(item => this.getFramePresentationState(item))
    };
  }

  protected getGroupPresentationState(
    group: GroupContainer,
    maxNumImagesInGroup: number,
    infoPanel: IImageInfoPanelPresentationState
  ): IGroupPresentationState {
    const { x, y } = group.getCoordinatesOnImageMatrix();
    const label = this.groupLabelsContainer.labels[group.getId()];
    const distanceBetweenImages = 32;
    const imageOffsetForInfoPanelX =
      infoPanel && infoPanel.position !== InfoPanelPosition.Bottom
        ? ((imageBaseSize + distanceBetweenImages) / 2) * 2.2
        : 0;
    const imageOffsetForInfoPanelY =
      infoPanel && infoPanel.position === InfoPanelPosition.Bottom
        ? ((imageBaseSize + distanceBetweenImages) / 2) * 2.2
        : 0;
    const xOffset = this.groupLabelsContainer.getDockingSide() === DockingSide.Right ? imageOffsetForInfoPanelX : 0;
    return {
      position: {
        x: (x + this.imageMatrix.x) / this.resolution - xOffset,
        y: (y + this.imageMatrix.y) / this.resolution
      },
      dimensions: {
        width: [DockingSide.Top, DockingSide.Bottom].includes(this.groupLabelsContainer.getDockingSide())
          ? group.width / this.resolution - 40
          : maxNumImagesInGroup * (imageBaseSize + imageOffsetForInfoPanelX) +
            (maxNumImagesInGroup - 1) * distanceBetweenImages,
        height: [DockingSide.Left, DockingSide.Right].includes(this.groupLabelsContainer.getDockingSide())
          ? group.height / this.resolution - 40
          : maxNumImagesInGroup * (imageBaseSize + imageOffsetForInfoPanelY) +
            (maxNumImagesInGroup - 1) * distanceBetweenImages
      },
      label: group.getLabel(),
      level: group.getLevel(),
      quickStats: label.getQuickStatsShownState()
        ? {
            info: label.getQuickStatsInfo(),
            fontSize: 12
          }
        : null,
      fontSize: 16
    };
  }

  protected getImagePresentationState(image: ImageContainer): IImagePresentationState {
    const { x, y } = this.getImagePosInWorld(image);
    const position = {
      x: x / this.resolution,
      y: y / this.resolution
    };
    if (
      this.matrixType !== ValidMatrix.Rank ||
      [DockingSide.Top, DockingSide.Bottom].includes(this.groupLabelsContainer.getDockingSide())
    )
      position.x -= imageBaseSize / 2;
    if (
      this.matrixType !== ValidMatrix.Rank ||
      [DockingSide.Left, DockingSide.Right].includes(this.groupLabelsContainer.getDockingSide())
    )
      position.y -= imageBaseSize / 2;
    return {
      id: image.getId(),
      position,
      dimensions: { width: imageBaseSize, height: imageBaseSize },
      view: image.getView(),
      data: image.getData()
    };
  }
}
