import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

/**
 * Show tooltip only if element overflow parent container
 * Example of usage:
 * <div class="some-parent-container" style="width: 200px">
 *   <span [overflow-tooltip]="true" [overflow-tooltip-placement]="'top'">
 *     Very long example text (more than 200px)
 *   </span>
 * </div>
 */

@Directive({
  selector: '[overflow-tooltip]'
})
export class OverflowTooltipDirective implements OnDestroy {
  @Input('overflow-tooltip') enabled: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('overflow-tooltip-placement') placement: string = 'bottom';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('overflow-tooltip-offset') offset: number = 10;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('overflow-tooltip-delay') delay: number = 400;

  @HostListener('mouseenter', ['$event'])
  handleMouseEnter($event: MouseEvent) {
    this.showTooltip();
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeave($event: MouseEvent) {
    this.hideTooltip();
  }

  private tooltip: HTMLElement;
  private updateTooltipContentHandler = this.updateTooltipContent.bind(this);

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
    }
  }

  isOverflow() {
    const parentWidth = this.elementRef.nativeElement.parentNode.offsetWidth;
    const width = this.elementRef.nativeElement.offsetWidth + 15; // Chrome added 15px to show three dots
    return width > parentWidth;
  }

  showTooltip() {
    if (this.enabled && !this.tooltip && this.isOverflow()) {
      this.tooltip = this.renderer.createElement('span');
      this.updateTooltipContent();
      this.renderer.addClass(this.tooltip, 'ng-tooltip');
      if (this.placement) {
        this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
      }
      this.renderer.appendChild(document.body, this.tooltip);
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
      this.elementRef.nativeElement.addEventListener('change', this.updateTooltipContentHandler);
    }
  }

  hideTooltip() {
    if (this.tooltip) {
      this.elementRef.nativeElement.removeEventListener('change', this.updateTooltipContentHandler);
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      setTimeout(() => {
        if (this.tooltip) {
          this.renderer.removeChild(document.body, this.tooltip);
          this.tooltip = null;
        }
      }, this.delay);
    }
  }

  updateTooltipContent() {
    if (this.tooltip) {
      const text = this.elementRef.nativeElement.innerHTML || this.elementRef.nativeElement.value;
      this.renderer.setProperty(
        this.tooltip,
        'innerHTML',
        this.elementRef.nativeElement.innerHTML || this.elementRef.nativeElement.value
      );
      // hide tooltip if empty value
      if (!text) {
        this.renderer.addClass(this.tooltip, 'hidden');
      } else {
        this.renderer.removeClass(this.tooltip, 'hidden');
      }
    }
  }

  setPosition() {
    const element = this.elementRef.nativeElement.parentNode;
    const hostPos = element.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top;
    let left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
