<div class="auth-container">
  <div class="auth">
    <div class="logo">
      <svg-icon svgClass="icon" name="text-logo-collagia-white"></svg-icon>
    </div>
    <!-- <div class="lens-drop">
          <img src="/assets/images/lens-drop.svg" alt="">
      </div>   -->
    <!-- <div class="lens-drop">
          <img src="/assets/images/collagia-logo-gradient.svg" alt="">
      </div> -->
    <router-outlet></router-outlet>
  </div>
</div>
