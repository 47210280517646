import { Inject, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupTableService } from '@app/components/lookup-table';
import { FrameContainer } from '@app/custom-pixi-containers/frame-container';
import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { IGroupByFrameModel } from '@app/models/group-model';
import { IHoverInfoFieldModel, IMetricsCustomFormattings } from '@app/models/study-setting.model';
import { IFrameStateModel, IImageStateModel, IPosition } from '@app/models/workspace-list.model';
import { WINDOW } from '@app/shared/utils/window';
import { Store } from '@ngxs/store';
import { Container, InteractionEvent, Renderer } from 'pixi.js';
import { DataService } from '../data/data.service';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import { LOW_IMAGE_SIZE_RESOURCE } from './base-matrix';
import { AppLoaderService } from '@app/shared/components/loader/app-loader.service';
import { MatrixToolEnum } from '@app/state/tools/tools.model';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '../../state/tools/tools.model';
import { Viewport } from 'pixi-viewport';
import { IWorkspaceTools, IZoomSettings } from '../../models/workspace-list.model';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import { IPresentationState } from '@app/models/presentation.models';
import { FriendlyNameService } from '../friendly-name/friendly-name.service';
import { UpdateFrame } from '@app/state/study/study.actions';
import { FiltersService } from '../filters/filters.service';
import { BaseMatrix } from './base-matrix';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { CursorStates } from '@app/shared/enums/cursor-styles.enum';

export class GroupByMatrix extends BaseMatrix {
  private tileSize = (this.imageSize + this.distanceBetweenImages) / 2;
  private pad = (this.imageSize + this.distanceBetweenImages) / 2;
  private marginTopOnZoom = 0;
  private images: ImageContainer[] = [];

  // true if aggregating GroupBy groups, don't call updateImageData() because it also uses getImageAggregatedHoverInfo()
  // and all active workers will be terminated
  // so, when it's true - all updateImageData() will be ignored
  public isCreatingGroups: boolean = false;

  constructor(
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override stage: Container,
    protected override renderer: Renderer,
    protected override viewport: Viewport,
    protected override imageInfoOverlayRef: ImageInfoOverlay,
    protected override store: Store,
    protected override snackBar: MatSnackBar,
    protected override resourceManagerService: ResourceManagerService,
    protected override dataService: DataService,
    protected override lookupTable: LookupTableService,
    protected override appLoaderService: AppLoaderService,
    protected override dropShadowService: DropShadowService,
    protected override friendlyNameService: FriendlyNameService,
    protected override filterService: FiltersService,
    protected override viewContainerRef: ViewContainerRef
  ) {
    super(
      window,
      stage,
      renderer,
      viewport,
      imageInfoOverlayRef,
      store,
      snackBar,
      resourceManagerService,
      dataService,
      lookupTable,
      appLoaderService,
      dropShadowService,
      friendlyNameService,
      filterService,
      viewContainerRef
    );
  }

  public setDisplayData(data: IGroupByFrameModel[], restore: boolean): void {
    this.isUpdating.next(true);
    this.clearMatrix();
    this.displayData = data || [];
    this.updateBaseRefs();
    if (restore) {
      this.restoreMatrix();
    } else {
      this.createMatrix();
    }
  }

  protected override updateBaseRefs() {
    super.updateBaseRefs();
    this.frameBorderBaseWidth = 20;
    this.frameBorderWidth = undefined;
    this.viewportBaseScale = 1;
    this.zoomFactor = 1;
    this.tileSize = (this.imageSize + this.distanceBetweenImages) / 2;
    this.pad = (this.imageSize + this.distanceBetweenImages) / 2;
    this.marginTopOnZoom = 0;
    this.frameMarginTop = undefined;
  }

  protected createMatrix(restore: boolean = false): void {
    if (this.displayData?.length === 0) {
      this.updateMatrixDimensions();
      this.clearCompass.next(false);
      this.setCanvasState(true);
      this.matrixState = undefined;
      return;
    }
    const images = [];
    this.displayData.forEach(group => {
      this.addProductsToShow(group, images);
    });
    this.checkForPlaceholderMode(images);
    this.productsToShow = [...images];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    this.loadProductImages(restore);
  }

  protected restoreMatrix(): void {
    const images = this.getAllImages(this.matrixState.frames);
    this.imageViewOverridesWithIndex = images.map(image => image.view);
    this.createMatrix(true);
  }

  public override clearMatrix(removeContainers = true): void {
    this.displayData = undefined;
    if (removeContainers) {
      this.images = [];
      this.frames.forEach(frame => this.clearFrameAndSubFrames(frame));
      this.frames = [];
      this.allFrames = [];
    } else {
      this.frames.forEach(frame => this.clearFrameAndSubFramesHtmlElements(frame));
    }
    this.frameTitles = {};
    this.frameCollidedOnImageDrag = null;
    super.clearMatrix(removeContainers);
    this.render();
  }

  private getAllImages(frames: IFrameStateModel[]): IImageStateModel[] {
    const images = [];
    frames.forEach(fr => {
      images.push(...this.getAllFrameImages(fr));
    });
    return images;
  }

  private getAllFrameImages(frame: IFrameStateModel): IImageStateModel[] {
    const images = [...frame.images];
    frame.frames.forEach(item => {
      images.push(...this.getAllFrameImages(item));
    });
    return images;
  }

  private addProductsToShow(group, images) {
    if (group.images) {
      images.push(...group.images);
    } else if (group.groups) {
      group.groups.forEach(groupItem => {
        this.addProductsToShow(groupItem, images);
      });
    }
  }

  private loadProductImages(restore: boolean) {
    if (this.loader) this.resourceManagerService.stopLoader(this.loader);
    if (this.isPlaceholderMode) {
      this.onProductLoadComplete(restore);
      return;
    }
    const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);

    if (resources.length > 0) this.sendLowResImageLoadingStart();

    this.loader = this.resourceManagerService.loadResources(
      resources,
      null,
      null,
      this.onProductLoadComplete.bind(this, restore)
    );
  }

  private onProductLoadComplete(restore: boolean) {
    if (this.matrixType !== ValidMatrix.GroupBy) {
      // bugfix: if a few previous Study used other Matrix type, all of them already subscribed to the Product Load Complete
      // so, do nothing if it's not a current matrix
      return;
    }
    this.isUpdating.next(false);
    this.clearCompass.next(false);
    this.sendLowResImageLoadingCompleted();
    this.sendDataLoadingCompleted();

    this.imageMatrix.removeChildren();
    this.frameMarginTop = 0;
    this.frames = (this.displayData as IGroupByFrameModel[]).map(item => {
      const frameModel = this.matrixState?.frames.find(
        frame => frame.title.featureName === item.title.name && frame.title.featureValue === item.title.value
      );
      return this.createGroupByFrame(item, frameModel);
    });
    // this.frames.sort((a, b) => {
    //   return b.width * b.height - a.width * a.height;
    // });
    this.frames.sort((a, b) => {
      return b.width - a.width;
    });
    if (restore) this.frames.forEach(frame => frame.grid());
    this.allFrames.forEach(frame => {
      frame.isParentFrame = false;
      frame.draggingEnabled = false;
      frame.interactive = true;
      frame.interactiveChildren = true;

      frame
        .on('mousedown', this.onGroupByFrameMouseDown.bind(this, frame))
        .on('mouseup', this.onGroupByFrameMouseUp.bind(this))
        .on('mouseupoutside', this.onGroupByFrameMouseUp.bind(this));
    });

    this.frames.forEach(frame => {
      frame.isParentFrame = true;
      frame.draggingEnabled = true;
    });

    const w = this.window.innerWidth - 2 * this.imageMatrixPaddings.leftRight;
    const h = this.window.innerHeight - this.imageMatrixPaddings.top - this.imageMatrixPaddings.bottom;

    if (this.matrixState) {
      this.matrixState.frames.forEach(frameModel => {
        const frame = this.frames.find(
          f =>
            f.getFeatureName() === frameModel.title.featureName && f.getFeatureValue() === frameModel.title.featureValue
        );
        if (frame) {
          frame.position.set(
            frameModel.position.x - this.matrixState.imageMatrix.position.x,
            frameModel.position.y - this.matrixState.imageMatrix.position.y
          );
          this.imageMatrix.addChild(frame);
        }
      });
      this.frames.forEach(item => this.updateInitialValues(item, this.frames));
      this.restoreMatrixDimensions();
    } else {
      const positionMapArray = [];
      this.frames.forEach(item => {
        this.findPosition(item, this.imageMatrix, positionMapArray, w / h);
        this.imageMatrix.addChild(item);
      });
      positionMapArray.length = 0;
      this.frames.forEach(item => this.updateInitialValues(item, this.frames));
      this.updateMatrixDimensions();
    }

    this.animation = false;

    this.imageRefsForHighlight = [...this.imageRefs];
    this.images = [...this.imageRefs];
    this.imageRefs = [];

    this.matrixState = undefined;

    if (!restore) {
      this.frames.forEach(frame => {
        frame.y += frame.numFramesOnTopInGroupBy * this.frameMarginTop;
      });

      this.setCanvasState(true);
    }

    this.onZoomChange(this.zoomFactor, true);
    this.onViewportMove();

    this.render();
    this.allFrames.forEach(item => item.showTitle());
    this.updateAllFrameHtmlElements();

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    }
  }

  private createGroupByFrame(group: IGroupByFrameModel, frameModel?: IFrameStateModel): FrameContainer {
    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    this.frameMarginTop = 0;
    const subFrames =
      group.groups?.map(item => {
        const subFrameModel = frameModel?.frames.find(
          subFrame => subFrame.title.featureName === item.title.name && subFrame.title.featureValue === item.title.value
        );
        return this.createGroupByFrame(item, subFrameModel);
      }) || [];
    const images = this.createGroupByImages(group, frameModel);

    if (images && frameModel?.images?.length > 0) {
      const imageIds = frameModel.images.map(item => item.id);
      images.sort((a, b) => {
        return imageIds.indexOf(a.getId()) - imageIds.indexOf(b.getId());
      });
    }

    const frame = new FrameContainer(
      frameModel?.id,
      0,
      0,
      this.frameBorderWidth,
      this.frameMarginTop,
      this.imageSize,
      this.distanceBetweenImages,
      this.friendlyNameService,
      this.dataService
    );

    if (group.filteredRows) frame.filteredRows = group.filteredRows;

    frame.createInfoIcon();
    frame.createInfoOverlay();
    frame.createTitle(group.title.name, group.title.value);
    frame.hideBordersAndCorners();
    this.addFrameHoverEventListeners(frame);

    if (images) frame.addImages(images);
    if (subFrames) frame.addFrames(subFrames);
    if (frameModel && frameModel.dimensions) {
      frame.resize(frameModel.dimensions.width, frameModel.dimensions.height, true);
      frame.calculateNumImagesOnRowAndCol();
      frame.makeGrid();
    } else {
      frame.makeSquareGrid(true);
    }
    this.allFrames.push(frame);
    return frame;
  }

  private createGroupByImages(group: IGroupByFrameModel, frameModel?: IFrameStateModel): ImageContainer[] {
    const { images } = group;
    if (!images) return [];

    const imageContainers = [];
    images.forEach((imageData, i) => {
      const uid = frameModel?.images[i]?.uid;
      const image = this.createImage(
        uid,
        imageData.prodId,
        imageData.data.displayName,
        { x: 0, y: 0 },
        null,
        imageData.data,
        this.lookupTable.getImagePlaceholder()
      );
      image.alpha = 1;
      this.setupImageListeners(image);
      imageContainers.push(image);
    });

    return imageContainers;
  }

  private findPosition(
    frame: FrameContainer,
    parent: Container | FrameContainer,
    positionMapArray,
    aspectRatio = 2
  ): void {
    let x = this.tileSize;
    let y = this.tileSize;
    const parentIsEmpty =
      parent instanceof FrameContainer
        ? !parent.children.some(cont => cont instanceof FrameContainer)
        : parent.children.length === 0;

    if (parentIsEmpty) {
      frame.position.set(x, y);
      this.updatePositionMapArray(frame, positionMapArray);
      return;
    }

    const w = Math.ceil(frame.width / this.tileSize);
    const h = Math.ceil(frame.height / this.tileSize);

    const numTilesRow = Math.ceil((parent.width + this.distanceBetweenImages) / this.tileSize) + 1;
    const numTilesCol = Math.ceil((parent.height + this.distanceBetweenImages) / this.tileSize) + 1;

    for (let i = 1; i < numTilesCol - 1; i++) {
      for (let j = 1; j < numTilesRow - 1; j++) {
        if (this.checkPositionMapArray(i, j, h, w, positionMapArray)) {
          frame.position.set(j * this.tileSize, i * this.tileSize);
          this.updatePositionMapArray(frame, positionMapArray);
          return;
        }
      }
    }

    if (parent.width / parent.height <= aspectRatio) {
      x = parent instanceof FrameContainer ? numTilesRow * this.tileSize : (numTilesRow + 1) * this.tileSize;
      y = this.tileSize;
    } else {
      x = this.tileSize;
      y = parent instanceof FrameContainer ? numTilesCol * this.tileSize : (numTilesCol + 1) * this.tileSize;
    }

    frame.position.set(x, y);
    this.updatePositionMapArray(frame, positionMapArray);
  }

  private checkPositionMapArray(i, j, h, w, positionMapArray) {
    if (!positionMapArray[i + h]) positionMapArray[i + h] = [];
    return (
      positionMapArray[i] &&
      !positionMapArray[i][j] &&
      !positionMapArray[i + h][j] &&
      !positionMapArray[i][j + w] &&
      !positionMapArray[i + h][j + w] &&
      !positionMapArray[Math.floor((i + i + h) / 2)][Math.floor((j + j + w) / 2)]
    );
  }

  private updatePositionMapArray(frame: FrameContainer, positionMapArray) {
    const x = Math.floor(frame.x / this.tileSize);
    const y = Math.floor(frame.y / this.tileSize);
    const w = Math.ceil(frame.width / this.tileSize);
    const h = Math.ceil(frame.height / this.tileSize);
    for (let i = y; i <= y + h; i++) {
      if (!positionMapArray[i]) positionMapArray[i] = [];
      for (let j = x; j <= x + w; j++) {
        positionMapArray[i][j] = true;
      }
    }
  }

  private updatePosAndSizesOnZoom() {
    this.imageMatrix.removeChildren();
    this.frames.forEach(frame => {
      this.updateFramePosAndSizeOnZoom(frame);
      this.imageMatrix.addChild(frame);
    });
    this.allFrames.forEach(frame => frame.updateTitleMaxWidth());
  }

  private updateFrameInfoOverlaysOnZoom() {
    this.allFrames.filter(frame => frame.getInfoShownState()).forEach(frame => frame.getInfoOverlay().resize());
  }

  private updateFramePosAndSizeOnZoom(frame: FrameContainer) {
    if (frame.active) frame.hideBordersAndCorners();
    frame.redraw(1, 1);
    frame.getFrames().forEach(subFrame => {
      frame.removeChild(subFrame);
      this.updateFramePosAndSizeOnZoom(subFrame);
      frame.addChild(subFrame);
    });
    frame.redraw(frame.width + this.pad, frame.height + this.pad);
    if (frame.active) frame.showBordersAndCorners();
    frame.position.y =
      frame.initialYPosForGroupBy * this.resolution + (frame.numFramesOnTopInGroupBy + 1) * this.marginTopOnZoom;
  }

  private updateInitialValues(frame: FrameContainer, framesOnSameGroup: FrameContainer[]) {
    frame.saveInitialSizeAndPosForGroupBy();
    const framesOnTop = [];
    this.testForFramesOnTop(frame, framesOnSameGroup, framesOnTop);
    frame.numFramesOnTopInGroupBy = framesOnTop.length;
    frame.getFrames().forEach(subFrame => this.updateInitialValues(subFrame, frame.getFrames()));
  }

  private testForFramesOnTop(frame: FrameContainer, framesToTest: FrameContainer[], framesOnTop: FrameContainer[]) {
    const frameBounds = frame.getBounds();
    const validFrames = framesToTest.filter(f => {
      const fBounds = f.getBounds();
      return (
        f.getId() !== frame.getId() &&
        fBounds.y < frameBounds.y &&
        ((fBounds.x >= frameBounds.x && fBounds.x <= frameBounds.x + frameBounds.width) ||
          (fBounds.x + fBounds.width >= frameBounds.x &&
            fBounds.x + fBounds.width <= frameBounds.x + frameBounds.width) ||
          (fBounds.x <= frameBounds.x && fBounds.x + fBounds.width >= frameBounds.x + frameBounds.width))
      );
    });

    for (let i = 0; i < validFrames.length; i++) {
      const validFrameBounds = validFrames[i].getBounds();
      if (
        validFrames.some(f => {
          const fBounds = f.getBounds();
          return (
            validFrameBounds.x > fBounds.x &&
            validFrameBounds.y >= fBounds.y &&
            validFrameBounds.y + validFrameBounds.height <= fBounds.y + fBounds.height
          );
        })
      ) {
        validFrames.splice(i, 1);
        i--;
      }
    }

    framesOnTop.push(...validFrames);

    // validFrames.forEach(f => {
    //   if (f.getFrames().length > 0) this.testForFramesOnTop(frame, f.getFrames(), framesOnTop);
    // });
  }

  private setupImageListeners(image: ImageContainer) {
    this.addImageHoverListeners(image);
    this.addImageDragAndClickListeners(image);
  }

  restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools) {
    const scaleDifference = Math.min(
      this.viewport.screenWidth / oldState.windowDimensions.width,
      this.viewport.screenHeight / oldState.windowDimensions.height
    );

    const scale = oldState.viewportScale * scaleDifference;
    /**
     * In GroupBy view state is not completele restored
     * Viewport world size is recalculated on state restore
     * and as dimensions are calculated to be more or less proportional with window aspect ratio
     * resulting new viewport has different dimentions
     * So we need to recalculate center with proportion
     *
     * Also new viewport is not updated with old resolution,
     * so we multiply new with and height on resolution to get correct proportions
     */
    const center = {
      x:
        (oldState.viewportCenter.x * viewPort.worldWidth * oldState.viewportZoom.resolution) /
        oldState.viewportDimensions.width,
      y:
        (oldState.viewportCenter.y * viewPort.worldHeight * oldState.viewportZoom.resolution) /
        oldState.viewportDimensions.height
    };

    zoom.baseScale = scale * 3;
    viewPort.scale.set(Math.abs(scale));
    viewPort.moveCenter(center.x, center.y);

    this.onZoomChange(this.zoomFactor, true);
    this.onViewportMove();
  }

  private updateMatrixDimensions() {
    const w = this.imageMatrix.width;
    const h = this.imageMatrix.height + this.tileSize;

    let scale = 0;
    if (this.imageMatrix.width === 0 || this.imageMatrix.height === 0) {
      scale = 1;
    } else {
      let scaleW = w / (this.window.innerWidth - 2 * this.imageMatrixPaddings.leftRight);
      scaleW = Math.round((scaleW + Number.EPSILON) * 100) / 100;
      let scaleH = h / (this.window.innerHeight - this.imageMatrixPaddings.top - this.imageMatrixPaddings.bottom);
      scaleH = Math.round((scaleH + Number.EPSILON) * 100) / 100;
      scale = scaleW > scaleH ? scaleW : scaleH;
    }

    const width = this.window.innerWidth * scale * 3;
    const height = this.window.innerHeight * scale * 3;
    const left = (width - w) / 2;
    const top = (height - h) / 2 - this.tileSize;

    this.matrixResizerSpriteRef.width = width;
    this.matrixResizerSpriteRef.height = height;

    this.imageMatrix.position.set(left, top);

    this.renderNewMatrix.next({ restore: false });
  }

  private restoreMatrixDimensions() {
    this.matrixResizerSpriteRef.width = this.matrixState.matrix.dimensions.width;
    this.matrixResizerSpriteRef.height = this.matrixState.matrix.dimensions.height;
    this.imageMatrix.position.set(this.matrixState.imageMatrix.position.x, this.matrixState.imageMatrix.position.y);

    this.renderNewMatrix.next({ restore: true });
  }

  private updateImageMatrixPosOnZoom() {
    this.imageMatrix.x = (this.matrixResizerSpriteRef.width - this.imageMatrix.width) / 2;
    this.imageMatrix.y =
      (this.matrixResizerSpriteRef.height - this.imageMatrix.height - this.tileSize) / 2 - this.tileSize;
  }

  public override onResolutionChange(zoomFactor, viewportBaseScale) {
    this.tileSize = (this.imageSize + this.distanceBetweenImages) / 2;
    this.pad = (this.imageSize + this.distanceBetweenImages) / 2;
    this.matrix.position.set(0, 0);
    this.zoomFactor = zoomFactor;
    this.viewportBaseScale = viewportBaseScale;
    this.frameBorderBaseWidth = 1.5 / viewportBaseScale;
    if (this.frames.length > 0) this.updateFramesOnZoom();
    this.updateImageMatrixPosOnZoom();
  }

  public override setControlCommandKeyState(active: boolean) {
    this.multiHightlightActive = active;
  }

  protected override onImageMouseOver(image: ImageContainer, event?) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOverWithMagnify(image, event);
      return;
    }

    if (this.imageDragging) return;

    image.setMouseOverState(true);
    const tl = this.animateImageHover(image);
    if (this.imageHoverTimeout) {
      clearTimeout(this.imageHoverTimeout);
    }
    this.imageHoverTimeout = setTimeout(() => {
      if (!this.imagePopperMenuShown) {
        this.imageInfoShown = true;
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    }, 250);

    if (this.highlitedProductIDs.size > 0 && !this.highlitedProductIDs.has(image.getId())) {
      tl.to(image, { pixi: { saturation: 1 }, duration: 0.1, alpha: 1 }, 'animationStart');
    }
  }

  protected override onImageMouseOut(image: ImageContainer) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOutWithMagnify(image);
      return;
    }

    if (this.imageDragging) return;

    image.setMouseOverState(false);
    const tl = this.animateImageHover(image);
    clearTimeout(this.imageHoverTimeout);
    if (this.imageInfoShown) {
      this.imageInfoShown = false;
      this.imageInfoOverlayRef.hide();
    }

    if (this.highlitedProductIDs.size > 0 && !this.highlitedProductIDs.has(image.getId())) {
      tl.to(image, { pixi: { saturation: 0 }, duration: 0.1, alpha: 0.4 }, 'animationStart');
    }
  }

  protected override updateFrameSizes(resizeFactor: number) {
    this.frameBorderBaseWidth *= resizeFactor;
    this.allFrames?.forEach(frame => {
      frame.resizeWithFactor(resizeFactor, this.resourceManagerService, this.dropShadowService, false, false);
      frame.x *= resizeFactor;
      frame.position.y =
        frame.initialYPosForGroupBy * this.resolution + (frame.numFramesOnTopInGroupBy + 1) * this.marginTopOnZoom;
    });
  }

  private clearFrameImageData(
    frame: FrameContainer,
    data: IHoverInfoFieldModel[],
    customFormattings: IMetricsCustomFormattings
  ) {
    const images = frame.getImages();

    images.forEach(image => {
      const imgData = image.getData();
      imgData.hoverInfo = null; // "Calculating..."
      image.setData(imgData);
      if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    });

    frame.refreshTitle();
    if (frame.getInfoShownState()) {
      frame.updateFrameInfoText(data, customFormattings);
      frame.getInfoOverlay().show(false);
    }

    frame.getFrames().forEach(childFrame => {
      this.clearFrameImageData(childFrame, data, customFormattings);
    });
  }

  public override updateImageData(data: IHoverInfoFieldModel[]) {
    if (this.isCreatingGroups) {
      return;
    }
    this.dataService.stopActiveImageAggregateRequests(); // stop all workers which not completed yet

    const customFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    // 1. cleanup Image data for free images and Frames
    for (const frame of this.allFrames) {
      this.clearFrameImageData(frame, data, customFormattings);
    }

    let countUpdatedFrames = 0;
    const framesToPrepare = this.allFrames.filter(item => item.filteredRows);
    framesToPrepare.forEach(item => {
      // --> console.log('!!! GroupBy.updateImageData > getImageAggregatedHoverInfo', data);

      // 2. calculate new Hover Info
      this.dataService.getImageAggregatedHoverInfo(item.filteredRows, null, imageData => {
        // 3. populate Hover Info data into each image
        item.getImages().forEach((image: ImageContainer) => {
          image.setData(imageData[image.getId()]);
          if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
            this.imageInfoOverlayRef.updateText(image);
            this.imageInfoOverlayRef.show();
          }
        });

        countUpdatedFrames++;
        if (countUpdatedFrames === framesToPrepare.length) {
          this.allFrames.forEach(someFrame => {
            someFrame.refreshTitle();
            if (someFrame.getInfoShownState()) {
              someFrame.updateFrameInfoText(data, customFormattings);
              someFrame.getInfoOverlay().show(false);
            }
          });
        }
        // --> console.log('!!! Freestyle.updateImageData > getImageAggregatedHoverInfo > done', data);
      });
    });
  }

  protected override onFrameMouseOver(frame: FrameContainer, event: InteractionEvent) {
    this.frames.filter(item => item.getId() !== frame.getId()).forEach(item => this.onFrameMouseOut(item));
    super.onFrameMouseOver(frame, event);
  }

  protected onGroupByFrameMouseDown(frame: FrameContainer, event: InteractionEvent) {
    event.stopPropagation();
    this.draggingFrameRef = frame;
    this.viewport.pause = true;

    frame.interactiveChildren = false;

    this.frameMoved = false;
    frame.off('mousemove');

    this.matrix.interactive = true;
    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    this.matrixMouseMoveListenerRef = this.onGroupByFrameMouseMoveForDrag.bind(this, frame);
    this.matrix.on('mousemove', this.matrixMouseMoveListenerRef);

    const coordinates = event.data.getLocalPosition(this.matrix);
    this.frameMovePrevPosition = coordinates;

    this.updateCursorOnFrameMouseMove(frame, event);
  }

  protected onGroupByFrameMouseUp(event: InteractionEvent) {
    event.stopPropagation();
    if (!this.draggingFrameRef) return;
    const frame = this.draggingFrameRef;
    this.draggingFrameRef = null;

    this.viewport.pause = false;

    frame.interactiveChildren = true;

    this.matrix.interactive = false;
    this.matrix.off('mousemove', this.matrixMouseMoveListenerRef);
    frame.off('mousemove');
    this.frameDragging = false;
    this.userInteraction = false;

    if (!frame.active) frame.hideBordersAndCorners();

    if (this.frameHover) {
      frame.off('mousemove');
      frame.on('mousemove', this.onFrameMouseMove.bind(this, frame));
      frame.showBordersAndCorners();
    }

    if (this.frameMoved) {
      this.frameMoved = false;
      if (
        this.frameMouseMoveState !== CursorStates.Drag &&
        this.frameMouseMoveState !== CursorStates.FrameInfo &&
        this.frameMouseMoveState !== CursorStates.PublishIcon
      ) {
        frame.onResizeEnd(this.frameMouseMoveState);
        frame.updateDropShadowOnResizeEnd(this.dropShadowService);
      } else if (!frame.draggingEnabled) {
        frame.updateDropShadowOnResizeEnd(this.dropShadowService);
      } else {
        frame.initialYPosForGroupBy =
          (frame.position.y - (frame.numFramesOnTopInGroupBy + 1) * this.marginTopOnZoom) / this.resolution;

        frame.updateDropShadowOnDragEnd();
      }

      this.updateAllFrameHtmlElements();

      this.render();

      this.allFrames.forEach(item => {
        if (item.getRestoreInfoState()) {
          item.setRestoreInfoState(false);
          if (item.htmlElementsVisible) {
            this.showFrameInfo(item, false);
          } else {
            this.hideFrameInfo(item, false);
            item.setInfoLockedState(false);
            item.changeInfoIconTexture();
          }
        }
      });

      this.updateFrameInCanvasState(this.getFirstFrameAncestor(frame));

      return;
    }

    const bounds = frame.getFrameBounds();
    if (frame.checkForInfoClick(event, bounds)) this.onFrameInfoClick(frame);
  }

  private onGroupByFrameMouseMoveForDrag(frame: FrameContainer, event: InteractionEvent) {
    event.stopPropagation();
    const coordinates = event.data.getLocalPosition(this.imageMatrix);
    if (
      Math.abs(coordinates.x - this.frameMovePrevPosition.x) < 1 &&
      Math.abs(coordinates.y - this.frameMovePrevPosition.y) < 1
    ) {
      return;
    }

    if (!this.frameMoved) this.onGroupByFrameDragStart(frame, coordinates);
    this.frameMovePrevPosition = coordinates;
    this.frameMoved = true;
    if (this.frameDragging) {
      if (this.frameMouseMoveState === CursorStates.Drag || this.frameMouseMoveState === CursorStates.FrameInfo) {
        if (frame.draggingEnabled) frame.mouseMoveActions[this.frameMouseMoveState](coordinates);
      } else {
        frame.mouseMoveActions[this.frameMouseMoveState](coordinates);
      }
      this.updateAllFrameHtmlElements();
    }
    this.render();
  }

  private onGroupByFrameDragStart(frame: FrameContainer, coordinates: IPosition) {
    this.hideImagePopper();
    this.contextMenuClosed$.next();

    this.allFrames.forEach(item => {
      if (item.getInfoShownState()) {
        this.hideFrameInfo(item, false);
        if (item.getInfoLockedState()) item.setRestoreInfoState(true);
      }
    });

    this.frameDragging = true;
    this.userInteraction = true;

    if (
      (this.frameMouseMoveState === CursorStates.Drag || this.frameMouseMoveState === CursorStates.FrameInfo) &&
      !frame.draggingEnabled
    ) {
      return;
    }

    const firstAncestor = this.getFirstFrameAncestor(frame);
    const index = this.frames.indexOf(firstAncestor);
    if (index > -1) {
      this.imageMatrix.removeChild(firstAncestor);
      this.imageMatrix.addChild(firstAncestor);
      this.frames.splice(index, 1);
      this.frames.push(firstAncestor);
    }

    frame.updateHtmlElements(true);

    frame.saveBounds();
    frame.saveCusrosPositionFromAnchor(coordinates);
  }

  public onHarvestModeInit() {}
  public onHarvestModeClose() {}

  public override onViewportMove() {
    super.onViewportMove();
    this.frames.forEach((frame: FrameContainer) => {
      frame.updateFrameTitlePosition();
      frame.updateInfoOverlay();
    });
  }

  public override onZoomChange(zoomFactor, instantResizeMatrix: boolean = false): void {
    super.onZoomChange(zoomFactor, instantResizeMatrix);
    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    if (this.frames.length > 0) this.updateFramesOnZoom();
    this.updateImageMatrixPosOnZoom();
  }

  private updateFramesOnZoom() {
    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    this.frameMarginTop = 30 / this.viewport.scale.x;

    this.frames.forEach((frame: FrameContainer) => {
      frame.updateOnZoom(this.frameBorderWidth, this.frameMarginTop, false);
      frame.makeGrid();
      frame.updateFrameTitlePosition();
      frame.updateInfoOverlay();
    });
  }

  public override onZoomEnd(): void {
    this.updateFramesInCanvasState(this.frames);
  }

  protected override getFramePosition(frame: FrameContainer) {
    if (frame.parent instanceof FrameContainer) return super.getFramePosition(frame);

    const topMargin = 0; // (frame.numFramesOnTopInGroupBy + 1) * this.marginTopOnZoom;
    return {
      x: (frame.x + this.imageMatrix.x) / this.resolution,
      y: (frame.y + this.imageMatrix.y - topMargin) / this.resolution
    };
  }

  public getImagePosInWorld(image: ImageContainer): IPosition {
    const framePos = (image.parent as FrameContainer).getCoordinatesOnMatrix();
    return {
      x: image.x + framePos.x,
      y: image.y + framePos.y
    };
  }

  public onIndividualImageViewChange(image: ImageContainer): void {
    this.updateImageInCanvasState(image);
  }

  protected updateImageView(name: string): void {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    const images = this.images.filter(imageCont => imageCont.getId() === prodId);
    if (!images || images.length === 0) return;

    images.forEach(image => image.updateView(this.resourceManagerService, this.selectedView, this.render.bind(this)));
    this.render();
  }

  public getStateForPresentationMode(): IPresentationState {
    return {
      frames: this.frames.map(frame => this.getFramePresentationState(frame)),
      images: [],
      groupsByLevel: []
    };
  }
}
