import { IHoverInfoField } from '@app/models/image.model';
import { IBounds } from '@app/models/bounds.model';
import { GroupLabel } from './group-label';
import gsap from 'gsap';
import { DockingSide, GroupOrientation } from '@app/models/study-setting.model';

export class GroupQuickStats {
  private readonly fontSize = 12;
  private readonly lineHeight = 14;

  private quickStatsRef: HTMLDivElement;

  private wrapperHeight: number;
  private wrapperWidth: number;

  private isShown: boolean = false;
  private hoverInfo: Array<IHoverInfoField> = null;

  constructor(private groupLabel: GroupLabel) {
    this.createQuickStats();
  }

  private createQuickStats() {
    this.quickStatsRef = document.createElement('div');
    this.quickStatsRef.classList.add('groupQuickStats');
    this.groupLabel.getWrapper().appendChild(this.quickStatsRef);
  }

  protected getLabelBounds(): IBounds {
    const labelWrapper = this.groupLabel.getWrapper();
    return {
      x: labelWrapper.offsetLeft,
      y: labelWrapper.offsetTop,
      width: labelWrapper.offsetWidth,
      height: labelWrapper.offsetHeight
    };
  }

  public getQuickStatsShownState(): boolean {
    return this.isShown;
  }

  public getQuickStatsRef(): HTMLDivElement {
    return this.quickStatsRef;
  }

  public getInfo(): Array<IHoverInfoField> {
    return this.hoverInfo;
  }

  public updateText(hoverInfo: Array<IHoverInfoField>): void {
    this.hoverInfo = hoverInfo || null;
    let innerHTML = '<div class="groupQuickStatsWrapper">';
    if (hoverInfo === null) {
      innerHTML += `
          <p class="calculating-message">Calculating...</p>
        `;
    } else if (hoverInfo.length === 0) {
      innerHTML += `
          <p class="calculating-message">Add Statistics</p>
      `;
    } else {
      hoverInfo?.forEach(info => {
        innerHTML += `<p class="field-name">${info.fieldName}</p>`;
        info.fieldValues.forEach(val => {
          innerHTML += `<p class="field-value">${val}</p>`;
        });
      });
    }
    innerHTML += '</div>';
    this.quickStatsRef.innerHTML = innerHTML;
    if (this.isShown) {
      this.scaleWrapper(1);
      const quickStatsWrapperRef = this.quickStatsRef.querySelector('.groupQuickStatsWrapper') as HTMLDivElement;
      this.wrapperHeight = quickStatsWrapperRef.offsetHeight;
      this.wrapperWidth = quickStatsWrapperRef.offsetWidth;
      this.scaleWrapper();
    }
  }

  public resize(scale: number) {
    this.setSizeAndPosition(scale);
    if (this.isShown) {
      this.scaleWrapper();
    }
  }

  public updateLabelOrientation(): void {
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Vertical) {
      this.quickStatsRef.style.height = '100%';
      this.quickStatsRef.style.width = '0';
    } else {
      this.quickStatsRef.style.width = '100%';
      this.quickStatsRef.style.height = '0';
    }
  }

  public setSizeAndPosition(scale: number) {
    this.quickStatsRef.style.borderRadius = `${5 * scale}px`;
    this.quickStatsRef.style.boxShadow = `0px ${4 * scale}px ${4 * scale}px 0px rgba(0, 0, 0, 0.2509803922)`;
    if (this.isShown) {
      if (this.groupLabel.getLabelOrientation() === GroupOrientation.Vertical) {
        this.quickStatsRef.style.width = `${Math.min(140, this.quickStatsRef.offsetHeight)}px`;
      } else {
        this.quickStatsRef.style.height = `${Math.min(140, this.quickStatsRef.offsetWidth)}px`;
      }
    }
    this.setPosition();
  }

  public setPosition(): void {
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Vertical) {
      if (this.groupLabel.getDockingSide() === DockingSide.Right) {
        this.quickStatsRef.style.left = `calc(100% + 1px)`;
        this.quickStatsRef.style.right = `auto`;
      } else {
        this.quickStatsRef.style.right = `calc(100% + 1px)`;
        this.quickStatsRef.style.left = `auto`;
      }
      this.quickStatsRef.style.top = `auto`;
      this.quickStatsRef.style.bottom = `auto`;
    } else {
      if (this.groupLabel.getDockingSide() === DockingSide.Bottom) {
        this.quickStatsRef.style.top = `calc(100% + 1px)`;
        this.quickStatsRef.style.bottom = `auto`;
        this.quickStatsRef.style.zIndex = `99`;
      } else {
        this.quickStatsRef.style.top = `auto`;
        this.quickStatsRef.style.bottom = `calc(100% + 1px)`;
        this.quickStatsRef.style.zIndex = `98`;
      }
      this.quickStatsRef.style.left = `auto`;
      this.quickStatsRef.style.right = `auto`;
    }
  }

  public show(animate: boolean): void {
    this.isShown = true;
    this.scaleWrapper(1);
    const quickStatsWrapperRef = this.quickStatsRef.querySelector('.groupQuickStatsWrapper') as HTMLDivElement;
    this.wrapperHeight = quickStatsWrapperRef.offsetHeight;
    this.wrapperWidth = quickStatsWrapperRef.offsetWidth;
    this.scaleWrapper();
    gsap.killTweensOf(this.quickStatsRef);
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Vertical) {
      gsap.to(this.quickStatsRef, {
        duration: animate ? 0.25 : 0,
        width: `${Math.min(140, this.quickStatsRef.offsetHeight)}px`
      });
    } else {
      gsap.to(this.quickStatsRef, {
        duration: animate ? 0.25 : 0,
        height: `${Math.min(140, this.quickStatsRef.offsetWidth)}px`
      });
    }
  }

  public hide(animate: boolean): void {
    this.isShown = false;
    gsap.killTweensOf(this.quickStatsRef);
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Vertical) {
      gsap.to(this.quickStatsRef, {
        duration: animate ? 0.25 : 0,
        width: 0
      });
    } else {
      gsap.to(this.quickStatsRef, {
        duration: animate ? 0.25 : 0,
        height: 0
      });
    }
  }

  private scaleWrapper(scale: number = null): void {
    const wrapperScale = scale || this.getScale();
    const quickStatsWrapperRef = this.quickStatsRef.querySelector('.groupQuickStatsWrapper') as HTMLDivElement;
    quickStatsWrapperRef.style.padding = `${5 * wrapperScale}px`;
    quickStatsWrapperRef.style.textAlign = 'center';
    this.setTextScale(wrapperScale);
  }

  private getScale(): number {
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Horizontal) {
      const height = Math.min(140, this.quickStatsRef.offsetWidth);
      return Math.min(height / this.wrapperHeight, this.quickStatsRef.offsetWidth / this.wrapperWidth, 1);
    } else {
      const width = Math.min(140, this.quickStatsRef.offsetHeight);
      return Math.min(width / this.wrapperWidth, this.quickStatsRef.offsetHeight / this.wrapperHeight, 1);
    }
  }

  public getSize(): number {
    if (this.groupLabel.getLabelOrientation() === GroupOrientation.Horizontal) {
      return Math.min(140, this.quickStatsRef.offsetWidth);
    } else {
      return Math.min(140, this.quickStatsRef.offsetHeight);
    }
  }

  private setTextScale(scale: number = 1): void {
    const fieldName = this.quickStatsRef.querySelectorAll('.field-name') as NodeListOf<HTMLParagraphElement>;
    const fieldValue = this.quickStatsRef.querySelectorAll('.field-value') as NodeListOf<HTMLParagraphElement>;
    const calculatingMessage = this.quickStatsRef.querySelectorAll(
      '.calculating-message'
    ) as NodeListOf<HTMLParagraphElement>;

    fieldName.forEach((item, key) => {
      item.style.fontSize = `${this.fontSize * scale}px`;
      item.style.lineHeight = `${this.lineHeight * scale}px`;
      if (key !== 0) item.style.marginTop = `${10 * scale}px`;
    });
    fieldValue.forEach(item => {
      item.style.fontSize = `${this.fontSize * scale}px`;
      item.style.lineHeight = `${this.lineHeight * scale}px`;
    });
    calculatingMessage.forEach(item => {
      item.style.fontSize = `${this.fontSize * scale}px`;
      item.style.lineHeight = `${this.lineHeight * scale}px`;
    });
  }

  public remove(): void {
    const labelWrapper = this.groupLabel.getWrapper();
    if (labelWrapper.contains(this.quickStatsRef)) labelWrapper.removeChild(this.quickStatsRef);
  }
}
