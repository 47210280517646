import { Component } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { User } from '@angular/fire/auth';

@Component({
  selector: 'app-unverified',
  templateUrl: './unverified.component.html',
  styleUrls: ['./unverified.component.scss']
})
export class UnverifiedComponent {
  public currentUser: User;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.currentUser;
  }

  public onSendVerificationEmail(): void {
    this.authService.sendEmailVerification();
  }
}
