<div class="search-field">
  <svg-icon svgIcon="icon" name="icon-user-search"></svg-icon>
  <input class="search-field__input" #searchInput type="text" (input)="onInput()" />
</div>
<div class="search-footer">
  <div class="search-footer__result">
    {{ resultCount || 0 }} {{ resultCount || 0 | i18nPlural: itemPluralMapping[type] }}
  </div>
  <div *ngIf="type === PortalMenuItem.Connections" class="search-footer__switcher">
    <button
      class="search-footer__switcher__button image"
      [ngClass]="{ active: showImageConnections }"
      (click)="onSwitchConnectionType(ConnectionType.Image)"
    >
      <svg-icon svgClass="icon" name="icon-image-connection"></svg-icon>
    </button>
    <div class="search-footer__switcher__divider"></div>
    <button
      class="search-footer__switcher__button business"
      [ngClass]="{ active: showBusinessConnections }"
      (click)="onSwitchConnectionType(ConnectionType.Business)"
    >
      <svg-icon svgClass="icon" name="icon-business-connection"></svg-icon>
    </button>
  </div>
</div>
