import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { iconsMap } from '@app/shared/constants/matrix-types';
import { IWorkspace } from '@app/models/workspace-list.model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { StudyState } from '@app/state/study/study.state';
import { AddWorkspace } from '@app/state/study/study.actions';
import { expendTopCompassAnimation } from '../../animations/animations';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { WorkspaceSelectorService } from '@app/services/workspace-selector/workspace-selector.service';
import { MatrixService } from '@app/services/matrix/matrix.service';

@UntilDestroy()
@Component({
  selector: 'app-compass',
  templateUrl: './compass.component.html',
  styleUrls: ['./compass.component.scss'],
  animations: [expendTopCompassAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompassComponent implements OnInit {
  public readonly icons = iconsMap;
  public expanded = false;

  @Select(StudyState.getMatrixType)
  public readonly matrixType$: Observable<ValidMatrix>;
  @Select(StudyState.canAddWorkspace)
  public readonly canAddWorkspace$: Observable<boolean>;
  @Select(StudyState.getWorkspaceList)
  public readonly workspaces$: Observable<Array<IWorkspace>>;
  @Select(StudyState.getActiveWorkspaceId)
  public readonly activeWorkspaceId$: Observable<string | null>;
  @Select(StudyState.isUpdatingWorkspace)
  public readonly isUpdatingWorkspaceInStore$: Observable<boolean>;
  @Select(StudyState.hasImagesOrFrames)
  public readonly hasImagesOrFrames$: Observable<boolean>;

  isUpdatingWorkspaces = false;
  isNeedRebuildMatrices = false;
  isNoMatrixAvailable = false;
  showWorkspaceSelector = false;
  workspaces = [];

  constructor(
    private store: Store,
    private matrixService: MatrixService,
    private workspaceSelectorService: WorkspaceSelectorService,
    protected readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.hasImagesOrFrames$.pipe(untilDestroyed(this)).subscribe((payload: boolean) => {
      this.expanded = payload;
    });

    this.workspaces$.pipe(untilDestroyed(this)).subscribe(workspaces => {
      this.isNoMatrixAvailable = workspaces.every(workspace => !workspace.matrixType);
      this.isNeedRebuildMatrices = workspaces.some(workspace => workspace.isNeedRebuildMatrix);
    });

    this.hasImagesOrFrames$.pipe(untilDestroyed(this)).subscribe((payload: boolean) => {
      this.expanded = payload;
    });

    this.matrixType$
      .pipe(
        filter(val => !val),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.expanded = false;
      });

    this.matrixService.isUpdatingWorkspacesSubject.pipe(untilDestroyed(this)).subscribe(isUpdating => {
      this.isUpdatingWorkspaces = isUpdating;
      this.cdr.detectChanges();
    });
  }

  public createWorkspace(): void {
    const activeStudyId = this.store.selectSnapshot(StudyState.getActiveStudyId);
    if (activeStudyId) this.store.dispatch(new AddWorkspace(activeStudyId));
    Sentry.addBreadcrumb({ level: 'log', message: 'Click createWorkspace', data: { activeStudyId } });
  }

  onWorkspaceSelectorButtonClick() {
    this.workspaceSelectorService.openWorkspaceSelector();
  }

  getWorkspaceSelectorBtnTooltip() {
    return this.isNeedRebuildMatrices ? 'Custom Calculations changed, some matrices need rebuild' : 'Switch workspace';
  }
}
