import { IHeaderData } from '../../components/build/business-data-grid/business-data-grid.component';

export type ColumnType = 'string' | 'number' | 'boolean' | 'currency' | 'percent' | 'date' | 'color';

export const NEW_LINE = '\n';

// put here any additional settings which helps to parse column data
export interface IColumnTypeSettings {
  dateFormat?: string;
}

export function rowsToCSVText(
  headers: Array<IHeaderData>,
  rows: Array<Array<string>>,
  ignoreHeaders: Array<string> = []
): string {
  const visibleHeaders = headers.filter(
    header => header.isShown && !ignoreHeaders.some(ignoredHeader => ignoredHeader === header.column)
  );
  const headersText = JSON.stringify(visibleHeaders.map(item => item.title));
  let text = `${headersText.slice(1, headersText.length - 1)}${NEW_LINE}`; // delete symbols "[", "]" and add NewLine
  rows.forEach(row => {
    if (row) {
      const rowData = [];
      visibleHeaders.forEach(header => {
        rowData.push(row[header.dataIndex]);
      });
      const rowText = JSON.stringify(rowData); // stringify filtered row data
      text += `${rowText.slice(1, rowText.length - 1)}${NEW_LINE}`; // delete symbols "[", "]" and add NewLine
    }
  });
  return text;
}

// this function extracted from ParseDataFile into the separate place,
// because ParseDataFile service uses in WebWorker, see app.process-csv-file.worker.ts
// WebWorker object cannot work with Window
export function downloadFile(filename: string, data: string, fileType: string = 'text/csv') {
  const blob = new Blob([data], { type: fileType });
  const elem = window.document.createElement('a');
  elem.href = URL.createObjectURL(blob);
  elem.download = filename;
  window.document.body.appendChild(elem);
  elem.click();
  window.document.body.removeChild(elem);
}
