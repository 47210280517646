import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WINDOW } from '../../utils/window';

export type ConfirmDialogIcon = 'none' | 'warning' | 'error' | 'success' | 'info' | 'question';

interface IConfirmDialogOption {
  title: string;
  disabled?: boolean;
}

export interface IConfirmDialogData {
  message: string;
  html?: string;
  notes?: string; // text under options, could be text or HTML
  title?: string;
  icon?: ConfirmDialogIcon;
  buttons?: string[];
  options?: IConfirmDialogOption[];
  defaultOptionIndex?: number;
}

export interface IConfirmDialogResult {
  button: string;
  buttonIndex: number;
  selectedOption: string;
  selectedOptionIndex: number;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public readonly ConfirmDialogIcon;

  message: string;
  html: string;
  notes: string;
  icon: ConfirmDialogIcon = 'none';
  buttons: Array<string> = ['OK'];
  title: string;

  options: IConfirmDialogOption[] = [];
  selectedOptionIndex: number;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, IConfirmDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
  ) {
    this.message = data.message;
    this.html = data.html;
    this.notes = data.notes;
    this.icon = data.icon || 'none';
    this.title = data.title;
    if (data.options) {
      this.options = data.options;
      if (data.defaultOptionIndex >= 0) {
        this.selectedOptionIndex = data.defaultOptionIndex;
      }
    }
  }

  onClickButton(index: number) {
    this.dialogRef.close({
      button: this.buttons[index],
      buttonIndex: index + 1,
      selectedOptionIndex: this.selectedOptionIndex
    } as IConfirmDialogResult);
  }

  onCancel() {
    this.dialogRef.close({
      button: 'Cancel',
      buttonIndex: 0
    } as IConfirmDialogResult);
  }
}
