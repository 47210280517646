import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthPipe, AuthGuard } from '@angular/fire/auth-guard';
import { BuildComponent } from './views/build/build.component';
import { HomeComponent } from './views/home/home.component';
import { map } from 'rxjs/operators';
import { User } from '@angular/fire/auth';
import { DelayResolver } from './shared/utils/delay-resolver';

const redirectToLoginOrVerifyEmail: AuthPipe = map((user: User) => {
  if (!user) {
    return ['auth', 'login'];
  }
  if (!user.emailVerified) {
    return ['auth', 'verify-email'];
  }
  return true;
});

const routes: Routes = [
  {
    path: 'build',
    component: BuildComponent,
    canActivate: [AuthGuard],
    resolve: { data: DelayResolver }, // to have time for app-fade-in-out animation
    data: { authGuardPipe: () => redirectToLoginOrVerifyEmail }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: { data: DelayResolver }, // to have time for app-fade-in-out animation
    data: { authGuardPipe: () => redirectToLoginOrVerifyEmail }
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
