import { Pipe, PipeTransform } from '@angular/core';
import { IFiltersModel } from '@app/models/filters.model';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(filters: Array<IFiltersModel>, searchValue: string): IFiltersModel[] {
    if (!filters || !searchValue || searchValue === '') {
      return filters;
    }
    return filters
      .filter(filter => {
        return (
          filter.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          filter.discreteValues?.some(({ name }) => name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
        );
      })
      .map(filter => {
        if (!filter.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
          return {
            ...filter,
            discreteValues: filter.discreteValues.filter(value =>
              value.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            )
          };
        }
        return filter;
      });
  }
}
