<div class="container">
  <div class="wrapper">
    <div class="logo">
      <svg-icon svgClass="icon" name="text-logo-collagia-white"></svg-icon>
    </div>
    <div class="message-wrapper">
      <p class="message-wrapper__message">Your browser is not supported.</p>
      <p class="message-wrapper__submessage">We recommend using the Chrome Browser for our application instead.</p>
    </div>
  </div>
</div>
