import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

/**
 * Resize column directive for Material Table
 */

@Directive({
  selector: '[resizeColumn]'
})
export class ResizeColumnDirective implements OnInit, OnDestroy {
  @Input('resizeColumn') resizable: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output() onStartResize = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output() onEndResize = new EventEmitter<number>();

  private startX: number;

  private startWidth: number;
  private currentWidth: number;
  private minWidth: number;

  private column: HTMLElement;
  private table: HTMLElement;

  private columnIndex: number;

  private pressed: boolean;

  private removeMousedown: any;
  private removeMousemove: any;
  private removeMouseup: any;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      const thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(thead);

      const headers = Array.from(row.querySelectorAll('.mat-header-cell'));
      this.columnIndex = headers.findIndex(header => header === this.column);

      const resizer = this.renderer.createElement('span');
      this.renderer.addClass(resizer, 'resize-holder');
      this.renderer.appendChild(this.column, resizer);

      this.removeMousedown = this.renderer.listen(resizer, 'mousedown', this.onMouseDown.bind(this));
    }
  }

  ngOnDestroy(): void {
    if (this.removeMousedown) {
      this.removeMousedown();
    }
    if (this.removeMousemove) {
      this.removeMousemove();
    }
    if (this.removeMouseup) {
      this.removeMouseup();
    }
  }

  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.removeMousemove = this.renderer.listen(this.table, 'mousemove', this.onMouseMove.bind(this));
    this.removeMouseup = this.renderer.listen('document', 'mouseup', this.onMouseUp.bind(this));
    this.onStartResize.emit();
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;

    const headerText = this.column.querySelector('.mat-sort-header-content');
    this.minWidth = headerText.clientWidth + 30; // plus 30px for sorting icon
  }

  onMouseMove(event: MouseEvent) {
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, 'resizing');

      // Calculate width of column
      this.currentWidth = Math.max(this.minWidth, this.startWidth + (event.pageX - this.startX));

      // Set table header width
      this.renderer.setStyle(this.column, 'min-width', `${this.currentWidth}px`);
      this.renderer.setStyle(this.column, 'max-width', `${this.currentWidth}px`);

      const tableCells = Array.from(this.table.querySelectorAll('.mat-row')).map((row: any) =>
        row.querySelectorAll('.mat-cell').item(this.columnIndex)
      );

      // Set table cells width
      for (const cell of tableCells) {
        this.renderer.setStyle(cell, 'min-width', `${this.currentWidth}px`);
        this.renderer.setStyle(cell, 'max-width', `${this.currentWidth}px`);
      }
    }
  }

  onMouseUp(event: MouseEvent) {
    if (this.pressed) {
      event.preventDefault();
      this.removeMousemove();
      this.removeMouseup();
      this.pressed = false;
      this.renderer.removeClass(this.table, 'resizing');
      this.onEndResize.emit(this.currentWidth);
    }
  }
}
