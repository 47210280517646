import { Component, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { PortalMenuItem, PortalMenuItemCollorMapper } from '@app/shared/enums/portal-menu-item.enum';
import { WINDOW } from '@app/shared/utils/window';
import { ConnectionType } from '@app/state/connection/connection.model';

@Component({
  selector: 'app-home-card-header',
  templateUrl: './home-card-header.component.html',
  styleUrls: ['./home-card-header.component.scss']
})
export class HomeCardHeaderComponent implements OnInit, OnChanges {
  @Input() public type: PortalMenuItem;
  @Input() public connectionType?: ConnectionType;
  @Input() public name: string;
  @Input() public id?: string;
  @Input() public favorite: boolean = false;
  @Input() public owner?: string;
  public readonly ConnectionType = ConnectionType;
  public readonly PortalMenuItemCollorMapper = PortalMenuItemCollorMapper;

  public matRippleRadius: number;
  public btnClassList: {
    [key: string]: boolean;
  } = {};

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.matRippleRadius = (this.window.innerHeight * 30) / 1080 / 2;
  }

  constructor(
    @Inject(WINDOW)
    private window: Window,
    private projectDataService: ProjectDataService
  ) {}

  ngOnInit(): void {
    this.matRippleRadius = (this.window.innerHeight * 30) / 1080 / 2;
    this.btnClassList = {
      [this.type]: true,
      image: this.connectionType === ConnectionType.Image,
      business: this.connectionType === ConnectionType.Business,
      active: this.favorite
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.favorite && !changes.favorite.firstChange) {
      this.btnClassList.active = this.favorite;
    }
  }

  public onAddToFavorites(event: MouseEvent): void {
    event.stopPropagation();
    switch (this.type) {
      case PortalMenuItem.Connections: {
        this.projectDataService.updateConnection(this.id, { favorite: !this.favorite });
        break;
      }
      case PortalMenuItem.Collages: {
        this.projectDataService.updateCollage(this.id, { favorite: !this.favorite });
        break;
      }
      case PortalMenuItem.Studies: {
        this.projectDataService.updateStudy(this.id, { favorite: !this.favorite } as any);
        break;
      }
    }
  }
}
