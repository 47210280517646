import { IMetricCustomFormatting } from '@app/models/study-setting.model';

export function formatCurrency(val: number): string {
  return `$${formatBigNumber(val)}`;
}

export function formatBigNumber(val: number, fractionDigits = 2): string {
  // Removed lookbehind assertion because it redundant if we use toFixed(2)
  // Lookbehind assertion doesn't work in Safari
  // return (+val.toFixed(2)).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  if (val === null || val === undefined) return 'NaN';
  return val
    .toFixed(fractionDigits)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function customFormatBigNumber(val: number, customFormatting: IMetricCustomFormatting) {
  return `${customFormatting?.prefix ? customFormatting.prefix : ''}${formatBigNumber(
    customFormatting?.multiplier ? val * customFormatting.multiplier : val,
    customFormatting?.fractionDigit
  )}${customFormatting?.suffix ? customFormatting.suffix : ''}`;
}

export function deleteFileExtension(fileName: string): string {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}
