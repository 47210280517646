import { Injectable } from '@angular/core';
import { ResizableEllipseContainer } from '@app/custom-pixi-containers/resizable-ellipse-container';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { Select, Store } from '@ngxs/store';
import gsap from 'gsap/all';
import { Viewport } from 'pixi-viewport';
import { Container, Renderer } from 'pixi.js';
import { Observable, Subject } from 'rxjs';
import { ImageContainer } from '../../custom-pixi-containers/image-container';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatrixService } from '../matrix/matrix.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class HarvestService {
  @Select(StudyState.getMatrixType)
  matrixType$: Observable<ValidMatrix>;
  private matrixType: ValidMatrix;

  private renderer: Renderer;
  private stage: Container;
  private viewport: Viewport;

  private ellipseTool: ResizableEllipseContainer;

  private onKeyDownRef;

  public harvestModeSubject: Subject<boolean>;

  constructor(protected store: Store, protected matrixService: MatrixService) {
    this.ellipseTool = new ResizableEllipseContainer();
    this.onKeyDownRef = this.onKeyDown.bind(this);
    this.harvestModeSubject = new Subject();
    this.subMatrixType();
  }

  private subMatrixType() {
    this.matrixType$.pipe(untilDestroyed(this)).subscribe({
      next: async type => {
        this.matrixType = type;
      }
    });
  }

  public updateReferences() {
    const { stage, renderer, viewport } = this.matrixService.getReferencesForHarvestService();
    this.stage = stage;
    this.renderer = renderer;
    this.viewport = viewport;
  }

  public initHarvestMode(image: ImageContainer) {
    this.updateReferences();
    const bounds = image.getBounds();
    const wMult = (window.innerWidth - 300) / bounds.width;
    const hMult = (window.innerHeight - 300) / bounds.height;
    const scaleMult = wMult < hMult ? wMult : hMult;
    const viewportStartScale = this.viewport.scale.x;
    const viewportEndScale = viewportStartScale * scaleMult;
    const imagePos = this.matrixService.getImagePosInWorld(image);
    const ellipseW = bounds.width * scaleMult;
    const ellipseH = bounds.height * scaleMult;

    const centerTemp = {
      x: this.viewport.center.x,
      y: this.viewport.center.y
    };

    gsap.to(centerTemp, {
      duration: 0.28,
      x: imagePos.x,
      y: imagePos.y,
      ease: 'circ.out'
    });
    gsap.to(this.viewport.scale, {
      duration: 0.28,
      x: viewportEndScale,
      y: viewportEndScale,
      ease: 'power1.in',
      onUpdate: () => {
        this.viewport.moveCenter(centerTemp.x, centerTemp.y);
        this.render();
      },
      onComplete: () => {
        this.createEllipse(ellipseW, ellipseH);
      }
    });

    this.matrixService.onHarvestModeInit();

    this.viewport.interactiveChildren = false;
    document.addEventListener('keydown', this.onKeyDownRef);
  }

  private createEllipse(width, height) {
    this.harvestModeSubject.next(true);
    this.ellipseTool.position.set(window.innerWidth / 2, window.innerHeight / 2);
    this.ellipseTool.createResizableEllipse(width, height, this.renderer, this.stage);
    this.stage.addChild(this.ellipseTool);
    this.render();
  }

  public closeHarvestMode() {
    this.harvestModeSubject.next(false);
    this.viewport.interactiveChildren = true;
    document.removeEventListener('keydown', this.onKeyDownRef);

    this.matrixService.onHarvestModeClose();

    if (gsap.isTweening(this.viewport.scale)) {
      gsap.killTweensOf(this.viewport);
      gsap.killTweensOf(this.viewport.scale);
      this.render();
      return;
    }
    this.ellipseTool.destroyResizableEllipse();
    this.stage.removeChild(this.ellipseTool);
    this.render();
  }

  private onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeHarvestMode();
    }
  }

  private render() {
    this.renderer.render(this.stage);
  }
}
