import { IHoverInfoField } from '@app/models/image.model';
import { GroupContainer } from './group-container';
import { InfoOverlayBase } from './info-overlay-base';
import { IBounds } from '@app/models/bounds.model';

export class GroupInfoOverlay extends InfoOverlayBase {
  private readonly defaultZIndex = 60;
  private overlapLevel: number = 0;
  private overlapped: boolean = false;

  constructor(private groupContRef: GroupContainer) {
    super('groupInfo');
    this.infoHtmlRef.style.zIndex = `${this.defaultZIndex}`;
  }

  protected getBounds(): IBounds {
    const { x, y, width, height } = this.groupContRef.getBounds();
    return {
      x: x - 1,
      y: y - 1,
      width: width + 2,
      height: height + 2
    };
  }

  public resize(infoShown: boolean): void {
    if (infoShown) {
      const bounds = this.getBounds();
      this.setSizeAndPosition(bounds);
      this.scaleWrapper();
    }
  }

  public updateText(hoverInfo: Array<IHoverInfoField>): void {
    let innerHTML = `
      <div class="groupInfo-wrapper">
        <p class="field-name">
          ${this.groupContRef.getLabel().key ? `${this.groupContRef.getLabel().key}:` : ''}
        </p>
        <p class="title">
          ${this.groupContRef.getLabel().value}
        </p>
      `;
    if (hoverInfo === null) {
      innerHTML += `
        <p class="calculating-message">Calculating...</p>
      `;
    } else {
      hoverInfo?.forEach(info => {
        innerHTML += `<p class="field-name">${info.fieldName}</p>`;
        info.fieldValues.forEach(val => {
          innerHTML += `<p class="field-value">${val}</p>`;
        });
      });
    }
    innerHTML += '</div>';
    this.infoHtmlRef.innerHTML = innerHTML;
    this.wrapperHeight = null;
    this.wrapperWidth = null;
  }

  public setOverlapLevel(overlapLevel: number, overlapped: boolean): void {
    this.overlapLevel = overlapLevel;
    this.overlapped = overlapped;
    this.infoHtmlRef.style.zIndex = `${this.defaultZIndex + overlapLevel}`;
    this.infoHtmlRef.style.opacity = this.overlapped ? `0.7` : '1';
    (this.infoHtmlRef.querySelector('.groupInfo-wrapper') as HTMLDivElement).style.filter = overlapped
      ? 'blur(5px)'
      : 'none';
  }

  public getOverlapLevel(): number {
    return this.overlapLevel;
  }

  public restoreOverlapLevel(): void {
    this.setOverlapLevel(this.overlapLevel, this.overlapped);
  }
}
