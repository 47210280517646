import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uuid } from '@app/shared/utils/uuid';
import { User } from '@angular/fire/auth';
import { Timestamp } from '@angular/fire/firestore';
import { IUploadedFile, UploadFileComponent } from '../upload-file/upload-file.component';
import { businessConnectionValidator, selectionRequiredValidator } from '../../../utils/custom-validators';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Observable } from 'rxjs';
import {
  DatabaseConnectionType,
  IBusinessConnection,
  IBusinessData,
  ICSVConnectionSettings,
  ISnowflakeSettings
} from '../../../state/connection/business/business-connection.model';
import { IImageConnection, ImageManagerType } from '../../../state/connection/image/image-connection.model';
import { filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConnectionType } from '@app/state/connection/connection.model';
import { deleteFileExtension } from '../../../utils/string';
import { DemoDataService } from '@app/services/demo-data/demo-data.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupTableService } from '@app/components/lookup-table';
import {
  ISelectEncodingDialogData,
  SelectEncodingDialogComponent
} from '../../../shared/components/select-encoding-dialog/select-encoding-dialog.component';
import {
  DefaultEncoding,
  getUserDefaultEncoding,
  setUserDefaultEncoding
} from '../../../shared/components/select-encoding-dialog/encodings';
import { ConnectionUtils } from '@app/utils/connection.utils';
import { ICollage } from '@app/state/collage/collage.model';
import { AuthService } from '@app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IUserData, UserGroup } from '@app/models/user-data.model';
import scrollIntoView from 'scroll-into-view-if-needed';
import { WINDOW } from '../../../shared/utils/window';
import gsap from 'gsap/all';
import { DataService, IParsedBusinessData } from '../../../services/data/data.service';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
  IConfirmDialogResult
} from '../../../shared/components/confirm-dialog/confirm-dialog.component';

export const leviteImagesSourceMock = {
  host: 'images.collagia.com',
  port: 4502,
  timeout: 3000,
  path: '/dam/',
  filter: '*'
};

export const columbiaImagesSourceMock = {
  host: 'columbia.scene7.com',
  port: 443,
  timeout: 1000,
  path: '/is/image/ColumbiaSportswear2/',
  filter: '*'
};

export const nikeImagesSourceMock = {
  host: 'api.nike.com',
  port: 443,
  timeout: 1000,
  path: '/product_feed/threads/v2',
  filter: '*'
};

export const userImagesSourceMock = {
  host: 'images.collagia.com',
  port: 443,
  timeout: 1000,
  path: '/uid',
  filter: '*'
};

export const leviteBusinessSourceMock = {
  auth: 'SSO',
  user: 'CollagiaUser',
  password: '********',
  host: 'aem.levite.ai',
  port: 4502,
  timeout: 3000,
  warehouse: 1,
  schema: 1
};

@UntilDestroy()
@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss']
})
export class ConfigDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly untitledName = 'Untitled Collage';
  public readonly untitledNameError = 'Name this collage';
  public readonly DatabaseType = DatabaseConnectionType;
  public readonly ImageManagerType = ImageManagerType;
  public readonly UserGroup = UserGroup;

  @ViewChild('contentBusinessPanel', { read: ElementRef })
  public contentBusinessPanel: ElementRef;

  @ViewChild('connectionName', { read: ElementRef, static: true })
  public connectionName: ElementRef;

  @ViewChild('dnSearch', { read: ElementRef, static: true })
  public dnSearch: ElementRef;

  @ViewChild('pmSearch', { read: ElementRef, static: true })
  public pmSearch: ElementRef;

  @ViewChild('bidSearch', { read: ElementRef, static: true })
  public bidSearch: ElementRef;

  @ViewChild('UploadFileComponent')
  public uploadFileComponent: UploadFileComponent;

  @ViewChild('element', { read: ElementRef, static: true })
  public collageNameInput: ElementRef;

  @ViewChild('approximatelyTime', { read: ElementRef, static: false })
  public approximatelyTimeMessage: ElementRef;

  public activeImageConnection: IImageConnection;

  public activeBusinessConnection: IBusinessConnection;

  private currentUser: User | null;
  public collageNamePlaceholder: string = this.untitledName;

  public tabIndex = 0;
  public imagesLoading = false;
  public initialRender = false;
  public isCSVUploading: boolean = false;
  public isCSVPreparing: boolean = false;
  public parsingBusinessDataId: string = null; // ID of parsing Business Data object, uses to cancel Parsing task
  public parsingProgress: number = 0;
  public parsingMessage: string = '';
  public parsingApproximatelyTime: number = 0;
  public parsingApproximatelyTimeMessage: string = '';
  private approximatelyTimer: any; // setTimeout handler
  private lastParsingPercent: number = -1;

  public databaseType: DatabaseConnectionType;
  public selectedCSVFile: string;
  public selectedCSVFileName: string;
  public csvData: Array<Array<any>>;
  public csvSettings: any;
  public csvEncoding: string = getUserDefaultEncoding().encoding;
  public csvPreviewHeight: number;
  public headers: Array<string> = [];
  public measureHeaders: Array<string> = [];
  public businessData: IBusinessData; // IParsedBusinessData;
  public totalRows: number;

  public filteredBIDHeaders$: Observable<Array<string>>;
  public filteredDisplayHeaders$: Observable<Array<string>>;
  public filteredMeasureHeaders$: Observable<Array<string>>;
  public businessConnectionFieldSearch: FormControl;
  public displayNameFieldSearch: FormControl;
  public primaryMetricFieldSearch: FormControl;

  public imageConnectionList$: Observable<Array<IImageConnection>>;
  public businessConnectionList$: Observable<Array<IBusinessConnection>>;

  public form: FormGroup;
  public businessConnectionField: string;
  public placeholderByBusinessConnectionField: {
    [businessConnectionField: string]: {
      images: Array<{ id: string; view: string; url: string }>;
      displayName: string | null;
    };
  } = {};

  private resizeObserver: ResizeObserver;

  public get imageForm(): FormGroup {
    return this.form.get('images') as FormGroup;
  }

  public get businessForm(): FormGroup {
    return this.form.get('business') as FormGroup;
  }

  public get combineForm(): FormGroup {
    return this.form.get('combine') as FormGroup;
  }

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    public dialogRef: MatDialogRef<ConfigDialogComponent, ICollage>,
    private confirmDialog: MatDialog,
    private dialogSelectEncoding: MatDialog,
    private fb: FormBuilder,
    private projectDataService: ProjectDataService,
    private authService: AuthService,
    private dataService: DataService,
    private demoData: DemoDataService,
    private renderer: Renderer2,
    private lookupTable: LookupTableService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentUserData: IUserData | null;
    }
  ) {}

  public ngOnInit(): void {
    this.setForm();
    this.imageConnectionList$ = this.projectDataService.getImageConnectionList().pipe(untilDestroyed(this));
    this.businessConnectionList$ = this.projectDataService.getBusinessConnectionList().pipe(untilDestroyed(this));
    this.currentUser = this.authService.currentUser;

    this.subscribeFilteredHeaders();
    this.subscribeCombineFields();

    this.resetImageSource();
    this.resetBusinessSource();

    this.resizeObserver = new ResizeObserver(entries => {
      this.updateBusinessPanelControls();
    });
  }

  ngOnDestroy() {
    this.csvData = null;
    this.selectedCSVFile = null;
    this.selectedCSVFileName = null;
    this.csvSettings = null;
    this.headers = null;
    this.form = null;
    this.resizeObserver.unobserve(this.contentBusinessPanel.nativeElement);
    this.resizeObserver = null;
    this.uploadFileComponent = null;
    this.activeImageConnection = null;
    this.activeBusinessConnection = null;
    this.businessConnectionFieldSearch = null;
    this.displayNameFieldSearch = null;
    this.primaryMetricFieldSearch = null;
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.contentBusinessPanel.nativeElement); // repaint CSV preview if container height changed
  }

  private setForm(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      images: this.fb.group(
        {
          connection: ['', Validators.required],
          type: ['', Validators.required],
          host: ['', Validators.required],
          port: ['', Validators.required],
          timeout: ['', Validators.required],
          path: ['', Validators.required],
          filter: ['', Validators.required]
        },
        {
          validators: []
        }
      ),
      business: this.fb.group(
        {
          connection: ['', Validators.required],
          database: ['', Validators.required],
          snowflake: this.fb.group({
            auth: [],
            user: [],
            password: [],
            host: [],
            port: [],
            timeout: [],
            warehouse: [],
            schema: []
          }),
          csv: this.fb.group({
            csvFile: []
          })
        },
        {
          validators: [businessConnectionValidator]
        }
      ),
      combine: this.fb.group({
        businessConnectionField: ['', Validators.required],
        displayNameField: ['', Validators.required],
        primaryMetricField: [''],
        defaultView: ['', Validators.required]
      })
    });

    this.businessConnectionFieldSearch = this.fb.control('');
    this.displayNameFieldSearch = this.fb.control('');
    this.primaryMetricFieldSearch = this.fb.control('');
  }

  private filterHeaders(headers: Array<string>, search: string): Array<string> {
    const filterValue = search.toLowerCase();
    return headers.filter(header => header.toLowerCase().includes(filterValue));
  }

  private subscribeFilteredHeaders(): void {
    this.filteredBIDHeaders$ = this.businessConnectionFieldSearch.valueChanges.pipe(
      startWith(''),
      map(search => (search ? this.filterHeaders(this.headers, search) : this.headers)),
      untilDestroyed(this)
    );
    this.filteredDisplayHeaders$ = this.displayNameFieldSearch.valueChanges.pipe(
      startWith(''),
      map((search: string) => (search ? this.filterHeaders(this.headers, search) : this.headers)),
      untilDestroyed(this)
    );
    this.filteredMeasureHeaders$ = this.primaryMetricFieldSearch.valueChanges.pipe(
      startWith(''),
      map((search: string) => (search ? this.filterHeaders(this.measureHeaders, search) : this.measureHeaders)),
      untilDestroyed(this)
    );
  }

  private subscribeCombineFields(): void {
    this.form
      .get('combine.businessConnectionField')
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(businessConnectionFieldStatus => {
        this.lookupTable.reset();
        this.imagesLoading = true;

        if (businessConnectionFieldStatus !== 'VALID') {
          this.imagesLoading = false;
          return;
        }
        this.businessConnectionField = this.form.get('combine.businessConnectionField').value;
        if (this.placeholderByBusinessConnectionField[this.businessConnectionField]) {
          this.imagesLoading = false;
          return;
        }
        const indexOfProdId = this.headers.indexOf(this.businessConnectionField);
        const rows = this.csvData;
        const uniqueProdIds = Array.from(new Set(rows.map(row => row[indexOfProdId])));
        const { dataAccessType } = this.activeImageConnection.settings;
        switch (dataAccessType) {
          case ImageManagerType.ColumbiaS7:
          case ImageManagerType.Nike:
          case ImageManagerType.CollagiaImageLibrary:
            this.lookupTable.createLookupTableForImagesDAM(
              uniqueProdIds,
              dataAccessType,
              this.activeBusinessConnection.id,
              true
            );
            break;
          default:
            this.demoData.getJSON(`${environment.leviteApiUrl}/api/OrbFilenames`).then(imageData => {
              this.lookupTable.createLookupTableForOrbImages(imageData, uniqueProdIds, true);
            });
        }
        this.lookupTable
          .getViewPlaceholderImages()
          .pipe(take(1), takeUntil(this.lookupTable.cancelled$), untilDestroyed(this))
          .subscribe({
            next: viewPlaceholderImages => {
              const displayNameControl = this.form.get('combine.displayNameField');
              this.placeholderByBusinessConnectionField[this.businessConnectionField] = {
                images: [],
                displayName: null
              };
              this.placeholderByBusinessConnectionField[this.businessConnectionField].images = viewPlaceholderImages;
              if (
                displayNameControl.valid &&
                this.placeholderByBusinessConnectionField[this.businessConnectionField].images.length > 0
              ) {
                const [image] = this.placeholderByBusinessConnectionField[this.businessConnectionField].images;
                const indexOfDisplayName = this.headers.indexOf(displayNameControl.value);
                const product = rows.find(row => row[indexOfProdId] === image.id);
                const name = product ? product[indexOfDisplayName] : null;
                this.placeholderByBusinessConnectionField[this.businessConnectionField].displayName = name;
              }
            },
            complete: () => {
              this.imagesLoading = false;
            }
          });
      });

    this.form
      .get('combine.displayNameField')
      .statusChanges.pipe(untilDestroyed(this))
      .subscribe(displayNameFieldStatus => {
        if (
          displayNameFieldStatus === 'VALID' &&
          this.placeholderByBusinessConnectionField[this.businessConnectionField]?.images.length > 0
        ) {
          const [image] = this.placeholderByBusinessConnectionField[this.businessConnectionField].images;
          const indexOfProdId = this.headers.indexOf(this.form.get('combine.businessConnectionField').value);
          const indexOfDisplayName = this.headers.indexOf(this.form.get('combine.displayNameField').value);
          const rows = this.csvData;
          const product = rows.find(row => row[indexOfProdId] === image.id);
          const name = product ? product[indexOfDisplayName] : null;
          this.placeholderByBusinessConnectionField[this.businessConnectionField].displayName = name;
        }
      });
  }

  public onTabChange(index: number) {
    this.initialRender = false;
    if (index === 1) {
      this.saveImageConnection();
    }
    if (index === 2) {
      this.saveBusinessConnection();
    }
  }

  public resetImageSource() {
    this.saveImageConnection(false);
    this.activeImageConnection = null;
    this.imageForm.reset();
    this.imageForm.enable();
  }

  public resetBusinessSource() {
    this.saveBusinessConnection(false);
    this.activeBusinessConnection = null;
    this.clearParsingMessage();
    this.businessForm.reset();
    this.businessForm.enable();
    this.onDatabaseSelectionChange(true);
  }

  public getDialogResult(): void {
    const name = this.form.get('name').value || this.untitledName;
    const { businessConnectionField, displayNameField, primaryMetricField, defaultView } = this.combineForm.value;
    this.dialogRef.close({
      id: uuid(),
      imageConnectionId: this.activeImageConnection.id,
      businessConnectionId: this.activeBusinessConnection.id,
      businessConnectionField,
      displayNameField,
      primaryMetricField: primaryMetricField || null,
      defaultView,
      metadata: {
        imageConnectionName: ConnectionUtils.getImageConnectionUrlPath(this.activeImageConnection),
        businessConnectionName: ConnectionUtils.getBusinessConnectionUrlPath(this.activeBusinessConnection),
        authorName: this.currentUser.displayName,
        name
      },
      owner: this.currentUser.uid,
      favorite: false,
      lastViewed: Timestamp.now(),
      created: Timestamp.now()
    });
  }

  public highlightUntitledCollageError(): void {
    this.collageNamePlaceholder = this.untitledNameError;
    this.renderer.addClass(this.collageNameInput.nativeElement, 'error');
    gsap.to(this.collageNameInput.nativeElement, {
      keyframes: {
        x: [15, -13, 11, -10, 6, -5, 2, 0]
      },
      duration: 1
    });
  }

  onImageManagerSelectionChange(resetConnectionName: boolean = false) {
    if (resetConnectionName) {
      this.imageForm.get('connection').reset();
    }
    const type = this.imageForm.get('type').value;
    switch (type) {
      case ImageManagerType.Nike: {
        this.imageForm.get('host').setValue(nikeImagesSourceMock.host);
        this.imageForm.get('port').setValue(nikeImagesSourceMock.port);
        this.imageForm.get('timeout').setValue(nikeImagesSourceMock.timeout);
        this.imageForm.get('path').setValue(nikeImagesSourceMock.path);
        this.imageForm.get('filter').setValue(nikeImagesSourceMock.filter);
        break;
      }
      case ImageManagerType.ColumbiaS7: {
        this.imageForm.get('host').setValue(columbiaImagesSourceMock.host);
        this.imageForm.get('port').setValue(columbiaImagesSourceMock.port);
        this.imageForm.get('timeout').setValue(columbiaImagesSourceMock.timeout);
        this.imageForm.get('path').setValue(columbiaImagesSourceMock.path);
        this.imageForm.get('filter').setValue(columbiaImagesSourceMock.filter);
        break;
      }
      case ImageManagerType.CollagiaImageLibrary: {
        this.imageForm.get('host').setValue(userImagesSourceMock.host);
        this.imageForm.get('port').setValue(userImagesSourceMock.port);
        this.imageForm.get('timeout').setValue(userImagesSourceMock.timeout);
        this.imageForm.get('path').setValue(userImagesSourceMock.path);
        this.imageForm.get('filter').setValue(userImagesSourceMock.filter);
        this.imageForm.disable();
        this.imageForm.get('connection').enable();
        break;
      }
      default: {
        this.imageForm.get('host').reset();
        this.imageForm.get('port').reset();
        this.imageForm.get('timeout').reset();
        this.imageForm.get('path').reset();
        this.imageForm.get('filter').reset();
      }
    }

    const control = this.businessForm.get('csv');
    if (this.databaseType === DatabaseConnectionType.CSV) {
      control.get('csvFile').setValidators([Validators.required]);
    } else {
      control.get('csvFile').clearValidators();
      control.reset();
    }
    this.form.updateValueAndValidity();
  }

  onDatabaseSelectionChange(resetConnectionName: boolean = false) {
    this.csvData = null;
    this.setHeaders();
    this.csvSettings = null;
    this.selectedCSVFile = null;
    this.selectedCSVFileName = null;
    this.businessForm.get('csv').get('csvFile').setValue(null);

    if (resetConnectionName) {
      this.businessForm.get('connection').reset();
    }
    this.databaseType = this.businessForm.get('database').value;

    let control = this.businessForm.get('snowflake');
    switch (this.databaseType) {
      case DatabaseConnectionType.CSV: {
        control.get('auth').clearValidators();
        control.get('user').clearValidators();
        control.get('password').clearValidators();
        control.get('host').clearValidators();
        control.get('port').clearValidators();
        control.get('timeout').clearValidators();
        control.get('warehouse').clearValidators();
        control.get('schema').clearValidators();
        control.reset();
        break;
      }
      default: {
        control.get('auth').setValidators([Validators.required]);
        control.get('user').setValidators([Validators.required]);
        control.get('password').setValidators([Validators.required]);
        control.get('host').setValidators([Validators.required]);
        control.get('port').setValidators([Validators.required]);
        control.get('timeout').setValidators([Validators.required]);
        control.get('warehouse').setValidators([Validators.required]);
        control.get('schema').setValidators([Validators.required]);
        break;
      }
    }

    control = this.businessForm.get('csv');
    if (this.databaseType === DatabaseConnectionType.CSV) {
      control.get('csvFile').setValidators([Validators.required]);
    } else {
      control.get('csvFile').clearValidators();
      control.reset();
    }
    this.form.updateValueAndValidity();
  }

  private loadParsedCSV() {
    this.csvData = null;
    this.setHeaders();
    this.csvSettings = null;
    if (this.selectedCSVFile) {
      this.isCSVPreparing = true;
      this.dataService.getBusinessData(this.activeBusinessConnection.businessDataId).subscribe(data => {
        this.totalRows = data.rowsTotal;
        this.setHeaders(data);
        this.addSelectionRequiredValidator();
        this.csvSettings = data.settings;
        this.dataService.getBusinessRows(this.activeBusinessConnection.businessDataId, 0, 100).subscribe(data2 => {
          this.isCSVPreparing = false;
          this.csvData = data2.rows;
        });
      });
    }
  }

  private parseCSV() {
    if (!this.activeBusinessConnection?.businessDataId && this.businessData?.id) {
      // previous CSV file has been parsed, but not saved for activeBusinessConnection
      // user selected another CSV file or Encoding, so previous parsed data must be deleted
      if (this.isCSVPreparing) this.stopParsingCSV();
      this.deleteParsedBusinessData(this.businessData.id);
    }

    this.csvData = null;
    this.setHeaders();
    this.csvSettings = null;
    if (this.selectedCSVFile) {
      this.isCSVPreparing = true;
      this.parsingBusinessDataId = null;
      this.parsingProgress = 0;
      this.parsingMessage = 'Parsing data file...';
      this.parsingApproximatelyTime = 0;
      this.parsingApproximatelyTimeMessage = '';
      this.lastParsingPercent = -1;

      this.dataService
        .createNewBusinessData(this.selectedCSVFile, this.csvEncoding, this.selectedCSVFileName)
        .pipe(take(1))
        .subscribe(
          result => {
            this.parsingBusinessDataId = result.id;
            this.dataService
              .parseBusinessDataFile(result.id)
              .pipe(take(1))
              .subscribe(
                (data: IBusinessData) => {
                  if (data.isFailed) {
                    const error = `Parsing file: Failed. ${data.errorMessage}`;
                    console.error(error);
                    if (data.errorMessage.indexOf('encoding is not supported') > 0) {
                      this.isCSVPreparing = false;
                      this.isCSVUploading = false;
                      this.parsingBusinessDataId = null; // cancel ony one time
                      this.clearParsingMessage();
                      this.stopApproximatelyTimeTicker();
                      // Encoding issue, try to select another Encoding
                      // set up Default encoding
                      this.csvEncoding = DefaultEncoding.encoding; // set UTF-8 by default
                      setUserDefaultEncoding(this.csvEncoding); // and save in the App Store
                      const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
                        width: '650px',
                        data: {
                          title: 'Encoding issue',
                          icon: 'error',
                          notes: `${data.errorMessage}.<br> Please select another Encoding and try to parse file again.`,
                          buttons: ['OK']
                        } as IConfirmDialogData
                      });
                      dialogRef.afterClosed().subscribe((result2: IConfirmDialogResult) => {
                        this.openEncodingDialog();
                      });
                    } else {
                      // some unknown error
                      this.stopParsingCSV();
                      this.deleteParsedBusinessData(data.id); // failed businessData must be deleted
                      this.snackBar.open(error, '', {
                        panelClass: 'error',
                        duration: 4000
                      });
                    }
                  } else {
                    console.log(`Parsing file: Done`);
                  }
                },
                error => {
                  this.stopApproximatelyTimeTicker();
                  console.error(error);
                  this.snackBar.open('Cannot parse Business Data file', '', {
                    panelClass: 'error',
                    duration: 3000
                  });
                  // result is null, something wrong happens, need to delete Uploaded file with wrong data
                  this.uploadFileComponent.deleteFileByFileRef(this.selectedCSVFile);
                }
              );
            this.getParsingStatus(result.id); // get Process status first time
          },
          error => {
            this.stopApproximatelyTimeTicker();
            console.error(error);
            this.snackBar.open('Cannot create Business Data for file', '', {
              panelClass: 'error',
              duration: 3000
            });
            // result is null, something wrong happens, need to delete Uploaded file with wrong data
            this.uploadFileComponent.deleteFileByFileRef(this.selectedCSVFile);
          }
        );
    }
  }

  getParsingStatus(businessDataID: string) {
    this.dataService
      .getBusinessDataProgress(businessDataID)
      .pipe(take(1), filter(Boolean))
      .subscribe(businessData => {
        if (businessData.isFailed) {
          this.stopApproximatelyTimeTicker();
          console.error(businessData.errorMessage);
          this.snackBar.open(`Cannot parse Business Data file: ${businessData.errorMessage}`, '', {
            panelClass: 'error',
            duration: 3000
          });
        } else {
          if (businessData.isParsing) {
            console.log(`=======> ${businessData.parsingPercent}`);
            this.parsingProgress = businessData.parsingPercent;
            this.parsingMessage = businessData.parsingMessage + '...';
            if (
              (businessData.rowsTotal > 0 && !this.approximatelyTimer) ||
              this.lastParsingPercent !== businessData.parsingPercent
            ) {
              this.lastParsingPercent = businessData.parsingPercent;
              // time not started yet so, start timer to show approximately time message
              const leftRows =
                businessData.parsingPercent == 0
                  ? businessData.rowsTotal
                  : Math.round(businessData.rowsTotal * ((100 - businessData.parsingPercent) / 100));
              this.startApproximatelyTimeTicker(leftRows);
            }
            setTimeout(() => {
              this.getParsingStatus(businessDataID); // wait for 2 sec and try again to get Process status
            }, 2000);
          } else {
            console.log(`=======> 100%`);
            this.stopApproximatelyTimeTicker();
            this.parsingProgress = 100;
            this.parsingMessage = 'Loading parsed data...';
            this.dataService
              .getBusinessData(businessDataID)
              .pipe(take(1))
              .subscribe(result => {
                this.businessData = result;
                this.totalRows = result.rowsTotal;
                this.setHeaders(result);
                this.addSelectionRequiredValidator();
                this.csvSettings = result.settings;

                if (result.headers.length === 1) {
                  this.snackBar.open('Seems like incorrect format', '', {
                    panelClass: 'error',
                    duration: 3000
                  });
                }

                this.parsingMessage = 'Loading first 100 rows...';
                this.dataService
                  .getBusinessRows(this.businessData.id, 0, 100)
                  .pipe(take(1))
                  .subscribe(result2 => {
                    this.clearParsingMessage();
                    this.isCSVPreparing = false;
                    this.csvData = result2.rows;

                    if (
                      this.businessForm.get('connection').value === null &&
                      this.businessForm.get('csv').get('csvFile').value !== null
                    ) {
                      const fileName = this.form.get('business').get('csv').get('csvFile').value[0].file.name;
                      this.businessForm.get('connection').setValue(deleteFileExtension(fileName));
                      this.businessForm.get('connection').markAsPristine();
                      this.connectionName.nativeElement.focus();
                    }
                  });
              });
          }
        }
      });
  }

  selectCSVFile(files: Array<IUploadedFile>) {
    console.log('>> selectCSVFile', files);
    this.isCSVUploading = true;
    this.selectedCSVFile = files[0].fileRef;
    this.selectedCSVFileName = files[0].fileName;
    this.clearParsingMessage();
    this.parsingBusinessDataId = null; // cancel ony one time
  }

  onDeleteFile($event: IUploadedFile) {
    if (!this.activeBusinessConnection?.businessDataId && !this.isCSVPreparing && this.businessData?.id) {
      // just uploaded CSV file deleted, because user clicked on "Delete file" button,
      // and this file not assigned yet to any Business Connection
      // so, when file deleted, the parsed data should be deleted too
      this.deleteParsedBusinessData(this.businessData.id);
    }
    this.stopParsingCSV();
    this.parsingBusinessDataId = null;
    this.csvData = null;
    this.setHeaders();
    this.csvSettings = null;
    this.isCSVUploading = false;
    this.selectedCSVFile = null;
    this.selectedCSVFileName = null;
    if (this.businessForm.get('connection').pristine) {
      this.businessForm.get('connection').reset();
    }
  }

  onFileUploaded($event: IUploadedFile) {
    console.log('>> onFileUploaded', $event);
    this.isCSVUploading = false;
    this.selectedCSVFile = $event.fileRef;
    this.selectedCSVFileName = $event.fileName;
    this.parseCSV();
  }

  selectImageCollection(imgConnection: IImageConnection) {
    this.saveImageConnection(false); // save changes for previous Connection
    this.activeImageConnection = imgConnection;
    this.imageForm.get('connection').setValue(imgConnection.metadata.name);
    this.imageForm.get('type').setValue(imgConnection.settings.dataAccessType);
    this.imageForm.get('host').setValue(imgConnection.settings.fields.host);
    this.imageForm.get('port').setValue(imgConnection.settings.fields.port);
    this.imageForm.get('timeout').setValue(imgConnection.settings.fields.timeout);
    this.imageForm.get('path').setValue(imgConnection.settings.fields.path);
    this.imageForm.get('filter').setValue(imgConnection.settings.fields.filter);
    if (this.activeImageConnection.owner === '*') {
      this.imageForm.disable();
    } else {
      this.imageForm.enable();
    }
  }

  selectBusinessConnection(businessConnection: IBusinessConnection) {
    this.saveBusinessConnection(false); // save changes for previous Connection
    this.activeBusinessConnection = businessConnection;
    this.businessForm.get('connection').setValue(businessConnection.metadata.name);
    this.businessForm.get('database').setValue(businessConnection.settings.databaseType);
    this.onDatabaseSelectionChange();

    switch (businessConnection.settings.databaseType) {
      case DatabaseConnectionType.CSV: {
        const fields = businessConnection.settings.fields as ICSVConnectionSettings;
        const file: any = [
          {
            file: fields.csvFile.file,
            fileName: fields.csvFile.fileName,
            fileRef: fields.csvFile.fileRef,
            isUploaded: true,
            progress: 100
          }
        ];
        this.businessForm.get('csv').get('csvFile').setValue(file);

        this.csvEncoding = fields.encoding || getUserDefaultEncoding().encoding;
        this.selectedCSVFile =
          fields.csvFile.fileRef || this.dataService.extractFileRefFromURL(fields.csvFile.uploadedURL);
        this.selectedCSVFileName = fields.csvFile.fileName;

        if (this.activeBusinessConnection.businessDataId) {
          this.loadParsedCSV();
        } else {
          this.parseCSV();
        }
        break;
      }
      default: {
        const fields = businessConnection.settings.fields as ISnowflakeSettings;
        const control = this.businessForm.get('snowflake');
        control.get('auth').setValue(fields?.auth || '');
        control.get('user').setValue(fields?.user || '');
        control.get('password').setValue(fields?.password || '');
        control.get('host').setValue(fields?.host || '');
        control.get('port').setValue(fields?.port || '');
        control.get('timeout').setValue(fields?.timeout || '');
        control.get('warehouse').setValue(fields?.warehouse || '');
        control.get('schema').setValue(fields?.schema || '');
        break;
      }
    }
    if (this.activeBusinessConnection.owner === '*') {
      this.businessForm.disable();
    } else {
      this.businessForm.enable();
    }
    this.prepareDataForCombine();
  }

  private getDataForLevite() {
    this.demoData.getJSON(`${environment.leviteApiUrl}/api/Levite`).then(data => {
      this.csvData = this.jsonArrayTo2D(data);
      this.setHeaders();
      this.addSelectionRequiredValidator();
    });
  }

  private saveImageConnection(isActive: boolean = true) {
    if (!this.imageForm.dirty || !this.imageForm.valid) {
      return; // don't save connection with wrong fields or if it was not changed
    }
    // get all fields for Image connection
    const metadata = {
      name: this.imageForm.get('connection').value,
      authorName: this.currentUser.displayName
    };
    const settings = {
      dataAccessType: this.imageForm.get('type').value,
      fields: {
        host: this.imageForm.get('host').value,
        port: this.imageForm.get('port').value,
        timeout: this.imageForm.get('timeout').value,
        path: this.imageForm.get('path').value,
        filter: this.imageForm.get('filter').value
      }
    };

    if (this.activeImageConnection && this.activeImageConnection.id) {
      // update Image connection
      this.activeImageConnection = {
        ...this.activeImageConnection,
        type: ConnectionType.Image,
        metadata,
        settings
      };
      if (isActive) {
        // set lastViewed=Now to show item in the Top
        this.activeImageConnection = {
          ...this.activeImageConnection,
          lastViewed: Timestamp.now()
        };
      }
      this.projectDataService.updateConnection(this.activeImageConnection.id, this.activeImageConnection as any);
    } else {
      // create Image connection
      const newImgConnection: IImageConnection = {
        id: uuid(),
        type: ConnectionType.Image,
        metadata,
        owner: this.currentUser.uid,
        created: Timestamp.now(),
        lastViewed: Timestamp.now(),
        settings,
        favorite: false
      };
      this.projectDataService.createConnection(newImgConnection).then(() => {
        this.activeImageConnection = newImgConnection;
      });
    }
  }

  private saveBusinessConnection(isActive: boolean = true) {
    if (!isActive && !this.activeBusinessConnection?.businessDataId && this.businessData?.id) {
      // some CSV file has been parsed, but not saved for activeBusinessConnection
      // user selected another businessConnection, so need to delete just parsed file
      // (a lot of unused data in Firebase database should be cleaned up)
      this.deleteParsedBusinessData(this.businessData.id);
    }

    if (!this.businessForm.dirty || !this.businessForm.valid) {
      return; // don't save connection with wrong fields or if it was not changed
    }
    // get all fields for Business connection
    const metadata = {
      name: this.businessForm.get('connection').value,
      authorName: this.currentUser.displayName
    };
    const databaseType = this.businessForm.get('database').value;
    let fields = null;
    if (databaseType !== DatabaseConnectionType.CSV) {
      fields = this.businessForm.get('snowflake').value;
    }
    if (databaseType === DatabaseConnectionType.CSV) {
      const uploadedFile = this.businessForm.get('csv').get('csvFile').value[0];
      fields = {
        csvFile: {
          file: {
            name: uploadedFile.file.name,
            size: uploadedFile.file.size,
            type: uploadedFile.file.type,
            lastModified: uploadedFile.file.lastModified
          },

          fileName: uploadedFile.fileName,
          fileRef: uploadedFile.fileRef // ref to delete from Firebase Storage
        },
        csvSettings: this.csvSettings,
        encoding: this.csvEncoding
      };
    }

    // add new or Update
    if (this.activeBusinessConnection && this.activeBusinessConnection.id) {
      // update Business connection
      delete fields.csvFile.fileRef; // don't need to update it
      this.activeBusinessConnection = {
        ...this.activeBusinessConnection,
        type: ConnectionType.Business,
        businessDataId: this.activeBusinessConnection?.businessDataId || this.businessData.id,
        metadata,
        settings: {
          databaseType,
          fields
        }
      };
      if (isActive) {
        // set lastViewed=Now to show item in the Top
        this.activeBusinessConnection = {
          ...this.activeBusinessConnection,
          lastViewed: Timestamp.now()
        };
      }
      this.projectDataService.updateConnection(this.activeBusinessConnection.id, this.activeBusinessConnection as any);
    } else {
      // create Business connection
      const newBusinessConnection: IBusinessConnection = {
        id: uuid(),
        type: ConnectionType.Business,
        businessDataId: this.businessData.id,
        metadata,
        owner: this.currentUser.uid,
        created: Timestamp.now(),
        lastViewed: Timestamp.now(),
        settings: {
          databaseType,
          fields
        },
        favorite: false
      };
      this.projectDataService.createConnection(newBusinessConnection).then(() => {
        this.activeBusinessConnection = newBusinessConnection;
        this.prepareDataForCombine();
      });
    }
  }

  prepareDataForCombine() {
    if (
      this.activeBusinessConnection.settings.databaseType !== DatabaseConnectionType.CSV &&
      (this.activeBusinessConnection.settings.fields as ISnowflakeSettings).host.includes(leviteBusinessSourceMock.host)
    ) {
      this.getDataForLevite();
    }
  }

  private jsonArrayTo2D(arrayOfObjects) {
    const header = [];
    const AoA = [];
    arrayOfObjects.forEach(obj => {
      // Ensure header includes all values in this object.
      // Previous rows will not have empty value in any new columns created
      Object.keys(obj).forEach(key => header.includes(key) || header.push(key));
      const thisRow = new Array(header.length);
      header.forEach((col, i) => {
        thisRow[i] = obj[col] || '';
      });
      AoA.push(thisRow);
    });
    AoA.unshift(header);
    return AoA;
  }

  private addSelectionRequiredValidator(): void {
    this.form.get('combine.businessConnectionField').reset();
    this.form.get('combine.displayNameField').reset();
    this.form.get('combine.primaryMetricField').reset();
    this.form
      .get('combine.businessConnectionField')
      .setValidators([Validators.required, selectionRequiredValidator(this.headers)]);
    this.form
      .get('combine.displayNameField')
      .setValidators([Validators.required, selectionRequiredValidator(this.headers)]);
    this.form.get('combine.primaryMetricField').setValidators([selectionRequiredValidator(this.measureHeaders)]);
    if (this.measureHeaders.length === 0) this.form.get('combine.primaryMetricField').disable();
    this.form.updateValueAndValidity();
  }

  private updateBusinessPanelControls() {
    const maxHeight = this.contentBusinessPanel.nativeElement.clientHeight;
    this.csvPreviewHeight = Math.max(maxHeight - 225, 200);
  }

  public selectEncoding(): void {
    if (this.isCSVUploading || this.isCSVPreparing) {
      this.snackBar.open('Wait for the end of parsing', '', {
        panelClass: 'info',
        duration: 3000
      });
      return;
    }
    if (this.activeBusinessConnection?.businessDataId) {
      const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        width: '650px',
        data: {
          title: 'Change encoding',
          icon: 'warning',
          notes:
            'You cannot change encoding for parsed file, because it could be already used in another collage. Try to upload the same file again and right after that select encoding.',
          buttons: ['OK']
        } as IConfirmDialogData
      });
      dialogRef.afterClosed().subscribe((result: IConfirmDialogResult) => {});
      return;
    }

    this.openEncodingDialog();
  }

  private openEncodingDialog() {
    const dialogRef = this.dialogSelectEncoding.open(SelectEncodingDialogComponent, {
      width: '550px',
      data: {
        encoding: this.csvEncoding
      }
    });
    dialogRef
      .afterClosed()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((data: ISelectEncodingDialogData) => {
        this.csvEncoding = data?.encoding || getUserDefaultEncoding().encoding;
        setUserDefaultEncoding(this.csvEncoding);
        this.businessForm.markAsDirty();
        this.parseCSV();
      });
  }

  private setHeaders(businessData?: IBusinessData | IParsedBusinessData): void {
    if (businessData) {
      this.headers = businessData.headers;
      this.measureHeaders = businessData.measures.map(item => item.name);
    } else {
      this.headers = [];
      this.measureHeaders = [];
    }
    this.businessConnectionFieldSearch.setValue('');
    this.displayNameFieldSearch.setValue('');
    this.primaryMetricFieldSearch.setValue('');
  }

  public changeDefaultView(view: string) {
    this.form.get('combine.defaultView').setValue(view);
  }

  protected readonly scrollIntoView = scrollIntoView;

  onDisplayNameOpen() {
    this.dnSearch.nativeElement.focus();
    this.filteredDisplayHeaders$.pipe(take(1), untilDestroyed(this)).subscribe(displayedHeaders => {
      const { value } = this.form.get('combine.displayNameField');
      if (displayedHeaders && value) {
        let index = displayedHeaders.findIndex(item => item === value);
        if (index < displayedHeaders.length) {
          index--;
        }
        if (index >= 0) {
          this.window.document.getElementById(`display-name-option-${index}`)?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  onPrimaryMetricOpen() {
    this.pmSearch.nativeElement.focus();
    this.filteredMeasureHeaders$.pipe(take(1), untilDestroyed(this)).subscribe(measureHeaders => {
      const { value } = this.form.get('combine.primaryMetricField');
      if (measureHeaders && value) {
        let index = measureHeaders.findIndex(item => item === value);
        if (index < measureHeaders.length) {
          index--;
        }
        if (index >= 0) {
          this.window.document.getElementById(`primary-metric-option-${index}`)?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  onBIDFieldOpen() {
    this.bidSearch.nativeElement.focus();
    this.filteredBIDHeaders$.pipe(take(1), untilDestroyed(this)).subscribe(displayedHeaders => {
      const { value } = this.form.get('combine.businessConnectionField');
      if (displayedHeaders && value) {
        let index = displayedHeaders.findIndex(item => item === value);
        if (index < displayedHeaders.length) {
          index--;
        }
        if (index >= 0) {
          this.window.document.getElementById(`bid-option-${index}`)?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  deleteParsedBusinessData(businessDataId: string) {
    this.dataService.deleteBusinessData(businessDataId).pipe(take(1)).subscribe();
  }

  // Run Count down to show how much time left when parser will be completed
  private startApproximatelyTimeTicker(rowsTotal: number) {
    this.stopApproximatelyTimeTicker();
    this.isCSVPreparing = true;
    this.parsingApproximatelyTime = (rowsTotal * 300 * 1000) / 180000; // 180k rows takes 5 min (5min = 300sec * 1000ms)
    this.approximatelyTimer = setInterval(() => {
      console.log('>>>> approximately time', this.parsingApproximatelyTime);
      this.parsingApproximatelyTime = this.parsingApproximatelyTime - 1000;
      if (this.parsingApproximatelyTime < 0) {
        this.stopApproximatelyTimeTicker();
      } else {
        const min = Math.floor(this.parsingApproximatelyTime / 1000 / 60);
        let sec: any = Math.floor(this.parsingApproximatelyTime / 1000 - min * 60);
        if (sec < 10) sec = `0${sec}`;
        this.parsingApproximatelyTimeMessage = `${min}:${sec}`;
        this.setApproximatelyTimeMessage(this.parsingApproximatelyTimeMessage);
      }
    }, 1000);
  }

  /**
   * Check - if file is Parsing, then need to Stop parse and delete business data
   */
  private stopParsingCSV() {
    const bid = this.parsingBusinessDataId;
    if (this.isCSVPreparing && bid) {
      this.clearParsingMessage();
      this.parsingBusinessDataId = null; // cancel ony one time

      this.dataService
        .cancelParsingBusinessData(bid)
        .pipe(take(1))
        .subscribe(() => {});
    }
    this.stopApproximatelyTimeTicker();
  }

  private stopApproximatelyTimeTicker() {
    if (this.approximatelyTimer) clearInterval(this.approximatelyTimer);
    this.approximatelyTimer = null;
    this.parsingApproximatelyTime = 0;
    this.parsingApproximatelyTimeMessage = '';
    this.setApproximatelyTimeMessage('');
  }

  private setApproximatelyTimeMessage(value: string) {
    console.log('>>>> approximately time', value);
    if (this.approximatelyTimeMessage) {
      this.renderer.setProperty(this.approximatelyTimeMessage.nativeElement, 'innerText', value);
    }
  }

  /**
   * Dialog closed by User, so all Tasks should be canceled
   */
  closeDialog() {
    // 1. if files loading or just loaded, delete all of them
    // will be ignored, if uploadFileComponent.isDisableDelete = true
    // 2. Also, deleted file will emit event onDeleteFile($event: IUploadedFile)
    // and if deleted file was parsing, the parsing process will be Canceled and businessData object - deleted
    this.uploadFileComponent?.deleteAllFiles();
    this.dialogRef.close();
  }

  /**
   * cleanup messages from previous Parsing
   */
  private clearParsingMessage() {
    this.parsingMessage = '';
    this.parsingProgress = 0;
    this.lastParsingPercent = -1;
  }
}
