import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@app/shared/utils/window';
import { SetAxesXY } from '@app/state/study/study.actions';
import { Store } from '@ngxs/store';
import { ExploreBarBase } from '../explore-bar-base.component';
import { ExploreBarType } from '../../../../models/filters.model';

@Component({
  selector: 'app-explore-bar-filtering',
  templateUrl: './explore-bar-filtering.component.html',
  styleUrls: ['./explore-bar-filtering.component.scss']
})
export class ExploreBarFilteringComponent extends ExploreBarBase implements OnInit {
  public readonly filterIdPrefix: ExploreBarType = 'filtering';

  constructor(
    protected override store: Store,
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override readonly cdr: ChangeDetectorRef
  ) {
    super(store, window, cdr);
  }

  protected setAxis() {
    this.store.dispatch(new SetAxesXY({ filtering: this.selectedFilters || null }));
  }
}
