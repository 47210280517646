<div class="explore-bar-grouping-container">
  <div class="filters-added">
    <app-adaptive-toolbar [orientation]="'top'" [canDragDrop]="true" (dropped)="onDrop($event)">
      <ng-template>
        <app-adaptive-toolbar-item
          class="show-bar-added-filter"
          #chip
          *ngFor="let filter of selectedFilters; let i = index"
          [matTooltip]="filter | filterTooltip : filtersToShow"
          matTooltipPosition="above"
          matTooltipClass="above"
          (click)="editFilterItem($event, filter)"
        >
          <div class="filter-name">
            <span>{{ filter.name | friendlyName }}</span>
          </div>
        </app-adaptive-toolbar-item>

        <button
          class="add"
          matTooltip="Add group"
          matTooltipPosition="above"
          matTooltipClass="above"
          matRipple
          #addIconRef
          (click)="addFilterItem($event)"
        >
          <svg-icon svgClass="icon" name="icon-show-add"></svg-icon>
        </button>
      </ng-template>
    </app-adaptive-toolbar>
  </div>

  <app-filter-box
    #filterBoxComponentRef
    [showFilterBox]="showFilterBox"
    [showBusinessFilters]="showBusinessFilters"
    [showVisualFilters]="showVisualFilters"
    [filtersToShow]="filtersToShow"
    [filtering]="filtering"
    [expandedFilterIndex]="expandedFilterIndex"
    [settings]="settings"
    [prefix]="filterIdPrefix"
    (resizing)="onResizeFilterBox($event)"
    (onToggleFilterType)="onToggleFilterType($event)"
    (onSelectAll)="onSelectAllCheckboxValueChange($event.filter, $event.event)"
    (onCheckboxChange)="onCheckboxValueChange($event.filter, $event.index, $event.event)"
    (onMinMaxChange)="onMinMaxValueChange($event.filter, $any($event.min), $any($event.max))"
    (onStringChange)="onStringValueChange($event.filter, $event.inputValue)"
  ></app-filter-box>
</div>
