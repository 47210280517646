import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { openCloseAnimation } from '@app/components/animations/animations';
import { IFiltersModel, FiltersTypeEnum, FilterDataType, ExploreBarType } from '@app/models/filters.model';
import { WINDOW } from '@app/shared/utils/window';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ResizeEvent } from 'angular-resizable-element';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IExploreBarSubComponentSettingsModel } from '@app/utils/explore-bar.utils';

export interface IResizeCorners {
  leftTop: boolean;
  rightTop: boolean;
  leftBottom: boolean;
  rightBottom: boolean;
}

export type IValidateResizeFunction = ($event: ResizeEvent) => boolean;

@UntilDestroy()
@Component({
  selector: 'app-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
  animations: [openCloseAnimation]
})
export class FilterBoxComponent implements OnInit, OnChanges {
  public readonly FiltersTypeEnum = FiltersTypeEnum;

  @Input() showFilterBox = false;
  @Input() showBusinessFilters = false;
  @Input() showVisualFilters = false;
  @Input() showActions: {
    clearAll: boolean;
    done: boolean;
  } = null; // not visible by default
  @Input() filtersToShow: Array<IFiltersModel> = [];
  @Input() filtering: Array<IFiltersModel> = [];
  @Input() expandedFilterIndex = 0;
  @Input() settings: IExploreBarSubComponentSettingsModel;
  @Input() prefix: ExploreBarType;
  @Input() resizeCorners: IResizeCorners = {
    leftTop: true,
    rightTop: true,
    leftBottom: false,
    rightBottom: false
  };
  @Input() validateResize: IValidateResizeFunction = this.onValidateResize.bind(this);

  @Output() resizing = new EventEmitter<FilterBoxComponent>();
  @Output() onToggleFilterType = new EventEmitter<FilterDataType>();
  @Output() onSelectAll = new EventEmitter<{ filter: IFiltersModel; event: any }>();
  @Output() onClearAll = new EventEmitter<{ filter: IFiltersModel; event: any }>();
  @Output() onDone = new EventEmitter<{ filter: IFiltersModel; event: any }>();
  @Output() onCheckboxChange = new EventEmitter<{ filter: IFiltersModel; index: number; event: any }>();
  @Output() onMinMaxChange = new EventEmitter<{
    filter: IFiltersModel;
    min: number | Date;
    max: number | Date;
  }>();
  @Output() onStringChange = new EventEmitter<{ filter: IFiltersModel; inputValue: string }>();

  @ViewChild('filterBoxRef', { static: true })
  public readonly filterBoxRef: ElementRef;

  public filterSearchValue: string;

  public minHeight: number = 200;
  public maxHeight: number = 800;
  public minWidth: number = 250;
  public maxWidth: number = 800;

  public currentHeight: number = 420;
  public currentWidth: number = 345;

  public isResizing: boolean = false;

  public onSearchInput$: Subject<string> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    this.updateMinMaxBounds($event.target.innerWidth, $event.target.innerHeight);
    this.updateCurrentBounds(this.currentWidth, this.currentHeight);
  }

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private cdr: ChangeDetectorRef
  ) {
    this.updateMinMaxBounds(this.window.innerWidth, this.window.innerHeight);
    this.updateCurrentBounds(this.currentWidth, this.currentHeight);
  }

  ngOnInit(): void {
    this.onSearchInput$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(value => {
      this.filterSearchValue = value;
      this.expandedFilterIndex = null;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expandedFilterIndex) {
      this.expandFilter(this.expandedFilterIndex, true);
    }
  }

  public expandFilter(i: number, isMustExpand: boolean = false) {
    if (!isMustExpand) {
      this.expandedFilterIndex = this.expandedFilterIndex === i ? null : i;
    }
    if (this.expandedFilterIndex) {
      setTimeout(() => {
        const node = this.window.document.getElementById(`${this.prefix}-filter-item-${this.expandedFilterIndex}`);
        if (node) {
          scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start' });
        }
      });
    }
  }

  onResizeStart($event: ResizeEvent) {
    this.isResizing = true;
    this.resizing.emit(this);
  }

  onResizeEnd($event: ResizeEvent) {
    this.isResizing = false;
    this.resizing.emit(this);
  }

  onResizing($event: ResizeEvent) {
    this.updateCurrentBounds($event.rectangle.width, $event.rectangle.height);
    this.resizing.emit(this);
  }

  onValidateResize($event: ResizeEvent): boolean {
    const newWidth = $event.rectangle.width;
    const newHeight = $event.rectangle.height;
    const left = this.filterBoxRef.nativeElement.offsetLeft;
    if (
      newWidth > this.maxWidth ||
      newWidth < this.minWidth ||
      newHeight > this.maxHeight ||
      newHeight < this.minHeight ||
      left + newWidth > this.window.innerWidth - 10 ||
      left < 38
    ) {
      return false;
    }
    return true;
  }

  private updateMinMaxBounds(windowWidth: number, windowHeight: number): void {
    this.maxHeight = windowHeight - 200;
    this.maxWidth = windowWidth - 300;
    this.maxHeight = Math.max(this.maxHeight, this.minHeight);
    this.maxWidth = Math.max(this.maxWidth, this.minWidth);
  }

  private updateCurrentBounds(newWidth: number, newHeight: number) {
    this.currentHeight = Number(newHeight);
    this.currentHeight = Math.min(this.currentHeight, this.maxHeight);
    this.currentHeight = Math.max(this.currentHeight, this.minHeight);

    this.currentWidth = Number(newWidth);
    this.currentWidth = Math.min(this.currentWidth, this.maxWidth);
    this.currentWidth = Math.max(this.currentWidth, this.minWidth);
  }

  public filtersTrackBy(index: number, item: IFiltersModel): string {
    return item.name;
  }

  onSearchKeyUp($event: any /*KeyboardEvent*/) {
    this.onSearchInput$.next($event.target.value);
  }
}
