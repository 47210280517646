import { ValidMatrix } from '@app/state/tools/tools.model';

export interface IMatrixIcon {
  name: string; // title
  iconName: string; // svg icon name
  value: ValidMatrix;
}

export const matrixIcons: IMatrixIcon[] = [
  {
    name: 'Pareto',
    iconName: 'icon-matrix-pareto',
    value: ValidMatrix.Pareto
  },
  {
    name: 'Freestyle',
    iconName: 'icon-matrix-manual-harvest',
    value: ValidMatrix.Freestyle
  },
  {
    name: 'GroupBy',
    iconName: 'icon-matrix-group-by',
    value: ValidMatrix.GroupBy
  },
  {
    name: 'Rank',
    iconName: 'icon-matrix-top-n',
    value: ValidMatrix.Rank
  }
  // {
  //   name: 'Bottom N',
  //   iconName: 'icon-matrix-bottom-n',
  //   value: ValidMatrix.RankBottom
  // },
  // {
  //   name: 'Top N%',
  //   iconName: 'icon-matrix-top-n_',
  //   value: ValidMatrix.RankPercent
  // },
  // {
  //   name: 'Bottom N%',
  //   iconName: 'icon-matrix-bottom-n_',
  //   value: ValidMatrix.RankPercentBottom
  // }
];

export const iconsMap = new Map(matrixIcons.map(icon => [icon.value, icon]));
