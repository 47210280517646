import { ICollage } from '@app/state/collage/collage.model';

export class LoadCollage {
  static readonly type = '[Collage] Load Collage';
  constructor(public collageId: string) {}
}

export class ResetActiveCollage {
  static readonly type = '[Collage] Reset Active Collage';
}

export class SetActiveCollage {
  static readonly type = '[Collage] Set Active Collage';
  constructor(public collage: ICollage | null, public isCreateNewStudy: boolean = false) {}
}

export class SetActiveCollageDisplayNameField {
  static readonly type = '[Collage] Set Display Name Field';
  constructor(public name: string) {}
}

export class CreateCollage {
  static readonly type = '[Collage] Create Collage';
  constructor(public collage: ICollage, public isCreateNewStudy: boolean = false) {}
}
