import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  @Input() label;
  @Input() min;
  @Input() max;
  @Input() value;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  public updateValue($event: any) {
    const val = $event.target.value;
    this.value = parseInt(val, 10);
    this.valueChange.emit(this.value);
  }
}
