import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { shortUid } from '../../../shared/utils/short-uid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '../../../shared/utils/window';
import { take } from 'rxjs/operators';
import { ResetActiveStudy } from '../../../state/study/study.actions';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-study-synchronizer',
  template: '<div></div>',
  styles: []
})
export class StudySynchronizerComponent {
  private studyID: string; // current active study
  private tabID: string; // current browser tabID - to identify is it local Study changes, or from other Browser Tab?
  private isNeedRefreshStudy: boolean;

  // Open and Listen channel about changing Study in OTHER BROWSER TABS
  // If user changed active Study somewhere, other tabs should catch this last active Study and show it in every Tab
  private broadcastChannel: BroadcastChannel = new BroadcastChannel('VXA_Current_Study');

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.tabID = shortUid();
    this.subscribeToActiveStudy();
    this.subscribeToDocumentFocus();
    this.subscribeToWindowsFocus();
    this.broadcastChannel.addEventListener('message', this.listenChangeStudyID.bind(this));
  }

  private subscribeToActiveStudy() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(data => {
      this.studyID = data.studyId;
      this.sendNewStudyBroadcastMessage();
    });
  }

  private sendNewStudyBroadcastMessage() {
    this.broadcastChannel.postMessage({ studyID: this.studyID, tabID: this.tabID });
  }

  // Check if Study has been changed, then mark as need to reload Study for last changes
  private listenChangeStudyID({ data }) {
    if (data.studyID === this.studyID && data.tabID !== this.tabID) {
      this.isNeedRefreshStudy = true;
    }
  }
  private subscribeToDocumentFocus() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.refreshStudy();
      }
    });
  }

  private subscribeToWindowsFocus() {
    this.window.onfocus = () => {
      this.refreshStudy();
    };
  }

  refreshStudy() {
    if (this.isNeedRefreshStudy) {
      this.isNeedRefreshStudy = false;
      this.store
        .dispatch(new ResetActiveStudy())
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['/build'], { queryParams: { studyId: this.studyID, query: shortUid() } });
        });
    }
  }
}
