<div class="content" *ngIf="isMenuOpen">
  <div class="filters-list-container">
    <div class="search-bar">
      <input type="text" #filter (keyup)="onFiltersSearchKeyUp(filter.value)" />
      <svg-icon svgClass="search-icon" name="icon-user-search"></svg-icon>
    </div>

    <div
      id="filtersList"
      class="filters-list scrollable"
      [class.hide-drop-placeholder]="draggingStartContainer?.id !== 'filtersList'"
      cdkDropList
      cdkDropListSortingDisabled
      [cdkDropListConnectedTo]="dls"
      [cdkDropListData]="filtersList"
      (cdkDropListDropped)="onFieldItemDrop($event)"
      (cdkDropListExited)="onDropListExited($event)"
      (cdkDropListEntered)="onDropListEntered()"
    >
      <div
        class="chip"
        cdkDrag
        cdkDragPreviewClass="chip-preview"
        cdkDragBoundary=".quick-stats-sub-menu"
        [cdkDragData]="null"
        matTooltip="Custom Calc"
        matTooltipPosition="above"
        matTooltipClass="above"
        (cdkDragStarted)="onDragStarted($event)"
      >
        <div class="chip-header">
          <div class="filter-icon">
            <svg-icon svgClass="icon" name="icon-hover-info-config-plus"></svg-icon>
          </div>
          <p>Custom Calc</p>
        </div>
      </div>
      <div
        class="chip"
        [class.cdk-drag-placeholder]="isHighlightSource(filter)"
        *ngFor="let filter of filtersList | searchByName : filterSearchValue; let i = index"
        cdkDrag
        cdkDragPreviewClass="chip-preview"
        cdkDragBoundary=".quick-stats-sub-menu"
        [cdkDragData]="filter"
        [cdkDragDisabled]="filter.dataType === 'date'"
        [matTooltip]="filterTooltipValue"
        matTooltipPosition="above"
        matTooltipClass="above"
        (mouseover)="onFilterMouseOver(textRef)"
        (cdkDragEntered)="onDragEntered($event)"
        (cdkDragStarted)="onDragStarted($event)"
        [class.expanded]="i === expandedFiltersListChipIndex"
      >
        <div class="chip-header" (click)="onFiltersListChipClick(filter, i, $event)">
          <div class="filter-icon">
            <svg-icon svgClass="icon" [name]="filter | filterTypeIcon"></svg-icon>
            <div *ngIf="filter.isMeasure && isChipFormatted(filter)" class="formatting-indicator"></div>
          </div>
          <p #textRef>{{ filter.name | friendlyName }}</p>
        </div>
        <div class="chip-formatting" *ngIf="i === expandedFiltersListChipIndex">
          <div class="chip-formatting-tools">
            <div class="chip-formatting-tools-prefix">
              <mat-form-field>
                <mat-label>Prefix</mat-label>
                <mat-select
                  [value]="formattings[filter.name]?.prefix"
                  (selectionChange)="onFormattingPrefixChange($event)"
                >
                  <mat-option class="chip-formatting-option" selected>None</mat-option>
                  <mat-option class="chip-formatting-option" *ngFor="let prefix of formattingPrefixes" [value]="prefix">
                    {{ prefix }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="chip-formatting-tools-suffix">
              <mat-form-field>
                <mat-label>Suffix</mat-label>
                <mat-select
                  [value]="formattings[filter.name]?.suffix"
                  (selectionChange)="onFormattingSuffixChange($event)"
                >
                  <mat-option class="chip-formatting-option" selected>None</mat-option>
                  <mat-option class="chip-formatting-option" *ngFor="let suffix of formattingSuffixes" [value]="suffix">
                    {{ suffix }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="chip-formatting-tools-fraction">
              <p>Round</p>
              <div>
                <svg-icon
                  svgClass="icon"
                  name="icon-chip-formatting-arrow"
                  (click)="onFormattingFractionLeft()"
                  [class.disabled]="isFormattingFractionLeftDisabled()"
                ></svg-icon>
                <div>
                  <p>{{ getFractionPickerDisplayNumber() }}</p>
                  <span [style.left.px]="getFormattingIndicatorLeftPos()"></span>
                </div>
                <svg-icon
                  svgClass="icon"
                  name="icon-chip-formatting-arrow"
                  (click)="onFormattingFractionRight()"
                  [class.disabled]="isFormattingFractionRightDisabled()"
                ></svg-icon>
              </div>
            </div>
          </div>
          <div class="chip-formatting-display">
            <p>Display:</p>
            <p>{{ getFormattedDisplayNumber() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="fieldsConfig"
    class="fields-config scrollable"
    cdkDropList
    [cdkDropListConnectedTo]="dls"
    [cdkDropListData]="infoFieldsList"
    [cdkDropListDisabled]="isDragCalcGroupStarted"
    (cdkDropListDropped)="onFieldItemDrop($event)"
  >
    <div class="build">
      <div class="config-item mandatory">
        <p>Frame Title / Image Name</p>
      </div>
      <ng-container *ngFor="let field of infoFieldsList">
        <div
          class="config-item"
          [class.calc]="field.type === FiltersTypeEnum.Calculated"
          [class.can-be-complex]="!field.isMeasure && field.type !== FiltersTypeEnum.Calculated"
          cdkDrag
          cdkDragPreviewClass="config-item-preview"
          [cdkDragData]="field"
          [cdkDragDisabled]="field.isEditingTitle"
          cdkDragBoundary=".quick-stats-sub-menu"
          (cdkDragEntered)="onDragEntered($event)"
          (cdkDragStarted)="onDragStarted($event)"
        >
          <div class="field-container" *ngIf="field.type !== FiltersTypeEnum.Calculated">
            <ng-container *ngIf="field.isMeasure">
              <ng-container *ngTemplateOutlet="templateNumericField; context: { field: field }"></ng-container>
            </ng-container>

            <!-- Text field with children (complex field) -->
            <ng-container *ngIf="!field.isMeasure">
              <div
                class="complex-drop-items"
                [class.empty]="field.children?.length === 0"
                [class.dragging-child]="isDragNumericalStarted && field.children && draggingField === field.children[0]"
                [class.invalid]="field.isInvalid"
                cdkDropList
                cdkDropListOrientation="horizontal"
                [cdkDropListConnectedTo]="dls"
                [cdkDropListData]="field.children"
                [cdkDropListDisabled]="isNonMeasureDropListDisabled(field)"
                (cdkDropListDropped)="onFieldItemDrop($event)"
              >
                <div
                  *ngIf="
                    !field.isDistinctCount &&
                      field.children &&
                      (field.children.length > 0 ||
                        ((isDragNumericalStarted || isDragCalcStarted) &&
                          !isDragCalcGroupStarted &&
                          (field.children.length === 0 || draggingField === field.children[0])));
                    else nameWithMenu
                  "
                  class="field-name"
                >
                  <p>{{ field.name | friendlyName }}</p>
                </div>
                <ng-template #nameWithMenu>
                  <ng-container *ngTemplateOutlet="templateStringField; context: { field }"></ng-container>
                </ng-template>
                <div class="complex-children" *ngIf="field.children?.length > 0">
                  <div
                    *ngFor="let child of field.children"
                    class="complex-child"
                    cdkDrag
                    [cdkDragData]="child"
                    (cdkDragEntered)="onDragEntered($event)"
                    (cdkDragStarted)="onDragStarted($event)"
                  >
                    <ng-container *ngTemplateOutlet="templateNumericField; context: { field: child }"></ng-container>
                  </div>
                </div>

                <div
                  class="complex-list-container"
                  *ngIf="
                    (isDragNumericalStarted || isDragCalcStarted) &&
                    !isDragCalcGroupStarted &&
                    !field.isDistinctCount &&
                    field.children &&
                    (field.children.length === 0 || draggingField === field.children[0])
                  "
                >
                  <div class="child-placeholder complex-drop-area">
                    <svg-icon svgClass="icon" name="icon-hover-info-config-plus"></svg-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="field-container" *ngIf="field.type === FiltersTypeEnum.Calculated">
            <div class="input-title" [class.has-error]="field.isInvalid">
              <div class="title-panel">
                <input
                  #editTitle
                  type="text"
                  [(ngModel)]="field.name"
                  (mousedown)="field.isEditingTitle = false; editTitle.focus(); $event.stopPropagation()"
                  (mouseleave)="field.isEditingTitle = false"
                  (keyup)="checkCalcForErrors()"
                />
                <div class="add-calc-group-btn" (click)="addCustomCalcGroup(field)">
                  <svg-icon svgClass="icon" name="icon-hover-info-config-calc-group"></svg-icon>
                </div>
              </div>
              <div class="mat-error" *ngIf="field.errors?.nameRequired">Name is required</div>
              <div class="mat-error" *ngIf="field.errors?.equalName">Field name must be unique</div>
              <div class="mat-error" *ngIf="field.errors?.needArgument && field.calcArguments.length < 2">
                2 measures minimum
              </div>
            </div>

            <div
              class="custom-calc-item"
              [class.zero-arguments]="field.calcArguments.length === 0"
              cdkDropList
              [cdkDropListConnectedTo]="dls"
              [cdkDropListData]="field.calcArguments"
              (cdkDropListDropped)="onFieldItemDrop($event)"
              [cdkDropListDisabled]="isDragCalcStarted"
            >
              <div class="operations-list">
                <div
                  *ngFor="let innerItem of field.calcArguments; let isLast = last"
                  class="argument"
                  [class.calc-group]="innerItem.isCalcGroup"
                  cdkDrag
                  cdkDragPreviewClass="calc-group-preview"
                  [cdkDragData]="innerItem"
                  (cdkDragEntered)="onDragEntered($event)"
                  (cdkDragStarted)="onDragStarted($event)"
                >
                  <ng-container *ngIf="!innerItem.isCalcGroup">
                    <ng-container
                      *ngTemplateOutlet="templateNumericField; context: { field: innerItem, isHideMinMax: true }"
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngIf="innerItem.isCalcGroup">
                    <div class="calc-group-wrapper">
                      <div class="calc-group-title">(&nbsp;)</div>
                      <div
                        class="calc-group-items operations-list"
                        [class.zero-arguments]="innerItem.calcArguments.length === 0"
                        [class.one-argument]="innerItem.calcArguments.length === 1"
                        cdkDropList
                        [cdkDropListConnectedTo]="dls"
                        [cdkDropListData]="innerItem.calcArguments"
                        (cdkDropListDropped)="onFieldItemDrop($event)"
                        [cdkDropListDisabled]="isDragCalcStarted"
                      >
                        <div class="mat-error" *ngIf="innerItem.calcArguments.length < 2">2 measures minimum</div>
                        <div
                          *ngFor="let innerGroupItem of innerItem.calcArguments; let isLastInGroup = last"
                          class="argument"
                          cdkDrag
                          cdkDragPreviewClass="calc-group-item-preview"
                          [cdkDragData]="innerGroupItem"
                          (cdkDragEntered)="onDragEntered($event)"
                          (cdkDragStarted)="onDragStarted($event)"
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              templateNumericField;
                              context: { field: innerGroupItem, isHideMinMax: true }
                            "
                          ></ng-container>

                          <mat-select
                            *ngIf="!isLastInGroup && !isDragNumericalStarted"
                            class="calc-operation"
                            [(value)]="innerGroupItem.calcOperation"
                            panelClass="calc-type-selector"
                          >
                            <mat-option value="+">+</mat-option>
                            <mat-option value="-">-</mat-option>
                            <mat-option value="*">*</mat-option>
                            <mat-option value="/">/</mat-option>
                          </mat-select>
                        </div>

                        <!-- Placeholder will be hidden by CSS roles when some dragging argument hover on Calc field -->
                        <div class="argument-placeholder" *ngIf="innerItem.calcArguments.length <= 1">
                          <svg-icon svgClass="icon" name="icon-hover-info-config-plus"></svg-icon>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <mat-select
                    *ngIf="!isLast && !isDragNumericalStarted"
                    class="calc-operation"
                    [(value)]="innerItem.calcOperation"
                    panelClass="calc-type-selector"
                  >
                    <mat-option value="+">+</mat-option>
                    <mat-option value="-">-</mat-option>
                    <mat-option value="*">*</mat-option>
                    <mat-option value="/">/</mat-option>
                  </mat-select>
                </div>
              </div>

              <!-- Placeholder will be hidden by CSS roles when some dragging argument hover on Calc field -->
              <div class="argument-placeholder" *ngIf="field.calcArguments.length === 0">
                <svg-icon svgClass="icon" name="icon-hover-info-config-plus"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="config-item plus" *ngIf="infoFieldsList.length === 0">
        <svg-icon svgClass="icon" name="icon-hover-info-config-plus"></svg-icon>
      </div>
    </div>
  </div>
  <div class="fields-config last-column">
    <div *ngIf="(matrixType$ | async) === 'rank'" class="rank-info-config">
      <mat-radio-group [(ngModel)]="rankInfoShowState" (change)="onRankInfoShowStateChange($event)">
        <mat-radio-button value="hover">On Hover</mat-radio-button>
        <mat-radio-button value="canvas">On Canvas</mat-radio-button>
      </mat-radio-group>

      <div class="rank-info-position" *ngIf="rankInfoShowState === 'canvas'">
        <p
          [class.active]="rankInfoShowPosition === InfoPanelPositionEnum.Left"
          (click)="onRankInfoPositionToggle(InfoPanelPositionEnum.Left)"
        >
          Left
        </p>
        <p
          [class.active]="rankInfoShowPosition === InfoPanelPositionEnum.Right"
          (click)="onRankInfoPositionToggle(InfoPanelPositionEnum.Right)"
        >
          Right
        </p>
        <p
          [class.active]="rankInfoShowPosition === InfoPanelPositionEnum.Bottom"
          (click)="onRankInfoPositionToggle(InfoPanelPositionEnum.Bottom)"
        >
          Bottom
        </p>
      </div>
    </div>
    <div class="preview-wrapper scrollable">
      <div class="preview">
        <div class="field">
          <p class="title">Title</p>
        </div>
        <div class="field" *ngFor="let field of infoFieldsList" [class.invalid]="field.isInvalid">
          <ng-container *ngIf="field.children?.length > 0; else hasNoChildren">
            <div class="field-name">
              {{ field.name | friendlyName }} ({{ field.children[0].name | friendlyName }},
              {{ field.children[0].aggregationType }}):
            </div>
            <div class="field-values-list" [innerHTML]="getPreviewFieldValue(field)"></div>
          </ng-container>
          <ng-template #hasNoChildren>
            <ng-container *ngIf="field.isDistinctCount; else simpleValue">
              <div class="field-name">{{ field.name | friendlyName }}:</div>
              <div class="field-values-list" [innerHTML]="getPreviewFieldValue(field)"></div>
            </ng-container>
            <ng-template #simpleValue>
              <div class="field-name">{{ field.name | friendlyName }}:</div>
              <div
                class="field-value"
                [class.invalid]="field.isInvalid"
                [innerHTML]="getPreviewFieldValue(field)"
              ></div>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="invalid-notes" *ngIf="isHasAnyInvalidField">* Invalid fields will not be saved</div>
  </div>
</div>

<ng-template #templateNumericField let-field="field" let-isHideMinMax="isHideMinMax" let-isArgument="isArgument">
  <div class="field-name">
    <mat-select
      [(value)]="field.aggregationType"
      (selectionChange)="onAggregationTypeChange(field)"
      panelClass="aggregation-type-selector"
      *ngIf="field.isMeasure || field.calcArguments"
    >
      <mat-option value="Avg">Avg</mat-option>
      <mat-option value="Sum">Sum</mat-option>
      <mat-option value="Median">Median</mat-option>
      <ng-container *ngIf="!isHideMinMax">
        <mat-option value="Min/Max">Min/Max</mat-option>
      </ng-container>
    </mat-select>
    <div class="distinct-type" *ngIf="!field.isMeasure && !field.calcArguments && field.isDistinctCount">Distinct</div>
    <p>{{ field.name | friendlyName }}</p>
  </div>
</ng-template>

<ng-template #templateStringField let-field="field">
  <div class="field-name">
    <mat-select
      [(value)]="!!field.isDistinctCount"
      (selectionChange)="onAggregationTypeChange(field)"
      panelClass="aggregation-type-selector"
    >
      <mat-option [value]="false">&nbsp;&nbsp;---&nbsp;&nbsp;</mat-option>
      <mat-option [value]="true">Distinct</mat-option>
    </mat-select>
    <p>{{ field.name | friendlyName }}</p>
  </div>
</ng-template>
