import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BrowserTracing } from '@sentry/tracing';
import { installCDKPatch } from '@app/cdk-patch';
import { UnsupportedBrowserModule } from '@app/shared/components/unsupported-browser/unsupported-browser.module';

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    release: `collagia@${environment.version}`,
    integrations: [
      new BrowserTracing({
        tracingOrigins: environment.sentry.tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate
  });
}

if (environment.production) {
  enableProdMode();
}

const agent = window.navigator.userAgent.toLowerCase();
const isChrome = agent.indexOf('chrome') > -1 && agent.indexOf('edg') === -1 && !!(<any>window).chrome;

platformBrowserDynamic()
  .bootstrapModule(isChrome ? AppModule : UnsupportedBrowserModule)
  .catch(err => console.error(err));

installCDKPatch();
