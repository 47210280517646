import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { SetParetoColumnSize } from '@app/state/study/study.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-pareto-column-size-picker',
  templateUrl: './pareto-column-size-picker.component.html',
  styleUrls: ['./pareto-column-size-picker.component.scss']
})
export class ParetoColumnSizePickerComponent implements OnInit {
  @ViewChild('columnSizeInput')
  private readonly columnSizeInputRef: ElementRef;

  minColumnSize;
  maxColumnSize;
  currColumnSize;

  constructor(private store: Store, private matrixService: MatrixService) {}

  ngOnInit(): void {
    this.matrixService.paretoColumnSizeSubject
      .pipe(untilDestroyed(this))
      .subscribe((data: { currentSize: number; minSize: number; maxSize: number }) => {
        this.currColumnSize = data.currentSize;
        this.minColumnSize = data.minSize;
        this.maxColumnSize = data.maxSize;
      });
  }

  onColumnSizeChange() {
    if (!this.columnSizeInputRef) return;
    this.currColumnSize = parseInt(this.columnSizeInputRef.nativeElement.value.trim(), 10) || 0;
    if (this.currColumnSize < this.minColumnSize) this.currColumnSize = this.minColumnSize;
    if (this.currColumnSize > this.maxColumnSize) this.currColumnSize = this.maxColumnSize;
    this.columnSizeInputRef.nativeElement.value = this.currColumnSize;
    this.store.dispatch(new SetParetoColumnSize(this.currColumnSize));
  }
}
