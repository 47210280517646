import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetSelectedTool,
  SetSelectedView,
  SetToolboxStateByMatrixType,
  SetToolboxState,
  RestoreSelectedView,
  ResetToolboxState,
  StartMatrixHighlighter,
  StopMatrixHighlighter
} from './tools.actions';
import { MatrixToolEnum, ToolboxModel, IToolsStateModel, ValidMatrix } from '@app/state/tools/tools.model';
import { Injectable } from '@angular/core';

const initialState: IToolsStateModel = {
  activeMatrixHighlight: false, // if true, current Matrix catch Mouse events to select images for Highlighted list
  selectedTool: MatrixToolEnum.Cursor,
  selectedView: null,
  toolbox: {
    [MatrixToolEnum.Highlighter]: undefined,
    [MatrixToolEnum.Cursor]: undefined,
    [MatrixToolEnum.HoverInfoConfig]: undefined,
    [MatrixToolEnum.Magnify]: undefined,
    [MatrixToolEnum.Image]: undefined,
    [MatrixToolEnum.Frame]: undefined,
    [MatrixToolEnum.PresentationMode]: undefined
  }
};

@State<IToolsStateModel>({
  name: 'Tools',
  defaults: initialState
})
@Injectable()
export class ToolsState {
  @Selector([ToolsState])
  static getSelectedTool(state: IToolsStateModel): MatrixToolEnum {
    return state.selectedTool;
  }

  @Selector([ToolsState])
  static getSelectedView(state: IToolsStateModel): string {
    return state.selectedView;
  }

  @Selector([ToolsState])
  static getToolbox(state: IToolsStateModel): ToolboxModel {
    return state.toolbox;
  }

  @Selector([ToolsState])
  static isActiveMatrixHighlight(state: IToolsStateModel): boolean {
    return state.activeMatrixHighlight;
  }

  @Action(SetToolboxStateByMatrixType)
  private SetToolboxStateByMatrixType(
    { setState }: StateContext<IToolsStateModel>,
    { payload }: SetToolboxStateByMatrixType
  ) {
    setState({ ...initialState, toolbox: this.defineToolboxState(payload) });
  }

  @Action(ResetToolboxState)
  private ResetToolboxState({ setState }: StateContext<IToolsStateModel>) {
    setState({ ...initialState });
  }

  @Action(SetSelectedTool)
  private SetSelectedTool({ patchState }: StateContext<IToolsStateModel>, { payload }: SetSelectedTool) {
    patchState({ selectedTool: payload });
  }

  @Action([SetSelectedView, RestoreSelectedView])
  private SetSelectedView({ patchState }: StateContext<IToolsStateModel>, { payload }: SetSelectedView) {
    patchState({ selectedView: payload });
  }

  @Action(StartMatrixHighlighter)
  private StartMatrixHighlighter({ patchState }: StateContext<IToolsStateModel>) {
    patchState({ activeMatrixHighlight: true });
  }

  @Action(StopMatrixHighlighter)
  private StopMatrixHighlighter({ patchState }: StateContext<IToolsStateModel>) {
    patchState({ activeMatrixHighlight: false });
  }

  @Action(SetToolboxState)
  private SetToolbox(ctx: StateContext<IToolsStateModel>, { payload }: SetToolboxState) {
    const toolbox = payload ? { ...initialState.toolbox, ...payload } : { ...initialState.toolbox };
    ctx.patchState({ toolbox });
  }

  private defineToolboxState(matrixType: ValidMatrix): ToolboxModel {
    switch (matrixType) {
      case ValidMatrix.GroupBy:
      case ValidMatrix.Rank:
        return {
          ...initialState.toolbox,
          [MatrixToolEnum.Frame]: 'hidden'
        };
      case ValidMatrix.Freestyle:
        return {
          ...initialState.toolbox //,
          // [MatrixToolEnum.Highlighter]: 'hidden'
        };
      case ValidMatrix.Pareto:
        return {
          ...initialState.toolbox,
          // [MatrixToolEnum.Highlighter]: 'hidden',
          [MatrixToolEnum.Frame]: 'hidden'
        };
    }
    return { ...initialState.toolbox };
  }
}
