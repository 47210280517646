<div class="toolbox">
  <div class="toolbox-content">
    <app-adaptive-toolbar [orientation]="'top'" [canDragDrop]="false">
      <ng-template>
        <app-adaptive-toolbar-item
          *ngFor="let icon of matrixIcons"
          (click)="onSelect(icon)"
          class="matrix-type-icon-container"
        >
          <svg-icon
            svgClass="matrix-type-icon"
            [name]="icon.iconName"
            [matTooltip]="icon.value | matrixIconTooltip"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></svg-icon>
        </app-adaptive-toolbar-item>
      </ng-template>
    </app-adaptive-toolbar>
  </div>
</div>
