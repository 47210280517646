import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, filter, map, mergeAll, take, tap, toArray } from 'rxjs/operators';
import { ImageManagerType } from '@app/state/connection/image/image-connection.model';
import { retryWithDelay } from '@app/utils/custom-rxjs-operators.utils';
import { getPartsFromArray } from '@app/utils/array.utils';

export interface IProduct {
  bid: string;
  prefix: string;
  suffix: string;
  perspectives: {
    [perspective: string]: { low: string; medium: string; high: string };
  };
}

export interface IProductBulk {
  [bid: string]: {
    prefix: string;
    suffix: string;
    perspectives: {
      [perspective: string]: { low: string; medium: string; high: string };
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class DamService {
  constructor(private http: HttpClient) {}

  public getColumbiaProductImage(materialNumber: string): Observable<IProduct> {
    return this.http.get<IProduct>(`${environment.damApiUrl}/dam/columbia/${materialNumber}`);
  }

  /**
   * Load image data for each productID
   * @param materialNumbers array of uniq productID
   * @param dataAccessType image type connection (Nike / Columbia / etc)
   * @param withTimeout if true, request will be cancelled after timeout
   * @param limitPerRequest maximum productId in one request
   * @param isRootRequest if true, then it's a root call, otherwise it's children cals with smaller amount of productID
   * @param progressSubject$ to observe loading progress in %
   */
  public getProductBulkParts(
    materialNumbers: Array<string>,
    dataAccessType: ImageManagerType,
    withTimeout: boolean = true,
    limitPerRequest: number = 500,
    isRootRequest: boolean = true,
    progressSubject$: BehaviorSubject<number> = null
  ): Observable<IProductBulk> {
    // --> console.log('DAM.getProductBulkParts', isRootRequest, materialNumbers, progressSubject$);
    const canceled$ = progressSubject$.pipe(filter(value => value === -1));

    if (materialNumbers.length > limitPerRequest) {
      let countDownloaded = 0;
      const requests = getPartsFromArray(materialNumbers, limitPerRequest).map(part =>
        this.getProductBulk(part, dataAccessType, withTimeout, progressSubject$).pipe(
          take(1),
          takeUntil(canceled$),
          tap(() => {
            if (isRootRequest) {
              countDownloaded += part.length;
              const percent = Math.round((countDownloaded / materialNumbers.length) * 100);
              if (progressSubject$) {
                // --> console.log('++++> ', percent);
                progressSubject$.next(percent);
              }
            }
          })
        )
      );
      // send single request at first to reduce pending time for next requests caused by cold start
      // then send only 6 concurrent requests at time to avoid timeout errors
      const reqs = requests
        .reduce((acc, req) => {
          if (acc.length === 0) {
            acc.push([req]);
          } else {
            if (acc.length === 1 || acc[acc.length - 1].length === 6) {
              acc.push([]);
            }
            acc[acc.length - 1].push(req);
          }
          return acc;
        }, [])
        .map(items => forkJoin(items) as Observable<IProductBulk[]>);

      const result: Array<IProductBulk> = [];
      const resultSubject$: Subject<IProductBulk> = new Subject();
      concat(...reqs).subscribe({
        next: (items: Array<IProductBulk>) => {
          result.push(...items);
        },
        complete: () => {
          const resultBulk = result.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr
            }),
            {}
          );
          resultSubject$.next(resultBulk);
        }
      });
      return resultSubject$;
    }
    return this.getProductBulk(materialNumbers, dataAccessType, withTimeout, progressSubject$);
  }

  /**
   * @deprecated Use this method only for testing.
   */
  public getProductBulkPartsOld(
    materialNumbers: Array<string>,
    dataAccessType: ImageManagerType
  ): Observable<IProductBulk> {
    const url = this.getUrlByDataAccessType(dataAccessType);
    const maxBIDsPerRequest = 1000; // if increase items per request to 10k, all will be failed by Timeout after ~25sec
    if (materialNumbers.length > maxBIDsPerRequest) {
      const requests: Array<Observable<IProductBulk>> = [];
      for (let i = 0; i < materialNumbers.length; ) {
        let end = i + maxBIDsPerRequest;
        if (end > materialNumbers.length) {
          end = materialNumbers.length;
        }
        const part = materialNumbers.slice(i, end);
        const partRequest = this.http.post<IProductBulk>(url, part).pipe(take(1));
        requests.push(partRequest);
        i = end;
      }
      return forkJoin(requests).pipe(
        map((parts: Array<IProductBulk>) => {
          const allParts = parts.reduce(
            (result, part) => ({
              ...result,
              ...part
            }),
            {}
          );
          // -- console.log(parts, allParts);
          return allParts;
        })
      );
    }
    return this.http.post<IProductBulk>(url, materialNumbers);
  }

  /**
   * One HTTP request to get Image data info
   * @param materialNumbers array of productID to get image data info
   * @param dataAccessType image type connection (Nike / Columbia / etc)
   * @param withTimeout if true, request will be cancelled after timeoutMs
   * @param progressSubject$ to observe loading progress in %
   * @private
   */
  private getProductBulk(
    materialNumbers: Array<string>,
    dataAccessType: ImageManagerType,
    withTimeout: boolean,
    progressSubject$: BehaviorSubject<number> = null
  ): Observable<IProductBulk> {
    // --> console.log('DAM.getProductBulk', materialNumbers, progressSubject$);
    const canceled$ = progressSubject$.pipe(filter(value => value === -1));

    const url = this.getUrlByDataAccessType(dataAccessType);
    return (
      withTimeout
        ? this.http.post<IProductBulk>(url, materialNumbers).pipe(
            takeUntil(canceled$)
            // TODO: uncomment this lines if returning to old strategy is needed
            // timeout(timeoutMs),
            // catchError(error => {
            //   if (error instanceof TimeoutError) {
            //     // if canceled by timeout, we will split requests to the smaller parts
            //     const limitPerRequest = Math.ceil(materialNumbers.length / 5);
            //     return this.getProductBulkParts(
            //       materialNumbers,
            //       dataAccessType,
            //       false,
            //       limitPerRequest,
            //       false,
            //       progressSubject$
            //     ).pipe(catchError(() => of({})));
            //   }
            //   return throwError(() => error);
            // })
          )
        : this.http.post<IProductBulk>(url, materialNumbers)
    ).pipe(
      retryWithDelay(10000, 3),
      catchError(() => of({}))
    );
  }

  public runCachingJob(bids: Array<string>, businessConnectionId: string) {
    return this.http.post(`${environment.damApiUrl}/dam/columbia/everything/materialNumbers`, {
      bids,
      businessConnectionId
    });
  }

  private getUrlByDataAccessType(dataAccessType: ImageManagerType): string {
    switch (dataAccessType) {
      case ImageManagerType.ColumbiaS7:
        return `${environment.damApiUrl}/dam/columbia/v2/product-images/bulk`;
      case ImageManagerType.Nike:
        return `${environment.damApiUrl}/dam/nike/v2/product-images/bulk`;
      case ImageManagerType.CollagiaImageLibrary:
        return `${environment.damApiUrl}/dam/user-image-data/v2/product-images/bulk`;
      default:
        return '';
    }
  }
}
