import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { Texture } from 'pixi.js';
import { getMatrixMagnifyContainer, getMatrixOverlayContainer } from '../../utils/matrix-overlay-container';
import { Inject } from '@angular/core';
import { WINDOW } from '../../shared/utils/window';
import { IPosition } from '@app/models/workspace-list.model';
import { LOW_IMAGE_SIZE_RESOURCE, HIGH_IMAGE_SIZE_RESOURCE } from './base-matrix';

export interface IMagnifySettings {
  scale: number;
  radius: number;
}

const MIN_MAGNIFY_SCALE = 1; // x1
const MAX_MAGNIFY_SCALE = 5; // x5

const MIN_MAGNIFY_RADIUS = 50;
const MAX_MAGNIFY_RADIUS = 150;

export class MagnifyTool {
  private border = 2;
  private radius = 100;
  private magnifyRef: HTMLDivElement;

  private imageScale = MIN_MAGNIFY_SCALE;

  public imageContainer: ImageContainer;
  private lastCoordinates: IPosition;

  private currentViewportImageSize = 100;

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private viewportScale = 1
  ) {}

  public init() {
    this.restoreSettings();
    this.createMagnify();
    this.updatePosition({ x: -1000, y: -1000 });
    // hide active frames in the overlay for groups when Magnify tool is active
    getMatrixOverlayContainer().classList.add('magnify');
  }

  public destroy() {
    this.storeSettings();
    this.removeMagnify();
    // restore hidden active frames in the overlay for groups - show them again
    getMatrixOverlayContainer().classList.remove('magnify');
  }

  get windowWidth(): number {
    return this.window.innerWidth;
  }

  // uses to calculate range of image sizes in Magnify circle
  // baseMinSize * (MIN_MAGNIFY_SCALE ... MAX_MAGNIFY_SCALE) --> (x2 ... x5)
  get baseMinSize(): number {
    return Math.max(this.windowWidth * 0.1, this.currentViewportImageSize);
  }

  public updateScale(scale = 1) {
    this.viewportScale = scale;
  }

  public updateContainer() {
    this.resizeContainer(0);
  }

  public onMouseWheel(dif: number, isCtrlKey: boolean) {
    if (!isCtrlKey) {
      this.resizeImage(dif);
    } else {
      this.resizeContainer(dif);
    }

    this.onImageMouseMove(this.imageContainer, this.lastCoordinates);
  }

  public updatePosition(coords: IPosition) {
    this.magnifyRef.style.left = `${coords.x + 2}px`;
    this.magnifyRef.style.top = `${coords.y + 2}px`;
  }

  public clearMagnifyImage() {
    this.magnifyRef.children[2].setAttribute('src', '');
    this.magnifyRef.style.display = 'none';
    this.updatePosition({ x: -1000, y: -1000 });
  }

  public updateImageTexture(texture: Texture) {
    this.onImageMouseOver(this.imageContainer, texture, this.lastCoordinates);
  }

  public onImageMouseOver(imageContainer: ImageContainer, texture: Texture, coord: IPosition) {
    const imageBg = this.magnifyRef.children[1] as HTMLDivElement;
    const image = this.magnifyRef.children[2] as HTMLImageElement;

    const scale = this.viewportScale * (imageContainer.width / LOW_IMAGE_SIZE_RESOURCE);
    this.currentViewportImageSize = HIGH_IMAGE_SIZE_RESOURCE * scale;

    const size = this.baseMinSize * this.imageScale;
    const diffScale = texture.baseTexture.width / size;
    const imageWidth = texture.baseTexture.width / diffScale;
    const imageHeight = texture.baseTexture.height / diffScale;

    image.setAttribute('width', `${imageWidth}px`);
    image.setAttribute('height', `${imageHeight}px`);
    image.setAttribute('src', (texture.baseTexture.resource as any).url || texture.baseTexture.textureCacheIds[1]);

    imageBg.style.width = `${imageWidth}px`;
    imageBg.style.height = `${imageHeight}px`;

    this.onImageMouseMove(imageContainer, coord);

    this.magnifyRef.style.display = 'block';
  }

  public onImageMouseMove(imageContainer: ImageContainer, coord: IPosition) {
    this.imageContainer = imageContainer;
    this.lastCoordinates = coord;

    const imageBg = this.magnifyRef.children[1] as HTMLDivElement;
    const image = this.magnifyRef.children[2] as HTMLImageElement;

    const imageBounds = imageContainer.getImageBounds();
    const frameBounds = this.magnifyRef.getBoundingClientRect();

    const diffScale2 = image.width / imageBounds.width;

    const x = frameBounds.width / 2 - (coord.x - imageBounds.x) * diffScale2;
    const y = frameBounds.height / 2 - (coord.y - imageBounds.y) * diffScale2;

    image.style.left = `${x}px`;
    image.style.top = `${y}px`;

    imageBg.style.left = `${x}px`;
    imageBg.style.top = `${y}px`;
  }

  public onImageMouseOut() {
    this.clearMagnifyImage();
    this.updatePosition({ x: -1000, y: -1000 });
  }

  private resizeImage(dif: number) {
    const imageBg = this.magnifyRef.children[1] as HTMLDivElement;
    const image = this.magnifyRef.children[2] as HTMLImageElement;
    const sizeDif = dif * 0.2;

    this.imageScale += sizeDif;
    if (this.imageScale + sizeDif < MIN_MAGNIFY_SCALE) this.imageScale = MIN_MAGNIFY_SCALE;
    if (this.imageScale + sizeDif > MAX_MAGNIFY_SCALE) this.imageScale = MAX_MAGNIFY_SCALE;

    const size = this.baseMinSize * this.imageScale;
    const diffScale = image.naturalWidth / size;
    const imageWidth = image.naturalWidth / diffScale;
    const imageHeight = image.naturalHeight / diffScale;

    image.setAttribute('width', `${imageWidth}px`);
    image.setAttribute('height', `${imageHeight}px`);

    imageBg.style.width = `${imageWidth}px`;
    imageBg.style.height = `${imageHeight}px`;
  }

  private resizeContainer(dif: number) {
    if (!this.magnifyRef) {
      return;
    }
    const sizeDif = dif * 5;
    if (this.radius + sizeDif < MIN_MAGNIFY_RADIUS) return;
    if (this.radius + sizeDif > MAX_MAGNIFY_RADIUS) return;
    this.radius += sizeDif;

    const size = this.radius * 2 + this.border * 2;
    this.magnifyRef.style.width = `${size}px`;
    this.magnifyRef.style.height = `${size}px`;

    const shift = Math.sqrt(2) * this.radius - this.radius;

    const imageBg = this.magnifyRef.children[1] as HTMLDivElement;
    imageBg.style.left = `${shift}px`;
    imageBg.style.top = `${shift}px`;

    const image = this.magnifyRef.children[2] as HTMLImageElement;
    image.style.left = `${shift}px`;
    image.style.top = `${shift}px`;
  }

  private removeMagnify() {
    getMatrixMagnifyContainer().innerHTML = '';
    this.magnifyRef = null;
    this.imageContainer?.off('mousemove');
    this.imageContainer = null;
  }

  private createMagnify() {
    this.magnifyRef = document.createElement('div');
    this.magnifyRef.innerHTML = `
      <div class="background"></div>
      <div class="image-background"></div>
      <img src="" alt="">
    `;
    this.magnifyRef.classList.add('magnify-tool');
    this.magnifyRef.style.display = 'none';
    this.magnifyRef.style.position = 'relative';
    this.magnifyRef.style.overflow = 'hidden';
    this.magnifyRef.style.background = 'transparent';
    this.magnifyRef.style.border = `${this.border}px solid #e1e0e7`;
    this.magnifyRef.style.borderRadius = '50%';

    const size = this.radius * 2 + this.border * 2;
    this.magnifyRef.style.width = `${size}px`;
    this.magnifyRef.style.height = `${size}px`;

    const background = this.magnifyRef.children[0] as HTMLDivElement;
    background.style.position = 'absolute';
    background.style.left = '0';
    background.style.top = '0';
    background.style.right = '0';
    background.style.bottom = '0';
    background.style.background = '#888888';
    background.style.opacity = '0.4';

    const imageBg = this.magnifyRef.children[1] as HTMLDivElement;
    imageBg.style.position = 'absolute';
    imageBg.style.background = '#ffffff';

    const image = this.magnifyRef.children[2] as HTMLImageElement;
    image.style.position = 'absolute';

    getMatrixMagnifyContainer().style.zIndex = '99';
    getMatrixMagnifyContainer().appendChild(this.magnifyRef);
  }

  private restoreSettings() {
    const settings: IMagnifySettings = JSON.parse(localStorage.getItem('magnify')) || null;
    if (settings) {
      this.radius = settings.radius;
      this.imageScale = settings.scale;
    } else {
      this.radius = 100;
    }
  }

  private storeSettings() {
    const settings: IMagnifySettings = {
      radius: this.radius,
      scale: this.imageScale
    };
    localStorage.setItem('magnify', JSON.stringify(settings));
  }
}
