import { Injectable, inject } from '@angular/core';
import { ICollage } from '@app/state/collage/collage.model';
import { IStudy } from '@app/state/study/study.model';
import { IConnection } from '@app/state/connection/connection.model';
import {
  IFrameDBModel,
  IFrameStateModel,
  IImageStateModel,
  IMatrixDBModel,
  IMatrixStateModel,
  IWorkspace,
  IWorkspaceDBModel,
  IWorkspaceListState
} from '@app/models/workspace-list.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ConnectionType } from '../../state/connection/connection.model';
import { IWhitelist } from '@app/models/whitelist.model';
import { IUserData, UserGroup } from '@app/models/user-data.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkspaceUtils } from '@app/utils/workspace.utils';
import {
  Firestore,
  collection,
  collectionData,
  deleteDoc,
  deleteField,
  doc,
  docData,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
  DocumentReference,
  DocumentData,
  UpdateData,
  writeBatch,
  collectionGroup,
  getDocs
} from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { Functions, HttpsCallable, getFunctions, httpsCallable } from '@angular/fire/functions';
import { processWorkspace } from '@app/adapters/workspace.adapter';
import { shortUid } from '@app/shared/utils/short-uid';
import { IBusinessConnection } from '../../state/connection/business/business-connection.model';
import { IFiltersModelOldVersion2 } from '../../models/filters.model';

export const CURRENT_STUDY_DATA_VERSION = 3;

export enum WriteBatchMethod {
  Set = 'set',
  Update = 'update',
  Delete = 'delete'
}

export interface IDocToWriteInBatch<T = DocumentData> {
  ref: string;
  writeMethod: WriteBatchMethod;
  data?: T | UpdateData<T>;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {
  private readonly MAX_WRITES_PER_BATCH = 500;
  private auth: Auth = inject(Auth);
  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);

  public getImageDocRef(studyId: string, workspaceId: string, imageId: string): DocumentReference<IImageStateModel> {
    return doc(
      this.firestore,
      `studies/${studyId}/workspaces/${workspaceId}/images/${imageId}`
    ) as DocumentReference<IImageStateModel>;
  }

  public getFrameDocRef(studyId: string, workspaceId: string, frameId: string): DocumentReference<IFrameDBModel> {
    return doc(
      this.firestore,
      `studies/${studyId}/workspaces/${workspaceId}/frames/${frameId}`
    ) as DocumentReference<IFrameDBModel>;
  }

  public getWorkspaceDocRef(studyId: string, workspaceId: string): DocumentReference<IWorkspaceDBModel> {
    return doc(this.firestore, `studies/${studyId}/workspaces/${workspaceId}`) as DocumentReference<IWorkspaceDBModel>;
  }

  public getImagePath(studyId: string, workspaceId: string, imageId: string): string {
    return `studies/${studyId}/workspaces/${workspaceId}/images/${imageId}`;
  }

  public getFramePath(studyId: string, workspaceId: string, frameId: string): string {
    return `studies/${studyId}/workspaces/${workspaceId}/frames/${frameId}`;
  }

  public getWorkspacePath(studyId: string, workspaceId: string): string {
    return `studies/${studyId}/workspaces/${workspaceId}`;
  }

  private multipleDocsWrite: HttpsCallable;

  constructor(private snackBar: MatSnackBar) {
    const functions = getFunctions();
    this.multipleDocsWrite = httpsCallable(functions, 'multipleDocsWrite');
  }

  /* Study */

  public getStudy(studyId: string): Observable<IStudy> {
    return docData(doc(this.firestore, `studies/${studyId}`)) as Observable<IStudy>;
  }

  public createStudy(study: IStudy): Promise<void> {
    return setDoc(doc(this.firestore, `studies/${study.id}`), study);
  }

  public updateStudy(studyId: string, update: UpdateData<IStudy>): Promise<void> {
    return updateDoc(doc(this.firestore, `studies/${studyId}`), update);
  }

  public deleteStudy(studyId: string): Promise<any> {
    return deleteDoc(doc(this.firestore, `studies/${studyId}`));
  }

  public getStudyList(): Observable<Array<IStudy>> {
    return this.auth.currentUser
      ? collectionData(
          query(
            collection(this.firestore, 'studies'),
            where('owner', '==', this.auth.currentUser.uid),
            orderBy('favorite', 'desc'),
            orderBy('lastViewed', 'desc')
          )
        )
      : of([]);
  }

  public getFiveLastStudies(): Observable<Array<IStudy>> {
    return this.auth.currentUser
      ? collectionData(
          query(
            collection(this.firestore, 'studies'),
            where('owner', '==', this.auth.currentUser.uid),
            orderBy('lastViewed', 'desc'),
            limit(5)
          )
        )
      : of([]);
  }

  public updateHoverInfoConfig(studyId: string, payload: any): Promise<void> {
    return this.updateStudy(studyId, { 'studySettings.hoverInfoConfig': payload } as any);
  }

  public updateMetricsCustomFormattings(studyId: string, payload: any): Promise<void> {
    return this.updateStudy(studyId, { 'studySettings.metricsCustomFormattings': payload } as any);
  }

  public updateCustomAttributes(studyId: string, payload: any): Promise<void> {
    return this.updateStudy(studyId, { customAttributes: payload });
  }

  public updateCustomCalculationFilters(studyId: string, payload: any): Promise<void> {
    return this.updateStudy(studyId, { customCalculationFilters: payload });
  }

  public updateFriendlyName(studyId: string, payload: any): Promise<void> {
    return this.updateStudy(studyId, { friendlyName: payload });
  }

  /* Workspace */

  /**
   * Return Study with Workspace entities
   * @param study
   */
  public getStudyWithWorkspaces(study: IStudy): Observable<IStudy> {
    return study.workspaceList.activeWorkspaceId
      ? this.getAllWorkspaces(study.id, study.workspaceList.ids).pipe(
          take(1),
          map((workspaces: IWorkspace[]) => ({
            ...study,
            workspaceList: {
              ...study.workspaceList,
              entities: workspaces.reduce(
                (result, workspace) => ({
                  ...result,
                  [workspace.id]: workspace
                }),
                {}
              )
            }
          }))
        )
      : of({
          ...study,
          workspaceList: {
            ...study.workspaceList,
            entities: {}
          }
        });
  }

  public migrateToLastVersion(study: IStudy): Promise<IStudy> {
    let migratedPromise;
    if (study.version === 2) {
      migratedPromise = this.migrateStudyFromV2ToV3(study);
    } else if ((!study.version || study.version < 2) && study.workspaceList.entities) {
      migratedPromise = this.migrateStudyToV2(study.id, study.workspaceList).then(() =>
        this.migrateStudyFromV2ToV3(study)
      );
    } else {
      // some old Study without workspaces and Version... set it to the CURRENT_STUDY_DATA_VERSION
      const docWrites: Array<IDocToWriteInBatch<any>> = [
        {
          ref: `studies/${study.id}`,
          writeMethod: WriteBatchMethod.Update,
          data: {
            version: CURRENT_STUDY_DATA_VERSION
          }
        }
      ];
      migratedPromise = this.writeDocsInBatches(docWrites);
    }

    return migratedPromise.then(() => {
      return this.getStudy(study.id)
        .pipe(take(1))
        .toPromise()
        .then((migratedStudy: IStudy) => {
          return this.getStudyWithWorkspaces(migratedStudy).pipe(take(1)).toPromise();
        });
    });
  }

  public migrateStudyFromV2ToV3(study: IStudy): Promise<any> {
    return this.getStudyWithWorkspaces(study)
      .pipe(take(1))
      .toPromise()
      .then(studyWithWorkspaceEntities => {
        const studyId = studyWithWorkspaceEntities.id;
        const workspaceList = studyWithWorkspaceEntities.workspaceList;
        const docWrites: Array<IDocToWriteInBatch<any>> = [
          {
            ref: `studies/${studyId}`,
            writeMethod: WriteBatchMethod.Update,
            data: {
              version: 3
            }
          }
        ];
        const workspaces = workspaceList.ids.map(id => workspaceList.entities[id]);
        for (const workspace of workspaces) {
          let x = [];
          let y = [];
          let filtering = workspace.axesShelf.filtering || [];
          workspace.axesShelf.x.forEach((oldFilter: IFiltersModelOldVersion2) => {
            if (oldFilter.isFiltering) {
              delete oldFilter.isFiltering;
              filtering.push(oldFilter);
            } else {
              x.push(oldFilter);
            }
          });
          workspace.axesShelf.y.forEach((oldFilter: IFiltersModelOldVersion2) => {
            if (oldFilter.isFiltering) {
              delete oldFilter.isFiltering;
              filtering.push(oldFilter);
            } else {
              y.push(oldFilter);
            }
          });
          docWrites.push({
            ref: this.getWorkspacePath(studyId, workspace.id),
            writeMethod: WriteBatchMethod.Update,
            data: {
              axesShelf: {
                x,
                y,
                filtering
              }
            }
          });
        }
        return this.writeDocsInBatches(docWrites);
      });
  }

  public migrateStudyToV2(studyId: string, workspaceList: IWorkspaceListState): Promise<any> {
    if (!workspaceList.entities) return Promise.resolve();
    const docWrites: Array<IDocToWriteInBatch<any>> = [
      {
        ref: `studies/${studyId}`,
        writeMethod: WriteBatchMethod.Update,
        data: {
          'workspaceList.entities': deleteField(),
          version: 2
        }
      }
    ];
    const workspaces = workspaceList.ids.map(id => workspaceList.entities[id]);
    for (const workspace of workspaces) {
      workspace.imageMatrix.images = this.setUidToImages(workspace.imageMatrix.images);
      workspace.frames = this.setUidToFrameImages(workspace.frames);
      docWrites.push({
        ref: this.getWorkspacePath(studyId, workspace.id),
        writeMethod: WriteBatchMethod.Set,
        data: {
          ...workspace,
          imageMatrix: {
            ...workspace.imageMatrix,
            images: workspace.imageMatrix.images.map(image => image.uid)
          },
          frames: workspace.frames.map(frame => frame.id)
        }
      });
      docWrites.push(
        ...workspace.imageMatrix.images.map(image => ({
          ref: this.getImagePath(studyId, workspace.id, image.uid),
          writeMethod: WriteBatchMethod.Set,
          data: image
        }))
      );
      const allFrames: Array<IFrameStateModel> = this.getAllFrames(workspace);
      const allFrameImages: Array<IImageStateModel> = allFrames.reduce(
        (result, frame) => [...result, ...frame.images],
        []
      );
      docWrites.push(
        ...allFrames.map(frame => ({
          ref: this.getFramePath(studyId, workspace.id, frame.id),
          writeMethod: WriteBatchMethod.Set,
          data: {
            ...frame,
            frames: frame.frames.map(subFrame => subFrame.id),
            images: frame.images.map(subImage => subImage.uid)
          }
        })),
        ...allFrameImages.map(image => ({
          ref: this.getImagePath(studyId, workspace.id, image.uid),
          writeMethod: WriteBatchMethod.Set,
          data: image
        }))
      );
    }
    return this.writeDocsInBatches(docWrites);
  }

  public getWorkspace(studyId: string, workspaceId: string): Observable<IWorkspace> {
    return docData(this.getWorkspaceDocRef(studyId, workspaceId)).pipe(
      withLatestFrom(this.getFrames(studyId, workspaceId), this.getImages(studyId, workspaceId)),
      map(([workspace, frames, images]: [IWorkspaceDBModel, Array<IFrameDBModel>, Array<IImageStateModel>]) => {
        return processWorkspace(workspace, frames, images);
      })
    );
  }

  public getAllWorkspaces(studyId: string, workspaceIds: Array<string>): Observable<Array<IWorkspace>> {
    return combineLatest(workspaceIds.map(workspaceId => this.getWorkspace(studyId, workspaceId)));
  }

  public createWorkspace(studyId: string, defaultView: string): Promise<void> {
    const workspace = WorkspaceUtils.create(studyId, defaultView);
    return this.setWorkspace(studyId, workspace);
  }

  public setWorkspace(studyId: string, workspace: IWorkspace): Promise<any> {
    if (workspace.imageMatrix.images.length > 0 || workspace.frames.length > 0) {
      const { imageMatrix, frames, ...anotherData } = workspace;
      const workspaceDb: IWorkspaceDBModel = anotherData as any;
      const docWrites: Array<IDocToWriteInBatch> = [];
      if (frames) {
        const allFrames: Array<IFrameStateModel> = this.getAllFrames({ frames });
        const allFramesImages: Array<IImageStateModel> = allFrames.reduce(
          (result, frame) => [...result, ...frame.images],
          []
        );
        workspaceDb.frames = frames.map((frame: IFrameStateModel) => frame.id);
        docWrites.push(
          ...allFrames.map(subframe => ({
            ref: this.getFramePath(studyId, workspace.id, subframe.id),
            writeMethod: WriteBatchMethod.Set,
            data: {
              ...subframe,
              frames: subframe.frames.map((frame: IFrameStateModel) => frame.id),
              images: subframe.images.map((image: IImageStateModel) => image.uid)
            }
          })),
          ...allFramesImages.map(subimage => ({
            ref: this.getImagePath(studyId, workspace.id, subimage.uid),
            writeMethod: WriteBatchMethod.Set,
            data: subimage
          }))
        );
      }
      if (imageMatrix.images) {
        workspaceDb.imageMatrix = {
          ...imageMatrix,
          images: imageMatrix.images.map((image: IImageStateModel) => image.uid)
        };
        docWrites.push(
          ...imageMatrix.images.map(image => ({
            ref: this.getImagePath(studyId, workspace.id, image.uid),
            writeMethod: WriteBatchMethod.Set,
            data: image
          }))
        );
      }

      docWrites.push({
        ref: this.getWorkspacePath(studyId, workspace.id),
        writeMethod: WriteBatchMethod.Set,
        data: workspaceDb
      });
      return this.writeDocsInBatches(docWrites);
    }
    return setDoc(this.getWorkspaceDocRef(studyId, workspace.id), workspace as any);
  }

  public deleteWorkspace(studyId: string, workspaceId: string): Promise<any> {
    const functions = getFunctions();
    const deleteWorkspace = httpsCallable(functions, 'deleteWorkspace');
    return deleteWorkspace({ studyId, workspaceId });
  }

  public updateWorkspace(studyId: string, workspaceId: string, update: UpdateData<IWorkspaceDBModel>): Promise<any> {
    return updateDoc(this.getWorkspaceDocRef(studyId, workspaceId), update);
  }

  public updateFramesAndImages(
    studyId: string,
    workspaceId: string,
    frames: Array<IFrameStateModel>,
    images: Array<IImageStateModel>
  ): Promise<any> {
    const docWrites: Array<IDocToWriteInBatch> = [];
    const updateWorkspace: UpdateData<IWorkspaceDBModel> = {};
    updateWorkspace.frames = frames.map((frame: IFrameStateModel) => frame.id);
    if (frames.length > 0) {
      const allFrames: Array<IFrameStateModel> = this.getAllFrames({ frames });
      const allFramesImages: Array<IImageStateModel> = allFrames.reduce(
        (result, frame) => [...result, ...frame.images],
        []
      );
      docWrites.push(
        ...allFrames.map(subframe => ({
          ref: this.getFramePath(studyId, workspaceId, subframe.id),
          writeMethod: WriteBatchMethod.Set,
          data: {
            ...subframe,
            frames: subframe.frames.map((frame: IFrameStateModel) => frame.id),
            images: subframe.images.map((image: IImageStateModel) => image.uid)
          }
        })),
        ...allFramesImages.map(subimage => ({
          ref: this.getImagePath(studyId, workspaceId, subimage.uid),
          writeMethod: WriteBatchMethod.Set,
          data: subimage
        }))
      );
    }
    updateWorkspace['imageMatrix.images'] = images.map((image: IImageStateModel) => image.uid);
    if (images.length > 0) {
      if (images.length > 0) {
        docWrites.push(
          ...images.map(image => ({
            ref: this.getImagePath(studyId, workspaceId, image.uid),
            writeMethod: WriteBatchMethod.Set,
            data: image
          }))
        );
      }
    }
    docWrites.push({
      ref: this.getWorkspacePath(studyId, workspaceId),
      writeMethod: WriteBatchMethod.Update,
      data: updateWorkspace
    });
    return this.writeDocsInBatches(docWrites);
  }

  public updateFrames(studyId: string, workspaceId: string, frames: Array<IFrameStateModel>): Promise<any> {
    const docWrites: Array<IDocToWriteInBatch> = [];
    if (frames.length > 0) {
      const allFrames: Array<IFrameStateModel> = this.getAllFrames({ frames });
      const allFramesImages: Array<IImageStateModel> = allFrames.reduce(
        (result, frame) => [...result, ...frame.images],
        []
      );
      docWrites.push(
        ...allFrames.map(subframe => ({
          ref: this.getFramePath(studyId, workspaceId, subframe.id),
          writeMethod: WriteBatchMethod.Set,
          data: {
            ...subframe,
            frames: subframe.frames.map((frame: IFrameStateModel) => frame.id),
            images: subframe.images.map((image: IImageStateModel) => image.uid)
          }
        })),
        ...allFramesImages.map(subimage => ({
          ref: this.getImagePath(studyId, workspaceId, subimage.uid),
          writeMethod: WriteBatchMethod.Set,
          data: subimage
        }))
      );
    }
    return this.writeDocsInBatches(docWrites);
  }

  public addFrame(studyId: string, workspaceId: string, frame: IFrameStateModel): Promise<any> {
    const docWrites: Array<IDocToWriteInBatch> = [];

    docWrites.push({
      ref: this.getFramePath(studyId, workspaceId, frame.id),
      writeMethod: WriteBatchMethod.Set,
      data: {
        ...frame,
        frames: frame.frames.map((subframe: IFrameStateModel) => subframe.id),
        images: frame.images.map((image: IImageStateModel) => image.uid)
      }
    });
    docWrites.push(
      ...frame.frames.map(subframe => ({
        ref: this.getFramePath(studyId, workspaceId, subframe.id),
        writeMethod: WriteBatchMethod.Set,
        data: {
          ...subframe,
          frames: subframe.frames.map((item: IFrameStateModel) => item.id),
          images: subframe.images.map((item: IImageStateModel) => item.uid)
        }
      })),
      ...frame.images.map(subimage => ({
        ref: this.getImagePath(studyId, workspaceId, subimage.uid),
        writeMethod: WriteBatchMethod.Set,
        data: subimage
      }))
    );
    return this.writeDocsInBatches(docWrites);
  }

  public deleteFramesAndImages(
    studyId: string,
    workspaceId: string,
    frames: Array<IFrameStateModel>,
    images: Array<IImageStateModel>
  ): Promise<any> {
    if (frames.length === 0 && images.length === 0) return Promise.resolve();
    const docWrites: Array<IDocToWriteInBatch> = [];
    if (frames.length > 0) {
      const allFrames: Array<IFrameStateModel> = this.getAllFrames({ frames });
      const allFramesImages: Array<IImageStateModel> = allFrames.reduce(
        (result, frame) => [...result, ...frame.images],
        []
      );
      docWrites.push(
        ...allFrames.map(subframe => ({
          ref: this.getFramePath(studyId, workspaceId, subframe.id),
          writeMethod: WriteBatchMethod.Delete
        })),
        ...allFramesImages.map(subimage => ({
          ref: this.getImagePath(studyId, workspaceId, subimage.uid),
          writeMethod: WriteBatchMethod.Delete
        }))
      );
    }
    if (images.length > 0) {
      if (images.length > 0) {
        docWrites.push(
          ...images.map(image => ({
            ref: this.getImagePath(studyId, workspaceId, image.uid),
            writeMethod: WriteBatchMethod.Delete
          }))
        );
      }
    }
    return this.writeDocsInBatches(docWrites);
  }

  public updateMatrixState(studyId: string, workspaceId: string, matrixState: IMatrixStateModel): Promise<any> {
    const { imageMatrix, frames, ...anotherData } = matrixState;
    const updateWorkspace: IMatrixDBModel = anotherData;
    const docWrites: Array<IDocToWriteInBatch> = [];
    if (imageMatrix) {
      const { images } = imageMatrix;
      if (images) {
        updateWorkspace.imageMatrix = {
          ...imageMatrix,
          images: images.map((image: IImageStateModel) => image.uid)
        };
        if (images.length > 0) {
          docWrites.push(
            ...images.map(image => ({
              ref: this.getImagePath(studyId, workspaceId, image.uid),
              writeMethod: WriteBatchMethod.Set,
              data: image
            }))
          );
        }
      }
    }
    if (frames) {
      updateWorkspace.frames = frames.map((frame: IFrameStateModel) => frame.id);
      if (frames.length > 0) {
        const allFrames: Array<IFrameStateModel> = this.getAllFrames({ frames });
        const allFramesImages: Array<IImageStateModel> = allFrames.reduce(
          (result, frame) => [...result, ...frame.images],
          []
        );
        docWrites.push(
          ...allFrames.map(subframe => ({
            ref: this.getFramePath(studyId, workspaceId, subframe.id),
            writeMethod: WriteBatchMethod.Set,
            data: {
              ...subframe,
              frames: subframe.frames.map((frame: IFrameStateModel) => frame.id),
              images: subframe.images.map((image: IImageStateModel) => image.uid)
            }
          })),
          ...allFramesImages.map(subimage => ({
            ref: this.getImagePath(studyId, workspaceId, subimage.uid),
            writeMethod: WriteBatchMethod.Set,
            data: subimage
          }))
        );
      }
    }
    docWrites.push({
      ref: this.getWorkspacePath(studyId, workspaceId),
      writeMethod: WriteBatchMethod.Update,
      data: updateWorkspace
    });
    return this.writeDocsInBatches(docWrites);
  }

  public setMatrixState(
    studyId: string,
    workspaceId: string,
    oldworkspaceState: IWorkspace,
    matrixState: IMatrixStateModel
  ): Promise<any> {
    this.deleteFramesAndImages(studyId, workspaceId, oldworkspaceState.frames, oldworkspaceState.imageMatrix.images);
    return this.updateMatrixState(studyId, workspaceId, matrixState);
  }

  /* Frame */

  public getFrames(studyId: string, workspaceId: string): Observable<Array<IFrameDBModel>> {
    return collectionData(
      collection(this.firestore, `studies/${studyId}/workspaces/${workspaceId}/frames`)
    ) as Observable<Array<IFrameDBModel>>;
  }

  public updateFrame(
    studyId: string,
    workspaceId: string,
    frameId: string,
    update: Partial<IFrameStateModel>
  ): Promise<any> {
    const { images, frames, ...anotherData } = update;
    const updateFrameData: Partial<IFrameDBModel> = anotherData;
    if (images || frames) {
      const docWrites: Array<IDocToWriteInBatch> = [];
      if (frames) {
        updateFrameData.frames = frames.map((frame: IFrameStateModel) => frame.id);
        const allSubframes: Array<IFrameStateModel> = this.getAllFrames({ frames });
        const allSubframesImages: Array<IImageStateModel> = allSubframes.reduce(
          (result, frame) => [...result, ...frame.images],
          []
        );
        docWrites.push(
          ...allSubframes.map(subframe => ({
            ref: this.getFramePath(studyId, workspaceId, subframe.id),
            writeMethod: WriteBatchMethod.Update,
            data: {
              ...subframe,
              frames: subframe.frames.map((frame: IFrameStateModel) => frame.id),
              images: subframe.images.map((image: IImageStateModel) => image.uid)
            }
          })),
          ...allSubframesImages.map(subimage => ({
            ref: this.getImagePath(studyId, workspaceId, subimage.uid),
            writeMethod: WriteBatchMethod.Update,
            data: subimage
          }))
        );
      }
      if (images) {
        updateFrameData.images = images.map((image: IImageStateModel) => image.uid);
        docWrites.push(
          ...images.map(image => ({
            ref: this.getImagePath(studyId, workspaceId, image.uid),
            writeMethod: WriteBatchMethod.Update,
            data: image
          }))
        );
      }
      docWrites.push({
        ref: this.getFramePath(studyId, workspaceId, frameId),
        writeMethod: WriteBatchMethod.Update,
        data: updateFrameData
      });
      return this.writeDocsInBatches(docWrites);
    }
    return updateDoc(this.getFrameDocRef(studyId, workspaceId, frameId), update as any);
  }

  public deleteFrame(studyId: string, workspaceId: string, frameId: string): Promise<void> {
    return deleteDoc(this.getFrameDocRef(studyId, workspaceId, frameId));
  }

  /* Image */

  public getImages(studyId: string, workspaceId: string): Observable<Array<IImageStateModel>> {
    return collectionData(
      collection(this.firestore, `studies/${studyId}/workspaces/${workspaceId}/images`)
    ) as Observable<Array<IImageStateModel>>;
  }

  public updateImage(studyId: string, workspaceId: string, imageId: string, update: IImageStateModel): Promise<void> {
    const imageDocRef = doc(this.firestore, `studies/${studyId}/workspaces/${workspaceId}/images/${imageId}`);
    return updateDoc(imageDocRef, update as any);
  }

  public updateImages(studyId: string, workspaceId: string, images: Array<IImageStateModel>): Promise<any> {
    const docWrites: Array<IDocToWriteInBatch> = [
      {
        ref: this.getWorkspacePath(studyId, workspaceId),
        writeMethod: WriteBatchMethod.Update,
        data: {
          'imageMatrix.images': images.map(image => image.uid)
        }
      }
    ];
    docWrites.push(
      ...images.map(image => ({
        ref: this.getImagePath(studyId, workspaceId, image.uid),
        writeMethod: WriteBatchMethod.Update,
        data: image
      }))
    );
    return this.writeDocsInBatches(docWrites);
  }

  /* Collage */

  public getCollageList(): Observable<Array<ICollage>> {
    return (
      this.auth.currentUser
        ? collectionData(
            query(
              collection(this.firestore, 'collages'),
              where('owner', 'in', [this.auth.currentUser.uid, '*']),
              orderBy('favorite', 'desc'),
              orderBy('lastViewed', 'desc')
            )
          )
        : of([])
    ).pipe(
      map((collages: Array<ICollage>) => {
        // Default 'Levité Sample' Collage must be first in the list
        const leviteCollageIndex = collages.findIndex(
          collage => collage.metadata.name === 'Levité Sample' && collage.owner === '*'
        );
        if (leviteCollageIndex >= 0) {
          const leviteCollage = collages.splice(leviteCollageIndex, 1)[0];
          collages = [leviteCollage, ...collages];
        }
        return collages;
      })
    );
  }

  public createCollage(collage: ICollage): Promise<void> {
    return setDoc(doc(this.firestore, `collages/${collage.id}`), collage);
  }

  public updateCollageDisplayNameField(collageId: string, name: string): Promise<void> {
    return this.updateCollage(collageId, { displayNameField: name }).then(null, error => {
      this.snackBar.open('Cannot update Display Name column. Only collage owner can change it.', '', {
        panelClass: 'error',
        duration: 3000
      });
    });
  }

  public updateCollage(collageId: string, update: UpdateData<ICollage>): Promise<void> {
    return updateDoc(doc(this.firestore, `collages/${collageId}`), update);
  }

  public async deleteCollage(collageId: string): Promise<any> {
    const currentUser = this.auth.currentUser;
    const studies = (
      await getDocs(
        query(
          collectionGroup(this.firestore, 'studies'),
          where('owner', '==', currentUser.uid),
          where('collageId', '==', collageId)
        )
      )
    ).docs.map(item => item.ref);
    if (studies.length === 0) {
      return deleteDoc(doc(this.firestore, `collages/${collageId}`));
    }
    const docWrites: Array<IDocToWriteInBatch> = [
      ...studies.map(studyDoc => ({
        ref: `studies/${studyDoc.id}`,
        writeMethod: WriteBatchMethod.Delete
      })),
      {
        ref: `collages/${collageId}`,
        writeMethod: WriteBatchMethod.Delete
      }
    ];
    return this.writeDocsInBatches(docWrites);
  }

  public getCollage(collageId: string): Observable<ICollage> {
    return docData(doc(this.firestore, `collages/${collageId}`)) as Observable<ICollage>;
  }

  /* Connection */

  public getConnection(connectionId: string): Observable<IConnection> {
    return docData(doc(this.firestore, `connections/${connectionId}`)) as Observable<IConnection>;
  }

  public createConnection(connection: IConnection): Promise<void> {
    return this.setConnection(connection);
  }

  public setConnection(connection: IConnection): Promise<void> {
    return setDoc(doc(this.firestore, `connections/${connection.id}`), connection);
  }

  public updateConnection(connectionId: string, update: UpdateData<IConnection>): Promise<void> {
    return updateDoc(doc(this.firestore, `connections/${connectionId}`), update);
  }

  public updateConnectionName(connectionId: string, name: string): Promise<void> {
    return this.updateConnection(connectionId, { 'metadata.name': name } as any);
  }

  public async deleteConnection(connectionId: string, type: ConnectionType): Promise<void> {
    const currentUser = await this.auth.currentUser;
    const connectionIdField = type === ConnectionType.Image ? 'imageConnectionId' : 'businessConnectionId';
    const collages = (
      await getDocs(
        query(
          collectionGroup(this.firestore, 'collages'),
          where('owner', '==', currentUser.uid),
          where(connectionIdField, '==', connectionId)
        )
      )
    ).docs.map(item => item.ref);
    if (collages.length === 0) {
      return deleteDoc(doc(this.firestore, `connections/${connectionId}`));
    }
    const docWrites: Array<IDocToWriteInBatch> = [
      ...collages.map(collageDoc => ({
        ref: `collages/${collageDoc.id}`,
        writeMethod: WriteBatchMethod.Delete
      })),
      {
        ref: `connections/${connectionId}`,
        writeMethod: WriteBatchMethod.Delete
      }
    ];
    const collageIds = collages.map(collageDoc => collageDoc.id);
    const studies = (
      await getDocs(
        query(
          collectionGroup(this.firestore, 'studies'),
          where('owner', '==', currentUser.uid),
          where('collageId', 'in', collageIds)
        )
      )
    ).docs.map(item => item.ref);
    if (studies.length > 0) {
      docWrites.push(
        ...studies.map(studyDoc => ({
          ref: `studies/${studyDoc.id}`,
          writeMethod: WriteBatchMethod.Delete
        }))
      );
    }
    return this.writeDocsInBatches(docWrites);
  }

  public getImageConnectionList(): Observable<Array<IConnection>> {
    return this.auth.currentUser
      ? collectionData(
          query(
            collection(this.firestore, 'connections'),
            where('owner', 'in', [this.auth.currentUser.uid, '*']),
            where('type', '==', ConnectionType.Image),
            orderBy('favorite', 'desc'),
            orderBy('lastViewed', 'desc')
          )
        )
      : of([]);
  }

  public getBusinessConnectionList(): Observable<Array<IBusinessConnection>> {
    return this.auth.currentUser
      ? collectionData(
          query(
            collection(this.firestore, 'connections'),
            where('owner', 'in', [this.auth.currentUser.uid, '*']),
            where('type', '==', ConnectionType.Business),
            orderBy('favorite', 'desc'),
            orderBy('lastViewed', 'desc')
          )
        )
      : of([]);
  }

  /* Whitelist */

  public getDomainWhiteList(): Observable<IWhitelist> {
    return collectionData(collection(this.firestore, 'whitelist')).pipe(map(items => items[0] as IWhitelist));
  }

  /* User data */

  public addUserData(id: string, data: IUserData): Promise<void> {
    return setDoc(doc(this.firestore, `users/${id}`), data);
  }

  public getCurrentUserData(): Observable<IUserData | null> {
    return this.auth.currentUser
      ? (docData(doc(this.firestore, `users/${this.auth.currentUser.uid}`)) as Observable<IUserData>)
      : of(null);
  }

  public updateUserData(id: string, groups: Array<UserGroup>): Promise<void> {
    return updateDoc(doc(this.firestore, `users/${id}`), { groups });
  }

  /* Other methods */

  private writeDocsInBatches<T = DocumentData>(docWrites: Array<IDocToWriteInBatch<T>>): Promise<any> {
    if (docWrites.length === 0) return Promise.resolve();
    if (docWrites.length <= this.MAX_WRITES_PER_BATCH) {
      const batch = writeBatch(this.firestore);
      docWrites.forEach(docItem => {
        switch (docItem.writeMethod) {
          case WriteBatchMethod.Set:
            batch.set(doc(this.firestore, docItem.ref), docItem.data as T);
            break;
          case WriteBatchMethod.Update:
            batch.update(doc(this.firestore, docItem.ref), docItem.data as any);
            break;
          case WriteBatchMethod.Delete:
            batch.delete(doc(this.firestore, docItem.ref));
            break;
        }
      });
      return batch.commit();
    }
    return this.multipleDocsWrite({ docWrites });
  }

  private getAllFrames<T extends { frames?: Array<IFrameStateModel> }>(item: T): Array<IFrameStateModel> {
    return item.frames
      ? [
          ...item.frames,
          ...item.frames.reduce(
            (result: Array<IFrameStateModel>, frame: IFrameStateModel) => [...result, ...this.getAllFrames(frame)],
            []
          )
        ]
      : [];
  }

  private setUidToImages(images: Array<IImageStateModel>): Array<IImageStateModel> {
    return images.map(image => ({
      ...image,
      uid: shortUid()
    }));
  }

  private setUidToFrameImages(frames: Array<IFrameStateModel>): Array<IFrameStateModel> {
    return frames.map(frame => ({
      ...frame,
      frames: this.setUidToFrameImages(frame.frames),
      images: this.setUidToImages(frame.images)
    }));
  }
}
