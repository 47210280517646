<div class="dotBG" #dotBGWithOpacity></div>
<div class="dotBG" #dotBG></div>
<div class="canvasWrapper" id="matrixCanvasWrapper"></div>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed"
  [style.left.px]="contextMenuPosition.x"
  [style.top.px]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
  <ng-template matMenuContent let-items="items">
    <button mat-menu-item (click)="onDownloadImages(items)">Download image{{ items.length > 1 ? 's' : '' }}</button>
  </ng-template>
</mat-menu>
