import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOne'
})
export class HasOnePipe implements PipeTransform {
  transform<T>(array: Array<T>, values: Array<T>): boolean {
    return Array.isArray(array) && Array.isArray(values) && values.some((value: T) => array.includes(value));
  }
}
