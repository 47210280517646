export const dark = {
  'background-color': '#000000',
  'button-background': '#C09CC5',
  'button-text-color': '#fff',
  'button-border-color': '#decce2',
  'active-background-color': '#2c2c2c',
  'active-border-background-color': '#9e6caf',
  'active-link-color': '#fff',
  'in-active-link-color': '#b5b5b5',
  'border-color': '#605361',
  'left-side-inner-div-background-color': '#383538',
  'text-color': '#fff',
  'content-background-color': '#2c2c2c'
};

export const light = {
  'background-color': '#2c2c2c',
  'button-background': '#fff',
  'button-text-color': '#111',
  'button-border-color': '#decce2',
  'active-background-color': '#000000',
  'active-border-background-color': '#9e6caf',
  'active-link-color': '#fff',
  'in-active-link-color': '#b5b5b5',
  'border-color': '#ddd',
  'left-side-inner-div-background-color': '#faf7fb',
  'text-color': '#000',
  'content-background-color': '#ffffff'
};
