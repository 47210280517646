<app-dialog-base-component (close)="dialogRef.close()">
  <mat-dialog-content>
    <div class="create-new-study-container">
      <div class="collages-list">
        <div class="dialog-title">Select Collage to create new Study</div>
        <app-home-list
          [type]="PortalMenuItem.Collages"
          [isHideAddNew]="true"
          (select)="onClose($any($event))"
        ></app-home-list>
      </div>
    </div>
  </mat-dialog-content>
</app-dialog-base-component>
