import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-base-component',
  templateUrl: './dialog-base-component.component.html',
  styleUrls: ['./dialog-base-component.component.scss']
})
export class DialogBaseComponentComponent {
  @Output() public close: EventEmitter<void> = new EventEmitter();

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.close.emit();
  }

  public onClose() {
    this.close.emit();
  }
}
