<div class="wrapper">
  <app-home-card-header
    [type]="PortalMenuItem.Studies"
    [name]="study.metadata.name"
    [id]="study.id"
    [favorite]="study.favorite"
    [owner]="study.owner"
  ></app-home-card-header>
  <app-home-card-body
    [type]="PortalMenuItem.Studies"
    [id]="study.id"
    [authorName]="study.metadata.authorName"
    [createdDate]="study.created"
  >
    <div class="collage">
      <svg-icon svgClass="icon" name="icon-collage"></svg-icon>
      <span class="collage__name">{{ study.metadata.collageName }}</span>
    </div>
  </app-home-card-body>
</div>
