import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CollageState } from '@app/state/collage/collage.state';
import { StudyState } from '@app/state/study/study.state';
import { IStudy } from '@app/state/study/study.model';
import { SetActiveStudyName } from '@app/state/study/study.actions';
import { filter } from 'rxjs/operators';
import { User } from '@angular/fire/auth';
import { AuthService } from '@app/services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-left-header',
  templateUrl: './left-header.component.html',
  styleUrls: ['./left-header.component.scss']
})
export class LeftHeaderComponent implements OnInit {
  public readonly studyPlaceholder = 'Untitled Study';

  public studyTitle: string = null;
  public studyOwner: string = null;
  public collageName: string = null;
  private currentUser: User;

  showBetaSign: boolean = false;

  constructor(private store: Store, private authService: AuthService) {}

  ngOnInit(): void {
    this.store
      .select(StudyState)
      .pipe(untilDestroyed(this))
      .subscribe((study: IStudy) => {
        if (study === null) {
          this.studyTitle = null;
        } else {
          this.studyTitle = study.metadata.name === this.studyPlaceholder ? '' : study.metadata.name;
          this.studyOwner = study.owner;
        }
      });
    this.store
      .select(CollageState.getActiveCollageName)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((name: string) => {
        this.collageName = name;
      });
    this.currentUser = this.authService.currentUser;

    // this.showBetaSign = window.location.host.includes('localhost');
    this.showBetaSign = window.location.host === 'beta.collagia.com';
  }

  public setStudyName(event): void {
    if (this.studyOwner === this.currentUser.uid) {
      const name = (event.target as HTMLSpanElement).textContent.trim();
      if (name.length > 0) {
        this.store.dispatch(new SetActiveStudyName(event.target.textContent.trim()));
      }
    }
  }
}
