import { Injectable } from '@angular/core';
import { IFiltersModel } from '@app/models/filters.model';
import {
  DeleteActiveWorkspace,
  DuplicateWorkspace,
  SetActiveStudy,
  SetActiveWorkspaceId,
  SetAxesXY
} from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, Select, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable, filter, switchMap, take } from 'rxjs';
import { debounce, debounceTime, map, withLatestFrom } from 'rxjs/operators';
import {
  ResetActiveStudy,
  SetCustomAttributeFilters,
  UpdateCustomAttributeFilter
} from '@app/state/study/study.actions';
import { LookupTableService } from '../../components/lookup-table';
import { FiltersService } from '../filters/filters.service';
import { DataService } from '../data/data.service';
import { UpdateDimension } from '@app/state/user-data/user-data.actions';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BackwardFilteringService {
  private readonly changeFiltersActions = [
    ResetActiveStudy,
    SetActiveStudy,
    SetActiveWorkspaceId,
    DuplicateWorkspace,
    DeleteActiveWorkspace,
    SetCustomAttributeFilters,
    SetAxesXY
  ];

  @Select(StudyState.getCustomAttributeFilters)
  private readonly customAttributeFilters$: Observable<Array<IFiltersModel>>;
  @Select(UserDataState.getDimensions)
  public dimensions$: Observable<Array<IFiltersModel>>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private lookupTable: LookupTableService,
    private filtersService: FiltersService,
    private dataService: DataService
  ) {
    this.subBackwardFiltering();
  }

  private subBackwardFiltering(): void {
    const axesPrepared$ = this.actions$.pipe(
      ofActionSuccessful(...this.changeFiltersActions),
      debounce(() => this.dimensions$.pipe(filter(Boolean))),
      switchMap(() => this.filtersService.preparedUserFilters$.pipe(take(1))),
      untilDestroyed(this)
    );
    axesPrepared$
      .pipe(
        withLatestFrom(
          this.dimensions$,
          this.customAttributeFilters$,
          this.filtersService.preparedUserFilters$.pipe(map(userFilters => userFilters.y))
        ),
        untilDestroyed(this),
        debounceTime(1000) // start only after 1 sec (user can select another userFilters in short time)
      )
      .subscribe(([axesPrepared, dimensions, customAttributeFilters, userFilters]) => {
        // --> console.log('-->> subBackwardFiltering', dimensions, customAttributeFilters, userFilters);
        if (dimensions && customAttributeFilters) {
          const allFilters = [...dimensions, ...customAttributeFilters];
          this.makeBackwardFiltering(allFilters, userFilters);
        }
      });
  }

  private makeBackwardFiltering(filters: Array<IFiltersModel>, userFilters: Array<IFiltersModel>) {
    this.dataService.cancelApiGetBackwardFiltering(); // stop all requests which not completed yet

    // send API request for each filter separately to avoid Timeout issue when filters contain thousands of values
    filters.forEach((singleFilter: IFiltersModel) => {
      if (!singleFilter.discreteValues) {
        return; // we don't need to calculate productsLength for Measured filters
      }
      // cleanup previous productsLength
      singleFilter.discreteValues.forEach(value => delete value.productsLength);
      if (singleFilter.isCustomAttr) {
        this.store.dispatch(new UpdateCustomAttributeFilter(singleFilter));
      } else {
        this.store.dispatch(new UpdateDimension(singleFilter));
      }

      // calculate new productsLength
      this.dataService.apiGetBackwardFiltering([singleFilter], userFilters).subscribe(result => {
        if (result.customAttrFilters?.length > 0) {
          this.store.dispatch(new UpdateCustomAttributeFilter(result.customAttrFilters[0]));
        }
        if (result.filters?.length > 0) {
          this.store.dispatch(new UpdateDimension(result.filters[0]));
        }
      });
    });
  }
}
