import { Component } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-right-header',
  templateUrl: './right-header.component.html',
  styleUrls: ['./right-header.component.scss']
})
export class RightHeaderComponent {
  public currentUser: User;
  public accountMenuOpened: boolean = false;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.currentUser;
  }

  public logout(): void {
    this.authService.logout();
  }
}
