import { ValidMatrix } from '@app/state/tools/tools.model';

export const ExploreBarSettings: { [key in ValidMatrix]?: IExploreBarSettingsModel } = {
  manualHarvest: {
    groupingComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    highlighterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    }
  },
  rank: {
    filterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    sortingComponent: {
      showMeasures: true,
      showDimensions: false,
      showSubFilters: false,
      enableFiltersToggle: false,
      enableExpansion: false,
      showNumEntries: true,
      showDirectionIndicator: true
    },
    groupingComponent: {
      showMeasures: false,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    highlighterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    }
  },
  groupBy: {
    groupingComponent: {
      showMeasures: false,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    filterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    highlighterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    }
  },
  pareto: {
    sortingComponent: {
      showMeasures: true,
      showDimensions: false,
      showSubFilters: false,
      enableFiltersToggle: false,
      enableExpansion: false,
      showToggleAdditionalInfo: true
    },
    filterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    },
    highlighterComponent: {
      showMeasures: true,
      showDimensions: true,
      showSubFilters: true,
      enableFiltersToggle: true,
      enableExpansion: true
    }
  }
};

export interface IExploreBarSettingsModel {
  filterComponent?: IExploreBarSubComponentSettingsModel;
  sortingComponent?: IExploreBarSubComponentSettingsModel;
  groupingComponent?: IExploreBarSubComponentSettingsModel;
  highlighterComponent?: IExploreBarSubComponentSettingsModel;
}

export interface IExploreBarSubComponentSettingsModel {
  showMeasures: boolean;
  showDimensions: boolean;
  showSubFilters: boolean;
  enableFiltersToggle: boolean;
  enableExpansion: boolean;
  showActionButtons?: boolean;
  showNumEntries?: boolean;
  showDirectionIndicator?: boolean;
  showToggleAdditionalInfo?: boolean; // Only for Pareto, might need to remove once design is updated
}
