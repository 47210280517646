export const screenWidth4k = 3840;
export const imageBaseSize = 256;
export const baseDistanceBetweenImages = 32;

export const imagePosChangeInTime = 16; // time in millisecond since last update, bigger values causes faster stop
export const stageFriction = 0.85; // Percent to decelerate after movement. This should be between 0 and 1, exclusive.
export const imageMinSpeed = 0.1; // Minimum velocity before stopping/reversing acceleration
export const animationFrameInMS = 16; // approximation of 1frame in ms;

export const dropShadowPadding = 20;
