<form #form="ngForm" class="auth-form" [formGroup]="registerForm" (ngSubmit)="form.valid && register()">
  <div class="form-field" [ngClass]="{ invalid: (form.submitted || fullName.touched) && fullName.invalid }">
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-user" name="icon-user-account"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="text"
      formControlName="fullName"
      placeholder="Full name"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || fullName.touched) && fullName.invalid" class="error">
      <ng-container *ngIf="fullName.hasError('required')"> Full name is required </ng-container>
    </div>
  </div>
  <div class="form-field" [ngClass]="{ invalid: (form.submitted || email.touched) && email.invalid }">
    <div class="form-field__prefix">
      <svg-icon svgClass="icon" name="icon-email-filled"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="email"
      formControlName="email"
      placeholder="Email"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || email.touched) && email.invalid" class="error">
      <ng-container *ngIf="email.hasError('required')"> Email is required </ng-container>
      <ng-container *ngIf="email.hasError('email')"> Enter valid email address </ng-container>
    </div>
  </div>
  <div
    class="form-field"
    [ngClass]="{
      invalid: (form.submitted || password.touched) && password.invalid
    }"
  >
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="password"
      formControlName="password"
      placeholder="Password"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || password.touched) && password.hasError('required')" class="error">
      Password is required
    </div>
  </div>
  <div
    class="form-field"
    [ngClass]="{
      invalid: (form.submitted || confirmPassword.touched) && confirmPassword.invalid
    }"
  >
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="password"
      formControlName="confirmPassword"
      placeholder="Confirm password"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || confirmPassword.touched) && registerForm.hasError('notmatched')" class="error">
      The password confirmation does not match
    </div>
  </div>
  <div class="auth-form__actions">
    <mat-checkbox class="terms-checkbox" formControlName="agreeTerms" labelPosition="after" color="accent">
      I have read and agreed to the <a>terms and conditions</a>
    </mat-checkbox>
    <div *ngIf="form.submitted && agreeTerms.hasError('required')" class="error">
      Please indicate that you have read and agree to the Terms and Conditions
    </div>
  </div>
  <div class="auth-form__actions">
    <button mat-flat-button color="accent" type="submit">REGISTER</button>
  </div>
  <div class="question align-center">
    <span>Already have an account?</span>
    <a class="question__link" [routerLink]="['/auth/login']">Log in</a>
  </div>
</form>
