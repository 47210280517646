export enum CursorStyles {
  Default = 'default',
  Pointer = 'pointer',
  Drag = 'default',
  TopLeftResize = 'nwse-resize',
  TopRightResize = 'nesw-resize',
  BottomRightResize = 'nwse-resize',
  BottomLeftResize = 'nesw-resize',
  LeftResize = 'ew-resize',
  TopResize = 'ns-resize',
  RightResize = 'ew-resize',
  BottomResize = 'ns-resize',
  Crosshair = 'crosshair',
  NotAllowed = 'not-allowed',
  Auto = 'auto',
  None = 'none',
  Move = 'move',
  TopLeftRotate = 'topLeftRotate',
  TopRightRotate = 'topRightRotate',
  BottomRightRotate = 'bottomRightRotate',
  BottomLeftRotate = 'bottomLeftRotate',
  FrameInfo = 'pointer',
  PublishIcon = 'pointer',
  Highlighter = `url('/assets/icons/custom-cursor-highlight.png') 2 21,auto`
}
export enum CursorStates {
  Default = 'Default',
  Drag = 'Drag',
  TopLeftResize = 'TopLeftResize',
  TopRightResize = 'TopRightResize',
  BottomRightResize = 'BottomRightResize',
  BottomLeftResize = 'BottomLeftResize',
  LeftResize = 'LeftResize',
  TopResize = 'TopResize',
  RightResize = 'RightResize',
  BottomResize = 'BottomResize',
  Crosshair = 'Crosshair',
  NotAllowed = 'NotAllowed',
  Auto = 'Auto',
  None = 'None',
  Move = 'Move',
  TopLeftRotate = 'TopLeftRotate',
  TopRightRotate = 'TopRightRotate',
  BottomRightRotate = 'BottomRightRotate',
  BottomLeftRotate = 'BottomLeftRotate',
  FrameInfo = 'FrameInfo',
  PublishIcon = 'PublishIcon'
}
