<div class="wrapper" #wrapper *ngIf="show">
  <div class="backdrop" (click)="onBackdropClick()"></div>
  <div class="workspaces-wrapper" [@openCloseWidthFadeScale]="show ? 'open' : 'closed'">
    <div
      *ngFor="let workspace of workspaces"
      class="workspace"
      (click)="onSelectWorkspace(workspace)"
      [ngClass]="{ active: workspace.id === (activeWorkspaceId$ | async) }"
    >
      <div class="menu">
        <button
          *ngIf="icons.get(workspace.matrixType)"
          class="icon-button matrix-type"
          [matTooltip]="workspace.matrixType | matrixIconTooltip : workspace.isNeedRebuild"
          matTooltipPosition="above"
          matTooltipClass="above"
        >
          <svg-icon svgClass="matrix-type-icon" [name]="icons.get(workspace.matrixType)?.iconName"></svg-icon>
        </button>
        <div class="need-rebuild" *ngIf="workspace.isNeedRebuild"></div>
        <div class="additional-options">
          <button
            [disabled]="!(canAddWorkspace$ | async)"
            class="context-menu-item-button"
            matTooltip="Duplicate"
            matTooltipPosition="above"
            matTooltipClass="above"
            (click)="onDuplicate($event, workspace)"
          >
            <svg-icon svgClass="icon" name="icon-duplicate"></svg-icon>
          </button>
          <button
            class="context-menu-item-button"
            matTooltip="Delete"
            matTooltipPosition="above"
            matTooltipClass="above"
            (click)="onDelete($event, workspace.id)"
          >
            <svg-icon svgClass="icon" name="icon-trash"></svg-icon>
          </button>
        </div>
      </div>
      <img *ngIf="workspace.img" [src]="workspace.img.src" />
    </div>
  </div>
</div>
