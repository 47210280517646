import { trigger, state, style, animate, transition, query, stagger, group, animateChild } from '@angular/animations';

export const openCloseAnimation = trigger('openCloseWidthFadeScale', [
  state(
    'open',
    style({
      opacity: 1,
      transform: 'scale(1)'
    })
  ),
  state(
    'openWithFlex',
    style({
      opacity: 1,
      transform: 'scale(1)'
    })
  ),
  state(
    'closed',
    style({
      opacity: 0,
      transform: 'scale(0.7)',
      display: 'none'
    })
  ),
  transition('open => closed', [animate('50ms ease-out')]),
  transition('openWithFlex => closed', [animate('50ms ease-out')]),
  transition('closed => open', [style({ display: 'block' }), animate('100ms ease-out')]),
  transition('closed => openWithFlex', [style({ display: 'flex' }), animate('100ms ease-out')])
]);

export const expendTopCompassAnimation = trigger('expendTopCompassAnimation', [
  state(
    'expanded',
    style({
      opacity: 1,
      height: '150px'
    })
  ),
  state(
    'closed',
    style({
      opacity: 0,
      height: 0
    })
  ),
  transition('expanded => closed', [animate('280ms ease-out')]),
  transition('closed => expanded', [animate('280ms ease-out')])
]);

export const expendInsightsPanelAnimation = trigger('expendInsightsPanelAnimation', [
  state(
    'expanded',
    style({
      width: '260px',
      padding: '12px'
    })
  ),
  state(
    'closed',
    style({
      width: '48px',
      padding: 0
    })
  ),
  transition('expanded <=> closed', [
    group([
      query('@animateInsightsPanel', animateChild()),
      query('@animateInsightsPanelButton', animateChild()),
      animate('280ms ease-out')
    ])
  ])
]);

export const animateInsightsPanel = trigger('animateInsightsPanel', [
  state('expanded', style({ opacity: 1, height: '*', borderWidth: '1px' })),
  state('closed', style({ opacity: 0, height: 0, borderWidth: 0 })),
  transition('expanded <=> closed', [query('*', style({ display: 'none' })), animate('280ms ease-out')])
]);

export const animateInsightsPanelButton = trigger('animateInsightsPanelButton', [
  state('expanded', style({ padding: 0 })),
  state('closed', style({ padding: '8px' })),
  transition('expanded <=> closed', [animate('187ms ease-out')])
]);

export const moveInsightsPanelAboveCompassAnimation = trigger('moveInsightsPanelAboveCompassAnimation', [
  state(
    'expanded',
    style({
      bottom: '216px',
      maxHeight: 'calc(100vh - 337px)'
    })
  ),
  state(
    'closed',
    style({
      bottom: '66px',
      maxHeight: 'calc(100vh - 187px)'
    })
  ),
  transition('expanded => closed', [animate('280ms ease-out')]),
  transition('closed => expanded', [animate('280ms ease-out')])
]);

export const expendStatsBarAnimation = trigger('expendStatsBarAnimation', [
  state(
    'expanded',
    style({
      height: '{{height}}px'
    }),
    { params: { height: 0 } } // default parameters values required
  ),
  state(
    'closed',
    style({
      height: '40px'
    })
  ),
  transition('expanded => closed', [animate('280ms ease-out')]),
  transition('closed => expanded', [animate('280ms ease-out')])
]);

export const matExpantionPanelAnimation = trigger('matExpantionPanelAnimation', [
  state(
    'open',
    style({
      width: '210px'
    })
  ),
  state(
    'closed',
    style({
      width: '48px',
      height: '48px'
    })
  ),
  transition('open => closed', [
    query('.mat-expansion-panel-body', [style({ opacity: 0 })]),
    animate('280ms ease-out')
  ]),
  transition('closed => open', [query('.mat-expansion-panel-body', [style({ opacity: 0 })]), animate('280ms ease-out')])
]);

export const compassExpandAnimation = trigger('compassExpandAnimation', [
  state(
    'expanded',
    style({
      width: '260px',
      height: '200px'
    })
  ),
  state(
    'minimized',
    style({
      width: '48px',
      height: '48px'
    })
  ),
  state(
    'expanded4K',
    style({
      width: '520px',
      height: '400px'
    })
  ),
  state(
    'minimized4K',
    style({
      width: '96px',
      height: '96px'
    })
  ),
  transition('expanded => minimized', [animate('50ms ease-out')]),
  transition('minimized => expanded', [animate('100ms ease-out')]),
  transition('expanded4K => minimized4K', [animate('50ms ease-out')]),
  transition('minimized4K => expanded4K', [animate('100ms ease-out')])
]);

export const carouselCellTitleAnimation = trigger('carouselCellTitleAnimation', [
  state(
    'recentlyActive',
    style({
      bottom: '-7px',
      transform: 'scale(0.8)'
    })
  ),
  state(
    'idle',
    style({
      bottom: '-7px',
      transform: 'scale(0.65)'
    })
  ),
  state(
    'active',
    style({
      bottom: '-35px',
      transform: 'scale(1)'
    })
  ),
  transition('* => recentlyActive', [
    style({ bottom: '-35px', transform: 'scale(1)' }),
    animate('200ms', style({ bottom: '-7px', transform: 'scale(0.65)' }))
  ]),
  transition('* => active', [
    style({ bottom: '-7px', transform: 'scale(0.65)' }),
    animate('200ms', style({ bottom: '-35px', transform: 'scale(1)' }))
  ])
]);

export const carouselCellImgAnimation = trigger('carouselCellImgAnimation', [
  state(
    'recentlyActive',
    style({
      opacity: '0.9',
      transform: 'scale(0.6)'
    })
  ),
  state(
    'idle',
    style({
      opacity: '0.9',
      transform: 'scale(0.6)'
    })
  ),
  state(
    'active',
    style({
      opacity: '1',
      transform: 'scale(0.9)'
    })
  ),
  transition('* => recentlyActive', [
    style({ opacity: '1', transform: 'scale(0.9)' }),
    animate('200ms', style({ opacity: '0.9', transform: 'scale(0.6)' }))
  ]),
  transition('* => active', [
    style({ opacity: '0.9', transform: 'scale(0.6)' }),
    animate('200ms', style({ opacity: '1', transform: 'scale(0.9)' }))
  ])
]);

export const paretoPrevBlockListAnimation = trigger('paretoPrevBlockListAnimation', [
  transition('* <=> *', [
    query(':enter', [style({ opacity: 0 }), stagger('50ms', animate('280ms ease-out', style({ opacity: 1 })))], {
      optional: true
    }),
    query(':leave', [style({ opacity: 1 }), stagger('-50ms', animate('280ms ease-out', style({ opacity: 0 })))], {
      optional: true
    })
  ])
]);
