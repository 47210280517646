import { ComponentFactoryResolver, ComponentRef, Inject, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupTableService } from '@app/components/lookup-table';
// import { ApiHarvestEngineService } from '@app/core/api/services/harvest-engine/api-harvest-engine.service';
import { GroupLabelsContainer } from '@app/custom-pixi-containers/group-labels-container';
import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { IBounds } from '@app/models/bounds.model';
import { GroupOrientation, IHoverInfoFieldModel } from '@app/models/study-setting.model';
import { IPosition, IWorkspaceTools, IZoomSettings } from '@app/models/workspace-list.model';
import { AppLoaderService } from '@app/shared/components/loader/app-loader.service';
import { MatrixToolEnum, ValidMatrix } from '@app/state/tools/tools.model';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { DataService } from '../data/data.service';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import { LOW_IMAGE_SIZE_RESOURCE } from './base-matrix';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { StudyState } from '@app/state/study/study.state';
import gsap from 'gsap/all';
import {
  IParetoAdditionalDataModel,
  IParetoColumnSizeSubject,
  IParetoDataModel,
  IParetoGroupModel,
  IParetoImageMatrixBoundsModel,
  IParetoProductDataModel
} from '@app/models/pareto-model';
import { ParetoSubtotalPercentOverlayComponent } from '@app/components/build/pareto-subtotal-percent-overlay/pareto-subtotal-percent-overlay.component';
import { Viewport } from 'pixi-viewport';
import { WINDOW } from '@app/shared/utils/window';
import { ParetoColumnSizePickerComponent } from '@app/components/build/pareto-column-size-picker/pareto-column-size-picker.component';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import { IPresentationState } from '@app/models/presentation.models';
import { dropShadowPadding } from '@app/shared/constants/viewport';
import { FriendlyNameService } from '@services/friendly-name/friendly-name.service';
import { Container, Renderer, Sprite, Texture } from 'pixi.js';
import { shortUid } from '@app/shared/utils/short-uid';
import { FiltersService } from '@services/filters/filters.service';
import { BaseMatrix } from './base-matrix';

export class ParetoMatrix extends BaseMatrix {
  public paretoAditionalDataSubject = new Subject<IParetoAdditionalDataModel>();
  public paretoDislpayDataSubject = new Subject<Array<IParetoGroupModel>>();
  public paretoColumnSizeSubject = new Subject<IParetoColumnSizeSubject>();
  public paretoImageMatrixBoundsSubject = new Subject<IParetoImageMatrixBoundsModel>();
  public paretoImageMatrixBounds: IParetoImageMatrixBoundsModel;
  public updateViewportOnCompass = new Subject<null>();

  private imageMatrixPaddingsPareto = { top: 120, bottom: 220, leftRight: 100 };
  private additionalData: IParetoAdditionalDataModel;

  private showImagePercentages = false;

  private imagePercentContainer: HTMLDivElement;
  private imagePercentOverlays: { [key: string]: HTMLElement } = {};
  private subTotalPercentOverlayComponentRef: ComponentRef<ParetoSubtotalPercentOverlayComponent>;
  private subTotalPercentOverlay: HTMLDivElement;
  private ColumnSizePickerComponentRef: ComponentRef<ParetoColumnSizePickerComponent>;
  private ColumnSizePicker: HTMLDivElement;

  private imageAnimationTimeline: gsap.core.Timeline;
  private imageAnimationDuration = 0.28;

  private groupMaxLength = 0;
  private maxPossibleNumSubColumns;
  private numSubColumns;
  private currentColumnSize;
  private minColumnSize;
  private maxColumnSize;

  private columnSizes = [];
  private distanceBetweenColumns: number;
  private subtotalOverlayBlockSize: number;
  private blockMinSizeOnScreen: number = 100;
  private imageMatrixSizeTemp = 0;

  private imagePositionData = {};
  private imageXPositions = [];
  private imageYPositions = [];

  /**
   * Used for correct size calculations during animations
   * can be used for testing sizes and positions with Texture.WHITE
   */
  protected imageMatrixResizerSpriteRef = new Sprite(Texture.EMPTY);

  constructor(
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override stage: Container,
    protected override renderer: Renderer,
    protected override viewport: Viewport,
    protected override imageInfoOverlayRef: ImageInfoOverlay,
    protected override store: Store,
    protected override snackBar: MatSnackBar,
    protected override resourceManagerService: ResourceManagerService,
    protected override dataService: DataService,
    protected override lookupTable: LookupTableService,
    protected override appLoaderService: AppLoaderService,
    protected override dropShadowService: DropShadowService,
    protected override friendlyNameService: FriendlyNameService,
    protected override filterService: FiltersService,
    protected override viewContainerRef: ViewContainerRef,
    private factoryResolver: ComponentFactoryResolver
  ) {
    super(
      window,
      stage,
      renderer,
      viewport,
      imageInfoOverlayRef,
      store,
      snackBar,
      resourceManagerService,
      dataService,
      lookupTable,
      appLoaderService,
      dropShadowService,
      friendlyNameService,
      filterService,
      viewContainerRef
    );

    this.recenterMatrix = new Subject();
    this.groupLabelsContainer = new GroupLabelsContainer(this.dataService);
  }

  public onShowImagePercentages(show: boolean) {
    this.showImagePercentages = show;
    this.updateImagePercentOverlayVisibility();
  }

  public setDisplayData(data: IParetoDataModel, restore: boolean): void {
    if (data && data.animate && !restore) {
      this.displayData = data.displayData || [];
      this.additionalData = data.additionalData;
      this.updateColumnsOnHandlersChange();
      if (this.displayData?.length > 0) this.animateMatrix();
      this.matrixState = undefined;
      return;
    }

    this.isUpdating.next(true);
    this.clearMatrix();
    this.displayData = data?.displayData || [];
    this.additionalData = data?.additionalData;
    this.updateBaseRefs();
    if (restore) {
      this.restoreMatrix();
    } else {
      this.createMatrix();
    }
  }

  public override setGroupOrientation(groupOrientation: GroupOrientation, updateMatrix: boolean = false): void {
    this.groupOrientation = groupOrientation || GroupOrientation.Vertical;
    if (updateMatrix && this.displayData?.length > 0)
      this.setDisplayData({ displayData: this.displayData, additionalData: this.additionalData }, false);
  }

  public override setColumnSize(columnSize: number) {
    this.currentColumnSize = columnSize;
    this.updateColumnsOnColumnSizeChange();
    if (this.displayData?.length > 0) this.animateMatrix();
  }

  // Update info for rendered images, if config of Image Hover Info has been changed
  public updateImageData(data: IHoverInfoFieldModel[]): void {
    // --> console.log('!!! Pareto.updateImageData > getImageAggregatedHoverInfo', data);
    const filteredRows = this.store.selectSnapshot(UserDataState.getFilteredRows);
    this.dataService.getImageAggregatedHoverInfo(filteredRows, null, imageData => {
      this.imageRefs.forEach(image => {
        image.setData(imageData[image.getId()]);
        if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
          this.imageInfoOverlayRef.updateText(image);
          this.imageInfoOverlayRef.show();
        }
      });
    });
  }

  protected createMatrix(restore = false): void {
    const images = [];
    this.displayData.forEach(group => {
      group.products.forEach(prod => images.push({ prodId: prod.id }));
    });
    this.checkForPlaceholderMode(images);
    this.productsToShow = [...images];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    setTimeout(() => {
      this.loadProductImages(restore);
    });
  }

  private loadProductImages(restore: boolean) {
    if (this.loader) this.resourceManagerService.stopLoader(this.loader);
    // this.resourceManagerService.stopActiveLoaders();
    if (this.isPlaceholderMode) {
      this.onProductLoadComplete(restore);
      return;
    }
    const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);

    if (resources.length > 0) this.sendLowResImageLoadingStart();

    this.loader = this.resourceManagerService.loadResources(
      resources,
      null,
      null,
      this.onProductLoadComplete.bind(this, restore)
    );
  }

  private onProductLoadComplete(restore: boolean) {
    if (this.matrixType !== ValidMatrix.Pareto) {
      // bugfix: if a few previous Study used other Matrix type, all of them already subscribed to the Product Load Complete
      // so, do nothing if it's not a current matrix
      return;
    }
    this.isUpdating.next(false);
    this.clearCompass.next(false);
    // // --> console.log('+++> Rank.onProductLoadComplete', restore);
    this.sendLowResImageLoadingCompleted();
    this.sendDataLoadingCompleted();

    if (!this.displayData || this.displayData.length === 0) {
      this.updateMatrixDimensions();
      this.renderNewMatrix.next({ restore });
      this.setCanvasState(false);
      return;
    }

    this.updateColumnsOnFilterChange();
    this.updateDistanceAndBlockSize();
    this.updateImagePositions();

    this.imageMatrix.addChildAt(this.imageMatrixResizerSpriteRef, 0);
    this.clearImageMatrixResizerSpriteBounds();

    this.displayData.forEach((group, i) => {
      group.products.forEach((prod, j) => {
        const uid = restore
          ? this.matrixState?.imageMatrix?.images?.find(item => item.id === prod.id)?.uid
          : shortUid();
        const image = this.createImage(
          uid,
          prod.id,
          prod.id,
          { x: this.imagePositionData[prod.id].x, y: this.imagePositionData[prod.id].y },
          this.imageMatrix,
          prod.imageData.data,
          this.lookupTable.getImagePlaceholder()
        );
        image.alpha = 1;
        this.addImageHoverListeners(image);
        this.addImageClickListeners(image);
      });
    });

    this.imageRefsForHighlight = [...this.imageRefs];

    if (!this.active) return;

    this.createImagePercentOverlays();
    this.createSubTotalPercentOverlay();
    this.createColumnSizePicker();
    this.updateColumnSizeSubject();
    this.paretoAditionalDataSubject.next({ ...this.additionalData });

    this.updateMatrixDimensions();
    this.renderNewMatrix.next({ restore });
    this.matrixState = undefined;

    setTimeout(() => {
      this.onZoomChange(this.zoomFactor, true);
      this.onViewportMove();

      if (!restore) {
        this.setCanvasState(false);
      }

      this.render();
    });

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    }
  }

  private updateImagePositions() {
    this.imageXPositions = [];
    this.imageYPositions = [];
    if (this.groupOrientation === GroupOrientation.Vertical) {
      this.updateVerticalImagePositions();
    } else {
      this.updateHorizontalImagePositions();
    }
  }

  private updateVerticalImagePositions() {
    const maxPossibleColumnLength = Math.max(...(this.displayData.map(group => group.products.length) as number[]));
    this.groupMaxLength = maxPossibleColumnLength;
    let subColumnMaxLength = 0;

    this.imageXPositions = [];
    let imageMatrixSize = 0;
    this.columnSizes = [];
    this.displayData.forEach((group, i) => {
      const columnX = imageMatrixSize;

      const numSubColumnsInCurrGroup = this.getNumSubColumnsInCurrGroup(group.products);
      const subColumnsArray = this.generateSubColumns(group.products, numSubColumnsInCurrGroup).filter(
        arr => arr.length > 0
      );

      const columnSize =
        subColumnsArray.length * (this.imageDesiredSizes.width + this.distanceBetweenImages) -
        this.distanceBetweenImages;
      const blockSize = this.subtotalOverlayBlockSize;
      const realColumnSize = columnSize > blockSize ? columnSize : blockSize;
      imageMatrixSize += realColumnSize + this.distanceBetweenColumns;

      this.columnSizes.push(columnSize);

      const columnXOffset = (realColumnSize - columnSize) / 2;

      this.imageXPositions.push({
        columnX, // TODO do we need this?
        columnXOffset, // TODO do we need this?
        images: []
      });
      subColumnsArray.forEach((subGroup, k) => {
        if (subGroup.length > subColumnMaxLength) subColumnMaxLength = subGroup.length;

        const subColumnYOffset = maxPossibleColumnLength - subGroup.length;
        const subColumnX = k * (this.imageDesiredSizes.width + this.distanceBetweenImages);

        subGroup.forEach((prod, j) => {
          this.imagePositionData[prod.id] = {
            x: columnX + subColumnX + columnXOffset,
            y: (j + subColumnYOffset) * (this.imageDesiredSizes.width + this.distanceBetweenImages)
          };
          this.imageXPositions[i].images.push({
            id: prod.id,
            x: subColumnX
          });
        });
      });
    });
    this.imageMatrixSizeTemp = imageMatrixSize - this.distanceBetweenColumns;
    if (subColumnMaxLength > 0) {
      this.groupMaxLength = subColumnMaxLength;
      Object.keys(this.imagePositionData).forEach(id => {
        this.imagePositionData[id].y -=
          (maxPossibleColumnLength - subColumnMaxLength) * (this.imageDesiredSizes.width + this.distanceBetweenImages);
      });
    }
  }

  private updateHorizontalImagePositions() {
    const maxPossibleColumnLength = Math.max(...(this.displayData.map(group => group.products.length) as number[]));
    this.groupMaxLength = maxPossibleColumnLength;
    let subColumnMaxLength = 0;

    this.imageYPositions = [];
    let imageMatrixSize = 0;
    this.columnSizes = [];
    this.displayData.forEach((group, i) => {
      const columnY = imageMatrixSize;
      const numSubColumnsInCurrGroup = this.getNumSubColumnsInCurrGroup(group.products);
      const subColumnsArray = this.generateSubColumns(group.products, numSubColumnsInCurrGroup).filter(
        arr => arr.length > 0
      );

      const columnSize =
        subColumnsArray.length * (this.imageDesiredSizes.width + this.distanceBetweenImages) -
        this.distanceBetweenImages;
      const blockSize = this.subtotalOverlayBlockSize;
      const realColumnSize = columnSize > blockSize ? columnSize : blockSize;
      imageMatrixSize += realColumnSize + this.distanceBetweenColumns;

      this.columnSizes.push(columnSize);

      const columnYOffset = (realColumnSize - columnSize) / 2;

      this.imageYPositions.push({
        columnY,
        columnYOffset,
        images: []
      });
      subColumnsArray.forEach((subGroup, k) => {
        if (subGroup.length > subColumnMaxLength) subColumnMaxLength = subGroup.length;

        const subColumnY = k * (this.imageDesiredSizes.width + this.distanceBetweenImages);

        subGroup.forEach((prod, j) => {
          this.imagePositionData[prod.id] = {
            y: columnY + subColumnY + columnYOffset,
            x: j * (this.imageDesiredSizes.width + this.distanceBetweenImages)
          };
          this.imageYPositions[i].images.push({
            id: prod.id,
            y: subColumnY
          });
        });
      });
    });
    this.imageMatrixSizeTemp = imageMatrixSize - this.distanceBetweenColumns;
    if (subColumnMaxLength > 0) this.groupMaxLength = subColumnMaxLength;
  }

  getNumSubColumnsInCurrGroup(products: Array<IParetoProductDataModel>): number {
    const maxPossibleNumSubColumnsInCurrGroup = Math.floor((Math.sqrt(1 + 8 * products.length) - 1) / 2);
    return this.numSubColumns > maxPossibleNumSubColumnsInCurrGroup
      ? maxPossibleNumSubColumnsInCurrGroup
      : this.numSubColumns;
  }

  restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools) {
    const scaleDifference = Math.min(
      this.viewport.screenWidth / oldState.windowDimensions.width,
      this.viewport.screenHeight / oldState.windowDimensions.height
    );

    const scale = oldState.viewportScale * scaleDifference;
    const center = oldState.viewportCenter;

    zoom.baseScale = scale * 3;
    viewPort.scale.set(Math.abs(scale));
    viewPort.moveCenter(center.x, center.y);
  }

  private updateMatrixDimensions() {
    // Based on [VXA-992] requirements instead of current image matrix size
    // extremes will be calculated and used for world size calculation

    // Height extreme would be one column with all images in one subCoulmn
    // Width extreme would be seven columns with four subcolumns each
    const numMaxColumns = 7;
    const numMaxSubColumns = 4;
    const subColumnSize =
      numMaxSubColumns * (this.imageDesiredSizes.width + this.distanceBetweenImages) - this.distanceBetweenImages;
    const distanceBetweenColumns = (this.imageDesiredSizes.width + this.distanceBetweenImages) / 2;
    let w;
    let h;
    let minWidth;
    let minHeight;

    if (this.groupOrientation === GroupOrientation.Vertical) {
      h =
        this.imageRefs.length * (this.imageDesiredSizes.width + this.distanceBetweenImages) -
        this.distanceBetweenImages;
      w = numMaxColumns * (subColumnSize + distanceBetweenColumns) - distanceBetweenColumns;

      minWidth = 10 * this.imageSize;
      minHeight = 6 * this.imageSize;
    } else {
      w =
        this.imageRefs.length * (this.imageDesiredSizes.width + this.distanceBetweenImages) -
        this.distanceBetweenImages;
      h = numMaxColumns * (subColumnSize + distanceBetweenColumns) - distanceBetweenColumns;

      minWidth = 6 * this.imageSize;
      minHeight = 10 * this.imageSize;
    }
    if (w < minWidth) w = minWidth;
    if (h < minHeight) h = minHeight;

    let scaleW = w / (window.innerWidth - 2 * this.imageMatrixPaddingsPareto.leftRight);
    scaleW = Math.round((scaleW + Number.EPSILON) * 100) / 100;
    let scaleH = h / (window.innerHeight - this.imageMatrixPaddingsPareto.top - this.imageMatrixPaddingsPareto.bottom);
    scaleH = Math.round((scaleH + Number.EPSILON) * 100) / 100;
    const scale = scaleW > scaleH ? scaleW : scaleH;

    // TODO Pareto
    // This is arbitrary number. currently used to make world size bit smaller and resonable.
    // need to be tested with huge number of images
    // possible problem might be images going out of bounds of world
    const multiplier = 0.3;

    const width = window.innerWidth * scale * 3 * multiplier;
    const height = window.innerHeight * scale * 3 * multiplier;

    this.matrixResizerSpriteRef.width = width;
    this.matrixResizerSpriteRef.height = height;
    this.imageMatrix.position.set(
      (this.matrixResizerSpriteRef.width - this.imageMatrix.width) / 2,
      (this.matrixResizerSpriteRef.height - this.imageMatrix.height) / 2
    );
  }

  private updateMatrixDimensionsBeforeAnimation(imageMatrixWidth, imageMatrixHeight) {
    if (this.groupOrientation === GroupOrientation.Vertical) {
      const imageMatrixPositionFromCenter = {
        x: this.viewport.center.x - this.imageMatrix.x,
        y: this.viewport.center.y - (this.imageMatrix.y + this.imageMatrix.height - 2 * dropShadowPadding)
      };
      this.imageMatrix.position.set(
        (this.matrixResizerSpriteRef.width - imageMatrixWidth) / 2,
        (this.matrixResizerSpriteRef.height - imageMatrixHeight) / 2
      );
      const newCenter = {
        x: this.imageMatrix.x + imageMatrixPositionFromCenter.x,
        y: this.imageMatrix.y + imageMatrixHeight + imageMatrixPositionFromCenter.y
      };

      this.recenterMatrix.next(newCenter);
    } else {
      const imageMatrixPositionFromCenter = {
        x: this.viewport.center.x - this.imageMatrix.x,
        y: this.viewport.center.y - this.imageMatrix.y
      };
      this.imageMatrix.position.set(
        (this.matrixResizerSpriteRef.width - imageMatrixWidth) / 2,
        (this.matrixResizerSpriteRef.height - imageMatrixHeight) / 2
      );
      const newCenter = {
        x: this.imageMatrix.x + imageMatrixPositionFromCenter.x,
        y: this.imageMatrix.y + imageMatrixPositionFromCenter.y
      };

      this.recenterMatrix.next(newCenter);
    }

    this.render();
  }

  private animateMatrix() {
    if (!this.displayData) {
      return;
    }

    this.isUpdating.next(true);
    this.imageAnimationTimeline?.kill();
    this.imageAnimationTimeline = gsap.timeline({
      ease: 'power2.inOut',
      onUpdate: () => {
        this.render();
      },
      onComplete: () => {
        this.isUpdating.next(false);
        if (this.showImagePercentages) this.imagePercentContainer?.classList.remove('hide');
        this.subTotalPercentOverlay?.classList.remove('hide');
        const imageMatrixBounds = this.imageMatrixResizerSpriteRef.getBounds();
        this.updateImagePercentOverlayPositions(imageMatrixBounds);
        this.updateSubTotalPercentOverlayPosition(imageMatrixBounds);
        this.updateViewportOnCompass.next(null);
        this.clearCompass.next(false);
        this.setCanvasState(false);
      }
    });
    this.imageAnimationTimeline.addLabel('animationStart', 0);

    this.updateDistanceAndBlockSize();
    this.updateImagePositions();

    let newWidth;
    let newHeight;
    let imageMatrixSizeDifferent;

    if (this.groupOrientation === GroupOrientation.Vertical) {
      newHeight =
        this.groupMaxLength * (this.imageDesiredSizes.width + this.distanceBetweenImages) - this.distanceBetweenImages;
      newWidth = this.imageMatrixSizeTemp;
      imageMatrixSizeDifferent = newHeight - (this.imageMatrix.height - 2 * dropShadowPadding);
    } else {
      newWidth =
        this.groupMaxLength * (this.imageDesiredSizes.width + this.distanceBetweenImages) - this.distanceBetweenImages;
      newHeight = this.imageMatrixSizeTemp;
    }

    this.updateMatrixDimensionsBeforeAnimation(newWidth, newHeight);
    this.imageMatrixResizerSpriteRef.width = newWidth;
    this.imageMatrixResizerSpriteRef.height = newHeight;

    this.clearImagePercentOverlays();
    this.createImagePercentOverlays();
    this.imagePercentContainer?.classList.add('hide');
    this.paretoDislpayDataSubject.next(this.displayData);
    const imageMatrixBounds = this.imageMatrixResizerSpriteRef.getBounds();
    this.updateImagePercentOverlayPositions(imageMatrixBounds);
    this.updateSubTotalPercentOverlayPosition(imageMatrixBounds, true);

    if (this.groupOrientation === GroupOrientation.Vertical) {
      this.imageRefs.forEach(image => {
        image.position.y += imageMatrixSizeDifferent;
      });
    }
    this.render();

    const animations = [];
    let animationGroups = [];
    let animationDirection = null;
    this.displayData.forEach((group, i) => {
      const animationSubGroups = {
        bottomToTop: [],
        topToBottom: [],
        sideways: []
      };
      const animationSequence = new Set();

      group.products.forEach((prod, j) => {
        const { x, y } = this.imagePositionData[prod.id];
        const image = this.imageRefs.find(img => img.getId() === prod.id);
        if (image) {
          if (image.position.x !== x || image.position.y !== y) {
            const data = { image, x, y };
            if (y < image.position.y) {
              animationSubGroups.bottomToTop.push(data);
              animationSequence.add('bottomToTop');
              if (!animationDirection) animationDirection = 'left';
            } else if (image.position.x === x) {
              animationSubGroups.topToBottom.push(data);
              animationSequence.add('topToBottom');
              if (!animationDirection) animationDirection = 'right';
            } else {
              animationSubGroups.sideways.push(data);
              animationSequence.add('sideways');
              if (!animationDirection) animationDirection = x < image.position.x ? 'left' : 'right';
            }
          }
        }
      });

      animationSubGroups.sideways = animationSubGroups.sideways.reverse();
      animationSubGroups.topToBottom = animationSubGroups.topToBottom.reverse();

      Array.from(animationSequence).forEach((animationGroupName: string) => {
        animationGroups.push(animationSubGroups[animationGroupName]);
      });
    });
    if (animationDirection === 'right') animationGroups = animationGroups.reverse();
    animationGroups.forEach(g => animations.push(...g));
    animations.forEach((animationData, k) => {
      gsap.killTweensOf(animationData.image);
      this.imageAnimationTimeline.to(
        animationData.image,
        {
          duration: this.imageAnimationDuration,
          x: animationData.x,
          y: animationData.y
        },
        'animationStart'
      );
    });

    this.render();
  }

  protected restoreMatrix(): void {
    this.matrixState.imageMatrix.images.forEach(image => {
      this.imageViewOverridesWithId[image.id] = image.view;
    });
    this.createMatrix(true);
  }

  public override clearMatrix(removeContainers = true): void {
    super.clearMatrix(removeContainers);
    this.displayData = undefined;
    this.groupLabelsContainer.clearGroupLabels();
    this.clearImageMatrixResizerSpriteBounds();
    this.clearImagePercentOverlays();
    this.clearSubtotalPercentOverlay();
    this.clearColumnSizePicker();
    this.paretoAditionalDataSubject.next(null);

    this.render();
  }

  private clearColumnSizePicker() {
    if (!this.ColumnSizePickerComponentRef) return;
    this.ColumnSizePicker.parentElement.removeChild(this.ColumnSizePicker);
    delete this.ColumnSizePicker;
    this.ColumnSizePicker = null;
    this.ColumnSizePickerComponentRef.destroy();
    delete this.ColumnSizePickerComponentRef;
    this.ColumnSizePickerComponentRef = null;
  }

  private clearSubtotalPercentOverlay() {
    if (!this.subTotalPercentOverlayComponentRef) return;
    this.subTotalPercentOverlay.parentElement.removeChild(this.subTotalPercentOverlay);
    delete this.subTotalPercentOverlay;
    this.subTotalPercentOverlay = null;
    this.subTotalPercentOverlayComponentRef.destroy();
    delete this.subTotalPercentOverlayComponentRef;
    this.subTotalPercentOverlayComponentRef = null;
  }

  private clearImagePercentOverlays() {
    if (!this.imagePercentContainer) return;
    Object.keys(this.imagePercentOverlays).forEach(id => {
      this.imagePercentOverlays[id].parentElement.removeChild(this.imagePercentOverlays[id]);
      delete this.imagePercentOverlays[id];
      this.imagePercentOverlays[id] = null;
    });

    this.imagePercentContainer.parentElement.removeChild(this.imagePercentContainer);
    delete this.imagePercentContainer;
    this.imagePercentContainer = null;
    this.imagePercentOverlays = {};
  }

  private clearImageMatrixResizerSpriteBounds() {
    this.imageMatrixResizerSpriteRef.width = 0;
    this.imageMatrixResizerSpriteRef.height = 0;
    this.imageMatrixResizerSpriteRef.scale.set(1);
  }

  public getImagePosInWorld(image: ImageContainer): IPosition {
    return {
      x: image.x + this.imageMatrix.x,
      y: image.y + this.imageMatrix.x
    };
  }

  public onHarvestModeInit() {}
  public onHarvestModeClose() {}

  /**
   * @Override
   */
  public override onViewportMove(): void {
    super.onViewportMove();
    this.updateOverlayPositions();
    this.render();
  }

  /**
   * @Override
   */
  public override onZoomChange(zoomFactor, instantResizeMatrix: boolean = false): void {
    super.onZoomChange(zoomFactor, instantResizeMatrix);
    this.updateColumnsOnZoom();
    this.updateOverlayPositions();
    this.render();
  }

  private updateOverlayPositions() {
    const imageMatrixBounds = this.imageMatrixResizerSpriteRef.getBounds();
    this.updateImagePercentOverlayPositions(imageMatrixBounds);
    this.updateSubTotalPercentOverlayPosition(imageMatrixBounds);
  }

  public override onResolutionChange(zoomFactor, viewportBaseScale) {
    this.matrix.position.set(0, 0);
    this.zoomFactor = zoomFactor;
    this.updateBaseSizes(viewportBaseScale);
    this.updateColumnsOnZoom();
    const imageMatrixBounds = this.imageMatrix.getBounds();
    this.updateImagePercentOverlayPositions(imageMatrixBounds);
    this.updateSubTotalPercentOverlayPosition(imageMatrixBounds);
    this.render();
  }

  public onIndividualImageViewChange(image: ImageContainer): void {
    this.updateImageInCanvasState(image);
    this.imageViewOverridesWithId[image.getId()] = image.getView();
  }

  protected updateImageView(name: string): void {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    const view = name.slice(name.lastIndexOf('_') + 1, name.lastIndexOf('-'));
    const image = this.imageRefs.find(imageCont => imageCont.getId() === prodId);

    if (!image) return;
    image.updateView(this.resourceManagerService, view, this.render.bind(this));
    this.render();
  }

  private updateDistanceAndBlockSize() {
    this.distanceBetweenColumns = (this.imageDesiredSizes.width + this.distanceBetweenImages) / 2;
    this.subtotalOverlayBlockSize = this.imageDesiredSizes.width;
    if (this.subtotalOverlayBlockSize < this.blockMinSizeOnScreen / this.viewport.scale.x)
      this.subtotalOverlayBlockSize = this.blockMinSizeOnScreen / this.viewport.scale.x;
  }

  private updateColumnsOnZoom() {
    this.updateDistanceAndBlockSize();
    let imageMatrixSize = 0;
    if (this.groupOrientation === GroupOrientation.Vertical) {
      this.imageXPositions.forEach((data, i) => {
        const { images } = data;
        const newColumnX = imageMatrixSize;
        const columnSize = this.columnSizes[i] * this.resolution;
        const blockSize = this.subtotalOverlayBlockSize;
        const realColumnSize = columnSize > blockSize ? columnSize : blockSize;
        imageMatrixSize += realColumnSize + this.distanceBetweenColumns;
        const columnXOffset = (realColumnSize - columnSize) / 2;
        images.forEach(imageData => {
          const image = this.imageRefs.find(imageCont => imageCont.getId() === imageData.id);
          if (image) image.position.x = newColumnX + columnXOffset + imageData.x * this.resolution;
        });
      });
    } else {
      this.imageYPositions.forEach((data, i) => {
        const { images } = data;
        const newColumnY = imageMatrixSize;
        const columnSize = this.columnSizes[i] * this.resolution;
        const blockSize = this.subtotalOverlayBlockSize;
        const realColumnSize = columnSize > blockSize ? columnSize : blockSize;
        imageMatrixSize += realColumnSize + this.distanceBetweenColumns;
        const columnYOffset = (realColumnSize - columnSize) / 2;
        images.forEach(imageData => {
          const image = this.imageRefs.find(imageCont => imageCont.getId() === imageData.id);
          if (image) image.position.y = newColumnY + columnYOffset + imageData.y * this.resolution;
        });
      });
    }

    imageMatrixSize -= this.distanceBetweenColumns;

    this.imageMatrix.removeChild(this.imageMatrixResizerSpriteRef);
    if (this.groupOrientation === GroupOrientation.Vertical) {
      this.imageMatrixResizerSpriteRef.width = imageMatrixSize;
      this.imageMatrixResizerSpriteRef.height = this.imageMatrix.height - 2 * dropShadowPadding;
    } else {
      this.imageMatrixResizerSpriteRef.height = imageMatrixSize;
      this.imageMatrixResizerSpriteRef.width = this.imageMatrix.width - 2 * dropShadowPadding;
    }

    this.imageMatrixResizerSpriteRef.position.set(-this.imageDesiredSizes.width / 2, -this.imageDesiredSizes.width / 2);
    this.imageMatrix.addChildAt(this.imageMatrixResizerSpriteRef, 0);

    this.imageMatrix.position.set(
      (this.matrixResizerSpriteRef.width - this.imageMatrix.width) / 2,
      (this.matrixResizerSpriteRef.height - this.imageMatrix.height) / 2
    );
  }

  private getSubArraySize(n, m) {
    const minNum = (m * (m + 1)) / 2;
    return m + Math.ceil((n - minNum) / m);
  }

  private generateSubColumns(products, numSubColumns) {
    const subColumns = [];

    const n = products.length;
    const m = numSubColumns;
    let subarraySize = this.getSubArraySize(n, m);
    if (subarraySize > this.currentColumnSize) subarraySize = this.currentColumnSize;
    let start = 0;
    let end = start + subarraySize;

    for (let i = 0; i < numSubColumns; i++) {
      subColumns.push(products.slice(start, end));
      subarraySize--;
      start = end;
      end = start + subarraySize;
    }

    return subColumns;
  }

  private updateColumnsBase(calculateColumnSizes = true) {
    this.groupMaxLength = Math.max(...this.displayData.map(group => group.products.length));
    this.maxPossibleNumSubColumns = Math.floor((Math.sqrt(1 + 8 * this.groupMaxLength) - 1) / 2);

    if (calculateColumnSizes) {
      this.maxColumnSize = this.groupMaxLength;
      this.minColumnSize = this.getSubArraySize(this.maxColumnSize, this.maxPossibleNumSubColumns);
    }
  }

  private updateColumnSizeSubject() {
    setTimeout(() => {
      this.paretoColumnSizeSubject.next({
        currentSize: this.currentColumnSize,
        maxSize: this.maxColumnSize,
        minSize: this.minColumnSize
      });
    });
  }

  private updateColumnsOnFilterChange() {
    this.updateColumnsBase();
    this.numSubColumns =
      this.groupOrientation === GroupOrientation.Vertical
        ? (Math.sqrt(
            1 +
              (8 * (this.window.innerWidth * this.groupMaxLength)) / (this.window.innerHeight * this.displayData.length)
          ) -
            1) /
          2
        : (Math.sqrt(
            1 +
              (8 * (this.window.innerHeight * this.groupMaxLength)) / (this.window.innerWidth * this.displayData.length)
          ) -
            1) /
          2;
    this.numSubColumns = Math.round(this.numSubColumns);
    if (this.numSubColumns < 1) this.numSubColumns = 1;
    if (this.numSubColumns > this.maxPossibleNumSubColumns) this.numSubColumns = this.maxPossibleNumSubColumns;

    this.currentColumnSize = this.getSubArraySize(this.maxColumnSize, this.numSubColumns);
    this.updateColumnSizeSubject();
  }

  private updateColumnsOnHandlersChange() {
    this.updateColumnsBase();
    if (this.currentColumnSize < this.minColumnSize) this.currentColumnSize = this.minColumnSize;
    if (this.currentColumnSize > this.maxColumnSize) this.currentColumnSize = this.maxColumnSize;
    this.updateColumnSizeSubject();
    const numSubColumns = this.calculateNumSubColumns();
    this.numSubColumns = numSubColumns > this.maxPossibleNumSubColumns ? this.maxPossibleNumSubColumns : numSubColumns;
  }

  private updateColumnsOnColumnSizeChange() {
    this.updateColumnsBase(false);
    const numSubColumns = this.calculateNumSubColumns();
    this.numSubColumns = numSubColumns > this.maxPossibleNumSubColumns ? this.maxPossibleNumSubColumns : numSubColumns;
  }

  private calculateNumSubColumns() {
    let columnSize = this.currentColumnSize;
    let numSubColumns = 0;
    let numImages = 0;
    // TODO Pareto: Need better (mathematical) solution
    while (numImages < this.groupMaxLength) {
      numSubColumns++;
      numImages += columnSize;
      columnSize--;
      // This line will make sure loop will break eventually
      // if for some reason other parameter values are not correct and cant reach break condition
      if (columnSize < 1) columnSize = 1;
    }
    return numSubColumns;
  }

  private createImagePercentOverlays() {
    const matrixOverlayContainer = getMatrixOverlayContainer();
    this.imagePercentContainer = document.createElement('div');
    this.imagePercentContainer.classList.add('imagePercentContainer');
    matrixOverlayContainer.appendChild(this.imagePercentContainer);
    this.displayData.forEach((group, i) => {
      group.products.forEach((prod, j) => {
        const overlay = document.createElement('span');
        overlay.classList.add('imagePercentOverlay');
        overlay.innerText = `${prod.value.toFixed(1)}%`;
        this.imagePercentContainer.appendChild(overlay);
        this.imagePercentOverlays[prod.id] = overlay;
      });
    });
    this.updateImagePercentOverlayVisibility();
  }

  private updateImagePercentOverlayPositions(imageMatrixBounds: IBounds) {
    if (!this.imagePercentContainer) return;
    this.imagePercentContainer.style.left = `${imageMatrixBounds.x}px`;
    this.imagePercentContainer.style.top = `${imageMatrixBounds.y}px`;
    const fontSize = Math.round(24 * this.resolution * this.viewport.scale.x);
    this.imageRefs.forEach(image => {
      const overlay = this.imagePercentOverlays[image.getId()];
      overlay.style.top = `${(image.position.y + image.getSize()) * this.viewport.scale.x}px`;
      overlay.style.left = `${(image.position.x + image.getSize()) * this.viewport.scale.x}px`;
      overlay.style.fontSize = `${fontSize}px`;
    });
  }

  private updateImagePercentOverlayVisibility() {
    if (!this.imagePercentContainer) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.showImagePercentages
      ? this.imagePercentContainer.classList.remove('hide')
      : this.imagePercentContainer.classList.add('hide');
  }

  private createSubTotalPercentOverlay() {
    const componentFactory = this.factoryResolver.resolveComponentFactory(ParetoSubtotalPercentOverlayComponent);
    this.subTotalPercentOverlayComponentRef = this.viewContainerRef.createComponent(componentFactory);
    this.subTotalPercentOverlay = this.subTotalPercentOverlayComponentRef.location.nativeElement;
    getMatrixOverlayContainer().appendChild(this.subTotalPercentOverlay);
    setTimeout(() => {
      this.paretoDislpayDataSubject.next(this.displayData);
    });
  }

  private updateSubTotalPercentOverlayPosition(imageMatrixBounds: IBounds, animate = false) {
    this.paretoImageMatrixBounds = {
      bounds: imageMatrixBounds,
      scale: ((this.subtotalOverlayBlockSize * this.viewport.scale.x) / this.imageDesiredSizes.width) * this.resolution,
      imageSize: this.imageDesiredSizes.width * this.viewport.scale.x,
      distanceBetweenImages: this.distanceBetweenImages * this.viewport.scale.x,
      blockMinSize: this.subtotalOverlayBlockSize * this.viewport.scale.x,
      columnSizes: this.columnSizes
        .map(columnSize => {
          return columnSize * this.resolution;
        })
        .map(columnSize => {
          return (
            (columnSize > this.subtotalOverlayBlockSize ? columnSize : this.subtotalOverlayBlockSize) *
            this.viewport.scale.x
          );
        }),
      animation: animate
    };

    this.paretoImageMatrixBoundsSubject.next(this.paretoImageMatrixBounds);
  }

  private createColumnSizePicker() {
    const componentFactory = this.factoryResolver.resolveComponentFactory(ParetoColumnSizePickerComponent);
    this.ColumnSizePickerComponentRef = this.viewContainerRef.createComponent(componentFactory);
    this.ColumnSizePicker = this.ColumnSizePickerComponentRef.location.nativeElement;
    getMatrixOverlayContainer().appendChild(this.ColumnSizePicker);
  }

  public getStateForPresentationMode(): IPresentationState {
    return {
      images: this.imageRefs.map(image => this.getImagePresentationState(image)),
      frames: [],
      groupsByLevel: [],
      paretoData: {
        groups: this.displayData.map(group => ({
          ...group,
          numSubColumns: this.getNumSubColumnsInCurrGroup(group.products)
        })),
        columnSizes: this.columnSizes.map(columnSize => {
          return columnSize > this.subtotalOverlayBlockSize ? columnSize : this.subtotalOverlayBlockSize;
        }),
        padding:
          this.columnSizes[0] > this.subtotalOverlayBlockSize
            ? 0
            : (this.subtotalOverlayBlockSize - this.columnSizes[0]) / 2,
        distanceBetweenColumns: this.distanceBetweenColumns,
        fontSize: 16,
        groupOrientation: this.groupOrientation,
        detailsBarFixedPosition: this.store.selectSnapshot(StudyState.getParetoDetailsBarFixedPosition)
      }
    };
  }
}
