import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { PortalMenuItem, PortalMenuItemCollorMapper } from '@app/shared/enums/portal-menu-item.enum';
import { WINDOW } from '@app/shared/utils/window';
import { ConnectionType } from '@app/state/connection/connection.model';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-home-card-body',
  templateUrl: './home-card-body.component.html',
  styleUrls: ['./home-card-body.component.scss']
})
export class HomeCardBodyComponent implements OnInit {
  @Input() public type: PortalMenuItem;
  @Input() public connectionType?: ConnectionType;
  @Input() public id: string;
  @Input() public authorName: string;
  @Input() public isDefault: boolean = false;
  @Input() public set createdDate(value: Timestamp) {
    this._createdDate = value.toDate();
  }
  public get created() {
    return this._createdDate;
  }
  private _createdDate: Date;
  public readonly PortalMenuItem = PortalMenuItem;
  public readonly PortalMenuItemCollorMapper = PortalMenuItemCollorMapper;

  public matRippleRadius: number;

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.matRippleRadius = (this.window.innerHeight * 30) / 1080 / 2;
  }

  constructor(
    @Inject(WINDOW)
    private window: Window,
    private projectDataService: ProjectDataService
  ) {}

  ngOnInit(): void {
    this.matRippleRadius = (this.window.innerHeight * 30) / 1080 / 2;
  }

  public onDelete(): void {
    switch (this.type) {
      case PortalMenuItem.Connections: {
        this.projectDataService.deleteConnection(this.id, this.connectionType);
        break;
      }
      case PortalMenuItem.Collages: {
        this.projectDataService.deleteCollage(this.id);
        break;
      }
      case PortalMenuItem.Studies: {
        this.projectDataService.deleteStudy(this.id);
      }
    }
  }
}
