import JSZip from 'jszip';

export function getImageBlob(name: string, url: string): Promise<Blob> {
  return fetch(url).then(response => {
    if (response.ok) {
      return response.blob();
    }
    throw new Error(`Error: Unable to find image ${name}`);
  });
}

export function convertToPngWithOffscreenCanvas(blob: Blob): Promise<Blob> {
  return createImageBitmap(blob).then(imageBM => {
    const offscreen = new OffscreenCanvas(1024, 1024);
    const ctx = offscreen.getContext('2d');
    ctx.drawImage(imageBM, 0, 0);
    return offscreen.convertToBlob({ type: 'image/png' });
  });
}

export function convertToPngWithCanvas(blob: Blob): Promise<Blob> {
  return createImageBitmap(blob).then(imageBM => {
    const canvas = this.window.document.createElement('canvas');
    canvas.width = 1024;
    canvas.height = 1024;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBM, 0, 0);
    return canvasToBlob(canvas);
  });
}

export function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, _) => {
    canvas.toBlob(blob => resolve(blob), 'image/png');
  });
}

export function prepareImagesToDownload(
  data: Array<string>,
  convertFunc: (blob: Blob) => Promise<Blob>,
  onComplete: (result: { name: string; blob: Blob }) => void
): void {
  if (data.length === 1) {
    const [url] = data;
    const [name] = url.split('/').pop().split('?');
    getImageBlob(name, url)
      .then((blob: Blob) => (blob.type === 'image/webp' ? convertFunc(blob) : blob))
      .then((blob: Blob) => {
        onComplete({ name, blob });
      });
  } else {
    const jsZip = new JSZip();
    Promise.all(
      data.map(url => {
        const name = url.split('/').pop().split('?')[0];
        return getImageBlob(name, url)
          .then((blob: Blob) => (blob.type === 'image/webp' ? convertFunc(blob) : blob))
          .then((blob: Blob) => {
            return {
              name,
              blob
            };
          })
          .catch((error: Error) => error);
      })
    ).then((items: Array<{ name: string; blob: Blob } | Error>) => {
      for (const item of items) {
        if (item instanceof Error) {
          console.error(item.message);
        } else {
          const ext = item.blob.type.split('/')[1];
          jsZip.file(`${item.name}.${ext}`, item.blob);
        }
      }
      jsZip.generateAsync({ type: 'blob' }).then(blob => {
        onComplete({ name: 'images', blob });
      });
    });
  }
}
