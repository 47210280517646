<div class="right-header" (app-delayed-hover)="(null)" [delayLeave]="1000" [menuOpened]="accountMenuOpened">
  <div class="right-header-content">
    <button
      #accountMenuTrigger
      [matMenuTriggerFor]="accountMenu"
      [matTooltip]="currentUser.email"
      matTooltipPosition="below"
      matTooltipClass="below"
      [ngClass]="{ selected: accountMenuOpened }"
      (menuOpened)="accountMenuOpened = true"
      (menuClosed)="accountMenuOpened = false"
    >
      <svg-icon svgClass="account" name="icon-user-account"></svg-icon>
    </button>
    <mat-menu #accountMenu="matMenu">
      <div class="user-info">
        <b>{{ currentUser.displayName }}</b
        ><br />{{ currentUser.email }}
      </div>
      <button mat-menu-item (click)="logout()">
        <span>Log out</span>
      </button>
    </mat-menu>
    <a
      matTooltip="Resources"
      matTooltipPosition="below"
      [matTooltipClass]="['below', 'resources']"
      href="https://resources.collagia.com"
      target="_blank"
    >
      <svg-icon svgClass="resources" name="icon-resources"></svg-icon>
    </a>
  </div>
</div>
