<form #form="ngForm" class="auth-form" [formGroup]="loginForm" (ngSubmit)="form.valid && login()">
  <div class="form-field" [ngClass]="{ invalid: (form.submitted || email.touched) && email.invalid }">
    <div class="form-field__prefix">
      <svg-icon svgClass="icon" name="icon-email-filled"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="email"
      formControlName="email"
      placeholder="Email"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || email.touched) && email.invalid" class="form-field__error">
      <ng-container *ngIf="email.hasError('required')"> Email is required </ng-container>
      <ng-container *ngIf="email.hasError('email')"> Enter valid email address </ng-container>
    </div>
  </div>
  <div
    class="form-field"
    [ngClass]="{
      invalid: (form.submitted || password.touched) && password.invalid
    }"
  >
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="password"
      formControlName="password"
      placeholder="Password"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || password.touched) && password.hasError('required')" class="form-field__error">
      Password is required
    </div>
  </div>
  <div class="question">
    <button type="button" class="question__button" (click)="openForgotPasswordDialog()">Forgot password?</button>
  </div>
  <div class="auth-form__actions">
    <button mat-flat-button color="accent" type="submit">LOG IN</button>
  </div>
  <div class="question align-center">
    <span>Don't have an acccount?</span>
    <a class="question__link" [routerLink]="['/auth/register']">Register</a>
  </div>
</form>
