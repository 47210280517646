import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE } from '../../utils/delay-resolver';

export interface IFadeInOutOnChange {
  element: ElementRef;
  fadeIn: boolean;
  event: string;
}

export const DEFAULT_FADE_IN_EVENT = 'AllFadeInEvent';
export const DEFAULT_FADE_OUT_EVENT = 'AllFadeOutEvent';

/**
 * Directive to add class 'fade-in-out' and styles to support animations 'fade-in' / 'fade-out' for DOM element
 * Trigger of animation can be any event, sent to the document.
 *
 * Notes:
 * - 'fade-in-out' class always present in the DOM element
 * - fade-in animation will happen when document event [fadeInEvent] triggered, with time [fadeInTime] (measured in ms)
 * - fade-out animation will happen when document event [fadeOutEvent] triggered, with time [fadeOutTime] (measured in ms)
 *
 * Examples:
 * <div class="container" app-fade-in-out (fadeInOut)="onChange($event)" fadeInEvent="ShowDialog" [fadeInTime]="1000">
 *   Will show 'fade-in' animation for opacity from 0 to 1 with transition 1000ms
 *   onChange($event) will be triggered every time, when comes fadeInEvent / fadeOutEvent
 * </div>
 *
 * <div class="container" app-fade-in-out
 *   fadeInByInit="false"
 *   [fadeInEvent]="'ShowDialog'" [fadeInTime]="500"
 *   [fadeOutEvent]="'CloseDialog'" [fadeOutTime]="1000"
 * >
 *   Default initial 'fade-in' animation disabled (when item creates)
 *   Will show 'fade-in' animation for opacity from 0 to 1 with transition 500ms, triggered by event 'ShowDialog'
 *   Will show 'fade-out' animation for opacity from 1 to 0 with transition 1000ms, triggered by event 'CloseDialog'
 * </div>
 *
 * <div class="container" app-fade-in-out
 *   [fadeIn]="isShowDialog"
 *   [fadeOut]="isCloseDialog"
 * >
 *   Will show 'fade-in' animation for opacity from 0 to 1 with default transition time, triggered by boolean variable isShowDialog=true
 *   Will show 'fade-out' animation for opacity from 1 to 0 with default transition time, triggered by boolean variable isCloseDialog=true
 * </div>
 * */
@Directive({
  selector: '[app-fade-in-out]'
})
export class FadeInOutDirective implements OnInit, OnDestroy, OnChanges {
  @Input() public fadeInEvent = DEFAULT_FADE_IN_EVENT;
  @Input() public fadeInTime = DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE;
  @Input() public fadeOutEvent = DEFAULT_FADE_OUT_EVENT;
  @Input() public fadeOutTime = DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE;
  @Input() public fadeInByInit = true;
  @Input() public fadeIn = false;
  @Input() public fadeOut = false;

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('fadeInOut') fadeInOut = new EventEmitter<IFadeInOutOnChange>();

  private subscriptions: Array<Subscription> = [];

  constructor(private readonly element: ElementRef) {}

  ngOnInit() {
    const main = this.element.nativeElement;
    main.classList.add('fade-in-out');

    if (this.fadeInByInit) {
      main.style.setProperty('opacity', `0`);
      setTimeout(() => {
        main.style.setProperty('transition', `opacity ${this.fadeInTime}ms ease`);
        main.style.setProperty('opacity', `1`);
      });
    }

    // Styles changed by Events
    this.subscriptions.push(
      fromEvent(document, this.fadeInEvent).subscribe(() => {
        this.doFadeIn();
      })
    );
    this.subscriptions.push(
      fromEvent(document, this.fadeOutEvent).subscribe(() => {
        this.doFadeOut();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fadeIn) {
      this.doFadeIn();
    }
    if (changes.fadeOut) {
      this.doFadeOut();
    }
  }

  private doFadeIn() {
    const main = this.element.nativeElement;
    main.style.removeProperty('visibility');
    main.style.setProperty('opacity', `0`);
    main.style.setProperty('transition', `opacity ${this.fadeInTime}ms ease`);
    main.style.setProperty('opacity', `1`);
    setTimeout(() => {
      main.style.removeProperty('visibility');
    }, this.fadeInTime);
    this.fadeInOut.emit({ element: this.element, fadeIn: true, event: this.fadeInEvent });
  }

  private doFadeOut() {
    const main = this.element.nativeElement;
    main.style.setProperty('opacity', `1`);
    main.style.setProperty('transition', `opacity ${this.fadeOutTime}ms ease`);
    main.style.setProperty('opacity', `0`);
    setTimeout(() => {
      main.style.setProperty('visibility', `hidden`); // to disable mouse and key events for hidden element
    }, this.fadeOutTime);
    this.fadeInOut.emit({ element: this.element, fadeIn: false, event: this.fadeOutEvent });
  }
}
