import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ColumnType, downloadFile, IColumnTypeSettings, rowsToCSVText } from '../../../shared/utils/parse-data-file';
import { Select, Store } from '@ngxs/store';
import { StudyState } from '../../../state/study/study.state';
import { Observable, of } from 'rxjs';
import { IFiltersModel } from '../../../models/filters.model';
import { ICustomAttributes } from '../../../state/study/study.model';
import { CollageState } from '../../../state/collage/collage.state';
import { WINDOW } from '../../../shared/utils/window';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../../services/data/data.service';
import { FriendlyNameService } from '../../../services/friendly-name/friendly-name.service';
import { AuthService } from '../../../services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { UserDataState } from '../../../state/user-data/user-data.state';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ColDef,
  Column,
  ColumnApi,
  ColumnState,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  SelectionChangedEvent
} from 'ag-grid-community';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeProductNameColumnDialogComponent } from '../change-product-name-column-dialog/change-product-name-column-dialog.component';
import { SetActiveCollageDisplayNameField } from '../../../state/collage/collage.actions';
import { SetFriendlyHeaderTitle } from '../../../state/study/study.actions';
import { MatMenuTrigger } from '@angular/material/menu';
import { CellMouseOverEvent } from 'ag-grid-community/dist/lib/events';
import { CustomAttributesComponent } from './cell-renderer/custom-attributes/custom-attributes.component';
import { CustomHeaderComponent } from './cell-renderer/custom-header/custom-header.component';
import { CustomRowIndexComponent } from './cell-renderer/custom-row-index/custom-row-index.component';
// ---- import { CustomLoadingCellComponent } from './cell-renderer/custom-loading/custom-loading-cell.component';
import { IGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iDatasource';
import { BusinessConnectionState } from '../../../state/connection/business/business-connection.state';
import { SetTotalRowsInView } from '../../../state/user-data/user-data.actions';
import { ISelectedRowsForServerQuery, SelectRowsService } from './select-rows.service';

export const VISUAL_FEATURES_COLUMN = '__Visual_Features';
export const SELECTION_COLUMN = '__selection';
export const BUSINESS_DATA_GRID_KEY = 'business-data-grid';

export interface IHeaderData {
  title: string;
  column: string;
  dataIndex: number;
  columnIndex: number;
  isShown: boolean;
  isResizing?: boolean;
  columnType: ColumnType;
  columnSettings: IColumnTypeSettings;
  columnWidth: number; // added to have ability resize, but not used yet, virtual table calculates each column width automatically
}

export interface IBusinessDataGridSettings {
  [key: string]: {
    state: Array<ColumnState>;
  };
}

interface IGridColDef extends ColDef {
  headerComponentParams: {
    isShown: boolean;
    isEditableTitle: boolean;
    dataIndex: number;
    columnIndex: number;
    columnType: ColumnType; // for sorting
    columnName: string; // real column name in the data set
    columnSettings: IColumnTypeSettings; // for sorting
    isBooleanHeader?: boolean; // show Header as a Checkbox selection
  };
}

interface IBusinessDatasource extends IDatasource {
  businessDataId: string;
}

@UntilDestroy()
@Component({
  selector: 'app-business-data-grid',
  templateUrl: './business-data-grid.component.html',
  styleUrls: ['./business-data-grid.component.scss']
})
export class BusinessDataGridComponent {
  @Input() imagesOnMatrix: Set<string>;

  @Input() containerHeight: number = 400;

  @Input() isCanSelectCell: boolean = true;

  @Input() isShowColumnList: boolean = false;
  @Output() isShowColumnListChange = new EventEmitter<boolean>();

  @ViewChild('grid', { static: false })
  private grid: ElementRef;

  @ViewChild('alternativeTitleEdit', { static: false })
  public readonly alternativeTitleEditRef: ElementRef;

  @ViewChild(MatMenuTrigger, { static: true })
  contextMenu: MatMenuTrigger;

  protected readonly outOfEditHeaderTitleClickListenerRef = this.onDocumentClick.bind(this);

  @Select(StudyState.getCustomAttributeFilters) customAttributeFilters$: Observable<Array<IFiltersModel>>;
  @Select(StudyState.getCustomAttributes) customAttributes$: Observable<ICustomAttributes>;
  customAttributes: ICustomAttributes = null;

  @Select(StudyState.isShowOriginalHeaders) isShowOriginalHeaders$: Observable<boolean>;
  isShowOriginalHeaders: boolean;

  @Select(CollageState.getActiveCollageOwner) collageOwner$: Observable<string>;
  @Select(CollageState.getDisplayNameField) displayNameField$: Observable<string>;
  displayNameField: string;

  @Select(BusinessConnectionState.getActiveBusinessDataId) businessDataId$: Observable<string>;

  currentUserId: string;

  readonly VISUAL_FEATURES_COLUMN = VISUAL_FEATURES_COLUMN;

  // Context menu position
  contextMenuPosition = { x: 0, y: 0 };

  hoveredRow: any;

  get headers(): Array<Column> {
    return this.gridColumnApi?.getAllGridColumns() || [];
  }
  rows: any[][] = [];

  // AG-Grid data
  columnDefs: IGridColDef[] = [];
  rowData: any[][] = [];
  rowsDataSource: IBusinessDatasource;
  isNeedRefreshColumns: boolean = false;

  productIdIndex: number;
  productNameIndex: number;
  customAttributesColumnIndex: number;

  headerToEdit: IHeaderData = null;
  newHeaderText: string;

  public gridOptions: GridOptions<any>;

  private gridColumnApi!: ColumnApi;
  private gridApi!: GridApi<any>;

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    minWidth: 100,
    sortable: true,
    filter: false,
    resizable: true
  };

  // AG-Grid components
  public components: {
    [p: string]: any;
  } = {
    agColumnHeader: CustomHeaderComponent
  };

  // ---- Will work only for Enterprise version
  // ---- Currently we are using CustomRowIndexComponent to show loader in the cell when data is loading
  // public loadingCellRenderer: any = CustomLoadingCellComponent;
  // public loadingCellRendererParams: any = {
  //   loadingMessage: 'One moment please...'
  // };
  // ----

  isAllRowsLoading: boolean;

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private readonly dialog: MatDialog,
    private hostElement: ElementRef,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private dataService: DataService,
    private friendlyName: FriendlyNameService,
    private store: Store,
    private auth: AuthService,
    public selection: SelectRowsService
  ) {}

  getRowsFromServer(params: IGetRowsParams) {
    const headers = this.store.selectSnapshot(UserDataState.getHeader);
    const prodIdHeader: string = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    this.productIdIndex = headers.indexOf(prodIdHeader);

    if (this.productIdIndex < 0) {
      this.rowData = [];
      params.successCallback(this.rowData);
      return;
    }

    let sortColumnIndex = null;
    let sort = null;
    let columnType: ColumnType = 'string';
    let columnSettings = {};
    let customAttributes = null;
    const sortedColumn = this.gridColumnApi.getColumnState().find(column => !!column.sort);
    if (sortedColumn) {
      const columnName = (this.gridColumnApi.getColumn(sortedColumn.colId) as any).userProvidedColDef.headerName;
      const headerName = this.friendlyName.getOriginalName(columnName);
      sortColumnIndex = headers.indexOf(headerName);
      const filters = this.store.selectSnapshot(UserDataState.getDimensionsAndMeasures);
      const columnFilter = filters.find(item => item.name === headerName);
      if (columnFilter) {
        columnType = columnFilter.dataType;
        columnSettings = columnFilter.dataSettings;
      } else {
        // seems it's virtual column for Custom Attributes
        columnType = 'string';
        columnSettings = null;
        customAttributes = this.customAttributes;
      }
      sort = sortedColumn?.sort || null;
    }
    this.dataService
      .getBusinessRowsForProducts(
        this.rowsDataSource.businessDataId,
        Array.from(this.imagesOnMatrix),
        this.productIdIndex,
        sortColumnIndex,
        sort,
        columnType,
        columnSettings,
        customAttributes, // for case, when user sort by Custom Attributes column, otherwise = null
        null,
        params.startRow,
        params.endRow - params.startRow
      )
      .pipe(take(1))
      .subscribe(
        data => {
          this.rowsDataSource.rowCount = data.total;
          this.store.dispatch(new SetTotalRowsInView(data.total));

          this.rows = data.rows; // last cell in each row it's rowIndex

          if (this.isNeedRefreshColumns) {
            this.isNeedRefreshColumns = false;
            this.rowData = [];
            this.updateColumnsDefinitions();
            this.restoreTableState();
          }

          this.processData();
          params.successCallback(this.rowData); // data.rows -> this.rowData
        },
        error => {
          params.failCallback();
        }
      );
  }

  getAllRowsFromServer(selectedRowIndexes?: ISelectedRowsForServerQuery): Observable<string> {
    this.isAllRowsLoading = true;

    const headers = this.store.selectSnapshot(UserDataState.getHeader);
    const prodIdHeader: string = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    this.productIdIndex = headers.indexOf(prodIdHeader);

    if (this.productIdIndex < 0) return of(null);

    let sortColumnIndex = null;
    let sort = null;
    let columnType: ColumnType = 'string';
    let columnSettings = {};
    let customAttributes = null;
    const sortedColumn = this.gridColumnApi.getColumnState().find(column => !!column.sort);
    if (sortedColumn) {
      const columnName = (this.gridColumnApi.getColumn(sortedColumn.colId) as any).userProvidedColDef.headerName;
      const headerName = this.friendlyName.getOriginalName(columnName);
      sortColumnIndex = headers.indexOf(headerName);
      const filters = this.store.selectSnapshot(UserDataState.getDimensionsAndMeasures);
      const columnFilter = filters.find(item => item.name === headerName);
      if (columnFilter) {
        columnType = columnFilter.dataType;
        columnSettings = columnFilter.dataSettings;
      } else {
        // seems it's virtual column for Custom Attributes
        columnType = 'string';
        columnSettings = null;
        customAttributes = this.customAttributes;
      }
      sort = sortedColumn?.sort || null;
    }
    return this.dataService
      .getBusinessRowsForProducts(
        this.rowsDataSource.businessDataId,
        Array.from(this.imagesOnMatrix),
        this.productIdIndex,
        sortColumnIndex,
        sort,
        columnType,
        columnSettings,
        customAttributes, // for case, when user sort by Custom Attributes column, otherwise = null
        selectedRowIndexes,
        null,
        null
      )
      .pipe(
        take(1),
        map(data => {
          this.updateCustomAttributes(data.rows);
          const text = this.rowsToCSVText(data.rows);
          return text;
        })
      );
  }

  ngOnInit(): void {
    this.gridOptions = {
      onCellMouseOver: this.onCellMouseOver.bind(this)
    };

    this.currentUserId = this.auth.currentUser.uid;

    this.customAttributeFilters$
      .pipe(
        untilDestroyed(this),
        filter(value => !!value),
        withLatestFrom(this.customAttributes$)
      )
      .subscribe(([customAttributeFilters, customAttributes]) => {
        // update VISUAL_FEATURES_COLUMN each time, when filters changed
        this.customAttributes = customAttributes;
        this.updateCustomAttributes(this.rows);
      });

    this.displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    this.displayNameField$.pipe(untilDestroyed(this)).subscribe(value => {
      this.displayNameField = value;
      this.processData();
    });

    this.isShowOriginalHeaders$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isShowOriginalHeaders = value;
    });

    this.businessDataId$.pipe(untilDestroyed(this)).subscribe(businessDataId => {
      this.isNeedRefreshColumns = true;
    });
  }

  ngOnDestroy() {
    this.storeTableState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagesOnMatrix) {
      this.updateRowsForImagesOnMatrix();
      this.gridApi?.redrawRows(); // will call rowsDataSource.getRows() --> getRowsFromServer()
    }
    if (changes.containerHeight) {
      this.hideColumnList();
    }
  }

  updateRowsForImagesOnMatrix() {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    // Server-Side Row Model
    this.rowsDataSource = {
      businessDataId,
      rowCount: 0, // will be updated in the first request
      getRows: this.getRowsFromServer.bind(this)
    };
    this.store.dispatch(new SetTotalRowsInView(0));
  }

  processData() {
    const headers = this.store.selectSnapshot(UserDataState.getHeader);
    const prodIdHeader: string = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    this.productIdIndex = headers.indexOf(prodIdHeader);

    this.displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    this.productNameIndex = headers.indexOf(this.displayNameField);

    if (this.rows.length < 0 || this.productIdIndex < 0 || this.productNameIndex < 0) {
      this.rows = [];
      this.rowData = [];
      return;
    }

    this.updateCustomAttributes(this.rows);
    this.rowData = this.rows;

    if (this.rows.length > 0) {
      setTimeout(() => {
        this.filterForVisibleColumns();
      }, 0);
    }
  }

  updateColumnsDefinitions() {
    const headers = this.store.selectSnapshot(UserDataState.getHeader);
    const prodIdHeader: string = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    this.productIdIndex = headers.indexOf(prodIdHeader);
    this.productNameIndex = headers.indexOf(this.displayNameField);

    const filters = this.store.selectSnapshot(UserDataState.getDimensionsAndMeasures);
    const productIdFilter = filters.find(item => item.name === prodIdHeader);
    const productNameFilter = filters.find(item => item.name === this.displayNameField);

    this.columnDefs = [
      {
        headerName: '#',
        valueGetter: (item: any) => (item.data !== undefined ? String(item.data[headers.length + 1]) : '. . .'),
        cellRenderer: CustomRowIndexComponent,
        width: 100,
        pinned: 'left',
        lockPosition: true,
        suppressMovable: true,
        headerComponentParams: {
          isShown: true,
          isEditableTitle: false,
          dataIndex: headers.length + 1,
          columnIndex: headers.length + 1,
          columnType: 'number',
          columnName: SELECTION_COLUMN,
          columnSettings: null,
          isBooleanHeader: true
        }
      },
      {
        headerName: headers[this.productIdIndex],
        valueGetter: (item: any) => String((item.data && item.data[this.productIdIndex]) || ''),
        pinned: 'left',
        lockPosition: true,
        suppressMovable: true,
        headerComponentParams: {
          isShown: true,
          isEditableTitle: true,
          dataIndex: this.productIdIndex,
          columnIndex: this.productIdIndex,
          columnName: headers[this.productIdIndex],
          columnType: productIdFilter.dataType,
          columnSettings: productIdFilter.dataSettings
        }
      }
    ];
    if (this.productNameIndex !== this.productIdIndex) {
      // 2: show productName as second column
      this.columnDefs.push({
        headerName: headers[this.productNameIndex],
        valueGetter: (item: any) => String((item.data && item.data[this.productNameIndex]) || ''),
        pinned: 'left',
        width: 200,
        headerComponentParams: {
          isShown: true,
          isEditableTitle: true,
          dataIndex: this.productNameIndex,
          columnIndex: this.productNameIndex,
          columnName: headers[this.productNameIndex],
          columnType: productNameFilter.dataType,
          columnSettings: productNameFilter.dataSettings
        }
      });
    }

    // 3: the third column reserved for Visual Features (custom Attributes)
    this.customAttributesColumnIndex = headers.length + 3;
    this.columnDefs.push({
      headerName: 'Visual Features',
      valueGetter: (item: any) => (item.data && item.data[headers.length + 3]) || '', // will return Array of pair:s {name: string, value: string}
      cellRenderer: CustomAttributesComponent,
      width: 220,
      headerComponentParams: {
        isShown: true,
        isEditableTitle: true,
        dataIndex: headers.length + 3,
        columnIndex: headers.length + 3,
        columnName: VISUAL_FEATURES_COLUMN,
        columnType: 'string',
        columnSettings: null
      }
    });

    headers.forEach((header, index) => {
      if (index !== this.productNameIndex && index !== this.productIdIndex) {
        const columnFilter = filters.find(item => item.name === header);
        this.columnDefs.push({
          headerName: header,
          valueGetter: (item: any) => String((item.data && item.data[index]) || ''),
          width: 200,
          headerComponentParams: {
            isShown: true,
            isEditableTitle: true,
            dataIndex: index,
            columnIndex: index,
            columnName: headers[index],
            columnType: columnFilter.dataType,
            columnSettings: columnFilter.dataSettings
          }
        });
      }
    });
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
    this.gridColumnApi = $event.columnApi;

    // -- this.filterForVisibleColumns();
    // -- this.rowData = this.rows;
  }

  private updateCustomAttributes(rows: any[]) {
    if (this.headers) {
      // init position index and copy Custom Attributes
      if (this.customAttributesColumnIndex) {
        for (let index = 0; index < rows.length; index++) {
          const row: any = rows[index];
          const prodId = row[this.productIdIndex];

          // eslint-disable-next-line dot-notation
          row.position = index;

          // copy custom attributes to the 'Visual Features' column
          row[this.customAttributesColumnIndex] = [];
          if (this.customAttributes) {
            Object.keys(this.customAttributes).forEach(attributeName => {
              const attribute = this.customAttributes[attributeName];
              Object.keys(attribute).forEach(valueName => {
                const prodIds = attribute[valueName];
                if (prodIds.some(id => id === prodId)) {
                  row[this.customAttributesColumnIndex].push({ name: attributeName, value: valueName });
                }
              });
            });
          }
        }
      }
    }
  }

  toggleColumnList(): void {
    this.isShowColumnList = !this.isShowColumnList;
    this.isShowColumnListChange.emit(this.isShowColumnList);
  }

  hideColumnList(): void {
    if (this.isShowColumnList) {
      this.isShowColumnList = false;
      this.isShowColumnListChange.emit(this.isShowColumnList);
    }
  }

  filterForVisibleColumns() {
    const columnStates = [];
    this.headers.forEach((header, index) => {
      columnStates.push({ colId: header.getId(), hide: !header.getColDef().headerComponentParams.isShown });
    });
    this.gridColumnApi?.applyColumnState({
      state: columnStates
    });
  }

  // some column has been hidden/shown
  refreshVisibleColumns() {
    this.filterForVisibleColumns();
  }

  dropColumn(event: CdkDragDrop<IHeaderData[]>) {
    this.gridColumnApi.moveColumnByIndex(event.previousIndex + 2, event.currentIndex + 2);
  }

  storeTableState() {
    const collageId: string = this.store.selectSnapshot(CollageState.getActiveCollageId);

    const settings: IBusinessDataGridSettings = JSON.parse(sessionStorage.getItem(BUSINESS_DATA_GRID_KEY)) || {};

    // save the column's state
    const savedState = this.gridColumnApi.getColumnState();

    settings[collageId] = {
      state: savedState
    };

    sessionStorage.setItem(BUSINESS_DATA_GRID_KEY, JSON.stringify(settings));
  }

  restoreTableState() {
    const collageId: string = this.store.selectSnapshot(CollageState.getActiveCollageId);
    const settings: IBusinessDataGridSettings = JSON.parse(sessionStorage.getItem(BUSINESS_DATA_GRID_KEY)) || {};
    const lastSettings = settings[collageId];
    if (lastSettings) {
      this.gridColumnApi.applyColumnState({ state: lastSettings.state });
    }
  }

  onCellMouseOver(event: CellMouseOverEvent<any>) {
    this.hoveredRow = event.data;
  }

  onStopEditHeader(isRestoreOriginal?: boolean) {
    if (this.headerToEdit) {
      if (isRestoreOriginal) {
        this.newHeaderText = ''; // send empty string to restore Original header
      }
      this.store.dispatch(
        new SetFriendlyHeaderTitle({ header: this.headerToEdit.title, headerTitle: this.newHeaderText })
      );
    }
    // use timeout, to avoid conflicts with Header Sort functionality
    setTimeout(() => {
      this.newHeaderText = null;
      this.headerToEdit = null;
    });
    this.window.document.removeEventListener('click', this.outOfEditHeaderTitleClickListenerRef);
  }

  private onDocumentClick(event: PointerEvent) {
    if (this.alternativeTitleEditRef?.nativeElement !== event.target) {
      this.onStopEditHeader();
    }
  }

  onSelectDefaultTitle($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ChangeProductNameColumnDialogComponent, {
      panelClass: 'change-product-name-column-dialog',
      data: {
        productIdIndex: this.productIdIndex,
        productNameIndex: this.productNameIndex
      }
    });
    dialogRef.afterClosed().subscribe((productNameColumn: string) => {
      if (productNameColumn) {
        // change it for Collage (can be success only if User is Collage OWNER)
        this.store.dispatch(new SetActiveCollageDisplayNameField(productNameColumn));
      }
    });
  }

  onSelectionChanged($event: SelectionChangedEvent<any>) {
    // this.selectedRows = this.gridApi.getSelectedRows();
  }

  onCopySelectionToClipboard() {
    const selectedRowIndexes = this.selection.getSelectedRowsForServerQuery();
    this.getAllRowsFromServer(selectedRowIndexes).subscribe(
      text => {
        const listener = (e: ClipboardEvent): void => {
          const clipboard = e.clipboardData || (window as any).clipboardData;
          clipboard.setData('text', text);
          e.preventDefault();
          this.showMessage('Selected rows copied to Clipboard');
        };
        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
      },
      error => {
        console.error('Cannot download selected rows', error);
      },
      () => {
        this.isAllRowsLoading = false;
      }
    );
  }

  onDownloadSelection() {
    const selectedRowIndexes = this.selection.getSelectedRowsForServerQuery();
    const selectedRowsNumber = this.selection.getSelectedRowsNumber();
    this.getAllRowsFromServer(selectedRowIndexes).subscribe(
      text => {
        const date = new Date().toString();
        downloadFile(`Selected_${selectedRowsNumber}_items_${date}.csv`, text);
        this.showMessage('Selected rows downloaded');
      },
      error => {
        console.error('Cannot download selected rows', error);
      },
      () => {
        this.isAllRowsLoading = false;
      }
    );
  }

  onDownloadAllRowsInView() {
    this.isAllRowsLoading = true;
    this.getAllRowsFromServer().subscribe(
      text => {
        const totalRows = this.rowsDataSource.rowCount;
        const date = new Date().toString();
        downloadFile(`All_view_${totalRows}_items_${date}.csv`, text);
        this.showMessage('All rows for current view downloaded');
      },
      error => {
        console.error('Cannot download all rows in view', error);
      },
      () => {
        this.isAllRowsLoading = false;
      }
    );
  }

  showMessage(message: string) {
    this.snackBar.open(message, '', {
      panelClass: 'info',
      duration: 3000
    });
  }

  onContextMenu($event: MouseEvent) {
    // console.log('--> onContextMenu', $event);
    $event.preventDefault();
    let text = null;
    if (this.hoveredRow || this.selection.isHasSelection()) {
      this.contextMenuPosition.x = $event.clientX;
      this.contextMenuPosition.y = $event.clientY;
      let rows: ISelectedRowsForServerQuery = null;
      const selectedRowIndexes = this.selection.getSelectedRowsForServerQuery();
      // if (this.selectedRows.length > 0) {
      if (this.selection.isHasSelection()) {
        rows = selectedRowIndexes;
        // text = this.rowsToCSVText(rows);
      } else {
        rows = selectedRowIndexes;
        text = this.rowsToCSVText([[...this.hoveredRow]]);
      }

      this.contextMenu.menuData = { items: rows, text };
      this.contextMenu.openMenu();
    }
  }

  private rowsToCSVText(rows: any[]): string {
    const displayedColumns = this.headers.filter(
      (column, index) => index > 0 && column.getColDef().headerComponentParams.isShown
    );

    // check is need to save CSV file with Original headers or with Friendly names for them
    const headers: IHeaderData[] = displayedColumns.map(column => {
      const headerName = column.getDefinition().headerName;
      return {
        title: this.isShowOriginalHeaders ? headerName : this.friendlyName.getFriendlyName(headerName),
        column: column.getColDef().headerComponentParams.columnName,
        dataIndex: column.getColDef().headerComponentParams.dataIndex,
        columnIndex: column.getColDef().headerComponentParams.columnIndex,
        isShown: true,
        columnType: 'string',
        columnSettings: {},
        columnWidth: 0
      };
    });

    // export custom attributes as separate columns
    const customAttributesColumnIndex = headers.find(item => item.column === VISUAL_FEATURES_COLUMN)?.dataIndex;
    if (this.customAttributes && customAttributesColumnIndex) {
      // ok, VISUAL_FEATURES_COLUMN is visible
      // so add new column for each Custom Attribute: "Visual Feature XXXX" column
      Object.keys(this.customAttributes).forEach((attributeName, attrbuteIndex) => {
        const columnName = `Visual Feature ${attributeName}`;
        headers.push({
          title: columnName,
          column: columnName,
          dataIndex: this.customAttributesColumnIndex + attrbuteIndex + 1,
          columnIndex: headers.length + 1,
          isShown: true,
          columnType: 'string',
          columnSettings: {},
          columnWidth: 0
        });
        rows.forEach(row => {
          const visualAttributeValue = [];
          row[this.customAttributesColumnIndex].forEach(customAttribute => {
            if (customAttribute.name === attributeName) {
              visualAttributeValue.push(customAttribute.value);
            }
          });
          row[this.customAttributesColumnIndex + attrbuteIndex + 1] = visualAttributeValue.join(',');
        });
      });
    }

    const text = rowsToCSVText(headers, rows, [SELECTION_COLUMN, VISUAL_FEATURES_COLUMN]);

    // rows = rows.map(row => row.slice(0, this.headers.length));
    return text;
  }
}
