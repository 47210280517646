import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DatabaseConnectionType } from '@app/state/connection/business/business-connection.model';

export const passwordMatchingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password?.value === confirmPassword?.value ? null : { notmatched: true };
};

// TODO: add here some validation logic if need, for different type of Business connection
export const businessConnectionValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const databaseType: DatabaseConnectionType = control.get('database').value;
  if (databaseType === DatabaseConnectionType.Snowflake) {
    const auth = control.get('snowflake').get('auth');
    // return dataJSON?.value ? null : { required: true };
  }
  if (databaseType === DatabaseConnectionType.CSV) {
    control = control.get('csv');
    const dataJSON = control.get('csvFile');
    // return dataJSON?.value ? null : { required: true };
  }
  return null;
};

export const selectionRequiredValidator = (headers: Array<string>): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const index = headers.findIndex(header => {
      return header === control.value;
    });
    return index < 0 ? { selectionRequired: true } : null;
  };
};

export const notEmptyValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  if (!value) {
    return null;
  }
  if (value.trim() === '' || value.trim().length < 1) {
    return { empty: true };
  }
  return null;
};
