import { Component, HostListener, Input } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { ICollage } from '@app/state/collage/collage.model';
import { IStudy } from '@app/state/study/study.model';
import { StudyUtils } from '@app/utils/study.utils';

@Component({
  selector: 'app-home-collage-card',
  templateUrl: './home-collage-card.component.html',
  styleUrls: ['./home-collage-card.component.scss']
})
export class HomeCollageCardComponent {
  @Input() public collage: ICollage;
  public readonly PortalMenuItem = PortalMenuItem;
  private currentUser: User;

  @HostListener('click')
  public onClick(): void {
    const newStudy: IStudy = StudyUtils.create(this.collage, this.currentUser);
    this.projectDataService.createStudy(newStudy).then(() => {
      this.router.navigate(['/build'], { queryParams: { studyId: newStudy.id } });
    });
  }

  constructor(
    private router: Router,
    private projectDataService: ProjectDataService,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.currentUser;
  }
}
