import { Action, State, StateContext, Selector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  SetAggregateData,
  SetUserData,
  UpdateDimension,
  UpdateDimensions,
  UpdateMeasures,
  SetFilteredRows,
  RefreshFilters,
  ResetUserData,
  UpdateCalculationResults,
  SetTotalRowsInView
} from './user-data.actions';
import { IFiltersModel } from '@app/models/filters.model';
import { Injectable } from '@angular/core';
import { ICustomCalculationResults, IUserDataStateModel } from './user-data.model';
import { IAxesShelfModel } from '@app/models/workspace-list.model';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { FiltersTypeEnum } from '../../models/filters.model';

const initialState = {
  headers: [],
  rows: [],
  dimensions: [],
  measures: [],
  calculationResults: {}
};

@State<IUserDataStateModel>({
  name: 'userData',
  defaults: {
    ...initialState
  }
})
@Injectable()
export class UserDataState {
  constructor(private appLoaderService: AppLoaderService) {}

  @Selector([UserDataState])
  static getHeader(state: IUserDataStateModel) {
    return state.headers;
  }

  @Selector([UserDataState])
  static getTotalRowsInView(state: IUserDataStateModel | null): number {
    return state.totalRowsInView || 0;
  }

  @Selector([UserDataState])
  static getDimensions(state: IUserDataStateModel): Array<IFiltersModel> {
    return state.dimensions;
  }

  @Selector([UserDataState])
  static getDimensionsAndMeasures(state: IUserDataStateModel): Array<IFiltersModel> {
    return [...state.dimensions, ...state.measures];
  }

  @Selector([UserDataState])
  static getCalculationResults(state: IUserDataStateModel): ICustomCalculationResults {
    return state.calculationResults || {};
  }

  @Selector([UserDataState])
  static getFilteredRows(state: IUserDataStateModel) {
    return state.filteredRows;
  }

  @Action(SetFilteredRows)
  SetFilteredRows({ patchState }: StateContext<IUserDataStateModel>, { payload }: SetFilteredRows) {
    patchState({ filteredRows: payload });
  }

  @Action(SetAggregateData)
  SetAggregateData({ patchState }: StateContext<IUserDataStateModel>, { payload }: SetAggregateData) {
    patchState({ aggregateData: payload });
  }

  @Action(SetUserData)
  SetUserData({ setState }: StateContext<IUserDataStateModel>, { payload }: SetUserData) {
    setState(payload);
  }

  @Action(ResetUserData)
  ResetUserData({ setState }: StateContext<IUserDataStateModel>) {
    setState({ ...initialState });
  }

  @Action(UpdateDimensions)
  UpdateDimensions({ setState }: StateContext<IUserDataStateModel>, { payload }: UpdateDimensions) {
    setState(
      patch({
        dimensions: payload
      })
    );
  }

  @Action(UpdateDimension)
  UpdateDimension({ getState, setState }: StateContext<IUserDataStateModel>, { payload }: UpdateDimension) {
    const { dimensions } = getState();
    const filterIndex = dimensions.findIndex(filter => filter.name === payload.name);
    if (filterIndex >= 0) {
      dimensions[filterIndex] = payload;
    } else {
      dimensions.push(payload);
    }
    setState(
      patch({
        dimensions: [...dimensions]
      })
    );
  }

  @Action(UpdateMeasures)
  UpdateMeasures({ setState }: StateContext<IUserDataStateModel>, { payload }: UpdateMeasures) {
    setState(
      patch({
        measures: payload
      })
    );
  }

  @Action(UpdateCalculationResults)
  UpdateCalculationResults({ setState }: StateContext<IUserDataStateModel>, { payload }: UpdateCalculationResults) {
    setState(
      patch({
        calculationResults: payload
      })
    );
  }

  @Action(RefreshFilters)
  refreshFilters({ getState, setState }: StateContext<IUserDataStateModel>, { payload }: RefreshFilters) {
    this.appLoaderService.setShowLoader('data', true, 'Refreshing filters...', 2000);
    const { dimensions } = getState();
    if (dimensions) {
      const axesXY: IAxesShelfModel = payload || { x: [], y: [], filtering: [] };
      const userFilters = new Map([...axesXY.x, ...axesXY.y].map(filter => [filter.name, filter]));
      const userFiltering = new Map([...axesXY.filtering].map(filter => [filter.name, filter]));
      const refreshedDimensions: Array<IFiltersModel> = dimensions.map(item => {
        const filter: IFiltersModel = userFilters.get(item.name);
        const discreteValues = item.discreteValues
          ? item.discreteValues.map(({ name, productsLength }) => ({
              name,
              productsLength: productsLength || null,
              checked: filter && filter.discreteValues.map(value => value.name).includes(name)
            }))
          : undefined;
        const isAllChecked =
          filter?.type === FiltersTypeEnum.Continuous
            ? filter.currentMinMaxValue?.min === filter.minMaxValue?.min &&
              filter.currentMinMaxValue?.max === filter.minMaxValue?.max
            : discreteValues?.every(({ checked }) => checked);
        return {
          ...item,
          discreteValues,
          isAllChecked,
          currentMinMaxValue: filter?.currentMinMaxValue || filter?.minMaxValue,
          stringValue: filter?.stringValue
        };
      });
      setState(
        patch({
          dimensions: refreshedDimensions
        })
      );
    }
  }

  @Action(SetTotalRowsInView)
  private setTotalRowsInView({ setState }: StateContext<IUserDataStateModel>, { payload }: SetTotalRowsInView) {
    setState(patch({ totalRowsInView: payload }));
  }
}
