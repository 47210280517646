import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { passwordMatchingValidator } from '@app/utils/custom-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  @Input() public oobCode: string;
  public resetForm: FormGroup;

  public get password(): FormControl {
    return this.resetForm.get('password') as FormControl;
  }

  public get confirmPassword(): FormControl {
    return this.resetForm.get('confirmPassword') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private route: ActivatedRoute) {
    this.resetForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['']
      },
      { validators: passwordMatchingValidator }
    );
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');
  }

  public resetPassword(): void {
    const { password } = this.resetForm.value;
    this.authService.resetPassword(this.oobCode, password);
  }
}
