<div class="left-menu-content">
  <mat-expansion-panel
    hideToggle
    #expansionPanel
    [@matExpantionPanelAnimation]="showLeftmenu ? 'open' : 'closed'"
    (opened)="onOpened()"
  >
    <mat-expansion-panel-header class="top-header" (click)="showLeftmenu = !showLeftmenu">
      <svg-icon
        svgClass="icon"
        name="icon-left-menu-quick-access"
        matTooltip="Quick menu"
        matTooltipPosition="right"
        matTooltipClass="right"
        [matTooltipDisabled]="showLeftmenu"
      ></svg-icon>
    </mat-expansion-panel-header>

    <div class="menu-content scrollable" #menuContent matExpansionPanelContent>
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg-icon svgClass="icon" name="icon-collages"></svg-icon>
              <span>Collages</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false">
            <mat-list-option (click)="addCollage()"><span class="item item-new"> + Collage</span></mat-list-option>
            <ng-container *ngIf="collageList$ | async as collageList">
              <mat-list-option
                *ngFor="let collage of collageList"
                [selected]="collage.id === (activeCollageId$ | async)"
                (click)="selectCollage(collage)"
                ><span class="item">{{ collage.metadata.name }}</span></mat-list-option
              >
            </ng-container>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg-icon svgClass="icon" name="icon-studies"></svg-icon>
              <span>Studies</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false">
            <mat-list-option (click)="addStudy()"><span class="item item-new"> + Study</span></mat-list-option>
            <ng-container *ngIf="studyList$ | async as studyList">
              <mat-list-option
                *ngFor="let study of studyList"
                [selected]="study.id === (activeStudyId$ | async)"
                (click)="selectStudy(study)"
              >
                <span class="item">{{ study.metadata.name }}</span>
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel [expanded]="false" [disabled]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <svg-icon svgClass="icon" name="icon-notification-2"></svg-icon>
              <span>Review</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list [multiple]="false">
            <mat-list-option><span class="item">Glossy Seams</span></mat-list-option>
            <mat-list-option><span class="item">Symmetry</span></mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel> -->
      </mat-accordion>
    </div>
  </mat-expansion-panel>
</div>
