import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ExploreBarType, FilterDataType, FiltersTypeEnum, IFiltersModel } from '@app/models/filters.model';
import { IAxesShelfModel } from '@app/models/workspace-list.model';
import { ICustomDragDrop } from '@app/shared/components/adaptive-toolbar/adaptive-toolbar.component';
import { WINDOW } from '@app/shared/utils/window';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { IExploreBarSubComponentSettingsModel } from '@app/utils/explore-bar.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { FilterBoxComponent } from '../filter-box/filter-box.component';

@UntilDestroy()
@Directive()
export abstract class ExploreBarBase implements OnInit, OnChanges {
  @Select(StudyState.getAxesXY)
  public readonly userFilters$: Observable<IAxesShelfModel>;

  @Select(StudyState.getHighlightFilters)
  public readonly highlightFilters$: Observable<IFiltersModel[]>;

  @ViewChild('filterBoxComponentRef', { static: true })
  protected readonly filterBoxComponentRef: FilterBoxComponent;
  public filterBoxRef: ElementRef;

  @Input() filters: Array<IFiltersModel> = [];
  @Input() settings: IExploreBarSubComponentSettingsModel;
  @Input() showFilterBox: boolean;
  @Output() filterBoxToggled: EventEmitter<ExploreBarType> = new EventEmitter();
  public filtersToShow: Array<IFiltersModel> = [];
  public isResizingFilterBox = false;
  public showBusinessFilters = true;
  public showVisualFilters = true;
  public lastToggledFilterType: FilterDataType;
  public expandedFilterIndex = null;

  public userFilters: Array<IFiltersModel> = [];
  public highlightFilters: Array<IFiltersModel> = [];
  public filtering: Array<IFiltersModel> = [];

  public readonly FiltersTypeEnum = FiltersTypeEnum;

  protected readonly outOfFilterBoxClickListenerRef = this.onDocumentClick.bind(this);
  protected abstract setAxis();
  public abstract readonly filterIdPrefix: ExploreBarType;

  get selectedFilters(): Array<IFiltersModel> {
    if (this.filterIdPrefix === 'highlighting') {
      return this.highlightFilters;
    }
    return this.userFilters;
  }
  set selectedFilters(value: Array<IFiltersModel>) {
    if (this.filterIdPrefix === 'highlighting') {
      this.highlightFilters = value;
    }
    this.userFilters = value;
  }

  // use timeout before sending Axes changes (user can select a few checkboxes in short time)
  protected debounceChangeAxes: Subject<void> = new Subject<void>();

  constructor(
    protected readonly store: Store,
    @Inject(WINDOW)
    protected readonly window: Window,
    protected readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // don't rebuild Axes in Matrix each time when some filter checkbox changed - wait for delay,
    // maybe another checkbox will be changed soon, so app will rebuild matrix for both checkboxes after some delay
    this.debounceChangeAxes.pipe(debounceTime(1000)).subscribe(() => this.setAxis());

    this.filterBoxRef = this.filterBoxComponentRef.filterBoxRef;

    if (this.filterIdPrefix === 'highlighting') {
      this.highlightFilters$.pipe(untilDestroyed(this)).subscribe((highlightFilters: IFiltersModel[]) => {
        this.highlightFilters = highlightFilters || [];
        this.initFilterToShowSelectedValues();
      });
    } else {
      this.userFilters$
        .pipe(
          untilDestroyed(this),
          filter(data => !!data)
        )
        .subscribe(data => {
          this.initUserFilters(data);
          this.initFilterToShowSelectedValues();
        });
    }
  }

  protected initUserFilters(data: IAxesShelfModel) {
    this.filtering = null;
    if (this.filterIdPrefix === 'filtering') {
      this.userFilters = [...data.filtering];
    } else {
      // If block is necessary for older workspaces where value was saved on AxesX
      if (this.store.selectSnapshot(StudyState.getMatrixType) === ValidMatrix.Freestyle && data.x.length > 0) {
        this.userFilters = [...data.x];
      } else {
        this.userFilters = [...data.y];
      }
      this.filtering = [...data.filtering];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) this.updateFiltersToShow();
    if (changes.showFilterBox) {
      if (changes.showFilterBox.currentValue) {
        this.showBusinessFilters = true;
        this.showVisualFilters = true;
        this.updateFiltersToShow();
        setTimeout(() => {
          this.window.document.addEventListener('click', this.outOfFilterBoxClickListenerRef);
        });
      } else {
        this.window.document.removeEventListener('click', this.outOfFilterBoxClickListenerRef);
      }
    }
  }

  onToggleFilterType(type: FilterDataType) {
    if (!this.settings.enableFiltersToggle) return;
    this.lastToggledFilterType = type;
    this.expandedFilterIndex = null;
    if (type === 'business') {
      this.showBusinessFilters = !this.showBusinessFilters;
      if (!this.showBusinessFilters) this.showVisualFilters = true;
    } else {
      this.showVisualFilters = !this.showVisualFilters;
      if (!this.showVisualFilters) this.showBusinessFilters = true;
    }
    this.updateFiltersToShow();
  }

  updateFiltersToShow() {
    const filtersByDimsMeasures = [];
    if (this.settings?.showDimensions) filtersByDimsMeasures.push(...this.filters.filter(f => !f.isMeasure));
    if (this.settings?.showMeasures) filtersByDimsMeasures.push(...this.filters.filter(f => f.isMeasure));

    const filtersByBusinessVisual = [];
    if (this.lastToggledFilterType === 'business') {
      if (this.showBusinessFilters) filtersByBusinessVisual.push(...filtersByDimsMeasures.filter(f => !f.isVisual));
      if (this.showVisualFilters) filtersByBusinessVisual.push(...filtersByDimsMeasures.filter(f => f.isVisual));
    } else {
      if (this.showVisualFilters) filtersByBusinessVisual.push(...filtersByDimsMeasures.filter(f => f.isVisual));
      if (this.showBusinessFilters) filtersByBusinessVisual.push(...filtersByDimsMeasures.filter(f => !f.isVisual));
    }

    this.filtersToShow = [...filtersByBusinessVisual];
    this.initFilterToShowSelectedValues();
    // this.refreshUserFilters(); //  fix: @Select(StudyState.getAxesXY) sometimes not triggered in this component, so refresh it manually
  }

  refreshUserFilters() {
    const axes = this.store.selectSnapshot(StudyState.getAxesXY);
    this.initUserFilters(axes);
    this.initFilterToShowSelectedValues();
  }

  protected initFilterToShowSelectedValues() {
    if (this.filtersToShow.length > 0 && this.selectedFilters) {
      this.filtersToShow = this.filtersToShow.map(filterToShow => {
        const selectedFilterIndex = this.selectedFilters.findIndex(
          selectedFilter => selectedFilter.name === filterToShow.name
        );
        if (selectedFilterIndex !== -1) {
          const selectedFilter = this.selectedFilters[selectedFilterIndex];
          if (filterToShow.type === FiltersTypeEnum.Continuous || filterToShow.type === FiltersTypeEnum.Date) {
            return {
              ...filterToShow,
              isAllChecked:
                selectedFilter.isAllChecked ||
                !selectedFilter.currentMinMaxValue ||
                (selectedFilter.currentMinMaxValue?.min === selectedFilter.minMaxValue?.min &&
                  selectedFilter.currentMinMaxValue?.max === selectedFilter.minMaxValue?.max),
              currentMinMaxValue: selectedFilter.currentMinMaxValue
            };
          }

          if (filterToShow.discreteValues) {
            return {
              ...filterToShow,
              isAllChecked: selectedFilter.isAllChecked,
              discreteValues: filterToShow.discreteValues.map(value => {
                return {
                  ...value,
                  checked:
                    selectedFilter.isAllChecked ||
                    (!selectedFilter.isExcluded &&
                      selectedFilter.discreteValues.some(selectedValue => value.name === selectedValue.name)) ||
                    (selectedFilter.isExcluded &&
                      selectedFilter.discreteValues.every(deselectedValue => value.name !== deselectedValue.name))
                };
              })
            };
          }
        }
        if (filterToShow.type === FiltersTypeEnum.Continuous || filterToShow.type === FiltersTypeEnum.Date) {
          return {
            ...filterToShow,
            isAllChecked: false,
            currentMinMaxValue: undefined
          };
        }

        if (filterToShow.discreteValues) {
          return {
            ...filterToShow,
            isAllChecked: false,
            discreteValues: filterToShow.discreteValues.map(value => ({ ...value, checked: false }))
          };
        }
        return { ...filterToShow };
      });
    }
  }

  public addFilterItem(event: MouseEvent) {
    event.stopPropagation();
    if (!this.showFilterBox || (this.showFilterBox && this.expandedFilterIndex !== null)) {
      const offset = event.clientX - event.offsetX;
      this.updateFilterBoxPosition(offset);
      this.expandedFilterIndex = null;
      this.window.document.addEventListener('click', this.outOfFilterBoxClickListenerRef);
      setTimeout(() => {
        this.window.document.getElementById(`${this.filterIdPrefix}-filter-item-0`)?.scrollIntoView();
      });
      if (!this.showFilterBox) this.filterBoxToggled.emit(this.filterIdPrefix);
    } else {
      this.window.document.removeEventListener('click', this.outOfFilterBoxClickListenerRef);
      this.filterBoxToggled.emit(null);
    }
  }

  protected updateFilterBoxPosition(filterBoxOffset: number) {
    const filterBoxWidth = this.filterBoxComponentRef?.currentWidth;
    if (filterBoxOffset + filterBoxWidth > this.window.innerWidth) {
      filterBoxOffset = this.window.innerWidth - filterBoxWidth - 10;
    }
    this.filterBoxRef?.nativeElement.style.setProperty('left', `${filterBoxOffset}px`);
  }

  public onResizeFilterBox(filterBox: FilterBoxComponent) {
    if (this.isResizingFilterBox !== filterBox.isResizing) {
      setTimeout(() => {
        // bugfix:
        // flag will be changed with minimum delay, to give time for onDocumentClick() which will react in correct way:
        // - if it was resizing and user clicked out of filterBox - then ignore last mouseup event
        // - if it was not resizing - close the filterBox
        this.isResizingFilterBox = filterBox.isResizing;
      });
    }
  }

  private onDocumentClick(event: PointerEvent) {
    const dataPickerClick = event.composedPath().some((item: Element) => item.localName === 'mat-datepicker-content');
    if (
      this.filterBoxRef?.nativeElement !== event.target &&
      !this.filterBoxRef?.nativeElement.contains(event.target) &&
      !this.filterBoxComponentRef?.isResizing &&
      !dataPickerClick
    ) {
      if (this.isResizingFilterBox) {
        this.isResizingFilterBox = false;
        event.preventDefault();
        return; // bugfix: last time was resizing, don't close the filter box
      }
      this.expandedFilterIndex = null;
      this.window.document.removeEventListener('click', this.outOfFilterBoxClickListenerRef);
      this.filterBoxToggled.emit(null);
      this.cdr.markForCheck();
    }
  }

  public onDrop(e: ICustomDragDrop) {
    if (!this.canBeDropped(e)) {
      return;
    }
    if (e.isPointerOverContainer) {
      moveItemInArray(this.selectedFilters, e.previousIndex, e.currentIndex);
    } else if (e.isPointerOverFilterContainer) {
      const filterToConvert = {
        ...this.selectedFilters[e.previousIndex]
      };
      const index = this.selectedFilters.findIndex(item => item.name === filterToConvert.name);
      if (index !== -1) {
        this.selectedFilters[index] = filterToConvert;
      }
    } else {
      const index = this.selectedFilters.findIndex(item => item.name === this.selectedFilters[e.currentIndex]?.name);
      if (index !== -1) {
        this.removeFilterItem(index);
        transferArrayItem(this.selectedFilters, [], index, 0);
        this.updateFiltersToShow();
      }
    }
    this.debounceChangeAxes.next();
  }

  protected canBeDropped(e: CdkDragDrop<IFiltersModel[]>): boolean {
    // by default user is not limited, limitation depends on implementation of subclasses
    return true;
  }

  public onMinMaxValueChange(filterModel: IFiltersModel, minVal: any | Date, maxVal: any | Date) {
    let min;
    let max;
    if (minVal instanceof Date) {
      min = minVal > filterModel.minMaxValue.min ? minVal : filterModel.minMaxValue.min;
      max = maxVal < filterModel.minMaxValue.max ? maxVal : filterModel.minMaxValue.max;
    } else {
      min =
        parseFloat(minVal as any) > Number(filterModel.minMaxValue.min)
          ? parseFloat(minVal as any)
          : filterModel.minMaxValue.min;
      max =
        parseFloat(maxVal as any) < Number(filterModel.minMaxValue.max)
          ? parseFloat(maxVal as any)
          : filterModel.minMaxValue.max;
    }

    filterModel.currentMinMaxValue = { min, max };

    const index = this.selectedFilters.findIndex(el => el.name === filterModel.name);
    if (index > -1) {
      this.selectedFilters[index].currentMinMaxValue = { min, max };
      this.selectedFilters[index].isAllChecked =
        this.selectedFilters[index].currentMinMaxValue.min === this.selectedFilters[index].minMaxValue.min &&
        this.selectedFilters[index].currentMinMaxValue.max === this.selectedFilters[index].minMaxValue.max;
    } else {
      const newFilter = {
        name: filterModel.name,
        currentMinMaxValue: { min, max },
        minMaxValue: filterModel.minMaxValue,
        type: filterModel.type,
        isAllChecked: true,
        isCustomAttr: filterModel.isCustomAttr || false,
        isCustomCalc: filterModel.isCustomCalc || false,
        customCalcField: filterModel.customCalcField || null
      };
      this.selectedFilters.push(newFilter);
    }

    this.debounceChangeAxes.next();
  }

  public onStringValueChange(filtersModel: IFiltersModel, inputVal: string) {
    filtersModel.stringValue = inputVal;
    const index = this.selectedFilters.findIndex(el => el.name === filtersModel.name);
    if (index > -1) {
      this.selectedFilters[index].stringValue = inputVal;
    } else {
      const newFilter = {
        name: filtersModel.name,
        stringValue: inputVal,
        type: filtersModel.type,
        isCustomAttr: filtersModel.isCustomAttr || false,
        isCustomCalc: filtersModel.isCustomCalc || false,
        customCalcField: filtersModel.customCalcField || null
      };
      this.selectedFilters.push(newFilter);
    }

    this.debounceChangeAxes.next();
  }

  public onCheckboxValueChange(filtersModel: IFiltersModel, valueIndex: number, event) {
    filtersModel.discreteValues[valueIndex].checked = event.checked;
    const index = this.selectedFilters.findIndex(el => el.name === filtersModel.name);
    if (event.checked) {
      if (index !== -1) {
        if (filtersModel.discreteValues.every(value => !!value.checked)) {
          delete this.selectedFilters[index].discreteValues;
          this.selectedFilters[index].isAllChecked = true;
          this.selectedFilters[index].isExcluded = false;
        } else if (this.selectedFilters[index].isExcluded) {
          this.selectedFilters[index].discreteValues = this.selectedFilters[index].discreteValues.filter(
            ({ name }) => name !== filtersModel.discreteValues[valueIndex].name
          );
        } else if (
          filtersModel.discreteValues.filter(val => !!val.checked).length >
          filtersModel.discreteValues.length / 2
        ) {
          this.selectedFilters[index].isExcluded = true;
          this.selectedFilters[index].discreteValues = filtersModel.discreteValues
            .filter(val => !val.checked)
            .map(({ name }) => ({ name }));
        } else {
          this.selectedFilters[index].discreteValues.push({
            name: `${filtersModel.discreteValues[valueIndex].name}`
          });
        }
      } else {
        const newFilter = {
          name: filtersModel.name,
          discreteValues: [
            {
              name: `${filtersModel.discreteValues[valueIndex].name}`
            }
          ],
          type: filtersModel.type,
          isCustomAttr: filtersModel.isCustomAttr || false,
          isCustomCalc: filtersModel.isCustomCalc || false,
          customCalcField: filtersModel.customCalcField || null
        };
        this.selectedFilters.push(newFilter);
      }
    } else if (index !== -1) {
      filtersModel.isAllChecked = false;
      if (this.selectedFilters[index].isAllChecked) {
        this.selectedFilters[index].isAllChecked = false;
        this.selectedFilters[index].isExcluded = true;
        this.selectedFilters[index].discreteValues = filtersModel.discreteValues
          .filter(val => !val.checked)
          .map(({ name }) => ({ name }));
      } else if (this.selectedFilters[index].isExcluded) {
        if (filtersModel.discreteValues.filter(val => !!val.checked).length <= filtersModel.discreteValues.length / 2) {
          this.selectedFilters[index].isExcluded = false;
          this.selectedFilters[index].discreteValues = filtersModel.discreteValues
            .filter(val => !!val.checked)
            .map(({ name }) => ({ name }));
        } else {
          this.selectedFilters[index].discreteValues.push({
            name: `${filtersModel.discreteValues[valueIndex].name}`
          });
        }
      } else {
        const valIndex = this.selectedFilters[index].discreteValues
          .map(({ name }) => name)
          .indexOf(filtersModel.discreteValues[valueIndex].name);
        this.selectedFilters[index].discreteValues.splice(valIndex, 1);
        if (this.selectedFilters[index].discreteValues.length === 0) {
          this.selectedFilters.splice(index, 1);
        }
      }
    }

    const selFilter = this.selectedFilters[index];
    if (
      selFilter !== undefined &&
      selFilter.isAllChecked !== true &&
      selFilter.isExcluded !== true &&
      selFilter.discreteValues.length === 0
    ) {
      // remove Filter from selected, if all checkboxes not selected
      this.selectedFilters.splice(index, 1);
    }

    this.debounceChangeAxes.next();
  }

  public onSelectAllCheckboxValueChange(filtersModel: IFiltersModel, event) {
    this.expandedFilterIndex = this.filtersToShow.findIndex(item => item.name === filtersModel.name);
    filtersModel.isAllChecked = event.checked;

    if (filtersModel.type === FiltersTypeEnum.Continuous || filtersModel.type === FiltersTypeEnum.Date) {
      this.onSelectAllMinMaxChange(filtersModel);
    } else {
      this.onSelectAllDiscreteChange(filtersModel);
    }

    this.debounceChangeAxes.next();
  }

  private onSelectAllDiscreteChange(filtersModel: IFiltersModel) {
    filtersModel.discreteValues.forEach(val => {
      val.checked = filtersModel.isAllChecked;
    });
    const index = this.selectedFilters.findIndex(el => el.name === filtersModel.name);
    if (filtersModel.isAllChecked) {
      let newFilter = {
        name: filtersModel.name,
        type: filtersModel.type,
        isAllChecked: true,
        isCustomAttr: filtersModel.isCustomAttr || false
      };
      if (index === -1) {
        this.selectedFilters.push(newFilter);
      } else {
        this.selectedFilters[index] = newFilter;
      }
    } else if (index > -1) {
      this.selectedFilters[index] = null;
      this.selectedFilters.splice(index, 1);
    }
  }

  private onSelectAllMinMaxChange(filtersModel: IFiltersModel) {
    if (filtersModel.isAllChecked) {
      /**
       * If filter is from 'sorting' filter box, we need to clear any other selected filter
       * as only one filter can be selected in 'sorting' filter box
       */
      if (this.filterIdPrefix === 'sorting') this.selectedFilters = [];
      const index = this.selectedFilters.findIndex(el => el.name === filtersModel.name);
      let min;
      let max;
      if (filtersModel.dataType === 'date') {
        min = filtersModel.minMaxValue.min;
        max = filtersModel.minMaxValue.max;
      } else {
        min = Number.parseFloat(filtersModel.minMaxValue.min.toString());
        max = Number.parseFloat(filtersModel.minMaxValue.max.toString());
      }
      filtersModel.currentMinMaxValue = { min, max };
      if (index > -1) {
        this.selectedFilters[index].currentMinMaxValue = { min, max };
        this.selectedFilters[index].isAllChecked = true;
      } else {
        const newFilter = {
          name: filtersModel.name,
          isAllChecked: true,
          currentMinMaxValue: { min, max },
          minMaxValue: { min, max },
          dataType: filtersModel.dataType,
          type: filtersModel.type,
          isCustomAttr: filtersModel.isCustomAttr || false,
          isCustomCalc: filtersModel.isCustomCalc || false,
          customCalcField: filtersModel.customCalcField || null
        };
        this.selectedFilters.push(newFilter);
      }
    } else {
      const filterIndex = this.selectedFilters.findIndex(el => el.name === filtersModel.name);
      if (filterIndex >= 0) {
        this.removeFilterItem(filterIndex);
        transferArrayItem(this.selectedFilters, [], filterIndex, 0);
      }
    }
  }

  // TODO check for performance drop
  public checkSelectAllIndeterminate(filtersModel: IFiltersModel): boolean {
    if (filtersModel.type === FiltersTypeEnum.Continuous || filtersModel.type === FiltersTypeEnum.Date) {
      return (
        filtersModel.currentMinMaxValue.min !== filtersModel.minMaxValue.min ||
        filtersModel.currentMinMaxValue.max !== filtersModel.minMaxValue.max
      );
    }
    return (
      filtersModel.isAllChecked &&
      filtersModel.discreteValues.some(val => val.checked) &&
      filtersModel.discreteValues.some(val => !val.checked)
    );
  }

  private removeFilterItem(index: number) {
    const filterIndex = this.filters.findIndex(el => el.name === this.selectedFilters[index]?.name);
    if (filterIndex > -1) {
      this.filters[filterIndex].currentMinMaxValue = null;
      this.filters[filterIndex].stringValue = null;
      this.filters[filterIndex].discreteValues?.forEach(val => {
        val.checked = false;
      });
      this.filters[filterIndex].isAllChecked = false;
    }
  }

  public editFilterItem(event: MouseEvent, item: IFiltersModel) {
    event.stopPropagation();

    // if any filter hidden, we cannot show and scroll to it, so all filters must be visible
    this.showBusinessFilters = true;
    this.showVisualFilters = true;
    this.updateFiltersToShow();

    const indexOf = this.filtersToShow.findIndex(el => el.name === item.name);
    this.expandedFilterIndex = this.expandedFilterIndex === indexOf ? null : indexOf;

    if (!this.showFilterBox || (this.showFilterBox && this.expandedFilterIndex !== null)) {
      const offset = event.clientX - event.offsetX;
      this.updateFilterBoxPosition(offset);

      this.window.document.addEventListener('click', this.outOfFilterBoxClickListenerRef);
      setTimeout(() => {
        if (this.expandedFilterIndex >= 0) {
          this.window.document
            .getElementById(`${this.filterIdPrefix}-filter-item-${this.expandedFilterIndex}`)
            ?.scrollIntoView();
        }
      });
      if (!this.showFilterBox) this.filterBoxToggled.emit(this.filterIdPrefix);
    } else {
      this.window.document.removeEventListener('click', this.outOfFilterBoxClickListenerRef);
      this.filterBoxToggled.emit(null);
    }
  }
}
