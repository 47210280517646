import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { paretoPrevBlockListAnimation } from '@app/components/animations/animations';
import { IParetoGroupModel, IParetoImageMatrixBoundsModel } from '@app/models/pareto-model';
import { GroupOrientation } from '@app/models/study-setting.model';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { SetParetoDetailsBarFixedPosition } from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { getSum } from '@app/utils/image-data.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-pareto-subtotal-percent-overlay',
  templateUrl: './pareto-subtotal-percent-overlay.component.html',
  styleUrls: ['./pareto-subtotal-percent-overlay.component.scss'],
  animations: [paretoPrevBlockListAnimation]
})
export class ParetoSubtotalPercentOverlayComponent implements OnInit, OnDestroy {
  @Select(StudyState.getParetoDetailsBarFixedPosition)
  public readonly paretoDetailsBarFixedPosition$: Observable<boolean>;

  displayData: Array<IParetoGroupModel>;
  prevBlocksData: Array<{ numBlocks: number; hasHalfBlock: boolean }>;
  imageMatrixData: IParetoImageMatrixBoundsModel;
  wrapperStyles;
  blockStyles;
  blockBottomStyles;
  previewBlockStyle;
  blockWrapperStyles;
  wrapperTransition = 'none';

  hideOverlay = false;
  overlayFixedPosition = false;
  overlayOrientation = GroupOrientation.Horizontal;

  constructor(private matrixService: MatrixService, private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.matrixService.paretoDislpayDataSubject
      .pipe(untilDestroyed(this))
      .subscribe((data: Array<IParetoGroupModel>) => {
        const matrixOrientation =
          this.store.selectSnapshot(StudyState.getGroupOrientation) || GroupOrientation.Vertical;
        this.overlayOrientation =
          matrixOrientation === GroupOrientation.Vertical ? GroupOrientation.Horizontal : GroupOrientation.Vertical;

        if (this.displayData && this.displayData.length === data.length) {
          data.forEach((group, i) => {
            this.displayData[i].products = group.products;
            this.displayData[i].subtotal = group.subtotal;
            this.displayData[i].subtotalWithPrevVal = group.subtotalWithPrevVal;
          });
        } else if (this.displayData) {
          this.displayData = this.displayData.splice(0, data.length);
          data.forEach((group, i) => {
            if (!this.displayData[i]) {
              this.displayData[i] = {
                products: group.products,
                subtotal: group.subtotal,
                subtotalWithPrevVal: group.subtotalWithPrevVal
              };
            } else {
              this.displayData[i].products = group.products;
              this.displayData[i].subtotal = group.subtotal;
              this.displayData[i].subtotalWithPrevVal = group.subtotalWithPrevVal;
            }
          });
        } else {
          this.displayData = data;
        }
        this.updatePrevBlocks();
        this.wrapperTransition = 'width 300ms';
        this.updateStyles();
      });
    this.matrixService.paretoImageMatrixBoundsSubject
      .pipe(untilDestroyed(this))
      .subscribe((data: IParetoImageMatrixBoundsModel) => {
        this.imageMatrixData = data;
        this.wrapperTransition = data.animation ? 'width 300ms' : 'none';
        this.updateStyles();
      });

    this.paretoDetailsBarFixedPosition$.pipe(untilDestroyed(this)).subscribe((payload: boolean) => {
      this.overlayFixedPosition = payload;
    });
  }

  ngOnDestroy(): void {
    this.displayData = null;
  }

  private updatePrevBlocks() {
    this.prevBlocksData = [];
    this.displayData.forEach(group => {
      const subtotalFull = Math.round(group.subtotal);
      const subtotalPreview = subtotalFull / 10;
      const hasHalfPreviewBlock =
        subtotalPreview - Math.floor(subtotalPreview) > 0 && subtotalPreview - Math.floor(subtotalPreview) < 0.5;
      const numBlocks = Math.ceil(subtotalPreview);
      this.prevBlocksData.push({ numBlocks, hasHalfBlock: hasHalfPreviewBlock });
    });
  }

  private updateStyles() {
    if (!this.imageMatrixData) return;
    const { blockMinSize, imageSize, distanceBetweenImages, columnSizes, scale, bounds } = this.imageMatrixData;

    const fontSize = Math.round(24 * scale);
    const padding = Math.round(24 * scale);
    const previewElementOverlaySize = Math.round(24 * scale);
    const previewElementSize = Math.round(18 * scale);
    this.hideOverlay = blockMinSize > 500;

    const distanceBetweenColumns = (imageSize + distanceBetweenImages) / 2;
    const blockWrapperSizes = columnSizes.map(size => size + distanceBetweenColumns);
    const wrapperSize = getSum(blockWrapperSizes);

    if (this.overlayOrientation === GroupOrientation.Horizontal) {
      let top = bounds.y + bounds.height + distanceBetweenImages;
      const height = 120 * scale;
      if (top + height > window.innerHeight - 115) top = window.innerHeight - 115 - height;

      this.wrapperStyles = {
        'left.px': bounds.x + bounds.width / 2 - wrapperSize / 2,
        'top.px': top,
        'fontSize.px': fontSize,
        'width.px': wrapperSize
      };
      this.blockWrapperStyles = blockWrapperSizes.map(size => {
        return {
          'width.px': size,
          'padding.px': padding
        };
      });
      this.blockStyles = {
        'width.px': blockMinSize,
        'min-width.px': blockMinSize
      };
      this.blockBottomStyles = {
        'height.px': previewElementOverlaySize,
        'padding.px': Math.round(3 * scale)
      };
      this.previewBlockStyle = {
        'width.px': previewElementSize,
        'height.px': previewElementSize
      };
    } else {
      this.wrapperStyles = {
        right: `calc(100% - ${bounds.x - distanceBetweenImages}px)`,
        'top.px': bounds.y + bounds.height / 2 - wrapperSize / 2,
        'fontSize.px': fontSize,
        'height.px': wrapperSize
      };

      this.blockWrapperStyles = blockWrapperSizes.map(size => {
        return {
          'height.px': size,
          'padding.px': padding
        };
      });
      this.blockStyles = {
        'height.px': blockMinSize,
        'min-height.px': blockMinSize
      };
      this.blockBottomStyles = {
        'width.px': previewElementOverlaySize,
        'padding.px': Math.round(3 * scale)
      };
      this.previewBlockStyle = {
        'width.px': previewElementSize,
        'height.px': previewElementSize,
        'margin-top.px': previewElementOverlaySize * 0.25
      };
    }

    this.cdr.detectChanges();
  }

  onOverlayFixedPositionChange() {
    this.overlayFixedPosition = !this.overlayFixedPosition;
    this.store.dispatch(new SetParetoDetailsBarFixedPosition(this.overlayFixedPosition));
  }
}
