<div class="left-header">
  <div class="left-header-content">
    <a class="logo" [routerLink]="['/home']"
      ><svg-icon
        svgClass="logo-image"
        name="logo-collagia"
        matTooltip="Home"
        matTooltipPosition="below"
        [matTooltipClass]="['below', 'home']"
      ></svg-icon>
      <p *ngIf="showBetaSign" class="beta-sign">Beta</p>
    </a>
    <ng-container *ngIf="collageName !== null && studyTitle !== null">
      <div class="collage-title" matTooltip="Collage name" matTooltipPosition="below" matTooltipClass="below">
        <span #collage class="collage-name" [textContent]="collageName"></span>
      </div>
      <div class="study-title" matTooltip="Study name" matTooltipPosition="below" matTooltipClass="below">
        <span
          contenteditable="true"
          [title]="studyPlaceholder"
          #study
          class="study-name editable-title"
          (keydown.enter)="study.blur()"
          [textContent]="studyTitle"
          (blur)="setStudyName($event)"
        ></span>
      </div>
    </ng-container>
  </div>
</div>
