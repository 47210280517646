import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounce, debounceTime, filter, switchMap } from 'rxjs/operators';

/**
 * Directive to add CSS classes 'delayed-hover' and 'hover' into the DOM element
 *
 * Notes:
 * - 'delayed-hover' class always present in the DOM element
 * - 'hover' class will be added after mouse enter with [delayEnter] delay (measured in ms)
 * - 'hover' class will be removed after mouse leave with [delayLeave] delay (measured in ms)
 *
 * Examples:
 * <div class="container" (app-delayed-hover)="onHover($event)" [delayLeave]="1000">
 *   Will add 'hover' class without delay, and will remove 'hover' class after 1000ms
 * </div>
 *
 * <div class="container" (app-delayed-hover)="isHovered = $event" [delayEnter]="200" [delayLeave]="1000">
 *   Mouse: {{isHovered ? 'enter' : 'leave'}}
 * </div>
 */
@Directive({
  selector: '[app-delayed-hover]'
})
export class DelayedHoverDirective implements OnInit {
  @Input() public delayEnter = 0;
  @Input() public delayLeave = 1000;
  @Input() public set menuOpened(value: boolean) {
    this.menuOpened$.next(value);
  }

  @Output('app-delayed-hover') hoverEvent = new EventEmitter();

  private readonly menuOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly element: ElementRef) {}

  ngOnInit() {
    const main = this.element.nativeElement;
    main.classList.add('delayed-hover');
    fromEvent(main, 'mouseenter')
      .pipe(
        debounceTime(this.delayEnter),
        switchMap(() => {
          main.classList.add('hover');
          this.hoverEvent.emit(true);
          return fromEvent(main, 'mouseleave').pipe(
            debounce(() => this.menuOpened$.pipe(filter(val => val === false))),
            debounceTime(this.delayLeave)
          );
        })
      )
      .subscribe(() => {
        this.hoverEvent.emit(false);
        main.classList.remove('hover');
      });
  }
}
