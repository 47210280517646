import { Injectable, inject } from '@angular/core';
import {
  Storage as FireStorage,
  ref,
  uploadBytesResumable,
  percentage,
  getDownloadURL,
  deleteObject,
  UploadTaskSnapshot,
  UploadTask
} from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { uuid } from '@app/shared/utils/uuid';
import { AuthService } from '../auth/auth.service';

export interface IUploadResult {
  file: File;
  fileRef: string; // file name with path, uploaded on server
  fileName: string; // original file name
  uploadProgress: Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }>;
  task: UploadTask;
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileManagerService {
  private storage: FireStorage = inject(FireStorage);
  constructor(protected authService: AuthService) {}

  /**
   * Uploading file into the server. Final Ref for file on the server will be:
   *   uploads/{current-user-id}/{path}/{generated-filename-id}
   * @param file local File object
   * @param path additional path, for example, 'images' or 'documents/excel'
   */
  uploadFile(file: File, path: string = ''): IUploadResult {
    const id = uuid();
    path = path === '' ? '' : path[0] === '/' ? path : `/${path}`;

    const fileRef = `uploads/${this.authService.currentUser.uid}${path}/${id}`;
    const storageRef = ref(this.storage, fileRef);
    const task = uploadBytesResumable(storageRef, file);

    // observe percentage and task changes
    const uploadResult = {
      id,
      file,
      fileRef,
      fileName: file.name,
      uploadProgress: percentage(task),
      task
    };
    return uploadResult;
  }

  getUploadedFileURL(fileRef: string): Promise<string> {
    const storageRef = ref(this.storage, fileRef);
    return getDownloadURL(storageRef);
  }

  deleteFile(fileRef: string) {
    if (fileRef) {
      const storageRef = ref(this.storage, fileRef);
      deleteObject(storageRef);
    }
  }
}
