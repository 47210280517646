<div class="highlighter-filter-box-container">
  <app-filter-box
    #filterBoxComponentRef
    [showFilterBox]="showFilterBox"
    [showBusinessFilters]="showBusinessFilters"
    [showVisualFilters]="showVisualFilters"
    [showActions]="{
      clearAll: true,
      done: true
    }"
    [filtersToShow]="filtersToShow"
    [expandedFilterIndex]="expandedFilterIndex"
    [settings]="settings"
    [prefix]="filterIdPrefix"
    [resizeCorners]="{
      leftTop: false,
      rightTop: true,
      leftBottom: false,
      rightBottom: true
    }"
    [validateResize]="onValidateResize.bind(this)"
    (resizing)="onResizeFilterBox($event)"
    (onToggleFilterType)="onToggleFilterType($event)"
    (onSelectAll)="onSelectAllCheckboxValueChange($event.filter, $event.event)"
    (onClearAll)="onClearAll()"
    (onDone)="onClose()"
    (onCheckboxChange)="onCheckboxValueChange($event.filter, $event.index, $event.event)"
    (onMinMaxChange)="onMinMaxValueChange($event.filter, $any($event.min), $any($event.max))"
    (onStringChange)="onStringValueChange($event.filter, $event.inputValue)"
  ></app-filter-box>
</div>
