import { Injectable } from '@angular/core';
import { ImagePlaceholder } from '@app/custom-pixi-containers/image-container';
import { DamService, IProductBulk } from '@app/services/dam/dam.service';
import { ImageManagerType } from '@app/state/connection/image/image-connection.model';
import { race, BehaviorSubject, Subject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { getPartsFromArray } from '@app/utils/array.utils';

export const qualityByImageSize = {
  256: 'low',
  512: 'medium',
  1024: 'high'
};

const placeholderUrl = {
  [ImagePlaceholder.DEFAULT]: 'assets/images/collagia-logo-placeholder.svg',
  [ImagePlaceholder.COLUMBIA]: 'assets/images/columbia-logo-placeholder.svg',
  [ImagePlaceholder.NIKE]: 'assets/images/nike-logo-placeholder.svg'
};

@Injectable({
  providedIn: 'root'
})
export class LookupTableService {
  private baseUrl: string = '';
  private placeholder: ImagePlaceholder = ImagePlaceholder.DEFAULT;
  private viewPlaceholderImage$: BehaviorSubject<Array<{ id: string; view: string; url: string }>> =
    new BehaviorSubject(null);
  private _productImages: IProductBulk = {};
  private _lastProgressSubject$: BehaviorSubject<number> = null;

  public readonly created$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly cancelled$: Subject<void> = new Subject();

  public get productImages(): IProductBulk {
    return this._productImages;
  }

  public get productImagesCount(): number {
    return Object.keys(this._productImages).length;
  }

  constructor(private damService: DamService) {}

  public createLookupTableForOrbImages(
    imageData: Array<{ PRODID: string; IMAGES: Array<string> }>,
    uniqueProdIds: Array<string>,
    fromConfig: boolean = false
  ): void {
    this.setImagePlaceholder();
    this.baseUrl = 'https://images.collagia.com/dam/';
    uniqueProdIds.forEach(prodId => {
      const views = ['Down', 'Front', 'Up', 'Top', 'Bottom'];
      if (prodId && prodId !== '') {
        const productData = imageData.find(orbName => orbName.PRODID === prodId);
        this._productImages[prodId] = {
          prefix: productData ? `${this.baseUrl}` : '',
          suffix: '',
          perspectives: productData
            ? productData.IMAGES.reduce((acc, image, index) => {
                const view = views[index];
                image = image.slice(0, image.lastIndexOf('.'));
                return {
                  ...acc,
                  [view]: {
                    low: `${image}-256.png`,
                    medium: `${image}-512.png`,
                    high: `${image}-1024.png`
                  }
                };
              }, {})
            : {}
        };
      }
    });

    if (fromConfig) {
      this.setViewPlaceholderImages();
    }
    this.created$.next(true);
  }

  /**
   * Load image info for list of uniqueProdIds,
   * @param uniqueProdIds
   * @param dataAccessType
   * @param businessConnectionId
   * @param fromConfig
   * @return Observable object, which return % of prepared items
   */
  public createLookupTableForImagesDAM(
    uniqueProdIds: Array<string>,
    dataAccessType: ImageManagerType,
    businessConnectionId: string,
    fromConfig: boolean = false
  ): Subject<number> {
    this.reset(); // stop previous requests
    const progressSubject$ = new BehaviorSubject(0);
    this._lastProgressSubject$ = progressSubject$;
    this.setImagePlaceholder(dataAccessType);
    if (dataAccessType === ImageManagerType.ColumbiaS7)
      this.damService.runCachingJob(uniqueProdIds, businessConnectionId).pipe(take(1)).subscribe();
    // Uncomment this to test bulk request duration
    // --> const start = Date.now();
    let partIndex = 0;
    (fromConfig
      ? race(
          getPartsFromArray(uniqueProdIds.length > 500 ? uniqueProdIds.slice(0, 500) : uniqueProdIds, 50).map(
            (part, index, array) =>
              this.damService.getProductBulkParts(part, dataAccessType, true, 500, true, progressSubject$).pipe(
                tap(() => ++partIndex),
                filter(
                  partBulk =>
                    partIndex === array.length ||
                    part
                      .map(prodId => partBulk[prodId] || {})
                      .some(perspectives => Object.keys(perspectives).length > 0)
                )
              )
          )
        )
      : this.damService.getProductBulkParts(uniqueProdIds, dataAccessType, true, 500, true, progressSubject$)
    )
      .pipe(take(1))
      .subscribe(
        res => {
          // check that current loading was not stopped by another call of createLookupTableForImagesDAM()
          if (!progressSubject$.isStopped) {
            this._productImages = res;
            if (fromConfig) {
              this.setViewPlaceholderImages();
            }
            setTimeout(() => {
              // send result with delay, the message with 'loaded 100%' should be first
              this.created$.next(true);
            }, 250);
          }
        },
        () => {},
        () => {
          progressSubject$.next(100);
          progressSubject$.complete();
          // Uncoment this to test bulk request duration
          // --> const end = Date.now();
          // --> console.log(`Bulk request took ${end - start}ms`);
        }
      );
    return progressSubject$;
  }

  public getProductImage(prodId: string, view: string, size: number): string {
    const quality = qualityByImageSize[size];
    if (
      this._productImages &&
      this._productImages[prodId] &&
      this._productImages[prodId].perspectives &&
      this._productImages[prodId].perspectives[view] &&
      this._productImages[prodId].perspectives[view][quality]
    ) {
      return `${this._productImages[prodId].prefix}${this._productImages[prodId].perspectives[view][quality]}${this._productImages[prodId].suffix}`;
    }
    return '';
  }

  public setViewPlaceholderImages(productsToShow: Set<string> = null): void {
    let maxNumImages = 0;
    let productWithMaxNumImages = null;
    if (productsToShow === null) productsToShow = new Set(Object.keys(this._productImages));
    for (const prodId of productsToShow) {
      if (this._productImages[prodId] && this._productImages[prodId].perspectives) {
        const { perspectives } = this._productImages[prodId];
        if (perspectives && Object.keys(perspectives).length > maxNumImages) {
          maxNumImages = Object.keys(perspectives).length;
          productWithMaxNumImages = {
            ...this._productImages[prodId],
            bid: prodId
          };
        }
      }
    }
    if (productWithMaxNumImages) {
      this.viewPlaceholderImage$.next(
        Object.keys(productWithMaxNumImages.perspectives).map(view => ({
          id: productWithMaxNumImages.bid,
          view,
          url: `${productWithMaxNumImages.prefix}${productWithMaxNumImages.perspectives[view].high}${productWithMaxNumImages.suffix}`
        }))
      );
    } else {
      this.viewPlaceholderImage$.next([]);
    }
  }

  public getViewPlaceholderImages(): Observable<Array<{ id: string; view: string; url: string }>> {
    return this.viewPlaceholderImage$.asObservable().pipe(filter(Boolean));
  }

  public reset(): void {
    this.baseUrl = '';
    this.viewPlaceholderImage$.next(null);
    this._productImages = {};
    if (this._lastProgressSubject$ && !this._lastProgressSubject$.closed) {
      this.cancelled$.next();
      this._lastProgressSubject$.next(-1); // stopped, send to all subscriptions to Cancel requests (see canceled$ in DAM bulk requests)
      this._lastProgressSubject$.complete();
      this._lastProgressSubject$ = null;
    }
    this.created$.next(false);
  }

  public getTotalUniqProductIDs(): number {
    return Object.entries(this._productImages).length;
  }

  public getStats(uniqueProdIds: Set<string>) {
    const uniqBIDs = Array.from(uniqueProdIds); // TODO: don't convert Set to Array, work with Set if possible
    const imagesCount = uniqBIDs
      .map(prodId => (this._productImages[prodId] ? Object.keys(this._productImages[prodId].perspectives) : []))
      .reduce((acc, imageArray) => {
        return acc + imageArray.length;
      }, 0);
    const productWithImagesCount = uniqBIDs.reduce((acc, prodId) => {
      if (this._productImages[prodId] && Object.keys(this._productImages[prodId].perspectives).length > 0) {
        acc++;
      }
      return acc;
    }, 0);
    return {
      avg: parseFloat((imagesCount / uniqueProdIds.size).toFixed(2)),
      hasImages: parseFloat(((productWithImagesCount * 100) / uniqueProdIds.size).toFixed(2))
    };
  }

  public getAllImageViews(prodId: string) {
    const { perspectives } = this.productImages[prodId];
    return Object.keys(perspectives).map((view: string) => ({ view, url: this.getProductImage(prodId, view, 256) }));
  }

  public setImagePlaceholder(dataAccessType: ImageManagerType = null): void {
    switch (dataAccessType) {
      case ImageManagerType.ColumbiaS7:
        this.placeholder = ImagePlaceholder.COLUMBIA;
        break;
      case ImageManagerType.Nike:
        this.placeholder = ImagePlaceholder.NIKE;
        break;
      default:
        this.placeholder = ImagePlaceholder.DEFAULT;
        break;
    }
  }

  public getImagePlaceholder(): ImagePlaceholder {
    return this.placeholder;
  }

  public getPlaceholderUrl(): string {
    return placeholderUrl[this.placeholder];
  }
}
