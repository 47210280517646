<form #form="ngForm" class="auth-form" [formGroup]="resetForm" (ngSubmit)="form.valid && resetPassword()">
  <div
    class="form-field"
    [ngClass]="{
      invalid: (form.submitted || password.touched) && password.invalid
    }"
  >
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="password"
      formControlName="password"
      placeholder="Password"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || password.touched) && password.hasError('required')" class="error">
      Password is required
    </div>
  </div>
  <div
    class="form-field"
    [ngClass]="{
      invalid: (form.submitted || confirmPassword.touched) && confirmPassword.invalid
    }"
  >
    <div class="form-field__prefix">
      <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
    </div>
    <input
      class="form-field__input"
      type="password"
      formControlName="confirmPassword"
      placeholder="Confirm password"
      (keyup.enter)="form.onSubmit($event)"
    />
    <div *ngIf="(form.submitted || confirmPassword.touched) && resetForm.hasError('notmatched')" class="error">
      The password confirmation does not match
    </div>
  </div>
  <div class="auth-form__actions">
    <button mat-flat-button color="accent" type="submit">RESET PASSWORD</button>
  </div>
</form>
