<div class="wrapper" *ngIf="additionalData?.snapPoints">
  <div class="slider-wrapper" *ngIf="additionalData">
    <input
      #sliderInput
      *ngFor="let boundIndex of additionalData.groupBoundIndexes; let i = index"
      type="range"
      step="0.1"
      min="0"
      max="100"
      [value]="additionalData.snapPoints[boundIndex]"
      (input)="onInputMouseMove(sliderInput, i)"
      (mousedown)="onInputMouseDown(sliderInput, i)"
      (mouseup)="onInputMouseUp(sliderInput, i)"
    />
    <button
      class="plus-left"
      matTooltip="Add bucket"
      matTooltipPosition="above"
      matTooltipClass="above"
      [disabled]="addButtonsDisabled"
      (click)="onPlusLeftButtonClick()"
    >
      <svg-icon svgClass="icon" name="icon-multi-handle-slider-plus"></svg-icon>
    </button>
    <button
      class="plus-right"
      matTooltip="Add bucket"
      matTooltipPosition="above"
      matTooltipClass="above"
      [disabled]="addButtonsDisabled"
      (click)="onPlusRightButtonClick()"
    >
      <svg-icon svgClass="icon" name="icon-multi-handle-slider-plus"></svg-icon>
    </button>
    <div class="slider">
      <div class="track"></div>
      <div
        class="thumb"
        #thumbs
        *ngFor="let item of [].constructor(numInputs); let i = index"
        #tooltipElement
        #tooltip="matTooltip"
        matTooltip="Cumulative % of total"
        matTooltipPosition="above"
        matTooltipClass="above"
        [style.left.%]="inputValues[i]"
        [class.active]="activeInputIndex === i"
      >
        {{ inputValues[i] }}%
      </div>
    </div>
  </div>
  <div class="snap-points-wrapper" *ngIf="additionalData?.snapPoints">
    <div class="snap-points" [class.show]="showSnapPoints">
      <span
        *ngFor="let point of additionalData.snapPoints"
        [style.left.%]="point"
        [style.width.px]="snapPointSize"
        [class.active]="point == activeValue"
      ></span>
    </div>
  </div>
</div>
