<div class="wrapper">
  <app-home-card-header
    [type]="PortalMenuItem.Collages"
    [name]="collage.metadata.name"
    [id]="collage.id"
    [favorite]="collage.favorite"
    [owner]="collage.owner"
  ></app-home-card-header>
  <app-home-card-body
    [type]="PortalMenuItem.Collages"
    [id]="collage.id"
    [authorName]="collage.metadata.authorName"
    [createdDate]="collage.created"
    [isDefault]="collage.owner === '*'"
  >
    <div class="collage">
      <div class="collage__connection">
        <svg-icon svgClass="icon" name="icon-business-connection"></svg-icon>
        <span
          [matTooltip]="collage.metadata.businessConnectionName"
          matTooltipPosition="below"
          matTooltipClass="below"
          class="collage__connection__name"
          >{{ collage.metadata.businessConnectionName }}</span
        >
      </div>
      <div class="collage__connection">
        <svg-icon svgClass="icon" name="icon-image-connection"></svg-icon>
        <span
          [matTooltip]="collage.metadata.imageConnectionName"
          matTooltipPosition="below"
          matTooltipClass="below"
          class="collage__connection__name"
          >{{ collage.metadata.imageConnectionName }}</span
        >
      </div>
    </div>
  </app-home-card-body>
</div>
