<div class="explore-bar-sorting-container">
  <button
    class="toggle-additional-info"
    *ngIf="settings.showToggleAdditionalInfo"
    (click)="onToggleAdditionalInfoClick()"
    matTooltip="Toggle image percentage"
    matTooltipPosition="above"
    matTooltipClass="above"
  >
    <svg-icon svgClass="icon" *ngIf="!showImagePercentages" name="icon-frame-info"></svg-icon>
    <svg-icon svgClass="icon" *ngIf="showImagePercentages" name="icon-frame-info-locked"></svg-icon>
  </button>

  <button
    class="direction-indicator"
    *ngIf="settings.showDirectionIndicator"
    [matTooltip]="rankDirectionTop ? 'Switch to Bottom N' : 'Switch to Top N'"
    matTooltipPosition="above"
    matTooltipClass="above"
    matRipple
    [matRippleCentered]="true"
    [matRippleUnbounded]="false"
    (click)="onDirectionIndicatorClick()"
    [class.bottom]="!rankDirectionTop"
  >
    <svg-icon svgClass="icon" name="icon-top-indicator"></svg-icon>
  </button>

  <div
    class="num-entries"
    [class.hide]="!settings.showNumEntries"
    [style.width.px]="numEntriesWidth"
    matTooltip="Number of images"
    matTooltipPosition="above"
    matTooltipClass="above"
  >
    <input
      type="number"
      #numEntriesInput
      [max]="maxNumEntries"
      (blur)="onNumEntriesChange()"
      (keyup.enter)="onNumEntriesChange()"
    />
  </div>

  <div class="filters-added sortBy" *ngIf="sortByFilter">
    <div class="added-filter" matRipple #filterItem (click)="editFilterItem($event, sortByFilter)">
      <div class="filter-name">
        <span>{{ sortByFilter | filterName }}</span>
      </div>
    </div>
  </div>

  <button
    class="add sortBy"
    matRipple
    #sortByAddIconRef
    *ngIf="!sortByFilter"
    (click)="addFilterItem($event)"
    matTooltip="Add metric"
    matTooltipPosition="above"
    matTooltipClass="above"
  >
    <svg-icon svgClass="icon" name="icon-show-add"></svg-icon>
  </button>

  <app-filter-box
    #filterBoxComponentRef
    [showFilterBox]="showFilterBox"
    [showBusinessFilters]="showBusinessFilters"
    [showVisualFilters]="showVisualFilters"
    [filtersToShow]="filtersToShow"
    [filtering]="filtering"
    [expandedFilterIndex]="expandedFilterIndex"
    [settings]="settings"
    [prefix]="filterIdPrefix"
    (resizing)="onResizeFilterBox($event)"
    (onToggleFilterType)="onToggleFilterType($event)"
    (onSelectAll)="onSelectAllCheckboxValueChange($event.filter, $event.event)"
    (onCheckboxChange)="onCheckboxValueChange($event.filter, $event.index, $event.event)"
    (onMinMaxChange)="onMinMaxValueChange($event.filter, $any($event.min), $any($event.max))"
    (onStringChange)="onStringValueChange($event.filter, $event.inputValue)"
  ></app-filter-box>

  <div class="divider"></div>
</div>
