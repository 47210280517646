import { Pipe, PipeTransform } from '@angular/core';
import { IFiltersModel, FiltersTypeEnum } from '@app/models/filters.model';
import { FriendlyNameService } from '../services/friendly-name/friendly-name.service';

@Pipe({
  name: 'filterName',
  pure: false
})
export class FilterNamePipe implements PipeTransform {
  constructor(private service: FriendlyNameService) {}

  transform(filter: IFiltersModel): string {
    const filterName = this.service.getFriendlyName(filter.name);
    return filter.type !== FiltersTypeEnum.Boolean
      ? filterName
      : filterName.substring(0, filterName.toLowerCase().indexOf('y/n') - 1);
  }
}
