import { ComponentFactoryResolver, Inject, Injectable, NgZone, ViewContainerRef } from '@angular/core';
import { BaseMatrix } from './base-matrix';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { Viewport } from 'pixi-viewport';
import { Renderer, Container, Rectangle } from 'pixi.js';
import { FreestyleMatrix } from './freestyle-matrix';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupTableService } from '@app/components/lookup-table';
import { AppLoaderService } from '@app/shared/components/loader/app-loader.service';
import { WINDOW } from '@app/shared/utils/window';
import { Actions, Select, Store, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { DataService } from '../data/data.service';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import { FiltersService } from '../filters/filters.service';
import { FriendlyNameService } from '../friendly-name/friendly-name.service';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  debounce,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  zip
} from 'rxjs';
import { MatrixToolEnum, ValidMatrix } from '@app/state/tools/tools.model';
import {
  IZoomSettings,
  IWorkspaceTools,
  IWorkspace,
  IMatrixStateModel,
  IPosition
} from '@app/models/workspace-list.model';
import { IHoverInfoFieldModel, IRankInfoPanelConfig, GroupOrientation } from '@app/models/study-setting.model';
import { CollageState } from '@app/state/collage/collage.state';
import { DisplayDataState } from '@app/state/display-data/display-data.state';
import { StudyState } from '@app/state/study/study.state';
import { ToolsState } from '@app/state/tools/tools.state';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SetDisplayData } from '@app/state/display-data/display-data.action';
import {
  SetActiveStudy,
  SetActiveWorkspaceId,
  DuplicateWorkspace,
  DeleteActiveWorkspace,
  SetAxesXY,
  SetParetoGroupBoundIndexes,
  SetMatrixType,
  SetN,
  SetRankDirection,
  SetRankAbsolutePlotting,
  SetRankInfoPanelConfig,
  SetGroupOrientation,
  SetParetoColumnSize,
  SetFriendlyHeaderTitle,
  SetShowOriginalHeaders,
  DeleteWorkspace
} from '@app/state/study/study.actions';
import { RestoreSelectedView, SetSelectedView } from '@app/state/tools/tools.actions';
import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { CustomViewport } from '@app/custom-pixi-containers/custom-viewport/CustomViewport';
import * as Sentry from '@sentry/angular';
import { ParetoMatrix } from './pareto-matrix';
import { GroupByMatrix } from './groupby-matrix';
import { RankMatrix } from './rank-matrix';
import {
  IAbsolutePlottingImageHover,
  IAbsolutePlottingPositionSubject,
  IAbsolutePlottingSizeSubject,
  ICompassWorkspaceModel,
  IContextMenuOpenCloseSubject,
  IDrowDotBgSubject,
  IImagePopperMenuSubject,
  IViewport
} from '@app/models/matrix.model';
import {
  IParetoAdditionalDataModel,
  IParetoColumnSizeSubject,
  IParetoGroupModel,
  IParetoImageMatrixBoundsModel
} from '@app/models/pareto-model';
import { clone } from '@app/shared/utils/object';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MatrixService {
  @Select(DisplayDataState.displayData) displayData$: Observable<Array<any>>;
  @Select(UserDataState.getFilteredRows) filteredRows$: Observable<Array<any>>;
  @Select(CollageState.getDisplayNameField) displayNameField$: Observable<string>;
  @Select(ToolsState.getSelectedView) selectedView$: Observable<string>;
  @Select(ToolsState.getSelectedTool) selectedTool$: Observable<MatrixToolEnum>;
  @Select(ToolsState.isActiveMatrixHighlight) highlighter$: Observable<boolean>;
  @Select(StudyState.getMatrixType) matrixType$: Observable<ValidMatrix>;
  @Select(StudyState.getN) numEntries$: Observable<number>;
  @Select(StudyState.getRankDirectionTop) rankDirection$: Observable<boolean>;
  @Select(StudyState.getFrameFeatureName) frameFeatureName$: Observable<string>;
  @Select(StudyState.getActiveWorkspace) activeWorkspace$: Observable<IWorkspace>;
  @Select(StudyState.getHoverInfoConfig) hoverInfoConfig$: Observable<IHoverInfoFieldModel[]>;
  @Select(StudyState.isUpdatingWorkspace) isUpdatingWorkspaceInStore$: Observable<boolean>;
  @Select(StudyState.getRankInfoPanelConfig) rankInfoPanelConfig$: Observable<IRankInfoPanelConfig>;
  @Select(StudyState.getGroupOrientation) groupOrientation$: Observable<GroupOrientation>;
  @Select(StudyState.getParetoColumnSize) paretoColumnSize$: Observable<number>;
  @Select(StudyState.getRankAbsolutePlotting) rankAbsolutePlotting$: Observable<boolean>;
  @Select(StudyState.getShowImagePercentages) showImagePercentages$: Observable<boolean>;

  private settingDisplayDataSubject: ReplaySubject<Boolean> = new ReplaySubject(1);
  public contextMenuOpenCloseSubject: Subject<IContextMenuOpenCloseSubject> = new Subject();
  public drowDotBgSubject: Subject<IDrowDotBgSubject> = new Subject();
  public imagePopperMenuSubject: Subject<IImagePopperMenuSubject> = new Subject();
  public productsToShowSubject: Subject<Set<string>> = new Subject();
  public highlightedProductsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());
  public selectedProductsSubject: Subject<Set<string>> = new Subject();
  public isUpdatingWorkspacesSubject: Subject<boolean> = new Subject();

  public paretoAditionalDataSubject: Subject<IParetoAdditionalDataModel> = new Subject();
  public paretoDislpayDataSubject: Subject<Array<IParetoGroupModel>> = new Subject();
  public paretoColumnSizeSubject: Subject<IParetoColumnSizeSubject> = new Subject();
  public paretoImageMatrixBoundsSubject: Subject<IParetoImageMatrixBoundsModel> = new Subject();

  public rankAbsolutePlottingPointsSubject: Subject<number[]> = new Subject();
  public rankAbsolutePlottingOrientationSubject: Subject<boolean> = new Subject();
  public rankAbsolutePlottingSizeSubject: Subject<IAbsolutePlottingSizeSubject> = new Subject();
  public rankAbsolutePlottingPositionSubject: Subject<IAbsolutePlottingPositionSubject> = new Subject();
  public rankAbsolutePlottingImageHover: Subject<IAbsolutePlottingImageHover> = new Subject();

  public readonly clearCompass$: Subject<boolean> = new Subject();
  public readonly _viewport$ = new Subject<IViewport>();
  public readonly _zoom$ = new Subject<number>();

  private matrices: BaseMatrix[] = [];
  private activeMatrix: BaseMatrix = null;
  private matrixSubs = {};

  private workspaceId: string;
  private matrixType: ValidMatrix;

  public imageInfoOverlayRef: ImageInfoOverlay;
  public viewContainerRef: ViewContainerRef;
  public canvas: HTMLCanvasElement;
  private pixiRenderer: Renderer;
  private stage: Container;
  private viewport: CustomViewport;

  private movedEndTimeout;
  private zoomEndTimeout;
  private zoom: IZoomSettings;
  private savedCenter;
  private savedScale;
  private updateBG = false;
  private viewPortInteractions = false;
  private isDragged: boolean = false;
  private zoomedFromCompass: boolean = false;
  private resizeImages = false;
  private resizeFactor = 0;

  private workspaceFiltersReady = false;

  private updatingWorkspaces: { [id: string]: boolean } = {};
  private matrixIsUpdatingSubs = {};

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    protected store: Store,
    protected snackBar: MatSnackBar,
    protected resourceManagerService: ResourceManagerService,
    protected dataService: DataService,
    protected lookupTable: LookupTableService,
    protected appLoaderService: AppLoaderService,
    protected dropShadowService: DropShadowService,
    protected friendlyNameService: FriendlyNameService,
    protected filterService: FiltersService,
    private actions$: Actions,
    private ngZone: NgZone,
    private factoryResolver: ComponentFactoryResolver,
    private filtersService: FiltersService
  ) {
    this.setupCanvas();
    this.setupPixi();
    this.setupPixiViewport();

    this.dropShadowService.setRenderer(this.pixiRenderer);

    this.subViewportPositionService();
    this.subWorkspace();
    this.subDisplayData();
    this.subMatrixType();
    this.subSelectedView();
    this.subSelectedTool();
    this.subHighlighter();
    this.subNumEntries();
    this.subRankDirection();
    this.subRankAbsolutePlotting();
    this.subRankInfoPanelConfig();
    this.subGroupOrientation();
    this.subParetoColumnSize();
    this.subFrameFeatureName();
    this.subParetoImagePercentages();
    this.subHoverInfoConfig();
  }

  private setupCanvas() {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('class', 'matrix-canvas');
    canvas.setAttribute('id', 'matrix-canvas');
    this.canvas = canvas;

    canvas.addEventListener('mousedown', e => {
      if (this.activeMatrix?.getMatrixType() === ValidMatrix.Freestyle)
        (this.activeMatrix as FreestyleMatrix).onCanvasMouseDown(e);
    });
    canvas.addEventListener('mouseup', e => {
      if (this.activeMatrix?.getMatrixType() === ValidMatrix.Freestyle)
        (this.activeMatrix as FreestyleMatrix).onCanvasMouseUp(e);
    });
  }

  private setupPixi() {
    this.ngZone.runOutsideAngular(() => {
      this.pixiRenderer = new Renderer({
        width: window.innerWidth,
        height: window.innerHeight,
        view: this.canvas,
        antialias: true,
        backgroundAlpha: 0
      });

      this.stage = new Container();
      this.animate = this.animate.bind(this);
      this.animate();
    });
  }

  private setupPixiViewport() {
    this.viewport = new CustomViewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: window.innerWidth,
      worldHeight: window.innerHeight,
      interaction: this.pixiRenderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
      divWheel: this.canvas,
      passiveWheel: false,
      disableOnContextMenu: true
    });
    this.stage.addChild(this.viewport);

    this.viewport
      .clamp({
        direction: 'all',
        underflow: 'center'
      })
      .clampZoom({
        maxWidth: this.viewport.worldWidth,
        maxHeight: this.viewport.worldHeight
      })
      .drag({
        mouseButtons: 'right',
        clampWheel: true
      })
      .pinch()
      .wheel({
        trackpadPinch: true
      })
      .decelerate()
      .on('zoomed', event => {
        if (event.type === 'wheel' || event.type === 'clamp-zoom') {
          clearTimeout(this.zoomEndTimeout);
          const zoomFactor = this.getZoomFactor();
          if (zoomFactor !== this.zoom.zoomFactor) {
            this.zoom.zoomFactor = zoomFactor;
            if (this.zoom.zoomFactor >= this.zoom.minZoomFactor) {
              this.onZoomChange(this.zoom.zoomFactor);
              this.updateViewportOnCompass();
            }
          }
        }
      })
      .on('zoomed-end', () => {
        clearTimeout(this.zoomEndTimeout);
        this.zoomEndTimeout = setTimeout(() => {
          this.onZoomEnd();
          if (this.zoomedFromCompass) {
            this.zoomedFromCompass = false;
            if (this.resizeImages === false) this.activeMatrix?.updateViewportInCanvasState();
          }
        }, 300);
      })
      .on('moved', (event: { viewport: Viewport; type: string }) => {
        clearTimeout(this.movedEndTimeout);
        this.viewPortInteractions = true;
        this.drowDotBG(event.type);
        this.onViewportMove();
        this.updateViewportOnCompass();
      })
      .on('moved-end', () => {
        clearTimeout(this.movedEndTimeout);
        this.movedEndTimeout = setTimeout(() => {
          if (this.resizeImages) {
            this.updateResolution();
          }
          this.onZoomAndMoveEnd();
          Sentry.addBreadcrumb({
            level: 'log',
            message: 'updateViewportInCanvasState',
            data: { workspaceId: this.workspaceId, matrixType: this.matrixType }
          });
          if (this.isDragged === false) this.activeMatrix?.updateViewportInCanvasState();
          this.viewPortInteractions = false;
          this.pixiRenderer.render(this.stage);
        }, 300);
      })
      .on('mouseup', () => {
        this.pixiRenderer.render(this.stage);
        this.viewPortInteractions = false;
      })
      .on('mouseupoutside', () => {
        this.pixiRenderer.render(this.stage);
        this.viewPortInteractions = false;
      })
      .on('drag-start', () => {
        this.isDragged = true;
      })
      .on('drag-end', () => {
        this.viewport.mouseMode = true;
        this.isDragged = false;
      });

    this.zoom = {
      zoomFactor: 1,
      baseScale: this.viewport.scale.x,
      resolution: 1
    };
    this.updateMinZoomFactor();
    this.viewport.fitWorld(true);
    this.viewPortInteractions = false;
  }

  private subViewportPositionService() {
    this._viewport$
      .pipe(
        filter(viewport => viewport.fromCompass),
        untilDestroyed(this)
      )
      .subscribe((viewport: IViewport) => {
        if (viewport.update) {
          this.activeMatrix?.updateViewportInCanvasState();
          this.isDragged = false;
        } else {
          this.isDragged = true;
          clearTimeout(this.movedEndTimeout);
          this.viewport.moveCenter(viewport.x + viewport.width / 2, viewport.y + viewport.height / 2);
          this.viewPortInteractions = true;
          this.drowDotBG();
          this.onViewportMove();
        }
      });

    /**
     * Part of the code is copied from 'Wheel' function implementation in PixiViewport
     * 'point' is supposed to be position of mouse on screen coorinates on top of 'divWheel'.
     * But in this case event happens outside of Viewport,
     * so we need to map coordinate on compass to screen coordinate of Viewport
     */
    this._zoom$.pipe(untilDestroyed(this)).subscribe(deltaY => {
      /**
       * Because of several reasond currently mapping does not work
       * so, similar to how it was working before, viewport will zoom on the center of screen
       */
      const point = { x: this.window.innerWidth / 2, y: this.window.innerHeight / 2 };
      /**
       * For 'step' and 'change' same calculations are used as Viewport uses with build-in 'Wheel' function
       * 0.1 is the default percent of zoom in 'Wheel' function
       */
      const step = -deltaY / 500;
      const change = Math.pow(2, (1 + 0.1) * step);

      const oldPoint = this.viewport.toLocal(point);

      this.viewport.scale.set(this.viewport.scale.x * change);

      // Clamp makes sure that zoom out will stop once viewport is scaled down to window size
      const clamp = this.viewport.plugins.get('clamp-zoom', true);
      if (clamp) clamp.clamp();

      const newPoint = this.viewport.toGlobal(oldPoint);
      this.viewport.x += point.x - newPoint.x;
      this.viewport.y += point.y - newPoint.y;

      this.viewport.emit('zoomed', { viewport: this.viewport, type: 'wheel' });
      this.viewport.emit('moved', { viewport: this.viewport, type: 'wheel' });
    });
  }

  private subWorkspace() {
    this.activeWorkspace$.pipe(untilDestroyed(this)).subscribe(workspace => {
      if (!workspace?.id || this.workspaceId === workspace?.id) return;
      this.workspaceId = workspace?.id;
      if (this.activeMatrix?.getId() !== this.workspaceId) {
        const matrix = this.matrices.find(m => m.getId() === this.workspaceId);
        if (matrix) this.onWorkspaceSwitch(matrix.getMatrixType(), matrix.getId());
      }
    });
    this.actions$.pipe(ofActionDispatched(SetActiveStudy)).subscribe(() => {
      this.workspaceFiltersReady = false;
    });
    this.filterService.preparedAllWorkspaceFilters$.pipe(untilDestroyed(this)).subscribe(workspaceFilters => {
      if (workspaceFilters == null) return;
      this.workspaceFiltersReady = true;
      const workspaceList = { ...this.store.selectSnapshot(StudyState.getWorkspaceEntities) };
      Object.keys(workspaceList).forEach(workspaceId => {
        workspaceList[workspaceId].axesShelf = workspaceFilters[workspaceId];
      });
      this.onStudyOpen(Object.values(workspaceList));
    });
    this.actions$.pipe(ofActionSuccessful(DuplicateWorkspace)).subscribe(() => {
      const activeWorkspace = this.store.selectSnapshot(StudyState.getActiveWorkspace);
      this.onNewWorkspace(activeWorkspace.matrixType, activeWorkspace.id);
    });
    this.actions$.pipe(ofActionSuccessful(DeleteWorkspace)).subscribe(data => {
      this.onWorkspaceDelete(data.workspaceId);
    });
    this.actions$.pipe(ofActionSuccessful(DeleteActiveWorkspace)).subscribe(() => {
      /**
       * This sub is called after 'subMatrixType' is called
       * which causes activeMatrix to be not one that gets deleted
       * but one that is switched to
       *
       * Using 'ofActionDispatched' also does not fix problem
       * because dispatched even it only emited after state changes
       * which also emits matrix type update
       *
       * Potential solution would be to add delay on 'subMatrixType'
       * but that can cause other issues
       *
       * So, instead, we find out id of workspace that needs to be deleted
       * and use regular delete function
       */

      const workspaces = this.store.selectSnapshot(StudyState.getWorkspaceIds);
      const idsToDelete = this.matrices.map(m => m.getId()).filter(id => !workspaces.includes(id));
      idsToDelete.forEach(id => this.onWorkspaceDelete(id));
    });
  }

  private subDisplayData() {
    this.actions$
      .pipe(
        ofActionDispatched(
          SetActiveStudy,
          SetActiveWorkspaceId,
          DuplicateWorkspace,
          DeleteActiveWorkspace,
          SetAxesXY,
          SetParetoGroupBoundIndexes
        )
      )
      .subscribe(() => {
        this.settingDisplayDataSubject.next(true);
      });

    this.actions$
      .pipe(
        ofActionSuccessful(
          SetActiveStudy,
          SetActiveWorkspaceId,
          DuplicateWorkspace,
          DeleteActiveWorkspace,
          SetAxesXY,
          SetParetoGroupBoundIndexes
        ),
        map(
          action =>
            action instanceof SetActiveStudy ||
            action instanceof SetActiveWorkspaceId ||
            action instanceof DuplicateWorkspace ||
            action instanceof DeleteActiveWorkspace
        ),
        debounce(() =>
          zip(
            this.actions$.pipe(ofActionSuccessful(SetDisplayData)),
            this.lookupTable.created$.pipe(filter(Boolean), take(1))
          )
        ),
        switchMap(restore =>
          combineLatest([
            this.displayData$,
            this.activeWorkspace$,
            this.numEntries$,
            this.groupOrientation$,
            this.rankInfoPanelConfig$,
            this.rankDirection$,
            this.rankAbsolutePlotting$
          ]).pipe(
            map(
              ([
                data,
                workspace,
                numEntries,
                groupOrientation,
                rankInfoPanelConfig,
                rankDirection,
                rankAbsolutePlotting
              ]: [Array<any>, IWorkspace, number, GroupOrientation, IRankInfoPanelConfig, boolean, boolean]) => ({
                data,
                restore,
                workspace: restore ? workspace : null,
                numEntries,
                groupOrientation,
                rankInfoPanelConfig,
                rankDirection,
                rankAbsolutePlotting
              })
            ),
            take(1)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(
        ({
          data,
          restore,
          workspace,
          numEntries,
          groupOrientation,
          rankInfoPanelConfig,
          rankDirection,
          rankAbsolutePlotting
        }) => {
          if (workspace) {
            const state: IMatrixStateModel = (({ id, frames, groups, matrix, imageMatrix, tools }) => ({
              id,
              frames,
              groups,
              matrix,
              imageMatrix,
              tools
            }))(workspace);
            this.activeMatrix?.setMatrixState(state);
            this.activeMatrix?.setSelectedView(workspace.tools.selectedView);
            this.store.dispatch(new RestoreSelectedView(workspace.tools.selectedView));
          }
          if (this.matrixType === ValidMatrix.Rank) {
            this.activeMatrix?.setNumEntries(numEntries);
            this.activeMatrix?.setGroupOrientation(groupOrientation);
            this.activeMatrix?.setInfoPanelConfig(rankInfoPanelConfig);
            this.activeMatrix?.setRankDirection(rankDirection);
            this.activeMatrix?.setRankAbsolutePlotting(rankAbsolutePlotting);
          }
          if (this.matrixType === ValidMatrix.Pareto) {
            this.activeMatrix?.setGroupOrientation(groupOrientation);
          }
          if (this.store.selectSnapshot(StudyState.isNeedRebuildMatrix)) {
            restore = false;
          }
          // this.resourceManagerService.stopActiveLoaders();
          this.activeMatrix?.setDisplayData(data, restore);
          this.settingDisplayDataSubject.next(false);
        }
      );
  }

  private subMatrixType() {
    this.matrixType$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((matrixType: ValidMatrix) => {
      if (!this.workspaceFiltersReady) return;
      const activeWorkspaceId: string = this.store.selectSnapshot(StudyState.getActiveWorkspaceId);
      if (!matrixType) {
        if (this.activeMatrix) {
          this.activeMatrix.setActiveState(false);
          this.activeMatrix.clearMatrix(false);
          this.viewport.removeChild(this.activeMatrix.matrix);
          this.removeMatrixSubs(this.activeMatrix);
          this.activeMatrix = null;
          this.pixiRenderer.render(this.stage);
        }
        return;
      }

      if (!this.getMatrixIds().includes(activeWorkspaceId)) {
        this.onNewWorkspace(matrixType, activeWorkspaceId);
        return;
      }

      if (this.activeMatrix) {
        if (this.activeMatrix.getId() !== activeWorkspaceId) {
          this.onWorkspaceSwitch(matrixType, activeWorkspaceId);
          return;
        } else if (this.activeMatrix.getMatrixType() !== matrixType) {
          this.onWorkspaceTypeChange(matrixType, activeWorkspaceId);
        }
      }
    });
  }

  private subSelectedView() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetSelectedView),
        switchMap(() => this.selectedView$.pipe(take(1))),
        untilDestroyed(this)
      )
      .subscribe((view: string) => {
        this.activeMatrix?.changeImageView(view);
      });
  }

  private subSelectedTool() {
    this.selectedTool$.pipe(untilDestroyed(this)).subscribe((tool: MatrixToolEnum) => {
      this.activeMatrix?.setSelectedTool(tool);
    });
  }

  private subHighlighter() {
    this.highlighter$.pipe(untilDestroyed(this)).subscribe((isActive: boolean) => {
      this.activeMatrix?.setHighlighter(isActive);
    });
  }

  private subNumEntries() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetN, SetMatrixType),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap((action: SetN | SetMatrixType) =>
          this.numEntries$.pipe(
            take(1),
            map((numEntries: number) => ({ numEntries, updateMatrix: action instanceof SetN }))
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(({ numEntries, updateMatrix }) => {
        if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) {
          this.activeMatrix.setNumEntries(numEntries, updateMatrix);
        }
      });
  }

  private subRankDirection() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetRankDirection),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap(() => this.rankDirection$.pipe(take(1))),
        untilDestroyed(this)
      )
      .subscribe((direction: boolean) => {
        if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) {
          this.activeMatrix.setRankDirection(direction, true);
        }
      });
  }

  private subRankAbsolutePlotting() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetRankAbsolutePlotting),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap(() => this.rankAbsolutePlotting$.pipe(take(1))),
        untilDestroyed(this)
      )
      .subscribe((absolute: boolean) => {
        if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) {
          this.activeMatrix.setRankAbsolutePlotting(absolute);
        }
      });
  }

  private subRankInfoPanelConfig() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetRankInfoPanelConfig),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap(() => this.rankInfoPanelConfig$.pipe(take(1))),
        filter(Boolean),
        untilDestroyed(this)
      )
      .subscribe((config: IRankInfoPanelConfig) => {
        if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) {
          this.activeMatrix.setInfoPanelConfig(config, true);
        }
      });
  }

  private subGroupOrientation() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetGroupOrientation),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap(() => this.groupOrientation$.pipe(take(1))),
        filter(Boolean)
      )
      .subscribe((groupOrientation: GroupOrientation) => {
        this.activeMatrix?.setGroupOrientation(groupOrientation, true);
      });
  }

  private subParetoColumnSize() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetParetoColumnSize),
        debounce(() =>
          zip(
            this.isUpdatingWorkspaceInStore$.pipe(filter(val => !val)),
            this.settingDisplayDataSubject.pipe(filter(val => !val))
          ).pipe(take(1))
        ),
        switchMap(() => this.paretoColumnSize$.pipe(take(1))),
        untilDestroyed(this)
      )
      .subscribe((num: number) => {
        if (this.activeMatrix?.getMatrixType() === ValidMatrix.Pareto) {
          this.activeMatrix.setColumnSize(num);
        }
      });
  }

  private subFrameFeatureName() {
    this.frameFeatureName$.pipe(untilDestroyed(this)).subscribe(name => {
      if (this.activeMatrix?.getMatrixType() === ValidMatrix.Freestyle) this.activeMatrix?.setFrameFeatureName(name);
    });
  }

  private subParetoImagePercentages() {
    this.showImagePercentages$.pipe(untilDestroyed(this)).subscribe((show: boolean) => {
      if (this.activeMatrix?.getMatrixType() === ValidMatrix.Pareto)
        (this.activeMatrix as ParetoMatrix).onShowImagePercentages(show);
    });
  }

  private subHoverInfoConfig() {
    this.hoverInfoConfig$.pipe(untilDestroyed(this)).subscribe(data => {
      this.refreshHoverInfoImageData();
    });

    this.actions$
      .pipe(ofActionSuccessful(SetShowOriginalHeaders, SetFriendlyHeaderTitle), untilDestroyed(this))
      .subscribe(() => {
        this.refreshHoverInfoImageData();
      });

    this.displayNameField$
      .pipe(
        filter(val => !!val),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.refreshHoverInfoImageData();
      });

    this.filteredRows$
      .pipe(
        filter(val => !!val),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.refreshHoverInfoImageData();
      });
  }

  private refreshHoverInfoImageData() {
    this.lookupTable.created$.pipe(filter(Boolean), take(1)).subscribe(() => {
      const data = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
      if (data) {
        this.activeMatrix?.updateImageData(data);
      }
    });
  }

  private animate() {
    requestAnimationFrame(this.animate);

    if (
      this.viewPortInteractions ||
      (this.activeMatrix && (this.activeMatrix.userInteraction || this.activeMatrix.animation))
    ) {
      this.pixiRenderer.render(this.stage);
    }
  }

  private getZoomFactor(): number {
    return Math.round((this.viewport.scale.x / this.zoom.baseScale + Number.EPSILON) * 100) / 100;
  }

  private updateMinZoomFactor() {
    const w =
      Math.round((this.viewport.screenWidth / this.viewport.worldWidth / this.zoom.baseScale + Number.EPSILON) * 100) /
      100;
    const h =
      Math.round(
        (this.viewport.screenHeight / this.viewport.worldHeight / this.zoom.baseScale + Number.EPSILON) * 100
      ) / 100;
    this.zoom.minZoomFactor = w > h ? w : h;
  }

  private updateViewportOnCompass(update = true) {
    const { left, top, width, height } = this.viewport.getVisibleBounds();
    const factor = this.zoom.zoomFactor > 0.5 && this.zoom.baseScale > 1 ? 0.5 : 1;
    const resolution = !this.zoom.resolution || this.zoom.resolution < 1 ? 1 : this.zoom.resolution;

    this.setViewport({
      workspaceId: this.workspaceId,
      x: (left * factor) / resolution,
      y: (top * factor) / resolution,
      width: (width * factor) / resolution,
      height: (height * factor) / resolution,
      update
    });
  }

  private drowDotBG(type?) {
    if (this.updateBG)
      this.drowDotBgSubject.next({
        type: type,
        viewportScale: this.viewport.scale.x,
        baseScale: this.zoom.baseScale,
        topLeft: this.viewport.toScreen(0, 0)
      });
  }

  private onRenderNewMatrix(matrixType, restore) {
    switch (matrixType) {
      case ValidMatrix.Freestyle: {
        this.attachAndCenterManualHarvestMatrix(restore);
        break;
      }
      case ValidMatrix.Rank: {
        this.attachAndCenterRankMatrix(restore);
        break;
      }
      case ValidMatrix.GroupBy: {
        this.attachAndCenterGroupByMatrix(restore);
        break;
      }
      case ValidMatrix.Pareto: {
        this.attachAndCenterParetoMatrix(restore);
        break;
      }
    }
  }

  private onResizeImageMatrix(instantResizeMatrix) {
    if (!instantResizeMatrix) {
      this.resizeImages = true;
    } else {
      this.updateResolution();
    }
  }

  private attachAndCenterRankMatrix(restore: boolean) {
    if (this.activeMatrix.getMatrixType() !== ValidMatrix.Rank) return;
    this.attachAndCenterBase(restore);
    this.activeMatrix.zoomFactor = parseFloat(this.zoom.zoomFactor.toFixed(2));
    this.activeMatrix.updateBaseSizes(this.zoom.baseScale);
    this.updateMinZoomFactor();
    this.updateViewportOnCompass(false);
  }

  private attachAndCenterManualHarvestMatrix(restore: boolean) {
    if (this.activeMatrix.getMatrixType() !== ValidMatrix.Freestyle) return;
    this.attachAndCenterBase(restore);
    this.activeMatrix.zoomFactor = parseFloat(this.zoom.zoomFactor.toFixed(2));
    this.pixiRenderer.render(this.stage);
    this.updateMinZoomFactor();
    this.activeMatrix.updateBaseSizes(this.zoom.baseScale);
    this.updateViewportOnCompass(false);
  }

  private attachAndCenterGroupByMatrix(restore: boolean) {
    if (this.activeMatrix.getMatrixType() !== ValidMatrix.GroupBy) return;
    this.attachAndCenterBase(restore);
    this.activeMatrix.zoomFactor = parseFloat(this.zoom.zoomFactor.toFixed(2));
    this.activeMatrix.updateBaseSizes(this.zoom.baseScale);
    this.updateMinZoomFactor();
    this.updateViewportOnCompass(false);
  }

  private attachAndCenterParetoMatrix(restore: boolean) {
    if (this.activeMatrix.getMatrixType() !== ValidMatrix.Pareto) return;
    const multW =
      this.activeMatrix.imageMatrix.width > 0
        ? (this.activeMatrix.matrix.width / this.activeMatrix.imageMatrix.width) * 0.7
        : 3;
    const multH =
      this.activeMatrix.imageMatrix.height > 0
        ? (this.activeMatrix.matrix.height / this.activeMatrix.imageMatrix.height) * 0.7
        : 3;
    this.attachAndCenterBase(restore, Math.min(multW, multH));
    this.activeMatrix.zoomFactor = parseFloat(this.zoom.zoomFactor.toFixed(2));
    this.activeMatrix.updateBaseSizes(this.zoom.baseScale);
    this.updateMinZoomFactor();
    this.updateViewportOnCompass(false);
  }

  private attachAndCenterBase(restore: boolean, scaleMultiplayer = 3) {
    this.zoom.resolution = 1;
    this.updateBG = false;
    const matrix = this.getMatrix();
    this.viewport.removeChild(matrix);
    const matrixBounds = matrix.getBounds();

    this.viewport.worldWidth = matrixBounds.width > 0 ? matrixBounds.width : window.innerWidth;
    this.viewport.worldHeight = matrixBounds.height > 0 ? matrixBounds.height : window.innerHeight;
    this.viewport.clampZoom({
      maxWidth: this.viewport.worldWidth,
      maxHeight: this.viewport.worldHeight
    });
    matrix.position.set(0, 0);
    this.viewport.fitWorld(true);

    const restoredMatrixState: IMatrixStateModel = restore
      ? this.store.selectSnapshot(StudyState.getMatrixStateModel)
      : null;

    if (
      restoredMatrixState?.tools?.windowDimensions &&
      (restoredMatrixState.tools.windowDimensions.width !== this.window.innerWidth ||
        restoredMatrixState.tools.windowDimensions.height !== this.window.innerHeight)
    ) {
      // need restore the same area of canvas, but for current Window Dimensions
      // each matrix know how to do this by self
      const oldState = restoredMatrixState.tools;
      this.restoreViewport(this.viewport, this.zoom, oldState);
    } else {
      const scale =
        restoredMatrixState?.tools.viewportScale ||
        (matrixBounds.width > 0 ? this.viewport.scale.x * scaleMultiplayer : this.viewport.scale.x);
      const center = restoredMatrixState?.tools.viewportCenter || {
        x: this.viewport.worldWidth / 2,
        y: this.viewport.worldHeight / 2
      };
      this.zoom.baseScale = restoredMatrixState ? this.viewport.scale.x : scale;
      this.viewport.scale.set(Math.abs(scale));
      this.viewport.moveCenter(center.x, center.y);
    }

    this.viewPortInteractions = false;
    this.viewport.addChild(matrix);

    this.updateBG = true;
    this.drowDotBG();
    this.zoom.zoomFactor = this.getZoomFactor();
  }

  private recenterMatrix(newCenter: IPosition) {
    this.viewport.removeChild(this.activeMatrix.matrix);
    const viewportScale = this.viewport.scale.x;

    const matrixBounds = this.activeMatrix.matrix.getBounds();

    this.viewport.worldWidth = matrixBounds.width > 0 ? matrixBounds.width : window.innerWidth;
    this.viewport.worldHeight = matrixBounds.height > 0 ? matrixBounds.height : window.innerHeight;

    this.viewport.clampZoom({
      maxWidth: this.viewport.worldWidth,
      maxHeight: this.viewport.worldHeight
    });

    this.zoom.baseScale = (this.viewport.screenWidth / this.viewport.worldWidth) * 3;
    this.updateMinZoomFactor();

    this.activeMatrix.matrix.position.set(0, 0);

    this.viewport.scale.set(viewportScale);
    this.viewport.moveCenter(newCenter.x, newCenter.y);
    this.viewport.addChild(this.activeMatrix.matrix);

    this.zoom.zoomFactor = this.getZoomFactor();

    this.pixiRenderer.render(this.stage);
  }

  private resizeImageMatrix() {
    this.updateBG = false;
    this.savedCenter = this.viewport.center;
    this.savedScale = this.viewport.scale.x;

    this.viewport.worldWidth *= this.resizeFactor;
    this.viewport.worldHeight *= this.resizeFactor;
    this.viewport.clampZoom({
      maxWidth: this.viewport.worldWidth,
      maxHeight: this.viewport.worldHeight
    });

    this.zoom.baseScale = (this.viewport.screenWidth / this.viewport.worldWidth) * 3;
    this.updateMinZoomFactor();

    this.viewport.scale.set(this.savedScale / this.resizeFactor);
    this.viewport.moveCenter(this.resizeFactor * this.savedCenter.x, this.resizeFactor * this.savedCenter.y);

    this.savedCenter = this.viewport.center;

    this.zoom.zoomFactor = this.getZoomFactor();
    if (this.zoom.zoomFactor >= this.zoom.minZoomFactor && this.activeMatrix?.onResolutionChange) {
      this.activeMatrix.onResolutionChange(this.zoom.zoomFactor, this.zoom.baseScale);
    }

    this.updateBG = true;
  }

  private updateResolution() {
    this.resizeImages = false;
    this.resizeFactor = this.activeMatrix?.updateResolution();
    this.zoom.resolution *= this.resizeFactor;
    this.resizeImageMatrix();
    this.activeMatrix?.updateViewportInCanvasState();
    this.drowDotBG();
    this.pixiRenderer.render(this.stage);
  }

  private onNewWorkspace(matrixType: ValidMatrix, workspaceId: string) {
    this.resourceManagerService.stopActiveLoaders();
    if (this.activeMatrix) {
      this.activeMatrix.clearMatrix(false);
      this.viewport.removeChild(this.activeMatrix.matrix);
      this.removeMatrixSubs(this.activeMatrix);
    }
    const newMatrix = this.createMatrix(workspaceId, matrixType);
    this.matrices.push(newMatrix);
    this.addMatrixSubs(newMatrix);
    this.activeMatrix = newMatrix;
    this.activeMatrix.setActiveState(true);
    this.matrixType = matrixType;
    this.activeMatrix.setSelectedTool(this.store.selectSnapshot(ToolsState.getSelectedTool));
    this.activeMatrix.setDefaultView(this.store.selectSnapshot(CollageState.getDefaultView));
    this.pixiRenderer.render(this.stage);
  }

  private onWorkspaceSwitch(matrixType: ValidMatrix, workspaceId: string) {
    this.resourceManagerService.stopActiveLoaders();
    if (this.activeMatrix) {
      this.activeMatrix.clearMatrix(false);
      this.activeMatrix.setActiveState(false);
      this.viewport.removeChild(this.activeMatrix.matrix);
      this.removeMatrixSubs(this.activeMatrix);
    }
    const newMatrix = this.matrices.find(matrix => matrix.getId() === workspaceId);
    newMatrix.setMatrixType(matrixType);
    this.addMatrixSubs(newMatrix);
    this.activeMatrix = newMatrix;
    this.activeMatrix.setActiveState(true);
    this.matrixType = matrixType;
    this.activeMatrix.setSelectedTool(this.store.selectSnapshot(ToolsState.getSelectedTool));
    this.activeMatrix.setDefaultView(this.store.selectSnapshot(CollageState.getDefaultView));
    this.pixiRenderer.render(this.stage);
  }

  private onWorkspaceTypeChange(matrixType: ValidMatrix, workspaceId: string) {
    if (this.activeMatrix.getId() !== workspaceId) return;
    this.resourceManagerService.stopActiveLoaders();
    this.activeMatrix.clearMatrix();
    this.viewport.removeChild(this.activeMatrix.matrix);
    this.removeMatrixSubs(this.activeMatrix);
    const index = this.matrices.findIndex(matrix => matrix.getId() === workspaceId);
    if (index > -1) this.matrices.splice(index, 1);
    this.activeMatrix.setActiveState(false);
    delete this.activeMatrix;
    this.activeMatrix = null;
    const newMatrix = this.createMatrix(workspaceId, matrixType);
    this.matrices.push(newMatrix);
    this.addMatrixSubs(newMatrix);
    this.activeMatrix = newMatrix;
    this.activeMatrix.setActiveState(true);
    this.matrixType = matrixType;
    this.activeMatrix.setSelectedTool(this.store.selectSnapshot(ToolsState.getSelectedTool));
    this.activeMatrix.setDefaultView(this.store.selectSnapshot(CollageState.getDefaultView));
    this.pixiRenderer.render(this.stage);
  }

  private onWorkspaceDelete(id) {
    const index = this.matrices.findIndex(matrix => matrix.getId() === id);
    if (index > -1) {
      this.removeIsUpdatingSub(this.matrices[index]);
      delete this.matrices[index];
      this.matrices[index] = null;
      this.matrices.splice(index, 1);
    }
  }

  private onStudyOpen(workspaces: IWorkspace[]) {
    this.resourceManagerService.stopActiveLoaders();
    if (this.activeMatrix) {
      this.activeMatrix.clearMatrix();
      this.viewport.removeChild(this.activeMatrix.matrix);
      this.removeMatrixSubs(this.activeMatrix);
    }
    this.matrices = [];
    this.updatingWorkspaces = {};
    this.matrixIsUpdatingSubs = {};

    workspaces.forEach(workspace => {
      const newMatrix = this.createMatrix(workspace.id, workspace.matrixType);
      if (newMatrix) {
        newMatrix.setSelectedTool(this.store.selectSnapshot(ToolsState.getSelectedTool));
        newMatrix.setDefaultView(this.store.selectSnapshot(CollageState.getDefaultView));
        this.aggregateDataOnStudyOpen(newMatrix, workspace);
        this.matrices.push(newMatrix);
      }
    });
    const activeWorkspaceId = this.store.selectSnapshot(StudyState.getActiveWorkspaceId);

    const index = this.matrices.findIndex(matrix => matrix.getId() === activeWorkspaceId);
    if (index > -1) {
      this.addMatrixSubs(this.matrices[index]);
      this.activeMatrix = this.matrices[index];
      this.activeMatrix.setActiveState(true);
      this.matrixType = this.matrices[index].getMatrixType();
    }
    this.pixiRenderer.render(this.stage);
  }

  private setDisplayDataOnStudyOpen(m: BaseMatrix, workspace: IWorkspace, data) {
    const matrixState: IMatrixStateModel = (({ id, frames, groups, matrix, imageMatrix, tools }) => ({
      id,
      frames,
      groups,
      matrix,
      imageMatrix,
      tools
    }))(workspace);
    m.setMatrixState(matrixState);
    const matrixTools = workspace.tools;
    m.setSelectedView(matrixTools.selectedView);

    if (workspace.matrixType === ValidMatrix.Rank) {
      m.setNumEntries(matrixTools.n);
      m.setGroupOrientation(matrixTools.groupOrientation);
      m.setInfoPanelConfig(matrixTools.rankInfoPanelConfig);
      m.setRankDirection(matrixTools.rankDirection);
      m.setRankAbsolutePlotting(matrixTools.rankAbsolutePlotting);
    }
    if (workspace.matrixType === ValidMatrix.Pareto) {
      m.setGroupOrientation(matrixTools.groupOrientation);
    }
    m?.setDisplayData(data, true);
  }

  private aggregateDataOnStudyOpen(matrix: BaseMatrix, workspace: IWorkspace): any {
    const axesCopy = clone(workspace.axesShelf);
    switch (workspace.matrixType) {
      case ValidMatrix.Rank: {
        let filters: any[] = [];
        if (axesCopy?.x.length > 0) {
          filters.push(axesCopy.x[0]);
        }
        if (axesCopy?.y.length > 0) {
          axesCopy.y.forEach(item => filters.push(item));
        }
        this.dataService.apiAggregateRank(filters, axesCopy.filtering).subscribe(result => {
          this.setDisplayDataOnStudyOpen(matrix, workspace, result.data);
        });
        break;
      }
      case ValidMatrix.Freestyle: {
        let filters: any[];
        if (axesCopy?.x.length > 0) {
          filters = [...axesCopy.x];
        } else {
          filters = axesCopy ? axesCopy.y : [];
        }
        this.dataService.apiAggregateByFilters(filters).subscribe(result => {
          this.setDisplayDataOnStudyOpen(matrix, workspace, result.data.imageData);
        });
        break;
      }
      case ValidMatrix.GroupBy: {
        this.dataService.apiAggregateGroupBy(axesCopy ? axesCopy.y : [], axesCopy.filtering).subscribe(result => {
          this.setDisplayDataOnStudyOpen(matrix, workspace, result.data);
        });
        break;
      }
      case ValidMatrix.Pareto: {
        let filters: any[] = [];
        if (axesCopy?.x.length > 0) {
          filters.push(axesCopy.x[0]);
        }
        if (axesCopy?.y.length > 0) {
          axesCopy.y.forEach(item => filters.push(item));
        }
        this.dataService.apiAggregatePareto(filters, axesCopy.filtering).subscribe(result => {
          this.setDisplayDataOnStudyOpen(matrix, workspace, result.data);
        });
        break;
      }
    }
  }

  public createMatrix(id, matrixType) {
    let matrix: BaseMatrix;

    switch (matrixType) {
      case ValidMatrix.Freestyle:
        matrix = new FreestyleMatrix(
          this.window,
          this.stage,
          this.pixiRenderer,
          this.viewport,
          this.imageInfoOverlayRef,
          this.store,
          this.snackBar,
          this.resourceManagerService,
          this.dataService,
          this.lookupTable,
          this.appLoaderService,
          this.dropShadowService,
          this.friendlyNameService,
          this.filterService,
          this.viewContainerRef
        );
        break;
      case ValidMatrix.Rank:
        matrix = new RankMatrix(
          this.window,
          this.stage,
          this.pixiRenderer,
          this.viewport,
          this.imageInfoOverlayRef,
          this.store,
          this.snackBar,
          this.resourceManagerService,
          this.dataService,
          this.lookupTable,
          this.appLoaderService,
          this.dropShadowService,
          this.friendlyNameService,
          this.filterService,
          this.viewContainerRef,
          this.factoryResolver
        );
        break;
      case ValidMatrix.GroupBy:
        matrix = new GroupByMatrix(
          this.window,
          this.stage,
          this.pixiRenderer,
          this.viewport,
          this.imageInfoOverlayRef,
          this.store,
          this.snackBar,
          this.resourceManagerService,
          this.dataService,
          this.lookupTable,
          this.appLoaderService,
          this.dropShadowService,
          this.friendlyNameService,
          this.filterService,
          this.viewContainerRef
        );
        break;
      case ValidMatrix.Pareto:
        matrix = new ParetoMatrix(
          this.window,
          this.stage,
          this.pixiRenderer,
          this.viewport,
          this.imageInfoOverlayRef,
          this.store,
          this.snackBar,
          this.resourceManagerService,
          this.dataService,
          this.lookupTable,
          this.appLoaderService,
          this.dropShadowService,
          this.friendlyNameService,
          this.filterService,
          this.viewContainerRef,
          this.factoryResolver
        );
        break;
    }

    if (!matrix) return null;

    matrix.setId(id);
    matrix.setMatrixType(matrixType);
    this.addIsUpdatingSub(matrix);
    return matrix;
  }

  private addMatrixSubs(matrix: BaseMatrix) {
    if (!matrix) return;
    const id = matrix.getId();
    this.matrixSubs[id] = [];
    this.matrixSubs[id].push(
      matrix.renderNewMatrix.subscribe(payload => {
        this.onRenderNewMatrix(matrix.getMatrixType(), payload.restore);
      }),
      matrix.resizeImageMatrix.subscribe(payload => {
        this.onResizeImageMatrix(payload.instantResizeMatrix);
      }),
      matrix.clearCompass.subscribe((payload: boolean) => {
        this.onClearCompass(payload);
      }),
      matrix.contextMenuOpened$.subscribe((payload: IContextMenuOpenCloseSubject) => {
        this.contextMenuOpenCloseSubject.next({ open: true, items: payload.items, image: payload.image });
      }),
      matrix.contextMenuClosed$.subscribe(() => {
        this.contextMenuOpenCloseSubject.next({ open: false });
      }),
      matrix.imagePopperMenu.subscribe((payload: IImagePopperMenuSubject) => {
        this.imagePopperMenuSubject.next(payload);
      }),
      matrix.productsToShow$.subscribe((payload: Set<string>) => {
        this.productsToShowSubject.next(payload);
      }),
      matrix.highlightedProducts$.subscribe((payload: Set<string>) => {
        this.highlightedProductsSubject.next(payload);
      }),
      matrix.selectedProducts$.subscribe((payload: Set<string>) => {
        this.selectedProductsSubject.next(payload);
      })
    );

    if (matrix.getMatrixType() === ValidMatrix.Pareto) {
      this.matrixSubs[id].push(
        (matrix as ParetoMatrix).recenterMatrix.subscribe((payload: IPosition) => this.recenterMatrix(payload)),
        (matrix as ParetoMatrix).paretoAditionalDataSubject.subscribe((payload: IParetoAdditionalDataModel) =>
          this.paretoAditionalDataSubject.next(payload)
        ),
        (matrix as ParetoMatrix).paretoDislpayDataSubject.subscribe((payload: Array<IParetoGroupModel>) =>
          this.paretoDislpayDataSubject.next(payload)
        ),
        (matrix as ParetoMatrix).paretoColumnSizeSubject.subscribe((payload: IParetoColumnSizeSubject) =>
          this.paretoColumnSizeSubject.next(payload)
        ),
        (matrix as ParetoMatrix).paretoImageMatrixBoundsSubject.subscribe((payload: IParetoImageMatrixBoundsModel) =>
          this.paretoImageMatrixBoundsSubject.next(payload)
        )
      );
    }
    if (matrix.getMatrixType() === ValidMatrix.Rank) {
      this.matrixSubs[id].push(
        (matrix as RankMatrix).recenterMatrix.subscribe((payload: IPosition) => this.recenterMatrix(payload)),
        (matrix as RankMatrix).absolutePlottingPointsSubject.subscribe((payload: number[]) =>
          this.rankAbsolutePlottingPointsSubject.next(payload)
        ),
        (matrix as RankMatrix).absolutePlottingOrientationSubject.subscribe((payload: boolean) =>
          this.rankAbsolutePlottingOrientationSubject.next(payload)
        ),
        (matrix as RankMatrix).absolutePlottingSizeSubject.subscribe((payload: IAbsolutePlottingSizeSubject) =>
          this.rankAbsolutePlottingSizeSubject.next(payload)
        ),
        (matrix as RankMatrix).absolutePlottingPositionSubject.subscribe((payload: IAbsolutePlottingPositionSubject) =>
          this.rankAbsolutePlottingPositionSubject.next(payload)
        ),
        (matrix as RankMatrix).absolutePlottingImageHover.subscribe((payload: IAbsolutePlottingImageHover) =>
          this.rankAbsolutePlottingImageHover.next(payload)
        )
      );
    }
  }

  private removeMatrixSubs(matrix: BaseMatrix) {
    if (!matrix) return;
    this.matrixSubs[matrix.getId()]?.forEach(sub => {
      sub.unsubscribe();
    });
    this.matrixSubs[matrix.getId()] = [];
  }

  private addIsUpdatingSub(matrix: BaseMatrix) {
    if (!matrix) return;
    this.matrixIsUpdatingSubs[matrix.getId()] = matrix.isUpdating.subscribe(payload => {
      this.updatingWorkspaces[matrix.getId()] = payload;
      this.isUpdatingWorkspacesSubject.next(Object.values(this.updatingWorkspaces).some(v => v));
    });
  }

  private removeIsUpdatingSub(matrix: BaseMatrix) {
    if (!matrix) return;
    this.matrixIsUpdatingSubs[matrix.getId()].unsubscribe();
    delete this.updatingWorkspaces[matrix.getId()];
    this.isUpdatingWorkspacesSubject.next(Object.values(this.updatingWorkspaces).some(v => v));
  }

  public getStateForPresentationMode() {
    return this.activeMatrix?.getStateForPresentationMode();
  }

  public onDeleteKeyPress(event: KeyboardEvent) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Freestyle) this.activeMatrix.onDeleteKeyPress(event);
  }

  public getActiveMatrix() {
    return this.activeMatrix;
  }

  public setZoomFactor(zoomFactor) {
    this.activeMatrix.zoomFactor = zoomFactor;
  }
  public updateBaseSizes(baseSize) {
    this.activeMatrix.updateBaseSizes(baseSize);
  }

  public onZoomChange(zoomFactor: number, needMove: boolean = false, instantResizeMatrix: boolean = false) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) {
      this.activeMatrix.onZoomChange(this.zoom.zoomFactor, true);
    } else {
      this.activeMatrix?.onZoomChange(this.zoom.zoomFactor);
    }
  }

  public onZoomEnd() {
    this.activeMatrix.onZoomEnd();
  }

  public onViewportMove() {
    this.activeMatrix.onViewportMove();
  }

  public onZoomAndMoveEnd() {
    this.activeMatrix.onZoomAndMoveEnd();
  }

  public getMatrix() {
    return this.activeMatrix.matrix;
  }

  public getMatrixIds() {
    return this.matrices.map(matrix => matrix.getId());
  }

  public restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools) {
    this.activeMatrix.restoreViewport(viewPort, zoom, oldState);
  }

  public onWindowWheel(event: WheelEvent) {
    if (this.canvas === event.target) {
      event.preventDefault();
    }
  }
  public onWindowResize(event) {
    this.pixiRenderer.resize(window.innerWidth, window.innerHeight);
    this.viewport.screenWidth = window.innerWidth;
    this.viewport.screenHeight = window.innerHeight;
    const largerScale = this.viewport.findCover(this.viewport.worldWidth, this.viewport.worldHeight);
    if (this.viewport.scale.x < largerScale) this.viewport.scale.set(largerScale);
    this.pixiRenderer.render(this.stage);
    this.updateMinZoomFactor();
  }

  public onRankAbsolutePointMouseOver(point: number) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).onAbsolutePointMouseOver(point);
  }

  public onRankAbsolutePointMouseOut() {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).onAbsolutePointMouseOut();
  }

  public onRankAbsolutePlottingLabelResize(event) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).onAbsolutePlottingLabelResize(event);
  }

  public onRankAbsolutePlottingLabelResizeEnd() {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).onAbsolutePlottingLabelResizeEnd();
  }

  public getDistanceTillRankAbsPlottingImage(point, pos) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).getDistanceTillImage(point, pos);
  }

  public storeAllRankGroupsInfoLockedStateAndHide() {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).storeAllGroupsInfoLockedStateAndHide();
  }

  public restoreAllRankGroupsInfoLockedState() {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank)
      (this.activeMatrix as RankMatrix).restoreAllGroupsInfoLockedState();
  }

  public getRankGroups() {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.Rank) return (this.activeMatrix as RankMatrix).getGroups();

    return [];
  }

  public setGroupByIsCreatingGroups(isCreatingGroups: boolean) {
    if (this.activeMatrix?.getMatrixType() === ValidMatrix.GroupBy)
      (this.activeMatrix as GroupByMatrix).isCreatingGroups = isCreatingGroups;
  }

  public getImagePosInWorld(image: ImageContainer) {
    return this.activeMatrix.getImagePosInWorld(image);
  }

  public onHarvestModeInit() {
    this.activeMatrix.onHarvestModeInit();
  }

  public onHarvestModeClose() {
    this.activeMatrix.onHarvestModeClose();
  }

  public getReferencesForHarvestService() {
    return {
      stage: this.stage,
      renderer: this.pixiRenderer,
      viewport: this.viewport
    };
  }

  public getWorkspaceDataForWorkspaceSelector(): ICompassWorkspaceModel[] {
    const workspaceModels = [];
    this.matrices.forEach(matrix => {
      const model = {
        id: matrix.getId(),
        matrixType: matrix.getMatrixType(),
        img: this.getMatrixSnapshot(matrix)
      };
      workspaceModels.push(model);
    });
    return workspaceModels;
  }

  private getMatrixSnapshot(matrixClass: BaseMatrix): HTMLImageElement | void {
    const { matrix, imageMatrix } = matrixClass;
    switch (matrixClass.getMatrixType()) {
      case ValidMatrix.Freestyle: {
        if (matrix.width <= 0) return null;
        const parent = matrix.parent;
        if (parent) parent.removeChild(matrix);
        const scale = this.window.innerWidth / matrix.width;
        const frame = this.getFreestyleBoundsForSnapshot(matrixClass as FreestyleMatrix, scale);
        matrix.scale.set(scale);
        const texture = this.pixiRenderer.generateTexture(matrix, { region: frame });
        const img = this.pixiRenderer.plugins.extract.image(texture);
        texture.destroy();
        matrix.scale.set(1);
        if (parent) parent.addChild(matrix);
        return img;
        break;
      }
      case ValidMatrix.Rank:
      case ValidMatrix.GroupBy:
      case ValidMatrix.Pareto: {
        if (matrix.width <= 0 || imageMatrix.width <= 0 || imageMatrix.children.length === 0) return null;
        matrix.removeChild(imageMatrix);
        const scale = this.window.innerWidth / imageMatrix.width;
        imageMatrix.scale.set(scale);
        const frame = imageMatrix.getBounds();
        const texture = this.pixiRenderer.generateTexture(imageMatrix, { region: frame });
        const img = this.pixiRenderer.plugins.extract.image(texture);
        texture.destroy();
        imageMatrix.scale.set(1);
        matrix.addChild(imageMatrix);
        return img;
      }
    }
  }

  private getFreestyleBoundsForSnapshot(matrix: FreestyleMatrix, scale): Rectangle {
    const containerBounds = [...matrix.getAllFrameBoundsInWorld(), ...matrix.getAllImageBoundsInWorld()];
    let minX = matrix.matrix.width;
    let minY = matrix.matrix.height;
    let maxX = 0;
    let maxY = 0;

    for (const bounds of containerBounds) {
      if (minX > bounds.x) {
        minX = bounds.x;
      }
      if (minY > bounds.y) {
        minY = bounds.y;
      }
      if (maxX < bounds.x + bounds.width) {
        maxX = bounds.x + bounds.width;
      }
      if (maxY < bounds.y + bounds.height) {
        maxY = bounds.y + bounds.height;
      }
    }
    // This is necessary to accomodate additional space for frame dropshadows in case they end up on the edge
    minX -= 300;
    minY -= 300;
    maxX += 300;
    maxY += 300;
    return new Rectangle(minX * scale, minY * scale, (maxX - minX) * scale, (maxY - minY) * scale);
  }

  private onClearCompass(clear: boolean) {
    this.clearCompass$.next(clear);
  }

  public setViewport(data) {
    const { workspaceId, x, y, width, height, update, fromCompass } = data;
    this._viewport$.next({
      workspaceId,
      x: Math.trunc(x),
      y: Math.trunc(y),
      width: Math.trunc(width),
      height: Math.trunc(height),
      update,
      fromCompass
    });
  }
}
