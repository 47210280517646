import { Injectable } from '@angular/core';
import { LookupTableService } from '@app/components/lookup-table';
import {
  IFramePresentationState,
  IGroupPresentationState,
  IImageInfoPanelPresentationState,
  IImagePresentationState,
  IParetoDataState,
  IPresentationState,
  IRankDataState
} from '@app/models/presentation.models';
import { DockingSide, GroupOrientation, InfoPanelPosition } from '@app/models/study-setting.model';
import { IDimensions, IPosition } from '@app/models/workspace-list.model';
import PptxGenJS from 'pptxgenjs';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {
  private readonly defaultSlideDimensions: IDimensions = {
    width: 1280,
    height: 720
  };
  private readonly textScale = 0.56;

  private slideDimensions: IDimensions;
  private scale: number;
  private offset: IPosition;
  private presentation: PptxGenJS;
  private mainSlide: PptxGenJS.Slide;
  private matrixDimensions: IDimensions;
  private matrixPaddings: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  private groupQuicStatsSizeByLevel: Array<number> = [];

  constructor(private lookupTable: LookupTableService) {}

  public exportMatrixAsPptx(
    state: IPresentationState,
    matrixDimensions: IDimensions,
    offset: IPosition,
    matrixPaddings: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    },
    groupQuicStatsSizeByLevel: Array<number>
  ) {
    this.slideDimensions = {
      width: this.defaultSlideDimensions.width - matrixPaddings.left - matrixPaddings.right,
      height: this.defaultSlideDimensions.height - matrixPaddings.top - matrixPaddings.bottom
    };
    this.matrixDimensions = matrixDimensions;
    this.matrixPaddings = matrixPaddings;
    this.groupQuicStatsSizeByLevel = groupQuicStatsSizeByLevel;
    this.setScale(this.slideDimensions, matrixDimensions);
    this.offset = {
      x:
        offset.x -
        (this.slideDimensions.width / this.scale - matrixDimensions.width) / 2 -
        matrixPaddings.left / this.scale,
      y:
        offset.y -
        (this.slideDimensions.height / this.scale - matrixDimensions.height) / 2 -
        matrixPaddings.top / this.scale
    };
    this.presentation = new PptxGenJS();
    this.mainSlide = this.presentation.addSlide();
    this.createFrames(state.frames);
    this.createGroups(state.groupsByLevel, state.rankData);
    this.createImages(state.images, state.infoPanel);
    if (state.paretoData) {
      this.createParetoSubtotals(state.paretoData);
    }
    this.presentation.writeFile();
  }

  private setScale<T extends { width: number; height: number }>(parent: T, child: T): void {
    const scaleX = parent.width / child.width;
    const scaleY = parent.height / child.height;
    this.scale = Math.min(scaleX, scaleY);
  }

  private createGroups(groupsByLevel: Array<Array<IGroupPresentationState>>, rankData: IRankDataState): void {
    for (const groups of groupsByLevel) {
      for (const group of groups) {
        this.createGroupLabel(group, rankData);
      }
    }
  }

  private createGroupLabel(group: IGroupPresentationState, rankData: IRankDataState): void {
    const { position, dimensions } = this.getGroupLabelPositionAndDimensionsInPercents(group, rankData);
    let { x, y } = position;
    this.mainSlide.addText(group.label.value, {
      w: `${dimensions.width}%`,
      h: `${dimensions.height}%`,
      x: `${x}%`,
      y: `${y}%`,
      rotate: rankData.labelOrientation === GroupOrientation.Horizontal ? 0 : 90,
      fontSize: Math.max(group.fontSize * this.textScale, 1),
      fontFace: 'Roboto',
      fit: 'shrink',
      align: 'center',
      valign: 'middle',
      isTextBox: true,
      breakLine: false,
      wrap: false,
      shape: 'roundRect',
      fill: {
        color: 'FFFFFF'
      },
      line: {
        width: 1,
        dashType: 'solid',
        color: '33323D'
      },
      inset: 0
    });
    if (group.quickStats) this.createGroupQuickStats(group, rankData);
  }

  private getGroupLabelPositionAndDimensionsInPercents(
    group: IGroupPresentationState,
    rankData: IRankDataState
  ): { position: IPosition; dimensions: IDimensions } {
    const { position, dimensions } = this.getDimensionsAndPositionInPercents(group);
    return {
      position: {
        x:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? position.x
            : this.getGroupLabelXInPercentForVertical(group, rankData),
        y:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? this.pxToPercents(this.getGroupLabelYForHorizontal(group, rankData), this.defaultSlideDimensions.height)
            : position.y - (this.pxToPercents(30, this.defaultSlideDimensions.height) - dimensions.height) / 2
      },
      dimensions: {
        width:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? dimensions.width
            : this.pxToPercents(group.dimensions.height * this.scale, this.defaultSlideDimensions.width),
        height: this.pxToPercents(30, this.defaultSlideDimensions.height)
      }
    };
  }

  private getGroupLabelXInPercentForVertical(group: IGroupPresentationState, rankData: IRankDataState): number {
    return this.pxToPercents(
      this.getGroupLabelXForVertical(group, rankData) - (group.dimensions.height * this.scale - 30) / 2,
      this.defaultSlideDimensions.width
    );
  }

  private getGroupLabelXForVertical(group: IGroupPresentationState, rankData: IRankDataState): number {
    const levelOnMatrix = rankData.maxLevel - group.level;
    const quickStatsPadding = this.groupQuicStatsSizeByLevel
      .slice(group.level + 1, rankData.maxLevel)
      .reduce((acc, curr) => acc + curr, 0);
    return rankData.dockingSide === DockingSide.Left
      ? (group.position.x - this.offset.x) * this.scale - 35 * levelOnMatrix - quickStatsPadding
      : (group.position.x + group.dimensions.width - this.offset.x) * this.scale +
          30 * (levelOnMatrix - 1) +
          5 * levelOnMatrix +
          quickStatsPadding;
  }

  private getGroupLabelYForHorizontal(group: IGroupPresentationState, rankData: IRankDataState): number {
    const levelOnMatrix = rankData.maxLevel - group.level;
    const quickStatsPadding = this.groupQuicStatsSizeByLevel
      .slice(group.level + 1, rankData.maxLevel)
      .reduce((acc, curr) => acc + curr, 0);
    return rankData.dockingSide === DockingSide.Top
      ? (group.position.y - this.offset.y) * this.scale - 35 * levelOnMatrix - quickStatsPadding
      : (group.position.y + group.dimensions.height - this.offset.y) * this.scale +
          30 * (levelOnMatrix - 1) +
          5 * levelOnMatrix +
          quickStatsPadding;
  }

  private createGroupQuickStats(group: IGroupPresentationState, rankData: IRankDataState): void {
    const { position, dimensions } = this.getGroupQuickStatsPositionAndDimensions(group, rankData);
    const textOptions = {
      fontSize: Math.max(group.quickStats.fontSize * this.textScale, 1),
      fontFace: 'Roboto',
      isTextBox: true,
      breakLine: true
    };
    const textLines = [];
    if (group.quickStats.info === null) {
      textLines.push({
        text: 'Calculating...',
        options: {
          ...textOptions,
          bold: false
        }
      });
    } else if (group.quickStats.info.length === 0) {
      textLines.push({
        text: 'Add Statistics',
        options: {
          ...textOptions,
          bold: false
        }
      });
    } else {
      group.quickStats.info.forEach(info => {
        textLines.push({
          text: info.fieldName,
          options: {
            ...textOptions,
            bold: false
          }
        });
        info.fieldValues.forEach(val => {
          textLines.push({
            text: val,
            options: {
              ...textOptions,
              bold: true
            }
          });
        });
      });
    }
    this.mainSlide.addText(textLines, {
      w: `${dimensions.width}%`,
      h: `${dimensions.height}%`,
      x: `${position.x}%`,
      y: `${position.y}%`,
      wrap: false,
      align: 'center',
      valign: 'middle',
      shape: 'roundRect',
      fill: {
        color: 'FFFFFF'
      },
      line: {
        width: 1,
        dashType: 'solid',
        color: '33323D'
      }
    });
  }

  private getGroupQuickStatsPositionAndDimensions(
    group: IGroupPresentationState,
    rankData: IRankDataState
  ): {
    position: IPosition;
    dimensions: IDimensions;
  } {
    const { position, dimensions } = this.getDimensionsAndPositionInPercents(group);
    return {
      position: {
        x:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? position.x
            : this.getGroupQuickStatsXInPercentsForVertical(
                rankData.dockingSide,
                this.getGroupLabelXForVertical(group, rankData)
              ),
        y:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? this.getGroupQuickStatsYInPercentsForHorizontal(
                rankData.dockingSide,
                this.getGroupLabelYForHorizontal(group, rankData)
              )
            : position.y
      },
      dimensions: {
        width:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? dimensions.width
            : this.pxToPercents(60, this.defaultSlideDimensions.width),
        height:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? this.pxToPercents(60, this.defaultSlideDimensions.height)
            : dimensions.height
      }
    };
  }

  private getGroupQuickStatsXInPercentsForVertical(dockingSide: DockingSide, positionX: number): number {
    return dockingSide === DockingSide.Left
      ? this.pxToPercents(positionX - 60, this.defaultSlideDimensions.width)
      : this.pxToPercents(positionX + 30, this.defaultSlideDimensions.width);
  }

  private getGroupQuickStatsYInPercentsForHorizontal(dockingSide: DockingSide, positionY: number): number {
    return dockingSide === DockingSide.Top
      ? this.pxToPercents(positionY - 60, this.defaultSlideDimensions.height)
      : this.pxToPercents(positionY + 30, this.defaultSlideDimensions.height);
  }

  private createFrames(frames: Array<IFramePresentationState>): void {
    for (const frame of frames) {
      this.createFrame(frame);
    }
  }

  private createFrame(frame: IFramePresentationState): void {
    const { position, dimensions } = this.getDimensionsAndPositionInPercents(frame);
    this.createFrameTitle(frame.title, frame.numImages, frame.fontSize, position, dimensions);
    this.mainSlide.addShape('rect', {
      x: `${position.x}%`,
      y: `${position.y}%`,
      w: `${dimensions.width}%`,
      h: `${dimensions.height}%`,
      line: {
        dashType: 'solid',
        color: '33323D',
        size: 1
      }
    });
    this.createFrames(frame.frames);
    this.createImages(frame.images);
  }

  private createFrameTitle(
    title: {
      featureName: string;
      featureValue: string | null;
    },
    numImages: number,
    fontSize: number,
    position: IPosition,
    dimensions: IDimensions
  ): void {
    fontSize = Math.max(fontSize * this.scale * this.textScale, 1);
    this.mainSlide.addText(`${title.featureName}: ${title.featureValue || 'undefined'} | ${numImages}`, {
      x: `${position.x}%`,
      y: `${position.y - this.pxToPercents(fontSize, this.defaultSlideDimensions.height) * 1.5}%`,
      w: `${dimensions.width}%`,
      h: `${this.pxToPercents(fontSize, this.defaultSlideDimensions.height)}%`,
      color: '33323D',
      fontSize,
      fontFace: 'Roboto',
      isTextBox: true,
      wrap: false,
      fit: 'shrink',
      align: 'left',
      inset: 0
    });
  }

  private createImages(images: Array<IImagePresentationState>, infoPanel?: IImageInfoPanelPresentationState): void {
    for (const image of images) {
      this.createImage(image);
    }
    if (infoPanel) this.createInfoPanels(images, infoPanel);
  }

  private createInfoPanels(images: Array<IImagePresentationState>, infoPanel: IImageInfoPanelPresentationState) {
    if (infoPanel) {
      for (const image of images) {
        this.createInfoPanel(image, infoPanel);
      }
    }
  }

  private getInfoPanelCoordinates(
    position: InfoPanelPosition,
    imagePos: IPosition,
    infoPanelDimensions: IDimensions,
    isHalfSize: boolean
  ): IPosition {
    const coordinates = {
      x: 0,
      y: 0
    };

    switch (position) {
      case InfoPanelPosition.Left:
        coordinates.x = imagePos.x - infoPanelDimensions.width - 5;
        coordinates.y = imagePos.y;
        break;
      case InfoPanelPosition.Right:
        coordinates.x = imagePos.x + (isHalfSize ? infoPanelDimensions.width : infoPanelDimensions.width / 2) + 5;
        coordinates.y = imagePos.y;
        break;
      case InfoPanelPosition.Bottom:
        coordinates.x = imagePos.x;
        coordinates.y = imagePos.y + (isHalfSize ? infoPanelDimensions.width : infoPanelDimensions.width / 2) + 5;
        break;
    }

    return coordinates;
  }

  private createInfoPanel(image: IImagePresentationState, infoPanel: IImageInfoPanelPresentationState): void {
    const infoPanelDimensions = {
      width: infoPanel.isHalfSize ? image.dimensions.width : image.dimensions.width * 2,
      height: image.dimensions.height
    };
    const infoPanelPosition = this.getInfoPanelCoordinates(
      infoPanel.position,
      image.position,
      infoPanelDimensions,
      infoPanel.isHalfSize
    );
    const { position, dimensions } = this.getDimensionsAndPositionInPercents({
      position: infoPanelPosition,
      dimensions: infoPanelDimensions
    });
    const textOptions = (fontSize: number) => ({
      fontSize: Math.max(fontSize * this.textScale, 1),
      fontFace: 'Roboto',
      align: infoPanel.position !== InfoPanelPosition.Left ? 'left' : 'right',
      isTextBox: true,
      breakLine: true,
      wrap: false,
      bold: true
    });
    const textLines = [];
    textLines.push({
      text: image.data.displayName,
      options: textOptions(infoPanel.fontSize * 1.125)
    });
    for (const { fieldName, fieldValues } of image.data.hoverInfo.slice(0, 2)) {
      textLines.push({
        text: fieldName,
        options: {
          ...textOptions(infoPanel.fontSize),
          bold: false
        }
      });
      for (const value of fieldValues) {
        textLines.push({
          text: value,
          options: textOptions(infoPanel.fontSize * 1.125)
        });
      }
    }
    this.mainSlide.addText(textLines, {
      w: `${dimensions.width}%`,
      h: `${dimensions.height}%`,
      x: `${position.x}%`,
      y: `${position.y}%`,
      inset: 0
    });
  }

  private createImage(image: IImagePresentationState): void {
    const { position, dimensions } = this.getDimensionsAndPositionInPercents(image);
    // TODO: uncoment when export card background (whit square behind images) will be variable
    // this.mainSlide.addShape('rect', {
    //   x: `${position.x + paddings.left}%`,
    //   y: `${position.y + paddings.top}%`,
    //   w: `${dimensions.width}%`,
    //   h: `${dimensions.height}%`,
    //   fill: {
    //     color: 'FFFFFF'
    //   }
    // });
    this.mainSlide.addImage({
      path: this.lookupTable.getProductImage(image.id, image.view, 1024) || this.lookupTable.getPlaceholderUrl(),
      x: `${position.x}%`,
      y: `${position.y}%`,
      sizing: {
        type: 'contain',
        w: `${dimensions.width}%`,
        h: `${dimensions.height}%`
      }
    });
  }

  private createParetoSubtotals(paretoData: IParetoDataState): void {
    let { x, y } = this.getParetoSubtotalPosition(paretoData);
    console.log(paretoData.padding, paretoData.padding * this.scale);
    for (let i = 0; i < paretoData.groups.length; i++) {
      const group = paretoData.groups[i];
      const { width, height } = this.getParetoSubtotalDimensions(paretoData, i);
      this.mainSlide.addText(`${group.products.length} (${group.subtotal.toFixed(1)}%)`, {
        x: `${this.pxToPercents(x, this.defaultSlideDimensions.width)}%`,
        y: `${this.pxToPercents(y, this.defaultSlideDimensions.height)}%`,
        w: `${this.pxToPercents(
          !paretoData.detailsBarFixedPosition && paretoData.groupOrientation === GroupOrientation.Vertical ? width : 90,
          this.defaultSlideDimensions.width
        )}%`,
        h: `${this.pxToPercents(height, this.defaultSlideDimensions.height)}%`,
        color: '33323D',
        fontSize: paretoData.fontSize * this.textScale,
        fontFace: 'Roboto',
        isTextBox: true,
        wrap: false,
        align: 'center',
        rotate:
          !paretoData.detailsBarFixedPosition && paretoData.groupOrientation === GroupOrientation.Horizontal ? 90 : 0
      });
      if (!paretoData.detailsBarFixedPosition) {
        if (paretoData.groupOrientation === GroupOrientation.Vertical) {
          x += width + paretoData.distanceBetweenColumns * this.scale;
        } else {
          y += width + paretoData.distanceBetweenColumns * this.scale;
        }
      } else {
        y += 24;
      }
    }
  }

  private getParetoSubtotalPosition(paretoData: IParetoDataState) {
    if (paretoData.detailsBarFixedPosition) {
      return {
        x: 5 + (this.slideDimensions.width - this.matrixDimensions.width * this.scale) / 2,
        y: (this.slideDimensions.height - (19 * paretoData.groups.length + 5 * (paretoData.groups.length - 1))) / 2
      };
    }
    return paretoData.groupOrientation === GroupOrientation.Vertical
      ? {
          y:
            this.matrixDimensions.height * this.scale +
            this.matrixPaddings.top +
            (this.slideDimensions.height - this.matrixDimensions.height * this.scale) / 2 +
            17,
          x: this.matrixPaddings.left + (this.slideDimensions.width - this.matrixDimensions.width * this.scale) / 2
        }
      : {
          x: (this.slideDimensions.width - this.matrixDimensions.width * this.scale) / 2 - (90 - 19) / 2,
          y:
            this.matrixPaddings.top +
            (this.slideDimensions.height - this.matrixDimensions.height * this.scale) / 2 -
            (19 - 90)
        };
  }

  private getParetoSubtotalDimensions(paretoData: IParetoDataState, columnIndex) {
    return {
      width: !paretoData.detailsBarFixedPosition ? paretoData.columnSizes[columnIndex] * this.scale : 90,
      height: 19
    };
  }

  private getDimensionsAndPositionInPercents<T extends { position: IPosition; dimensions: IDimensions }>({
    position,
    dimensions
  }: T): { position: IPosition; dimensions: IDimensions } {
    return {
      position: {
        x: this.pxToPercents((position.x - this.offset.x) * this.scale, this.defaultSlideDimensions.width),
        y: this.pxToPercents((position.y - this.offset.y) * this.scale, this.defaultSlideDimensions.height)
      },
      dimensions: {
        width: this.pxToPercents(dimensions.width * this.scale, this.defaultSlideDimensions.width),
        height: this.pxToPercents(dimensions.height * this.scale, this.defaultSlideDimensions.height)
      }
    };
  }

  private pxToPercents(axisOrDimension: number, slideDimension: number) {
    return (axisOrDimension * 100) / slideDimension;
  }
}
