import { CursorStates, CursorStyles } from '@app/shared/enums/cursor-styles.enum';
import { Container, Graphics, InteractionEvent, Point, Polygon, Renderer, Sprite, Texture } from 'pixi.js';

export class ResizableEllipseContainer extends Container {
  private borderContainer: Container;
  private borderGraph: Graphics;
  private cornerGraphs: Array<Graphics>;

  private borderWidth = 2;

  private hover: boolean = false;
  private dragging: boolean = false;
  private mouseMoveEventRef;
  private dragOffsetFromCenterGlobal: { x: number; y: number };
  private dragStartMouseCoordinates: { x: number; y: number };
  private dragInitialDims: {
    x: number;
    y: number;
    width: number;
    height: number;
    angle: number;
    angleOffset: number;
  };
  private dims: {
    width: number;
    height: number;
  };

  private corners;
  private tempCorners;
  private tempCornersRotated;
  private mouseMoveState: CursorStates;

  private angleInRad = 0;

  private resizePad = 15;
  private rotatePad = 15;
  private innerPad = 15;

  private sinA;
  private cosA;

  private overlay: Graphics;
  private overlayContainer: Container;
  private maskContainer: Container;
  private maskSprite: Sprite;

  private renderer: Renderer;
  private stage: Container;

  private updateOverlayRef;

  private horizontalResizeCursor: HTMLImageElement;
  private cornerResizeCursor: HTMLImageElement;
  private rotateCursor: HTMLImageElement;
  private activeCursor: HTMLImageElement;

  public mouseMoveActions = {
    Drag: this.onDrag.bind(this),
    TopLeftRotate: this.onRotate.bind(this),
    TopRightRotate: this.onRotate.bind(this),
    BottomRightRotate: this.onRotate.bind(this),
    BottomLeftRotate: this.onRotate.bind(this),
    TopLeftResize: this.onTopLeftResize.bind(this),
    TopRightResize: this.onTopRightResize.bind(this),
    BottomRightResize: this.onBottomRightResize.bind(this),
    BottomLeftResize: this.onBottomLeftResize.bind(this),
    LeftResize: this.onLeftResize.bind(this),
    TopResize: this.onTopResize.bind(this),
    RightResize: this.onRightResize.bind(this),
    BottomResize: this.onBottomResize.bind(this)
  };

  constructor() {
    super();
    this.createEventListeners();
    this.updateOverlayRef = this.updateOverlay.bind(this);
  }

  public createResizableEllipse(width: number, height: number, renderer: Renderer, stage: Container) {
    this.renderer = renderer;
    this.stage = stage;
    this.angleInRad = 0;
    this.updateAngle();
    this.createBorderAndCorners(width, height);
    this.updateHitArea(width, height);
    this.creatOverlayAndMask();
    this.createCustomCursors();
    // document.addEventListener('resize', this.updateOverlayRef); //TODO not working
    this.interactive = true;
    this.interactiveChildren = true;
  }

  public destroyResizableEllipse() {
    this.borderContainer = null;
    this.borderGraph = null;
    this.cornerGraphs = null;
    this.overlayContainer.removeChildren();
    this.overlayContainer.mask = null;
    this.overlayContainer = null;
    this.overlay = null;
    this.maskContainer.removeChildren();
    this.maskContainer = null;
    this.maskSprite.texture.destroy(true);
    this.maskSprite = null;
    this.hideCustomCursors();
    this.removeCustomCursors();
    this.removeChildren();
    document.body.style.cursor = CursorStyles.Default;
    // document.removeEventListener('resize', this.updateOverlayRef);
    this.interactive = false;
    this.interactiveChildren = false;
  }

  private createEventListeners() {
    this.on('mousedown', this.onMouseDown.bind(this))
      .on('mouseup', this.onMouseUp.bind(this))
      .on('mouseupoutside', this.onMouseUp.bind(this))
      .on('mouseover', this.onMouseOver.bind(this))
      .on('mouseout', this.onMouseOut.bind(this));
    this.mouseMoveEventRef = this.onMouseMove.bind(this);
  }

  private createCustomCursors() {
    this.horizontalResizeCursor = document.createElement('img');
    this.horizontalResizeCursor.classList.add('customCursor');
    this.horizontalResizeCursor.setAttribute('src', '/assets/icons/custom-cursor-horizontal-resize.png');
    this.horizontalResizeCursor.setAttribute('alt', '');
    document.body.appendChild(this.horizontalResizeCursor);
    this.cornerResizeCursor = document.createElement('img');
    this.cornerResizeCursor.classList.add('customCursor');
    this.cornerResizeCursor.setAttribute('src', '/assets/icons/custom-cursor-corner-resize.png');
    this.cornerResizeCursor.setAttribute('alt', '');
    document.body.appendChild(this.cornerResizeCursor);
    this.rotateCursor = document.createElement('img');
    this.rotateCursor.classList.add('customCursor');
    this.rotateCursor.setAttribute('src', '/assets/icons/custom-cursor-rotate.png');
    this.rotateCursor.setAttribute('alt', '');
    document.body.appendChild(this.rotateCursor);
  }

  private removeCustomCursors() {
    document.body.removeChild(this.horizontalResizeCursor);
    document.body.removeChild(this.cornerResizeCursor);
    document.body.removeChild(this.rotateCursor);
    this.horizontalResizeCursor = null;
    this.cornerResizeCursor = null;
    this.rotateCursor = null;
    this.activeCursor = null;
  }

  private createBorderAndCorners(width: number, height: number) {
    this.borderContainer = new Container();
    this.addChild(this.borderContainer);

    this.borderGraph = new Graphics();
    this.borderContainer.addChild(this.borderGraph);

    this.corners = [
      { x: -width / 2, y: -height / 2 },
      { x: width / 2, y: -height / 2 },
      { x: width / 2, y: height / 2 },
      { x: -width / 2, y: height / 2 }
    ];

    const strokeWidth = 2 * this.borderWidth;
    const halfStrokeWidth = 1.5 * this.borderWidth;
    const strokeLength = 8 * this.borderWidth;

    const topLeftCorner = new Graphics();
    topLeftCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(-halfStrokeWidth, strokeLength - halfStrokeWidth)
      .lineTo(-halfStrokeWidth, -halfStrokeWidth)
      .lineTo(-halfStrokeWidth + strokeLength, -halfStrokeWidth);
    topLeftCorner.position.set(this.corners[0].x, this.corners[0].y);

    const topRightCorner = new Graphics();
    topRightCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(-strokeLength + halfStrokeWidth, -halfStrokeWidth)
      .lineTo(halfStrokeWidth, -halfStrokeWidth)
      .lineTo(halfStrokeWidth, strokeLength - halfStrokeWidth);
    topRightCorner.position.set(this.corners[1].x, this.corners[1].y);

    const bottomRightCorner = new Graphics();
    bottomRightCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(halfStrokeWidth, -strokeLength + halfStrokeWidth)
      .lineTo(halfStrokeWidth, halfStrokeWidth)
      .lineTo(halfStrokeWidth - strokeLength, halfStrokeWidth);
    bottomRightCorner.position.set(this.corners[2].x, this.corners[2].y);

    const bottomLeftCorner = new Graphics();
    bottomLeftCorner
      .clear()
      .lineStyle(strokeWidth, 0x33323d)
      .moveTo(-halfStrokeWidth + strokeLength, halfStrokeWidth)
      .lineTo(-halfStrokeWidth, halfStrokeWidth)
      .lineTo(-halfStrokeWidth, -strokeLength + halfStrokeWidth);
    bottomLeftCorner.position.set(this.corners[3].x, this.corners[3].y);

    this.cornerGraphs = [topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner];
    this.borderContainer.addChild(topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner);

    this.drawBordersAndCorners(width, height);
  }

  private drawBordersAndCorners(width: number, height: number) {
    this.dims = {
      width,
      height
    };

    this.updateTempCorners(width, height);

    this.tempCornersRotated = [
      this.getRotatedCoordinates(this.tempCorners[0].x, this.tempCorners[0].y),
      this.getRotatedCoordinates(this.tempCorners[1].x, this.tempCorners[1].y),
      this.getRotatedCoordinates(this.tempCorners[2].x, this.tempCorners[2].y),
      this.getRotatedCoordinates(this.tempCorners[3].x, this.tempCorners[3].y)
    ];

    this.borderGraph
      .clear()
      .lineStyle(this.borderWidth, 0x04d7b9)
      .moveTo(this.tempCornersRotated[0].x, this.tempCornersRotated[0].y)
      .lineTo(this.tempCornersRotated[1].x, this.tempCornersRotated[1].y)
      .lineTo(this.tempCornersRotated[2].x, this.tempCornersRotated[2].y)
      .lineTo(this.tempCornersRotated[3].x, this.tempCornersRotated[3].y)
      .lineTo(this.tempCornersRotated[0].x, this.tempCornersRotated[0].y);

    this.cornerGraphs.forEach((corner: Graphics, i) => {
      corner.position.set(this.tempCornersRotated[i].x, this.tempCornersRotated[i].y);
      corner.rotation = this.angleInRad;
    });

    // TODO try to find better solution
    if (this.tempCorners[0].x > this.tempCorners[1].x && this.tempCorners[0].y > this.tempCorners[3].y) {
      this.cornerGraphs[0].position.set(this.tempCornersRotated[2].x, this.tempCornersRotated[2].y);
      this.cornerGraphs[2].position.set(this.tempCornersRotated[0].x, this.tempCornersRotated[0].y);
      this.cornerGraphs[1].position.set(this.tempCornersRotated[3].x, this.tempCornersRotated[3].y);
      this.cornerGraphs[3].position.set(this.tempCornersRotated[1].x, this.tempCornersRotated[1].y);
    } else if (this.tempCorners[0].x > this.tempCorners[1].x) {
      this.cornerGraphs[0].position.set(this.tempCornersRotated[1].x, this.tempCornersRotated[1].y);
      this.cornerGraphs[1].position.set(this.tempCornersRotated[0].x, this.tempCornersRotated[0].y);
      this.cornerGraphs[2].position.set(this.tempCornersRotated[3].x, this.tempCornersRotated[3].y);
      this.cornerGraphs[3].position.set(this.tempCornersRotated[2].x, this.tempCornersRotated[2].y);
    } else if (this.tempCorners[0].y > this.tempCorners[3].y) {
      this.cornerGraphs[0].position.set(this.tempCornersRotated[3].x, this.tempCornersRotated[3].y);
      this.cornerGraphs[3].position.set(this.tempCornersRotated[0].x, this.tempCornersRotated[0].y);
      this.cornerGraphs[1].position.set(this.tempCornersRotated[2].x, this.tempCornersRotated[2].y);
      this.cornerGraphs[2].position.set(this.tempCornersRotated[1].x, this.tempCornersRotated[1].y);
    }
  }

  private updateTempCorners(width, height) {
    switch (this.mouseMoveState) {
      case CursorStates.TopLeftResize:
        this.tempCorners = [
          { x: this.corners[2].x - width, y: this.corners[2].y - height },
          { x: this.corners[2].x, y: this.corners[2].y - height },
          { x: this.corners[2].x, y: this.corners[2].y },
          { x: this.corners[2].x - width, y: this.corners[2].y }
        ];
        break;
      case CursorStates.TopResize:
      case CursorStates.TopRightResize:
        this.tempCorners = [
          { x: this.corners[3].x, y: this.corners[3].y - height },
          { x: this.corners[3].x + width, y: this.corners[3].y - height },
          { x: this.corners[3].x + width, y: this.corners[3].y },
          { x: this.corners[3].x, y: this.corners[3].y }
        ];
        break;
      case CursorStates.LeftResize:
      case CursorStates.BottomLeftResize:
        this.tempCorners = [
          { x: this.corners[1].x - width, y: this.corners[1].y },
          { x: this.corners[1].x, y: this.corners[1].y },
          { x: this.corners[1].x, y: this.corners[1].y + height },
          { x: this.corners[1].x - width, y: this.corners[1].y + height }
        ];
        break;
      default:
        this.tempCorners = [
          { x: this.corners[0].x, y: this.corners[0].y },
          { x: this.corners[0].x + width, y: this.corners[0].y },
          { x: this.corners[0].x + width, y: this.corners[0].y + height },
          { x: this.corners[0].x, y: this.corners[0].y + height }
        ];
    }
  }

  private updateHitArea(width: number, height: number) {
    const poligon = [
      this.getRotatedCoordinates(-this.resizePad - width / 2, this.rotatePad - this.resizePad - height / 2),
      this.getRotatedCoordinates(
        -this.rotatePad - this.resizePad - width / 2,
        this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(
        -this.rotatePad - this.resizePad - width / 2,
        -this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(
        this.rotatePad - this.resizePad - width / 2,
        -this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(this.rotatePad - this.resizePad - width / 2, -this.resizePad - height / 2),

      this.getRotatedCoordinates(width / 2 - this.rotatePad + this.resizePad, -this.resizePad - height / 2),
      this.getRotatedCoordinates(
        width / 2 - this.rotatePad + this.resizePad,
        -this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(
        width / 2 + this.rotatePad + this.resizePad,
        -this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(
        width / 2 + this.rotatePad + this.resizePad,
        this.rotatePad - this.resizePad - height / 2
      ),
      this.getRotatedCoordinates(width / 2 + this.resizePad, this.rotatePad - this.resizePad - height / 2),

      this.getRotatedCoordinates(width / 2 + this.resizePad, height / 2 - this.rotatePad + this.resizePad),
      this.getRotatedCoordinates(
        width / 2 + this.rotatePad + this.resizePad,
        height / 2 - this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(
        width / 2 + this.rotatePad + this.resizePad,
        height / 2 + this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(
        width / 2 - this.rotatePad + this.resizePad,
        height / 2 + this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(width / 2 - this.rotatePad + this.resizePad, height / 2 + this.resizePad),

      this.getRotatedCoordinates(this.rotatePad - this.resizePad - width / 2, height / 2 + this.resizePad),
      this.getRotatedCoordinates(
        this.rotatePad - this.resizePad - width / 2,
        height / 2 + this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(
        -this.rotatePad - this.resizePad - width / 2,
        height / 2 + this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(
        -this.rotatePad - this.resizePad - width / 2,
        height / 2 - this.rotatePad + this.resizePad
      ),
      this.getRotatedCoordinates(-this.resizePad - width / 2, height / 2 - this.rotatePad + this.resizePad)
    ];

    this.hitArea = new Polygon(poligon);
  }

  private getRotatedCoordinates(x, y) {
    return new Point(x * this.cosA - y * this.sinA, y * this.cosA + x * this.sinA);
  }

  private getCoordinatesBeforeRotation(x, y) {
    return {
      x: x * this.cosA + y * this.sinA,
      y: y * this.cosA - x * this.sinA
    };
  }

  private creatOverlayAndMask() {
    this.overlayContainer = new Container();
    this.overlayContainer.interactive = false;
    this.overlayContainer.interactiveChildren = false;
    this.overlay = new Graphics();
    this.overlay.clear().beginFill(0xcfcfcf, 0.65).drawRect(-this.x, -this.y, window.innerWidth, window.innerHeight);
    this.maskContainer = new Container();
    this.maskContainer.addChild(new Graphics(), new Graphics());
    this.maskSprite = new Sprite(Texture.EMPTY);
    this.overlayContainer.addChild(this.maskSprite, this.overlay);
    this.overlayContainer.mask = this.maskSprite;
    this.addChildAt(this.overlayContainer, 0);
    this.updateOverlay();
  }

  private updateOverlay() {
    const ellipseHalfW = Math.abs(this.dims.width / 2);
    const ellipseHalfH = Math.abs(this.dims.height / 2);
    const w = ellipseHalfW * Math.abs(this.cosA) + ellipseHalfH * Math.abs(this.sinA);
    const h = ellipseHalfH * Math.abs(this.cosA) + ellipseHalfW * Math.abs(this.sinA);
    const x = (this.tempCornersRotated[2].x + this.tempCornersRotated[0].x) / 2 + this.x;
    const y = (this.tempCornersRotated[2].y + this.tempCornersRotated[0].y) / 2 + this.y;
    const difX = x < w ? x - w : 0;
    const difY = y < h ? y - h : 0;

    this.overlay
      .clear()
      .beginFill(0xcfcfcf, 0.65)
      .drawRect(0, 0, window.innerWidth - difX, window.innerHeight - difY);
    this.overlayContainer.position.set(difX - this.x, difY - this.y);

    (this.maskContainer.children[0] as Graphics)
      .clear()
      .beginFill(0xffffff)
      .drawRect(0, 0, window.innerWidth - difX, window.innerHeight - difY);
    (this.maskContainer.children[1] as Graphics)
      .clear()
      .beginFill(0x000000)
      .drawEllipse(0, 0, ellipseHalfW, ellipseHalfH);
    (this.maskContainer.children[1] as Graphics).rotation = this.angleInRad;
    (this.maskContainer.children[1] as Graphics).position.set(x - difX, y - difY);

    this.maskSprite.texture.destroy(true);
    this.maskSprite.texture = this.renderer.generateTexture(this.maskContainer);
  }

  private onMouseDown(event: InteractionEvent) {
    this.dragging = true;
    this.off('mousemove', this.mouseMoveEventRef);
    this.mouseMoveEventRef = this.onMouseMoveForDrag.bind(this);

    this.dragOffsetFromCenterGlobal = {
      x: event.data.global.x - this.x,
      y: event.data.global.y - this.y
    };

    this.dragStartMouseCoordinates = {
      ...this.getCoordinatesBeforeRotation(event.data.global.x, event.data.global.y)
    };

    this.dragInitialDims = {
      x: this.x,
      y: this.y,
      width: this.corners[2].x - this.corners[0].x,
      height: this.corners[2].y - this.corners[0].y,
      angle: this.angleInRad,
      angleOffset: 0
    };

    this.tempCorners = [...this.corners];
    this.dragInitialDims.angleOffset = this.getAngleFromXAxes(event.data.global);

    this.on('mousemove', this.mouseMoveEventRef);
  }

  private onMouseUp() {
    this.dragging = false;
    this.off('mousemove', this.mouseMoveEventRef);

    if (this.hover) {
      this.mouseMoveEventRef = this.onMouseMove.bind(this);
      this.on('mousemove', this.mouseMoveEventRef);
    }

    let w = this.tempCorners[2].x - this.tempCorners[0].x;
    let h = this.tempCorners[2].y - this.tempCorners[0].y;
    const wDif = (w - this.dragInitialDims.width) / 2;
    const hDif = (h - this.dragInitialDims.height) / 2;

    const wx =
      wDif *
      this.cosA *
      (this.mouseMoveState === CursorStates.LeftResize ||
      this.mouseMoveState === CursorStates.TopLeftResize ||
      this.mouseMoveState === CursorStates.BottomLeftResize
        ? -1
        : 1);
    const hx =
      hDif *
      this.sinA *
      (this.mouseMoveState === CursorStates.TopResize ||
      this.mouseMoveState === CursorStates.TopLeftResize ||
      this.mouseMoveState === CursorStates.TopRightResize
        ? -1
        : 1);
    const wy =
      wDif *
      this.sinA *
      (this.mouseMoveState === CursorStates.TopResize ||
      this.mouseMoveState === CursorStates.LeftResize ||
      this.mouseMoveState === CursorStates.TopLeftResize ||
      this.mouseMoveState === CursorStates.BottomLeftResize
        ? -1
        : 1);
    const hy =
      hDif *
      this.cosA *
      (this.mouseMoveState === CursorStates.TopResize ||
      this.mouseMoveState === CursorStates.LeftResize ||
      this.mouseMoveState === CursorStates.TopLeftResize ||
      this.mouseMoveState === CursorStates.TopRightResize
        ? -1
        : 1);

    this.x += wx - hx;
    this.y += wy + hy;

    w = Math.abs(w);
    h = Math.abs(h);
    this.updateCorners(w, h);
    this.resize(w, h);
    this.updateHitArea(w, h);

    this.dragInitialDims = null;
    this.renderStage();
  }

  private updateCorners(w, h) {
    this.corners = [
      { x: -w / 2, y: -h / 2 },
      { x: w / 2, y: -h / 2 },
      { x: w / 2, y: h / 2 },
      { x: -w / 2, y: h / 2 }
    ];
  }

  private onMouseMoveForDrag(event: InteractionEvent) {
    const coordinates = [
      CursorStates.Drag,
      CursorStates.TopLeftRotate,
      CursorStates.TopRightRotate,
      CursorStates.BottomRightRotate,
      CursorStates.BottomLeftRotate
    ].includes(this.mouseMoveState)
      ? event.data.global
      : this.getCoordinatesBeforeRotation(event.data.global.x, event.data.global.y);
    this.mouseMoveActions[this.mouseMoveState](coordinates);
    this.updateCustomCursor(event.data.global);
    this.renderStage();
  }

  private onMouseMove(event: InteractionEvent) {
    this.updateCursor(event);
  }

  private onMouseOver(event: InteractionEvent) {
    this.hover = true;
    if (this.dragging) return;
    this.off('mousemove', this.mouseMoveEventRef);
    this.mouseMoveEventRef = this.onMouseMove.bind(this);
    this.on('mousemove', this.mouseMoveEventRef);
    this.updateCursor(event);
  }

  private onMouseOut(event: InteractionEvent) {
    this.hover = false;
    if (this.dragging) return;
    this.off('mousemove', this.mouseMoveEventRef);
    this.hideCustomCursors();
    document.body.style.cursor = CursorStyles.Default;
  }

  private onDrag(coordinates) {
    this.position.set(
      coordinates.x - this.dragOffsetFromCenterGlobal.x,
      coordinates.y - this.dragOffsetFromCenterGlobal.y
    );
    this.updateOverlay();
  }

  private onTopLeftResize(coordinates) {
    const difW = this.dragStartMouseCoordinates.x - coordinates.x;
    const difH = this.dragStartMouseCoordinates.y - coordinates.y;
    const width = difW + this.dragInitialDims.width;
    const height = difH + this.dragInitialDims.height;
    this.resize(width, height);
  }

  private onTopRightResize(coordinates) {
    const width = coordinates.x - this.dragStartMouseCoordinates.x + this.dragInitialDims.width;
    const dif = this.dragStartMouseCoordinates.y - coordinates.y;
    const height = dif + this.dragInitialDims.height;
    this.resize(width, height);
  }

  private onBottomRightResize(coordinates) {
    const width = coordinates.x - this.dragStartMouseCoordinates.x + this.dragInitialDims.width;
    const height = coordinates.y - this.dragStartMouseCoordinates.y + this.dragInitialDims.height;
    this.resize(width, height);
  }

  private onBottomLeftResize(coordinates) {
    const height = coordinates.y - this.dragStartMouseCoordinates.y + this.dragInitialDims.height;
    const dif = this.dragStartMouseCoordinates.x - coordinates.x;
    const width = dif + this.dragInitialDims.width;
    this.resize(width, height);
  }

  private onLeftResize(coordinates) {
    const { height } = this.dragInitialDims;
    const dif = this.dragStartMouseCoordinates.x - coordinates.x;
    const width = dif + this.dragInitialDims.width;
    this.resize(width, height);
  }

  private onTopResize(coordinates) {
    const { width } = this.dragInitialDims;
    const dif = this.dragStartMouseCoordinates.y - coordinates.y;
    const height = dif + this.dragInitialDims.height;
    this.resize(width, height);
  }

  private onRightResize(coordinates) {
    const width = coordinates.x - this.dragStartMouseCoordinates.x + this.dragInitialDims.width;
    const { height } = this.dragInitialDims;
    this.resize(width, height);
  }

  private onBottomResize(coordinates) {
    const { width } = this.dragInitialDims;
    const height = coordinates.y - this.dragStartMouseCoordinates.y + this.dragInitialDims.height;
    this.resize(width, height);
  }

  private resize(width: number, height: number) {
    this.drawBordersAndCorners(width, height);
    this.updateOverlay();
  }

  private onRotate(coordinates) {
    this.angleInRad =
      this.dragInitialDims.angle + this.getAngleFromXAxes(coordinates) - this.dragInitialDims.angleOffset;
    this.updateAngle();
    this.drawBordersAndCorners(this.dragInitialDims.width, this.dragInitialDims.height);
    this.updateOverlay();
  }

  private updateCursor(event: InteractionEvent) {
    this.mouseMoveState = this.getMouseMoveState(event.data.global.x, event.data.global.y);
    this.updateCustomCursor(event.data.global);
    if (document.body.style.cursor !== CursorStyles[this.mouseMoveState]) {
      this.hideCustomCursors();

      if (
        [
          CursorStates.TopLeftRotate,
          CursorStates.TopRightRotate,
          CursorStates.BottomRightRotate,
          CursorStates.BottomLeftRotate
        ].includes(this.mouseMoveState)
      ) {
        this.rotateCursor.style.display = 'block';
        this.activeCursor = this.rotateCursor;
        this.updateCustomCursor(event.data.global);
        document.body.style.cursor = CursorStyles.None;
        return;
      }

      if (
        [CursorStates.RightResize, CursorStates.LeftResize, CursorStates.TopResize, CursorStates.BottomResize].includes(
          this.mouseMoveState
        )
      ) {
        this.horizontalResizeCursor.style.display = 'block';
        this.activeCursor = this.horizontalResizeCursor;
        this.updateCustomCursor(event.data.global);
        document.body.style.cursor = CursorStyles.None;
        if ([CursorStates.TopResize, CursorStates.BottomResize].includes(this.mouseMoveState)) {
          this.activeCursor.style.transform = `rotate(${this.angleInRad + Math.PI / 2}rad)`;
        } else {
          this.activeCursor.style.transform = `rotate(${this.angleInRad}rad)`;
        }
        return;
      }

      if (
        [
          CursorStates.TopLeftResize,
          CursorStates.TopRightResize,
          CursorStates.BottomRightResize,
          CursorStates.BottomLeftResize
        ].includes(this.mouseMoveState)
      ) {
        this.cornerResizeCursor.style.display = 'block';
        this.activeCursor = this.cornerResizeCursor;
        this.updateCustomCursor(event.data.global);
        document.body.style.cursor = CursorStyles.None;
        if ([CursorStates.TopLeftResize, CursorStates.BottomRightResize].includes(this.mouseMoveState)) {
          this.activeCursor.style.transform = `rotate(${this.angleInRad + Math.PI / 2}rad)`;
        } else {
          this.activeCursor.style.transform = `rotate(${this.angleInRad}rad)`;
        }
        return;
      }

      document.body.style.cursor = CursorStyles[this.mouseMoveState];
    }
  }

  private updateCustomCursor(coordinates) {
    if (this.activeCursor) {
      this.activeCursor.style.left = `${coordinates.x - 12}px`;
      this.activeCursor.style.top = `${coordinates.y - 12}px`;
      if (this.activeCursor === this.rotateCursor) {
        const angle =
          this.mouseMoveState === CursorStates.TopLeftRotate
            ? Math.atan2(-this.dims.height / 2, -this.dims.width / 2)
            : this.mouseMoveState === CursorStates.TopRightResize
            ? Math.atan2(-this.dims.height / 2, this.dims.width / 2)
            : this.mouseMoveState === CursorStates.BottomRightRotate
            ? Math.atan2(this.dims.height / 2, this.dims.width / 2)
            : /** bottomLeftRotate */ Math.atan2(this.dims.height / 2, -this.dims.width / 2);

        this.activeCursor.style.transform = `rotate(${this.angleInRad + angle}rad)`;
      }
    }
  }

  private hideCustomCursors() {
    this.horizontalResizeCursor.style.display = 'none';
    this.cornerResizeCursor.style.display = 'none';
    this.rotateCursor.style.display = 'none';
    this.activeCursor = null;
  }

  private getAngleFromXAxes(p) {
    return Math.atan2(p.y - this.y, p.x - this.x);
  }

  private getMouseMoveState(x, y): CursorStates {
    const point = this.getCoordinatesBeforeRotation(x - this.x, y - this.y);
    point.x += this.dims.width / 2;
    point.y += this.dims.height / 2;

    const outerTop = -this.resizePad;
    const outerRight = this.dims.width + this.resizePad;
    const outerBottom = this.dims.height + this.resizePad;
    const outerLeft = -this.resizePad;

    const innerTop = this.innerPad;
    const innerRight = this.dims.width - this.innerPad;
    const innerBottom = this.dims.height - this.innerPad;
    const innerLeft = this.innerPad;

    const mouseMoveState =
      point.x < outerLeft && point.y < outerTop
        ? CursorStates.TopLeftRotate
        : point.x > outerRight && point.y < outerTop
        ? CursorStates.TopLeftRotate
        : point.x > outerRight && point.y > outerBottom
        ? CursorStates.BottomRightRotate
        : point.x < outerLeft && point.y > outerBottom
        ? CursorStates.BottomLeftRotate
        : point.x < innerLeft && point.y < innerTop
        ? CursorStates.TopLeftResize
        : point.x > innerRight && point.y < innerTop
        ? CursorStates.TopRightResize
        : point.x > innerRight && point.y > innerBottom
        ? CursorStates.BottomRightResize
        : point.x < innerLeft && point.y > innerBottom
        ? CursorStates.BottomLeftResize
        : point.x < innerLeft
        ? CursorStates.LeftResize
        : point.y < innerTop
        ? CursorStates.TopResize
        : point.x > innerRight
        ? CursorStates.RightResize
        : point.y > innerBottom
        ? CursorStates.BottomLeftResize
        : CursorStates.Drag;

    return mouseMoveState;
  }

  private updateAngle() {
    this.sinA = Math.sin(this.angleInRad);
    this.cosA = Math.cos(this.angleInRad);
  }

  private renderStage() {
    this.renderer.render(this.stage);
  }
}
