import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent {
  public headerText: string;

  constructor() {
    const date = new Date();
    // console.log(
    //   `${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    //     date
    //   )} ${date.getDate()}, ${date.getFullYear()} Alpha 1.0`
    // );
  }
}
