import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NetworkStateService } from '../../../services/network-state/network-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-detect-network-state',
  templateUrl: './detect-network-state.component.html',
  styleUrls: ['./detect-network-state.component.scss']
})
export class DetectNetworkStateComponent implements OnInit {
  public isActive: boolean = false; // network status changed, need to show panel
  public isShow: boolean = false; // is panel visible or not
  public isConnected: boolean = null; // is online or not

  constructor(public networkStateService: NetworkStateService, protected readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isConnected = this.networkStateService.isConnected;
    this.networkStateService.isConnected$.pipe(untilDestroyed(this)).subscribe(state => {
      if (this.isConnected && !state) {
        this.isActive = true; // build DOM, to prepare for CSS animation
        setTimeout(() => {
          this.isShow = true; // show that lost connection
          this.cdr.markForCheck();
        }, 100); // timeout to build DOM
      }
      if (!this.isConnected && state) {
        this.isShow = true; // show that connection restored
        setTimeout(() => {
          this.isShow = false; // and hide after 3 sec
          this.cdr.markForCheck();
          setTimeout(() => {
            this.isActive = false; // clear DOM
            this.cdr.markForCheck();
          }, 1000); // animation time to hide
        }, 3000);
      }
      this.isConnected = state;
    });
  }

  close() {
    this.isShow = false;
  }
}
