// CSS Color Names
// Map containing all of the color names listed in the CSS Spec.
// The full list can be found here: https://www.w3schools.com/cssref/css_colors.asp
export const CSS_COLOR_NAMES = {
  AliceBlue: '#F0F8FF',
  AntiqueWhite: '#FAEBD7',
  Aqua: '#00FFFF',
  Aquamarine: '#7FFFD4',
  Azure: '#F0FFFF',
  Beige: '#F5F5DC',
  Bisque: '#FFE4C4',
  Black: '#000000',
  BlanchedAlmond: '#FFEBCD',
  Blue: '#0000FF',
  BlueViolet: '#8A2BE2',
  Brown: '#A52A2A',
  BurlyWood: '#DEB887',
  CadetBlue: '#5F9EA0',
  Chartreuse: '#7FFF00',
  Chocolate: '#D2691E',
  Coral: '#FF7F50',
  CornflowerBlue: '#6495ED',
  Cornsilk: '#FFF8DC',
  Crimson: '#DC143C',
  Cyan: '#00FFFF',
  DarkBlue: '#00008B',
  DarkCyan: '#008B8B',
  DarkGoldenRod: '#B8860B',
  DarkGray: '#A9A9A9',
  DarkGrey: '#A9A9A9', // ???
  DarkGreen: '#006400',
  DarkKhaki: '#BDB76B',
  DarkMagenta: '#8B008B',
  DarkOliveGreen: '#556B2F',
  DarkOrange: '#FF8C00',
  DarkOrchid: '#9932CC',
  DarkRed: '#8B0000',
  DarkSalmon: '#E9967A',
  DarkSeaGreen: '#8FBC8F',
  DarkSlateBlue: '#483D8B',
  DarkSlateGray: '#2F4F4F',
  DarkSlateGrey: '#2F4F4F', // ???
  DarkTurquoise: '#00CED1',
  DarkViolet: '#9400D3',
  DeepPink: '#FF1493',
  DeepSkyBlue: '#00BFFF',
  DimGray: '#696969',
  DimGrey: '#696969', // ???
  DodgerBlue: '#1E90FF',
  FireBrick: '#B22222',
  FloralWhite: '#FFFAF0',
  ForestGreen: '#228B22',
  Fuchsia: '#FF00FF',
  Gainsboro: '#DCDCDC',
  GhostWhite: '#F8F8FF',
  Gold: '#FFD700',
  GoldenRod: '#DAA520',
  Gray: '#808080',
  Grey: '#808080', // ???
  Green: '#008000',
  GreenYellow: '#ADFF2F',
  HoneyDew: '#F0FFF0',
  HotPink: '#FF69B4',
  IndianRed: '#CD5C5C',
  Indigo: '#4B0082',
  Ivory: '#FFFFF0',
  Khaki: '#F0E68C',
  Lavender: '#E6E6FA',
  LavenderBlush: '#FFF0F5',
  LawnGreen: '#7CFC00',
  LemonChiffon: '#FFFACD',
  LightBlue: '#ADD8E6',
  LightCoral: '#F08080',
  LightCyan: '#E0FFFF',
  LightGoldenRodYellow: '#FAFAD2',
  LightGray: '#D3D3D3',
  LightGrey: '#D3D3D3', // ???
  LightGreen: '#90EE90',
  LightPink: '#FFB6C1',
  LightSalmon: '#FFA07A',
  LightSeaGreen: '#20B2AA',
  LightSkyBlue: '#87CEFA',
  LightSlateGray: '#778899',
  LightSlateGrey: '#778899', // ???
  LightSteelBlue: '#B0C4DE',
  LightYellow: '#FFFFE0',
  Lime: '#00FF00',
  LimeGreen: '#32CD32',
  Linen: '#FAF0E6',
  Magenta: '#FF00FF',
  Maroon: '#800000',
  MediumAquaMarine: '#66CDAA',
  MediumBlue: '#0000CD',
  MediumOrchid: '#BA55D3',
  MediumPurple: '#9370DB',
  MediumSeaGreen: '#3CB371',
  MediumSlateBlue: '#7B68EE',
  MediumSpringGreen: '#00FA9A',
  MediumTurquoise: '#48D1CC',
  MediumVioletRed: '#C71585',
  MidnightBlue: '#191970',
  MintCream: '#F5FFFA',
  MistyRose: '#FFE4E1',
  Moccasin: '#FFE4B5',
  NavajoWhite: '#FFDEAD',
  Navy: '#000080',
  OldLace: '#FDF5E6',
  Olive: '#808000',
  OliveDrab: '#6B8E23',
  Orange: '#FFA500',
  OrangeRed: '#FF4500',
  Orchid: '#DA70D6',
  PaleGoldenRod: '#EEE8AA',
  PaleGreen: '#98FB98',
  PaleTurquoise: '#AFEEEE',
  PaleVioletRed: '#DB7093',
  PapayaWhip: '#FFEFD5',
  PeachPuff: '#FFDAB9',
  Peru: '#CD853F',
  Pink: '#FFC0CB',
  Plum: '#DDA0DD',
  PowderBlue: '#B0E0E6',
  Purple: '#800080',
  RebeccaPurple: '#663399',
  Red: '#FF0000',
  RosyBrown: '#BC8F8F',
  RoyalBlue: '#4169E1',
  SaddleBrown: '#8B4513',
  Salmon: '#FA8072',
  SandyBrown: '#F4A460',
  SeaGreen: '#2E8B57',
  SeaShell: '#FFF5EE',
  Sienna: '#A0522D',
  Silver: '#C0C0C0',
  SkyBlue: '#87CEEB',
  SlateBlue: '#6A5ACD',
  SlateGray: '#708090',
  SlateGrey: '#708090', // ???
  Snow: '#FFFAFA',
  SpringGreen: '#00FF7F',
  SteelBlue: '#4682B4',
  Tan: '#D2B48C',
  Teal: '#008080',
  Thistle: '#D8BFD8',
  Tomato: '#FF6347',
  Turquoise: '#40E0D0',
  Violet: '#EE82EE',
  Wheat: '#F5DEB3',
  White: '#FFFFFF',
  WhiteSmoke: '#F5F5F5',
  Yellow: '#FFFF00',
  YellowGreen: '#9ACD32'
};

// some additional colors, can be found here: https://color-register.org/color/?q=Raspberry
export const ADDITIONAL_COLOR_NAMES = {
  Oyster: '#D7C9B1',
  // Vanta: ['#000000', '#003366', '#ccd6e0', '#f6efe1', '#fafafa'],
  // Caribbean: ['#1AC1DD', '#00CC99'],
  Moby: '#8EB2BE',
  Storm: '#36365D',
  Cornflower: '#9ACEEB',
  Forest: '#0B5509',
  BlackForest: '#2C3227',
  DarkForestGreen: '#254117',
  DeepForestGreen: '#182D09',
  ForestGreen: '#014421',
  LightForestGreen: '#4F9153',
  LithuanianForestGreen: '#364C20',
  MediumForestGreen: '#347235',
  RainForest: '#667028',
  TropicalRainForest: '#17806D',
  TropicalRainforest: '#00755E',
  Raspberry: '#E30B5D',
  BlueRaspberry: '#0CBFE9',
  BrilliantRaspberry: '#E75189',
  DarkRaspberry: '#872657',
  DeepRaspberry: '#590021',
  FrenchRaspberry: '#C72C48',
  LightBrilliantRaspberry: '#FF659F',
  LightRaspberry: '#E78BAE',
  LuminousVividRaspberry: '#FF0060',
  ModerateRaspberry: '#A84A6D',
  PaleRaspberry: '#FFC2D9',
  PinkRaspberry: '#980036',
  RaspberryPink: '#E25098',
  StrongRaspberry: '#A8003F',
  VeryLightRaspberry: '#FF9EC2',
  VividRaspberry: '#FF006C',
  Celadon: '#ACE1AF',
  CeladonGreen: '#2F847C',
  RustyCeladon: '#898A74',
  AquaMarine: '#2EE8BB',
  Aquamarine: '#7FFFD4',
  AquamarineBlue: '#71D9E2',
  BrilliantAquamarine: '#51E7AF',
  DarkAquamarine: '#017371',
  DeepAquamarine: '#005938',
  FilmproUltramarineBlue: '#2C3E6B',
  LightAquamarine: '#93FFE8',
  LightBrilliantAquamarine: '#65FFC5',
  LuminousVividAquamarine: '#00FF9F',
  Marine: '#042E60',
  MarineBlue: '#01386A',
  Mariner: '#286ACD',
  MediumAquaMarine: '#66CDAA',
  MediumAquamarine: '#66DDAA',
  ModerateAquamarine: '#4AA885',
  PaleAquamarine: '#C2FFE8',
  PastelAquamarine: '#B0E9D5',
  PastelUltramarine: '#807DDB',
  StrongAquamarine: '#00A869',
  Submarine: '#8C9C9C',
  Ultramarine: '#3F00FF',
  UltramarineBlue: '#4166F5',
  UltramarineColor: '#5D8CAE',
  UltramarineViolet: '#5C246E',
  VeryLightAquamarine: '#9EFFDB',
  VividAquamarine: '#00E791',
  YellowSubmarine: '#E8D233',
  Mint: '#3EB489', // https://color-register.org/color/?q=Mint  about 24 colors
  Persimmon: '#EC5800', // https://color-register.org/color/?q=persimmon  8 colors
  Bordeaux: '#7B002C',
  Wheat: '#F5DEB3', // https://color-register.org/color/?q=Wheat 7 colors
  Apple: '#66B447', // https://color-register.org/color/?q=Apple 22 colors
  Yolk: '#F4BB29',
  Rose: '#FF0080', // https://color-register.org/color/?q=Rose  xxx colors
  Lagoon: '#00626F',
  Ice: '#D6FFFA', // https://color-register.org/color/?q=Ice  a lot colors
  Gold: '#A57C00', // https://color-register.org/color/?q=Gold  many colors
  Blood: '#8A0303',
  Groovy: '#5CA345',
  BlackSheep: '#3E393A',
  Sheep: '#e8e6dc'
};

export const ALL_KNOWN_COLOR_NAMES = { ...CSS_COLOR_NAMES, ...ADDITIONAL_COLOR_NAMES };

/**
 * Convert string value into the Camel case format
 * Examples:
 *   camelCase('HELLO WORLD') --> 'helloWorld'
 *   camelCase('SomethingGO Wrong') --> 'somethinggoWrong'
 * @param str
 */
export function camelCase(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => {
    return chr.toUpperCase();
  });
}

/**
 * Return color HEX code by color name
 * Examples:
 *   getHEXColorByName('Black') --> '#000000'
 *   getHEXColorByName('spring green') --> '#00FF7F'
 *   getHEXColorByName('#fff') --> '#fff'
 *   getHEXColorByName('#ff') --> null  (HEX value for color must be in format #FFFFFF or #FFF)
 * @param colorName
 */
export function getHEXColorByName(colorName: string): string {
  let color = camelCase(colorName);
  color = color.charAt(0).toUpperCase() + color.substring(1);
  const result = ALL_KNOWN_COLOR_NAMES[colorName] || ALL_KNOWN_COLOR_NAMES[color] || null;
  if (result) {
    return result;
  }
  return isHEXColor(colorName) ? colorName : null;
}

/**
 * Return true if value has string format for HEX color,
 * For example, for values "#FFFFFF", "#fff" or "#feB5c4" will return true
 * @param value
 */
export function isHEXColor(value: string): boolean {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(value);
}
