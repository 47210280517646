import { Container, Texture, Resource } from 'pixi.js';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { IBounds } from '@app/models/bounds.model';
import gsap from 'gsap/all';

export class FrameTitle {
  titleHtmlRef: HTMLDivElement;
  featureNameHtmlRef: HTMLSpanElement;
  featureValueHtmlRef: HTMLSpanElement;
  numImagesHtmlRef: HTMLParagraphElement;

  private featureNameTemp: string;
  private featureValueTemp: string;

  private titleChangeCallback: Function;
  private titleBlurCallback: Function;

  constructor(
    featureName: string,
    numImages: number,
    featureValue: string,
    titleChangeCallback: Function,
    titleBlurCallback: Function
  ) {
    this.titleChangeCallback = titleChangeCallback;
    this.titleBlurCallback = titleBlurCallback;

    this.featureNameHtmlRef = document.createElement('span');
    this.featureNameHtmlRef.classList.add('frameFeatureName');
    this.featureNameHtmlRef.contentEditable = 'true';
    this.featureNameHtmlRef.setAttribute('placeholder', 'undefined');
    this.featureNameHtmlRef.textContent = `${featureName}`;
    this.featureNameHtmlRef.addEventListener('focus', this.onFeatureNameFocus.bind(this));
    this.featureNameHtmlRef.addEventListener('input', this.onTitleChange.bind(this));
    this.featureNameHtmlRef.addEventListener('blur', this.onFeatureNameBlur.bind(this));
    this.featureNameHtmlRef.addEventListener('keydown', this.onFeatureNameKeyDown.bind(this));

    this.featureValueHtmlRef = document.createElement('span');
    this.featureValueHtmlRef.classList.add('frameFeatureValue');
    this.featureValueHtmlRef.contentEditable = 'true';
    this.featureValueHtmlRef.setAttribute('placeholder', 'undefined');
    this.featureValueHtmlRef.textContent = `${featureValue || ''}`;
    this.featureValueHtmlRef.addEventListener('focus', this.onFeatureValueFocus.bind(this));
    this.featureValueHtmlRef.addEventListener('input', this.onTitleChange.bind(this));
    this.featureValueHtmlRef.addEventListener('blur', this.onFeatureValueBlur.bind(this));
    this.featureValueHtmlRef.addEventListener('keydown', this.onFeatureValueKeyDown.bind(this));

    this.numImagesHtmlRef = document.createElement('p');
    this.numImagesHtmlRef.classList.add('frameNumImages');
    this.numImagesHtmlRef.innerHTML = `${numImages}`;

    this.titleHtmlRef = document.createElement('div');
    this.titleHtmlRef.classList.add('frameTitle');
    this.titleHtmlRef.appendChild(this.featureNameHtmlRef);
    this.titleHtmlRef.appendChild(this.featureValueHtmlRef);
    this.titleHtmlRef.appendChild(this.numImagesHtmlRef);

    getMatrixOverlayContainer().appendChild(this.titleHtmlRef);

    // TODO check if getIntegerPart func is still used
  }

  move(frameBounds: IBounds) {
    this.titleHtmlRef.style.left = `${frameBounds.x + 12}px`;
    this.titleHtmlRef.style.top = `${frameBounds.y - 26}px`;
  }

  private moveOutOfScreen() {
    this.titleHtmlRef.style.left = `-1000px`;
    this.titleHtmlRef.style.top = `1000px`;
  }

  public show(animate: boolean = false): void {
    gsap.killTweensOf(this.titleHtmlRef);
    this.titleHtmlRef.style.display = 'flex';
    if (animate) {
      gsap.to(this.titleHtmlRef, { duration: 0.25, opacity: 1 });
    } else {
      this.titleHtmlRef.style.opacity = '1';
    }
  }

  public hide(animate: boolean = false): void {
    gsap.killTweensOf(this.titleHtmlRef);
    if (animate) {
      gsap.to(this.titleHtmlRef, {
        duration: 0.25,
        opacity: 0,
        onComplete: () => {
          this.moveOutOfScreen();
          this.titleHtmlRef.style.display = `none`;
        }
      });
    } else {
      this.titleHtmlRef.style.display = `none`;
      this.titleHtmlRef.style.opacity = '0';
      this.moveOutOfScreen();
    }
  }

  public updateTitle(featureName: string) {
    this.featureNameHtmlRef.textContent = `${featureName}`;
  }

  private onTitleChange() {
    if (this.titleChangeCallback)
      this.titleChangeCallback({
        featureName: this.featureNameHtmlRef.innerText || this.featureNameTemp,
        featureValue: this.featureValueHtmlRef.innerText
      });
  }

  private onTitleBlur() {
    this.titleHtmlRef.scrollLeft = 0;
    if (this.titleBlurCallback)
      this.titleBlurCallback({
        featureName: this.featureNameHtmlRef.innerText || this.featureNameTemp,
        featureValue: this.featureValueHtmlRef.innerText
      });
  }

  private onFeatureNameFocus() {
    this.featureNameTemp = this.featureNameHtmlRef.innerText;
    this.featureNameHtmlRef.setAttribute('placeholder', this.featureNameTemp);
    window.getSelection().selectAllChildren(this.featureNameHtmlRef);
  }

  private onFeatureNameBlur() {
    window.getSelection().removeAllRanges();
    if (!this.featureNameHtmlRef.innerText) this.featureNameHtmlRef.innerText = this.featureNameTemp;
    this.onTitleChange();
    this.onTitleBlur();
  }

  private onFeatureNameKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === 'Escape') {
      this.featureNameHtmlRef.blur();
    }
  }

  private onFeatureValueFocus() {
    this.featureValueTemp = this.featureValueHtmlRef.innerText;
    window.getSelection().selectAllChildren(this.featureValueHtmlRef);
  }

  private onFeatureValueBlur() {
    window.getSelection().removeAllRanges();
    this.onTitleChange();
    this.onTitleBlur();
  }

  private onFeatureValueKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.featureValueHtmlRef.blur();
      return;
    }

    if (event.key === 'Escape') {
      this.featureValueHtmlRef.innerText = this.featureValueTemp;
      this.featureValueHtmlRef.blur();
    }
  }

  public updateNumImagesText(numImages) {
    this.numImagesHtmlRef.innerText = `${numImages}`;
  }

  public updateMaxWidth(frameWidth: number) {
    this.titleHtmlRef.style.maxWidth = `${frameWidth - 12}px`;
  }

  public getFeatureName(): string {
    return this.featureNameHtmlRef.innerText;
  }

  public getFeatureValue(): string {
    return this.featureValueHtmlRef.innerText;
  }

  public getHtmlRef(): HTMLElement {
    return this.titleHtmlRef;
  }

  delete() {
    const overlay = getMatrixOverlayContainer();
    if (overlay.contains(this.titleHtmlRef)) overlay.removeChild(this.titleHtmlRef);
    delete this.featureNameHtmlRef;
    delete this.featureValueHtmlRef;
    delete this.numImagesHtmlRef;
    delete this.titleHtmlRef;
  }
}
