import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

// Lilac Components
import { ToolboxMatrixTypeComponent } from '@components/build/toolbox-matrix-type/toolbox-matrix-type.component';
import { ConfigDialogComponent } from '@components/build/config-dialog/config-dialog.component';
import { LeftMenuComponent } from '@components/build/left-menu/left-menu.component';
import { ToolboxFormatCanvasComponent } from '@components/build/toolbox-format-canvas/toolbox-format-canvas.component';
import { MatrixCanvasComponent } from '@components/build/matrix-canvas/matrix-canvas.component';
import { StatsBarComponent } from '@components/build/stats-bar/stats-bar.component';
import { ToolboxLeftPrimaryComponent } from '@app/components/build/toolbox-left-primary/toolbox-left-primary.component';
import { QuickStatsSubMenuComponent } from '@app/components/build/toolbox-left-primary/quick-stats-sub-menu/quick-stats-sub-menu.component';
import { PopperMenuComponent } from '@components/build/popper-menu/popper-menu.component';
import { CompassComponent } from '@components/build/compass/compass.component';
import { CompassCanvasComponent } from '@components/build/compass/compass-canvas/compass-canvas.component';
import { HarvestToolbarComponent } from '@app/components/build/harvest-toolbar/harvest-toolbar.component';
import { CarouselComponent } from '@app/components/build/carousel/carousel.component';
// Inputs
import { RangeSliderComponent } from '@components/build/range-slider/range-slider.component';
import { SearchFilterPipe } from '@app/pipes/searchfilter.pipe';
import { FilterNamePipe } from '@app/pipes/filter-name.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatChipsModule } from '@angular/material/chips';
import { ExploreBarComponent } from '@components/build/explore-bar/explore-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterBoxComponent } from '@app/components/build/filter-box/filter-box.component';
import { FormatDatePipe } from '@app/pipes/format-date.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CSVPreviewComponent } from '@app/components/build/csv-preview/csv-preview.component';
import { BuildComponent } from './build.component';
import { MatRadioModule } from '@angular/material/radio';

import { SharedModule } from '@app/shared/components/shared.module';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ResizableModule } from 'angular-resizable-element';
import { UploadFileComponent } from '@components/build/upload-file/upload-file.component';
import { FileSizePipe } from '@app/pipes/file-size.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatrixIconTooltipPipe } from '@app/pipes/matrix-icon-tooltip.pipe';
import { ExploreBarSortingComponent } from '@app/components/build/explore-bar/explore-bar-sorting/explore-bar-sorting.component';
import { ExploreBarGroupingComponent } from '@app/components/build/explore-bar/explore-bar-grouping/explore-bar-grouping.component';
import { MatSortModule } from '@angular/material/sort';
import { AttributeColorPipe } from '@app/pipes/attribute-color.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { FilterTypeIconPipe } from '@app/pipes/filter-type-icon.pipe';
import { GetHEXColorPipe } from '@app/pipes/get-hexcolor.pipe';
import { ExploreBarFilteringComponent } from '@app/components/build/explore-bar/explore-bar-filtering/explore-bar-filtering.component';
import { MatBadgeModule } from '@angular/material/badge';
import { FilterValuesSelectedPipe } from '@app/pipes/filter-values-selected.pipe';
import { FilterBoxItemComponent } from '@app/components/build/filter-box/filter-box-item/filter-box-item.component';
import { FilterAllIndeterminatePipe } from '@app/pipes/filter-all-indeterminate.pipe';
import { SearchByNamePipe } from '@app/pipes/search-by-name.pipe';
import { HasOnePipe } from '@app/pipes/has-one.pipe';
import { MultiHandleSliderComponent } from '@app/components/build/multi-handle-slider/multi-handle-slider.component';
import { ParetoSubtotalPercentOverlayComponent } from '@app/components/build/pareto-subtotal-percent-overlay/pareto-subtotal-percent-overlay.component';
import { ParetoColumnSizePickerComponent } from '@app/components/build/pareto-column-size-picker/pareto-column-size-picker.component';
import { RankAbsolutePlottingLabelComponent } from '@app/components/build/rank-absolute-plotting-label/rank-absolute-plotting-label.component';
import { PresentationModeComponent } from '@app/components/build/presentation-mode/presentation-mode.component';
import { CreateNewStudyDialogComponent } from '@components/build/create-new-study-dialog/create-new-study-dialog.component';
import { HomeModule } from '@app/views/home/home.module';
import { FriendlyNamePipe } from '@app/pipes/friendly-name.pipe';
import { BusinessDataGridComponent } from '@components/build/business-data-grid/business-data-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomAttributesComponent } from '@components/build/business-data-grid/cell-renderer/custom-attributes/custom-attributes.component';
import { CustomHeaderComponent } from '@components/build/business-data-grid/cell-renderer/custom-header/custom-header.component';
import { CustomRowIndexComponent } from '@components/build/business-data-grid/cell-renderer/custom-row-index/custom-row-index.component';
import { ChangeProductNameColumnDialogComponent } from '@app/components/build/change-product-name-column-dialog/change-product-name-column-dialog.component';
import { DialogBaseComponentComponent } from '@app/components/build/dialog-base-component/dialog-base-component.component';
import { InsightsPanelComponent } from '@app/components/build/insights-panel/insights-panel.component';
import { HighlighterFilterBoxComponent } from '@components/build/highlighter-filter-box/highlighter-filter-box.component';
import { FilterTooltipPipe } from '@app/pipes/filter-tooltip.pipe';
import { WorkspaceSelectorComponent } from '@app/components/build/compass/workspace-selector/workspace-selector.component';
import { StudySynchronizerComponent } from '@components/build/study-synchronizer/study-synchronizer.component';
import { RankInsightsPanelComponent } from '@app/components/build/insights-panel/rank-insights-panel/rank-insights-panel.component';

@NgModule({
  declarations: [
    BuildComponent,
    MatrixCanvasComponent,
    RangeSliderComponent,
    StatsBarComponent,
    ToolboxFormatCanvasComponent,
    ToolboxMatrixTypeComponent,
    ConfigDialogComponent,
    LeftMenuComponent,
    ToolboxLeftPrimaryComponent,
    QuickStatsSubMenuComponent,
    SearchFilterPipe,
    SearchByNamePipe,
    FilterNamePipe,
    FilterAllIndeterminatePipe,
    FormatDatePipe,
    FileSizePipe,
    MatrixIconTooltipPipe,
    FilterTooltipPipe,
    ExploreBarComponent,
    ExploreBarSortingComponent,
    ExploreBarGroupingComponent,
    ExploreBarFilteringComponent,
    PopperMenuComponent,
    CompassComponent,
    CompassCanvasComponent,
    WorkspaceSelectorComponent,
    FilterBoxComponent,
    FilterBoxItemComponent,
    HarvestToolbarComponent,
    CarouselComponent,
    BusinessDataGridComponent,
    CustomAttributesComponent,
    CustomRowIndexComponent,
    CustomHeaderComponent,
    CSVPreviewComponent,
    UploadFileComponent,
    AttributeColorPipe,
    FilterTypeIconPipe,
    FilterValuesSelectedPipe,
    GetHEXColorPipe,
    HasOnePipe,
    FriendlyNamePipe,
    MultiHandleSliderComponent,
    ParetoSubtotalPercentOverlayComponent,
    ParetoColumnSizePickerComponent,
    RankAbsolutePlottingLabelComponent,
    PresentationModeComponent,
    CreateNewStudyDialogComponent,
    ChangeProductNameColumnDialogComponent,
    DialogBaseComponentComponent,
    InsightsPanelComponent,
    RankInsightsPanelComponent,
    HighlighterFilterBoxComponent,
    StudySynchronizerComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatTooltipModule,
    MatButtonModule,
    MatExpansionModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatTreeModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    AngularSvgIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ScrollingModule,
    TableVirtualScrollModule,
    ResizableModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatBadgeModule,
    SharedModule,
    MatSortModule,
    OverlayModule,
    MatRadioModule,
    MatRippleModule,
    HomeModule,
    AgGridModule
  ],
  providers: [ProjectDataService],
  exports: [
    MatrixCanvasComponent,
    RangeSliderComponent,
    StatsBarComponent,
    ToolboxFormatCanvasComponent,
    ToolboxMatrixTypeComponent,
    ConfigDialogComponent,
    LeftMenuComponent,
    ToolboxLeftPrimaryComponent,
    QuickStatsSubMenuComponent,
    SearchFilterPipe,
    SearchByNamePipe,
    FilterNamePipe,
    FilterAllIndeterminatePipe,
    FormatDatePipe,
    FileSizePipe,
    FilterTooltipPipe,
    ExploreBarComponent,
    ExploreBarSortingComponent,
    ExploreBarGroupingComponent,
    ExploreBarFilteringComponent,
    PopperMenuComponent,
    CompassComponent,
    CompassCanvasComponent,
    WorkspaceSelectorComponent,
    FilterBoxComponent,
    FilterBoxItemComponent,
    HarvestToolbarComponent,
    CarouselComponent,
    BusinessDataGridComponent,
    CustomAttributesComponent,
    CustomRowIndexComponent,
    CustomHeaderComponent,
    CSVPreviewComponent,
    UploadFileComponent,
    AttributeColorPipe,
    FilterTypeIconPipe,
    FilterValuesSelectedPipe,
    GetHEXColorPipe,
    HasOnePipe,
    FriendlyNamePipe,
    MultiHandleSliderComponent,
    ParetoSubtotalPercentOverlayComponent,
    ParetoColumnSizePickerComponent,
    RankAbsolutePlottingLabelComponent,
    PresentationModeComponent,
    CreateNewStudyDialogComponent,
    ChangeProductNameColumnDialogComponent,
    DialogBaseComponentComponent,
    InsightsPanelComponent,
    RankInsightsPanelComponent,
    HighlighterFilterBoxComponent,
    StudySynchronizerComponent
  ]
})
export class BuildModule {}
