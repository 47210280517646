import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { openCloseAnimation } from '@app/components/animations/animations';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filter, map, startWith, take } from 'rxjs/operators';
import { MatrixToolEnum, ToolboxModel, ValidMatrix } from '@app/state/tools/tools.model';
import { Observable, combineLatest } from 'rxjs';
import {
  SetSelectedTool,
  SetSelectedView,
  StartMatrixHighlighter,
  StopMatrixHighlighter
} from '@app/state/tools/tools.actions';
import { ToolsState } from '@app/state/tools/tools.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LookupTableService } from '@components/lookup-table';
import { SetFrameFeatureName, SetActiveWorkspaceSelectedView } from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import * as Sentry from '@sentry/angular';
import { notEmptyValidator } from '../../../utils/custom-validators';
import { MatDialog } from '@angular/material/dialog';
import { PresentationModeComponent } from '../presentation-mode/presentation-mode.component';
import { slideRatio, xOffset, yOffset } from '@app/shared/constants/presentation';
import { IFiltersModel, filterType2Number } from '@app/models/filters.model';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { ExploreBarSettings, IExploreBarSettingsModel } from '../../../utils/explore-bar.utils';

@UntilDestroy()
@Component({
  selector: 'app-toolbox-left-primary',
  templateUrl: './toolbox-left-primary.component.html',
  styleUrls: ['./toolbox-left-primary.component.scss'],
  animations: [openCloseAnimation]
})
export class ToolboxLeftPrimaryComponent implements OnInit {
  @ViewChild('quickStatsSubMenu', { static: true }) quickStatsSubMenuRef: ElementRef;
  @ViewChild('highlighterFilterBoxSubMenu', { static: true }) highlighterFilterBoxSubMenuRef: ElementRef;
  @ViewChild('frameSubMenu', { static: true }) frameSubMenuRef: ElementRef;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('imageViewSubMenu', { static: true }) imageViewSubMenuRef: ElementRef;
  @ViewChild('image', { static: false }) imageButtonRef: ElementRef;

  @Select(ToolsState.getToolbox)
  public readonly toolbox$: Observable<ToolboxModel>;
  @Select(ToolsState.getSelectedTool)
  private readonly selectedTool$: Observable<MatrixToolEnum>;
  @Select(ToolsState.getSelectedView)
  private readonly selectedView$: Observable<string>;

  @Select(StudyState.getMatrixType)
  public readonly matrixType$: Observable<ValidMatrix>;

  @Select(UserDataState.getDimensionsAndMeasures)
  public readonly filters$: Observable<IFiltersModel[]>;

  @Select(StudyState.getCustomAttributeFilters)
  public readonly customAttributes$: Observable<IFiltersModel[]>;

  @Select(StudyState.getCustomCalculationFilters)
  public readonly customCalculations$: Observable<IFiltersModel[]>;

  @Select(StudyState.getHighlightFilters)
  private readonly highlightFilters$: Observable<IFiltersModel[]>;

  public readonly MatrixTool = MatrixToolEnum;

  filtersList: Array<IFiltersModel> = [];

  frameFeatureNameForm = this.fb.control('', [Validators.required, notEmptyValidator]);

  selectedTool: MatrixToolEnum;
  selectedImageView: string;
  showQuickStatsSubMenu = false;
  showHighlighterFilterBoxSubMenu = false;
  showImageViewSelector = false;
  showFrameSubMenu = false;
  selectedFrameType = 0;

  frameFeatureNamesImage = ['Color', 'Shape', 'Feature 1'];
  frameFeatureNamesData = ['Mood', 'New Class 1', 'Sentiment'];
  frameFeatureFilteredNames$: Observable<string[]>;
  frameFeatureName: string;

  imageSubMenuTop = 0;
  public images: Array<{ id: string; view: string; url: string }> = [];

  isHighlightFiltersActive: boolean = false;

  private lastSelectedTool: MatrixToolEnum = MatrixToolEnum.Cursor;
  private validToolsForRestore = [
    MatrixToolEnum.Cursor,
    MatrixToolEnum.Magnify,
    MatrixToolEnum.Highlighter,
    MatrixToolEnum.Frame
  ];

  protected matrixType: ValidMatrix;
  matrixSettings: IExploreBarSettingsModel;

  private outOfSubMenuClickListenerRef = this.onDocumentClick.bind(this);

  constructor(
    private readonly dialog: MatDialog,
    private store: Store,
    private fb: FormBuilder,
    private lookupTable: LookupTableService
  ) {}

  ngOnInit() {
    combineLatest([this.filters$, this.customAttributes$, this.customCalculations$])
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe(
        ([filters, customFilters, customCulculationFilters]: [IFiltersModel[], IFiltersModel[], IFiltersModel[]]) => {
          const data = [];
          if (filters && filters.length > 0) data.push(...filters);
          if (customFilters && customFilters.length > 0) data.push(...customFilters);
          if (customCulculationFilters && customCulculationFilters.length > 0) data.push(...customCulculationFilters);
          this.filtersList = [];
          data.forEach(filterData => {
            filterData.children = [];
            this.filtersList.push(filterData);
          });

          // show all filters sorted by type + name
          this.filtersList = this.filtersList.sort((a, b) => {
            if (a.type === b.type) {
              return a.name.localeCompare(b.name);
            }
            return filterType2Number(a.type) - filterType2Number(b.type);
          });
        }
      );

    this.matrixType$.pipe(untilDestroyed(this)).subscribe((type: ValidMatrix) => {
      this.matrixType = type;
      this.matrixSettings = ExploreBarSettings[this.matrixType];
    });

    this.selectedView$.pipe(untilDestroyed(this)).subscribe(selectedView => {
      this.selectedImageView = selectedView;
    });
    this.onToolSelect(MatrixToolEnum.Cursor);

    this.selectedTool$.pipe(untilDestroyed(this)).subscribe(tool => {
      this.selectedTool = tool;
      if (this.selectedTool === MatrixToolEnum.Cursor) this.lastSelectedTool = this.selectedTool;
      if (this.selectedTool === MatrixToolEnum.Highlighter) {
        this.showHighlighterFilterBoxSubMenu = true;
      }
    });

    this.highlightFilters$.pipe(untilDestroyed(this)).subscribe((filters: IFiltersModel[]) => {
      this.isHighlightFiltersActive = filters?.length > 0;
    });

    this.frameFeatureNameForm.valueChanges.pipe(untilDestroyed(this)).subscribe(name => {
      if (this.frameFeatureNameForm.invalid) {
        this.store.dispatch(new SetFrameFeatureName(null)); // not selected yet, disable selection on the canvas
      } else {
        this.frameFeatureName = name;
        this.store.dispatch(new SetFrameFeatureName(name)); // selected - allow to select area with images
      }
    });
    this.frameFeatureNameForm.setValue(this.store.selectSnapshot(StudyState.getFrameFeatureName));

    this.showQuickStatsSubMenu = false;
    this.showHighlighterFilterBoxSubMenu = false;
    this.showImageViewSelector = false;
    this.showFrameSubMenu = false;

    this.updateFrameFeatureFilteredNames();
    this.getImageURLs();
  }

  updateFrameFeatureFilteredNames() {
    this.frameFeatureFilteredNames$ = this.frameFeatureNameForm.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = this.selectedFrameType === 0 ? this.frameFeatureNamesImage : this.frameFeatureNamesData;
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onFrameTypeSelect(type: number) {
    if (this.selectedFrameType === type) return;
    this.selectedFrameType = type;
    this.updateFrameFeatureFilteredNames();
  }

  onToolSelect(toolName: MatrixToolEnum, event?) {
    if (toolName === MatrixToolEnum.Frame) {
      this.onFrameClick(event);
    } else if (this.showFrameSubMenu) {
      this.onFrameSubMenuClose();
    }

    if (toolName === MatrixToolEnum.Highlighter) {
      this.onHighlighterFilterBoxSubMenuOpen();
    } else if (this.showHighlighterFilterBoxSubMenu) {
      this.onHighlighterFilterBoxSubMenuClose();
    }

    if (toolName === MatrixToolEnum.Image) {
      this.updateImageSubMenuTop();
      this.onImageViewClick(event);
    } else if (this.showImageViewSelector) {
      this.onImageViewSubMenuClose();
    }

    if (toolName === MatrixToolEnum.HoverInfoConfig) {
      this.onQuickStatsSubMenuOpen();
    } else if (this.showQuickStatsSubMenu) {
      this.onQuickStatsSubMenuClose();
    }

    if (toolName === MatrixToolEnum.PresentationMode) {
      const maxHeight = window.innerHeight - 100 - yOffset;
      const maxWidth = window.innerWidth - 100 - xOffset;
      const width =
        maxHeight / slideRatio.height > maxWidth / slideRatio.width
          ? maxWidth
          : (maxHeight / slideRatio.height) * slideRatio.width;
      const height =
        maxHeight / slideRatio.height > maxWidth / slideRatio.width
          ? (maxWidth / slideRatio.width) * slideRatio.height
          : maxHeight;

      const dialogRef = this.dialog.open(PresentationModeComponent, {
        panelClass: 'presentation-dialog',
        disableClose: true,
        width: `${width + xOffset}px`,
        height: `${height + yOffset}px`
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.store.dispatch(new SetSelectedTool(this.lastSelectedTool));
        });
    }

    if (this.selectedTool === toolName && !this.validToolsForRestore.includes(this.selectedTool)) {
      this.selectedTool = this.lastSelectedTool;
      this.store.dispatch(new SetSelectedTool(this.selectedTool));
      return;
    }

    this.selectedTool = toolName;
    if (this.validToolsForRestore.includes(this.selectedTool)) this.lastSelectedTool = this.selectedTool;
    Sentry.addBreadcrumb({
      level: 'log',
      message: 'onToolSelect',
      data: { selectedTool: this.selectedTool }
    });
    this.store.dispatch(new SetSelectedTool(toolName));
  }

  onImageViewClick(e) {
    this.showImageViewSelector = !this.showImageViewSelector;
    Sentry.addBreadcrumb({
      level: 'log',
      message: 'onImageViewClick',
      data: { showImageViewSelector: this.showImageViewSelector }
    });
    if (this.showImageViewSelector) {
      this.addDocumentClickListener();
    } else {
      this.removeDocumentClickListener();
    }
  }

  onImageViewSubMenuClose() {
    this.showImageViewSelector = false;
    this.removeDocumentClickListener();
    this.store.dispatch(new SetSelectedTool(this.lastSelectedTool));
  }

  onFrameClick(e) {
    this.showFrameSubMenu = !this.showFrameSubMenu;
    if (this.showFrameSubMenu) {
      this.frameFeatureNameForm.markAsUntouched();
      this.addDocumentClickListener();
    } else {
      this.removeDocumentClickListener();
    }
  }

  onFrameSubMenuClose() {
    this.showFrameSubMenu = false;
    this.removeDocumentClickListener();
  }

  onHighlighterFilterBoxSubMenuOpen() {
    this.showHighlighterFilterBoxSubMenu = !this.showHighlighterFilterBoxSubMenu;
    this.store.dispatch(new StopMatrixHighlighter());
    if (this.showHighlighterFilterBoxSubMenu) {
      this.addDocumentClickListener();
    } else {
      this.removeDocumentClickListener();
    }
  }

  onHighlighterFilterBoxSubMenuClose() {
    this.showHighlighterFilterBoxSubMenu = false;
    this.removeDocumentClickListener();
    setTimeout(() => {
      this.store.dispatch(new StartMatrixHighlighter());
    }, 200); // give some time to avoid cleanup Highlight selection
  }

  onQuickStatsSubMenuOpen() {
    this.showQuickStatsSubMenu = !this.showQuickStatsSubMenu;
    if (this.showQuickStatsSubMenu) {
      this.addDocumentClickListener();
    } else {
      this.removeDocumentClickListener();
    }
  }

  onQuickStatsSubMenuClose() {
    this.showQuickStatsSubMenu = false;
    this.removeDocumentClickListener();
    this.store.dispatch(new SetSelectedTool(this.lastSelectedTool));
  }

  onFrameSubMenuError() {
    this.frameFeatureNameForm.markAllAsTouched();
    this.frameFeatureNameForm.updateValueAndValidity();
  }

  onFeatureFrameNameSelect(name, event) {
    if (event.isUserInput) this.onFrameSubMenuClose();
  }

  onFeatureFrameNameInput() {
    if (!this.frameFeatureNameForm.invalid) {
      this.autocomplete.closePanel();
      if (this.frameFeatureName) {
        this.onFrameSubMenuClose();
      } else {
        this.onFrameSubMenuError();
      }
    }
  }

  onImageViewSelect(view: string) {
    this.selectedImageView = view;
    this.store.dispatch([new SetSelectedView(view), new SetActiveWorkspaceSelectedView(view)]);
  }

  onDocumentClick(event) {
    if (
      this.showHighlighterFilterBoxSubMenu &&
      this.highlighterFilterBoxSubMenuRef.nativeElement !== event.target &&
      !this.highlighterFilterBoxSubMenuRef.nativeElement.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.onHighlighterFilterBoxSubMenuClose();
      return;
    }

    if (
      this.showFrameSubMenu &&
      this.frameSubMenuRef.nativeElement !== event.target &&
      !this.frameSubMenuRef.nativeElement.contains(event.target) &&
      !event.target.classList.contains('mat-option-text') &&
      !event.target.closest('.frame')
    ) {
      if (this.selectedTool === MatrixToolEnum.Frame) {
        event.preventDefault();
        event.stopPropagation();
        if (this.frameFeatureName) {
          this.onFrameSubMenuClose();
        } else {
          this.onFrameSubMenuError();
        }
      }
    }

    if (
      this.showImageViewSelector &&
      this.imageViewSubMenuRef.nativeElement !== event.target &&
      !this.imageViewSubMenuRef.nativeElement.contains(event.target) &&
      !event.target.closest('.image')
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.onImageViewSubMenuClose();
    }

    if (
      this.showQuickStatsSubMenu &&
      this.quickStatsSubMenuRef.nativeElement !== event.target &&
      !this.quickStatsSubMenuRef.nativeElement.contains(event.target) &&
      !event.target.closest('.aggregation-type-selector') &&
      !event.target.closest('.cdk-overlay-container') &&
      !event.target.closest('.hoverInfoConfig')
    ) {
      if (this.selectedTool === MatrixToolEnum.HoverInfoConfig) {
        event.preventDefault();
        event.stopPropagation();
        this.onQuickStatsSubMenuClose();
      }
    }
  }

  private addDocumentClickListener() {
    document.addEventListener('mousedown', this.outOfSubMenuClickListenerRef);
  }

  private removeDocumentClickListener() {
    document.removeEventListener('mousedown', this.outOfSubMenuClickListenerRef);
  }

  private getImageURLs(): void {
    this.lookupTable
      .getViewPlaceholderImages()
      .pipe(untilDestroyed(this))
      .subscribe(viewPlaceholderImages => {
        this.images = viewPlaceholderImages || [];
      });
  }

  private updateImageSubMenuTop() {
    if (this.imageButtonRef) {
      this.imageSubMenuTop =
        this.imageButtonRef.nativeElement.offsetTop + this.imageButtonRef.nativeElement.offsetHeight / 2;
      return;
    }
    this.imageSubMenuTop = 0;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onDeleteKeyPress(event: KeyboardEvent) {
    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.showHighlighterFilterBoxSubMenu = false;
    } else {
      this.onToolSelect(MatrixToolEnum.Cursor);
    }
  }

  setHighlighterFilterBoxToggled($event: any) {
    this.showHighlighterFilterBoxSubMenu = !this.showHighlighterFilterBoxSubMenu;
  }
}
