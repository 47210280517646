import { Injectable } from '@angular/core';
import { ILoaderResource, Loader, Texture } from 'pixi.js';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import * as PIXI from 'pixi.js';
import { ImagePlaceholder } from '../../custom-pixi-containers/image-container';

@Injectable({
  providedIn: 'root'
})
export class ResourceManagerService {
  idleLoaders: Loader[] = [];
  activeLoaders: Loader[] = [];
  downloadedResources: { [name: string]: ILoaderResource } = {};
  erroredResources: { [name: string]: ILoaderResource } = {};

  constructor(private appLoaderService: AppLoaderService) {}

  public getPlaceholderImageName(placeholder: ImagePlaceholder): string {
    switch (placeholder) {
      case ImagePlaceholder.COLUMBIA:
        return 'placeholder-columbia';
      case ImagePlaceholder.NIKE:
        return 'placeholder-nike';
      default:
        return 'placeholder-collagia';
    }
  }

  public loadResources(
    resources: Array<{ name: string; url: string }>,
    loadCallback,
    errorCallback,
    completeCallback
  ): Loader {
    // --> console.log('ResourceManagerService.loadResources', resources);
    let loader: Loader;

    if (this.idleLoaders.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      loader = this.idleLoaders[0];
      this.idleLoaders.splice(0, 1);
    } else {
      loader = new Loader();
    }
    this.activeLoaders.push(loader);

    resources.forEach(resource => {
      if (this.checkExists(resource.name)) {
        if (loadCallback) loadCallback(loader, this.getResource(resource.name));
      } else if (resource.url) {
        loader.add(resource);
      }
    });

    if (completeCallback) {
      loader.onComplete.add(completeCallback);
    }

    loader.onLoad.add(this.onResourceLoad.bind(this));
    loader.onError.add(this.onResourceError.bind(this));
    loader.onComplete.add(this.onResourceLoadComplete.bind(this));

    if (loadCallback) {
      loader.onLoad.add(loadCallback);
    }
    if (errorCallback) {
      loader.onError.add(errorCallback);
    }

    loader.load();
    return loader;
  }

  private onResourceLoad(loader, resource) {
    this.downloadedResources[resource.name] = resource;

    const { baseTexture } = this.downloadedResources[resource.name].texture;
    if (!baseTexture.isPowerOfTwo) {
      const size = parseInt(resource.name.slice(resource.name.lastIndexOf('-') + 1), 10);
      if (!size || baseTexture.width !== baseTexture.height) {
        baseTexture.setStyle(PIXI.SCALE_MODES.LINEAR, PIXI.MIPMAP_MODES.ON);
      } else {
        baseTexture.setSize(size, size);
        baseTexture.setStyle(PIXI.SCALE_MODES.LINEAR, PIXI.MIPMAP_MODES.POW2);
      }
      baseTexture.update();
    }
  }

  private onResourceError(loader, resource) {
    this.erroredResources[resource.name] = resource;
  }

  private onResourceLoadComplete(loader) {
    PIXI.utils.clearTextureCache();
    this.stopLoader(loader);
  }

  public checkExists(name: string): boolean {
    return this.downloadedResources[name] && !this.downloadedResources[name].error;
  }

  public getTexture(name): Texture {
    return this.downloadedResources[name].texture;
  }

  public getResource(name): ILoaderResource {
    return this.downloadedResources[name];
  }

  public checkAndGetTexture(name): Texture | null {
    return this.checkExists(name) ? this.getTexture(name) : null;
  }

  public stopActiveLoaders() {
    this.activeLoaders.forEach(loader => {
      this.clearActiveLoader(loader);
      this.idleLoaders.push(loader);
    });
    this.activeLoaders = [];
  }

  public stopLoader(loader) {
    // --> console.log('clearLoader', loader);
    loader.onLoad.detachAll();
    loader.onError.detachAll();
    loader.onProgress.detachAll();
    loader.onComplete.detachAll();
    loader.reset();
    const index = this.activeLoaders.indexOf(loader);
    if (index < 0) return;
    this.activeLoaders.splice(index, 1);
    this.idleLoaders.push(loader);
  }

  private clearActiveLoader(loader) {
    // --> console.log('clearActiveLoader', loader);
    loader.onLoad.detachAll();
    loader.onError.detachAll();
    loader.onProgress.detachAll();
    loader.onComplete.detachAll();
    loader.onLoad.add(this.onResourceLoad.bind(this));
    loader.onError.add(this.onResourceError.bind(this));
    loader.onComplete.add(this.onResourceLoadComplete.bind(this));
    loader.reset();
  }
}
