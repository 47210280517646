import { Directive } from '@angular/core';

@Directive()
export abstract class InsightsPanelBase {
  public loading: boolean = false;

  public getPercentValue(value: number, totalValue: number): number {
    return this.roundValue((value * 100) / totalValue);
  }

  private roundValue(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
