import { Component, OnInit } from '@angular/core';
import { IBounds } from '@app/models/bounds.model';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { SetRankAbsolutePlottingLabelSize } from '@app/state/study/study.actions';
import { formatCurrency } from '@app/utils/string';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ResizeEvent } from 'angular-resizable-element';

export const LABEL_SIZE = 30;
export const LABEL_GAP = 30;
export const POINTS_WRAPPER_SIZE = 1;

@UntilDestroy()
@Component({
  selector: 'app-rank-absolute-plotting-label',
  templateUrl: './rank-absolute-plotting-label.component.html',
  styleUrls: ['./rank-absolute-plotting-label.component.scss']
})
export class RankAbsolutePlottingLabelComponent implements OnInit {
  labelStyle = {
    'top.px': 0,
    'left.px': 0,
    'width.px': 0,
    'height.px': 0
  };
  pointsWrapperStyle;
  pointValueStyle;
  points: number[] = [];

  lineStyle;
  showLine: boolean = false;
  activePoint: number = 0;

  imageSize: number = 0;

  isHorizontal: boolean = false;

  private top: number = 0;
  private left: number = 0;

  constructor(private store: Store, private matrixService: MatrixService) {}

  ngOnInit(): void {
    this.matrixService.rankAbsolutePlottingPointsSubject.pipe(untilDestroyed(this)).subscribe((data: number[]) => {
      this.points = data;
    });
    this.matrixService.rankAbsolutePlottingOrientationSubject.pipe(untilDestroyed(this)).subscribe((data: boolean) => {
      this.isHorizontal = data;

      if (this.isHorizontal) {
        this.pointValueStyle = {
          'top.px': LABEL_GAP,
          transform: `translateX(-50%)`
        };
      } else {
        this.pointValueStyle = {
          'left.px': LABEL_GAP,
          transform: `translateY(-${LABEL_GAP / 2}px)`
        };
      }
    });
    this.matrixService.rankAbsolutePlottingPositionSubject
      .pipe(untilDestroyed(this))
      .subscribe((data: { imageMatrixBounds: IBounds; tl?: gsap.core.Timeline }) => {
        const top = this.isHorizontal
          ? data.imageMatrixBounds.y + data.imageMatrixBounds.height + LABEL_GAP
          : data.imageMatrixBounds.y;
        const left = this.isHorizontal
          ? data.imageMatrixBounds.x
          : data.imageMatrixBounds.x + data.imageMatrixBounds.width + LABEL_GAP;

        this.top = top;
        this.left = left;

        if (data.tl) {
          data.tl.to(
            this.labelStyle,
            {
              duration: 0.28,
              'top.px': top,
              'left.px': left
            },
            'animationStart'
          );
        } else {
          this.labelStyle['top.px'] = top;
          this.labelStyle['left.px'] = left;
        }
        this.updateLineStyle(this.activePoint);
      });
    this.matrixService.rankAbsolutePlottingSizeSubject
      .pipe(untilDestroyed(this))
      .subscribe((data: { labelSize: number; imageSize: number }) => {
        this.imageSize = data.imageSize;
        this.labelStyle['width.px'] = this.isHorizontal ? data.labelSize : LABEL_SIZE;
        this.labelStyle['height.px'] = this.isHorizontal ? LABEL_SIZE : data.labelSize;

        this.pointsWrapperStyle = {
          'width.px': this.isHorizontal ? data.labelSize - data.imageSize : POINTS_WRAPPER_SIZE,
          'height.px': this.isHorizontal ? POINTS_WRAPPER_SIZE : data.labelSize - data.imageSize
        };

        this.updateLineStyle(this.activePoint);
        this.updateLabelSizeInCanvasState();
      });
    this.matrixService.rankAbsolutePlottingImageHover
      .pipe(untilDestroyed(this))
      .subscribe((data: { show: boolean; sortByVal?: number }) => {
        if (data.show) {
          if (data.sortByVal) {
            this.onPointMouseOver(data.sortByVal);
          }
        } else {
          this.onPointMouseOut();
        }
      });
  }

  onPointMouseOver(point: number) {
    this.activePoint = point;
    this.showLine = true;
    this.matrixService.onRankAbsolutePointMouseOver(point);
    this.updateLineStyle(point);
  }

  onPointMouseOut() {
    this.activePoint = 0;
    this.showLine = false;
    this.matrixService.onRankAbsolutePointMouseOut();
    this.updateLineStyle();
  }

  updateLineStyle(point?) {
    if (this.showLine) {
      const pos = this.calculatePointPosition(point);
      if (this.isHorizontal) {
        const size = this.matrixService.getDistanceTillRankAbsPlottingImage(point, this.top + LABEL_SIZE / 2);
        this.lineStyle = {
          'height.px': size,
          'top.px': -size,
          'left.%': pos,
          'border-right': '1px dashed #04d7b9'
        };
      } else {
        const size = this.matrixService.getDistanceTillRankAbsPlottingImage(point, this.left + LABEL_SIZE / 2);
        this.lineStyle = {
          'width.px': size,
          'left.px': -size,
          'top.%': pos,
          'border-top': '1px dashed #04d7b9'
        };
      }
    } else {
      this.lineStyle = {
        'height.px': 0,
        'width.px': 0
      };
    }
  }

  calculatePointPosition(point) {
    return (
      (1 -
        parseFloat(
          (
            (point - this.points[this.points.length - 1]) /
            (this.points[0] - this.points[this.points.length - 1])
          ).toFixed(2)
        )) *
      100
    );
  }

  getPointStyle(point) {
    const pos = this.calculatePointPosition(point);
    return this.isHorizontal ? { 'left.%': pos } : { 'top.%': pos };
  }

  formatPoint(point) {
    return point ? formatCurrency(point) : '';
  }

  onResizing($event: ResizeEvent) {
    this.matrixService.storeAllRankGroupsInfoLockedStateAndHide();
    if ($event.edges.bottom) {
      this.labelStyle['height.px'] = $event.rectangle.height;
    }
    if ($event.edges.top) {
      this.labelStyle['height.px'] = $event.rectangle.height;
      this.labelStyle['top.px'] = $event.rectangle.top;
    }
    if ($event.edges.right) {
      this.labelStyle['width.px'] = $event.rectangle.width;
    }
    if ($event.edges.left) {
      this.labelStyle['width.px'] = $event.rectangle.width;
      this.labelStyle['left.px'] = $event.rectangle.left;
    }

    this.pointsWrapperStyle = {
      'width.px': this.isHorizontal ? this.labelStyle['width.px'] - this.imageSize : POINTS_WRAPPER_SIZE,
      'height.px': this.isHorizontal ? POINTS_WRAPPER_SIZE : this.labelStyle['height.px'] - this.imageSize
    };
    this.matrixService.onRankAbsolutePlottingLabelResize($event);
  }

  onResizeEnd($event: ResizeEvent) {
    this.matrixService.restoreAllRankGroupsInfoLockedState();
    this.updateLabelSizeInCanvasState();
    this.matrixService.onRankAbsolutePlottingLabelResizeEnd();
  }

  validateResize($event: ResizeEvent): boolean {
    const minSize = this.imageSize;
    const newSize = this.isHorizontal ? $event.rectangle.width : $event.rectangle.height;
    return newSize > minSize;
  }

  updateLabelSizeInCanvasState() {
    let labelSize = this.isHorizontal ? this.labelStyle['width.px'] : this.labelStyle['height.px'];
    if (!labelSize || labelSize < 0) labelSize = 0;
    this.store.dispatch(new SetRankAbsolutePlottingLabelSize(labelSize));
  }
}
