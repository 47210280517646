import { Injectable } from '@angular/core';
import { IFrameStateModel, IImageStateModel, IMatrixStateModel, IPosition } from '@app/models/workspace-list.model';
import { Renderer, Container, Graphics, Sprite, Texture } from 'pixi.js';
import { MatrixService } from '../matrix/matrix.service';
import { imageBaseSize } from '@app/shared/constants/viewport';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StudyState } from '@app/state/study/study.state';
import { Observable, combineLatest, distinctUntilChanged, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { IViewport } from '@app/models/matrix.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CompassService {
  private readonly CANVAS_DIMENTIONS = { width: 232, height: 140 };

  @Select(StudyState.getActiveWorkspaceId)
  private readonly workspaceId$: Observable<string>;
  @Select(StudyState.getMatrixStateModelWithoutTools)
  public readonly currentMatrix$: Observable<IMatrixStateModel>;
  public readonly viewport$ = this.matrixService._viewport$;

  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private stage: Container;
  private matrix: Container;
  private viewBox: Graphics;

  private canvasMouseMoveFnRef;
  private canvasMouseDownPosition: IPosition;

  private baseScale = 1;
  private mouseMode: boolean = true;

  private matrixStateModel: IMatrixStateModel;
  private viewportStateModel: IViewport;
  private workspaceId: string;
  private highlightedProdIds: Set<string> = new Set();
  private isHighlighted: boolean = false;
  private selectedProdIds: Set<string> = new Set();
  private isSelected: boolean = false;

  constructor(private store: Store, private matrixService: MatrixService) {
    this.initCanvas();
    this.initCanvasEventListeners();
    this.subWorkspaceId();
    this.subMatrixChange();
    this.subHighlightedProducts();
    this.subSelectedProducts();
  }

  private initCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvas.style.width = `${this.CANVAS_DIMENTIONS.width}px`;
    this.canvas.style.height = `${this.CANVAS_DIMENTIONS.height}px`;

    this.renderer = new Renderer({
      width: this.CANVAS_DIMENTIONS.width,
      height: this.CANVAS_DIMENTIONS.height,
      view: this.canvas,
      antialias: true,
      backgroundColor: 0xf2f2f2
    });

    this.stage = new Container();
    this.stage.width = this.CANVAS_DIMENTIONS.width;
    this.stage.height = this.CANVAS_DIMENTIONS.height;
    this.stage.position.set(0, 0);

    this.viewBox = new Graphics();
  }

  private initCanvasEventListeners() {
    this.canvasMouseMoveFnRef = this.onCanvasMouseMove.bind(this);
    this.renderer.view.addEventListener('mousedown', this.onCanvasMouseDown.bind(this));
    this.renderer.view.addEventListener('mouseup', this.onCanvasMouseUp.bind(this));
    this.renderer.view.addEventListener('mouseleave', this.onCanvasMouseUp.bind(this));
    this.renderer.view.addEventListener('wheel', this.onCanvasZoom.bind(this));
  }

  private subWorkspaceId() {
    this.workspaceId$.pipe(untilDestroyed(this)).subscribe(workspaceId => {
      this.workspaceId = workspaceId;
    });
  }

  private subMatrixChange() {
    combineLatest([
      this.currentMatrix$.pipe(filter(Boolean)),
      this.viewport$.pipe(
        map(viewport => {
          if (viewport.fromCompass) {
            debugger;
            const resolution = this.matrixService.getActiveMatrix()?.getResolution() || 1;
            return {
              ...viewport,
              x: viewport.x / resolution,
              y: viewport.y / resolution,
              width: viewport.width / resolution,
              height: viewport.height / resolution
            };
          }
          return viewport;
        }),
        distinctUntilChanged((prev: IViewport, curr: IViewport) => this.isSameViewport(prev, curr))
      )
    ])
      .pipe(
        withLatestFrom(this.matrixService.clearCompass$),
        filter(([, clearCompass]) => !clearCompass),
        map(([[matrix, viewport]]) => [matrix, viewport]),
        untilDestroyed(this)
      )
      .subscribe(([matrix, viewport]: [IMatrixStateModel, IViewport]) => {
        if (matrix.id === viewport.workspaceId) {
          this.renderModel(matrix, viewport);
        } else {
          this.renderModel(matrix);
        }
      });

    this.matrixService.clearCompass$.pipe(filter(Boolean), untilDestroyed(this)).subscribe(() => {
      this.matrixStateModel = null;
      this.clearStage();
      this.updateStage();
    });
  }

  private subHighlightedProducts() {
    this.store
      .select(StudyState.getMatrixType)
      .pipe(
        filter(value => !!value),
        switchMap((matrixType: ValidMatrix) =>
          matrixType ? this.matrixService.highlightedProductsSubject : of(new Set())
        ),
        untilDestroyed(this)
      )
      .subscribe((highlightedProdIds: Set<string>) => {
        this.highlightedProdIds = highlightedProdIds; // .filter((item, index, arr) => arr.indexOf(item) === index);
        this.isHighlighted = this.highlightedProdIds.size > 0;
        if (this.matrixStateModel) this.renderModel(this.matrixStateModel, this.viewportStateModel);
      });
  }

  private subSelectedProducts() {
    this.store
      .select(StudyState.getMatrixType)
      .pipe(
        filter(value => !!value),
        switchMap((matrixType: ValidMatrix) =>
          matrixType ? this.matrixService.selectedProductsSubject : of(new Set())
        ),
        untilDestroyed(this)
      )
      .subscribe((selectedProdIds: Set<string>) => {
        this.selectedProdIds = selectedProdIds;
        this.isSelected = this.selectedProdIds.size > 0;
        if (this.matrixStateModel) this.renderModel(this.matrixStateModel, this.viewportStateModel);
      });
  }

  private onCanvasMouseMove(event: MouseEvent): void {
    const coordinates = { x: event.offsetX, y: event.offsetY };
    if (
      this.canvasMouseDownPosition &&
      Math.abs(coordinates.x - this.canvasMouseDownPosition.x) > 1 &&
      Math.abs(coordinates.y - this.canvasMouseDownPosition.y) > 1
    ) {
      this.updateViewBoxPosition(coordinates);
    }
  }

  private onCanvasMouseDown(event: MouseEvent): void {
    const coordinates = { x: event.offsetX, y: event.offsetY };
    this.canvasMouseDownPosition = coordinates;
    this.updateViewBoxPosition(coordinates);
    this.renderer.view.addEventListener('mousemove', this.canvasMouseMoveFnRef);
  }

  private onCanvasMouseUp(): void {
    this.mouseMode = true;
    if (this.canvasMouseDownPosition) {
      this.canvasMouseDownPosition = null;
      this.renderer.view.removeEventListener('mousemove', this.canvasMouseMoveFnRef);
      this.updateViewportOnMatrix(true);
    }
  }

  private onCanvasZoom(event: WheelEvent | any): void {
    event.preventDefault();
    if (event.ctrlKey) {
      this.mouseMode = false;
      this.matrixService._zoom$.next(event.deltaY);
    } else if (this.mouseMode) {
      const isTouchPad = event.wheelDeltaY ? event.wheelDeltaY === -3 * event.deltaY : event.deltaMode === 0;
      if (isTouchPad) this.mouseMode = false;
      this.matrixService._zoom$.next(event.deltaY);
    } else {
      this.viewBox.x += event.deltaX * -1;
      this.viewBox.y += event.deltaY * -1;
      this.updateViewportOnMatrix();
      this.updateStage();
    }
  }

  private updateViewBoxPosition(coordinates: { x: number; y: number }): void {
    let x = (coordinates.x - this.matrix.x) / this.matrix.scale.x - this.viewBox.width / 2;
    let y = (coordinates.y - this.matrix.y) / this.matrix.scale.y - this.viewBox.height / 2;
    if (x < 0) {
      x = 0;
    } else if (x > this.CANVAS_DIMENTIONS.width) {
      x = this.CANVAS_DIMENTIONS.width;
    }
    if (y < 0) {
      y = 0;
    } else if (y > this.CANVAS_DIMENTIONS.height) {
      y = this.CANVAS_DIMENTIONS.height;
    }
    this.viewBox.position.set(x, y);
    this.updateViewportOnMatrix();
    this.updateStage();
  }

  // TODO:
  // Update to use this service
  // Remove Viewport Pos Service
  private updateViewportOnMatrix(update: boolean = false) {
    const scale = this.baseScale;
    const resolution = this.matrixService.getActiveMatrix()?.getResolution() || 1;
    this.matrixService.setViewport({
      workspaceId: this.workspaceId,
      x: this.viewBox.position.x * scale * resolution,
      y: this.viewBox.position.y * scale * resolution,
      width: this.viewBox.width * scale * resolution,
      height: this.viewBox.height * scale * resolution,
      update,
      fromCompass: true
    });
  }

  private updateStage() {
    this.renderer.render(this.stage);
  }

  private clearStage() {
    this.stage.removeChildren();
  }

  private renderModel(model: IMatrixStateModel, viewport: IViewport = null) {
    this.clearStage();

    if (!model.matrix) {
      return;
    }

    this.matrixStateModel = model;
    this.viewportStateModel = viewport;

    // proportions between matrix and compass are different
    this.baseScale = this.getScale(model.matrix.dimensions, this.CANVAS_DIMENTIONS);

    this.matrix = this.createMatrix(model, viewport);

    if (this.matrix) {
      this.stage.addChild(this.matrix);
    }

    this.updateStage();
  }

  private createMatrix(model: IMatrixStateModel, viewport: IViewport = null): Container {
    const images = model.imageMatrix.images.map(image => this.createImage(image, model.imageMatrix.position));

    const frames = model.frames.map(frame => this.createFrame(frame));

    const elements = [...frames, ...images];

    if (elements.length === 0) {
      return null;
    }

    const container = new Container();

    container.addChild(...elements);

    // scale to fit world
    const scale = this.getScale(this.CANVAS_DIMENTIONS, container);
    container.scale.set(scale > 2 ? 2 : scale); // limit to prevent big images

    // center world
    const bounds = container.getBounds();
    container.position.set(
      container.position.x - bounds.x + (this.CANVAS_DIMENTIONS.width - container.width) / 2,
      container.position.y - bounds.y + (this.CANVAS_DIMENTIONS.height - container.height) / 2
    );

    // add viewport box
    if (viewport) {
      this.updateViewPort(viewport);
      if (this.viewBox) {
        container.addChild(this.viewBox);
      }
    }

    return container;
  }

  private createImage(image: IImageStateModel, offset: IPosition): Sprite {
    const scale = this.baseScale;
    const imgSize = imageBaseSize / scale;

    const x = (offset.x + image.position.x) / scale;
    const y = (offset.y + image.position.y) / scale;

    const img = new Sprite(Texture.WHITE);
    img.width = imgSize;
    img.height = imgSize;
    img.anchor.set(0, 0);
    img.tint = this.isHighlighted
      ? this.highlightedProdIds.has(image.id)
        ? 0x04d7b9
        : 0xe1e0e7
      : this.isSelected
      ? this.selectedProdIds.has(image.id)
        ? 0x04d7b9
        : 0x038fa8
      : 0x038fa8;
    img.position.set(x, y);

    return img;
  }

  private createFrame(frame: IFrameStateModel): Container {
    const scale = this.baseScale;

    const width = frame.dimensions.width / scale;
    const height = frame.dimensions.height / scale;
    const x = frame.position.x / scale;
    const y = frame.position.y / scale;

    const container = new Container();
    container.width = width;
    container.height = height;
    container.position.set(x, y);

    const bg = new Sprite(Texture.WHITE);
    bg.width = width;
    bg.height = height;
    bg.anchor.set(0, 0);
    bg.tint = 0x038fa8; // var(--accent-color)
    bg.alpha = 0.3;

    const offset = { x: -imageBaseSize / 2, y: -imageBaseSize / 2 };
    const frames = frame.frames.map(item => this.createFrame(item));
    const images = frame.images.map(image => this.createImage(image, offset));

    container.addChild(bg, ...frames, ...images);

    return container;
  }

  private updateViewPort(viewport: IViewport) {
    if (!viewport) {
      return;
    }

    const scale = this.baseScale;

    const width = viewport.width / scale;
    const height = viewport.height / scale;
    const x = viewport.x / scale;
    const y = viewport.y / scale;

    this.viewBox.position.set(x, y);
    this.viewBox
      .clear()
      .lineStyle(0.25, 0x000000)
      .drawRect(0, 0, width - 0.25, height - 0.25);
  }

  private getScale<T extends { width: number; height: number }>(parent: T, child: T): number {
    const scaleX = parent.width / child.width;
    const scaleY = parent.height / child.height;
    return scaleX < scaleY ? scaleX : scaleY;
  }

  private isSameViewport(prev: IViewport, next: IViewport): boolean {
    return (
      prev &&
      prev.workspaceId === next.workspaceId &&
      prev.x === next.x &&
      prev.y === next.y &&
      prev.width === next.width &&
      prev.height === next.height
    );
  }

  public getCanvas() {
    return this.canvas;
  }
}
