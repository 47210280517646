import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByName'
})
export class SearchByNamePipe<T extends { name: string }> implements PipeTransform {
  transform(items: Array<T>, searchValue: string): Array<T> {
    if (!items || !searchValue || searchValue === '') {
      return items;
    }
    return items.filter(item => {
      return item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });
  }
}
