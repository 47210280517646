import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HarvestService } from '@app/services/harvest/harvest.service';

@Component({
  selector: 'app-harvest-toolbar',
  templateUrl: './harvest-toolbar.component.html',
  styleUrls: ['./harvest-toolbar.component.scss']
})
export class HarvestToolbarComponent implements OnInit, OnDestroy {
  showToolbar = false;

  featureTypeFormGroup = this.fb.group({
    featureType: ['', Validators.required]
  });
  featureName = '';
  featureValue = '';

  private subs = [];

  constructor(private harvestService: HarvestService, private fb: FormBuilder) {
    this.subs.push(
      this.harvestService.harvestModeSubject.subscribe((init: boolean) => {
        this.showToolbar = init;
        if (this.showToolbar) {
          this.featureName = '';
          this.featureValue = '';
          this.featureTypeFormGroup.reset();
        }
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onTrainClick() {
    this.harvestService.closeHarvestMode();
  }

  checkFormValidity() {
    return this.featureTypeFormGroup.valid && this.featureName && this.featureValue;
  }

  onInputFeatureValue($event: any) {
    this.featureValue = $event.target.textContent;
  }

  onInputFeatureName($event: any) {
    this.featureName = $event.target.textContent;
  }
}
