import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(date: Date | number, parseFormat?: string): string {
    if (parseFormat) {
      return moment(date, parseFormat).format('MM/DD/YYYY');
    }
    return moment(date).format('MM/DD/YYYY');
  }
}
