import { Injectable } from '@angular/core';
import { light, dark } from './theme.const';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public active;
  constructor() {
    this.setTheme(light);
  }
  toggle() {
    if (this.active === light) {
      this.setTheme(dark);
    } else {
      this.setTheme(light);
    }
  }
  private setTheme(theme) {
    this.active = theme;
    Object.keys(theme).forEach(k => document.documentElement.style.setProperty(`--${k}`, theme[k]));
  }
}
