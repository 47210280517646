<div class="toolbox">
  <div class="toolbox-heading">Format Canvas</div>
  <div style="padding: 0 4px">
    <app-range-slider
      (change)="updateSpacing()"
      label="Spacing X "
      min="1"
      max="100"
      [(value)]="spacing.x"
    ></app-range-slider>
    <app-range-slider
      (change)="updateSpacing()"
      label="Spacing Y "
      min="1"
      max="100"
      [(value)]="spacing.y"
    ></app-range-slider>
  </div>
</div>
