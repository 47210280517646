<h2 mat-dialog-title>
  Select Encoding
  <span *ngIf="selectedCodepage">: {{ selectedCodepage.encoding }}</span>
</h2>
<div mat-dialog-content class="scrollable">
  <div class="codepage-list">
    <div
      class="codepage"
      *ngFor="let codePage of CodePageList; let i = index"
      [class.selected]="codePage === selectedCodepage"
      [attr.id]="'codepage-' + codePage.codepage"
    >
      <div class="name" (click)="selectCodepage(codePage)">{{ codePage.displayName }}</div>
    </div>
  </div>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="null">Cancel</button>
  <button mat-flat-button color="accent" (click)="onClickOKButton()" [disabled]="!selectedCodepage">OK</button>
</mat-dialog-actions>
