import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  Renderer2,
  Inject,
  ViewContainerRef
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isMac, isWin } from '@app/utils/os.utils';
import { LookupTableService } from '@app/components/lookup-table';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { WINDOW } from '@app/shared/utils/window';
import {
  IPrepareImagesToDownloadWorkerAction,
  IPrepareImagesToDownloadWorkerActionResult
} from '@app/workers/worker.type';
import { convertToPngWithCanvas, prepareImagesToDownload } from '@app/utils/image.utils';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { IContextMenuOpenCloseSubject, IDrowDotBgSubject } from '@app/models/matrix.model';

@UntilDestroy()
@Component({
  selector: 'app-matrix-canvas',
  templateUrl: './matrix-canvas.component.html',
  styleUrls: ['./matrix-canvas.component.scss']
})
export class MatrixCanvasComponent implements OnInit, OnDestroy {
  @ViewChild('dotBG', { read: ElementRef, static: true }) dotBG: ElementRef;
  @ViewChild('dotBGWithOpacity', { read: ElementRef, static: true }) dotBGWithOpacity: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true })
  contextMenuTrigger: MatMenuTrigger;
  @ViewChild(MatMenu, { read: ElementRef, static: false })
  contextMenuRef: ElementRef;

  public canvas: HTMLCanvasElement;
  public contextMenuPosition: { x: number; y: number } = { x: 0, y: 0 };

  private subs = [];

  private downKeyRefs: any[] = [];

  private contextMenuImage: ImageContainer;
  private prepareImagesToDownload: Worker = null;

  private documentClickRef = this.onDocumentClick.bind(this);

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private renderer: Renderer2,
    private lookupTable: LookupTableService,
    private viewContainerRef: ViewContainerRef,
    private matrixService: MatrixService
  ) {
    setTimeout(() => {
      matrixService.viewContainerRef = this.viewContainerRef;
      matrixService.imageInfoOverlayRef = new ImageInfoOverlay();
    });
  }

  ngOnInit(): void {
    this.initWorker();

    document.getElementById('matrixCanvasWrapper')?.appendChild(this.matrixService.canvas);

    this.setupDocumentVisibilityChange();
    this.addMatrixSub();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.prepareImagesToDownload?.terminate();
  }

  private openContextMenu(items, image) {
    this.closeContextMenu();
    this.contextMenuImage = image;
    this.contextMenuPosition = this.contextMenuImage.getContextMenuGlobalPosition();
    this.contextMenuTrigger.menuData = { items };
    this.contextMenuTrigger.openMenu();
    document.addEventListener('click', this.documentClickRef);
  }

  private closeContextMenu(): void {
    if (this.contextMenuTrigger.menuOpen === true) {
      this.contextMenuTrigger.closeMenu();
      this.contextMenuImage = null;
    }
  }

  private onDocumentClick(event: MouseEvent) {
    if (
      this.contextMenuRef.nativeElement !== event.target &&
      !this.contextMenuRef.nativeElement.contains(event.target)
    ) {
      document.removeEventListener('click', this.documentClickRef);
      this.closeContextMenu();
    }
  }

  private addMatrixSub() {
    this.subs.push(
      this.matrixService.contextMenuOpenCloseSubject.subscribe((payload: IContextMenuOpenCloseSubject) => {
        if (payload.open) {
          this.openContextMenu(payload.items, payload.image);
        } else {
          this.closeContextMenu();
        }
      })
    );
    this.subs.push(
      this.matrixService.drowDotBgSubject.subscribe((payload: IDrowDotBgSubject) => {
        this.drowDotBG(payload.viewportScale, payload.baseScale, payload.topLeft, payload.type);
      })
    );
  }

  private drowDotBG(viewportScale, baseScale, topLeftcorner, type?: string) {
    const min = 14; // 8
    const mid = 20;
    const max = 28; // 32

    let distance = mid * (viewportScale / baseScale);

    while (distance > max) {
      distance /= 4;
    }
    while (distance < min) {
      distance *= 4;
    }

    const size = distance;

    const alpha = size >= mid ? 1 : size >= 18 ? 0.4 : size >= 16 ? 0.3 : size >= 14 ? 0.2 : 0;

    const bigSize = alpha !== 1 ? size * 4 : size;
    const opacity = alpha === 1 ? 0 : alpha;

    const pos = {
      x: topLeftcorner.x % bigSize,
      y: topLeftcorner.y % bigSize
    };

    this.renderer.setStyle(
      this.dotBG.nativeElement,
      'background-position',
      `${pos.x - bigSize / 2}px ${pos.y - bigSize / 2}px`
    );
    this.renderer.setStyle(
      this.dotBGWithOpacity.nativeElement,
      'background-position',
      `${pos.x - size / 2}px ${pos.y - size / 2}px`
    );

    if (type === 'drag' || type === 'decelerate' || type === 'clamp-x' || type === 'clamp-y') {
      return;
    }

    this.renderer.setStyle(this.dotBG.nativeElement, 'background-size', `${bigSize}px ${bigSize}px`);
    this.renderer.setStyle(this.dotBGWithOpacity.nativeElement, 'background-size', `${size}px ${size}px`);
    this.renderer.setStyle(this.dotBGWithOpacity.nativeElement, 'opacity', `${opacity}`);
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    this.matrixService.onWindowWheel(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.matrixService.onWindowResize(event);
  }

  @HostListener('document:keydown.delete', ['$event'])
  @HostListener('document:keydown.backspace', ['$event'])
  onDeleteKeyPress(event: KeyboardEvent) {
    this.matrixService.onDeleteKeyPress(event);
  }

  @HostListener(`document:keydown.control`, ['true', `'control'`])
  @HostListener(`document:keydown.meta`, ['true', `'meta'`])
  @HostListener(`document:keyup.control`, ['false', `'control'`])
  @HostListener(`document:keyup.meta`, ['false', `'meta'`])
  setControlCommandKeyState(active: boolean, key: 'meta' | 'control') {
    if ((isMac && key === 'meta') || (isWin && key === 'control')) {
      this.updateDownKeyRefs(
        active,
        key,
        this.matrixService
          .getActiveMatrix()
          ?.setControlCommandKeyState.bind(this.matrixService.getActiveMatrix(), false)
      );
      this.matrixService.getActiveMatrix()?.setControlCommandKeyState(active);
    }
  }

  @HostListener(`document:mousedown`, ['true', '$event'])
  @HostListener(`document:mouseup`, ['false', '$event'])
  setPanningState(active: boolean, event: MouseEvent) {
    if (event.button === 2) this.matrixService.getActiveMatrix()?.setPanningState(active);
  }

  private setupDocumentVisibilityChange() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') this.clearDownKeyRefs();
    });
  }

  private updateDownKeyRefs(active: boolean, key: string, callbackfn: Function) {
    const index = this.downKeyRefs.findIndex(downkey => downkey.key === key);
    if (!active && index > -1) {
      if (this.downKeyRefs[index].callbackfn) this.downKeyRefs[index].callbackfn();
      this.downKeyRefs.splice(index, 1);
    } else if (active && index === -1) {
      this.downKeyRefs.push({ key, callbackfn });
    }
  }

  private clearDownKeyRefs() {
    this.downKeyRefs.forEach(downkey => {
      if (downkey.callbackfn) downkey.callbackfn();
    });
    this.downKeyRefs = [];
  }

  private initWorker() {
    if (typeof Worker !== 'undefined' && typeof OffscreenCanvas !== 'undefined') {
      this.prepareImagesToDownload = new Worker(
        new URL('../../../workers/app.prepare-images-to-download.worker', import.meta.url),
        {
          type: 'module'
        }
      );
      this.prepareImagesToDownload.addEventListener('message', (message: MessageEvent) => {
        const { action, result, startTime } = message.data as IPrepareImagesToDownloadWorkerActionResult;
        if (action === 'prepareImagesToDownload') {
          // --> console.log('prepareImagesToDownload.done by ', new Date().getTime() - startTime);
          this.downloadBlob(result);
        }
      });
    }
  }

  public onDownloadImages(images: Array<ImageContainer>): void {
    const urls = this.getUrlsForUniqueImages(images);
    if (this.prepareImagesToDownload) {
      this.prepareImagesToDownload.postMessage({
        action: 'prepareImagesToDownload',
        data: urls
      } as IPrepareImagesToDownloadWorkerAction);
    } else {
      // Web Worker is not supported. So make everything in Main thread
      const onComplete = (result: { name: string; blob: Blob }) => {
        this.downloadBlob(result);
      };
      prepareImagesToDownload(urls, convertToPngWithCanvas.bind(this), onComplete.bind(this));
    }
  }

  private getUrlsForUniqueImages(images: Array<ImageContainer>): Array<string> {
    const uniqueImages: Array<{ id: string; url: string }> = [];
    for (const image of images) {
      if (uniqueImages.findIndex(item => item.id === image.getId()) === -1) {
        uniqueImages.push({
          id: image.getId(),
          url: this.lookupTable.getProductImage(image.getId(), image.getView(), 1024)
        });
      }
    }
    return uniqueImages.map(({ url }) => url);
  }

  private downloadBlob(data: { name: string; blob: Blob }): void {
    const elem = this.window.document.createElement('a');
    elem.style.display = 'none';
    elem.href = URL.createObjectURL(data.blob);
    elem.download = data.name;
    this.window.document.body.appendChild(elem);
    elem.click();
    this.window.document.body.removeChild(elem);
  }
}
