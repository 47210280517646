import { Injectable } from '@angular/core';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import {
  LoadBusinessConnection,
  ResetActiveBusinessConnection,
  SetActiveBusinessConnection,
  SetActiveBusinessConnectionName,
  UpdateBusinessConnection
} from './business-connection.actions';
import { IBusinessConnection } from './business-connection.model';

@State<IBusinessConnection>({
  name: 'activeBusinessConnection',
  defaults: null
})
@Injectable()
export class BusinessConnectionState {
  @Selector([BusinessConnectionState])
  static getActiveBusinessConnection(state: IBusinessConnection): IBusinessConnection {
    return state;
  }

  @Selector([BusinessConnectionState])
  static getActiveBusinessConnectionId(state: IBusinessConnection | null): string | null {
    return state ? state.id : null;
  }

  @Selector([BusinessConnectionState])
  static getActiveBusinessDataId(state: IBusinessConnection | null): string | null {
    return state ? state.businessDataId : null;
  }

  @Selector([BusinessConnectionState])
  static getActiveBusinessConnectionName(state: IBusinessConnection | null): string | null {
    return state ? state.metadata.name : null;
  }

  constructor(private store: Store, private projectDataService: ProjectDataService) {}

  @Action(SetActiveBusinessConnection)
  private setActiveBusinessConnection(
    { setState }: StateContext<IBusinessConnection>,
    { connection }: SetActiveBusinessConnection
  ) {
    setState(connection);
  }

  @Action(SetActiveBusinessConnectionName)
  private setActiveBusinessConnectionName(
    { setState, getState }: StateContext<IBusinessConnection>,
    { name }: SetActiveBusinessConnectionName
  ) {
    const { id } = getState();
    this.projectDataService.updateConnectionName(id, name).then(() => {
      setState(patch({ metadata: patch({ name }) }));
    });
  }

  @Action(ResetActiveBusinessConnection)
  private resetActiveBusinessConnection({ setState }: StateContext<IBusinessConnection>) {
    setState(null);
  }

  @Action(UpdateBusinessConnection)
  private setBusinessConnection(
    { getState, setState }: StateContext<IBusinessConnection>,
    { payload }: UpdateBusinessConnection
  ) {
    const { id } = getState();
    this.projectDataService.updateConnection(id, payload as any).then(() => {
      setState(
        patch({
          metadata: patch({ name: payload.metadata.name }),
          settings: patch(payload.settings)
        })
      );
    });
  }

  @Action(LoadBusinessConnection)
  private loadConnection({ dispatch }: StateContext<IBusinessConnection>, { connectionId }: LoadBusinessConnection) {
    return this.projectDataService.getConnection(connectionId).pipe(
      tap((connection: IBusinessConnection) => {
        dispatch(new SetActiveBusinessConnection(connection));
      })
    );
  }
}
