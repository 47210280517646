import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserDataState } from '../../../state/user-data/user-data.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ISelectedRowsForServerQuery {
  isExclude: boolean;
  selection: Array<number>;
}

/**
 * Service to support selection in BusinessDataGridComponent.
 * Work with big amount of rows.
 * selectedRows array could store Included OR Excluded elements - depend on if selectAll checkbox was selected or not
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SelectRowsService {
  @Select(UserDataState.getTotalRowsInView) totalRowsInView$: Observable<number>;

  private selectedRows: Set<number> = new Set();
  private totalRows: number = 0;

  // if true, all rows in the selectedRows are EXCLUDED from selection (all other, which not in list are included)
  private isExcludeSelected: boolean = false;
  private isAllSelected: boolean = false; // if true, the SelectALL checkbox was selected

  constructor(public store: Store) {
    this.totalRowsInView$.pipe(untilDestroyed(this)).subscribe(totalRows => {
      this.setTotalRows(totalRows);
    });
  }

  setTotalRows(totalRows: number) {
    this.totalRows = totalRows;
  }

  isAllRowsSelected(): boolean {
    if (this.isExcludeSelected) {
      return this.selectedRows.size === 0;
    }
    return this.selectedRows.size === this.totalRows;
  }

  getSelectedRowsForServerQuery(): ISelectedRowsForServerQuery {
    return {
      isExclude: this.isExcludeSelected,
      selection: Array.from(this.selectedRows)
    };
  }

  getSelectedRowsNumber(): number {
    if (this.isExcludeSelected) {
      return this.totalRows - this.selectedRows.size;
    }
    return this.selectedRows.size;
  }

  getSelectedRows(): Array<number> {
    if (this.isExcludeSelected) {
      const rows = [];
      for (let index = 0; index < this.totalRows; index++) {
        if (!this.selectedRows.has(index)) {
          rows.push(index);
        }
      }
      return rows;
    }
    return Array.from(this.selectedRows);
  }

  isHasSelection(): boolean {
    if (this.isExcludeSelected) {
      return this.selectedRows.size < this.totalRows;
    }
    return this.selectedRows.size > 0;
  }

  selectAll() {
    this.isAllSelected = true;
    this.isExcludeSelected = true;
    this.selectedRows = new Set();
  }

  deselectAll() {
    this.isAllSelected = false;
    this.isExcludeSelected = false;
    this.selectedRows = new Set();
  }

  getRowSelection(rowIndex: number): boolean {
    if (this.isExcludeSelected) {
      return !this.selectedRows.has(rowIndex);
    }
    return this.selectedRows.has(rowIndex);
  }

  selectRow(rowIndex: number) {
    this.setRowSelection(rowIndex, true);
  }

  unselectRow(rowIndex: number) {
    this.setRowSelection(rowIndex, false);
  }

  setRowSelection(rowIndex: number, isSelected: boolean) {
    if (
      ((isSelected && this.isExcludeSelected) || (!isSelected && !this.isExcludeSelected)) &&
      this.selectedRows.has(rowIndex)
    ) {
      this.selectedRows.delete(rowIndex);
    }
    if ((isSelected && !this.isExcludeSelected) || (!isSelected && this.isExcludeSelected)) {
      this.selectedRows.add(rowIndex);
    }
  }
}
