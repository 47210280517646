import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { DatabaseConnectionType, IBusinessConnection } from '@app/state/connection/business/business-connection.model';
import { ConnectionType } from '@app/state/connection/connection.model';
import { IImageConnection, ImageManagerType } from '@app/state/connection/image/image-connection.model';
import { ConnectionUtils } from '@app/utils/connection.utils';

@Component({
  selector: 'app-home-connection-card',
  templateUrl: './home-connection-card.component.html',
  styleUrls: ['./home-connection-card.component.scss']
})
export class HomeConnectionCardComponent implements OnInit {
  @Input() public connection: IImageConnection | IBusinessConnection;
  public readonly PortalMenuItem = PortalMenuItem;
  public readonly ConnectionType = ConnectionType;
  public readonly ImageManagerType = ImageManagerType;
  public readonly DatabaseConnectionType = DatabaseConnectionType;

  public urlPath: string;

  @HostBinding('class.business') get business(): boolean {
    return this.connection.type === ConnectionType.Business;
  }

  @HostBinding('class.image') get image(): boolean {
    return this.connection.type === ConnectionType.Image;
  }

  ngOnInit(): void {
    this.urlPath = ConnectionUtils.getConnectionUrlPath(this.connection);
  }
}
