import { IGroupImageModel, IRankGroupModel, IRankGroupNameModel } from '@app/models/group-model';
import { Container } from 'pixi.js';
import { ImageContainer } from './image-container';
import { FriendlyNameService } from '@services/friendly-name/friendly-name.service';
import { IBounds } from '@app/models/bounds.model';
import { IDimensions, IPosition } from '@app/models/workspace-list.model';
import { GroupOrientation } from '@app/models/study-setting.model';
import { shortUid } from '@app/shared/utils/short-uid';

export class GroupContainer extends Container {
  private friendlyNameService: FriendlyNameService;
  private label: IRankGroupNameModel;
  private level: number;
  private filteredRows: Array<Array<any>>;
  private images: Array<ImageContainer> = [];
  private groups: Array<GroupContainer> = [];
  private customLocalBounds: IBounds;
  private customBounds: IBounds;
  public isFirst: boolean;
  public isLast: boolean;
  public labelOffset = 0;
  public imageModels: Array<IGroupImageModel>;

  private cursorPositionFromAnchor: IPosition;
  private savedGroupPositionOnParent: IPosition;
  private savedGroupDimensions: IDimensions;
  private newSize: number;

  constructor(
    private readonly id = shortUid(),
    group: IRankGroupModel,
    level: number,
    friendlyNameService: FriendlyNameService
  ) {
    super();
    this.label = group.name;
    this.level = level;
    this.filteredRows = group.filteredRows;
    this.friendlyNameService = friendlyNameService;
  }

  setNewSize(newSize: number) {
    this.newSize = newSize;
  }

  getNewSize(): number {
    return this.newSize;
  }

  setImages(images: Array<ImageContainer>): void {
    this.images = images;
  }

  setGroups(groups: Array<GroupContainer>): void {
    this.groups = groups;
  }

  setCustomLocalBounds(bounds: IBounds) {
    this.customLocalBounds = { ...bounds };
  }

  setCustomBounds(bounds: IBounds) {
    this.customBounds = { ...bounds };
  }

  getCustomLocalBounds(): IBounds {
    return this.customLocalBounds ? this.customLocalBounds : this.getLocalBounds();
  }

  getCustomBounds(): IBounds {
    return this.customBounds ? this.customBounds : this.getBounds();
  }

  getId(): string {
    return this.id;
  }

  getLabel(): IRankGroupNameModel {
    return this.label;
  }

  getLevel(): number {
    return this.level;
  }

  getFriendlyNameService(): FriendlyNameService {
    return this.friendlyNameService;
  }

  getFilteredRows(): Array<Array<any>> {
    return this.filteredRows;
  }

  getImages(): Array<ImageContainer> {
    return this.images;
  }

  getAllImages(): Array<ImageContainer> {
    let allImages = [...this.images];
    if (this.groups.length > 0) {
      this.groups.forEach(group => {
        allImages = [...allImages, ...group.getAllImages()];
      });
    }
    return allImages;
  }

  getFirstImageInGroup(): ImageContainer {
    return this.images.length > 0 ? this.images[0] : this.groups[0].getFirstImageInGroup();
  }

  getImageSize(): number {
    return this.getFirstImageInGroup().getBounds().width;
  }

  getGroups(): Array<GroupContainer> {
    return this.groups;
  }

  getAllGroups(): Array<GroupContainer> {
    if (this.groups.length === 0) return [];
    const allGroups = [...this.groups];
    this.groups.forEach(group => {
      allGroups.push(...group.getAllGroups());
    });
    return allGroups;
  }

  getCoordinatesOnImageMatrix(): { x: number; y: number } {
    const pos = { x: this.x, y: this.y };
    if (this.parent instanceof GroupContainer) {
      const parentPos = this.parent.getCoordinatesOnImageMatrix();
      pos.x += parentPos.x;
      pos.y += parentPos.y;
    }
    return pos;
  }

  onDrag(coordinates: IPosition, groupOrientation: GroupOrientation, limits: { min: number; max: number }) {
    if (groupOrientation === GroupOrientation.Vertical) {
      this.position.x = Math.min(Math.max(coordinates.x - this.cursorPositionFromAnchor.x, limits.min), limits.max);
    } else {
      this.position.y = Math.min(Math.max(coordinates.y - this.cursorPositionFromAnchor.y, limits.min), limits.max);
    }
  }

  saveCusrosPositionFromAnchor(coordinates: IPosition) {
    this.cursorPositionFromAnchor = {
      x: coordinates.x - this.savedGroupPositionOnParent.x,
      y: coordinates.y - this.savedGroupPositionOnParent.y
    };
  }

  getSavedPosition(): IPosition {
    return this.savedGroupPositionOnParent;
  }

  savePosition(position?: IPosition) {
    this.savedGroupPositionOnParent = position || { x: this.x, y: this.y };
  }

  getSavedDimensions(): IDimensions {
    return this.savedGroupDimensions || { width: this.width, height: this.height };
  }

  saveDimensions() {
    this.savedGroupDimensions = { width: this.width, height: this.height };
  }

  delete() {
    this.removeAllListeners();
    this.removeChildren();
    this.images = null;
    this.groups = null;
  }
}
