import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemoDataService {
  constructor(private http: HttpClient) {}

  public getJSON(url) {
    return this.http
      .get<any[]>(url)
      .pipe(tap(data => this.mapData(data)))
      .toPromise();
  }

  private mapData(data) {
    // parse currency string values '$343,3434.23' to number
    const currencyKeys: string[] = Object.entries(data[0])
      .filter(([, val]) => typeof val === 'string' && val[0] === '$')
      .map(([key]) => key);

    data.forEach(item => {
      currencyKeys.forEach(currencyKey => {
        item[currencyKey] = parseFloat(item[currencyKey].toString().replaceAll('$', '').replaceAll(',', ''));
      });
    });
  }
}
