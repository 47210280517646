import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { IHeaderParams } from 'ag-grid-community';
import { SetFriendlyHeaderTitle, SetShowOriginalHeaders } from '../../../../../state/study/study.actions';
import { WINDOW } from '../../../../../shared/utils/window';
import { FriendlyNameService } from '../../../../../services/friendly-name/friendly-name.service';
import { Store } from '@ngxs/store';
import { StudyState } from '../../../../../state/study/study.state';
import { SortDirection } from 'ag-grid-community/dist/lib/entities/colDef';
import { IHeaderAngularComp } from 'ag-grid-angular/lib/interfaces';
import { SelectRowsService } from '../../select-rows.service';

export interface ICustomHeaderParams {
  menuIcon: string;
  isEditableTitle: boolean;
  isBooleanHeader?: boolean;
}

/**
 * Component to render custom Header in AG-Grid
 * see example: https://www.ag-grid.com/angular-data-grid/component-header/
 */

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements IHeaderAngularComp {
  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

  @ViewChild('alternativeTitleEdit', { static: false })
  public readonly alternativeTitleEditRef: ElementRef;

  public params!: IHeaderParams & ICustomHeaderParams;

  public sort: SortDirection = null;

  isEditHeader: boolean = false;
  newHeaderText: string;

  protected readonly outOfEditHeaderTitleClickListenerRef = this.onDocumentClick.bind(this);

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private friendlyName: FriendlyNameService,
    private store: Store,
    private selection: SelectRowsService
  ) {}

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;

    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));

    this.onSortChanged();
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.sort = null;
    if (this.params.column.isSortAscending()) {
      this.sort = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sort = 'desc';
    }
  }

  onSortRequested(event: any) {
    if (this.sort === 'asc') {
      this.params.setSort('desc', event.shiftKey);
    } else if (this.sort === 'desc') {
      this.params.setSort(null, event.shiftKey);
    } else {
      this.params.setSort('asc', event.shiftKey);
    }
  }

  refresh(params: IHeaderParams) {
    return false;
  }

  onStartEditHeader($event: MouseEvent) {
    if (!this.params.isEditableTitle) {
      return;
    }

    $event.preventDefault();

    this.isEditHeader = true;

    const isShowOriginalHeaders = this.store.selectSnapshot(StudyState.isShowOriginalHeaders);
    if (isShowOriginalHeaders) {
      // when we start edit Friendly name, friendly names should be shown everywhere
      this.store.dispatch(new SetShowOriginalHeaders(false));
    }

    // use timeout, to avoid conflicts with Header Sort functionality
    setTimeout(() => {
      this.window.document.addEventListener('click', this.outOfEditHeaderTitleClickListenerRef);
      this.alternativeTitleEditRef.nativeElement.focus();
    });
    this.newHeaderText = this.friendlyName.getFriendlyName(this.params.displayName);
  }

  onStopEditHeader(isRestoreOriginal?: boolean) {
    this.isEditHeader = false;

    if (isRestoreOriginal) {
      this.newHeaderText = ''; // send empty string to restore Original header
    }
    this.store.dispatch(
      new SetFriendlyHeaderTitle({ header: this.params.displayName, headerTitle: this.newHeaderText })
    );

    // use timeout, to avoid conflicts with Header Sort functionality
    setTimeout(() => {
      this.newHeaderText = null;
    });
    this.window.document.removeEventListener('click', this.outOfEditHeaderTitleClickListenerRef);
  }

  private onDocumentClick(event: PointerEvent) {
    if (this.alternativeTitleEditRef?.nativeElement !== event.target) {
      this.onStopEditHeader();
    }
  }

  public toggleSelectionAll() {
    // Update: cannot use api.selectAll();, it throws error below:
    // Error: selectAll only available when rowModelType='clientSide', ie not infinite
    //     at SelectionService.selectAllRowNodes
    if (this.selection.isAllRowsSelected()) {
      this.selection.deselectAll();
      this.params.api.deselectAll();
    } else {
      this.selection.selectAll();
      // this.params.api.selectAll();
      this.params.api.forEachNode((row, index) => {
        this.params.api.getRowNode(row.id).setSelected(true);
      });
    }
  }

  public isHasSelection(): boolean {
    return this.selection.isHasSelection();
    // return this.params.api.getSelectedRows().length > 0;
  }

  public isAllSelected(): boolean {
    return this.selection.isAllRowsSelected();
    // return this.params.api.getSelectedRows().length === this.params.api.getDisplayedRowCount();
  }
}
