<div
  class="stats-bar"
  [class.expand]="isStatsExpanded"
  mwlResizable
  [enableGhostResize]="false"
  [validateResize]="validateResize.bind(this)"
  (resizing)="onResizing($event)"
  [style.height]="(isStatsExpanded ? currentHeight : 40) + 'px'"
  [@expendStatsBarAnimation]="{ value: isStatsExpanded ? 'expanded' : 'closed', params: { height: currentHeight } }"
>
  <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }" *ngIf="isStatsExpanded"></div>

  <div
    class="header-row"
    [matTooltip]="isStatsExpanded ? 'Hide data table' : 'Show data table'"
    matTooltipPosition="above"
    matTooltipClass="above"
    (click)="onStatsClick()"
  >
    <div class="header-row__item" *ngFor="let stat of headerRowStats; let i = index">
      <span class="header-row__item__count">{{ stat.value }}</span>
      <span class="header-row__item__label">{{ stat.label }}</span>
    </div>
  </div>
  <div class="content stats-tabs" [class.hidden]="!isStatsExpanded">
    <mat-tab-group>
      <mat-tab label="Data">
        <app-business-data-grid
          [imagesOnMatrix]="productsToShowOnMatrix"
          [containerHeight]="tableHeight"
          [(isShowColumnList)]="isShowColumnList"
        ></app-business-data-grid>
      </mat-tab>
      <!-- mat-tab label="Discoveries" [disabled]="true"> Coming soon... </mat-tab -->
    </mat-tab-group>

    <div class="top-right-info">
      <div class="total-rows-in-view">{{ totalRowsInView$ | async }} rows in View</div>
      <div class="toggle-friendly-names" *ngIf="isFriendlyNamesNotEmpty$ | async">
        <mat-checkbox (change)="$event ? toggleFriendlyNames() : null" [checked]="isShowOriginalHeaders">
          Original headers
        </mat-checkbox>
      </div>
      <div class="toggle-columns-button mat-flat-button" (click)="toggleColumnList()" [class.active]="isShowColumnList">
        Columns
      </div>
    </div>
  </div>
</div>
