<div
  class="wrapper"
  *ngIf="displayData && wrapperStyles"
  [class.hide]="hideOverlay"
  [class.fixed]="overlayFixedPosition"
  [class.horizontal]="overlayOrientation === 'horizontal'"
>
  <div
    class="groupSubtotalPercentOverlay"
    [ngStyle]="!overlayFixedPosition && wrapperStyles"
    [style.transition]="wrapperTransition"
    *ngIf="!overlayFixedPosition"
  >
    <div class="groupSubTotalPercentBlocksWrapper">
      <div
        class="groupSubTotalPercentBlockWrapper"
        *ngFor="let group of displayData; let i = index"
        [ngStyle]="!overlayFixedPosition && blockWrapperStyles[i]"
      >
        <div class="groupSubtotalPercentBlock" [ngStyle]="!overlayFixedPosition && blockStyles">
          <div class="groupSubtotalPercentBlockTop">
            <p class="numImages">{{ group.products.length }}</p>
            <p class="subTotalPercent">{{ group.subtotal.toFixed(1) }}%</p>
          </div>
          <div
            class="groupSubtotalPercentBlockBottom"
            [ngStyle]="!overlayFixedPosition && blockBottomStyles"
            [@paretoPrevBlockListAnimation]="prevBlocksData[i].numBlocks"
          >
            <div
              class="prevBlock"
              [ngStyle]="!overlayFixedPosition && previewBlockStyle"
              [class.isHalfBlock]="prevBlocksData[i].hasHalfBlock && j === prevBlocksData[i].numBlocks - 1"
              *ngFor="let block of [].constructor(prevBlocksData[i].numBlocks); let j = index"
            >
              <div *ngIf="prevBlocksData[i].hasHalfBlock && j === prevBlocksData[i].numBlocks - 1; else prevFullBlock">
                <svg-icon svgClass="icon" name="icon-pareto-preview-block-half"></svg-icon>
              </div>
              <ng-template #prevFullBlock>
                <svg-icon svgClass="icon" name="icon-pareto-preview-block-full"></svg-icon>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="group-orientation-icon" matRipple (click)="onOverlayFixedPositionChange()">
      <svg-icon svgClass="icon" name="icon-rank-group-orientation"></svg-icon>
    </div>
  </div>

  <div class="groupSubtotalPercentOverlay" [style.transition]="wrapperTransition" *ngIf="overlayFixedPosition">
    <div class="groupSubtotalPercentBlock" *ngFor="let group of displayData; let i = index">
      <span
        ><p class="numImages">{{ group.products.length }}</p></span
      >
      <div class="groupSubtotalPercentPrevBlockWrapper" [@paretoPrevBlockListAnimation]="prevBlocksData[i].numBlocks">
        <div
          class="prevBlock"
          [class.isHalfBlock]="prevBlocksData[i].hasHalfBlock && j === prevBlocksData[i].numBlocks - 1"
          *ngFor="let block of [].constructor(prevBlocksData[i].numBlocks); let j = index"
        >
          <div *ngIf="prevBlocksData[i].hasHalfBlock && j === prevBlocksData[i].numBlocks - 1; else prevFullBlock">
            <svg-icon svgClass="icon" name="icon-pareto-preview-block-half"></svg-icon>
          </div>
          <ng-template #prevFullBlock>
            <svg-icon svgClass="icon" name="icon-pareto-preview-block-full"></svg-icon>
          </ng-template>
        </div>
      </div>
      <span
        ><p class="subTotalPercent">{{ group.subtotal.toFixed(1) }}%</p></span
      >
    </div>

    <div class="group-orientation-icon fixed" matRipple (click)="onOverlayFixedPositionChange()">
      <svg-icon svgClass="icon" name="icon-rank-group-orientation"></svg-icon>
    </div>
  </div>
</div>
