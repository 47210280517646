<h3 class="name" [matTooltip]="name" matTooltipPosition="below" matTooltipClass="below" [matTooltipDisabled]="!owner">
  {{ name }}
</h3>
<button
  *ngIf="owner && owner !== '*'"
  matRipple
  [matRippleColor]="
    connectionType ? PortalMenuItemCollorMapper[type][connectionType] : PortalMenuItemCollorMapper[type]
  "
  [matRippleCentered]="true"
  [matRippleRadius]="matRippleRadius"
  class="favorite-button"
  [ngClass]="btnClassList"
  (click)="onAddToFavorites($event)"
>
  <svg-icon *ngIf="!favorite" svgClass="icon" name="icon-card-favorite"></svg-icon>
  <svg-icon *ngIf="favorite" svgClass="icon" name="icon-card-favorite-active"></svg-icon>
</button>
