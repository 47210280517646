import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

/**
 * Navigation Route Resolver, instead of getting some external data - make only delay when page changes.
 * Uses to have a time for app-fade-in-out directive to hide components on the page with fade-out animation.
 * Without it, Router will change the navigation page immediately
 */

export const DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE = 300;

@Injectable({
  providedIn: 'root'
})
export class DelayResolver implements Resolve<any> {
  resolve() {
    return of('').pipe(delay(DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE));
  }
}
