import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HarvestService } from '@app/services/harvest/harvest.service';
import { ImageContainer } from '@app/custom-pixi-containers/image-container';
import { openCloseAnimation } from '@app/components/animations/animations';
import { ResourceManagerService } from '@app/services/resource-manager/resource-manager.service';
import { ILoaderResource } from 'pixi.js';
import { Store } from '@ngxs/store';
import { LookupTableService } from '@app/components/lookup-table';
import * as PIXI from 'pixi.js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatrixService } from '@app/services/matrix/matrix.service';

@UntilDestroy()
@Component({
  selector: 'app-popper-menu',
  templateUrl: './popper-menu.component.html',
  styleUrls: ['./popper-menu.component.scss'],
  animations: [openCloseAnimation]
})
export class PopperMenuComponent implements OnInit, OnDestroy {
  @ViewChild('imagePopper', { static: true }) imagePopperRef: ElementRef;

  popupPosition = {
    top: -10000,
    left: -10000
  };
  showPopup = false;
  private image: ImageContainer;
  private imageDeselectFuncRef: Function;
  private renderFuncRef: Function;
  private documentClickRef;
  private subs = [];
  imageViewUrls: Array<{ view: string; url: string }> = [];
  selectedImageView: string;
  showImageViewSelector = false;

  constructor(
    private matrixService: MatrixService,
    private harvestService: HarvestService,
    private resourceManagerService: ResourceManagerService,
    private store: Store,
    private lookupTable: LookupTableService
  ) {}

  ngOnInit(): void {
    this.matrixService.imagePopperMenuSubject
      .pipe(untilDestroyed(this))
      .subscribe(data => this.onImagePopperMenuUpdate(data));

    this.documentClickRef = this.onDocumentClick.bind(this);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onHarvestClick() {
    this.showPopup = false;
    this.popupPosition = {
      top: -10000,
      left: -10000
    };
    if (this.imageDeselectFuncRef) this.imageDeselectFuncRef(this.image);
    this.harvestService.initHarvestMode(this.image);
  }

  onImagePopperMenuUpdate(data: {
    show: boolean;
    coordinates?: { x: number; y: number };
    image?: ImageContainer;
    imageDeselectRef?: Function;
    renderRef?: Function;
  }) {
    this.showImageViewSelector = false;
    this.imageViewUrls = [];
    this.showPopup = data.show;

    if (!this.showPopup) {
      document.removeEventListener('click', this.documentClickRef);
      return;
    }

    this.selectedImageView = data.image.getView();
    this.image = data.image;
    this.imageDeselectFuncRef = data.imageDeselectRef;
    this.renderFuncRef = data.renderRef;

    this.popupPosition.left = data.coordinates.x - 150 / 2;
    this.popupPosition.top = data.coordinates.y - 10 - 56;
    setTimeout(() => {
      document.addEventListener('click', this.documentClickRef);
    });
  }

  onDocumentClick(event) {
    if (
      this.imagePopperRef.nativeElement !== event.target &&
      !this.imagePopperRef.nativeElement.contains(event.target)
    ) {
      document.removeEventListener('click', this.documentClickRef);
      this.matrixService.getActiveMatrix().imagePopperMenuShown = false;
      this.showPopup = false;
      this.showImageViewSelector = false;
      this.imageViewUrls = [];
      this.popupPosition = {
        top: -10000,
        left: -10000
      };
    }
  }

  onImageClick() {
    this.showImageViewSelector = !this.showImageViewSelector;
    if (this.showImageViewSelector) this.imageViewUrls = this.lookupTable.getAllImageViews(this.image.getId());
  }

  onImageViewClick(view: string) {
    if (view === this.selectedImageView) return;
    this.selectedImageView = view;
    // TODO: this might create problems
    this.resourceManagerService.stopActiveLoaders();
    const resource = [this.createResource(256)];
    this.resourceManagerService.loadResources(
      resource,
      this.onViewLoad.bind(this),
      null,
      this.loadHighResView.bind(this)
    );
  }

  private onViewLoad() {
    const matrix = this.matrixService.getActiveMatrix();
    this.image.updateView(this.resourceManagerService, this.selectedImageView, matrix.render.bind(matrix));
    matrix?.onIndividualImageViewChange(this.image);
    if (this.renderFuncRef) this.renderFuncRef();
  }

  private loadHighResView() {
    const resources = [this.createResource(512), this.createResource(1024)];
    this.resourceManagerService.loadResources(resources, this.onHighResViewLoad.bind(this), null, null);
  }

  private onHighResViewLoad(loader, resource: ILoaderResource) {
    if (resource.name.includes(this.image.getId())) {
      const size = resource.name.slice(resource.name.lastIndexOf('-') + 1);
      if (String(this.image.getSize()) === size) {
        this.image.updateResolution(this.resourceManagerService, this.image.getSize());
        if (this.renderFuncRef) this.renderFuncRef();
      }
    }
  }

  private createResource(size) {
    return {
      name: `${this.image.getId()}_${this.selectedImageView}-${size}`,
      url: this.lookupTable.getProductImage(this.image.getId(), this.selectedImageView, size),
      crossOrigin: true,
      loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
      xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB
    };
  }
}
