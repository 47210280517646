import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { IFiltersModel } from '@app/models/filters.model';
import {
  IFrameDBModel,
  IFrameStateModel,
  IImageStateModel,
  IWorkspace,
  IWorkspaceDBModel
} from '@app/models/workspace-list.model';

export function processWorkspace(
  workspace: IWorkspaceDBModel,
  frames: Array<IFrameDBModel>,
  images: Array<IImageStateModel>
): IWorkspace {
  return {
    ...workspace,
    frames: processFrames(workspace.frames, frames, images),
    imageMatrix: {
      ...workspace.imageMatrix,
      images: processImages(workspace.imageMatrix.images, images)
    },
    axesShelf: {
      x: processFilters(workspace.axesShelf.x),
      y: processFilters(workspace.axesShelf.y),
      filtering: processFilters(workspace.axesShelf.filtering || [])
    }
  };
}

export function processFilters(filters: Array<IFiltersModel>): Array<IFiltersModel> {
  filters.forEach(filter => {
    if (filter.type === 'Date' || filter.dataType === 'date') {
      if (filter.currentMinMaxValue) {
        if (filter.currentMinMaxValue.min instanceof Timestamp) {
          filter.currentMinMaxValue.min = (filter.currentMinMaxValue.min as any).toDate();
          filter.currentMinMaxValue.max = (filter.currentMinMaxValue.max as any).toDate();
        }
      }
      if (filter.minMaxValue) {
        if (filter.minMaxValue.min instanceof Timestamp) {
          filter.minMaxValue.min = (filter.minMaxValue.min as any).toDate();
          filter.minMaxValue.max = (filter.minMaxValue.max as any).toDate();
        }
      }
    }
  });
  return filters;
}

export function processFrames(
  frameRefs: Array<string | DocumentReference>,
  frames: Array<IFrameDBModel>,
  images: Array<IImageStateModel>
): Array<IFrameStateModel> {
  if (frameRefs.length === 0) return [];
  return frameRefs.map(frameRef => {
    return processFrame(frameRef, frames, images);
  });
}

export function processFrame(
  frameRef: string | DocumentReference,
  frames: Array<IFrameDBModel>,
  images: Array<IImageStateModel>
): IFrameStateModel {
  const frameId: string = isString(frameRef) ? (frameRef as string) : (frameRef as DocumentReference).id;
  const frame = frames.find(item => item.id === frameId);
  return {
    ...frame,
    frames: processFrames(frame.frames, frames, images),
    images: processImages(frame.images, images)
  };
}

export function processImages(
  imageRefs: Array<string | DocumentReference>,
  images: Array<IImageStateModel>
): Array<IImageStateModel> {
  return imageRefs.map(imageRef => processImage(imageRef, images));
}

export function processImage(imageRef: string | DocumentReference, images: Array<IImageStateModel>): IImageStateModel {
  const imageId: string = isString(imageRef) ? (imageRef as string) : (imageRef as DocumentReference).id;
  return images.find(item => item.uid === imageId);
}

function isString(value: any): boolean {
  return typeof value === 'string' || value instanceof String;
}
