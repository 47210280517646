import { Pipe, PipeTransform } from '@angular/core';
import { FiltersTypeEnum, IFiltersModel } from '@app/models/filters.model';
import moment from 'moment';

@Pipe({
  name: 'filterTooltip'
})
export class FilterTooltipPipe implements PipeTransform {
  transform(filter: IFiltersModel, allFilters: Array<IFiltersModel>): string {
    if (filter.currentMinMaxValue) {
      const { min, max } = filter.currentMinMaxValue;
      if (filter.type === FiltersTypeEnum.Date) {
        return `${moment(min).format('MM-DD-YYYY')} - ${moment(max).format('MM-DD-YYYY')}`;
      }
      return `${min} - ${max}`;
    }
    if (filter.stringValue) {
      return this.truncateTooltip(filter.stringValue);
    }
    if (filter.isAllChecked) {
      return 'All';
    }
    if (filter.discreteValues) {
      let discreteValueNames = filter.discreteValues.map(({ name }) => name);
      if (filter.isExcluded) {
        const item = allFilters.find(({ name }) => name === filter.name);
        if (item) {
          discreteValueNames = item.discreteValues
            .filter(({ name }) => !discreteValueNames.includes(name))
            .map(({ name }) => name);
        }
      }
      return this.truncateTooltip(discreteValueNames.join(', '));
    }
    return '';
  }

  private truncateTooltip(tooltip: string, maxLength = 500): string {
    return tooltip.length < maxLength ? tooltip : `${tooltip.slice(0, maxLength)}...`;
  }
}
