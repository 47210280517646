// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';

// Ngxs tools, only for Development mode
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

export const environment: IEnvironment = {
  // eslint-disable-next-line global-require
  version: require('../../package.json').version,
  production: false,
  rootUrl: '',
  // dataAPIUrl: 'http://127.0.0.1:5001/collagia-dev/us-central1', // functions started via data-api project locally
  dataAPIUrl: 'https://us-central1-collagia-dev.cloudfunctions.net', // functions deployed from data-api to Firebase, see here: https://console.firebase.google.com/project/collagia-dev/functions
  plugins: [
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      collapsed: false,
      disabled: true
    })
  ],
  leviteApiUrl: '',
  damApiUrl: 'https://damrelay-5exyq3tsnq-uc.a.run.app',
  firebase: {
    apiKey: 'AIzaSyCVKHbCMWTOhb0mYnUL3r9g2ACO_9x3-tE',
    authDomain: 'collagia-dev.firebaseapp.com',
    projectId: 'collagia-dev',
    storageBucket: 'collagia-dev.appspot.com',
    messagingSenderId: '968134743872',
    appId: '1:968134743872:web:4274782950ee815ce5cca5'
  },
  sentry: null
  // Note: if need to see or test how Sentry works in dev environment, uncomment rows below:
  //   {
  //   dsn: 'https://34f46d4e527c498ca127518239e70ecc@o1364497.ingest.sentry.io/6658747',
  //   tracingOrigins: ['localhost', 'https://yourserver.io/api'],
  //   tracesSampleRate: 1.0
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
