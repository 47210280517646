import { Inject, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import gsap from 'gsap/all';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import { ImageContainer } from '../../custom-pixi-containers/image-container';
import { MatrixToolEnum } from '@app/state/tools/tools.model';
import { FrameContainer } from '../../custom-pixi-containers/frame-container';
import { LOW_IMAGE_SIZE_RESOURCE } from './base-matrix';
import { DataService } from '../data/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDimensions, IFrameStateModel, IImageStateModel, IPosition } from '@app/models/workspace-list.model';
import { LookupTableService } from '@app/components/lookup-table';
import { IImageData } from '@app/models/image.model';
import { IHoverInfoFieldModel, IMetricsCustomFormattings } from '@app/models/study-setting.model';
import { CursorStyles } from '@app/shared/enums/cursor-styles.enum';
import { StudyState } from '@app/state/study/study.state';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { ValidMatrix } from '../../state/tools/tools.model';
import { WINDOW } from '../../shared/utils/window';
import { Viewport } from 'pixi-viewport';
import { IWorkspaceTools, IZoomSettings } from '../../models/workspace-list.model';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import { IPresentationState } from '@app/models/presentation.models';
import { FriendlyNameService } from '../friendly-name/friendly-name.service';
import * as PIXI from 'pixi.js';
import { FiltersService } from '../filters/filters.service';
import { BaseMatrix } from './base-matrix';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { Container, Renderer } from 'pixi.js';

export class FreestyleMatrix extends BaseMatrix {
  private images: { [key: string]: Partial<IImageStateModel> };
  private imageData: { [id: string]: IImageData } = {};
  private imageMatrixWidth;
  private imageMatrixHeight;
  private matrixDimsOnMatrixUpdate: IDimensions;

  private canvasMouseDownPosition: IPosition;

  private productsToShowTemp: Array<IImageData> = [];

  private imageFadeInAnimationDuration = 0.1;
  private imageFadeInAnimationMaxDelay = 0.02;
  private imageFadeInTotalAnimationMaxDuration = 1.5;

  constructor(
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override stage: Container,
    protected override renderer: Renderer,
    protected override viewport: Viewport,
    protected override imageInfoOverlayRef: ImageInfoOverlay,
    protected override store: Store,
    protected override snackBar: MatSnackBar,
    protected override resourceManagerService: ResourceManagerService,
    protected override dataService: DataService,
    protected override lookupTable: LookupTableService,
    protected override appLoaderService: AppLoaderService,
    protected override dropShadowService: DropShadowService,
    protected override friendlyNameService: FriendlyNameService,
    protected override filterService: FiltersService,
    protected override viewContainerRef: ViewContainerRef
  ) {
    super(
      window,
      stage,
      renderer,
      viewport,
      imageInfoOverlayRef,
      store,
      snackBar,
      resourceManagerService,
      dataService,
      lookupTable,
      appLoaderService,
      dropShadowService,
      friendlyNameService,
      filterService,
      viewContainerRef
    );
    this.setupMatrixHoverListeners();
    this.selectedToolsStartStopFunctions[MatrixToolEnum.Cursor] = {
      start: this.startMultiSelectRectTool.bind(this),
      stop: this.endMultiSelectRectTool.bind(this)
    };
    this.setSelectedTool(MatrixToolEnum.Cursor);
  }

  public override setFrameFeatureName(name: string) {
    this.frameFeatureName = name;
    if (this.selectedTool === MatrixToolEnum.Frame) {
      document.body.style.cursor = this.frameFeatureName ? CursorStyles.Crosshair : CursorStyles.NotAllowed;
    }
  }

  public setDisplayData(data: Array<any>, restore: boolean) {
    this.isUpdating.next(true);
    this.displayData = data;
    this.checkForPlaceholderMode(this.displayData.map(d => d.prodId));
    if (!restore && this.frames.length > 0 && data.length > 0) {
      this.updateMatrix();
      return;
    }
    this.clearMatrix();
    if (restore) {
      this.restoreMatrix();
    } else {
      this.createMatrix();
    }
  }

  protected createMatrix() {
    this.clearCompass.next(false);
    this.updateBaseRefs();
    this.calculateResolution();
    this.calculateImagePositions();
    this.updateImageMatrixAndImageRefs(false);
    setTimeout(() => {
      this.loadProductImages(false, true);
    });
  }

  private calculateImagePositions() {
    if (this.productsToShow.length > 10) {
      this.calculateImagePositionsInSpiral();
    } else {
      this.calculateImagePositionInSubwayTiles();
    }
  }

  protected restoreMatrix() {
    this.clearCompass.next(false);
    this.updateBaseRefs();
    this.restoreMatrixDimensions();
    this.renderNewMatrix.next({ restore: true });
    this.restoreImages();
    this.restoreFrames();
    setTimeout(() => {
      this.loadProductImages(true);
    });
    this.render();
  }

  private updateMatrix() {
    this.clearCompass.next(false);
    this.saveFramePosFromCenter();
    this.matrixDimsOnMatrixUpdate = {
      width: this.matrix.width / this.resolution,
      height: this.matrix.height / this.resolution
    };
    if (this.resolution > 1)
      this.frames?.forEach(frame =>
        frame.resizeWithFactor(1 / this.resolution, this.resourceManagerService, this.dropShadowService)
      );
    this.updateBaseRefs();
    this.productsToShowTemp = [...this.productsToShow];
    this.calculateResolution();
    this.filterProductsToShow();
    this.calculateImagePositions();
    this.updateImageMatrixAndImageRefs(false);
    this.restoreFramePosFromCenter();
    this.filterFrames();
    if (this.frames.length > 0) this.updateFramesOnZoom();
    setTimeout(() => {
      this.loadProductImages(false, true);
    });
    this.matrixDimsOnMatrixUpdate = null;
  }

  protected override updateBaseRefs() {
    this.productsToShow = [...this.displayData];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    super.updateBaseRefs();
  }

  private updateImageMatrixAndImageRefs(restore: boolean) {
    this.imageMatrix.removeChildren();
    this.updateMatrixDimensions();
    this.renderNewMatrix.next({ restore });
    this.deselectAllImages();
    this.imageRefs = [];
    this.imageRefsForHighlight = [];
    this.selectedImages = [];
  }

  public override clearMatrix(removeContainers = true) {
    super.clearMatrix(removeContainers);
    this.deselectAllImages();
    if (removeContainers) {
      this.frames.forEach(frame => this.clearFrameAndSubFrames(frame));
      this.frames = [];
    } else {
      this.frames.forEach(frame => this.clearFrameAndSubFramesHtmlElements(frame));
    }
    this.productsToShowTemp = [];
    this.selectedImages = [];
    this.selectedProducts$.next(new Set());
    this.frameTitles = {};
    this.allFrames = [];
    this.frameCollidedOnImageDrag = null;
    this.updateMatrixDimensions();

    this.render();
  }

  private saveFramePosFromCenter() {
    for (const frame of this.frames) {
      frame.setPositionFromWorldCenter(
        (this.viewport.worldWidth / 2 - frame.x) / this.resolution,
        (this.viewport.worldHeight / 2 - frame.y) / this.resolution
      );
      this.matrix.removeChild(frame);
    }
  }
  private restoreFramePosFromCenter() {
    for (const frame of this.frames) {
      const pos = frame.getPositionFromWorldCenter();
      frame.position.set(this.viewport.worldWidth / 2 - pos.x, this.viewport.worldHeight / 2 - pos.y);
      this.matrix.addChild(frame);
      frame.updateFrameTitlePosition();
    }
  }

  /**
   * removes filtered-out images from frames
   */
  private filterFrames() {
    for (const frame of this.allFrames) {
      for (let i = 0; i < frame.getImages().length; i++) {
        if (this.productsToShowTemp.findIndex(product => product.prodId === frame.getImages()[i].getId()) === -1) {
          frame.removeChild(frame.getImages()[i]);
          frame.getImages().splice(i, 1);
          i--;
        }
      }
      frame.makeSquareGrid(false);
      frame.gridParent();
    }
  }

  /**
   * adds to the matrix only those images which are not if the frame
   */
  private filterProductsToShow() {
    this.productsToShow = this.productsToShow.filter((product: IImageData) => {
      for (const frame of this.frames) {
        for (const image of frame.getImages()) {
          if (image.getId() === product.prodId) return false;
        }
      }
      return true;
    });
  }

  private loadProductImages(restore: boolean, animate: boolean = false) {
    if (this.loader) this.resourceManagerService.stopLoader(this.loader);
    if (this.isPlaceholderMode) {
      this.onProductLoadComplete(animate, restore);
      return;
    }
    const resources = [
      ...this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE),
      ...this.createLoaderResourcesForFrameImages(LOW_IMAGE_SIZE_RESOURCE)
    ];

    if (resources.length > 0) this.sendLowResImageLoadingStart();

    this.loader = this.resourceManagerService.loadResources(
      resources,
      null,
      null,
      this.onProductLoadComplete.bind(this, animate, restore)
    );
  }

  protected createLoaderResourcesForFrameImages(size): { name: string; url: string }[] {
    const frameImages: ImageContainer[] = [];
    this.frames.forEach(frame => {
      frameImages.push(...frame.getAllImages());
    });
    let resources = frameImages.map((image, i) => {
      return {
        name: `${image.getId()}_${image.getView()}-${size}`,
        url: this.lookupTable.getProductImage(image.getId(), image.getView(), size),
        crossOrigin: true,
        loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
        xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB
      };
    });

    resources = resources.filter((item, i, ar) => ar.findIndex(l => l.name === item.name) === i);
    return resources;
  }

  private refreshCanvasAfterAllImagesLoaded(restore: boolean) {
    if (!restore) {
      this.setCanvasState(true);
    } else {
      this.restoreImagesInFrames();
      this.frames.forEach(f => f.checkUnpublishedChanges(this.store.selectSnapshot(StudyState.getCustomAttributes)));
      this.updateAllFrameHtmlElements();
    }
    this.render();
  }

  private onProductLoadComplete(animate: boolean, restore: boolean) {
    if (this.matrixType !== ValidMatrix.Freestyle) {
      // bugfix: if a few previous Study used other Matrix type, all of them already subscribed to the Product Load Complete
      // so, do nothing if it's not a current matrix
      return;
    }
    // this.clearCompass.next(false);
    this.sendLowResImageLoadingCompleted();
    this.sendDataLoadingCompleted();

    // const imageData = this.store.selectSnapshot(UserDataState.getImageData);
    // if (!imageData) {
    //   this.refreshCanvasAfterAllImagesLoaded(restore);
    //   return;
    // }

    if (this.productsToShow?.length === 0) {
      this.isUpdating.next(false);
      this.refreshCanvasAfterAllImagesLoaded(restore);
    } else {
      if (Object.keys(this.images).length === 0) {
        this.calculateImagePositions(); // bugfix: lost image positions - create new
      }
      const tl = gsap.timeline({
        ease: 'none',
        onUpdate: () => {
          this.render();
        },
        onComplete: () => {
          this.isUpdating.next(false);
        }
      });

      const animationDelay = animate ? this.getImageFadeInAnimationDelay(this.productsToShow.length) : 0;

      this.productsToShow.forEach(product => {
        const imageContainer = this.createImage(
          this.images[product.prodId]?.uid,
          product.prodId,
          product.displayName,
          this.images[product.prodId].position,
          this.imageMatrix,
          this.imageData[product.prodId] || {
            displayName: product.displayName,
            hoverInfo: null
          },
          this.lookupTable.getImagePlaceholder()
        );
        this.addImageEventListenersAll(imageContainer);

        /**
         * Last parameter in tl.to function controls the start of animation relative to other animations
         * By default animation starts at the end of previous animation
         * < sign means that animation will start at the begining of previous animation
         * <+=0.2 means that animatoin will start with delay of 200ms after begining of previous animatoin
         * causing images to pop with slight delay
         */
        tl.to(
          imageContainer,
          {
            duration: animate ? this.imageFadeInAnimationDuration : 0,
            alpha: 1
          },
          `<+=${animate ? animationDelay : 0}`
        );
      });

      this.imageRefsForHighlight = [...this.imageRefs];

      this.refreshCanvasAfterAllImagesLoaded(restore);
    }

    const frameImages: ImageContainer[] = [];
    this.frames.forEach(frame => {
      frameImages.push(...frame.getAllImages());
    });
    frameImages.forEach(image => image.updateSprite(this.resourceManagerService, this.isPlaceholderMode));

    this.imageRefsForHighlight.push(...frameImages);

    if (this.productsToShowTemp && this.productsToShowTemp.length > this.productsToShow.length)
      this.productsToShow = [...this.productsToShowTemp];

    if (restore) this.onZoomChange(this.zoomFactor, true);

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    }
  }

  private calculateImagePositionInSubwayTiles() {
    const numImages = this.productsToShow.length;
    const w = this.imageDesiredSizes.width;
    const h = this.imageDesiredSizes.height;
    const d = 100;

    const numRows = Math.floor(Math.sqrt(numImages));
    let numImagesOnRow = Math.round(numImages / numRows);

    this.images = {};
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    let col = 0;
    let row = 0;
    let offset = (w + d) / 2;
    let numPosCalculated = 0;

    for (const product of this.productsToShow) {
      const x = col * (w + d) + offset;
      const y = row * (h + d);
      this.images[product.prodId] = {
        position: { x, y }
      };

      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;

      numPosCalculated++;
      col++;

      if (col % numImagesOnRow === 0) {
        col = 0;
        row++;
        numImagesOnRow += row % 2 === 1 ? 1 : -1;
        offset = row % 2 === 1 ? 0 : (w + d) / 2;
        if (numImages - numPosCalculated < numImagesOnRow) {
          const numImagesOnLastRow = numImages - numPosCalculated;
          offset += Math.floor((numImagesOnRow - numImagesOnLastRow) / 2) * (w + d);
        }
      }
    }

    this.imageMatrixWidth = maxX - minX + w;
    this.imageMatrixHeight = maxY - minY + h;

    for (const product of this.productsToShow) {
      this.images[product.prodId].position.x -= minX;
      this.images[product.prodId].position.x += w / 2;
      this.images[product.prodId].position.y += h / 2;
    }
  }

  private calculateImagePositionsInSpiral() {
    const c = this.imageDesiredSizes.width;
    const index = this.productsToShow.length - 1;
    const offset = c * Math.sqrt(index + 1);

    const viewportRatio = {
      width: window.innerWidth - 2 * this.imageMatrixPaddings.leftRight,
      height: window.innerHeight - this.imageMatrixPaddings.top - this.imageMatrixPaddings.bottom
    };

    const ratio = viewportRatio.width / viewportRatio.height;
    const height = 2 * offset * Math.sqrt(1 / ratio);
    let i = 0;
    let images = 0;
    this.images = {};
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    switch (this.productsToShow.length) {
      case 1:
        minX = 0;
        minY = 0;
        maxX = 0;
        maxY = 0;
        this.images[this.productsToShow[0].prodId] = { position: { x: 0, y: 0 } };
        break;
      case 2:
        minX = -this.imageDesiredSizes.width;
        minY = 0;
        maxX = this.imageDesiredSizes.width;
        maxY = 0;
        this.images[this.productsToShow[0].prodId] = { position: { x: minX, y: 0 } };
        this.images[this.productsToShow[1].prodId] = { position: { x: maxX, y: 0 } };
        break;
      case 3:
        minX = -1.5 * this.imageDesiredSizes.width;
        minY = -this.imageDesiredSizes.height;
        maxX = 1.5 * this.imageDesiredSizes.width;
        maxY = this.imageDesiredSizes.height;
        this.images[this.productsToShow[0].prodId] = { position: { x: 0, y: minY } };
        this.images[this.productsToShow[1].prodId] = { position: { x: maxX, y: maxY } };
        this.images[this.productsToShow[2].prodId] = { position: { x: minX, y: maxY } };
        break;
      default:
        while (images < this.productsToShow.length) {
          const a = (i + 1) * 2.399963;
          const r = c * Math.sqrt(i + 1);
          const x = r * Math.cos(a);
          const y = r * Math.sin(a);

          if (
            y > -(height - this.imageDesiredSizes.height) / 2 &&
            y < (height - this.imageDesiredSizes.height) / 2 &&
            !this.isPositionUnderFrame(x, y)
          ) {
            this.images[this.productsToShow[images].prodId] = { position: { x, y } };
            images++;
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
          }
          i++;
        }
    }

    this.imageMatrixWidth = maxX - minX + this.imageDesiredSizes.width;
    this.imageMatrixHeight = maxY - minY + this.imageDesiredSizes.height;

    for (const product of this.productsToShow) {
      this.images[product.prodId].position.x += this.imageMatrixWidth / 2;
      this.images[product.prodId].position.y += this.imageMatrixHeight / 2;
    }
  }

  private isPositionUnderFrame(x, y) {
    for (const frame of this.frames) {
      const pos = frame.getPositionFromWorldCenter();
      const frameX = -pos.x;
      const frameY = -pos.y;

      if (
        x > frameX - this.imageDesiredSizes.width / 2 &&
        x < frameX + frame.width + this.imageDesiredSizes.width / 2 &&
        y > frameY - this.imageDesiredSizes.height / 2 &&
        y < frameY + frame.height + this.imageDesiredSizes.height / 2
      ) {
        return true;
      }
    }
    return false;
  }

  restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools) {
    const scaleDifference = Math.min(
      this.viewport.screenWidth / oldState.windowDimensions.width,
      this.viewport.screenHeight / oldState.windowDimensions.height
    );

    const scale = oldState.viewportScale * scaleDifference;
    const center = oldState.viewportCenter;

    zoom.baseScale = scale * 3;
    viewPort.scale.set(Math.abs(scale));
    viewPort.moveCenter(center.x, center.y);
  }

  /**
   * Calculates bounds and resizes canvas/matrix in a way to ensure
   * images are horizontally centered and do not go behind any UI component
   */
  private updateMatrixDimensions() {
    // Minimum dimensions of image matrix is used to force minimum dimensions of viewport.
    // porpose of this is to visually mintain maximum size of images on initial render.
    // sizes are calculated from actual image width and height
    // so if image sizes are different this will still work
    const imageMatrixMinWidth = 10 * this.imageDesiredSizes.width;
    const imageMatrixMinHeight = 6 * this.imageDesiredSizes.height;
    let leftByMinWidth = 0;
    let topByMinHeight = 0;
    if (this.imageMatrixWidth < imageMatrixMinWidth) {
      leftByMinWidth = (imageMatrixMinWidth - this.imageMatrixWidth) / 2;
      this.imageMatrixWidth = imageMatrixMinWidth;
    }
    if (this.imageMatrixHeight < imageMatrixMinHeight) {
      topByMinHeight = (imageMatrixMinHeight - this.imageMatrixHeight) / 2;
      this.imageMatrixHeight = imageMatrixMinHeight;
    }
    // scales are calculated with size of image matrix in world pixels
    // and with approximate size it should visually take on screen
    let scaleW = this.imageMatrixWidth / (window.innerWidth - 2 * this.imageMatrixPaddings.leftRight);
    scaleW = Math.round((scaleW + Number.EPSILON) * 100) / 100;
    let scaleH =
      this.imageMatrixHeight / (window.innerHeight - this.imageMatrixPaddings.top - this.imageMatrixPaddings.bottom);
    scaleH = Math.round((scaleH + Number.EPSILON) * 100) / 100;
    const scale = scaleW > scaleH ? scaleW : scaleH;

    // scale is used to calculate dimensions of matrix (canvas) and position of image matrix
    let width = window.innerWidth * scale * 3;
    let height = window.innerHeight * scale * 3;

    if (this.matrixDimsOnMatrixUpdate?.width && width < this.matrixDimsOnMatrixUpdate.width)
      width = this.matrixDimsOnMatrixUpdate.width;
    if (this.matrixDimsOnMatrixUpdate?.height && height < this.matrixDimsOnMatrixUpdate.height)
      height = this.matrixDimsOnMatrixUpdate.height;

    const left = (width - this.imageMatrixWidth) / 2 + leftByMinWidth;
    const top = (height - this.imageMatrixHeight) / 2 + topByMinHeight;

    this.matrixResizerSpriteRef.width = width;
    this.matrixResizerSpriteRef.height = height;

    this.imageMatrix.position.set(left, top);
  }

  private restoreMatrixDimensions() {
    this.matrixResizerSpriteRef.width = this.matrixState.matrix.dimensions.width;
    this.matrixResizerSpriteRef.height = this.matrixState.matrix.dimensions.height;
    this.imageMatrixWidth = this.matrixState.imageMatrix.dimensions.width;
    this.imageMatrixHeight = this.matrixState.imageMatrix.dimensions.height;
    this.imageMatrix.width = this.matrixState.imageMatrix.dimensions.width;
    this.imageMatrix.height = this.matrixState.imageMatrix.dimensions.height;
    this.imageMatrix.position.set(this.matrixState.imageMatrix.position.x, this.matrixState.imageMatrix.position.y);
  }

  private restoreImages() {
    const images = [...this.matrixState.imageMatrix.images];
    this.matrixState.frames.forEach(frameModel => {
      images.push(...this.getAllFrameImages(frameModel));
    });
    this.images = {};
    images.forEach(image => {
      this.images[image.id] = { ...image };
      this.imageViewOverridesWithId[image.id] = image.view;
    });
  }

  private restoreFrames() {
    this.matrixState.frames.forEach((model: IFrameStateModel) => {
      const frame = this.restoreFrame(model);
      this.frames.push(frame);
      this.matrix.addChild(frame);
    });
  }

  private restoreImagesInFrames() {
    this.matrixState.frames.forEach((model: IFrameStateModel) => {
      const frameContainer = this.frames.find(item => item.getId() === model.id);
      if (frameContainer) {
        this.restoreImagesInFrame(model, frameContainer);
        frameContainer.updateFrameTitlePosition();
      }
    });
  }

  private getAllFrameImages(frame: Partial<IFrameStateModel>): Array<IImageStateModel> {
    const images = [...frame.images];
    frame.frames.forEach(frameModel => {
      images.push(...this.getAllFrameImages(frameModel));
    });
    return images;
  }

  protected updateImageView(name) {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    const view = name.slice(name.lastIndexOf('_') + 1, name.lastIndexOf('-'));
    let image = this.imageRefs.find(imageCont => imageCont.getId() === prodId);

    if (!image) {
      for (const frame of this.allFrames) {
        image = frame.getImages().find(imageCont => imageCont.getId() === prodId);
        if (image) break;
      }
    }
    if (!image) return;
    image.updateView(this.resourceManagerService, view, this.render.bind(this));
    this.render();
  }

  public onIndividualImageViewChange(image: ImageContainer) {
    this.updateImageInCanvasState(image);
    this.imageViewOverridesWithId[image.getId()] = image.getView();
  }

  private getImageFadeInAnimationDelay(numImages: number): number {
    const animationDelayWithMaxDuration =
      (this.imageFadeInTotalAnimationMaxDuration - this.imageFadeInAnimationDuration) / numImages;
    return parseFloat(Math.min(this.imageFadeInAnimationMaxDelay, animationDelayWithMaxDuration).toFixed(5));
  }

  public override setControlCommandKeyState(active: boolean) {
    this.multiHightlightActive = active;
    if (this.selectedTool !== MatrixToolEnum.Highlighter) {
      this.multiSelectActive = active;
      if (active && this.imageInfoShown) {
        clearTimeout(this.imageHoverTimeout);
        this.imageInfoShown = false;
        this.imageInfoOverlayRef.hide(false);
      }
    }
  }

  protected override onImageClick(image: ImageContainer) {
    super.onImageClick(image);
    if (this.selectedTool !== MatrixToolEnum.Highlighter) {
      this.onImageSelect(image);
    }
  }

  public override onResolutionChange(zoomFactor, viewportBaseScale) {
    this.matrix.position.set(0, 0);
    this.zoomFactor = zoomFactor;
    this.updateBaseSizes(viewportBaseScale);
    if (this.frames.length > 0) this.updateFramesOnZoom();
  }

  private updateFramesOnZoom() {
    console.log('>> ManualHarvest.updateFramesOnZoom');

    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    this.frameMarginTop = 30 / this.viewport.scale.x;
    this.frames.forEach((frame: FrameContainer) => {
      frame.updateOnZoom(this.frameBorderWidth, this.frameMarginTop);
      frame.makeGrid();
      frame.updateFrameTitlePosition();
      frame.updateInfoOverlay();
    });
    this.updateAllFrameHtmlElements();
  }

  public onHarvestModeInit() {}

  public onHarvestModeClose() {
    this.frames.forEach(frame => {
      frame.updateFrameTitlePosition();
    });
  }

  private clearFrameImageData(
    frame: FrameContainer,
    data: IHoverInfoFieldModel[],
    customFormattings: IMetricsCustomFormattings
  ) {
    const images = frame.getImages();

    images.forEach(image => {
      const imgData: any = image.getData() || {};
      imgData.hoverInfo = null; // "Calculating..."
      image.setData(imgData);
      if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    });

    if (frame.getInfoShownState()) {
      frame.updateFrameInfoText(data, customFormattings);
      frame.getInfoOverlay().show(false);
    }

    frame.getFrames().forEach(childFrame => {
      this.clearFrameImageData(childFrame, data, customFormattings);
    });
  }

  private updateFrameImageData(frame: FrameContainer, data: IHoverInfoFieldModel[], customFormattings) {
    const images = frame.getImages();

    images.forEach(image => {
      image.setData(this.imageData[image.getId()]);
      if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    });

    frame.getFrames().forEach(childFrame => {
      this.updateFrameImageData(childFrame, data, customFormattings);
    });

    // if Frame active, show updated Hover Info
    if (frame.getInfoShownState()) {
      frame.updateFrameInfoText(data, customFormattings);
      frame.getInfoOverlay().show(false);
    }
  }

  /**
   * @override
   */
  public updateImageData(data: IHoverInfoFieldModel[]) {
    // --> console.log('!!! Freestyle.updateImageData > getImageAggregatedHoverInfo', data);
    this.dataService.stopActiveImageAggregateRequests(); // stop all workers which not completed yet

    const customFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    // 1. cleanup Image data for free images and Frames
    this.imageRefs.forEach(image => {
      const imgData: any = image.getData() || {};
      imgData.hoverInfo = null; // "Calculating..."
      image.setData(imgData);
      if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
    });
    for (const frame of this.allFrames) {
      this.clearFrameImageData(frame, data, customFormattings);
    }

    // 2. calculate new Hover Info
    const filteredRows = this.store.selectSnapshot(UserDataState.getFilteredRows);
    this.dataService.getImageAggregatedHoverInfo(filteredRows, null, imageData => {
      // 3. populate Hover Info data into each image
      this.imageData = imageData;

      if (this.imageRefs.length > 0) {
        this.imageRefs.forEach(image => {
          image.setData(imageData[image.getId()]);
          if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
            this.imageInfoOverlayRef.updateText(image);
            this.imageInfoOverlayRef.show();
          }
        });
      }

      for (const frame of this.allFrames) {
        this.updateFrameImageData(frame, data, customFormattings);
      }
      // --> console.log('!!! Freestyle.updateImageData > getImageAggregatedHoverInfo > done', data);
    });
  }

  /**
   * @override
   */
  public override onViewportMove() {
    super.onViewportMove();
    this.frames.forEach((frame: FrameContainer) => {
      frame.updateFrameTitlePosition();
      frame.updateInfoOverlay();
    });
  }

  /**
   * @Override
   */
  public override onZoomChange(zoomFactor, instantResizeMatrix: boolean = false): void {
    super.onZoomChange(zoomFactor, instantResizeMatrix);

    this.hideImagePopper();
    this.contextMenuClosed$.next();
    this.frameBorderWidth = this.frameBorderBaseWidth / this.zoomFactor;
    if (this.frames.length > 0) this.updateFramesOnZoom();
  }

  public onCanvasMouseDown(event: MouseEvent) {
    this.canvasMouseDownPosition = {
      x: event.clientX,
      y: event.clientY
    };
  }

  public onCanvasMouseUp(event: MouseEvent) {
    if (
      this.canvasMouseDownPosition &&
      Math.abs(event.clientX - this.canvasMouseDownPosition.x) <= 1 &&
      Math.abs(event.clientY - this.canvasMouseDownPosition.y) <= 1
    ) {
      this.onCanvasClick(event);
    }
  }

  public getImagePosInWorld(image: ImageContainer) {
    if (image.parent instanceof FrameContainer) {
      const framePos = image.parent.getCoordinatesOnMatrix();
      return {
        x: image.x + framePos.x,
        y: image.y + framePos.y
      };
    }
    return {
      x: image.x + this.imageMatrix.x,
      y: image.y + this.imageMatrix.y
    };
  }

  public getStateForPresentationMode(): IPresentationState {
    return {
      frames: this.frames.map(frame => this.getFramePresentationState(frame)),
      images: this.imageRefs.map(image => this.getImagePresentationState(image)),
      groupsByLevel: []
    };
  }

  public getAllFrameBoundsInWorld() {
    return this.frames.map(frame => {
      return {
        x: frame.x,
        y: frame.y,
        width: frame.width,
        height: frame.height
      };
    });
  }

  public getAllImageBoundsInWorld() {
    return this.imageRefs.map(image => {
      return {
        x: image.x - this.imageDesiredSizes.width / 2 + this.imageMatrix.x,
        y: image.y - this.imageDesiredSizes.width / 2 + this.imageMatrix.y,
        width: image.width,
        height: image.height
      };
    });
  }
}
