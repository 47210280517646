import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth/auth.service';
import { passwordMatchingValidator } from '@app/utils/custom-validators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  public registerForm: FormGroup;

  public get fullName(): FormControl {
    return this.registerForm.get('fullName') as FormControl;
  }

  public get email(): FormControl {
    return this.registerForm.get('email') as FormControl;
  }

  public get password(): FormControl {
    return this.registerForm.get('password') as FormControl;
  }

  public get confirmPassword(): FormControl {
    return this.registerForm.get('confirmPassword') as FormControl;
  }

  public get agreeTerms(): FormControl {
    return this.registerForm.get('agreeTerms') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {
    this.registerForm = this.formBuilder.group(
      {
        fullName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        agreeTerms: [false, Validators.requiredTrue]
      },
      { validators: passwordMatchingValidator }
    );
  }

  public register(): void {
    // TODO: need to compare password and confirmPassword - both should be equal!
    // TODO: need to implement rules for strong passwords
    const { confirmPassword, ...registerData } = this.registerForm.value;
    this.authService.register(registerData);
  }
}
