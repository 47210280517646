import { Injectable } from '@angular/core';
import { ICustomAttributes, IStudy, IStudyMetadata } from '@app/state/study/study.model';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Action, Selector, State, StateContext, StateOperator, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { LoadCollage } from '../collage/collage.actions';
import {
  AddFrame,
  AddWorkspace,
  CreateStudy,
  DeleteActiveWorkspace,
  DeleteWorkspace,
  DuplicateWorkspace,
  RemoveFrame,
  RemoveFrameFromDatabase,
  ResetActiveStudy,
  ResetNeedRebuildMatrix,
  ResetParetoGroupBoundIndexes,
  SetActiveStudy,
  SetActiveStudyName,
  SetActiveWorkspaceId,
  SetActiveWorkspaceSelectedView,
  SetAxesXY,
  SetCustomAttributeFilters,
  SetCustomAttributes,
  SetCustomCalculationFilters,
  SetFrameFeatureName,
  SetFriendlyHeaderTitle,
  SetGroupOrientation,
  SetGroups,
  SetHighlightFilters,
  SetHoverInfoConfig,
  SetIsAggregating,
  SetMatrixState,
  SetMatrixType,
  SetMetricsCustomFormattings,
  SetN,
  SetParetoColumnSize,
  SetParetoDetailsBarFixedPosition,
  SetParetoGroupBoundIndexes,
  SetRankAbsolutePlotting,
  SetRankAbsolutePlottingLabelSize,
  SetRankDirection,
  SetRankInfoPanelConfig,
  SetShowImagePercentages,
  SetShowOriginalHeaders,
  SetSpacing,
  SetStudyEdited,
  SetViewport,
  UpdateCustomAttributeFilter,
  UpdateCustomAttributeFilters,
  UpdateCustomCalculationFiltersInWorkspaces,
  UpdateFrame,
  UpdateFrames,
  UpdateFramesAndImages,
  UpdateGroup,
  UpdateImage,
  UpdateImages,
  UpdateMatrixState
} from './study.actions';
import { Timestamp } from '@angular/fire/firestore';
import { FiltersTypeEnum, IFiltersModel } from '@app/models/filters.model';
import { ValidMatrix } from '../tools/tools.model';
import { WorkspaceUtils } from '@app/utils/workspace.utils';
import { RestoreSelectedView, SetToolboxStateByMatrixType } from '../tools/tools.actions';
import { RefreshFilters, ResetUserData } from '../user-data/user-data.actions';
import {
  IAxesShelfModel,
  IFrameStateModel,
  IGroupStateModel,
  IImageMatrixStateModel,
  IImageStateModel,
  IMatrixModel,
  IMatrixStateModel,
  IWorkspace,
  IWorkspaceListState,
  IWorkspaceTools
} from '@app/models/workspace-list.model';
import { StudyUtils } from '@app/utils/study.utils';
import { Router } from '@angular/router';
import { CollageState } from '../collage/collage.state';
import {
  GroupOrientation,
  IHoverInfoFieldModel,
  IMetricsCustomFormattings,
  IRankInfoPanelConfig
} from '@app/models/study-setting.model';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { take } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { FriendlyNameService } from '../../services/friendly-name/friendly-name.service';
import { LookupTableService } from '@app/components/lookup-table';
import { FrameContainer } from '@app/custom-pixi-containers/frame-container';
import { IUpdatedWorkspaceList } from '../../models/workspace-list.model';
import { ExploreBarSettings } from '@app/utils/explore-bar.utils';
import { UserDataState } from '../user-data/user-data.state';

export const WORKSPACE_LIMIT = 4;

@State<IStudy>({
  name: 'activeStudy',
  defaults: null
})
@Injectable()
export class StudyState {
  @Selector([StudyState])
  static getActiveStudyId(state: IStudy): string {
    return state ? state.id : null;
  }

  @Selector([StudyState])
  static getActiveStudyMetadata(state: IStudy): IStudyMetadata {
    return state ? state.metadata : null;
  }

  @Selector([StudyState])
  static getHoverInfoConfig(state: IStudy): Array<IHoverInfoFieldModel> {
    return state.studySettings.hoverInfoConfig;
  }

  @Selector([StudyState])
  static getMetricsCustomFormattings(state: IStudy): IMetricsCustomFormattings {
    return state.studySettings.metricsCustomFormattings;
  }

  @Selector([StudyState])
  static getHeaderTitles(state: IStudy) {
    return state?.friendlyName?.headerTitles;
  }

  @Selector([StudyState])
  static isFriendlyNamesNotEmpty(state: IStudy): boolean {
    return state?.friendlyName?.headerTitles.length > 0;
  }

  @Selector([StudyState])
  static isShowOriginalHeaders(state: IStudy): boolean {
    return state?.friendlyName?.isShowOriginalHeaders;
  }

  @Selector([StudyState])
  static getCustomAttributes(state: IStudy): ICustomAttributes {
    return state.customAttributes;
  }

  @Selector([StudyState])
  static getCustomAttributeFilters(state: IStudy): Array<IFiltersModel> {
    return state.customAttributeFilters;
  }

  @Selector([StudyState])
  static getCustomCalculationFilters(state: IStudy): Array<IFiltersModel> {
    return state.customCalculationFilters;
  }

  @Selector([StudyState, StudyState.getActiveWorkspace])
  static isNeedRebuildMatrix(study: IStudy, activeWorkspace: IWorkspace): boolean {
    return activeWorkspace?.isNeedRebuildMatrix || false;
  }

  @Selector([StudyState, StudyState.getActiveWorkspace])
  static getRankInfoPanelConfig(study: IStudy, activeWorkspace: IWorkspace): IRankInfoPanelConfig {
    return activeWorkspace?.tools?.rankInfoPanelConfig || study.studySettings.rankInfoPanelConfig;
  }

  @Selector([StudyState, StudyState.getActiveWorkspace])
  static getGroupOrientation(study: IStudy, activeWorkspace: IWorkspace): GroupOrientation {
    return activeWorkspace?.tools?.groupOrientation || study.studySettings.groupOrientation;
  }

  @Selector([StudyState, StudyState.getActiveWorkspace])
  static getParetoDetailsBarFixedPosition(study: IStudy, activeWorkspace: IWorkspace): boolean {
    return activeWorkspace?.tools?.paretoDetailsBarFixedPosition;
  }

  @Selector([StudyState, StudyState.getActiveWorkspace])
  static isAggregating(study: IStudy, activeWorkspace: IWorkspace): boolean {
    return activeWorkspace?.isAggregating || false;
  }

  @Selector([StudyState])
  static getWorkspaceEntityState(state: IStudy): IWorkspaceListState {
    return state ? state.workspaceList : null;
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static getWorkspaceIds(state: IWorkspaceListState): Array<string> {
    return state ? state.ids : null;
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static getWorkspaceEntities(state: IWorkspaceListState): { [id: string]: IWorkspace } {
    return state ? state.entities : null;
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static getWorkspaceList(state: IWorkspaceListState): Array<IWorkspace> {
    return state ? state.ids.map((id: string) => state.entities[id]) : null;
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static getActiveWorkspaceId(state: IWorkspaceListState): string {
    return state ? state.activeWorkspaceId : null;
  }

  @Selector([StudyState.getWorkspaceIds])
  static canAddWorkspace(ids: Array<string>): boolean {
    return ids && ids.length < WORKSPACE_LIMIT;
  }

  @Selector([StudyState.getWorkspaceIds])
  static hasWorkspaces(ids: Array<string>): boolean {
    return ids && ids.length > 0;
  }

  @Selector([StudyState.getActiveWorkspace])
  static canChangeMatrixType(workspace: IWorkspace): boolean {
    // Matrix Type can be changed only if current filters are empty (we don't lost any data in Matrix)
    return !(workspace?.axesShelf?.x.length > 0 || workspace?.axesShelf?.y.length > 0);
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static getActiveWorkspace(state: IWorkspaceListState): IWorkspace {
    return state?.activeWorkspaceId ? state.entities[state.activeWorkspaceId] : null;
  }

  @Selector([StudyState.getWorkspaceEntityState])
  static isUpdatingWorkspace(state: IWorkspaceListState): boolean {
    return state?.countUpdatingWorkspace > 0;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getMatrix(workspace: IWorkspace): IMatrixModel {
    return workspace ? workspace.matrix : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getTools(workspace: IWorkspace): IWorkspaceTools {
    return workspace ? workspace.tools : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getImageMatrix(workspace: IWorkspace): IImageMatrixStateModel {
    return workspace ? workspace.imageMatrix : null;
  }

  @Selector([StudyState.getImageMatrix])
  static getImages(imageMatrix: IImageMatrixStateModel): Array<IImageStateModel> {
    return imageMatrix?.images || null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static hasImagesOrFrames(workspace: IWorkspace): boolean {
    return workspace && (workspace.imageMatrix?.images.length > 0 || workspace.frames.length > 0);
  }

  @Selector([StudyState.getActiveWorkspace])
  static getFrames(workspace: IWorkspace): Array<IFrameStateModel> {
    return workspace ? workspace.frames : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getFrameFeatureName(workspace: IWorkspace): string {
    return workspace ? workspace.frameFeatureName : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getFramesTitleFilters(workspace: IWorkspace): Array<IFiltersModel> {
    return workspace ? workspace.framesTitleFilters : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getHighlightFilters(workspace: IWorkspace): Array<IFiltersModel> {
    return workspace ? workspace.highlightFilters : [];
  }

  @Selector([StudyState.isHighlightFiltersActive])
  static isHighlightFiltersActive(workspace: IWorkspace): boolean {
    return workspace ? workspace.highlightFilters?.length > 0 : false;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getMatrixType(workspace: IWorkspace): ValidMatrix {
    return workspace ? workspace.matrixType : null;
  }

  @Selector([StudyState.getTools])
  static getSpacing(tools: IWorkspaceTools): {
    x: number;
    y: number;
  } {
    return tools ? tools.spacing : null;
  }

  @Selector([StudyState.getTools])
  static getN(tools: IWorkspaceTools): number {
    return tools ? tools.n : null;
  }

  @Selector([StudyState.getTools])
  static getRankDirectionTop(tools: IWorkspaceTools): boolean {
    return tools.rankDirection;
  }

  @Selector([StudyState.getTools])
  static getRankAbsolutePlotting(tools: IWorkspaceTools): boolean {
    return tools.rankAbsolutePlotting;
  }

  @Selector([StudyState.getTools])
  static getParetoColumnSize(tools: IWorkspaceTools): number {
    return tools.paretoColumnSize;
  }

  @Selector([StudyState.getTools])
  static getParetoGroupBoundIndexes(tools: IWorkspaceTools): number[] {
    return tools.paretoGroupBoundIndexes || [];
  }

  @Selector([StudyState.getTools])
  static getShowImagePercentages(tools: IWorkspaceTools): boolean {
    return tools.showImagePercentages;
  }

  @Selector([StudyState.getMatrix])
  static getZoom(matrix: IMatrixModel): number {
    return matrix ? matrix.zoom : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getMatrixStateModel(workspace: IWorkspace): IMatrixStateModel {
    if (!workspace) {
      return null;
    }
    const { id, imageMatrix, frames, groups, matrix, tools } = workspace;
    return { id, imageMatrix, frames, groups, matrix, tools };
  }

  @Selector([StudyState.getActiveWorkspace])
  static getMatrixStateModelWithoutTools(workspace: IWorkspace): IMatrixStateModel {
    if (!workspace) {
      return null;
    }
    const { id, imageMatrix, frames, groups, matrix } = workspace;
    return { id, imageMatrix, frames, groups, matrix };
  }

  @Selector([StudyState.getActiveWorkspace])
  static getAxesXY(workspace: IWorkspace): IAxesShelfModel {
    return workspace ? workspace.axesShelf : null;
  }

  @Selector([StudyState.getAxesXY])
  static getAxisX(axesShelf: IAxesShelfModel): Array<IFiltersModel> {
    return axesShelf ? axesShelf.x : null;
  }

  @Selector([StudyState.getAxesXY])
  static getAxisY(axesShelf: IAxesShelfModel): Array<IFiltersModel> {
    return axesShelf ? axesShelf.y : null;
  }

  @Selector([StudyState.getAxesXY])
  static getFiltering(axesShelf: IAxesShelfModel): Array<IFiltersModel> {
    return axesShelf ? axesShelf.filtering : null;
  }

  @Selector([StudyState.getActiveWorkspace])
  static getGroups(workspace: IWorkspace): Array<IGroupStateModel> {
    return workspace ? [...workspace.groups] : null;
  }
  constructor(
    private projectDataService: ProjectDataService,
    private router: Router,
    private store: Store,
    private appLoaderService: AppLoaderService,
    private friendlyNameService: FriendlyNameService,
    private lookupTable: LookupTableService
  ) {}

  private lockWorkspace(context: StateContext<IStudy>) {
    const study = context.getState();
    if (study) {
      const { workspaceList } = study;
      const countUpdatingWorkspace = workspaceList?.countUpdatingWorkspace || 0;
      // --> console.log('lockWorkspace', countUpdatingWorkspace + 1);
      context.setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            countUpdatingWorkspace: countUpdatingWorkspace + 1
          })
        })
      );
    }
  }

  private unlockWorkspace(context: StateContext<IStudy>) {
    const study = context.getState();
    if (study) {
      const { workspaceList } = study;
      const countUpdatingWorkspace = workspaceList?.countUpdatingWorkspace || 0;
      if (countUpdatingWorkspace > 0) {
        // --> console.log('unlockWorkspace', countUpdatingWorkspace - 1);
        context.setState(
          patch<IStudy>({
            workspaceList: patch<IWorkspaceListState>({
              countUpdatingWorkspace: countUpdatingWorkspace - 1
            })
          })
        );
        if (countUpdatingWorkspace === 1) {
          // it's last blocker, so hide Loader
          this.appLoaderService.setShowLoader('workspace', false);
        }
      } else {
        this.appLoaderService.setShowLoader('workspace', false);
      }
    }
  }

  private clearLockedWorkspace(context: StateContext<IStudy>) {
    const study = context.getState();
    if (study) {
      // --> console.log('clearLockedWorkspace');
      context.setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            countUpdatingWorkspace: 0
          })
        })
      );
      this.appLoaderService.setShowLoader('workspace', false);
    }
  }

  private deleteWorkspaceFromWorkspaceList(
    workspaceId: string,
    workspaceList: IWorkspaceListState
  ): IWorkspaceListState {
    const { [workspaceId]: _, ...otherEntities } = workspaceList.entities;
    return {
      ...workspaceList,
      ids: workspaceList.ids.filter(item => item !== workspaceId),
      entities: {
        ...otherEntities
      }
    };
  }

  private updateOldFilters(
    filterList: IFiltersModel[],
    customCalcFilters: IFiltersModel[]
  ): {
    updatedFilters: IFiltersModel[];
    isNeedRebuild: boolean;
  } {
    let isNeedRebuild = false;
    const approvedFilters = [];
    filterList.forEach(filter => {
      if (filter.isCustomCalc) {
        const newFilterVersion = customCalcFilters.find(
          f => f.customCalcField.calcId === filter.customCalcField.calcId
        );
        if (newFilterVersion) {
          let isChangedFilter = false;
          if (
            newFilterVersion.customCalcField.name !== filter.customCalcField.name ||
            newFilterVersion.customCalcField.calcOperation !== filter.customCalcField.calcOperation ||
            newFilterVersion.customCalcField.calcArguments.length !== filter.customCalcField.calcArguments.length
          ) {
            isChangedFilter = true;
          }
          if (!isChangedFilter) {
            newFilterVersion.customCalcField.calcArguments.forEach((arg, index) => {
              if (
                arg.calcOperation !== filter.customCalcField.calcArguments[index].calcOperation ||
                arg.aggregationType !== filter.customCalcField.calcArguments[index].aggregationType
              ) {
                isChangedFilter = true;
              }
            });
          }
          // updated filter:
          const updatedFilter = { ...newFilterVersion };
          updatedFilter.currentMinMaxValue = {
            min: updatedFilter.minMaxValue.min,
            max: updatedFilter.minMaxValue.max
          };
          approvedFilters.push(updatedFilter);
          isNeedRebuild = isNeedRebuild || isChangedFilter;
        } else {
          // Filter was deleted, so, don't use old deleted filter in Workspace
          isNeedRebuild = true;
        }
      } else {
        approvedFilters.push(filter);
      }
    });
    return { updatedFilters: approvedFilters, isNeedRebuild };
  }

  private updateCustomCalculationFiltersInWorkspaceList(
    workspaceList: IWorkspaceListState,
    newCustomCalcFilters: IFiltersModel[]
  ): IUpdatedWorkspaceList {
    const newWorkspaceList = workspaceList;
    const updatedWorkspaces = [];
    Object.keys(workspaceList.entities).forEach(key => {
      const workspace: IWorkspace = workspaceList.entities[key];
      const axisX = this.updateOldFilters(workspace.axesShelf.x, newCustomCalcFilters);
      const axisY = this.updateOldFilters(workspace.axesShelf.y, newCustomCalcFilters);
      if (axisX.isNeedRebuild || axisY.isNeedRebuild) {
        workspace.axesShelf.x = axisX.updatedFilters;
        workspace.axesShelf.y = axisY.updatedFilters;
        workspace.isNeedRebuildMatrix = true;
        updatedWorkspaces.push(workspace);
      }
    });
    return {
      updatedWorkspaces,
      newWorkspaceList
    };
  }

  @Action(CreateStudy)
  private createStudy(context: StateContext<IStudy>, { collage, currentUser }: CreateStudy) {
    const study = StudyUtils.create(collage, currentUser);
    this.projectDataService.createStudy(study).then(() => {
      this.router.navigate(['/build'], { queryParams: { studyId: study.id } });
    });
  }

  @Action(SetActiveStudy)
  private setActiveStudy(context: StateContext<IStudy>, { study }: SetActiveStudy) {
    if (study) {
      const { setState, dispatch } = context;
      const lastViewed = Timestamp.now();
      study = { ...study, lastViewed };
      if (!study.friendlyName) {
        // fix/init old studies which were created without friendlyName fields
        const friendlyName = {
          isShowOriginalHeaders: false,
          headerTitles: []
        };
        study = { ...study, friendlyName };
      }
      setState(study);
      const actions = [];
      const activeCollageId = this.store.selectSnapshot(CollageState.getActiveCollageId);
      if (study.collageId && study.collageId !== activeCollageId) {
        this.lookupTable.reset();
        actions.push(new ResetUserData(), new LoadCollage(study.collageId));
      }
      if (study.workspaceList.ids.length === 0) {
        actions.push(new AddWorkspace(study.id));
      } else if (study.workspaceList.activeWorkspaceId) {
        const workspace = study.workspaceList.entities[study.workspaceList.activeWorkspaceId];
        actions.push(new SetToolboxStateByMatrixType(workspace.matrixType));
      }
      this.clearLockedWorkspace(context);
      dispatch(actions);
      this.projectDataService.updateStudy(study.id, { lastViewed });
    }
  }

  @Action(SetActiveStudyName)
  private setActiveStudyName({ setState, getState }: StateContext<IStudy>, { name }: SetActiveStudyName) {
    const { id } = getState();
    this.projectDataService.updateStudy(id, { 'metadata.name': name } as any).then(() => {
      setState(patch({ metadata: patch({ name }) }));
    });
  }

  @Action(ResetActiveStudy)
  private resetActiveStudy({ setState }: StateContext<IStudy>) {
    setState(null);
  }

  @Action(ResetNeedRebuildMatrix)
  private resetNeedRebuildMatrix(context: StateContext<IStudy>) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      setState(
        patch({
          workspaceList: patch({
            entities: patch({
              [activeWorkspaceId]: patch({ isNeedRebuildMatrix: false })
            })
          })
        })
      );
      this.projectDataService.updateWorkspace(id, activeWorkspaceId, { isNeedRebuildMatrix: false } as any);
    }
  }

  @Action(AddWorkspace)
  private addWorkspace(context: StateContext<IStudy>, { payload }: AddWorkspace) {
    if (context.getState()?.workspaceList.ids.length >= WORKSPACE_LIMIT) {
      throw new Error('Workspaces limit exceeded');
    }
    // --> console.log('addWorkspace start', payload);
    const defaultView = this.store.selectSnapshot(CollageState.getDefaultView);
    const workspace = WorkspaceUtils.create(payload, defaultView);
    context.setState(
      patch({
        workspaceList: patch({
          activeWorkspaceId: workspace.id,
          ids: append([workspace.id]),
          entities: patch({ [workspace.id]: workspace })
        })
      })
    );
  }

  @Action(SetActiveWorkspaceId)
  private setActiveWorkspaceId(context: StateContext<IStudy>, { payload }: SetActiveWorkspaceId) {
    this.appLoaderService.setShowLoader('workspace', true, 'Changing workspace...', 2000);
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { countUpdatingWorkspace } = workspaceList;
    if (countUpdatingWorkspace > 0) {
      return; // stop. previous workspace not completed yet
    }
    Sentry.addBreadcrumb({
      level: 'debug',
      category: 'action',
      message: 'SetActiveWorkspaceId',
      data: { payload }
    });
    const { matrixType, axesShelf, tools } = workspaceList.entities[payload];
    const { activeWorkspaceId, entities } = workspaceList;

    let updatedWorkspaceList = {
      ...workspaceList,
      activeWorkspaceId: payload
    };
    if (!entities[activeWorkspaceId].matrixType) {
      const { [activeWorkspaceId]: _, ...newEntities } = entities;
      updatedWorkspaceList = {
        ...updatedWorkspaceList,
        ids: [...workspaceList.ids.filter(item => item !== activeWorkspaceId)],
        entities: { ...newEntities }
      };
    }
    this.lockWorkspace(context);
    setState(
      patch({
        workspaceList: patch(updatedWorkspaceList)
      })
    );
    context
      .dispatch([
        new SetToolboxStateByMatrixType(matrixType),
        new RefreshFilters(axesShelf),
        new RestoreSelectedView(tools.selectedView)
      ])
      .pipe(take(1))
      .subscribe({
        next: () => {
          // --> console.log('setActiveWorkspaceId done', payload);
        },
        error: err => {
          // --> console.log('setActiveWorkspaceId failed after dispatch bulk actions', payload, err);
        }
      });
    this.projectDataService
      .updateStudy(id, {
        'workspaceList.activeWorkspaceId': payload
      } as any)
      .then(
        () => {
          // --> console.log('setActiveWorkspaceId saved, preparing environment...', payload);
        },
        error => {
          // --> console.log('setActiveWorkspaceId failed', payload, error);
        }
      )
      .finally(() => {
        this.unlockWorkspace(context);
      });
  }

  @Action(UpdateMatrixState)
  private UpdateMatrixState(context: StateContext<IStudy>, { payload }: UpdateMatrixState) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateMatrixState(id, activeWorkspaceId, payload as any)
        .catch(error => {
          // --> console.error('UpdateMatrixState ERROR', error);
        })
        .then(() => {
          setState(
            patch({
              workspaceList: patch({
                entities: patch({
                  [activeWorkspaceId]: patch(payload)
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetMatrixState)
  private SetMatrixState(context: StateContext<IStudy>, { payload }: SetMatrixState) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('setMatrixStateModel start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .setMatrixState(id, activeWorkspaceId, workspaceList.entities[activeWorkspaceId], payload as any)
        .catch(error => {
          // --> console.error('SetMatrixState ERROR', error);
        })
        .then(() => {
          // --> console.log('setMatrixStateModel saved', activeWorkspaceId);
          setState(
            patch({
              workspaceList: patch({
                entities: patch({
                  [activeWorkspaceId]: patch(payload)
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetMatrixType)
  private setMatrixType(context: StateContext<IStudy>, { payload }: SetMatrixType) {
    // this.appLoaderService.setShowLoader('data', true, 'Selecting matrix...', 2000);
    const { id, workspaceList } = context.getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      const activeWorkspace = workspaceList.entities[activeWorkspaceId];
      const defaultView = this.store.selectSnapshot(CollageState.getDefaultView);
      const axesShelf = {
        x: [],
        y: [],
        filtering: []
      };
      if (ExploreBarSettings[payload].sortingComponent) {
        const primaryMetricField = this.store.selectSnapshot(CollageState.getPrimaryMetricField);
        const isEdited = this.store.selectSnapshot(StudyState.isStudyEdited);
        if (primaryMetricField) {
          axesShelf.x = [
            {
              name: primaryMetricField,
              isAllChecked: true
            }
          ];

          if (!activeWorkspace.matrixType && !isEdited && payload === ValidMatrix.Rank) {
            /**
             * Idea is to find some dimension which will have number of values closes to 5
             * Because we want to render Rank Matrix with Top 5, sorted by 'primaryMetricField' and grouped by found dimension
             */
            const dims = this.store.selectSnapshot(UserDataState.getDimensions);
            let selectedDim = dims.find(dim => dim.discreteValues.length >= 4 && dim.discreteValues.length <= 6);
            if (!selectedDim)
              selectedDim = dims.find(dim => dim.discreteValues.length > 6 && dim.discreteValues.length <= 10);
            if (!selectedDim)
              selectedDim = dims.find(dim => dim.discreteValues.length > 1 && dim.discreteValues.length < 4);
            if (selectedDim) {
              axesShelf.y = [
                {
                  name: selectedDim.name,
                  type: FiltersTypeEnum.Discrete,
                  discreteValues: [...selectedDim.discreteValues],
                  isAllChecked: true
                }
              ];
            }
          }
        }
      }
      if (activeWorkspace.matrixType) {
        const { frames, imageMatrix } = activeWorkspace;
        const { images } = imageMatrix;
        const resetedWorkspace = WorkspaceUtils.reset(activeWorkspace, defaultView);
        const updatedActiveWorkspace = {
          ...resetedWorkspace,
          matrixType: payload
        } as IWorkspace;
        this.lockWorkspace(context);
        this.projectDataService.deleteFramesAndImages(id, activeWorkspaceId, frames, images);
        this.projectDataService
          .setWorkspace(id, updatedActiveWorkspace)
          .then(() => {
            // --> console.log('setMatrixType saved', updatedActiveWorkspace);
            context.dispatch([
              new SetToolboxStateByMatrixType(payload),
              new RestoreSelectedView(defaultView),
              new SetAxesXY(axesShelf),
              new RefreshFilters(axesShelf)
            ]);
            context.setState(
              patch({
                workspaceList: patch({
                  entities: patch({
                    [activeWorkspaceId]: patch(updatedActiveWorkspace)
                  })
                })
              })
            );
          })
          .finally(() => {
            this.unlockWorkspace(context);
          });
      } else {
        this.lockWorkspace(context);
        const { entities, ...workspaceListWithoutEntities } = workspaceList;
        this.projectDataService
          .updateStudy(id, {
            workspaceList: workspaceListWithoutEntities
          } as any)
          .then(() =>
            this.projectDataService.setWorkspace(id, {
              ...entities[activeWorkspaceId],
              matrixType: payload
            })
          )
          .then(() => {
            context.dispatch([
              new SetToolboxStateByMatrixType(payload),
              new RestoreSelectedView(defaultView),
              new SetAxesXY(axesShelf),
              new RefreshFilters(axesShelf)
            ]);
            context.setState(
              patch({
                workspaceList: patch({
                  entities: patch({
                    [activeWorkspaceId]: patch({
                      matrixType: payload
                    })
                  })
                })
              })
            );
          })
          .finally(() => {
            this.unlockWorkspace(context);
          });
      }
    }
  }

  @Action(SetSpacing)
  private setCanvasSpacing(context: StateContext<IStudy>, { payload }: SetSpacing) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('setCanvasSpacing start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.spacing': payload } as any)
        .then(() => {
          // --> console.log('setCanvasSpacing saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      spacing: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetActiveWorkspaceSelectedView)
  private setActiveWorkspaceSelectedView(context: StateContext<IStudy>, { payload }: SetActiveWorkspaceSelectedView) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('setActiveWorkspaceSelectedView start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.selectedView': payload } as any)
        .then(() => {
          // --> console.log('setActiveWorkspaceSelectedView saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      selectedView: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetN)
  private setN(context: StateContext<IStudy>, { payload }: SetN) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('setN start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.n': payload } as any)
        .then(() => {
          // --> console.log('setN saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      n: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetRankDirection)
  private SetRankDirection(context: StateContext<IStudy>, { payload }: SetRankDirection) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('SetRankDirection start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.rankDirection': payload } as any)
        .then(() => {
          // --> console.log('SetRankDirection saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      rankDirection: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetRankAbsolutePlotting)
  private SetRankAbsolutePlotting(context: StateContext<IStudy>, { payload }: SetRankAbsolutePlotting) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('SetRankDirection start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.rankAbsolutePlotting': payload } as any)
        .then(() => {
          // --> console.log('SetRankDirection saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      rankAbsolutePlotting: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetRankAbsolutePlottingLabelSize)
  private SetRankAbsolutePlottingLabelSize(
    context: StateContext<IStudy>,
    { payload }: SetRankAbsolutePlottingLabelSize
  ) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('SetRankDirection start', payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          'tools.rankAbsolutePlottingLabelSize': payload
        } as any)
        .then(() => {
          // --> console.log('SetRankDirection saved', activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      rankAbsolutePlottingLabelSize: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetParetoColumnSize)
  private SetParetoColumnSize(context: StateContext<IStudy>, { payload }: SetParetoColumnSize) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.paretoColumnSize': payload } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      paretoColumnSize: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetParetoGroupBoundIndexes)
  private SetParetoGroupBoundIndexes(context: StateContext<IStudy>, { payload }: SetParetoGroupBoundIndexes) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                tools: patch<IWorkspaceTools>({
                  paretoGroupBoundIndexes: payload
                })
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.paretoGroupBoundIndexes': payload } as any)
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(ResetParetoGroupBoundIndexes)
  private ResetParetoGroupBoundIndexes(context: StateContext<IStudy>) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                tools: patch<IWorkspaceTools>({
                  paretoGroupBoundIndexes: []
                })
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.paretoGroupBoundIndexes': [] } as any)
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetHighlightFilters)
  private SetHighlightFilters(context: StateContext<IStudy>, { payload }: SetHighlightFilters) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                highlightFilters: payload
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { highlightFilters: payload } as any)
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetShowImagePercentages)
  private SetShowImagePercentages(context: StateContext<IStudy>, { payload }: SetShowImagePercentages) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, { 'tools.showImagePercentages': payload } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      showImagePercentages: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetViewport)
  private SetViewport(context: StateContext<IStudy>, { payload }: SetViewport) {
    const { getState, setState } = context;
    const study = getState();
    if (study) {
      const { id, workspaceList } = study;
      const { activeWorkspaceId, countUpdatingWorkspace } = workspaceList;
      const viewportScale = payload.viewport.scale.x * payload.resolution;
      const viewportZoom = payload.viewport.zoom;
      const viewportCenter = {
        x: payload.viewport.center.x / payload.resolution,
        y: payload.viewport.center.y / payload.resolution
      };
      const viewportDimensions = payload.viewport.dimensions;
      const { windowDimensions } = payload.viewport;
      if (
        !countUpdatingWorkspace &&
        activeWorkspaceId !== null &&
        activeWorkspaceId === payload.workspaceId &&
        (workspaceList.entities[activeWorkspaceId].tools.viewportScale !== viewportScale ||
          workspaceList.entities[activeWorkspaceId].tools.viewportZoom.zoomFactor !== viewportZoom.zoomFactor ||
          workspaceList.entities[activeWorkspaceId].tools.viewportCenter.x !== viewportCenter.x ||
          workspaceList.entities[activeWorkspaceId].tools.viewportCenter.y !== viewportCenter.y)
      ) {
        // --> console.log('SetViewport start', countUpdatingWorkspace, activeWorkspaceId);
        this.lockWorkspace(context);
        this.projectDataService
          .updateWorkspace(id, activeWorkspaceId, {
            'tools.viewportScale': viewportScale,
            'tools.viewportZoom': viewportZoom,
            'tools.viewportCenter': viewportCenter,
            'tools.viewportDimensions': viewportDimensions,
            'tools.windowDimensions': windowDimensions
          } as any)
          .then(
            () => {
              // --> console.log('SetViewport saved', activeWorkspaceId);
              setState(
                patch<IStudy>({
                  workspaceList: patch<IWorkspaceListState>({
                    entities: patch<{ [key: string]: IWorkspace }>({
                      [activeWorkspaceId]: patch<IWorkspace>({
                        tools: patch<IWorkspaceTools>({
                          viewportScale,
                          viewportZoom,
                          viewportCenter
                        })
                      })
                    })
                  })
                })
              );
            },
            error => {
              // --> console.log('SetViewport failed', activeWorkspaceId, error);
            }
          )
          .finally(() => {
            this.unlockWorkspace(context);
          });
      }
    }
  }

  @Action(SetFrameFeatureName)
  private SetFrameFeatureName({ getState, setState }: StateContext<IStudy>, { payload }: SetFrameFeatureName) {
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('SetFrameFeatureName start', payload, activeWorkspaceId);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                frameFeatureName: payload
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          frameFeatureName: payload
        })
        .then(() => {
          // --> console.log('SetFrameFeatureName saved', payload, activeWorkspaceId);
        });
    }
  }

  @Action(AddFrame)
  private AddFrame(context: StateContext<IStudy>, { payload }: AddFrame) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      const frames = workspaceList.entities[activeWorkspaceId].frames;
      const images = workspaceList.entities[activeWorkspaceId].imageMatrix?.images || [];
      let updatedFrames = [...frames, payload.frame];
      let updatedImages = null;
      if (payload.created) {
        updatedFrames = updatedFrames.filter(frame => !payload.frame.frames.map(item => item.id).includes(frame.id));
        updatedImages = images.filter(image => !payload.frame.images.map(item => item.uid).includes(image.uid));
      }
      (payload.created
        ? this.projectDataService.addFrame(id, activeWorkspaceId, payload.frame)
        : this.projectDataService.updateFrame(id, activeWorkspaceId, payload.frame.id, {
            position: payload.frame.position
          })
      )
        .then(() =>
          this.projectDataService.updateWorkspace(
            id,
            activeWorkspaceId,
            updatedImages
              ? ({
                  frames: updatedFrames.map(item => item.id),
                  'imageMatrix.images': updatedImages.map(item => item.uid)
                } as any)
              : {
                  frames: updatedFrames.map(item => item.id)
                }
          )
        )
        .catch(error => {
          // --> console.error('AddFrame ERROR', error);
        })
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>(
                    updatedImages
                      ? {
                          frames: updatedFrames,
                          imageMatrix: patch({ images: updatedImages })
                        }
                      : {
                          frames: updatedFrames
                        }
                  )
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(UpdateFramesAndImages)
  private UpdateFramesAndImages(context: StateContext<IStudy>, { payload }: UpdateFramesAndImages) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateFramesAndImages(id, activeWorkspaceId, payload.frames, payload.images)
        .catch(error => {
          // --> console.error('UpdateFramesAndImages ERROR', error);
        })
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    frames: payload.frames,
                    imageMatrix: patch({ images: payload.images })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(UpdateFrames)
  private UpdateFrames(context: StateContext<IStudy>, { payload }: UpdateFrames) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      const frames = workspaceList.entities[activeWorkspaceId].frames.map(frame => {
        const index = payload.findIndex(item => item.id === frame.id);
        return index === -1 ? frame : payload[index];
      });
      this.lockWorkspace(context);
      this.projectDataService
        .updateFrames(id, activeWorkspaceId, payload)
        .catch(error => {
          // --> console.error('UpdateFramesAndImages ERROR', error);
        })
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    frames: frames
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(RemoveFrame)
  private RemoveFrame(context: StateContext<IStudy>, { payload }: RemoveFrame) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      const frames = workspaceList.entities[activeWorkspaceId].frames;
      const updatedFrames = frames.filter(frame => frame.id !== payload);
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          frames: updatedFrames.map(frame => frame.id)
        } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    frames: removeItem<IFrameStateModel>(item => item.id === payload)
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(RemoveFrameFromDatabase)
  private RemoveFrameFromDatabase(context: StateContext<IStudy>, { payload }: RemoveFrameFromDatabase) {
    const { getState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.projectDataService.deleteFrame(id, activeWorkspaceId, payload).then(() => {});
    }
  }

  @Action(UpdateFrame)
  private UpdateFrame(context: StateContext<IStudy>, { payload }: UpdateFrame) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      // update State
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                frames: updateItem<IFrameStateModel>(
                  frame => frame.id === payload.id,
                  patch<IFrameStateModel>(payload.update)
                )
              })
            })
          })
        })
      );
      // send changes to Firebase
      this.projectDataService.updateFrame(id, activeWorkspaceId, payload.id, payload.update).then(() => {}); // don't wait for API response, just send and forgot
      this.unlockWorkspace(context);
    }
  }

  @Action(UpdateImages)
  private UpdateImages(context: StateContext<IStudy>, { payload }: UpdateImages) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('UpdateImageMatrix start', payload, activeWorkspaceId);
      this.lockWorkspace(context);
      this.projectDataService
        .updateImages(id, activeWorkspaceId, payload)
        .then(() => {
          // --> console.log('UpdateImageMatrix saved', payload, activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    imageMatrix: patch<IImageMatrixStateModel>({
                      images: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(UpdateImage)
  private UpdateImage(context: StateContext<IStudy>, { payload }: UpdateImage) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('UpdateImage start', payload, activeWorkspaceId);
      this.lockWorkspace(context);
      this.projectDataService
        .updateImage(id, activeWorkspaceId, payload.uid, payload.update)
        .then(() => {
          // --> console.log('UpdateImage saved', payload, activeWorkspaceId);
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>(
                    payload.parentFrame
                      ? {
                          frames: this.patchFrame(
                            payload.parentFrame,
                            patch<IFrameStateModel>({
                              images: updateItem<IImageStateModel>(image => image.id === payload.uid, payload.update)
                            })
                          )
                        }
                      : {
                          imageMatrix: patch({
                            images: updateItem<IImageStateModel>(image => image.id === payload.uid, payload.update)
                          })
                        }
                  )
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetAxesXY)
  setAxesXY(context: StateContext<IStudy>, { payload }: SetAxesXY) {
    const { id, workspaceList } = context.getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      // --> console.log('setAxesXY start', payload);
      // this.appLoaderService.setShowLoader('data', true, 'Preparing data ...');
      let updateWorkspace = {};
      for (const key of Object.keys(payload)) {
        updateWorkspace = {
          ...updateWorkspace,
          [`axesShelf.${key}`]: payload[key]
        } as any;
      }
      this.lockWorkspace(context);
      context.setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                axesShelf: patch(payload)
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, updateWorkspace)
        .then(() => {
          // --> console.log('setAxesXY saved', payload);
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetShowOriginalHeaders)
  SetShowOriginalHeaders({ setState, getState }: StateContext<IStudy>, { payload }: SetShowOriginalHeaders) {
    const { id, friendlyName } = getState();
    this.friendlyNameService.isShowOriginalHeaders = payload;
    friendlyName.isShowOriginalHeaders = payload;
    setState(patch<IStudy>({ friendlyName: patch({ isShowOriginalHeaders: payload }) }));
    this.projectDataService.updateFriendlyName(id, friendlyName);
  }

  @Action(SetFriendlyHeaderTitle)
  SetFriendlyHeaderTitle({ setState, getState }: StateContext<IStudy>, { payload }: SetFriendlyHeaderTitle) {
    const { id, friendlyName } = getState();
    this.friendlyNameService.setFriendlyName(payload.header, payload.headerTitle);
    friendlyName.headerTitles = this.friendlyNameService.getAllFriendlyNames();
    setState(patch<IStudy>({ friendlyName: patch({ headerTitles: this.friendlyNameService.getAllFriendlyNames() }) }));
    this.projectDataService.updateFriendlyName(id, friendlyName);
  }

  @Action(SetHoverInfoConfig)
  private SetHoverInfoConfig({ setState, getState }: StateContext<IStudy>, { payload }: SetHoverInfoConfig) {
    const { id } = getState();
    setState(patch<IStudy>({ studySettings: patch({ hoverInfoConfig: payload }) }));
    this.projectDataService.updateHoverInfoConfig(id, payload);
  }

  @Action(SetMetricsCustomFormattings)
  private SetMetricsCustomFormattings(
    { setState, getState }: StateContext<IStudy>,
    { payload }: SetMetricsCustomFormattings
  ) {
    const { id } = getState();
    setState(patch<IStudy>({ studySettings: patch({ metricsCustomFormattings: payload }) }));
    this.projectDataService.updateMetricsCustomFormattings(id, payload);
  }

  @Action(SetCustomAttributes)
  private SetCustomAttributes({ setState, getState }: StateContext<IStudy>, { payload }: SetCustomAttributes) {
    const { id } = getState();
    this.projectDataService.updateCustomAttributes(id, payload).then(() => {
      setState(patch<IStudy>({ customAttributes: payload }));
    });
  }

  @Action(SetCustomAttributeFilters)
  private SetCustomAttributeFilters({ patchState }: StateContext<IStudy>, { payload }: SetCustomAttributeFilters) {
    patchState({ customAttributeFilters: payload });
  }

  @Action(UpdateCustomAttributeFilters)
  private UpdateCustomAttributeFilters(
    { patchState }: StateContext<IStudy>,
    { payload }: UpdateCustomAttributeFilters
  ) {
    patchState({ customAttributeFilters: payload });
  }

  @Action(UpdateCustomAttributeFilter)
  private UpdateCustomAttributeFilter(
    { getState, patchState }: StateContext<IStudy>,
    { payload }: UpdateCustomAttributeFilter
  ) {
    const { customAttributeFilters } = getState();
    const filterIndex = customAttributeFilters.findIndex(filter => filter.name === payload.name);
    if (filterIndex >= 0) {
      customAttributeFilters[filterIndex] = payload;
    } else {
      customAttributeFilters.push(payload);
    }
    patchState({ customAttributeFilters: [...customAttributeFilters] });
  }

  @Action(SetCustomCalculationFilters)
  private SetCustomCalculationFilters(
    { setState, getState }: StateContext<IStudy>,
    { payload }: SetCustomCalculationFilters
  ) {
    const { id } = getState();
    setState(patch<IStudy>({ customCalculationFilters: payload }));
    this.projectDataService.updateCustomCalculationFilters(id, payload);
  }

  @Action(SetRankInfoPanelConfig)
  private SetRankInfoPanelConfig(context: StateContext<IStudy>, { payload }: SetRankInfoPanelConfig) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          'tools.rankInfoPanelConfig': payload
        } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      rankInfoPanelConfig: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetGroupOrientation)
  private SetGroupOrientation(context: StateContext<IStudy>, { payload }: SetGroupOrientation) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          'tools.groupOrientation': payload
        } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      groupOrientation: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetParetoDetailsBarFixedPosition)
  private SetParetoDetailsBarOrientation(context: StateContext<IStudy>, { payload }: SetParetoDetailsBarFixedPosition) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          'tools.paretoDetailsBarFixedPosition': payload
        } as any)
        .then(() => {
          setState(
            patch<IStudy>({
              workspaceList: patch<IWorkspaceListState>({
                entities: patch<{ [key: string]: IWorkspace }>({
                  [activeWorkspaceId]: patch<IWorkspace>({
                    tools: patch<IWorkspaceTools>({
                      paretoDetailsBarFixedPosition: payload
                    })
                  })
                })
              })
            })
          );
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(DuplicateWorkspace)
  private duplicateWorkspace(context: StateContext<IStudy>, { workspace }: DuplicateWorkspace) {
    this.appLoaderService.setShowLoader('workspace', true, 'Duplicating workspace...');
    const { id, workspaceList } = context.getState();
    if (workspaceList.ids.length < WORKSPACE_LIMIT) {
      const duplicatedWorkspace = WorkspaceUtils.duplicate(workspace);
      this.lockWorkspace(context);
      context.setState(
        patch({
          workspaceList: patch({
            activeWorkspaceId: duplicatedWorkspace.id,
            ids: append([duplicatedWorkspace.id]),
            entities: patch({ [duplicatedWorkspace.id]: duplicatedWorkspace })
          })
        })
      );
      context
        .dispatch([
          new SetToolboxStateByMatrixType(duplicatedWorkspace.matrixType),
          new RefreshFilters(duplicatedWorkspace.axesShelf),
          new RestoreSelectedView(duplicatedWorkspace.tools.selectedView)
        ])
        .pipe(take(1))
        .subscribe({
          next: () => {
            // --> console.log('setActiveWorkspaceId done');
          },
          error: err => {
            // --> console.log('setActiveWorkspaceId failed after dispatch bulk actions', err);
          }
        });
      this.projectDataService
        .setWorkspace(id, duplicatedWorkspace)
        .catch(error => {
          // --> console.error('DuplicateWorkspace error', error);
        })
        .then(() => {
          return this.projectDataService.updateStudy(id, {
            'workspaceList.activeWorkspaceId': duplicatedWorkspace.id,
            'workspaceList.ids': [...workspaceList.ids, duplicatedWorkspace.id]
          } as any);
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(DeleteWorkspace)
  private deleteWorkspace(context: StateContext<IStudy>, { workspaceId }: DeleteWorkspace) {
    this.appLoaderService.setShowLoader('workspace', true, 'Deleting workspace...');
    const { id, workspaceList } = context.getState();
    const newWorkspaceList = this.deleteWorkspaceFromWorkspaceList(workspaceId, workspaceList);
    this.lockWorkspace(context);
    this.projectDataService
      .deleteWorkspace(id, workspaceId)
      .then(() => {
        return this.projectDataService.updateStudy(id, {
          'workspaceList.ids': newWorkspaceList.ids
        } as any);
      })
      .then(() => {
        context.setState(
          patch({
            workspaceList: newWorkspaceList
          })
        );
      })
      .finally(() => {
        this.unlockWorkspace(context);
      });
  }

  @Action(DeleteActiveWorkspace)
  private deleteActiveWorkspace(context: StateContext<IStudy>) {
    this.appLoaderService.setShowLoader('workspace', true, 'Deleting active workspace...');
    const { id, workspaceList } = context.getState();
    const { activeWorkspaceId } = workspaceList;
    const deletedWorkspaceIndex = workspaceList.ids.findIndex(item => item === activeWorkspaceId);
    let newWorkspaceList: IWorkspaceListState;
    let activeWorkspace: IWorkspace;
    if (workspaceList.ids.length > 1) {
      newWorkspaceList = this.deleteWorkspaceFromWorkspaceList(activeWorkspaceId, workspaceList);
      newWorkspaceList = {
        ...newWorkspaceList,
        activeWorkspaceId: newWorkspaceList.ids[deletedWorkspaceIndex === 0 ? 0 : deletedWorkspaceIndex - 1]
      };
      activeWorkspace = newWorkspaceList.entities[newWorkspaceList.activeWorkspaceId];
      context.setState(
        patch({
          workspaceList: newWorkspaceList
        })
      );
    } else {
      /**
       * When Deleting last workspace, WorkspaceUtils.reset would just reset last workspace
       * resulting in same ids under workspaceList.
       * That was making catching last delete almost impossible.
       * So, needed to completely delete last workspace and create new empty one
       */
      const defaultView = this.store.selectSnapshot(CollageState.getDefaultView);
      const workspace = WorkspaceUtils.create(id, defaultView);
      activeWorkspace = workspace;
      context.setState(
        patch({
          workspaceList: patch({
            activeWorkspaceId: workspace.id,
            ids: [workspace.id],
            entities: { [workspace.id]: workspace }
          })
        })
      );
    }

    context
      .dispatch([
        new RefreshFilters(activeWorkspace.axesShelf),
        new SetToolboxStateByMatrixType(activeWorkspace.matrixType),
        new RestoreSelectedView(activeWorkspace.tools.selectedView)
      ])
      .pipe(take(1))
      .subscribe({
        next: () => {
          // --> console.log('setActiveWorkspaceId done');
        },
        error: err => {
          // --> console.log('setActiveWorkspaceId failed after dispatch bulk actions', err);
        }
      });

    this.lockWorkspace(context);
    this.projectDataService
      .deleteWorkspace(id, activeWorkspaceId)
      .then(() => {
        return this.projectDataService.updateStudy(id, {
          'workspaceList.ids': newWorkspaceList.ids,
          'workspaceList.activeWorkspaceId': newWorkspaceList.activeWorkspaceId
        } as any);
      })
      .finally(() => {
        this.unlockWorkspace(context);
      });
  }

  /**
   * This action calls when CustomCalculationFilters changed, so we need to rebuild all existing Workspaces
   * Here we set flag isNeedRebuildMatrix=true to avoid "restore" matrix. Instead, matrix should be rebuild.
   */
  @Action(UpdateCustomCalculationFiltersInWorkspaces)
  private UpdateCustomCalculationFiltersInWorkspaces(
    context: StateContext<IStudy>,
    { changedFilters }: UpdateCustomCalculationFiltersInWorkspaces
  ) {
    const state = context.getState();
    const { id, workspaceList } = state;
    const { updatedWorkspaces, newWorkspaceList } = this.updateCustomCalculationFiltersInWorkspaceList(
      workspaceList,
      changedFilters
    );
    if (updatedWorkspaces.length > 0) {
      this.lockWorkspace(context);
      const allPromises = updatedWorkspaces.map(workspace => {
        return this.projectDataService.updateWorkspace(id, workspace.id, {
          axesShelf: workspace.axesShelf,
          isNeedRebuildMatrix: true
        });
      });
      Promise.all(allPromises)
        .then(() => {
          context.setState(
            patch({
              workspaceList: newWorkspaceList
            })
          );
          // send actions to refresh Filters and User data for Active Workspace
          const axesXY = workspaceList.entities[workspaceList.activeWorkspaceId].axesShelf;
          context.dispatch([new SetAxesXY({ x: axesXY.x, y: axesXY.y }), new RefreshFilters(null)]);
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  /* Utils */

  private patchFrame(
    frame: FrameContainer,
    patchFrameOperator: StateOperator<IFrameStateModel>
  ): StateOperator<Array<IFrameStateModel>> {
    const patchFramesOperator = updateItem<IFrameStateModel>(item => item.id === frame.getId(), patchFrameOperator);
    return frame.parent instanceof FrameContainer
      ? this.patchFrame(
          frame.parent,
          patch<IFrameStateModel>({
            frames: patchFramesOperator
          })
        )
      : patchFramesOperator;
  }

  @Action(UpdateGroup)
  private UpdateGroup(context: StateContext<IStudy>, { payload }: UpdateGroup) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                groups: updateItem<IGroupStateModel>(
                  item => item.id === payload.id,
                  patch<IGroupStateModel>(payload.update)
                )
              })
            })
          })
        })
      );
      const updatedGroups = workspaceList.entities[activeWorkspaceId].groups.map(group =>
        group.id === payload.id ? { ...group, ...payload.update } : group
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          groups: updatedGroups
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetGroups)
  private SetGroups(context: StateContext<IStudy>, { payload }: SetGroups) {
    const { getState, setState } = context;
    const { id, workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      this.lockWorkspace(context);
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                groups: payload
              })
            })
          })
        })
      );
      this.projectDataService
        .updateWorkspace(id, activeWorkspaceId, {
          groups: payload
        })
        .finally(() => {
          this.unlockWorkspace(context);
        });
    }
  }

  @Action(SetIsAggregating)
  private SetIsAggregating(context: StateContext<IStudy>, { payload }: SetIsAggregating) {
    const { getState, setState } = context;
    const { workspaceList } = getState();
    const { activeWorkspaceId } = workspaceList;
    if (activeWorkspaceId !== null) {
      setState(
        patch<IStudy>({
          workspaceList: patch<IWorkspaceListState>({
            entities: patch<{ [key: string]: IWorkspace }>({
              [activeWorkspaceId]: patch<IWorkspace>({
                isAggregating: payload
              })
            })
          })
        })
      );
    }
  }

  @Action(SetStudyEdited)
  private setStudyEdited({ setState, getState }: StateContext<IStudy>, { payload }: SetStudyEdited) {
    const { id } = getState();
    this.projectDataService.updateStudy(id, { isEdited: payload } as any).then(() => {
      setState(patch({ isEdited: payload }));
    });
  }

  @Selector([StudyState])
  static isStudyEdited(state: IStudy): boolean {
    return state ? state.isEdited : null;
  }
}
