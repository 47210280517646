import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from '@app/components/auth/login/login.component';
import { RegisterComponent } from '@app/components/auth/register/register.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '@app/components/auth/forgot-password-dialog/forgot-password-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { EmailVerificationComponent } from '@app/components/auth/email-verification/email-verification.component';
import { ResetPasswordComponent } from '@app/components/auth/reset-password/reset-password.component';
import { AuthActionComponent } from '@app/components/auth/auth-action/auth-action.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UnverifiedComponent } from '@app/components/auth/unverified/unverified.component';
import { UpdateEmailComponent } from '@app/components/auth/update-email/update-email.component';

@NgModule({
  declarations: [
    AuthenticationComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordDialogComponent,
    AuthActionComponent,
    ResetPasswordComponent,
    EmailVerificationComponent,
    UnverifiedComponent,
    UpdateEmailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    AuthenticationRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule
  ],
  exports: [AuthenticationComponent]
})
export class AuthenticationModule {}
