<div *ngIf="params.enableMenu" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked()">
  <i class="fa {{ params.menuIcon }}"></i>
</div>

<mat-checkbox
  *ngIf="params.isBooleanHeader"
  (change)="$event ? toggleSelectionAll() : null"
  [checked]="isHasSelection() && isAllSelected()"
  [indeterminate]="isHasSelection() && !isAllSelected()"
>
</mat-checkbox>

<div *ngIf="!params.isBooleanHeader" class="customHeaderLabel" [class.title-editable]="params.isEditableTitle">
  <span class="alternative-title" *ngIf="!isEditHeader" (click)="onStartEditHeader($event)">
    {{ params.displayName | friendlyName }}
  </span>
  <span class="alternative-title-edit" *ngIf="params.isEditableTitle && isEditHeader">
    <input
      #alternativeTitleEdit
      class="edit-header"
      (keydown.enter)="$event.preventDefault(); alternativeTitleEdit.blur()"
      (keydown.tab)="$event.preventDefault(); alternativeTitleEdit.blur()"
      (keydown.escape)="$event.preventDefault(); newHeaderText = ''; onStopEditHeader()"
      (keydown)="$event.stopPropagation()"
      [(ngModel)]="newHeaderText"
      (change)="onStopEditHeader()"
    />
    <div
      *ngIf="newHeaderText !== params.displayName"
      class="restore-original-header-icon"
      (click)="onStopEditHeader(true)"
    >
      <svg-icon
        svgClass="icon"
        name="icon-remove-filter"
        [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"
        matTooltip="Restore original header"
        matTooltipPosition="above"
      ></svg-icon>
    </div>
  </span>
</div>

<span *ngIf="params.enableSorting" class="ag-sort-indicator-container" (click)="onSortRequested($event)">
  <!-- span ref="eSortOrder" class="ag-sort-indicator-icon ag-sort-order ag-hidden" aria-hidden="true">1</span -->
  <span *ngIf="sort === 'asc'" ref="eSortAsc" class="ag-sort-indicator-icon ag-sort-ascending-icon">
    <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
  </span>
  <span *ngIf="sort === 'desc'" ref="eSortDesc" class="ag-sort-indicator-icon ag-sort-descending-icon">
    <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
  </span>
  <!-- span *ngIf="ascSort === 'active'" ref="eSortMixed" class="ag-sort-indicator-icon ag-sort-mixed-icon">
    <span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>
  </span -->
  <span *ngIf="sort === null" ref="eSortNone" class="ag-sort-indicator-icon ag-sort-none-icon">
    <span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>
  </span>
</span>
