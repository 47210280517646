import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UploadFileManagerService } from '../../../services/upload-file-manager/upload-file-manager.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadTask } from '@angular/fire/storage';

export interface IUploadedFile {
  file: File;
  fileName: string; // original file name
  fileRef: string; // path + filename = Reference
  isUploaded: boolean;
  uploadedURL: string; // URL to download uploaded file from server
  progress: number; // from 0 to 100 %
  task?: UploadTask; // to allow abort uploading file
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() accept: string = '.png,.jpg,.jpeg';
  @Input() label: string = 'File selection';
  @Input() buttonTitle: string = 'Browse files';
  @Input() messageTitle: string = 'Drag & Drop files here';

  @Input() disabled: boolean = false;
  @Input() isMultiple: boolean = true; // one or multiple file selection
  @Input() isNeedUpload: boolean = true; // need to upload on the server and get URL for uploaded file
  @Input() isDisableDelete: boolean = false; // disable delete button?
  @Input() maxFileLength: number = null; // max file size enabled to upload

  @Output() onSelectFiles = new EventEmitter<Array<IUploadedFile>>();
  @Output() onFileUploaded = new EventEmitter<IUploadedFile>();
  @Output() onDeleteFile = new EventEmitter<IUploadedFile>();

  public files: Array<IUploadedFile>;
  public errorMessages = new Map<string, any>();
  public dragAreaClass: string = 'dragarea';

  private isTouched = false;

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const { files } = event.dataTransfer;
      this.selectFiles(files);
    }
  }

  constructor(
    @Optional() public control: NgControl,
    private uploadFileManager: UploadFileManagerService,
    private snackBar: MatSnackBar
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
    this.errorMessages.set('required', () => `${this.label} is required.`);
    this.errorMessages.set('maxFileLength', () => `File size exceeds the maximum limit ${this.maxFileLength}.`);
    this.errorMessages.set('waitForUpload', () => `File is still uploading.`);
  }

  /* ----------------------------------------
   * Show validation errors
   * -------------------------------------- */
  public get invalid(): boolean {
    if (this.isNeedUpload) {
      // if (this.maxFileLength && this.files?.some(uploadFile => uploadFile.file.size > this.maxFileLength)) {
      //   this.control?.control.setErrors({ maxFileLength: true });
      //   return true;
      // }
      if (this.files?.some(uploadFile => !uploadFile.isUploaded)) {
        this.control?.control.setErrors({ waitForUpload: true });
        return true;
      }
    }
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errors(): Array<string> {
    if (!this.control) {
      return [];
    }
    const { errors } = this.control;
    return Object.keys(errors).map(key =>
      this.errorMessages.has(key) ? this.errorMessages.get(key)() : <string>errors[key] || key
    );
  }

  /* ----------------------------------------
   * Interface ControlValueAccessor
   * -------------------------------------- */
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: Array<IUploadedFile>): void {
    this.files = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /* end of ControlValueAccessor interface */

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.files = null;
    if (this.control) {
      this.control.valueAccessor = null;
    }
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }

  selectFiles(files: FileList) {
    if (!this.isMultiple) {
      this.files = [];
      const file: IUploadedFile = {
        file: files.item(0),
        fileName: null,
        fileRef: null,
        isUploaded: false,
        uploadedURL: null,
        progress: 0
      };
      this.files = [file];
    } else {
      this.files = this.files || [];
      for (let index = 0; index < files.length; index++) {
        this.files.push({
          file: files.item(index),
          fileName: null,
          fileRef: null,
          isUploaded: false,
          uploadedURL: null,
          progress: 0
        } as IUploadedFile);
      }
    }
    if (this.isNeedUpload) {
      this.uploadFiles();
    }
    this.onSelectFiles.emit(this.files);
    this.onChange(this.files?.length ? this.files : null);
  }

  deleteAllFiles() {
    this.files?.forEach((file, index) => {
      this.deleteFile(file, index);
    });
  }

  deleteFileByFileRef(fileRef: string) {
    const index = this.files.findIndex(item => item.fileRef === fileRef);
    this.deleteFile(this.files[index], index);
  }

  deleteFile(file: IUploadedFile, index: number) {
    if (this.isDisableDelete) {
      return;
    }
    this.markAsTouched();
    this.files.splice(index, 1);
    this.onDeleteFile.emit(file);
    if (this.isNeedUpload && file) {
      if (file.isUploaded) {
        this.uploadFileManager.deleteFile(file.fileRef); // already uploaded, ready to delete
      } else {
        file.task.cancel(); // just abort to upload file - delete file during uploading process
        delete file.task;
      }
      // eslint-disable-next-line no-empty
      if (this.invalid) {
      }
    }
    this.onChange(this.files?.length ? this.files : null);
  }

  uploadFiles() {
    this.markAsTouched();
    let index = 0;
    this.files.forEach(uploadFile => {
      if (!uploadFile.isUploaded) {
        this.uploadFile(this.files[index]);
        // this.uploadFilesSimulator(index);
      }
      index++;
    });
    this.onChange(this.files?.length ? this.files : null);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index < this.files?.length) {
        const progressInterval = setInterval(() => {
          if (!this.files[index]) {
            clearInterval(progressInterval); // already deleted
          }
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);

            // here the real service should return URL for uploaded file
            this.files[index].fileRef = 'http://some.com/url/path/to/uploaded/file.extention';
            this.files[index].isUploaded = true;
            this.onFileUploaded.emit(this.files[index]);
            this.onChange(this.files?.length ? this.files : null);

            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  private uploadFile(fileToUpload: IUploadedFile) {
    // Check Maximum file size limitation
    if (this.maxFileLength && fileToUpload.file.size > this.maxFileLength) {
      this.control?.control.setErrors({ maxFileLength: true });
      return;
    }

    // set Error the file not uploaded yet
    this.control?.control.setErrors({ waitForUpload: true });

    // upload the file
    const uploadData = this.uploadFileManager.uploadFile(fileToUpload.file);
    fileToUpload.fileName = uploadData.fileName;
    fileToUpload.fileRef = uploadData.fileRef;
    fileToUpload.task = uploadData.task;
    uploadData.uploadProgress.subscribe(
      value => {
        fileToUpload.progress = value.progress;
        // console.log(next); // status of uploading file (paused, resumed, % of loading)
      },
      error => {
        // --> console.log(error);
        // -- Usually it's error like:
        // -- ERROR FirebaseError: Firebase Storage: User canceled the upload/download. (storage/canceled)
        // -- so, don't show it in the snack Bar
        // this.snackBar.open(error.message, '', {
        //   panelClass: 'error',
        //   duration: 3000
        // });
      },
      () => {
        fileToUpload.progress = 100;
        // file uploaded AND READY for external usage
        // so, get new URL of stored file from our server
        this.uploadFileManager.getUploadedFileURL(uploadData.fileRef).then(url => {
          // this.control?.control.setErrors({}); // Uploaded - remove errors
          fileToUpload.isUploaded = true;
          fileToUpload.uploadedURL = url;
          delete fileToUpload.task;
          this.onFileUploaded.emit(fileToUpload);
          this.onChange(this.files?.length ? this.files : null);
        });
      }
    );
  }

  onChangeSelectFiles($event: any) {
    const files = $event.target.files;
    this.selectFiles(files);
  }
}
