<div class="detect-connection-warning" *ngIf="isActive" [class.connected]="isConnected" [class.visible]="isShow">
  <div class="connect-icon">
    <svg-icon svgClass="icon" name="icon-wifi"></svg-icon>
  </div>
  <div class="message">
    {{ isConnected ? 'Internet connection restored.' : 'You are offline.' }}
  </div>
  <div class="close-icon" (click)="close()">
    <svg-icon svgClass="icon" name="icon-remove-filter" [svgStyle]="{ 'width.px': 12, 'height.px': 12 }"></svg-icon>
  </div>
</div>
