import { Pipe, PipeTransform } from '@angular/core';
import { IFiltersModel, FiltersTypeEnum } from '@app/models/filters.model';

@Pipe({
  name: 'filterValuesSelected'
})
export class FilterValuesSelectedPipe implements PipeTransform {
  transform(filter: IFiltersModel): boolean {
    if (filter?.isAllChecked) {
      return true;
    }
    switch (filter?.type) {
      case FiltersTypeEnum.Continuous:
      case FiltersTypeEnum.Date:
        return !!filter.currentMinMaxValue;
      case FiltersTypeEnum.UniqueString:
      case FiltersTypeEnum.Discrete:
      case FiltersTypeEnum.Boolean:
        return filter.discreteValues?.some(value => value.checked);
      default:
        return false;
    }
  }
}
