import { Pipe, PipeTransform } from '@angular/core';
import { iconsMap } from '../shared/constants/matrix-types';
import { ValidMatrix } from '../state/tools/tools.model';

@Pipe({
  name: 'matrixIconTooltip'
})
export class MatrixIconTooltipPipe implements PipeTransform {
  transform(matrixType: ValidMatrix, isNeedRebuild?: boolean): string {
    let needRebuildText = '';
    if (isNeedRebuild) {
      needRebuildText = ' - Custom Calculation changed, matrix needs rebuild';
    }
    if (!matrixType) {
      return ''; // bugfix: TypeError: Cannot read properties of undefined (reading 'value')
    }
    const icon = iconsMap.get(matrixType);
    if ([ValidMatrix.Rank, ValidMatrix.Freestyle, ValidMatrix.GroupBy, ValidMatrix.Pareto].includes(matrixType)) {
      return icon.name + needRebuildText;
    }
    return `${icon.name}\n Coming soon`;
  }
}
