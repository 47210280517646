import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AppLoaderService, ILoader, ILoaderEvent } from './app-loader.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {
  @Input() isShown: boolean = false; // to show/hide Loader manually
  @Input() subjects: string[] = null; // to catch global events from AppLoaderService
  @Input() isOverlay: boolean = true; // show/hide overlay for parent container
  @Input() title: string = null; // to change title text manually
  @Input() isShowTitle: boolean = false; // show or hide title text
  @Input() showTitleAfterMs: number = 3000; // show title text ANYWAY after delay, if it is still active

  public isShowTitleAfterTimeout: boolean = false;

  private activeLoaders: Array<ILoader> = [];

  constructor(public element: ElementRef, private renderer: Renderer2, private appLoaderService: AppLoaderService) {}

  ngOnInit(): void {
    if (this.subjects) {
      // Loader should check global changes from appLoaderService
      // get status for first time (probably, some Loader subject already visible)
      this.activeLoaders = this.appLoaderService.getLoaders(this.subjects);
      if (this.activeLoaders[0]) {
        this.isShown = true;
        this.title = this.activeLoaders[0].title;
        this.checkTimeoutForActiveLoader(this.activeLoaders[0]);
        this.update();
      }

      // subscribe for Loader changes
      this.appLoaderService.showLoader$
        .pipe(
          distinctUntilChanged((prev, curr) => prev === curr),
          untilDestroyed(this)
        )
        .subscribe((event: ILoaderEvent) => {
          if (this.subjects === null || this.subjects.some(subject => subject === event.subject)) {
            // --> console.log('loader', event);
            const activeLoader = this.activeLoaders.find(loader => loader.subject === event.subject);
            if (activeLoader) {
              if (event.isShow) {
                this.title = event.title;
              } else {
                // delete Loader
                this.activeLoaders = this.activeLoaders.filter(loader => loader.subject !== event.subject);
              }
            } else {
              // add new Loader
              const newLoader = this.appLoaderService.getLoader(event.subject);
              if (newLoader) {
                this.activeLoaders.push(newLoader);
              }
            }
            // show data about last active Loader
            const lastLoader =
              this.activeLoaders?.length > 0 ? this.activeLoaders[this.activeLoaders.length - 1] : null;
            this.isShown = lastLoader !== null;
            this.title = lastLoader ? lastLoader.title : '';

            // check is need to show Title for loader immediately
            if (event.isShowTitleImmediately) {
              this.isShowTitleAfterTimeout = true;
            } else {
              if (this.isShown) {
                this.checkTimeoutForActiveLoader(lastLoader);
              } else {
                this.isShowTitleAfterTimeout = false;
              }
            }
            this.update();
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  private update() {
    if (this.isShown) {
      this.renderer.addClass(this.element.nativeElement, 'visible');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'visible');
    }
  }

  private checkTimeoutForActiveLoader(loader: ILoader) {
    const { startedAt } = loader;
    this.isShowTitleAfterTimeout = false;
    if (new Date().getTime() > startedAt + this.showTitleAfterMs) {
      this.isShowTitleAfterTimeout = true;
    }
  }
}
