import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppEvents } from './app.events';
import { DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE } from './shared/utils/delay-resolver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private isLeavePage = false;
  private isSamePage = false;
  private currentUrl: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationStart) {
            this.isSamePage = !this.compareIsDifferentPage(event.url, this.currentUrl);
            if (!this.isLeavePage) {
              if (this.isSamePage) {
                return;
              }
              this.isLeavePage = true;
              this.sendCustomEvent(AppEvents.LeavePage);
            }
          } else if (this.isLeavePage && event instanceof NavigationEnd) {
            if (this.isSamePage) {
              // when it's the same Page, we need give a timeout for previous event 'LeavePage' to show fade-out
              // because delay-resolver.ts not triggered
              setTimeout(() => {
                // after that, ready to show fade-in animation
                this.sendCustomEvent(AppEvents.ChangedPage);
              }, DEFAULT_TIMEOUT_TO_NAVIGATE_PAGE);
            } else {
              this.sendCustomEvent(AppEvents.ChangedPage);
            }
            this.currentUrl = event.url;
            this.isLeavePage = false;
          }
        })
      )
      .subscribe(() => {});
  }

  private getPageName(url: string): string {
    const end = url?.indexOf('?');
    return end >= 0 ? url.substring(0, end) : url;
  }

  private getPageParams(url: string): string {
    const end = url?.indexOf('?');
    return end >= 0 ? url.substring(end) : '';
  }

  private sendCustomEvent(eventName: string) {
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: {}
    });
    document.dispatchEvent(event);
  }

  private compareIsDifferentPage(newURL: string, oldURL: string): boolean {
    const newURLParams = new URLSearchParams(this.getPageParams(newURL));
    const oldURLParams = new URLSearchParams(this.getPageParams(newURL));
    const isSamePage = this.getPageName(newURL) === this.getPageName(oldURL);
    if (!isSamePage) {
      return true;
    }
    // TODO: change this logic, if we will add new URL pages
    // Currently only Build page should show animation fade-in-out, if url parameters changes (studyId changed)
    if (this.getPageName(newURL) === '/build') {
      // compare params for build page
      let isDifferent = false;
      newURLParams.forEach((value, key) => {
        if (oldURLParams.has(key)) {
          if (key !== 'search' && oldURLParams.get(key) !== value) {
            isDifferent = true;
          }
        } else {
          isDifferent = true;
        }
      });
      return isDifferent;
    }
    return false;
  }
}
