import { Injectable } from '@angular/core';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import {
  LoadImageConnection,
  ResetActiveImageConnection,
  SetActiveImageConnection,
  SetActiveImageConnectionName
} from '@app/state/connection/image/image-connection.actions';
import { UpdateImageConnection } from './image-connection.actions';
import { IImageConnection } from './image-connection.model';

@State<IImageConnection>({
  name: 'activeImageConnection',
  defaults: null
})
@Injectable()
export class ImageConnectionState {
  @Selector([ImageConnectionState])
  static getActiveImageConnection(state: IImageConnection): IImageConnection {
    return state;
  }

  @Selector([ImageConnectionState])
  static getActiveImageConnectionId(state: IImageConnection | null): string | null {
    return state ? state.id : null;
  }

  @Selector([ImageConnectionState])
  static getActiveImageConnectionName(state: IImageConnection | null): string | null {
    return state ? state.metadata.name : null;
  }

  constructor(private store: Store, private projectDataService: ProjectDataService) {}

  @Action(SetActiveImageConnection)
  private setActiveImageConnection(
    { setState }: StateContext<IImageConnection>,
    { connection }: SetActiveImageConnection
  ) {
    setState(connection);
  }

  @Action(SetActiveImageConnectionName)
  private setActiveImageConnectionName(
    { setState, getState }: StateContext<IImageConnection>,
    { name }: SetActiveImageConnectionName
  ) {
    const { id } = getState();
    this.projectDataService.updateConnectionName(id, name).then(() => {
      setState(patch({ metadata: patch({ name }) }));
    });
  }

  @Action(ResetActiveImageConnection)
  private resetActiveImageConnection({ setState }: StateContext<IImageConnection>) {
    setState(null);
  }

  @Action(UpdateImageConnection)
  private setImageConnection(
    { getState, setState }: StateContext<IImageConnection>,
    { payload }: UpdateImageConnection
  ) {
    const { id } = getState();
    this.projectDataService.updateConnection(id, payload as any).then(() => {
      setState(
        patch({
          metadata: patch({ name: payload.metadata.name }),
          settings: patch(payload.settings)
        })
      );
    });
  }

  @Action(LoadImageConnection)
  private loadConnection({ dispatch }: StateContext<IImageConnection>, { connectionId }: LoadImageConnection) {
    return this.projectDataService.getConnection(connectionId).pipe(
      tap((connection: IImageConnection) => {
        dispatch(new SetActiveImageConnection(connection));
      })
    );
  }
}
