<mat-dialog-content>
  <div class="change-product-name-column-container">
    <div class="dialog-title">Select column for Product Name</div>

    <div class="columns-list scrollable">
      <ng-container [formGroup]="form">
        <mat-form-field class="wide-form-field col-12">
          <mat-label>Product Name column</mat-label>
          <mat-select
            formControlName="displayNameField"
            [disableOptionCentering]="true"
            (openedChange)="$event && dnSearch.focus()"
            (selectionChange)="displayNameFieldSearch.reset()"
          >
            <input class="select-search" #dnSearch [formControl]="displayNameFieldSearch" placeholder="Search..." />
            <mat-option
              *ngFor="let header of filteredDisplayHeaders$ | async; let i = index"
              [value]="header"
              [disabled]="i === productIdIndex"
            >
              {{ header | friendlyName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('displayNameField').touched && form.get('displayNameField').hasError('required')">
            Display Name is required.
          </mat-error>
          <mat-error
            *ngIf="
              form.get('displayNameField').touched &&
              !form.get('displayNameField').hasError('required') &&
              form.get('displayNameField').hasError('selectionRequired')
            "
          >
            No match for that value
          </mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="default-view" *ngIf="isShowImages">
      <div class="preview-title">Default view</div>
      <div class="preview-image">
        <img [src]="images[0].url" />
      </div>
      <div class="display-name">{{ displayName }}</div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="onClose()">Cancel</button>
  <button mat-button (click)="onOK()">Save</button>
</div>
