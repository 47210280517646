import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

export type ToolbarItemType = 'button' | 'text' | 'delimiter';

@Component({
  selector: 'app-adaptive-toolbar-item',
  templateUrl: './adaptive-toolbar-item.component.html',
  styleUrls: ['./adaptive-toolbar-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdaptiveToolbarItemComponent implements OnInit {
  @Input()
  public toolbarItemType: ToolbarItemType = 'button';

  @Output()
  public clicked = new EventEmitter<void>();

  constructor(public element: ElementRef) {}

  ngOnInit(): void {}

  onClick(): void {
    this.clicked.emit();
  }

  isOutOfBounds(width: number): boolean {
    const element = this.element?.nativeElement.firstChild;
    return element.offsetLeft + element.clientWidth > width;
  }
}
