import { Pipe, PipeTransform } from '@angular/core';

export const AttributeColors = [
  '#FFA500',
  '#FF8C00',
  '#FF7F50',
  '#FF6347',
  '#FF4500',

  '#FFD700',
  '#FFFF00',
  '#FFFFE0',
  '#FFFACD',
  '#FAFAD2',
  '#FFEFD5',
  '#FFE4B5',
  '#FFDAB9',
  '#EEE8AA',
  '#F0E68C',
  '#BDB76B',

  '#AFEEEE',
  '#7FFFD4',
  '#40E0D0',
  '#48D1CC',
  '#00CED1',

  '#B0C4DE',
  '#ADD8E6',
  '#B0E0E6',
  '#87CEFA',
  '#87CEEB',
  '#6495ED',
  '#00BFFF',
  '#1E90FF',
  '#4169E1',
  // '#0000FF',

  '#E6E6FA',
  '#D8BFD8',
  '#DDA0DD',
  '#DA70D6',
  '#EE82EE',
  '#FF00FF',
  '#BA55D3',
  '#9932CC',
  '#9400D3',
  '#8A2BE2',
  '#9370DB',
  '#7B68EE',
  '#6A5ACD',

  '#FFF8DC',
  '#FFEBCD',
  '#FFE4C4',
  '#FFDEAD',
  '#F5DEB3',
  '#DEB887',
  '#D2B48C',
  '#BC8F8F',
  '#F4A460',
  '#DAA520',
  '#B8860B',
  '#CD853F',
  '#D2691E',
  '#808000',
  '#8B4513',
  '#A0522D',
  // '#A52A2A',

  '#DCDCDC',
  '#D3D3D3',
  '#C0C0C0',
  '#A9A9A9',
  '#808080',
  '#778899',
  '#708090',

  '#ADFF2F',
  '#7FFF00',
  '#00FF00',
  '#32CD32',
  '#98FB98',
  '#90EE90',
  '#00FA9A',
  '#00FF7F',
  '#3CB371',
  '#2E8B57',
  '#228B22',
  '#9ACD32',
  '#6B8E23',
  '#66CDAA',
  '#8FBC8F',
  '#20B2AA'
];

@Pipe({
  name: 'attributeColor'
})
export class AttributeColorPipe implements PipeTransform {
  transform(value: any): string {
    const str = `${value}`;
    const index = this.hashCode(str);
    return AttributeColors[index % AttributeColors.length];
  }

  // see: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  hashCode(value: string) {
    let hash = 0;
    let i = 0;
    const len = value.length;
    while (i < len) {
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash + value.charCodeAt(i++)) << 0;
    }
    return hash + 2147483647 + 1;
  }
}
