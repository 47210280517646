import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { StudyState } from '@app/state/study/study.state';
import { SetMatrixType, SetGroupOrientation } from '@app/state/study/study.actions';
import { CollageState } from '@app/state/collage/collage.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iconsMap, IMatrixIcon, matrixIcons } from '@app/shared/constants/matrix-types';
import { IFiltersModel, FiltersTypeEnum, ExploreBarType } from '@app/models/filters.model';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { ExploreBarSettings, IExploreBarSettingsModel } from '@app/utils/explore-bar.utils';
import { WINDOW } from '@app/shared/utils/window';
import { GroupOrientation } from '@app/models/study-setting.model';
import { AppLoaderService } from '../../../shared/components/loader/app-loader.service';
import { AddWorkspace, SetRankAbsolutePlotting } from '../../../state/study/study.actions';
import { CompassComponent } from '../compass/compass.component';
import { moveInsightsPanelAboveCompassAnimation } from '@app/components/animations/animations';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
  IConfirmDialogResult
} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { WORKSPACE_LIMIT } from '../../../state/study/study.state';
import { getTextForValidMatrix } from '../../../state/tools/tools.model';
import { MatrixService } from '@app/services/matrix/matrix.service';

@UntilDestroy()
@Component({
  selector: 'app-explore-bar',
  templateUrl: './explore-bar.component.html',
  styleUrls: ['./explore-bar.component.scss'],
  animations: [moveInsightsPanelAboveCompassAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreBarComponent implements OnInit {
  public readonly icons = iconsMap;
  public readonly FiltersTypeEnum = FiltersTypeEnum;
  public readonly matrixIcons: IMatrixIcon[] = matrixIcons;
  public readonly ValidMatrix = ValidMatrix;

  @Select(UserDataState.getDimensionsAndMeasures)
  public readonly filters$: Observable<IFiltersModel[]>;
  @Select(StudyState.getCustomAttributeFilters)
  public readonly customAttributes$: Observable<IFiltersModel[]>;
  @Select(StudyState.getCustomCalculationFilters)
  public readonly customCalculations$: Observable<IFiltersModel[]>;
  @Select(StudyState.isAggregating)
  public readonly isAggregating$: Observable<boolean>;
  @Select(StudyState.getMatrixType)
  public readonly matrixType$: Observable<ValidMatrix>;
  @Select(CollageState.getDefaultView)
  public readonly defaultView$: Observable<string>;
  @Select(StudyState.hasWorkspaces)
  public readonly hasWorkspaces$: Observable<boolean>;
  @Select(StudyState.getActiveWorkspaceId)
  public readonly activeWorkspaceId$: Observable<string>;
  @Select(StudyState.getGroupOrientation)
  public readonly groupOrientation$: Observable<GroupOrientation>;
  @Select(StudyState.hasImagesOrFrames)
  public readonly hasImagesOrFrames$: Observable<boolean>;
  @Select(StudyState.getRankAbsolutePlotting)
  public readonly absolutePlotting$: Observable<boolean>;

  @Input() public readonly filtersSelected: boolean;

  @ViewChild(CompassComponent)
  public readonly compassComponent: CompassComponent;

  public filters: IFiltersModel[] = [];
  private defaultView: string;

  protected matrixType: ValidMatrix;
  currentMatrixIcon: IMatrixIcon;
  matrixSettings: IExploreBarSettingsModel;

  public filterBoxState: ExploreBarType = null;

  groupOrientation: GroupOrientation = GroupOrientation.Vertical;
  public matrixTypeMenuOpened: boolean = false;

  matrixRendered: boolean = false;

  public absolutePlotting = true;

  constructor(
    protected readonly store: Store,
    protected readonly appLoaderService: AppLoaderService,
    @Inject(WINDOW)
    protected readonly window: Window,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly renderer: Renderer2,
    private confirmDialog: MatDialog,
    private matrixService: MatrixService
  ) {}

  ngOnInit(): void {
    combineLatest([this.filters$, this.customAttributes$, this.customCalculations$, this.isAggregating$])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([filters, customFilters, customCalculationFilters, isAggregating]: [
          IFiltersModel[],
          IFiltersModel[],
          IFiltersModel[],
          boolean
        ]) => {
          if (filters && customFilters && customCalculationFilters) {
            customFilters.forEach(f => {
              f.isVisual = true;
            });
            this.filters = [...filters, ...customFilters, ...customCalculationFilters];
          }
          if (filters?.length > 0 && customFilters?.length === 0 && !isAggregating) {
            this.appLoaderService.setShowLoader('data', false);
          }
        }
      );

    this.defaultView$.pipe(untilDestroyed(this)).subscribe(defaultView => {
      this.defaultView = defaultView;
    });

    this.activeWorkspaceId$.pipe(untilDestroyed(this)).subscribe((workspaceId: string) => {
      // active workspace changed, so...
      this.filterBoxState = null; // hide any filter box if it was active/opened
      this.matrixRendered = this.store.selectSnapshot(StudyState.hasImagesOrFrames);
    });

    this.matrixType$.pipe(untilDestroyed(this)).subscribe((type: ValidMatrix) => {
      this.matrixType = type;
      this.currentMatrixIcon = matrixIcons.find(item => item.value === this.matrixType);
      this.matrixSettings = ExploreBarSettings[this.matrixType];
      this.matrixRendered = this.store.selectSnapshot(StudyState.hasImagesOrFrames);
      this.filterBoxState = null; // hide any filter box if it was active/opened
      this.groupOrientation = this.store.selectSnapshot(StudyState.getGroupOrientation) || GroupOrientation.Vertical;
    });

    this.groupOrientation$.pipe(untilDestroyed(this)).subscribe((payload: GroupOrientation) => {
      this.groupOrientation = payload || GroupOrientation.Vertical;
    });

    this.hasImagesOrFrames$.pipe(untilDestroyed(this)).subscribe((payload: boolean) => {
      this.matrixRendered = payload;
    });

    this.absolutePlotting$.pipe(untilDestroyed(this)).subscribe((absolute: boolean) => {
      this.absolutePlotting = absolute;
    });
  }

  public onPlottingToggleClick() {
    this.store.dispatch(new SetRankAbsolutePlotting(!this.store.selectSnapshot(StudyState.getRankAbsolutePlotting)));
  }

  public onSelectMatrixType(value: ValidMatrix) {
    const implementedTypes = [ValidMatrix.Freestyle, ValidMatrix.Rank, ValidMatrix.GroupBy, ValidMatrix.Pareto];
    if (implementedTypes.includes(value) && this.matrixType !== value) {
      const isCanChangeMatrixType = this.store.selectSnapshot(StudyState.canChangeMatrixType);
      if (isCanChangeMatrixType) {
        this.clearFilters();
        this.matrixType = value;
        this.store.dispatch(new SetMatrixType(value));
        return;
      }
      const matrix = getTextForValidMatrix(value);

      const isCanAddWorkspace = this.store.selectSnapshot(StudyState.canAddWorkspace);
      const options = [
        { title: `Create new workspace with ${matrix}`, disabled: !isCanAddWorkspace },
        { title: `Replace with new ${matrix} matrix. (Current data will be lost)` }
      ];
      if (!isCanAddWorkspace) {
        options[0].title += ' <span class="warning-text">[Reached workspace limit: ' + WORKSPACE_LIMIT + ']</span>';
      }

      const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        width: '650px',
        data: {
          title: 'Change matrix',
          icon: 'question',
          // notes: '',
          options,
          defaultOptionIndex: isCanAddWorkspace ? 0 : 1,
          buttons: ['OK']
        } as IConfirmDialogData
      });
      dialogRef.afterClosed().subscribe((result: IConfirmDialogResult) => {
        if (result?.button === 'OK') {
          if (result.selectedOptionIndex === 0) {
            // Create new Workspace with selected Matrix
            const activeStudyId = this.store.selectSnapshot(StudyState.getActiveStudyId);
            this.store.dispatch([new AddWorkspace(activeStudyId), new SetMatrixType(value)]);
          } else {
            // Clear current workspace and set new Matrix
            this.clearFilters();
            this.matrixType = value;
            this.store.dispatch(new SetMatrixType(value));
          }
        }
      });
    }
  }

  private clearFilters(): void {
    this.filters.forEach(item => this.setFiltersDefaultValues(item));
  }

  private setFiltersDefaultValues(filtersModel: IFiltersModel) {
    switch (filtersModel.type) {
      case FiltersTypeEnum.Boolean:
      case FiltersTypeEnum.Discrete:
        filtersModel.discreteValues.forEach(val => {
          val.checked = false;
        });
        break;
      case FiltersTypeEnum.Continuous:
        if (filtersModel.currentMinMaxValue) filtersModel.currentMinMaxValue = null;
        break;
      case FiltersTypeEnum.UniqueString:
        if (filtersModel.stringValue) filtersModel.stringValue = '';
        break;
    }
  }

  public setFilterBoxState(type: ExploreBarType | null): void {
    this.matrixService.contextMenuOpenCloseSubject.next({ open: false });
    this.filterBoxState = type;
  }

  onGroupOrientationChange() {
    this.groupOrientation =
      this.groupOrientation === GroupOrientation.Vertical ? GroupOrientation.Horizontal : GroupOrientation.Vertical;
    this.store.dispatch(new SetGroupOrientation(this.groupOrientation));
  }

  setMatrixTypeMenuOpened(value: boolean) {
    this.matrixTypeMenuOpened = value;
    if (this.matrixTypeMenuOpened) {
      this.renderer.addClass(this.window.document.body, 'matrix-type-menu-opened');
    } else {
      this.renderer.removeClass(this.window.document.body, 'matrix-type-menu-opened');
    }
  }
}
