import { Injectable } from '@angular/core';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { take, tap } from 'rxjs/operators';
import {
  LoadBusinessConnection,
  ResetActiveBusinessConnection
} from '../connection/business/business-connection.actions';
import { BusinessConnectionState } from '../connection/business/business-connection.state';
import { LoadImageConnection, ResetActiveImageConnection } from '../connection/image/image-connection.actions';
import { ImageConnectionState } from '../connection/image/image-connection.state';
import { CreateStudy } from '../study/study.actions';
import {
  CreateCollage,
  LoadCollage,
  ResetActiveCollage,
  SetActiveCollage,
  SetActiveCollageDisplayNameField
} from './collage.actions';
import { ICollage } from './collage.model';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';

@State<ICollage>({
  name: 'activeCollage',
  defaults: null
})
@Injectable()
export class CollageState {
  @Selector([CollageState])
  static getActiveCollageOwner(state: ICollage | null): string | null {
    return state ? state.owner : null;
  }

  @Selector([CollageState])
  static getActiveCollageId(state: ICollage | null): string | null {
    return state ? state.id : null;
  }

  @Selector([CollageState])
  static getActiveCollageName(state: ICollage | null): string | null {
    return state ? state.metadata.name : null;
  }

  @Selector([CollageState])
  static getBusinessConnectionField(state: ICollage | null): string | null {
    return state ? state.businessConnectionField : null;
  }

  @Selector([CollageState])
  static getDisplayNameField(state: ICollage | null): string | null {
    return state ? state.displayNameField : null;
  }

  @Selector([CollageState])
  static getPrimaryMetricField(state: ICollage | null): string | null {
    return state ? state.primaryMetricField : null;
  }

  @Selector([CollageState])
  static getDefaultView(state: ICollage | null): string | null {
    return state ? state.defaultView : null;
  }

  constructor(
    private store: Store,
    private projectDataService: ProjectDataService,
    private appLoaderService: AppLoaderService
  ) {}

  @Action(CreateCollage)
  private createCollage({ dispatch }: StateContext<ICollage>, { collage, isCreateNewStudy }: CreateCollage) {
    this.projectDataService.createCollage(collage).then(() => {
      dispatch(new SetActiveCollage(collage, isCreateNewStudy));
    });
  }

  @Action(SetActiveCollage)
  private setActiveCollage(
    { setState, dispatch }: StateContext<ICollage>,
    { collage, isCreateNewStudy }: SetActiveCollage
  ) {
    setState(collage);
    if (isCreateNewStudy) {
      dispatch(new CreateStudy(collage, { displayName: collage.metadata.authorName, uid: collage.owner }));
    }
    const activeImageConnection = this.store.selectSnapshot(ImageConnectionState);
    if (!activeImageConnection || activeImageConnection.id !== collage.imageConnectionId) {
      dispatch(new LoadImageConnection(collage.imageConnectionId));
    }
    const activeBusinessConnection = this.store.selectSnapshot(BusinessConnectionState);
    if (!activeBusinessConnection || activeBusinessConnection.id !== collage.businessConnectionId) {
      dispatch(new LoadBusinessConnection(collage.businessConnectionId));
    }
  }

  @Action(SetActiveCollageDisplayNameField)
  private setActiveCollageDisplayNameField(
    { setState, getState }: StateContext<ICollage>,
    { name }: SetActiveCollageDisplayNameField
  ) {
    const { id } = getState();
    this.projectDataService.updateCollageDisplayNameField(id, name).then(() => {
      setState(patch({ displayNameField: name }));
    });
  }

  @Action(ResetActiveCollage)
  private resetActiveCollage({ setState, dispatch }: StateContext<ICollage>) {
    setState(null);
    dispatch([new ResetActiveImageConnection(), new ResetActiveBusinessConnection()]);
  }

  @Action(LoadCollage)
  private loadCollage({ dispatch }: StateContext<ICollage>, { collageId }: LoadCollage) {
    return this.projectDataService.getCollage(collageId).pipe(
      take(1),
      tap((collage: ICollage) => {
        dispatch(new SetActiveCollage(collage));
      })
    );
  }
}
