<div
  class="filter"
  [attr.id]="prefix + '-filter-item-' + index"
  [ngClass]="{ expanded: expandedState }"
  [ngSwitch]="filter.type"
>
  <div
    class="filter__header"
    [ngClass]="{
      selected: !enableExpansion && filter | filterValuesSelected,
      grouping: prefix === 'grouping',
      sorting: !enableExpansion
    }"
    (click)="!enableExpansion && onSelectAll.emit({ filter, event: { checked: true } })"
  >
    <div
      class="filter__header__wrapper"
      matRipple
      (click)="prefix === 'grouping' ? onWrapperClick() : toggleExpand(index)"
    >
      <div class="filter__header__type-icon">
        <svg-icon svgClass="icon" [name]="filter | filterTypeIcon"></svg-icon>
        <div *ngIf="enableExpansion && filter | filterValuesSelected" class="filter__header__badge"></div>
      </div>
      <p>
        {{ filter | filterName }}
      </p>
    </div>
    <div *ngIf="enableExpansion" matRipple class="toggle-expansion-icon" (click)="toggleExpand(index)">
      <svg-icon svgClass="icon" name="icon-filter-arrow"></svg-icon>
    </div>
  </div>
  <div *ngIf="enableExpansion" class="filter__body">
    <div class="select-all">
      <mat-checkbox
        [checked]="
          filter.isAllChecked || (filter.currentMinMaxValue !== undefined && filter.currentMinMaxValue !== null)
        "
        [indeterminate]="filter | filterAllIndeterminate"
        (change)="onSelectAll.emit({ filter, event: $event })"
        [disabled]="filter.isAllDiscreteValuesEmpty"
        >All</mat-checkbox
      >
    </div>
    <div class="filter-values filter-values-continuous" *ngSwitchCase="FiltersTypeEnum.Continuous">
      <div class="inputs">
        <div class="min">
          <input
            #minValueInputRef
            type="number"
            [min]="filter.minMaxValue.min"
            [max]="maxValueInputRef.value || filter.minMaxValue.max"
            [placeholder]="filter.minMaxValue.min"
            [value]="filter.currentMinMaxValue?.min"
            (change)="onMinMaxValueChange()"
            [overflow-tooltip]="true"
            [overflow-tooltip-placement]="'bottom'"
            [overflow-tooltip-offset]="5"
          />
          <div>From (min {{ filter.minMaxValue.min }})</div>
        </div>
        <div class="max">
          <input
            #maxValueInputRef
            type="number"
            [min]="minValueInputRef.value || filter.minMaxValue.min"
            [max]="filter.minMaxValue.max"
            [placeholder]="filter.minMaxValue.max"
            [value]="filter.currentMinMaxValue?.max"
            (change)="onMinMaxValueChange()"
            [overflow-tooltip]="true"
            [overflow-tooltip-placement]="'bottom'"
            [overflow-tooltip-offset]="5"
          />
          <div>To (max {{ filter.minMaxValue.max }})</div>
        </div>
        <button
          mat-flat-button
          (click)="onMinMaxChange.emit({ filter, min: $any(minValueInputRef.value), max: $any(maxValueInputRef.value) })"
        >
          Apply
        </button>
      </div>
    </div>
    <div class="filter-values filter-values-continuous" *ngSwitchCase="FiltersTypeEnum.Date">
      <div class="inputs">
        <mat-form-field appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input
            [formGroup]="dateRange"
            [rangePicker]="picker"
            [min]="filter.minMaxValue.min"
            [max]="filter.minMaxValue.max"
          >
            <input matStartDate formControlName="start" [placeholder]="$any(filter.minMaxValue.min) | formatDate" />
            <input matEndDate formControlName="end" [placeholder]="$any(filter.minMaxValue.max) | formatDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-hint
            >{{ $any(filter.minMaxValue.min) | formatDate }} – {{ $any(filter.minMaxValue.max) | formatDate }}</mat-hint
          >

          <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          (click)="onMinMaxChange.emit({ filter, min: dateRange.controls.start.value || filter.minMaxValue.min, max: dateRange.controls.end.value || filter.minMaxValue.max })"
        >
          Apply
        </button>
      </div>
    </div>
    <div class="filter-values filter-values-discrete" *ngSwitchDefault>
      <ng-container *ngIf="filter.discreteValues.length >= 20">
        <cdk-virtual-scroll-viewport class="values-viewport scrollable" itemSize="40" minBufferPx="200">
          <div class="value" *cdkVirtualFor="let value of filter.discreteValues">
            <mat-checkbox
              [disabled]="value.productsLength === 0 || value.disabled"
              [checked]="value.checked"
              (change)="onCheckboxValueChange(value.name, $event)"
            >
              <span>
                {{ value.name }}
                <ng-container *ngIf="value.productsLength && value.productsLength > 0">
                  ({{ value.productsLength }})
                </ng-container>
              </span>
            </mat-checkbox>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
      <ng-container *ngIf="filter.discreteValues.length < 20">
        <div class="value" *ngFor="let value of filter.discreteValues; let i = index; trackBy: trackByName">
          <mat-checkbox
            [disabled]="value.productsLength === 0 || value.disabled"
            [checked]="value.checked"
            (change)="onCheckboxValueChange(value.name, $event)"
          >
            <span
              >{{ value.name }}
              <ng-container *ngIf="value.productsLength && value.productsLength > 0"
                >({{ value.productsLength }})</ng-container
              >
            </span>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</div>
