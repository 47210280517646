<div class="update-email">
  <p class="update-email__message">Change your email</p>
  <form #form="ngForm" class="auth-form" [formGroup]="changeEmailForm" (ngSubmit)="form.valid && onChangeEmail()">
    <div class="form-field" [ngClass]="{ invalid: (form.submitted || newEmail.touched) && newEmail.invalid }">
      <div class="form-field__prefix">
        <svg-icon svgClass="icon" name="icon-email-filled"></svg-icon>
      </div>
      <input
        class="form-field__input"
        type="email"
        formControlName="newEmail"
        placeholder="New email"
        (keyup.enter)="form.onSubmit($event)"
      />
      <div *ngIf="(form.submitted || newEmail.touched) && newEmail.invalid" class="error">
        <ng-container *ngIf="newEmail.hasError('required')"> Please enter new email </ng-container>
        <ng-container *ngIf="newEmail.hasError('email')"> Enter valid email address </ng-container>
      </div>
    </div>
    <div
      class="form-field"
      [ngClass]="{
        invalid: (form.submitted || password.touched) && password.invalid
      }"
    >
      <div class="form-field__prefix">
        <svg-icon svgClass="icon icon-password" name="icon-password-lock"></svg-icon>
      </div>
      <input
        class="form-field__input"
        type="password"
        formControlName="password"
        placeholder="Password"
        (keyup.enter)="form.onSubmit($event)"
      />
      <div *ngIf="(form.submitted || password.touched) && password.hasError('required')" class="error">
        Password is required
      </div>
    </div>
    <div class="auth-form__actions">
      <button mat-flat-button color="accent" type="submit">CHANGE</button>
      <button mat-stroked-button color="accent" type="button" (click)="location.back()">CANCEL</button>
    </div>
  </form>
</div>
