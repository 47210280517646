/* eslint-disable eqeqeq */
import { ComponentFactoryResolver, ComponentRef, Inject, ViewContainerRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Container, Renderer } from 'pixi.js';
import { ImageContainer } from '../../custom-pixi-containers/image-container';
import { ResourceManagerService } from '../resource-manager/resource-manager.service';
import gsap from 'gsap/all';
import { LOW_IMAGE_SIZE_RESOURCE } from './base-matrix';
import { DataService } from '../data/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupTableService } from '@app/components/lookup-table';
import { SetMatrixState, SetStudyEdited, UpdateMatrixState } from '@app/state/study/study.actions';
import {
  GroupOrientation,
  IHoverInfoFieldModel,
  IMetricsCustomFormattings,
  IRankInfoPanelConfig,
  InfoPanelPosition
} from '@app/models/study-setting.model';
import { IGroupImageModel, IRankGroupModel } from '@app/models/group-model';
import { GroupContainer } from '@app/custom-pixi-containers/group-container';
import { GroupLabel } from '@app/custom-pixi-containers/group-label';
import { ImageInfoPanel } from '@app/custom-pixi-containers/image-info-panel';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { GroupLabelsContainer } from '@app/custom-pixi-containers/group-labels-container';
import { MatrixToolEnum } from '@app/state/tools/tools.model';
import { ValidMatrix } from '../../state/tools/tools.model';
import { StudyState } from '@app/state/study/study.state';
import { WINDOW } from '../../shared/utils/window';
import { IGroupStateModel, IPosition, IWorkspaceTools, IZoomSettings } from '../../models/workspace-list.model';
import { Viewport } from 'pixi-viewport';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { IBounds } from '@app/models/bounds.model';
import { ImageInfoOverlay } from '@app/custom-pixi-containers/image-info-container';
import { Observable, Subject } from 'rxjs';
import { RankAbsolutePlottingLabelComponent } from '@app/components/build/rank-absolute-plotting-label/rank-absolute-plotting-label.component';
import { ResizeEvent } from 'angular-resizable-element';
import { DropShadowService } from '../drop-shadow/drop-shadow.service';
import { IPresentationState } from '@app/models/presentation.models';
import { dropShadowPadding, imageBaseSize } from '@app/shared/constants/viewport';
import { FriendlyNameService } from '../friendly-name/friendly-name.service';
import { shortUid } from '@app/shared/utils/short-uid';
import { FiltersService } from '../filters/filters.service';
import { BaseMatrix } from './base-matrix';
import {
  IAbsolutePlottingImageHover,
  IAbsolutePlottingPositionSubject,
  IAbsolutePlottingSizeSubject
} from '@app/models/matrix.model';

export class RankMatrix extends BaseMatrix {
  @Select(StudyState.getN) numEntries$: Observable<number>;

  protected lastHoverInfoData: IHoverInfoFieldModel[];

  private numEntries;
  private rankDockingTopOrRight = true;
  private labelsHidden = false;
  private absolutePlotting: boolean = false;
  private maxNumImagesInGroup: number = 0;

  private plottingAnimationTimeline: gsap.core.Timeline;

  private imageAnimationTime = 0.28;
  private maxAnimationDelay = 0.4;

  private isDefaultRender = false;
  private isDefaultRenderAnimating = false;
  private defaultRenderImageAnimationTime = 0.28;
  private defaultRenderDelayMultiplier = 0.28;
  private defaultRenderMaxAnimationRandomness = 0.1;

  infoPanelPosition: InfoPanelPosition = InfoPanelPosition.Left;

  private infoPanelWrapperMaxHeight;
  private infoPanelWrapperMaxWidth;
  private groupContGapBase = (this.imageSize + this.distanceBetweenImages) / 2;
  private imageOffsetsForInfoPanel: IPosition = { x: 0, y: 0 };
  private groupOffsetsForInfoPanel: IPosition = { x: 0, y: 0 };

  private absolutePlottingLabelComponentRef: ComponentRef<RankAbsolutePlottingLabelComponent>;
  private absolutePlottingLabel: HTMLElement;
  private imageSortByValues: number[] = [];

  public absolutePlottingPointsSubject = new Subject<number[]>();
  public absolutePlottingOrientationSubject = new Subject<boolean>();
  public absolutePlottingSizeSubject = new Subject<IAbsolutePlottingSizeSubject>();
  public absolutePlottingPositionSubject = new Subject<IAbsolutePlottingPositionSubject>();
  public absolutePlottingImageHover = new Subject<IAbsolutePlottingImageHover>();
  private absolutePlottingLabelSize = 0;
  private activeImageZIntexTemp: number = -1;
  private activeImageRefTemp: ImageContainer;
  private ctrlZoomActive: boolean = false;

  private rankNChangeAnimationTimeline: gsap.core.Timeline;

  private newImageMatrixWidth;
  private newImageMatrixHeight;
  private newViewportScale;

  public updateViewportOnCompass = new Subject<null>();

  private imageIndexInCanvasState: number;

  // Thresholds, when need to hide and show labels
  // if value of image between them, label will be shown like a 'light' version (0.4 opacity and without text)
  private get IMAGE_SIZE_WITHOUT_LABELS(): number {
    return (this.window.innerWidth * 1) / 100;
  }
  private get IMAGE_SIZE_WITH_LABELS(): number {
    return (this.window.innerWidth * 3) / 100;
  }

  constructor(
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override stage: Container,
    protected override renderer: Renderer,
    protected override viewport: Viewport,
    protected override imageInfoOverlayRef: ImageInfoOverlay,
    protected override store: Store,
    protected override snackBar: MatSnackBar,
    protected override resourceManagerService: ResourceManagerService,
    protected override dataService: DataService,
    protected override lookupTable: LookupTableService,
    protected override appLoaderService: AppLoaderService,
    protected override dropShadowService: DropShadowService,
    protected override friendlyNameService: FriendlyNameService,
    protected override filterService: FiltersService,
    protected override viewContainerRef: ViewContainerRef,
    private factoryResolver: ComponentFactoryResolver
  ) {
    super(
      window,
      stage,
      renderer,
      viewport,
      imageInfoOverlayRef,
      store,
      snackBar,
      resourceManagerService,
      dataService,
      lookupTable,
      appLoaderService,
      dropShadowService,
      friendlyNameService,
      filterService,
      viewContainerRef
    );

    this.recenterMatrix = new Subject();
    this.groupLabelsContainer = new GroupLabelsContainer(this.dataService);
  }

  public override setInfoPanelConfig(config: IRankInfoPanelConfig, updateMatrix: boolean = false): void {
    this.resetResolution();
    this.infoPanelShowState = config.showState || 'hover';
    this.infoPanelPosition = config.position ? config.position : InfoPanelPosition.Left; // default to left if position is not available for old studies

    this.updateImageOffsetsForInfoPanel();
    this.updateGroupOffsetsForInfoPanel();

    if (!this.viewport || !config) return;
    if (updateMatrix && this.displayData?.length > 0) {
      this.hideAllHtmlElementsBeforeMatrixUpdate();

      this.updateMatrix(false);
      this.onMatrixUpdateComplete();
      this.updateMatrixBase(true);
      this.animateImages();
      this.animateLabels();
    }
  }

  private hideAllHtmlElementsBeforeMatrixUpdate(): void {
    this.groupLabelsContainer.storeAllGroupsQuickStatsStateAndHide();
    this.groupLabelsContainer.hideLabels(true);
    this.clearCompass.next(true);
    this.storeAllGroupsInfoLockedStateAndHide();
    this.removeInfoPanelsOnCanvas();
  }

  public onMatrixUpdateComplete(): void {
    this.groupLabelsContainer.showLabels();
    this.groupLabelsContainer.restoreAllGroupsQuickStatsState(
      this.store.selectSnapshot(StudyState.getHoverInfoConfig),
      this.store.selectSnapshot(StudyState.getMetricsCustomFormattings)
    );
    this.updateViewportOnCompass.next(null);
    this.clearCompass.next(false);
    this.updateViewportInCanvasState();
    this.setCanvasStateWithGroups();
    if (this.infoPanelShowState === 'canvas') this.addInfoPanelsOnCanvas();

    this.setInitialAbsolutePlottingSize(this.getMinImagePositionInGroup());
    this.updateAbsolutePlottingPosition(this.getImageMatrixBounds());

    this.restoreAllGroupsInfoLockedState();
    this.highlightedProducts$.next(this.highlitedProductIDs);

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    } else {
      this.highlightedProducts$.next(new Set());
    }
  }

  public override setGroupOrientation(groupOrientation: GroupOrientation, updateMatrix: boolean = false): void {
    this.groupOrientation = groupOrientation || GroupOrientation.Vertical;
    if (updateMatrix && this.displayData?.length > 0) {
      this.hideAllHtmlElementsBeforeMatrixUpdate();
      this.groupLabelsContainer.setLabelsOrientation(this.groupOrientation);
      this.groupLabelsContainer.setDockingSide(this.rankDockingTopOrRight);
      this.groupLabelsContainer.updateLabelsOrientation();

      this.absolutePlottingOrientationSubject.next(this.groupOrientation === GroupOrientation.Horizontal);

      this.updateMatrix(true);
      this.updateMatrixBase(true);
      this.onMatrixUpdateComplete();
      this.animateImages();
      this.animateLabels();
    } else {
      this.groupLabelsContainer.setLabelsOrientation(this.groupOrientation);
    }
  }

  private getMinImagePositionInGroup(): number {
    return (
      (this.maxNumImagesInGroup - 1) *
      (this.imageDesiredSizes.width +
        this.distanceBetweenImages +
        (this.groupOrientation === GroupOrientation.Horizontal
          ? this.imageOffsetsForInfoPanel.x
          : this.imageOffsetsForInfoPanel.y))
    );
  }

  private setInitialAbsolutePlottingSize(minPosition: number, maxPosition: number = 0) {
    this.absolutePlottingLabelSize = (minPosition + this.imageDesiredSizes.width - maxPosition) * this.viewport.scale.x;
    const imageSize = this.imageDesiredSizes.width * this.viewport.scale.x;
    this.absolutePlottingSizeSubject.next({
      labelSize: this.absolutePlottingLabelSize,
      imageSize
    });
  }

  private setNewGroupsSizes(isOrientationChanging: boolean): void {
    for (let level = this.groupContainerRefsByLevels.length - 1; level >= 0; level--) {
      this.groupContainerRefsByLevels[level].forEach(group => {
        if (group.isLast) {
          if (this.groupOrientation === GroupOrientation.Vertical) {
            group.setNewSize(isOrientationChanging ? group.height : group.width);
          } else {
            group.setNewSize(isOrientationChanging ? group.width : group.height);
          }
        } else {
          group.setNewSize(
            group.children.reduce((newSize: number, childGroup: GroupContainer, index: number) => {
              let result = newSize + childGroup.getNewSize();
              if (index !== 0) {
                result += this.getGroupContGap(childGroup);
                if (this.groupOrientation === GroupOrientation.Vertical) {
                  result += this.groupOffsetsForInfoPanel.x;
                } else {
                  result += this.groupOffsetsForInfoPanel.y;
                }
              }
              return result;
            }, 0)
          );
        }
      });
    }
  }

  private updateMatrix(isOrientationChanging: boolean): void {
    this.setNewGroupsSizes(isOrientationChanging);

    if (this.groupOrientation === GroupOrientation.Vertical) {
      this.updateChildrenGroupsCoordinatesVertical(this.imageMatrix, isOrientationChanging);
    } else {
      this.updateChildrenGroupsCoordinatesHorizontal(this.imageMatrix, isOrientationChanging);
    }

    if (this.absolutePlotting) {
      const max = 0;
      const min =
        this.groupOrientation == GroupOrientation.Horizontal
          ? this.imageMatrix.width - (this.imageDesiredSizes.width + this.distanceBetweenImages)
          : this.imageMatrix.height - (this.imageDesiredSizes.width + this.distanceBetweenImages);

      const scale = this.window.innerHeight / (this.matrixResizerSpriteRef.height / 3);
      this.absolutePlottingLabelSize = (min + this.imageDesiredSizes.width - max) * scale;

      const imageSize = this.imageDesiredSizes.width * scale;
      this.absolutePlottingSizeSubject.next({
        labelSize: this.absolutePlottingLabelSize,
        imageSize
      });
      this.updateAbsolutePlottingPosition(this.getImageMatrixBounds());

      this.imageRefs.forEach((image, i) => {
        const sortByVal = image.getData().sortByValue;
        const percent = parseFloat(
          (
            (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
            (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
          ).toFixed(2)
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.groupOrientation == GroupOrientation.Horizontal
          ? (image.x = min + (max - min) * percent)
          : (image.y = min + (max - min) * percent);
      });

      this.absolutePlottingLabel.style.display = 'flex';
      this.absolutePlottingLabel.style.opacity = '1';
    }
  }

  private updateChildrenGroupsCoordinatesVertical(
    parenGroupCont,
    isOrientationChanging: boolean,
    tl?: gsap.core.Timeline
  ) {
    if (!parenGroupCont.isLast) {
      let subX = 0;
      parenGroupCont.children.forEach((groupCont: GroupContainer) => {
        if (tl) {
          tl.to(
            groupCont,
            {
              duration: this.imageAnimationTime,
              x: subX,
              y: 0
            },
            'animationStart'
          );
        } else {
          groupCont.position.set(subX, 0);
        }
        this.updateChildrenGroupsCoordinatesVertical(groupCont, isOrientationChanging, tl);
        const width = groupCont.getNewSize();
        subX += width + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.x;
      });
    } else {
      this.updateChildrenImagesCoordinates(parenGroupCont, tl);
    }
  }

  private updateChildrenGroupsCoordinatesHorizontal(
    parenGroupCont,
    isOrientationChanging: boolean,
    tl?: gsap.core.Timeline
  ) {
    if (!parenGroupCont.isLast) {
      let subY = 0;
      parenGroupCont.children.forEach((groupCont: GroupContainer) => {
        if (tl) {
          tl.to(
            groupCont,
            {
              duration: this.imageAnimationTime,
              x: 0,
              y: subY
            },
            'animationStart'
          );
        } else {
          groupCont.position.set(0, subY);
        }
        this.updateChildrenGroupsCoordinatesHorizontal(groupCont, isOrientationChanging, tl);
        const height = groupCont.getNewSize();
        subY += height + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.y;
      });
    } else {
      this.updateChildrenImagesCoordinates(parenGroupCont, tl);
    }
  }

  private updateChildrenImagesCoordinates(parenGroupCont: GroupContainer, tl?: gsap.core.Timeline) {
    const imageOffset = this.rankDockingTopOrRight ? this.maxNumImagesInGroup - parenGroupCont.children.length : 0;
    parenGroupCont.labelOffset =
      this.groupOrientation === GroupOrientation.Vertical ? this.numEntries - parenGroupCont.children.length : 0;
    parenGroupCont.children
      .sort((a, b) => (a as ImageContainer).getData().rank - (b as ImageContainer).getData().rank)
      .forEach((image: ImageContainer, index: number) => {
        const newImagePosition = this.getImagePositionInGroup(index, imageOffset);
        if (tl) {
          tl.to(
            image,
            {
              duration: this.imageAnimationTime,
              ...newImagePosition
            },
            'animationStart'
          );
        } else {
          image.position.set(newImagePosition.x, newImagePosition.y);
        }
      });
  }

  private updateImagesCoordinatesForAbsolutePlotting(tl?: gsap.core.Timeline): void {
    this.imageRefs.forEach(image => {
      const newImagePosition = this.getImagePositionInGroupForAbsolutePlotting(
        image.getData().sortByValue,
        this.getMinImagePositionInGroup()
      );
      if (tl) {
        tl.to(
          image,
          {
            duration: this.imageAnimationTime,
            ...newImagePosition
          },
          'animationStart'
        );
      } else {
        image.position.set(newImagePosition.x, newImagePosition.y);
      }
    });
  }

  private getImagePositionInGroupForAbsolutePlotting(sortByVal: number, min: number, max: number = 0): IPosition {
    const percent = parseFloat(
      (
        (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
        (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
      ).toFixed(2)
    );

    return {
      x: this.groupOrientation == GroupOrientation.Horizontal ? min + (max - min) * percent : this.imageSize / 2,
      y: this.groupOrientation == GroupOrientation.Horizontal ? this.imageSize / 2 : min + (max - min) * percent
    };
  }

  private getImagePositionInGroup(index: number, imageOffset: number): IPosition {
    return {
      x:
        this.groupOrientation === GroupOrientation.Vertical
          ? this.imageSize / 2
          : (index + imageOffset) * (this.imageSize + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.x),
      y:
        this.groupOrientation === GroupOrientation.Horizontal
          ? this.imageSize / 2
          : (index + imageOffset) * (this.imageSize + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.y)
    };
  }

  private updateMatrixBase(imageMatrixSizeChanged: boolean, tl?: gsap.core.Timeline) {
    this.updateMatrixDimensionsBeforeMatrixUpdate(imageMatrixSizeChanged);
    this.updateViewport(tl);
    if (this.absolutePlotting) {
      this.animateAbsoluteLabelOnNChange();
    }
  }

  private getNewImageMatrixSize(): number {
    return this.groupContainerRefsByLevels[0].reduce((newSize: number, childGroup: GroupContainer, index: number) => {
      let result = newSize + childGroup.getNewSize();
      if (index !== 0) {
        result += this.getGroupContGap(childGroup);
        if (this.groupOrientation === GroupOrientation.Vertical) {
          result += this.groupOffsetsForInfoPanel.x;
        }
      }
      return result;
    }, 0);
  }

  private updateMatrixDimensionsBeforeMatrixUpdate(imageMatrixSizeChanged: boolean) {
    this.newImageMatrixWidth =
      this.groupOrientation === GroupOrientation.Vertical
        ? imageMatrixSizeChanged
          ? this.getNewImageMatrixSize()
          : this.imageMatrix.width
        : this.maxNumImagesInGroup *
            (this.imageDesiredSizes.width + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.x) -
          this.distanceBetweenImages;
    this.newImageMatrixHeight =
      this.groupOrientation === GroupOrientation.Vertical
        ? this.maxNumImagesInGroup *
            (this.imageDesiredSizes.width + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.y) -
          this.distanceBetweenImages
        : imageMatrixSizeChanged
        ? this.getNewImageMatrixSize()
        : this.imageMatrix.height;

    const imageMatrixPositionFromCenter = {
      x: this.viewport.center.x - this.imageMatrix.x,
      y: this.viewport.center.y - this.imageMatrix.y
    };
    this.updateMatrixDimensionsBase(this.newImageMatrixWidth, this.newImageMatrixHeight);

    const newCenter = {
      x: this.imageMatrix.x + imageMatrixPositionFromCenter.x,
      y: this.imageMatrix.y + imageMatrixPositionFromCenter.y
    };

    this.recenterMatrix.next(newCenter);
    this.render();
  }

  private addInfoPanelsOnCanvas() {
    this.removeInfoPanelsOnCanvas();
    if (this.infoPanelShowState !== 'canvas' || this.absolutePlotting) {
      return;
    }
    const isHorizontal = this.groupOrientation === GroupOrientation.Horizontal;
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.imageRefs.forEach((image: ImageContainer) => {
      const imageBounds = image.getImageBounds();
      image.infoPanel = new ImageInfoPanel(
        image,
        this.infoPanelPosition,
        isHorizontal || this.infoPanelPosition === InfoPanelPosition.Bottom
      );
      image.infoPanel.resize(imageMatrixBounds, imageBounds);
    });
    this.updateInfoPanelBaseSizes();
    this.scaleInfoPanelText();
    this.imageRefs.forEach(image => image.infoPanel?.move(imageMatrixBounds));
  }

  private removeInfoPanelsOnCanvas() {
    this.imageRefs.forEach((image: ImageContainer) => {
      image.infoPanel?.delete();
      image.infoPanel = undefined;
    });
  }

  private updateLabelsOnZoom(needMove: boolean = false) {
    const distanceBetweenLabels = this.distanceBetweenImages * this.viewport.scale.x;
    const groupBaseSize = this.absolutePlotting
      ? this.absolutePlottingLabelSize
      : (this.getMinImagePositionInGroup() + this.imageDesiredSizes.width) * this.viewport.scale.x;
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.groupLabelsContainer.zoomLabels(
      groupBaseSize,
      this.getGroupBasePosition(imageMatrixBounds),
      distanceBetweenLabels
    );
    if (needMove) this.moveLabels(imageMatrixBounds);
  }

  private moveLabels(imageMatrixBounds: IBounds) {
    const imageSize =
      this.imageDesiredSizes.width +
      this.distanceBetweenImages +
      (this.groupOrientation == GroupOrientation.Horizontal
        ? this.imageOffsetsForInfoPanel.x * this.resolution
        : this.imageOffsetsForInfoPanel.y * this.resolution);
    const groupBaseSize = this.absolutePlotting
      ? this.absolutePlottingLabelSize
      : ((this.maxNumImagesInGroup - 1) * imageSize +
          this.imageDesiredSizes.width +
          this.distanceBetweenImages +
          (this.groupOrientation == GroupOrientation.Vertical ? this.imageOffsetsForInfoPanel.y : 0)) *
        this.viewport.scale.x;
    this.groupLabelsContainer.moveLabels(
      groupBaseSize,
      this.getGroupBasePosition(imageMatrixBounds),
      this.groupContainerRefs
    );
  }

  private getGroupBasePosition(imageMatrixBounds: IBounds) {
    const groupBasePosition = {
      x: imageMatrixBounds.x,
      y: imageMatrixBounds.y
    };
    if (
      this.groupOrientation === GroupOrientation.Horizontal &&
      this.infoPanelPosition === InfoPanelPosition.Left &&
      !this.rankDockingTopOrRight
    ) {
      groupBasePosition.x -= this.imageDesiredSizes.width * this.viewport.scale.x;
    }
    if (
      this.groupOrientation === GroupOrientation.Horizontal &&
      this.infoPanelPosition === InfoPanelPosition.Right &&
      this.rankDockingTopOrRight
    ) {
      groupBasePosition.x += this.imageDesiredSizes.width * this.viewport.scale.x;
    }
    return groupBasePosition;
  }

  public override setNumEntries(numEntries: number, updateMatrix: boolean = false) {
    if (numEntries && numEntries > 0) {
      const addImages = numEntries > this.numEntries;
      this.numEntries = numEntries;
      if (updateMatrix && this.displayData?.length > 0) {
        if (addImages) {
          setTimeout(() => {
            this.storeAllGroupsInfoLockedStateAndHide();
          });
        } else {
          this.storeAllGroupsInfoLockedStateAndHide();
        }

        if (this.infoPanelShowState === 'canvas') this.removeInfoPanelsOnCanvas();
        this.rankNChangeAnimationTimeline?.kill();
        this.rankNChangeAnimationTimeline = gsap.timeline({
          ease: 'none',
          onUpdate: () => {
            this.render();
          },
          onComplete: () => {
            this.updateGroupLocalBounds();
            this.updateViewportOnCompass.next(null);
            this.clearCompass.next(false);
            this.updateViewportInCanvasState();
            this.setCanvasStateWithGroups();
            if (this.infoPanelShowState === 'canvas') this.addInfoPanelsOnCanvas();
            this.updateImageData(this.lastHoverInfoData || this.store.selectSnapshot(StudyState.getHoverInfoConfig)); // recalculate image data for added Images
            this.restoreAllGroupsInfoLockedState();
            this.highlightedProducts$.next(this.highlitedProductIDs);

            if (this.selectedTool === MatrixToolEnum.Highlighter) {
              const filters = this.filterService.preparedHighlightFilters$.getValue();
              this.highlightFilters = filters ? filters : [];
              this.buildHighlights();
            } else {
              this.highlightedProducts$.next(new Set());
            }
          }
        });
        this.rankNChangeAnimationTimeline.addLabel('animationStart', 0);

        if (addImages) {
          this.animateNChangeAdd();
        } else {
          this.animateNChangeRemove();
        }
        this.render();
      }
    }
  }

  private animateNChangeAdd() {
    const images = [];
    this.displayData.forEach(group => {
      this.updateProductsToShow(group, images);
    });
    this.productsToShow = [...images];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    setTimeout(() => {
      this.resourceManagerService.stopActiveLoaders();
      const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);
      this.resourceManagerService.loadResources(resources, null, null, () => {
        this.animateNChangeBase();

        const allNewImages = [];
        const allOldImages = [];
        this.groupContainerRefs
          .filter(group => group.isLast)
          .forEach(group => {
            const nImages = this.getNImages<IGroupImageModel>(group.imageModels);
            group.labelOffset =
              this.groupOrientation === GroupOrientation.Vertical ? this.numEntries - nImages.length : 0;

            allOldImages.push(...group.getImages());
            const newImages = [];
            const imageOffset = this.rankDockingTopOrRight ? this.maxNumImagesInGroup - nImages.length : 0;
            nImages.forEach((imageData, i) => {
              const pos = this.getImagePositionInGroup(i, imageOffset);
              const image = group.getImages().find(img => img.getId() === imageData.prodId);
              if (image) {
                image.x = pos.x;
                image.y = pos.y;
              } else {
                const newImage = this.createImage(
                  shortUid(),
                  imageData.prodId,
                  imageData.data.displayName,
                  pos,
                  group,
                  imageData.data,
                  this.lookupTable.getImagePlaceholder()
                );
                newImage.alpha = 1;
                this.setupImageListeners(newImage);
                newImages.push(newImage);
              }
            });

            allNewImages.push(...newImages);
            group.setImages([...group.getImages(), ...newImages]);
            group.getAllImages().forEach((image, i) => {
              image.zIndex = nImages.length - i;
              image.infoPanel?.delete();
              image.infoPanel = undefined;
            });
            group.sortChildren();

            if (!this.absolutePlotting) {
              newImages.forEach(image => {
                image.alpha = 0;
                const delay = Math.floor(Math.random() * (this.maxAnimationDelay * 1000)) / 1000;
                this.rankNChangeAnimationTimeline.to(
                  image,
                  {
                    duration: this.imageAnimationTime,
                    delay,
                    alpha: 1
                  },
                  'animationStart'
                );
              });
            }
          });
        this.imageRefs = [...allNewImages, ...allOldImages];
        this.imageRefsForHighlight = [...this.imageRefs];
        this.imageSortByValues = this.imageRefs.map(image => image.getData().sortByValue).sort((a, b) => b - a);

        if (this.absolutePlotting) {
          this.absolutePlottingPointsSubject.next(this.imageSortByValues);
          this.absolutePlottingOrientationSubject.next(this.groupOrientation === GroupOrientation.Horizontal);

          const max = 0;
          const min = this.absolutePlottingLabelSize / this.viewport.scale.x - this.imageDesiredSizes.width;

          allOldImages.forEach(image => {
            const sortByVal = image.getData().sortByValue;
            const percent = parseFloat(
              (
                (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
                (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
              ).toFixed(2)
            );

            const animateData =
              this.groupOrientation == GroupOrientation.Horizontal
                ? { x: min + (max - min) * percent }
                : { y: min + (max - min) * percent };

            this.rankNChangeAnimationTimeline.to(
              image,
              {
                duration: this.imageAnimationTime,
                ...animateData
              },
              'animationStart'
            );
          });
          allNewImages.forEach(image => {
            image.alpha = 0;
            const sortByVal = image.getData().sortByValue;
            const percent = parseFloat(
              (
                (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
                (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
              ).toFixed(2)
            );

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.groupOrientation == GroupOrientation.Horizontal
              ? (image.x = min + (max - min) * percent)
              : (image.y = min + (max - min) * percent);

            this.rankNChangeAnimationTimeline.to(
              image,
              {
                duration: this.imageAnimationTime,
                alpha: 1
              },
              'animationStart'
            );
          });
        }
      });
    });
  }

  private animateNChangeRemove() {
    const maxNumImagesInGroupOld = this.maxNumImagesInGroup;
    this.animateNChangeBase();
    const allImagesToLeave = [];
    this.groupContainerRefs
      .filter(group => group.isLast)
      .forEach(group => {
        const nImages = this.getNImages<IGroupImageModel>(group.imageModels);
        group.labelOffset = this.groupOrientation === GroupOrientation.Vertical ? this.numEntries - nImages.length : 0;

        const emptyImageOffsetOld = this.rankDockingTopOrRight ? maxNumImagesInGroupOld - group.getImages().length : 0;

        const nImageIds = nImages.map(data => data.prodId);
        const imagesToLeave = group.getImages().filter(image => nImageIds.includes(image.getId()));
        allImagesToLeave.push(...imagesToLeave);
        const imagesToRemove = group.getImages().filter(image => !nImageIds.includes(image.getId()));
        group.setImages(imagesToLeave);

        const emptyImageOffsetNew = this.rankDockingTopOrRight ? this.maxNumImagesInGroup - imagesToLeave.length : 0;
        const emptyImageOffset = emptyImageOffsetOld - emptyImageOffsetNew;

        if (!this.absolutePlotting) {
          const nOffsetForOldImages = !this.rankDockingTopOrRight ? 0 : imagesToRemove.length + emptyImageOffset;
          imagesToLeave.forEach((image, i) => {
            image.zIndex = nImages.length - i;
            if (this.groupOrientation === GroupOrientation.Vertical) {
              image.y -= nOffsetForOldImages * (this.imageSize + this.distanceBetweenImages);
            } else {
              image.x -=
                nOffsetForOldImages * (this.imageSize + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.x);
            }
          });
        }
        imagesToRemove.forEach(image => {
          if (this.infoPanelShowState === 'canvas') {
            image.infoPanel?.delete();
            image.infoPanel = undefined;
          }
          image.zIndex = 0;
          this.rankNChangeAnimationTimeline.to(
            image,
            {
              duration: this.imageAnimationTime,
              alpha: 0,
              onComplete: () => {
                group.removeChild(image);
              }
            },
            'animationStart'
          );
        });
        group.sortChildren();
      });

    this.imageRefs = [...allImagesToLeave];
    const images = [];
    this.displayData.forEach(group => {
      this.updateProductsToShow(group, images);
    });
    this.productsToShow = [...images];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    this.imageRefsForHighlight = [...this.imageRefs];
    this.imageSortByValues = allImagesToLeave.map(image => image.getData().sortByValue).sort((a, b) => b - a);

    if (this.absolutePlotting) {
      this.absolutePlottingPointsSubject.next(this.imageSortByValues);
      this.absolutePlottingOrientationSubject.next(this.groupOrientation === GroupOrientation.Horizontal);

      const max = 0;
      const min = this.absolutePlottingLabelSize / this.viewport.scale.x - this.imageDesiredSizes.width;

      allImagesToLeave.forEach(image => {
        const sortByVal = image.getData().sortByValue;
        const percent = parseFloat(
          (
            (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
            (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
          ).toFixed(2)
        );

        const animateData =
          this.groupOrientation == GroupOrientation.Horizontal
            ? { x: min + (max - min) * percent }
            : { y: min + (max - min) * percent };

        this.rankNChangeAnimationTimeline.to(
          image,
          {
            duration: this.imageAnimationTime,
            ...animateData
          },
          'animationStart'
        );
      });
    }
  }

  private getNImages<T>(images: Array<T>): Array<T> {
    // eslint-disable-next-line no-nested-ternary
    return !this.rankDockingTopOrRight
      ? images.slice(0, this.numEntries)
      : images.length > this.numEntries
      ? images.slice(images.length - this.numEntries)
      : [...images];
  }

  private animateNChangeBase() {
    this.resetResolution();
    this.maxNumImagesInGroup = Math.min(
      this.numEntries,
      Math.max(...this.groupContainerRefs.filter(g => g.isLast).map(group => group.imageModels.length))
    );
    if (!this.absolutePlotting) {
      this.updateMatrixDimensionsBeforeMatrixUpdate(false);
      this.updateViewport(this.rankNChangeAnimationTimeline);
    } else {
      this.updateViewportAbsolute(this.rankNChangeAnimationTimeline);
      this.animateAbsoluteLabelOnNChange();
    }
    this.updateGroupLocalBounds();
  }

  private updateViewport(tl?: gsap.core.Timeline) {
    const newCenter = {
      x: this.imageMatrix.x + this.newImageMatrixWidth / 2,
      y: this.imageMatrix.y + this.newImageMatrixHeight / 2
    };

    this.newViewportScale = this.viewport.findFit(this.newImageMatrixWidth / 0.6, this.newImageMatrixHeight / 0.6);
    if (tl) {
      const scale = {
        x: this.viewport.scale.x,
        y: this.viewport.scale.y
      };
      tl.to(
        scale,
        {
          duration: this.imageAnimationTime,
          x: this.newViewportScale,
          y: this.newViewportScale,
          onUpdate: () => {
            this.viewport.scale.set(scale.x);
            this.viewport.emit('zoomed', { viewport: this.viewport, type: 'wheel' });
          }
        },
        'animationStart'
      );
    } else {
      this.viewport.scale.set(this.newViewportScale);
      this.viewport.emit('zoomed', { viewport: this.viewport, type: 'wheel' });
    }
    this.updateViewportCenter(newCenter, tl);
  }

  private updateViewportAbsolute(tl?: gsap.core.Timeline) {
    const newCenter = {
      x: this.imageMatrix.x + this.imageMatrix.width / 2,
      y: this.imageMatrix.y + this.imageMatrix.height / 2
    };
    this.updateViewportCenter(newCenter, tl);
  }

  private updateViewportCenter(newCenter: IPosition, tl?: gsap.core.Timeline) {
    if (tl) {
      const centerData = {
        x: this.viewport.center.x,
        y: this.viewport.center.y
      };
      tl.to(
        centerData,
        {
          duration: this.imageAnimationTime,
          x: newCenter.x,
          y: newCenter.y,
          onUpdate: () => {
            this.viewport.moveCenter(centerData.x, centerData.y);
            this.viewport.emit('moved', { viewport: this.viewport, type: 'wheel' });
          }
        },
        'animationStart'
      );
    } else {
      this.viewport.moveCenter(newCenter.x, newCenter.y);
      this.viewport.emit('moved', { viewport: this.viewport, type: 'wheel' });
    }
  }

  private animateAbsoluteLabelOnNChange() {
    const imageMatrixBounds: IBounds = {
      x:
        (this.window.innerWidth - this.imageMatrix.width * this.viewport.scale.x) / 2 -
        (this.imageDesiredSizes.width / 2) * this.viewport.scale.x,
      y:
        (this.window.innerHeight - this.imageMatrix.height * this.viewport.scale.x) / 2 -
        (this.imageDesiredSizes.height / 2) * this.viewport.scale.x,
      width: (this.imageMatrix.width + this.imageDesiredSizes.width / 2) * this.viewport.scale.x,
      height: (this.imageMatrix.height + this.imageDesiredSizes.height / 2) * this.viewport.scale.x
    };
    this.updateAbsolutePlottingPosition(imageMatrixBounds, this.rankNChangeAnimationTimeline);
  }

  public override setRankDirection(dockingBottomOrLeft: boolean, updateMatrix: boolean = false) {
    this.rankDockingTopOrRight = !dockingBottomOrLeft;
    this.groupLabelsContainer.setDockingSide(this.rankDockingTopOrRight);
    if (updateMatrix && this.displayData?.length > 0) {
      this.saveHoverInfoData(this.displayData);
      this.hideAllHtmlElementsBeforeMatrixUpdate();
      this.groupLabelsContainer.updateDockingSide();

      this.groupContainerRefs
        .filter(group => group.isLast)
        .forEach(group => {
          group.removeChildren();
          group.setImages([]);
        });
      this.updateBaseRefs();
      this.productsToShow = [];
      this.productsToShow$.next(new Set());
      this.highlitedProductIDs = new Set();
      this.imageRefsForHighlight = [];
      this.highlightedProducts$.next(new Set());
      this.imageRefs = [];
      this.imageViewOverridesWithId = {};
      this.imageViewOverridesWithIndex = [];
      this.render();

      const images = [];
      this.displayData.forEach(group => {
        this.updateProductsToShow(group, images);
      });
      this.productsToShow = [...images];
      const bids: Set<string> = new Set();
      this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
      this.productsToShow$.next(bids);
      this.resourceManagerService.stopActiveLoaders();
      const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);
      this.resourceManagerService.loadResources(resources, null, null, () => {
        this.groupContainerRefs
          .filter(group => group.isLast)
          .forEach(group => {
            group.setImages(this.createGroupImages(group.imageModels, group, false));
          });
        this.updateGroupCoordinates();
        this.updateGroupLocalBounds();
        this.imageSortByValues = this.imageRefs.map(image => image.getData().sortByValue).sort((a, b) => b - a);
        if (this.absolutePlotting) {
          this.absolutePlottingPointsSubject.next(this.imageSortByValues);
          this.absolutePlottingOrientationSubject.next(this.groupOrientation === GroupOrientation.Horizontal);

          const max = 0;
          const min = this.absolutePlottingLabelSize / this.viewport.scale.x - this.imageDesiredSizes.width;

          this.imageRefs.forEach(image => {
            const sortByVal = image.getData().sortByValue;
            const percent = parseFloat(
              (
                (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
                (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
              ).toFixed(2)
            );

            if (this.groupOrientation == GroupOrientation.Horizontal) {
              image.x = min + (max - min) * percent;
            } else {
              image.y = min + (max - min) * percent;
            }
          });
        }
        this.onMatrixUpdateComplete();
        this.updateMatrixBase(false);
        this.restoreHoverInfoData(this.displayData);
        this.animateImages();
        this.animateLabels();
      });
    }
  }

  private saveHoverInfoData(ownerGroup: any) {
    ownerGroup?.forEach(group => {
      group.groups?.forEach(subGroup => {
        subGroup.images?.forEach(image => {
          image.data._hoverInfo = image.data.hoverInfo; // save current value of Hover Info for each image
        });
        if (subGroup.groups) {
          this.saveHoverInfoData(subGroup.groups);
        }
      });
    });
  }

  private restoreHoverInfoData(ownerGroup: any) {
    ownerGroup?.forEach(group => {
      group.groups?.forEach(subGroup => {
        subGroup.images?.forEach(image => {
          image.data.hoverInfo = image.data._hoverInfo; // restore Hover Info for each image
        });
        if (subGroup.groups) {
          this.restoreHoverInfoData(subGroup.groups);
        }
      });
    });
  }

  public override setRankAbsolutePlotting(absolute: boolean) {
    // Does not check type to avoid problems with 'false' and 'undefined' being different
    if (this.absolutePlotting != absolute) {
      this.storeAllGroupsInfoLockedStateAndHide();
      this.absolutePlotting = absolute;
      if (this.displayData?.length > 0 && this.imageRefs.length > 0) this.animatePlottingChange();
    }
  }

  public setDisplayData(data: Array<IRankGroupModel>, restore: boolean, saveSorting: boolean = false): void {
    this.isUpdating.next(true);
    this.clearMatrix();
    this.displayData = restore || saveSorting ? data || [] : this.sortGroupsByValue(data);
    this.updateBaseRefs();
    if (restore) {
      this.restoreMatrix();
    } else {
      if (this.store.selectSnapshot(StudyState.isStudyEdited) === false) {
        this.isDefaultRender = true;
        this.store.dispatch(new SetStudyEdited(true));
      }
      this.createMatrix(false, saveSorting);
    }
  }

  private sortGroupsByValue(groups: Array<IRankGroupModel>): Array<IRankGroupModel> {
    if (!groups) return [];
    return groups
      .map((group: IRankGroupModel) => {
        if (group.groups) {
          const subGroups = this.sortGroupsByValue(group.groups);
          return {
            ...group,
            groups: subGroups,
            sortByValue: subGroups.reduce(
              (result: number, subGroup: IRankGroupModel) => result + subGroup.sortByValue,
              0
            )
          };
        }
        const nImages = this.getNImages<IGroupImageModel>(group.images);
        return {
          ...group,
          sortByValue: nImages.reduce((result: number, image: IGroupImageModel) => result + image.data.sortByValue, 0)
        };
      })
      .sort((groupA, groupB) =>
        this.rankDockingTopOrRight ? groupA.sortByValue - groupB.sortByValue : groupB.sortByValue - groupA.sortByValue
      );
  }

  protected createMatrix(restore: boolean = false, saveSorting: boolean = false) {
    if (restore || saveSorting) {
      const groupStates: Array<IGroupStateModel> = this.store.selectSnapshot(StudyState.getGroups);
      this.displayData = this.restoreGroupsSorting(this.displayData, groupStates);
    }
    const images = [];
    this.displayData.forEach(group => {
      this.updateProductsToShow(group, images);
    });
    this.productsToShow = [...images];
    const bids: Set<string> = new Set();
    this.productsToShow.forEach(imageData => bids.add(imageData.prodId));
    this.productsToShow$.next(bids);
    setTimeout(() => {
      this.loadProductImages(restore, saveSorting);
    });
  }

  protected restoreMatrix() {
    this.imageViewOverridesWithIndex = this.matrixState.imageMatrix.images.map(image => image.view);
    this.createMatrix(true);
  }

  public override clearMatrix(removeContainers = true) {
    this.removeInfoPanelsOnCanvas();
    super.clearMatrix(removeContainers);
    this.displayData = undefined;
    this.numImageContainer = 0;
    if (removeContainers) {
      this.groupContainerRefs.forEach(group => {
        group.delete();
        group = null;
      });
      this.groupContainerRefs = [];
      this.groupContainerRefsByLevels = [];
    }
    this.groupLabelRefs = [];
    this.groupLabelsContainer.clearGroupLabels();
    this.absolutePlottingLabelSize = -1;
    this.clearAbsolutePlottingLabel();
    this.isDefaultRender = false;

    this.render();
  }

  private clearAbsolutePlottingLabel() {
    if (!this.absolutePlottingLabelComponentRef) return;
    this.absolutePlottingLabel.parentElement.removeChild(this.absolutePlottingLabel);
    delete this.absolutePlottingLabel;
    this.absolutePlottingLabel = null;
    this.absolutePlottingLabelComponentRef.destroy();
    delete this.absolutePlottingLabelComponentRef;
    this.absolutePlottingLabelComponentRef = null;
  }

  private updateProductsToShow(group, images) {
    if (group.images) {
      const nImages = !this.rankDockingTopOrRight
        ? group.images.slice(0, this.numEntries)
        : group.images.length > this.numEntries
        ? group.images.slice(group.images.length - this.numEntries)
        : [...group.images];

      nImages.forEach(image => {
        images.push(image);
      });
    } else if (group.groups) {
      group.groups.forEach(groupItem => {
        this.updateProductsToShow(groupItem, images);
      });
    }
  }

  private restoreGroupsSorting(
    groupModels: Array<IRankGroupModel>,
    groupStates: Array<IGroupStateModel>
  ): Array<IRankGroupModel> {
    if (!groupStates) return [...groupModels];
    return groupModels
      .map((groupModel, index) => {
        const groupState = groupStates.find(state => state.label.value === groupModel.name.value);
        return {
          ...groupModel,
          id: groupState?.id || shortUid(),
          groups:
            groupState && groupModel.groups
              ? this.restoreGroupsSorting(groupModel.groups, groupState.groups)
              : groupModel.groups,
          order: groupState ? groupState.order : index
        };
      })
      .sort((a, b) => a.order - b.order);
  }

  private loadProductImages(restore: boolean, saveSorting: boolean) {
    if (this.loader) this.resourceManagerService.stopLoader(this.loader);
    const resources = this.createLoaderResources(LOW_IMAGE_SIZE_RESOURCE);

    if (resources.length > 0) this.sendLowResImageLoadingStart();

    this.loader = this.resourceManagerService.loadResources(
      resources,
      null,
      null,
      this.onProductLoadComplete.bind(this, restore, saveSorting)
    );
  }

  private onProductLoadComplete(restore: boolean, saveSorting: boolean) {
    if (this.matrixType !== ValidMatrix.Rank) {
      // bugfix: if a few previous Study used other Matrix type, all of them already subscribed to the Product Load Complete
      // so, do nothing if it's not a current matrix
      return;
    }
    this.clearCompass.next(false);
    this.isUpdating.next(false);
    this.sendLowResImageLoadingCompleted();
    this.sendDataLoadingCompleted();

    this.maxNumImagesInGroup = 0;
    this.groupContainerRefsByLevels.push([]);
    this.imageIndexInCanvasState = 0;
    this.createGroups(this.displayData, this.imageMatrix, 0, restore || saveSorting);
    this.groupContainerRefsByLevels[0].forEach(group => {
      group.isFirst = true;
    });

    this.imageSortByValues = this.imageRefs.map(image => image.getData().sortByValue).sort((a, b) => b - a);

    this.updateGroupOffsetsForInfoPanel();
    this.groupLabelsContainer.setDockingSide(this.rankDockingTopOrRight);
    this.updateGroupCoordinates();
    this.updateMatrixDimensionsOnInitialRender(restore);
    this.updateGroupLocalBounds();
    this.groupLabelsContainer.resetLabelsByLevel(this.groupContainerRefsByLevels.length - 1);
    const hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    this.createLabels(this.groupContainerRefsByLevels[0], hoverInfoConfig.length === 0, true);
    // this.checkLabelsVisibility(true);
    this.groupLabelsContainer?.showLabels();
    this.createAbsolutePlottingLabel();
    if (this.infoPanelShowState === 'canvas') this.addInfoPanelsOnCanvas();
    this.animation = false;

    // eslint-disable-next-line no-unused-expressions
    // this.infoPanelShowState === 'hover' ? this.removeInfoPanelsOnCanvas() : this.addInfoPanelsOnCanvas();

    this.imageRefsForHighlight = [...this.imageRefs];

    if (this.absolutePlotting) {
      const max = 0;
      const min =
        this.groupOrientation == GroupOrientation.Horizontal
          ? (this.matrixState ? this.matrixState.imageMatrix.dimensions.width : this.imageMatrix.width) -
            (this.imageDesiredSizes.width + this.distanceBetweenImages)
          : (this.matrixState ? this.matrixState.imageMatrix.dimensions.height : this.imageMatrix.height) -
            (this.imageDesiredSizes.width + this.distanceBetweenImages);

      const scale = this.matrixState
        ? this.matrixState.tools.viewportScale
        : this.window.innerHeight / (this.matrixResizerSpriteRef.height / 3);

      if (
        this.matrixState &&
        this.matrixState.tools.rankAbsolutePlottingLabelSize &&
        this.matrixState.tools.rankAbsolutePlottingLabelSize > 0
      ) {
        this.absolutePlottingLabelSize = this.matrixState.tools.rankAbsolutePlottingLabelSize;
      } else {
        this.absolutePlottingLabelSize = (min + this.imageDesiredSizes.width - max) * scale;
      }

      this.imageRefs.forEach((image, i) => {
        const sortByVal = image.getData().sortByValue;
        const percent = parseFloat(
          (
            (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
            (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
          ).toFixed(2)
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.groupOrientation == GroupOrientation.Horizontal
          ? (image.x = min + (max - min) * percent)
          : (image.y = min + (max - min) * percent);
      });

      if (this.active) {
        this.absolutePlottingLabel.style.display = 'flex';
        this.absolutePlottingLabel.style.opacity = '1';

        const imageSize = this.imageDesiredSizes.width * scale;
        setTimeout(() => {
          this.absolutePlottingSizeSubject.next({
            labelSize: this.absolutePlottingLabelSize,
            imageSize
          });
          this.updateAbsolutePlottingPosition(this.getImageMatrixBounds());
        });
      }
    }

    if (!this.active) return;

    const imageMatrixBounds = this.getImageMatrixBounds();
    this.updateGroupBounds(imageMatrixBounds);

    if (this.isDefaultRender) {
      this.isDefaultRender = false;
      this.groupLabelsContainer?.hideLabels(true);
      this.animateImagesForDefaultView();
    } else {
      this.updateLabelsOnZoom(true);
      if (!this.matrixState && !this.absolutePlotting) {
        this.animateImages();
        this.animateLabels();
      }
    }

    this.matrixState = undefined;

    if (!restore) {
      if (saveSorting) {
        this.updateCanvasStateWithGroups();
      } else {
        this.setCanvasStateWithGroups();
      }
    }

    setTimeout(() => {
      this.onZoomChange(this.zoomFactor, true, true);
      this.onViewportMove();
      this.render();
    });

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    }
  }

  private animateImages() {
    const groups = this.groupContainerRefs.filter(group => group.isLast);
    const labelsTotalAnimationTime = this.groupContainerRefsByLevels.length * this.imageAnimationTime;
    this.isUpdating.next(true);
    const tl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      },
      onComplete: () => {
        this.isUpdating.next(false);
      }
    });
    tl.addLabel('animationStart', 0);
    groups.forEach((group: GroupContainer) => {
      if (!this.rankDockingTopOrRight) group.children.reverse();
      group.children.forEach((image: ImageContainer) => {
        image.alpha = 0;
        let delay = Math.floor(Math.random() * (this.maxAnimationDelay * 1000)) / 1000;
        delay += labelsTotalAnimationTime;
        /**
         * Last parameter in tl.to function controls the start of animation relative to other animations or custom labels
         * By default animation starts at the end of previous animation
         * tl.addLabel adds custom label or waypoint in timeline so we can set animation times relative to it
         * we are marking begining of timeline with 'animationStart' label
         * and settinig all animations to start at that label, i.e. at the same time, in the begining of timeline
         *
         * we are also adding somewhat random delay to each animation to achieve images popping randomly
         *
         * another way of achieving same result would be to add delay to the third parameter (start of animation in timeline)
         * instead of adding it the animation itself, like 'animationStart+=delay'
         */
        tl.to(
          image,
          {
            duration: this.imageAnimationTime,
            delay,
            alpha: 1
          },
          'animationStart'
        );
        if (image.infoPanel?.infoHtmlRef) {
          image.infoPanel.infoHtmlRef.style.opacity = '0';
          tl.to(
            image.infoPanel.infoHtmlRef,
            {
              duration: this.imageAnimationTime,
              delay,
              opacity: 1
            },
            'animationStart'
          );
        }
      });
      if (!this.rankDockingTopOrRight) group.children.reverse();
    });
  }

  private animateImagesForDefaultView() {
    this.isDefaultRenderAnimating = true;

    const tl = gsap.timeline({
      ease: 'none',
      onUpdate: () => {
        this.render();
      },
      onComplete: () => {
        this.isDefaultRenderAnimating = false;
        setTimeout(() => {
          this.onZoomChange(this.zoomFactor, true, true);
          this.onViewportMove();
          this.groupLabelsContainer?.showLabels();
          this.render();
        });
      }
    });
    tl.addLabel('animationStart', 0);

    const groups = this.groupContainerRefs.filter(group => group.isLast);

    const centerI = (groups.length - 1) / 2;
    const centerJ = (this.numEntries - 1) / 2;

    groups.forEach((group: GroupContainer, i) => {
      if (!this.rankDockingTopOrRight) group.children.reverse();
      group.children.forEach((image: ImageContainer, j) => {
        image.alpha = 0;
        let animationRandomness = Math.floor(Math.random() * (this.defaultRenderMaxAnimationRandomness * 1000)) / 1000;
        let delay =
          Math.max(Math.floor(Math.abs(centerI - i)), Math.floor(Math.abs(centerJ - j))) *
            this.defaultRenderDelayMultiplier +
          animationRandomness;
        tl.to(
          image,
          {
            duration: this.defaultRenderImageAnimationTime,
            delay,
            alpha: 1
          },
          'animationStart'
        );
      });
      if (!this.rankDockingTopOrRight) group.children.reverse();
    });

    const maxAnimationDelay =
      Math.max(Math.floor(centerI), Math.floor(centerJ)) * this.defaultRenderDelayMultiplier +
      this.defaultRenderMaxAnimationRandomness;
    const totalAnimationTime = parseFloat((maxAnimationDelay + this.imageAnimationTime).toFixed(2));

    this.animateViewportForDefaultView(tl, totalAnimationTime);
  }

  private animateViewportForDefaultView(tl, totalAnimationTime) {
    const center = {
      x: this.imageMatrix.x + (this.imageMatrix.width - 40) / 2,
      y: this.imageMatrix.y + (this.imageMatrix.height - this.imageDesiredSizes.width - 40) / 2
    };
    const savedCenter = {
      x: this.viewport.center.x,
      y: this.viewport.center.y
    };
    this.viewport.moveCenter(center.x, center.y);

    tl.to(
      center,
      {
        duration: totalAnimationTime,
        ease: 'power4.in',
        x: savedCenter.x,
        y: savedCenter.y,
        onUpdate: () => {
          this.viewport.moveCenter(center.x, center.y);
        }
      },
      'animationStart'
    );

    const scaleX =
      this.displayData.length % 2 !== 0
        ? this.window.innerWidth / (2 * this.imageDesiredSizes.width)
        : this.window.innerWidth / (1.2 * (2 * this.imageDesiredSizes.width + this.groupContGapBase));
    const scale = {
      x: scaleX,
      y: scaleX
    };
    const savedScale = this.viewport.scale.x;
    this.viewport.setZoom(scale.x, true);

    tl.to(
      scale,
      {
        duration: totalAnimationTime,
        ease: 'none',
        x: savedScale,
        y: savedScale,
        onUpdate: () => {
          this.viewport.setZoom(scale.x, true);
        }
      },
      'animationStart'
    );
  }

  private animatePlottingChange() {
    const animationDuration = 0.28;

    this.removeInfoPanelsOnCanvas();
    this.plottingAnimationTimeline?.kill();
    this.plottingAnimationTimeline = gsap.timeline({
      ease: 'power2.inOut',
      onUpdate: () => {
        this.savedImageMatrixWidth = this.imageMatrix.width;
        this.savedImageMatrixHeight = this.imageMatrix.height;
        this.imageMatrix.position.set(
          (this.matrixResizerSpriteRef.width - this.imageMatrix.width) / 2,
          (this.matrixResizerSpriteRef.height - this.imageMatrix.height) / 2
        );
        const imageMatrixBounds = this.getImageMatrixBounds();

        if (this.infoPanelShowState === 'canvas') {
          this.updateGroupLocalBounds();
          this.updateGroupBounds(imageMatrixBounds);
          this.updateAbsolutePlottingPosition(imageMatrixBounds);
        }
        this.moveLabels(imageMatrixBounds);
        this.render();
      },
      onComplete: () => {
        if (!this.absolutePlotting) this.absolutePlottingLabel.style.display = 'none';
        this.updateCanvasStateWithGroups();
        this.addInfoPanelsOnCanvas();
        this.restoreAllGroupsInfoLockedState();
      }
    });

    this.plottingAnimationTimeline.addLabel('animationStart', 0);

    const animationData = [];

    // TODO check if can be refactored with createGroupImages function
    if (this.absolutePlotting) {
      const maxPos = 0;
      const minPos = this.getMinImagePositionInGroup();

      this.setInitialAbsolutePlottingSize(minPos, maxPos);
      this.updateAbsolutePlottingPosition(this.getImageMatrixBounds());

      this.imageRefs.forEach(image => {
        const sortByVal = image.getData().sortByValue;
        const percent = parseFloat(
          (
            (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
            (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
          ).toFixed(2)
        );
        if (this.groupOrientation === GroupOrientation.Horizontal) {
          animationData.push({ image, x: minPos + (maxPos - minPos) * percent });
        } else {
          animationData.push({ image, y: minPos + (maxPos - minPos) * percent });
        }
      });
    } else {
      this.groupContainerRefs
        .filter(group => group.isLast)
        .forEach(group => {
          const images = group.getImages();
          const nImages = this.getNImages<ImageContainer>(images);

          const imageOffset = this.rankDockingTopOrRight ? this.numEntries - nImages.length : 0;

          nImages.forEach((image, i) => {
            if (this.groupOrientation === GroupOrientation.Horizontal) {
              const x =
                (i + imageOffset) * (this.imageSize + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.x);
              animationData.push({ image, x });
            } else {
              const y =
                (i + imageOffset) * (this.imageSize + this.distanceBetweenImages + this.imageOffsetsForInfoPanel.y);
              animationData.push({ image, y });
            }
          });
        });

      const currentPos =
        this.groupOrientation === GroupOrientation.Horizontal ? this.imageMatrix.x : this.imageMatrix.y;
      const oldImageMatrixSize =
        this.groupOrientation === GroupOrientation.Horizontal ? this.imageMatrix.width : this.imageMatrix.height;
      const newImageMatrixSize =
        this.getMinImagePositionInGroup() + this.imageDesiredSizes.width + this.distanceBetweenImages + 12;
      const newPos = currentPos + (oldImageMatrixSize - newImageMatrixSize) / 2;
      gsap.killTweensOf(this.imageMatrix);
      const imageMatrixAnimationData =
        this.groupOrientation === GroupOrientation.Horizontal ? { x: newPos } : { y: newPos };
      this.plottingAnimationTimeline.to(
        this.imageMatrix,
        {
          duration: animationDuration,
          ...imageMatrixAnimationData
        },
        'animationStart'
      );
    }

    animationData.forEach(item => {
      gsap.killTweensOf(item.image);
      const data = {
        duration: animationDuration,
        ...(item.x !== undefined ? { x: item.x } : null),
        ...(item.y !== undefined ? { y: item.y } : null)
      };

      this.plottingAnimationTimeline.to(item.image, data, 'animationStart');
    });

    this.updateGroupOffsetsForInfoPanel();

    if (this.infoPanelShowState === 'canvas') {
      this.setNewGroupsSizes(false);

      if (this.groupOrientation === GroupOrientation.Vertical) {
        this.updateGroupCoordinatesOnPlottingChangeVertical(this.imageMatrix, this.plottingAnimationTimeline);
      } else {
        this.updateGroupCoordinatesOnPlottingChangeHorizontal(this.imageMatrix, this.plottingAnimationTimeline);
      }
      this.render();
    }

    if (this.absolutePlotting) this.absolutePlottingLabel.style.display = 'flex';
    gsap.killTweensOf(this.absolutePlottingLabel);
    this.plottingAnimationTimeline.to(
      this.absolutePlottingLabel,
      {
        duration: animationDuration,
        opacity: this.absolutePlotting ? 1 : 0
      },
      'animationStart'
    );
  }

  private updateGroupCoordinatesOnPlottingChangeVertical(parenGroupCont, tl: gsap.core.Timeline) {
    if (!parenGroupCont.isLast) {
      let subX = 0;
      parenGroupCont.children.forEach((groupCont: GroupContainer) => {
        tl.to(
          groupCont,
          {
            duration: this.imageAnimationTime,
            x: subX,
            y: 0
          },
          'animationStart'
        );

        this.updateGroupCoordinatesOnPlottingChangeVertical(groupCont, tl);
        const width = groupCont.getNewSize();
        subX += width + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.x;
      });
    }
  }

  private updateGroupCoordinatesOnPlottingChangeHorizontal(parenGroupCont, tl: gsap.core.Timeline) {
    if (!parenGroupCont.isLast) {
      let subY = 0;
      parenGroupCont.children.forEach((groupCont: GroupContainer) => {
        tl.to(
          groupCont,
          {
            duration: this.imageAnimationTime,
            x: 0,
            y: subY
          },
          'animationStart'
        );

        this.updateGroupCoordinatesOnPlottingChangeHorizontal(groupCont, tl);
        const height = groupCont.getNewSize();
        subY += height + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.y;
      });
    }
  }

  private createLabels(
    groups: Array<GroupContainer>,
    quickStatsHidden: boolean,
    hideLabels: boolean = false,
    parent: GroupLabel = null
  ) {
    const subGroupLabels = [];
    const maxLevel = this.groupContainerRefsByLevels.length - 1;
    groups.forEach(group => {
      const groupLabel = this.groupLabelsContainer.createLabel(group, maxLevel, quickStatsHidden, hideLabels);
      groupLabel.createInfoOverlay();
      if (parent) groupLabel.setParentGroupLabel(parent);
      this.addGroupEventListeners(group);
      this.groupLabelRefs.push(groupLabel);
      subGroupLabels.push(groupLabel);
      const subGroups = group.getGroups();
      if (subGroups.length > 0) {
        this.createLabels(subGroups, quickStatsHidden, hideLabels, groupLabel);
      }
    });
    if (parent) parent.setChildGroupLabels(subGroupLabels);
  }

  private animateLabels() {
    if (!this.labelsHidden) {
      this.groupLabelsContainer.showLabelsAnimation(this.imageAnimationTime);
    }
  }

  private createAbsolutePlottingLabel() {
    const componentFactory = this.factoryResolver.resolveComponentFactory(RankAbsolutePlottingLabelComponent);
    this.absolutePlottingLabelComponentRef = this.viewContainerRef.createComponent(componentFactory);
    this.absolutePlottingLabel = this.absolutePlottingLabelComponentRef.location.nativeElement;
    getMatrixOverlayContainer().appendChild(this.absolutePlottingLabel);
    this.absolutePlottingLabel.style.display = 'none';
    this.absolutePlottingLabel.style.opacity = '0';

    setTimeout(() => {
      this.absolutePlottingPointsSubject.next(this.imageSortByValues);
      this.absolutePlottingOrientationSubject.next(this.groupOrientation === GroupOrientation.Horizontal);
    });
  }

  private updateAbsolutePlottingPosition(imageMatrixBounds: IBounds, tl?: gsap.core.Timeline) {
    imageMatrixBounds.x += dropShadowPadding * this.resolution * this.viewport.scale.x;
    imageMatrixBounds.y += dropShadowPadding * this.resolution * this.viewport.scale.x;
    const data = tl
      ? {
          imageMatrixBounds,
          tl
        }
      : {
          imageMatrixBounds
        };
    this.absolutePlottingPositionSubject.next(data);
  }

  public onAbsolutePlottingLabelResize($event: ResizeEvent) {
    this.absolutePlottingLabelSize =
      this.groupOrientation == GroupOrientation.Horizontal ? $event.rectangle.width : $event.rectangle.height;

    const oldHeight = this.imageMatrix.height;
    const imageMatrixPositionFromCenter = {
      x: this.viewport.center.x - this.imageMatrix.x,
      y: this.viewport.center.y - (this.imageMatrix.y + this.imageMatrix.height)
    };
    this.updateImagesOnAbsolutePlotting();

    const { top, left } = $event.rectangle;
    const labelPosOnMatrix = this.viewport.toWorld(left, top);
    let centerX = this.viewport.center.x;
    let centerY = this.viewport.center.y;

    if (this.groupOrientation == GroupOrientation.Horizontal) {
      const diff = this.imageMatrix.x - this.imageDesiredSizes.width / 2 - labelPosOnMatrix.x;
      centerX = this.imageMatrix.x + imageMatrixPositionFromCenter.x + diff;
    } else {
      centerY =
        this.imageMatrix.y + ($event.edges.top ? this.imageMatrix.height : oldHeight) + imageMatrixPositionFromCenter.y;
    }

    this.viewport.moveCenter(centerX, centerY);
    this.moveLabels(this.getImageMatrixBounds());

    this.render();
  }

  public onAbsolutePlottingLabelResizeEnd() {
    this.updateCanvasStateWithGroups();
  }

  private updateImagesOnAbsolutePlotting() {
    const max = 0;
    const min = this.absolutePlottingLabelSize / this.viewport.scale.x - this.imageDesiredSizes.width;

    this.imageRefs.forEach(image => {
      const sortByVal = image.getData().sortByValue;
      const percent = parseFloat(
        (
          (sortByVal - this.imageSortByValues[this.imageSortByValues.length - 1]) /
          (this.imageSortByValues[0] - this.imageSortByValues[this.imageSortByValues.length - 1])
        ).toFixed(2)
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.groupOrientation == GroupOrientation.Horizontal
        ? (image.x = min + (max - min) * percent)
        : (image.y = min + (max - min) * percent);
    });

    this.imageMatrix.position.set(
      (this.matrixResizerSpriteRef.width - this.imageMatrix.width) / 2,
      (this.matrixResizerSpriteRef.height - this.imageMatrix.height) / 2
    );
  }

  private createGroups(
    groupModels: Array<IRankGroupModel>,
    parent: Container | GroupContainer,
    level: number,
    restoreOrSaveSorting: boolean
  ): Array<GroupContainer> {
    const groups: Array<GroupContainer> = [];
    groupModels.forEach((groupModel: IRankGroupModel, index: number) => {
      groups.push(this.createGroup(groupModel, parent, level, restoreOrSaveSorting, index + 1));
    });
    parent.sortChildren();
    return groups;
  }

  private createGroup(
    groupModel: IRankGroupModel,
    parent: Container | GroupContainer,
    level: number,
    restoreOrSaveSorting: boolean,
    zIndex: number
  ): GroupContainer {
    const groupCont = new GroupContainer(groupModel.id, groupModel, level, this.friendlyNameService);
    parent.addChild(groupCont);
    this.groupContainerRefs.push(groupCont);
    this.groupContainerRefsByLevels[level].push(groupCont);
    if (groupModel.groups && groupModel.groups.length > 0) {
      if (!this.groupContainerRefsByLevels[level + 1]) {
        this.groupContainerRefsByLevels.push([]);
      }
      const subGroups = this.createGroups(groupModel.groups, groupCont, level + 1, restoreOrSaveSorting);
      groupCont.setGroups(subGroups);
      groupCont.labelOffset = Math.min(...subGroups.map(item => item.labelOffset));
    } else if (groupModel.images && groupModel.images.length > 0) {
      groupCont.setImages(this.createGroupImages(groupModel.images, groupCont, restoreOrSaveSorting));
    }
    groupCont.zIndex = zIndex;
    return groupCont;
  }

  private createGroupImages(
    groupImages: Array<IGroupImageModel>,
    parent: GroupContainer,
    restoreOrSaveSorting: boolean
  ) {
    const nImages = this.getNImages<IGroupImageModel>(groupImages);

    if (nImages.length > this.maxNumImagesInGroup) this.maxNumImagesInGroup = nImages.length;

    const imageOffset = this.rankDockingTopOrRight ? this.maxNumImagesInGroup - nImages.length : 0;

    parent.labelOffset = this.groupOrientation === GroupOrientation.Vertical ? this.numEntries - nImages.length : 0;

    const images = [];
    nImages.forEach((imageData: IGroupImageModel | null, index: number) => {
      const uid = restoreOrSaveSorting
        ? this.store.selectSnapshot(StudyState.getImageMatrix).images[this.imageIndexInCanvasState]?.uid
        : shortUid();
      this.imageIndexInCanvasState++;
      const image = this.createImage(
        uid,
        imageData.prodId,
        imageData.data.displayName,
        this.getImagePositionInGroup(index, imageOffset),
        parent,
        imageData.data,
        this.lookupTable.getImagePlaceholder()
      );
      image.alpha = 1;
      image.zIndex = nImages.length - index;
      this.setupImageListeners(image);
      images.push(image);
    });
    parent.sortChildren();
    parent.isLast = true;
    parent.imageModels = groupImages;
    return images;
  }

  protected updateImageView(name) {
    const prodId = name.slice(0, name.lastIndexOf('_'));
    const images = this.imageRefs.filter(imageCont => imageCont.getId() === prodId);
    if (!images || images.length === 0) return;

    images.forEach(image => image.updateView(this.resourceManagerService, this.selectedView, this.render.bind(this)));
    this.render();
  }

  public onIndividualImageViewChange(image: ImageContainer): void {
    this.updateImageInCanvasState(image);
  }

  private setupImageListeners(image: ImageContainer) {
    this.addImageHoverListeners(image);
    this.addImageClickListeners(image);
  }

  private updateGroupCoordinates() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.groupOrientation === GroupOrientation.Vertical
      ? this.updateChildrenCoordinatesVertical(this.imageMatrix)
      : this.updateChildrenCoordinatesHorizontal(this.imageMatrix);
  }

  private updateChildrenCoordinatesVertical(parenGroupCont) {
    if (!parenGroupCont.isLast) {
      let subX = 0;
      parenGroupCont.children.forEach(groupCont => {
        groupCont.position.x = subX;
        this.updateChildrenCoordinatesVertical(groupCont);
        subX = groupCont.x + groupCont.width + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.x;
      });
    }
  }

  private updateChildrenCoordinatesHorizontal(parenGroupCont) {
    if (!parenGroupCont.isLast) {
      let subY = 0;
      parenGroupCont.children.forEach(groupCont => {
        groupCont.position.y = subY;
        this.updateChildrenCoordinatesHorizontal(groupCont);
        subY = groupCont.y + groupCont.height + this.getGroupContGap(groupCont) + this.groupOffsetsForInfoPanel.y;
      });
    }
  }

  private getGroupContGap(groupCont: GroupContainer) {
    const maxLevel = this.groupContainerRefsByLevels.length - 1;
    const currLevel = groupCont.getLevel();
    // base gap is increased by 50% for each level
    return this.groupContGapBase + (maxLevel - currLevel) * this.groupContGapBase * 0.5;
  }

  private getLabelsTotalHeight(): number {
    const maxLevel = this.groupContainerRefsByLevels.length - 1;
    const labelHeight = 30;
    const distanceBetweenLabels = 40;
    return this.groupOrientation === GroupOrientation.Vertical
      ? (maxLevel + 1) * (labelHeight + distanceBetweenLabels)
      : 0;
  }

  private getImageMatrixScale(imageMatrixWidth, imageMatrixHeight): number {
    const labelsTotalHeight = this.getLabelsTotalHeight();
    let scaleW = imageMatrixWidth / (window.innerWidth - 2 * this.imageMatrixPaddings.leftRight);
    scaleW = Math.round((scaleW + Number.EPSILON) * 100) / 100;
    let scaleH =
      imageMatrixHeight /
      (window.innerHeight - this.imageMatrixPaddings.top - this.imageMatrixPaddings.bottom - labelsTotalHeight);
    scaleH = Math.round((scaleH + Number.EPSILON) * 100) / 100;
    return scaleW > scaleH ? scaleW : scaleH;
  }

  restoreViewport(viewPort: Viewport, zoom: IZoomSettings, oldState: IWorkspaceTools) {
    const scaleDifference = Math.min(
      this.viewport.screenWidth / oldState.windowDimensions.width,
      this.viewport.screenHeight / oldState.windowDimensions.height
    );

    const scale = oldState.viewportScale * scaleDifference;
    /**
     * In Rank view state is not completele restored
     * Image Matrix is recalcukated on state restore, which causes viewport world size to be recalculated too
     * and as dimensions are calculated to be more or less proportional with window aspect ratio
     * resulting new viewport has different dimentions
     * So we need to recalculate center with proportion
     *
     * Also new viewport is not updated with old resolution,
     * so we multiply new with and height on resolution to get correct proportions
     */
    const center = {
      x:
        (oldState.viewportCenter.x * viewPort.worldWidth * oldState.viewportZoom.resolution) /
        oldState.viewportDimensions.width,
      y:
        (oldState.viewportCenter.y * viewPort.worldHeight * oldState.viewportZoom.resolution) /
        oldState.viewportDimensions.height
    };

    zoom.baseScale = scale * 3;
    viewPort.scale.set(Math.abs(scale));
    viewPort.moveCenter(center.x, center.y);
    setTimeout(() => {
      this.updateLabelsOnZoom(true);
      // this.checkLabelsVisibility(true);
    }, 100);
  }

  private updateMatrixDimensionsOnInitialRender(restore: boolean) {
    this.updateMatrixDimensionsBase(this.imageMatrix.width, this.imageMatrix.height + this.imageOffsetsForInfoPanel.y); // TODO check this addition
    this.renderNewMatrix.next({ restore });
  }

  private updateMatrixDimensionsBase(imageMatrixWidth, imageMatrixHeight) {
    this.savedImageMatrixWidth = imageMatrixWidth;
    this.savedImageMatrixHeight = imageMatrixHeight;
    let w = imageMatrixWidth;
    let h = imageMatrixHeight;

    let leftByMinWidth = 0;
    let topByMinHeight = 0;

    const imageMatrixMinWidth = 10 * this.imageSize;
    const imageMatrixMinHeight = 6 * this.imageSize;

    if (w < imageMatrixMinWidth) {
      leftByMinWidth = (imageMatrixMinWidth - w) / 2;
      w = imageMatrixMinWidth;
    }
    if (h < imageMatrixMinHeight) {
      topByMinHeight = (imageMatrixMinHeight - h) / 2;
      h = imageMatrixMinHeight;
    }

    const labelsTotalHeight = this.getLabelsTotalHeight();

    const scale = this.getImageMatrixScale(imageMatrixWidth, imageMatrixHeight);

    let width = window.innerWidth * scale;
    let height = window.innerHeight * scale;
    let left = (width - w) / 2 + leftByMinWidth;
    let top = !this.rankDockingTopOrRight
      ? ((height - h) * this.imageMatrixPaddings.top) /
          (this.imageMatrixPaddings.top + this.imageMatrixPaddings.bottom + labelsTotalHeight) +
        topByMinHeight
      : ((height - h) * (this.imageMatrixPaddings.top + labelsTotalHeight)) /
          (this.imageMatrixPaddings.top + this.imageMatrixPaddings.bottom + labelsTotalHeight) +
        topByMinHeight;

    left += width;
    top += height;
    width *= 3;
    height *= 3;

    this.matrixResizerSpriteRef.width = width;
    this.matrixResizerSpriteRef.height = height;
    this.imageMatrix.position.set(left, top);
  }

  private updateImageOffsetsForInfoPanel() {
    this.imageOffsetsForInfoPanel =
      this.infoPanelShowState === 'canvas'
        ? {
            x: this.infoPanelPosition !== InfoPanelPosition.Bottom ? this.groupContGapBase * 2.2 : 0,
            y: this.infoPanelPosition === InfoPanelPosition.Bottom ? this.groupContGapBase * 2 : 0
          }
        : { x: 0, y: 0 };
  }

  private updateGroupOffsetsForInfoPanel() {
    this.groupOffsetsForInfoPanel =
      !this.absolutePlotting && this.infoPanelShowState === 'canvas'
        ? {
            x: this.infoPanelPosition !== InfoPanelPosition.Bottom ? this.groupContGapBase * 3.4 : 0,
            y: this.infoPanelPosition === InfoPanelPosition.Bottom ? this.groupContGapBase * 1 : 0
          }
        : { x: 0, y: 0 };
  }

  private updateInfoPanelBaseSizes() {
    this.infoPanelWrapperMaxWidth = Math.max(...this.imageRefs.map(image => image.infoPanel?.wrapperWidth));
    this.infoPanelWrapperMaxHeight = Math.max(...this.imageRefs.map(image => image.infoPanel?.wrapperHeight));
  }

  private scaleInfoPanelText() {
    if (this.imageRefs.length === 0) return;
    this.imageRefs.forEach(image =>
      image.infoPanel?.scaleWrapper(this.infoPanelWrapperMaxWidth, this.infoPanelWrapperMaxHeight)
    );
  }

  public override setControlCommandKeyState(active: boolean) {
    this.multiHightlightActive = active;
    this.ctrlZoomActive = active;
  }

  protected override onImageMouseOver(image: ImageContainer, event?) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOverWithMagnify(image, event);
      return;
    }
    image.setMouseOverState(true);
    const tl = this.animateImageHover(image);
    if (this.imageHoverTimeout) {
      clearTimeout(this.imageHoverTimeout);
    }
    this.imageHoverTimeout = setTimeout(() => {
      if (this.infoPanelShowState === 'hover' || this.absolutePlotting) {
        this.imageInfoShown = true;
        this.imageInfoOverlayRef.updateText(image);
        this.imageInfoOverlayRef.show();
      }
      if (this.absolutePlotting) {
        this.absolutePlottingImageHover.next({ show: true, sortByVal: image.getData().sortByValue });
      }
    }, 250);

    if (this.highlitedProductIDs.size > 0 && !this.highlitedProductIDs.has(image.getId())) {
      tl.to(image, { pixi: { saturation: 1 }, duration: 0.1, alpha: 1 }, 'animationStart');
    }
  }

  protected override onImageMouseOut(image: ImageContainer) {
    if (this.selectedTool === MatrixToolEnum.Magnify) {
      this.onImageMouseOutWithMagnify(image);
      return;
    }
    image.setMouseOverState(false);
    const tl = this.animateImageHover(image);
    clearTimeout(this.imageHoverTimeout);
    if (this.imageInfoShown) {
      this.imageInfoShown = false;
      this.imageInfoOverlayRef.hide();
    }
    if (this.absolutePlotting) {
      this.absolutePlottingImageHover.next({ show: false });
    }

    if (this.highlitedProductIDs.size > 0 && !this.highlitedProductIDs.has(image.getId())) {
      tl.to(image, { pixi: { saturation: 0 }, duration: 0.1, alpha: 0.4 }, 'animationStart');
    }
  }

  public onAbsolutePointMouseOver(sortByVal: number) {
    const image =
      this.imageInfoShown && this.imageInfoOverlayRef.imageContRef
        ? this.imageInfoOverlayRef.imageContRef
        : this.imageRefs.find(item => item.getData().sortByValue == sortByVal);
    if (image) {
      this.activeImageRefTemp = image;
      this.activeImageZIntexTemp = image.zIndex;
      image.zIndex = this.imageRefs.length + 100;
      image.parent.sortChildren();
      this.render();
    }
  }

  public onAbsolutePointMouseOut() {
    if (this.activeImageRefTemp) {
      this.activeImageRefTemp.zIndex = this.activeImageZIntexTemp;
      this.activeImageZIntexTemp = -1;
      this.activeImageRefTemp.parent.sortChildren();
      this.activeImageRefTemp = null;
      this.render();
    }
  }

  /**
   * Refresh Info Text data for Parent Labels
   * Should be called only when all children labels are updated by updateImageData()
   * @param data array of Hover info fields
   * @param parentsToUpdate Set of parent GroupLabel, which active and need to be refreshed after children
   * @param totalLabels Count of total Children Labels
   * @param countUpdatedLabels Count of updated Children labels, when equal to totalLabels, the parents ready to refresh
   */
  updateActiveParentLabels(
    data: IHoverInfoFieldModel[],
    parentsToUpdate: Set<GroupLabel>,
    totalLabels: number,
    countUpdatedLabels: number,
    customFormattings
  ) {
    if (countUpdatedLabels === totalLabels) {
      // we can start to update parent only when all children refreshed
      parentsToUpdate.forEach(parentLabel => {
        this.updateGroupHoverInfo(parentLabel, data, customFormattings);
      });
      // support parents for top levels
      const parentSet: Set<GroupLabel> = new Set();
      parentsToUpdate.forEach(parentLabel => {
        const parent = parentLabel.getParentGroupLabel();
        if (parent?.getInfoShownState() || parent?.getQuickStatsShownState()) {
          parentSet.add(parent);
        }
      });
      if (parentSet.size > 0) this.updateActiveParentLabels(data, parentSet, 1, 1, customFormattings);
    }
  }

  private clearGroupImageData(data: IHoverInfoFieldModel[], customFormattings: IMetricsCustomFormattings) {
    const activeParentsToUpdate: Set<GroupLabel> = new Set();
    this.groupContainerRefs
      .filter(group => group.isLast)
      .forEach(group => {
        const images = group.getImages();

        images.forEach(image => {
          const imgData = image.getData();
          imgData.hoverInfo = null; // "Calculating..."
          image.setData(imgData);
          if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
            this.imageInfoOverlayRef.updateText(image);
            this.imageInfoOverlayRef.show();
          }
        });

        const label = this.groupLabelsContainer.labels[group.getId()];
        this.updateGroupHoverInfo(label, data, customFormattings);

        const parentLabel = label.getParentGroupLabel();
        if (parentLabel?.getInfoShownState() || parentLabel?.getQuickStatsShownState()) {
          activeParentsToUpdate.add(parentLabel);
        }
      });
    this.updateActiveParentLabels(data, activeParentsToUpdate, 1, 1, customFormattings);
    this.refreshImageInfoPanelsText();
  }

  public updateImageData(data: IHoverInfoFieldModel[]) {
    if (!data) {
      return;
    }
    if (data.length === 0) {
      this.groupLabelsContainer.hideAllQuickStatsIcons();
      this.groupLabelsContainer.hideAllOpenedQuickStats();
    } else {
      this.groupLabelsContainer.showAllQuickStatsIcons();
    }
    this.lastHoverInfoData = data;
    this.dataService.stopActiveImageAggregateRequests(); // stop all requests which not completed yet

    // init data to remember active Parent Labels, which also need to be refreshed after children
    const activeParentsToUpdate: Set<GroupLabel> = new Set();
    let countUpdatedLabels = 0;
    let totalLabels = 0;

    const customFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    // 1. cleanup Image data for groups
    this.clearGroupImageData(data, customFormattings);
    // refresh each label in Group
    this.groupContainerRefs
      .filter(group => group.isLast)
      .forEach(group => {
        const label = this.groupLabelsContainer.labels[group.getId()];
        const filteredRows = group.getFilteredRows();
        if (filteredRows) {
          totalLabels++;
          // --> console.log('!!! Rank.updateImageData > getImageAggregatedHoverInfo', group.getFilteredRows());

          // 1. calculate new Hover Info
          const rows = group.getFilteredRows();
          const sortBy = this.store.selectSnapshot(StudyState.getAxisX)[0].name;
          this.dataService.getImageAggregatedHoverInfo(rows, sortBy, imageData => {
            // 2. populate Hover Info data into each image
            group.imageModels = group.imageModels.map(image => ({
              ...image,
              data: imageData[image.prodId]
            }));

            group.children.forEach((image: ImageContainer) => {
              const { rank } = image.getData();
              const newData = imageData[image.getId()];
              newData.rank = rank;
              image.setData(newData);
              if (this.imageInfoShown && this.imageInfoOverlayRef.imageContRef.getUid() === image.getUid()) {
                this.imageInfoOverlayRef.updateText(image);
                this.imageInfoOverlayRef.show();
              }
            });

            // refresh visible Group Info
            this.updateGroupHoverInfo(label, data, customFormattings);
            countUpdatedLabels++; // async code done, increase count of completed

            // try to refresh Parents for Async code version
            this.updateActiveParentLabels(
              data,
              activeParentsToUpdate,
              totalLabels,
              countUpdatedLabels,
              customFormattings
            );

            this.refreshImageInfoPanelsText();
          });
        }

        // remember active parents which also need to refresh
        const parentLabel = label.getParentGroupLabel();
        if (parentLabel?.getInfoShownState() || parentLabel?.getQuickStatsShownState()) {
          activeParentsToUpdate.add(parentLabel);
        }
      });

    // try to refresh Parents immediately for sync code version
    this.updateActiveParentLabels(data, activeParentsToUpdate, totalLabels, countUpdatedLabels, customFormattings);

    this.refreshImageInfoPanelsText();

    if (this.selectedTool === MatrixToolEnum.Highlighter) {
      this.buildHighlights();
    }
  }

  private updateGroupHoverInfo(
    label: GroupLabel,
    hoverInfoConfig: Array<IHoverInfoFieldModel>,
    customFormattings: IMetricsCustomFormattings
  ) {
    if (label.getInfoShownState()) {
      label.updateGroupInfoText(hoverInfoConfig, customFormattings);
      label.getInfoOverlay().show(false);
    }
    if (label.getQuickStatsShownState()) {
      label.updateGroupQuickStatsText(hoverInfoConfig, customFormattings);
    }
  }

  private refreshImageInfoPanelsText() {
    if (this.infoPanelShowState === 'canvas') {
      const imageMatrixBounds = this.getImageMatrixBounds();
      this.imageRefs.forEach(image => {
        image.infoPanel?.updateText(image);
        image.infoPanel?.resize(imageMatrixBounds, image.getImageBounds());
        image.infoPanel?.move(imageMatrixBounds);
      });
      this.updateInfoPanelBaseSizes();
      this.scaleInfoPanelText();
    }
  }

  public override onViewportMove() {
    super.onViewportMove();

    if (this.groupContainerRefs.length === 0) return;
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.updateGroupBounds(imageMatrixBounds);
    if (this.infoPanelShowState === 'canvas') {
      this.imageRefs.forEach(image => {
        image.infoPanel?.setImageRelativePos(imageMatrixBounds, image.getImageBounds());
        image.infoPanel?.move(imageMatrixBounds);
      });
    }
    this.updateAbsolutePlottingPosition(imageMatrixBounds);
    this.moveLabels(imageMatrixBounds);
  }

  public override onZoomChange(
    zoomFactor: number,
    needMove: boolean = false,
    instantResizeMatrix: boolean = false
  ): void {
    this.zoomFactor = zoomFactor;
    if (!this.isDefaultRenderAnimating) super.onZoomChange(zoomFactor, instantResizeMatrix);
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.updateGroupLocalBounds();
    this.updateGroupBounds(imageMatrixBounds);
    this.updateInfoPanelsOnZoom(imageMatrixBounds);
    this.updateAbsolutePlottingOnZoom(imageMatrixBounds);
    this.updateLabelsOnZoom(needMove);
    // this.checkLabelsVisibility();
  }

  public override onResolutionChange(zoomFactor, viewportBaseScale) {
    this.matrix.position.set(0, 0);
    this.zoomFactor = zoomFactor;
    this.updateBaseSizes(viewportBaseScale);
    const imageMatrixBounds = this.getImageMatrixBounds();
    this.updateGroupLocalBounds();
    this.updateGroupBounds(imageMatrixBounds);
    this.updateInfoPanelsOnZoom(imageMatrixBounds);
    this.updateAbsolutePlottingOnZoom(imageMatrixBounds);
    this.updateLabelsOnZoom(true);
    // this.checkLabelsVisibility();
    this.render();
  }

  private resetResolution() {
    if (this.resolution > 1) {
      const resizeFactor = 1 / this.resolution;
      this.updateBaseRefs();
      this.updateImageSizes(resizeFactor);
      this.updateGroupCoordinates();
      this.updateGroupLocalBounds();
    }
  }

  private updateInfoPanelsOnZoom(imageMatrixBounds) {
    if (this.infoPanelShowState !== 'canvas' || !this.imageRefs.every(image => image.infoPanel)) return;
    this.imageRefs.forEach(image => image.infoPanel?.resize(imageMatrixBounds, image.getImageBounds()));
    this.updateInfoPanelBaseSizes();
    this.scaleInfoPanelText();
    this.imageRefs.forEach(image => image.infoPanel?.move(imageMatrixBounds));
  }

  private updateAbsolutePlottingOnZoom(imageMatrixBounds) {
    if (!this.absolutePlotting || this.absolutePlottingLabelSize <= 0) return;
    const imageSize = this.imageDesiredSizes.width * this.viewport.scale.x;

    if (this.ctrlZoomActive) {
      const viewportSize =
        (this.groupOrientation == GroupOrientation.Horizontal ? this.viewport.worldWidth : this.viewport.worldHeight) *
        this.viewport.scale.x;
      const pad =
        this.groupOrientation == GroupOrientation.Horizontal
          ? this.imageMatrixPaddings.leftRight * 2
          : this.imageMatrixPaddings.top + this.imageMatrixPaddings.bottom;
      const labelMaxSize = viewportSize - pad;
      if (this.absolutePlottingLabelSize < imageSize) this.absolutePlottingLabelSize = imageSize;
      if (this.absolutePlottingLabelSize > labelMaxSize) this.absolutePlottingLabelSize = labelMaxSize;
      this.updateImagesOnAbsolutePlotting();
    } else {
      const max = 0;
      const min =
        this.groupOrientation == GroupOrientation.Horizontal
          ? this.imageMatrix.width - (this.imageDesiredSizes.width + this.distanceBetweenImages)
          : this.imageMatrix.height - (this.imageDesiredSizes.width + this.distanceBetweenImages);

      this.absolutePlottingLabelSize = (min + this.imageDesiredSizes.width - max) * this.viewport.scale.x;

      this.updateAbsolutePlottingPosition(imageMatrixBounds);
    }
    this.absolutePlottingSizeSubject.next({
      labelSize: this.absolutePlottingLabelSize,
      imageSize
    });
  }

  private getImageWidth(): number {
    return this.viewport ? this.viewport.scale.x * this.imageSize * this.resolution : -1;
  }

  // private checkLabelsVisibility(isRepaint: boolean = false) {
  //   const imageWidth = this.getImageWidth();
  //   if (imageWidth < this.IMAGE_SIZE_WITH_LABELS || (this.labelsHidden && isRepaint)) {
  //     this.labelsHidden = true;
  //     this.groupLabelsContainer?.hideLabels(imageWidth < this.IMAGE_SIZE_WITHOUT_LABELS);
  //   } else if (imageWidth > this.IMAGE_SIZE_WITH_LABELS && (this.labelsHidden || isRepaint)) {
  //     this.labelsHidden = false;
  //     this.groupLabelsContainer?.showLabels();
  //   }
  // }

  public getDistanceTillImage(sortByVal: number, labelPos) {
    const image =
      this.imageInfoShown && this.imageInfoOverlayRef.imageContRef
        ? this.imageInfoOverlayRef.imageContRef
        : this.imageRefs.find(item => item.getData().sortByValue == sortByVal);

    if (image) {
      const bounds = image.getImageBounds();
      if (this.groupOrientation == GroupOrientation.Horizontal) {
        return Math.round(labelPos - bounds.y - bounds.height);
      }
      return Math.round(labelPos - bounds.x - bounds.width);
    }

    return 0;
  }

  public getImagePosInWorld(image: ImageContainer) {
    const groupPos = (image.parent as GroupContainer).getCoordinatesOnImageMatrix();
    return {
      x: image.x + groupPos.x + this.imageMatrix.x,
      y: image.y + groupPos.y + this.imageMatrix.y
    };
  }

  public onHarvestModeInit() {
    this.labelsHidden = true;
    this.groupLabelsContainer.hideLabels(true);
  }

  public onHarvestModeClose() {
    this.labelsHidden = false;
    this.groupLabelsContainer.showLabels();
    this.updateLabelsOnZoom();
    // this.checkLabelsVisibility();
  }

  protected override getImagePosRelativeToImageMatrix(image) {
    const pos = super.getImagePosRelativeToImageMatrix(image);
    pos.x -= imageBaseSize / 2;
    pos.y -= imageBaseSize / 2;
    return pos;
  }

  private updateCanvasStateWithGroups() {
    const groups = this.groupContainerRefsByLevels[0].map(group => this.createGroupStateModel(group));
    this.store.dispatch(
      new UpdateMatrixState({
        ...this.getCanvasState(),
        groups
      })
    );
  }

  private setCanvasStateWithGroups() {
    const groups = this.groupContainerRefsByLevels[0].map(group => this.createGroupStateModel(group));
    this.store.dispatch(
      new SetMatrixState({
        ...this.getCanvasState(),
        groups
      })
    );
  }

  public getStateForPresentationMode(): IPresentationState {
    const infoPanel =
      this.infoPanelShowState === 'canvas'
        ? {
            position: this.infoPanelPosition,
            isHalfSize:
              this.groupOrientation === GroupOrientation.Horizontal ||
              this.infoPanelPosition === InfoPanelPosition.Bottom,
            fontSize: 16
          }
        : null;
    return {
      groupsByLevel: this.groupContainerRefsByLevels.map(groups =>
        groups.map(group => this.getGroupPresentationState(group, this.maxNumImagesInGroup, infoPanel))
      ),
      images: this.imageRefs.map(image => this.getImagePresentationState(image)),
      frames: [],
      rankData: {
        maxLevel: this.groupContainerRefsByLevels.length,
        labelOrientation: this.groupLabelsContainer.getLabelsOrientation(),
        dockingSide: this.groupLabelsContainer.getDockingSide()
      },
      infoPanel: infoPanel
    };
  }
}
