import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

/**
 * Component to render custom Cell in AG-Grid
 * see example: https://www.ag-grid.com/angular-data-grid/component-cell-renderer/
 */

@Component({
  selector: 'app-custom-attributes',
  templateUrl: './custom-attributes.component.html',
  styleUrls: ['./custom-attributes.component.scss']
})
export class CustomAttributesComponent implements ICellRendererAngularComp {
  public attributes: Array<{
    name: string;
    value: string;
  }> = [];

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.attributes = params.value || [];
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.attributes = params.value || [];
    return true;
  }
}
