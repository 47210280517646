import { Component } from '@angular/core';
import { IPosition } from '@app/models/workspace-list.model';
import { SetSpacing } from '@app/state/study/study.actions';
import { StudyState } from '@app/state/study/study.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-toolbox-format-canvas',
  templateUrl: './toolbox-format-canvas.component.html',
  styleUrls: ['./toolbox-format-canvas.component.scss']
})
export class ToolboxFormatCanvasComponent {
  public spacing: IPosition;
  constructor(private store: Store) {
    this.spacing = this.store.selectSnapshot(StudyState.getSpacing);
  }

  public updateSpacing() {
    this.store.dispatch(new SetSpacing(this.spacing));
  }
}
