<div
  class="adaptive-toolbar-item"
  matRipple
  (click)="onClick()"
  [ngClass]="{
    'dynamic jm-btn-sm jm-btn-rounded-gray': toolbarItemType == 'button',
    static: toolbarItemType == 'text',
    delimiter: toolbarItemType == 'delimiter'
  }"
>
  <ng-content></ng-content>
</div>
