import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { ConnectionType } from '@app/state/connection/connection.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-home-list-search',
  templateUrl: './home-list-search.component.html',
  styleUrls: ['./home-list-search.component.scss']
})
export class HomeListSearchComponent implements OnInit {
  public readonly PortalMenuItem = PortalMenuItem;
  public readonly ConnectionType = ConnectionType;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  @Input() public resultCount: number = 0;
  @Input() public type: PortalMenuItem;
  @Output() public search: EventEmitter<string> = new EventEmitter();

  public itemPluralMapping = {
    [PortalMenuItem.Projects]: {
      '=1': 'Project',
      other: 'Projects'
    },
    [PortalMenuItem.Studies]: {
      '=1': 'Study',
      other: 'Studies'
    },
    [PortalMenuItem.Collages]: {
      '=1': 'Collage',
      other: 'Collages'
    },
    [PortalMenuItem.Connections]: {
      '=1': 'Connection',
      other: 'Connections'
    },
    [PortalMenuItem.Insights]: {
      '=1': 'Insights',
      other: 'Insights'
    },
    file: {
      '=1': 'File',
      other: 'Files'
    }
  };

  private connectionTypes: Array<ConnectionType>;
  public showImageConnections: boolean = true;
  public showBusinessConnections: boolean = true;

  @HostListener('click')
  public onClick(): void {
    this.searchInput.nativeElement.focus();
  }

  @HostListener('keyup.enter')
  public onEnterKeyup(): void {
    this.search.emit(this.searchInput.nativeElement.value);
  }

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(({ search, connectionType }) => {
      this.searchInput.nativeElement.value = search || '';
      this.connectionTypes = connectionType
        ? connectionType.split(',').map(item => Number(item))
        : [ConnectionType.Image, ConnectionType.Business];
      this.showImageConnections = this.connectionTypes.includes(ConnectionType.Image);
      this.showBusinessConnections = this.connectionTypes.includes(ConnectionType.Business);
    });
  }

  public onInput(): void {
    this.search.emit(this.searchInput.nativeElement.value);
  }

  public onSwitchConnectionType(connectionType: ConnectionType): void {
    if (this.connectionTypes.length > 1) {
      this.connectionTypes = this.connectionTypes.filter(item => item !== connectionType);
    } else if (this.connectionTypes.includes(connectionType)) {
      this.connectionTypes =
        connectionType === ConnectionType.Image ? [ConnectionType.Business] : [ConnectionType.Image];
    } else {
      this.connectionTypes.push(connectionType);
    }
    this.router.navigate([], {
      queryParams: {
        connectionType: this.connectionTypes.join(',')
      },
      queryParamsHandling: 'merge'
    });
  }
}
