<div class="right-top-corner" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>

<ag-grid-angular
  #grid
  style="width: 100%"
  [ngStyle]="{ height: containerHeight + 'px' }"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [gridOptions]="gridOptions"
  [datasource]="rowsDataSource"
  [rowSelection]="'multiple'"
  [rowModelType]="'infinite'"
  [animateRows]="true"
  [components]="components"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (contextmenu)="onContextMenu($event)"
></ag-grid-angular>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed"
  [style.left.px]="contextMenuPosition.x"
  [style.top.px]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu" class="cell-context-menu">
  <ng-template matMenuContent let-items="items" let-text="text">
    <ng-container *ngIf="!selection.isHasSelection()">
      <button mat-menu-item [appCopyToClipboard]="text" (copied)="showMessage('Row data copied to Clipboard')">
        Copy row to Clipboard
      </button>
    </ng-container>
    <ng-container *ngIf="selection.isHasSelection()">
      <button mat-menu-item (click)="onCopySelectionToClipboard()">Copy selection to Clipboard</button>
      <button mat-menu-item (click)="onDownloadSelection()">Download selected rows</button>
    </ng-container>
    <ng-container *ngIf="rowData.length > 0">
      <button mat-menu-item [disabled]="isAllRowsLoading" (click)="onDownloadAllRowsInView()">
        Download all rows in View
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isShowColumnList">
  <div
    class="columns-list scrollable"
    [style.height.px]="containerHeight - 20"
    cdkDropList
    (cdkDropListDropped)="dropColumn($event)"
  >
    <ng-container *ngFor="let column of headers; let i = index">
      <div cdkDrag class="column-item" *ngIf="i > 1">
        <mat-checkbox
          class="example-margin"
          [class.product-name]="column.getInstanceId() === productNameIndex"
          [disabled]="i === 1"
          (change)="refreshVisibleColumns()"
          [(ngModel)]="column.getColDef().headerComponentParams.isShown"
        >
          {{ column.getDefinition().headerName | friendlyName }}
          <span
            class="change-product-title"
            *ngIf="
              column.getColDef().headerComponentParams.dataIndex === productNameIndex &&
              currentUserId === (collageOwner$ | async)
            "
            (click)="onSelectDefaultTitle($event)"
          >
            (change default title)
          </span>
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
</ng-template>
