import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CodePageType, DefaultEncoding, SupportedCodePageList } from './encodings';
import scrollIntoView from 'scroll-into-view-if-needed';
import { WINDOW } from '../../utils/window';

export interface ISelectEncodingDialogData {
  encoding?: string;
  codepage?: number;
  selectedValue?: CodePageType;
}

@Component({
  selector: 'app-select-encoding-dialog',
  templateUrl: './select-encoding-dialog.component.html',
  styleUrls: ['./select-encoding-dialog.component.scss']
})
export class SelectEncodingDialogComponent {
  public readonly CodePageList = SupportedCodePageList;

  public selectedCodepage: CodePageType;

  constructor(
    public dialogRef: MatDialogRef<SelectEncodingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectEncodingDialogData,
    @Inject(WINDOW)
    protected readonly window: Window
  ) {
    this.selectedCodepage =
      this.searchCodepageByEncoding(data?.encoding) || this.searchCodepageByEncoding(DefaultEncoding.encoding);
    this.scrollToCodepage(this.selectedCodepage.codepage);
  }

  onClickOKButton(): void {
    this.dialogRef.close({
      encoding: this.selectedCodepage?.encoding.toLowerCase(),
      selectedCodepage: this.selectedCodepage
    });
  }

  selectCodepage(codepage: CodePageType) {
    this.selectedCodepage = codepage;
  }

  public scrollToCodepage(codepage: number) {
    setTimeout(() => {
      const node = this.window.document.getElementById(`codepage-${codepage}`);
      if (node) {
        scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start' });
      }
    });
  }

  private searchCodepageByEncoding(encoding: string): CodePageType {
    return this.CodePageList.find(item => item.encoding === encoding);
  }
}
