import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { interval } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  @Input() public oobCode: string;
  public redirectTimer: number = 5;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.verifyEmail(this.oobCode).then(() => {
      interval(1000)
        .pipe(
          takeWhile(val => val < 5),
          finalize(() => {
            this.router.navigate(['/home']);
          })
        )
        .subscribe(() => {
          this.redirectTimer -= 1;
        });
    });
  }
}
