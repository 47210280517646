import { IWorkspace } from '@app/models/workspace-list.model';
import { Timestamp } from '@angular/fire/firestore';
import { GroupOrientation, InfoPanelPosition } from '@app/models/study-setting.model';
import { clone } from '@app/shared/utils/object';
import { shortUid } from '@app/shared/utils/short-uid';

export const newWorkspaceData: Omit<IWorkspace, 'id' | 'studyId' | 'created'> = {
  matrix: {
    dimensions: {
      width: 0,
      height: 0
    },
    scale: 1,
    zoom: 1,
    palette: null
  },
  tools: {
    n: 5,
    axes: null,
    spacing: {
      x: 240,
      y: 12
    },
    type: null,
    selectedView: null,
    viewportZoom: null,
    viewportScale: null,
    viewportCenter: null,
    viewportDimensions: null,
    windowDimensions: null,
    rankDirection: true,
    groupOrientation: GroupOrientation.Vertical,
    rankInfoPanelConfig: { showState: 'hover', position: InfoPanelPosition.Left },
    rankAbsolutePlotting: false,
    paretoDetailsBarFixedPosition: false,
    showImagePercentages: false
  },
  frames: [],
  groups: [],
  imageMatrix: {
    position: { x: 0, y: 0 },
    dimensions: { width: 0, height: 0 },
    images: []
  },
  matrixType: null,
  frameFeatureName: '',
  framesTitleFilters: [],
  highlightFilters: [],
  axesShelf: {
    x: [],
    y: [],
    filtering: []
  }
};

export class WorkspaceUtils {
  public static create(studyId: string, defaultView: string): IWorkspace {
    return {
      ...newWorkspaceData,
      id: shortUid(),
      studyId,
      created: Timestamp.now(),
      tools: {
        ...newWorkspaceData.tools,
        selectedView: defaultView
      }
    };
  }

  public static reset(workspace: IWorkspace, defaultView: string): IWorkspace {
    return {
      ...workspace,
      ...newWorkspaceData,
      tools: {
        ...newWorkspaceData.tools,
        selectedView: defaultView
      }
    };
  }

  public static duplicate(workspace: IWorkspace): IWorkspace {
    return clone({
      ...workspace,
      id: shortUid()
    });
  }
}
