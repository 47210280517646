import { Pipe, PipeTransform } from '@angular/core';
import { getHEXColorByName } from '../shared/utils/colors';

@Pipe({
  name: 'getHEXColor'
})
export class GetHEXColorPipe implements PipeTransform {
  transform(value: string, isForColor?: boolean): string {
    return getHEXColorByName(value);
  }
}
