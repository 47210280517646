import { IConnection } from '@app/state/connection/connection.model';
import { IFiltersModel } from '../../../models/filters.model';

/**
 * Parsed Business data, with headers, measures, dimensions
 */
export interface IBusinessData {
  id: string;
  rowsTotal: number;
  headers: Array<string>;
  settings: {
    fileURL: string;
    fileName: string;
    encoding: string;
  };
  measures: Array<IFiltersModel>;
  dimensions: Array<IFiltersModel>;

  // fields during the Process CSV-file and saving parsed data
  isParsing?: boolean;
  parsingPercent?: number;
  parsingMessage?: string; // what BE doing right now
  isFailed?: boolean;
  errorMessage?: string;
}

export interface IBusinessConnection extends IConnection {
  id: string;
  businessDataId: string;
  settings: {
    databaseType: DatabaseConnectionType;
    fields: ICSVConnectionSettings | ISnowflakeSettings;
  };
}

export enum DatabaseConnectionType {
  CSV = 1,
  Snowflake = 2
}
export interface ISnowflakeSettings {
  auth: string;
  user: string;
  password: string;
  host: string;
  port: number;
  timeout: number;
  warehouse: number;
  schema: number;
}
export interface ICSVConnectionSettings {
  csvFile?: {
    file: {
      name: string;
      size: number;
      type: string;
      lastModified: number;
    };
    fileName: string;
    fileRef: string;
    uploadedURL: string;
  };
  csvSettings: any;
  encoding: string; // !!! deprecated, need use codepage
  codepage: number;
}
