import { Component, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { ResetActiveCollage } from '@app/state/collage/collage.actions';
import { ResetDisplayData } from '@app/state/display-data/display-data.action';
import { ResetActiveStudy } from '@app/state/study/study.actions';
import { ResetToolboxState } from '@app/state/tools/tools.actions';
import { ResetUserData } from '@app/state/user-data/user-data.actions';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public menuItems: Array<{ name: PortalMenuItem; selected: boolean; disabled: boolean }> = [
    { name: PortalMenuItem.Projects, selected: false, disabled: true },
    { name: PortalMenuItem.Studies, selected: false, disabled: false },
    { name: PortalMenuItem.Collages, selected: false, disabled: false },
    { name: PortalMenuItem.Connections, selected: false, disabled: false },
    { name: PortalMenuItem.Insights, selected: false, disabled: true }
  ];
  public selectedItemName: PortalMenuItem;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch([
      new ResetActiveStudy(),
      new ResetActiveCollage(),
      new ResetDisplayData(),
      new ResetUserData(),
      new ResetToolboxState()
    ]);
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(({ menu }) => {
      this.selectedItemName = menu || PortalMenuItem.Studies;
      this.menuItems = this.menuItems.map(item => ({
        ...item,
        selected: item.name === this.selectedItemName
      }));
    });
  }

  public onSelectMenuItem(event: MatSelectionListChange): void {
    this.router.navigate([], { queryParams: { menu: event.options[0].value } });
  }
}
