import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LookupTableService } from '../../lookup-table';
import { Store } from '@ngxs/store';
import { UserDataState } from '../../../state/user-data/user-data.state';
import { DataService } from '../../../services/data/data.service';
import { BusinessConnectionState } from '../../../state/connection/business/business-connection.state';

@Component({
  selector: 'app-change-product-name-column-dialog',
  templateUrl: './change-product-name-column-dialog.component.html',
  styleUrls: ['./change-product-name-column-dialog.component.scss']
})
export class ChangeProductNameColumnDialogComponent implements OnInit {
  headers: Array<string> = [];
  rows: any[][] = null;
  productIdIndex: number;
  productNameIndex: number;

  selectedValue: string;

  isShowImages: boolean;

  public filteredDisplayHeaders$: Observable<Array<string>>;

  public form: FormGroup;
  public displayNameFieldSearch: FormControl;
  public displayName: string;
  public images: Array<{ id: string; view: string; url: string }> = [];

  constructor(
    private dialogRef: MatDialogRef<ChangeProductNameColumnDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productIdIndex: number;
      productNameIndex: number;
    },
    public lookupTable: LookupTableService,
    private fb: FormBuilder,
    private store: Store,
    private dataService: DataService
  ) {
    this.productIdIndex = data.productIdIndex;
    this.productNameIndex = data.productNameIndex;
    this.headers = this.store.selectSnapshot(UserDataState.getHeader);
    this.selectedValue = this.headers[this.productNameIndex];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      displayNameField: ['', Validators.required]
    });
    this.displayNameFieldSearch = this.fb.control('');
    this.filteredDisplayHeaders$ = this.displayNameFieldSearch.valueChanges.pipe(
      startWith(''),
      map((search: string) => (search ? this.filterHeaders(search) : this.headers))
    );
    this.form.get('displayNameField').valueChanges.subscribe(value => {
      this.selectedValue = value;
      this.refreshImagePreview();
    });
    this.form.get('displayNameField').setValue(this.selectedValue);

    this.lookupTable
      .getViewPlaceholderImages()
      .pipe(take(1))
      .subscribe(viewPlaceholderImages => {
        this.images = viewPlaceholderImages;
        this.isShowImages = true;
        this.refreshImagePreview();
      });
  }

  private refreshImagePreview() {
    if (this.selectedValue && this.images.length > 0) {
      const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
      if (this.rows === null) {
        this.displayName = 'Loading...';
        this.dataService
          .getBusinessRowsForProducts(
            businessDataId,
            [this.images[0].id],
            this.productIdIndex,
            null,
            null,
            'string',
            null,
            null, // for case, when user sort by Custom Attributes column, otherwise = null
            null,
            0,
            10
          )
          .pipe(take(1))
          .subscribe(data => {
            this.rows = data.rows; // last cell in each row it's rowIndex
            this.updateDisplayName();
          });
      } else {
        this.updateDisplayName();
      }
    }
  }

  private filterHeaders(search: string): Array<string> {
    const filterValue = search.toLowerCase();
    return this.headers.filter(header => header.toLowerCase().includes(filterValue));
  }

  onOK() {
    this.dialogRef.close(this.selectedValue);
  }

  onClose() {
    this.dialogRef.close(null);
  }

  private updateDisplayName() {
    const indexOfDisplayName = this.headers.indexOf(this.selectedValue);
    const product = this.rows.find(row => row[this.productIdIndex] === this.images[0].id);
    const name = product ? product[indexOfDisplayName] : null;
    this.displayName = name;
  }
}
