import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  animateInsightsPanel,
  animateInsightsPanelButton,
  expendInsightsPanelAnimation
} from '@app/components/animations/animations';
import { IAxesShelfModel } from '@app/models/workspace-list.model';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-insights-panel',
  templateUrl: './insights-panel.component.html',
  styleUrls: ['./insights-panel.component.scss'],
  animations: [expendInsightsPanelAnimation, animateInsightsPanel, animateInsightsPanelButton]
})
export class InsightsPanelComponent implements OnChanges {
  @Input()
  public matrixType: ValidMatrix;
  public readonly ValidMatrix = ValidMatrix;

  @Select(StudyState.getAxesXY)
  private axesXY$: Observable<IAxesShelfModel>;

  public showInsightsPanel: boolean = false;
  public expanded: boolean = false;

  private axesXYSub: Subscription;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.matrixType) {
      this.axesXYSub?.unsubscribe();
      this.axesXYSub = this.axesXY$.subscribe((axesXY: IAxesShelfModel) => {
        switch (changes.matrixType.currentValue) {
          case ValidMatrix.Rank: {
            this.showInsightsPanel = axesXY && axesXY.x.length > 0 && axesXY.y.length > 0;
            break;
          }
          default: {
            this.showInsightsPanel = false;
          }
        }
        if (this.expanded && !this.showInsightsPanel) {
          this.expanded = false;
        }
      });
    }
  }
}
