import { Injectable } from '@angular/core';
import { IFiltersModel } from '@app/models/filters.model';
import { IAxesShelfModel, IWorkspace } from '@app/models/workspace-list.model';
import { clone } from '@app/shared/utils/object';
import {
  AddWorkspace,
  DeleteActiveWorkspace,
  DuplicateWorkspace,
  SetActiveStudy,
  SetActiveWorkspaceId,
  SetAxesXY
} from '@app/state/study/study.actions';
import { ICustomAttributes } from '@app/state/study/study.model';
import { StudyState } from '@app/state/study/study.state';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, Select, ofActionSuccessful } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest, filter, of, switchMap, take, tap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { ResetActiveStudy, SetCustomAttributeFilters } from '../../state/study/study.actions';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private readonly changeFiltersActions = [
    ResetActiveStudy,
    SetActiveStudy,
    SetActiveWorkspaceId,
    DuplicateWorkspace,
    DeleteActiveWorkspace,
    SetCustomAttributeFilters,
    SetAxesXY,
    AddWorkspace
  ];

  @Select(StudyState.getAxesXY)
  private readonly userFilters$: Observable<IAxesShelfModel>;
  @Select(StudyState.getHighlightFilters)
  private highlightFilters$: Observable<IFiltersModel[]>;
  @Select(StudyState.getCustomAttributes)
  private readonly customAttributes$: Observable<ICustomAttributes>;
  @Select(StudyState.getCustomAttributeFilters)
  private readonly customAttributeFilters$: Observable<Array<IFiltersModel>>;
  @Select(StudyState.getWorkspaceEntities)
  private readonly workspaces$: Observable<{ [id: string]: IWorkspace }>;
  @Select(UserDataState.getDimensions)
  private readonly allFilters$: Observable<Array<IFiltersModel>>;

  private readonly preparedUserFiltersSubject$: BehaviorSubject<IAxesShelfModel> = new BehaviorSubject(null);
  public get preparedUserFilters$(): Observable<IAxesShelfModel> {
    return this.preparedUserFiltersSubject$.pipe(filter(Boolean));
  }

  public readonly preparedAllWorkspaceFilters$: BehaviorSubject<{ [id: string]: IAxesShelfModel }> =
    new BehaviorSubject(null);

  public readonly preparedHighlightFilters$: BehaviorSubject<Array<IFiltersModel>> = new BehaviorSubject([]);

  constructor(private actions$: Actions) {
    this.subUserFilters();
    this.subAllWorkspaceFilters();
    this.subHighlightFilters();
  }

  private prepareUserFilters(
    userFilters: Array<IFiltersModel>,
    allFilters: Array<IFiltersModel>
  ): Array<IFiltersModel> {
    return userFilters.map(userFilter => {
      const defaultFilter = allFilters.find(item => item.name === userFilter.name);
      if (userFilter.isAllChecked && !userFilter.discreteValues) {
        if (defaultFilter) {
          userFilter = {
            ...userFilter,
            discreteValues: defaultFilter.discreteValues.map(({ name }) => ({
              name
            }))
          };
        }
      } else if (userFilter.isExcluded) {
        delete userFilter.isExcluded;
        if (defaultFilter) {
          const newDiscreteValues = defaultFilter.discreteValues
            .filter(discreteValue => {
              const index = userFilter.discreteValues.findIndex(val => {
                return val.name === discreteValue.name;
              });
              return index === -1;
            })
            .map(({ name }) => ({
              name
            }));
          userFilter = {
            ...userFilter,
            discreteValues: newDiscreteValues
          };
        }
      }
      return userFilter;
    });
  }

  private subUserFilters() {
    this.actions$
      .pipe(
        ofActionSuccessful(...this.changeFiltersActions),
        untilDestroyed(this),
        tap(() => {
          this.preparedUserFiltersSubject$.next(null);
        }),
        switchMap(() =>
          combineLatest([
            this.userFilters$.pipe(filter(Boolean)),
            this.allFilters$.pipe(filter(allFilters => allFilters?.length > 0)),
            this.customAttributes$.pipe(
              filter(Boolean),
              switchMap(customAttributes => {
                if (Object.keys(customAttributes).length > 0) {
                  return this.customAttributeFilters$.pipe(take(1));
                }
                return of([]);
              })
            )
          ]).pipe(take(1))
        )
      )
      .subscribe(
        ([userFilters, allFilters, customAttributeFilters]: [IAxesShelfModel, IFiltersModel[], IFiltersModel[]]) => {
          userFilters = clone(userFilters);
          if (userFilters.y.some(userFilter => userFilter.isCustomAttr) && customAttributeFilters.length === 0) {
            return; // bugfix: customAttributeFilters not ready yet, but we require them. So, wait for the next time
          }
          if (
            userFilters.y.length === 0 ||
            (userFilters.y.every(userFilter => !userFilter.isExcluded) &&
              userFilters.y.every(userFilter => !userFilter.isAllChecked))
          ) {
            this.preparedUserFiltersSubject$.next(userFilters);
          } else {
            this.preparedUserFiltersSubject$.next({
              x: [...userFilters.x],
              y: this.prepareUserFilters(userFilters.y, [...allFilters, ...customAttributeFilters]),
              filtering: this.prepareUserFilters(userFilters.filtering, [...allFilters, ...customAttributeFilters])
            });
          }
        }
      );
  }

  private subAllWorkspaceFilters() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetActiveStudy),
        untilDestroyed(this),
        tap(() => this.preparedAllWorkspaceFilters$.next(null)),
        switchMap(() =>
          combineLatest([
            this.workspaces$.pipe(filter(Boolean)),
            this.allFilters$.pipe(filter(allFilters => allFilters?.length > 0)),
            this.customAttributes$.pipe(
              filter(Boolean),
              switchMap(customAttributes => {
                if (Object.keys(customAttributes).length > 0) {
                  return this.customAttributeFilters$.pipe(
                    filter(customAttributeFilters => customAttributeFilters?.length > 0)
                  );
                }
                return of([]);
              })
            )
          ]).pipe(take(1))
        )
      )
      .subscribe(
        ([workspaceList, allFilters, customAttributeFilters]: [
          { [id: string]: IWorkspace },
          IFiltersModel[],
          IFiltersModel[]
        ]) => {
          const workspaceFilters = {};
          Object.keys(workspaceList).forEach(workspaceId => {
            const userFilters = clone(workspaceList[workspaceId].axesShelf);
            if (
              userFilters.y.length === 0 ||
              (userFilters.y.every(userFilter => !userFilter.isExcluded) &&
                userFilters.y.every(userFilter => !userFilter.isAllChecked))
            ) {
              workspaceFilters[workspaceId] = userFilters;
            } else {
              workspaceFilters[workspaceId] = {
                x: [...userFilters.x],
                y: this.prepareUserFilters(userFilters.y, [...allFilters, ...customAttributeFilters]),
                filtering: this.prepareUserFilters(userFilters.filtering, [...allFilters, ...customAttributeFilters])
              };
            }
          });
          this.preparedAllWorkspaceFilters$.next(workspaceFilters);
        }
      );
  }

  private subHighlightFilters() {
    this.highlightFilters$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(
          this.allFilters$.pipe(filter(allFilters => allFilters?.length > 0)),
          this.customAttributes$.pipe(
            filter(Boolean),
            switchMap(customAttributes => {
              if (Object.keys(customAttributes).length > 0) {
                return this.customAttributeFilters$.pipe(take(1));
              }
              return of([]);
            })
          )
        )
      )
      .subscribe(
        ([highlightFilters, allFilters, customAttributeFilters]: [
          IFiltersModel[],
          IFiltersModel[],
          IFiltersModel[]
        ]) => {
          highlightFilters = clone(highlightFilters || []);
          if (highlightFilters.some(userFilter => userFilter.isCustomAttr) && customAttributeFilters.length === 0) {
            return; // bugfix: customAttributeFilters not ready yet, but we require them. So, wait for the next time
          }
          if (
            highlightFilters.length === 0 ||
            (highlightFilters.every(userFilter => !userFilter.isExcluded) &&
              highlightFilters.every(userFilter => !userFilter.isAllChecked))
          ) {
            this.preparedHighlightFilters$.next(highlightFilters);
          } else {
            this.preparedHighlightFilters$.next(
              this.prepareUserFilters(highlightFilters, [...allFilters, ...customAttributeFilters])
            );
          }
        }
      );
  }
}
