import { IAxesShelfModel } from '@app/models/workspace-list.model';
import { ICustomCalculationResults, IUserDataStateModel } from './user-data.model';
import { IFiltersModel } from '../../models/filters.model';

export class SetAggregateData {
  static readonly type = '[User Data] SetAggregateData';
  constructor(public payload: {}) {}
}
export class SetUserData {
  static readonly type = '[User Data] SetUserData';
  constructor(public payload: IUserDataStateModel) {}
}
export class SetFilteredRows {
  static readonly type = '[User Data] SetFilteredRows';
  constructor(public payload: Array<any>) {}
}
export class SetTotalRowsInView {
  static readonly type = '[User Data] Set Total Rows In View';
  constructor(public payload: number) {}
}

export class ResetUserData {
  static readonly type = '[User Data] ResetUserData';
}
export class UpdateDimensions {
  static readonly type = '[User Data] UpdateDimensions';
  constructor(public payload: Array<IFiltersModel>) {}
}
export class UpdateDimension {
  static readonly type = '[User Data] Update Dimension';
  constructor(public payload: IFiltersModel) {}
}
export class UpdateMeasures {
  static readonly type = '[User Data] UpdateMeasures';
  constructor(public payload: Array<any>) {}
}
export class UpdateCalculationResults {
  static readonly type = '[User Data] UpdateCalculationResults';
  constructor(public payload: ICustomCalculationResults) {}
}

// TODO: FIXME get rid of this workaround - avoid state mutation (in show-bar component) of UserDataStateModel.measures selection
export class RefreshFilters {
  static readonly type = '[User Data] RefreshFilters';
  constructor(public payload: IAxesShelfModel | null) {}
}
