import { Component, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';

@Component({
  selector: 'app-home-new-card',
  templateUrl: './home-new-card.component.html',
  styleUrls: ['./home-new-card.component.scss']
})
export class HomeNewCardComponent implements OnChanges {
  @Input() public type: PortalMenuItem;

  @HostListener('click')
  public onClick(): void {
    switch (this.type) {
      case PortalMenuItem.Collages:
        this.router.navigate(['/build'], { queryParams: { createCollage: true } });
        break;
      case PortalMenuItem.Studies:
        this.router.navigate(['/build'], { queryParams: { createStudy: true } });
        break;
    }
  }

  constructor(private renderer: Renderer2, public elementRef: ElementRef, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      if (this.elementRef.nativeElement.classList.length > 0)
        this.renderer.removeClass(this.elementRef.nativeElement, this.elementRef.nativeElement.classList[0]);
      this.renderer.addClass(this.elementRef.nativeElement, changes.type.currentValue.toLowerCase());
    }
  }
}
