<div class="harvest-toolbar" [class.hide]="!showToolbar">
  <div class="feature-type">
    <form [formGroup]="featureTypeFormGroup">
      <mat-form-field appearance="fill">
        <mat-label>Feature Type</mat-label>
        <mat-select formControlName="featureType">
          <mat-option>None</mat-option>
          <mat-option [value]="'Shape'">Shape</mat-option>
          <mat-option [value]="'Color'">Color</mat-option>
          <mat-option [value]="'Material'">Material</mat-option>
          <mat-option [value]="'Other'">Other</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="feature-name">
    <span
      contenteditable="true"
      [title]="'Untitled Feature'"
      #featureNameRef
      class="editable-title"
      (keydown.enter)="featureNameRef.blur()"
      [textContent]="featureName"
      (input)="onInputFeatureName($event)"
    ></span>
  </div>

  <div class="feature-value">
    <span
      contenteditable="true"
      [title]="'Value'"
      #featureValueRef
      class="editable-title"
      (keydown.enter)="featureValueRef.blur()"
      [textContent]="featureValue"
      (input)="onInputFeatureValue($event)"
    ></span>
  </div>

  <div class="feature-actions">
    <button class="icon icon-add">
      <svg-icon svgClass="icon" name="icon-harvest-toolbar-add" disabled></svg-icon>
    </button>
    <button class="icon icon-train" (click)="onTrainClick()" [disabled]="!checkFormValidity()">
      <svg-icon svgClass="icon" name="icon-harvest-toolbar-train"></svg-icon>
    </button>
  </div>
</div>
