import { NgModule } from '@angular/core';
import { AdaptiveToolbarComponent } from '@app/shared/components/adaptive-toolbar/adaptive-toolbar.component';
import { AdaptiveToolbarItemComponent } from '@app/shared/components/adaptive-toolbar/adaptive-toolbar-item/adaptive-toolbar-item.component';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { LeftHeaderComponent } from './left-header/left-header.component';
import { RightHeaderComponent } from './right-header/right-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DelayedHoverDirective } from './delayed-hover/delayed-hover.directive';
import { DisableAutocompleteInputDirective } from './disable-autocomplete-input/disable-autocomplete-input.directive';
import { SelectEncodingDialogComponent } from './select-encoding-dialog/select-encoding-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.directive';
import { ResizeColumnDirective } from './resize-column/resize-column.directive';
import { OverflowTooltipDirective } from './overflow-tooltip/overflow-tooltip.directive';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { AppLoaderService } from '@app/shared/components/loader/app-loader.service';
import { DetectNetworkStateComponent } from './detect-network-state/detect-network-state.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { FadeInOutDirective } from '@app/shared/components/fade-in-out/fade-in-out.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

const components = [
  DelayedHoverDirective,
  DisableAutocompleteInputDirective,
  AdaptiveToolbarComponent,
  AdaptiveToolbarItemComponent,
  LeftHeaderComponent,
  RightHeaderComponent,
  SelectEncodingDialogComponent,
  CopyToClipboardDirective,
  ResizeColumnDirective,
  OverflowTooltipDirective,
  LoaderComponent,
  DetectNetworkStateComponent,
  FadeInOutDirective
];

@NgModule({
  declarations: [...components, ConfirmDialogComponent],
  providers: [AppLoaderService],
  imports: [
    CommonModule,
    DragDropModule,
    PortalModule,
    RouterModule,
    AngularSvgIconModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    MatRadioModule
  ],
  exports: [...components]
})
export class SharedModule {}
