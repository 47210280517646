import { Viewport, IWheelOptions, IDragOptions } from 'pixi-viewport';
import { CustomPluginDrag } from './CustomPluginDrag';
import { CustomPluginWheel } from './CustomPluginWheel';

export class CustomViewport extends Viewport {
  public mouseMode: boolean = true;

  public override wheel(options?: IWheelOptions): Viewport {
    this.plugins.add('wheel', new CustomPluginWheel(this, options));

    return this;
  }

  public override drag(options?: IDragOptions): Viewport {
    this.plugins.add('drag', new CustomPluginDrag(this, options));

    return this;
  }
}
