import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import gsap from 'gsap';
import { GroupInfoOverlay } from './group-info-overlay';
import { GroupContainer } from './group-container';
import {
  DockingSide,
  GroupOrientation,
  IHoverInfoFieldModel,
  IMetricsCustomFormattings
} from '@app/models/study-setting.model';
import { IBounds } from '@app/models/bounds.model';
import { IPosition } from '@app/models/workspace-list.model';
import { InteractionEvent } from 'pixi.js';
import { GroupQuickStats } from './group-quick-stats';
import { IHoverInfoField } from '@app/models/image.model';
import { DataService } from '@services/data/data.service';

export const MIN_DISTANCE_BETWEEN_LABELS = 40;
export const MAX_DISTANCE_BETWEEN_LABELS = 200;

export const PARENT_LABEL_SIZE = 80; // size of Label for Level 0 (usual uses only for VERTICAL mode)
export const DEFAULT_LABEL_SIZE = 30; // size of Label for other cases

export class GroupLabel implements IBounds {
  private readonly infoIconSize = 16;
  private readonly activeInfoIconSize = 24;

  private labelSize = DEFAULT_LABEL_SIZE;

  private get defaultLabelSize(): number {
    return this.labelOrientation === GroupOrientation.Vertical && this.isParentLabel
      ? PARENT_LABEL_SIZE
      : DEFAULT_LABEL_SIZE;
  }

  private wrapper: HTMLDivElement;
  private infoIconRef: HTMLDivElement;
  private quickStatsIcon: HTMLDivElement;
  private handler: HTMLSpanElement;
  private label: HTMLSpanElement;
  private labelTooltip: HTMLSpanElement;

  private quickStats: GroupQuickStats;

  private maxLevel: number;
  private level: number;

  private infoOverlay: GroupInfoOverlay;
  private infoLocked: boolean = false;
  private infoShown: boolean = false;
  private infoActiveState: boolean = false;
  private infoHoverState: boolean = false;
  private quickStatsHoverState = false;
  private restoreInfo: boolean = false;

  private groupContRef: GroupContainer;
  private childGroupLabels: Array<GroupLabel> = [];
  private parentGroupLabel: GroupLabel | null = null;

  private labelOrientation: GroupOrientation;
  private dockingSide: DockingSide = DockingSide.Top;

  // Last values, used to Calculate HoverInfo (if still the same, don't call API method to recalculate HoverInfo)
  private lastHoverInfoConfig: Array<IHoverInfoFieldModel>;
  private lastCustomFormattings: string;
  private lastHoverInfo: IHoverInfoField[];
  private lastImagesDataIDs: string;

  public isParentLabel: boolean = false;
  public isHiddenLabel: boolean = false;
  public isHiddenAll: boolean = false;
  public numEmptyImagesInGroup = 0;

  public x: number = 0;
  public y: number = 0;
  public width: number = 0;
  public height: number = 0;
  public padding: number = 0;

  private groupBaseSize: number = 0;
  private groupBasePosition: IPosition = { x: 0, y: 0 };

  private mouseWasOverLabel = false;
  private mouseWasOverLabelRect = false;

  private dataService: DataService;

  constructor(
    group: GroupContainer,
    maxLevel: number,
    dockingSide: DockingSide,
    labelOrientation: GroupOrientation,
    quickStatsHidden: boolean,
    isHidden: boolean = false,
    dataService: DataService
  ) {
    this.dataService = dataService;
    this.groupContRef = group;
    this.labelOrientation = labelOrientation;
    this.dockingSide = dockingSide;
    this.isParentLabel = group.isFirst;
    this.isHiddenLabel = isHidden;
    this.isHiddenAll = isHidden;
    this.maxLevel = maxLevel;
    const bounds = group.getBounds();
    this.updatePadding();
    this.x = bounds.x;
    this.y = bounds.y;
    this.width = bounds.width;
    this.height = bounds.height;
    this.level = group.getLevel();
    this.createDOMElements(group, quickStatsHidden);
    let { x, y } = bounds;
    this.numEmptyImagesInGroup = group.labelOffset;
    if (labelOrientation === GroupOrientation.Vertical) {
      if (this.isParentLabel) {
        this.labelSize = this.defaultLabelSize;
        this.label.classList.add('parent');
        this.label.style.width = `${this.label.offsetWidth}px`;
      }
      this.wrapper.style.height = `${this.height - this.padding * 2}px`;
      x = this.getXForVertical(32, 1);
    } else {
      this.wrapper.style.width = `${this.width - this.padding * 2}px`;
      y = this.getYForHorizontal(32, 1);
    }
    if (isHidden) {
      this.wrapper.classList.add('hiddenLabel');
      this.wrapper.classList.add('hidden');
    }
    this.setPositionVertical(x, y);
    this.wrapper.classList.remove('init');
    this.createQuickStats();
  }

  setGroupBases(groupBaseSize: number, groupBasePosition: IPosition) {
    this.groupBaseSize = groupBaseSize;
    this.groupBasePosition = groupBasePosition;
  }

  updatePadding() {
    const imageSize = this.groupContRef.getAllImages()[0].getBounds().width;
    this.padding = (imageSize * 1.135 - imageSize) / 2;
  }

  /**
   * Calculate X position for Labels with Vertical orientation
   * @param infoOnCanvas if true, will include offset for empty images
   */
  getXForVertical(distanceBetweenLabels: number, scale: number): number {
    const between = Math.max(distanceBetweenLabels * scale, 1);
    const labelSize = DEFAULT_LABEL_SIZE * scale;
    const firstLinePadding = Math.max(between, labelSize); // distance between images and first line on label
    const levelOffset = this.maxLevel - this.groupContRef.getLevel();
    const offset = firstLinePadding + levelOffset * (between + labelSize);
    return this.dockingSide === DockingSide.Right
      ? this.groupBasePosition.x + this.groupBaseSize + offset
      : this.groupBasePosition.x - offset + 2 * labelSize;
  }

  /**
   * Calculate Y position for Labels with Horizontal orientation
   */
  getYForHorizontal(distanceBetweenLabels: number, scale: number): number {
    const between = Math.max(distanceBetweenLabels * scale, 1);
    const labelSize = DEFAULT_LABEL_SIZE * scale;
    const firstLinePadding = between; // distance between images and first line on labels
    const levelOffset = this.maxLevel - this.groupContRef.getLevel();
    const offset = firstLinePadding + levelOffset * (between + labelSize);
    return this.dockingSide === DockingSide.Top
      ? this.groupBasePosition.y - offset - 2 * labelSize
      : this.groupBasePosition.y + this.groupBaseSize + offset;
  }

  get distanceBetweenImages(): number {
    const imgSize = this.groupContRef.getImageSize();
    return (32 / 256) * imgSize;
  }

  getWrapper(): HTMLDivElement {
    return this.wrapper;
  }

  getLabel(): HTMLSpanElement {
    return this.label;
  }

  getLevel(): number {
    return this.level;
  }

  getInfoIcon(): HTMLDivElement {
    return this.infoIconRef;
  }

  getLabelSize(): number {
    return this.labelSize;
  }

  getRestoreInfoState() {
    return this.restoreInfo;
  }

  getLabelOrientation(): GroupOrientation {
    return this.labelOrientation;
  }

  getDockingSide(): DockingSide {
    return this.dockingSide;
  }

  public getQuickStatsInfo(): Array<IHoverInfoField> {
    return this.quickStats.getInfo();
  }

  showLabel() {
    this.isHiddenLabel = false;
    this.isHiddenAll = false;
    this.wrapper.classList.remove('hiddenLabel');
    this.wrapper.classList.remove('hidden');
  }

  /**
   * Hide label text
   * @param isHideAll if true, hide everything - not only text
   */
  hideLabel(isHideAll: boolean) {
    this.isHiddenLabel = true;
    this.isHiddenAll = isHideAll;
    this.wrapper.classList.add('hiddenLabel');
    if (isHideAll) {
      this.wrapper.classList.add('hidden');
    } else {
      this.wrapper.classList.remove('hidden');
    }
  }

  getAllParents(): Array<GroupLabel> {
    if (!this.parentGroupLabel) return [];
    return [this.parentGroupLabel, ...this.parentGroupLabel.getAllParents()];
  }

  getAllChilds(): Array<GroupLabel> {
    if (this.childGroupLabels.length === 0) return [];
    let allChilds = [...this.childGroupLabels];
    for (const subGroupLabel of this.childGroupLabels) {
      allChilds = [...allChilds, ...subGroupLabel.getAllChilds()];
    }
    return allChilds;
  }

  getAllRelatives(): Array<GroupLabel> {
    return [...this.getAllParents(), ...this.getAllChilds()];
  }

  getAllShownRelatives(): Array<GroupLabel> {
    return this.getAllRelatives().filter(item => item.getInfoShownState());
  }

  isRelative(groupLabel: GroupLabel): boolean {
    return this.getAllRelatives().findIndex(item => item.getLabelId() === groupLabel.getLabelId()) !== -1;
  }

  setChildGroupLabels(childGroupLabels: Array<GroupLabel>): void {
    this.childGroupLabels = childGroupLabels;
  }

  setParentGroupLabel(parentGroupLabel: GroupLabel): void {
    this.parentGroupLabel = parentGroupLabel;
  }

  getParentGroupLabel(): GroupLabel {
    return this.parentGroupLabel;
  }

  getInfoOverlay(): GroupInfoOverlay {
    return this.infoOverlay;
  }

  getInfoShownState(): boolean {
    return this.infoShown;
  }

  getInfoLockedState(): boolean {
    return this.infoLocked;
  }

  getLabelId(): string {
    return `label-${this.groupContRef.getId()}`;
  }

  getInfoIconId(): string {
    return `group-info-${this.groupContRef.getId()}`;
  }

  getGroupContainer(): GroupContainer {
    return this.groupContRef;
  }

  createDOMElements(group: GroupContainer, quickStatsHidden: boolean) {
    this.createWrapper();
    this.createHandler();
    this.createLabel(group.getLabel().value);
    this.createInfoIcon();
    this.createQuickStatsIcon(quickStatsHidden);
    this.createTooltipLabel(group.getLabel().value);
  }

  private onHover() {
    this.wrapper.classList.add('hover');
  }

  private onLeave() {
    this.wrapper.classList.remove('hover');
  }

  public onQuickStatsIconHover() {
    this.quickStatsIcon.classList.add('hover');
  }

  public onQuickStatsIconLeave() {
    this.quickStatsIcon.classList.remove('hover');
  }

  private createWrapper() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('rankGroupWrapper');
    this.wrapper.classList.add(this.getDockingClass());
    this.wrapper.classList.add('init');
    getMatrixOverlayContainer().appendChild(this.wrapper);
  }

  public setCustomWrapperOpacity(opacity?: number) {
    this.wrapper.style.opacity = opacity ? `${opacity}` : '';
  }

  private createHandler() {
    this.handler = document.createElement('div');
    this.handler.classList.add('rankGroupHandlerLine');
    this.handler.addEventListener('mouseover', this.onHover.bind(this));
    this.handler.addEventListener('mouseleave', this.onLeave.bind(this));
    this.wrapper.appendChild(this.handler);
  }

  public createInfoIcon() {
    this.infoIconRef = document.createElement('div');
    this.infoIconRef.addEventListener('mouseover', this.onHover.bind(this));
    this.infoIconRef.addEventListener('mouseleave', this.onLeave.bind(this));
    this.wrapper.appendChild(this.infoIconRef);
    this.infoIconRef.classList.add('group-info-icon');
    this.infoIconRef.setAttribute('id', this.getInfoIconId());
    this.infoIconRef.innerHTML = `
      <span class="circle"></span>
      <img src="assets/icons/icon-frame-info.svg" alt="">
      <img src="assets/icons/icon-frame-info-locked.svg" alt="">
    `;
  }

  private createQuickStatsIcon(quickStatsHidden: boolean) {
    this.quickStatsIcon = document.createElement('div');
    const img = document.createElement('img');
    img.classList.add('inactive-icon');
    img.src = 'assets/icons/icon-quick-stats-arrows.svg';
    const imgActive = document.createElement('img');
    imgActive.classList.add('active-icon');
    imgActive.src = 'assets/icons/icon-quick-stats-arrows-active.svg';
    this.quickStatsIcon.appendChild(img);
    this.quickStatsIcon.appendChild(imgActive);
    this.quickStatsIcon.classList.add('group-quick-stats-icon');
    this.wrapper.appendChild(this.quickStatsIcon);
    if (quickStatsHidden) {
      // this.hideQuickStatsIcon();
      this.showQuickStatsIcon();
    }
  }

  public isQuickStatsHidden(): boolean {
    return this.quickStatsIcon.classList.contains('hidden');
  }

  public hideQuickStatsIcon() {
    this.quickStatsIcon.classList.add('hidden');
  }

  public showQuickStatsIcon() {
    this.quickStatsIcon.classList.remove('hidden');
  }

  public createTooltipLabel(label: string) {
    this.labelTooltip = document.createElement('span');
    this.labelTooltip.classList.add('groupLabelTooltip');
    this.labelTooltip.innerText = label;
    getMatrixOverlayContainer().appendChild(this.labelTooltip);
  }

  private createQuickStats() {
    this.quickStats = new GroupQuickStats(this);
  }

  public removeTooltipLabel() {
    const overlay = getMatrixOverlayContainer();
    if (overlay?.contains(this.labelTooltip)) overlay.removeChild(this.labelTooltip);
  }

  public setInfoActiveState(active: boolean): void {
    this.infoActiveState = active;
    if (this.infoLocked) return;
    if (active) {
      this.infoIconRef.classList.add('active');
      gsap.to(this.infoIconRef, { duration: 0.25, width: this.activeInfoIconSize, height: this.activeInfoIconSize });
    } else {
      this.infoIconRef.classList.remove('active');
      gsap.to(this.infoIconRef, { duration: 0.25, width: this.infoIconSize, height: this.infoIconSize });
    }
  }

  public getInfoActiveState(): boolean {
    return this.infoActiveState;
  }

  public setInfoHoverState(hover: boolean): void {
    this.infoHoverState = hover;
  }

  public setQuickStatsHoverState(hover: boolean): void {
    this.quickStatsHoverState = hover;
  }

  public getInfoHoverState(): boolean {
    return this.infoHoverState;
  }

  public getQuickStatsHoverState(): boolean {
    return this.quickStatsHoverState;
  }

  private createLabel(groupLabel) {
    this.label = document.createElement('div');
    this.label.innerText = groupLabel;
    this.label.classList.add('groupLabel');
    this.label.setAttribute('id', this.getLabelId());
    this.label.addEventListener('mouseover', this.onHover.bind(this));
    this.label.addEventListener('mouseleave', this.onLeave.bind(this));
    this.wrapper.appendChild(this.label);
  }

  public getDockingClass(): string {
    switch (this.dockingSide) {
      case DockingSide.Bottom:
        return 'bottom';
      case DockingSide.Left:
        return 'left';
      case DockingSide.Right:
        return 'right';
      default:
        return 'top';
    }
  }

  public updateDockingSide(dockingSide: DockingSide) {
    this.removeDockingSideClass();
    this.dockingSide = dockingSide;
    this.setDockingSideClass();
  }

  private removeDockingSideClass() {
    const prevDockingSide = this.getDockingClass();
    this.wrapper.classList.remove(prevDockingSide);
  }

  private setDockingSideClass() {
    const newDockingSide = this.getDockingClass();
    this.wrapper.classList.add(newDockingSide);
    this.quickStats.setPosition();
  }

  public updateLabelOrientation(labelOrientation: GroupOrientation): void {
    this.removeLabelOrientationClass();
    this.labelOrientation = labelOrientation;
    this.setLabelOrientationClass();
    if (this.labelOrientation === GroupOrientation.Horizontal) {
      if (this.dockingSide === DockingSide.Right) {
        this.updateDockingSide(DockingSide.Top);
      } else {
        this.updateDockingSide(DockingSide.Bottom);
      }
    } else {
      if (this.dockingSide === DockingSide.Top) {
        this.updateDockingSide(DockingSide.Right);
      } else {
        this.updateDockingSide(DockingSide.Left);
      }
    }
    this.quickStats.updateLabelOrientation();
    this.quickStats.setPosition();
  }

  private removeLabelOrientationClass() {
    this.wrapper.classList.remove(this.labelOrientation);
    if (this.labelOrientation === GroupOrientation.Vertical && this.isParentLabel)
      this.label.classList.remove('parent');
  }

  private setLabelOrientationClass() {
    this.wrapper.classList.add(this.labelOrientation);
    if (this.labelOrientation === GroupOrientation.Vertical && this.isParentLabel) this.label.classList.add('parent');
  }

  public resize(bounds: IBounds, scale: number = null) {
    this.width = bounds.width;
    this.height = bounds.height;
    this.updatePadding();
    this.resizeWrapper(scale);
    if (scale === null) scale = this.calculateScale();
    this.labelSize = this.defaultLabelSize * scale;
    this.resizeLabel(scale);
    this.resizeInfoIcon(scale);
    this.resizeQuickStatsIcon(scale);
    this.resizeHandler(scale);
    this.infoOverlay.resize(this.infoShown);
    this.quickStats.resize(scale);
    return scale;
  }

  private calculateScale(): number {
    return this.labelOrientation === GroupOrientation.Vertical
      ? Math.min(this.wrapper.offsetWidth / this.defaultLabelSize, 1)
      : Math.min(this.wrapper.offsetHeight / this.defaultLabelSize, 1);
  }

  private resizeWrapper(scale: number = null) {
    if (this.labelOrientation === GroupOrientation.Vertical) {
      const height = this.height - this.padding * 2;
      const width = scale === null ? Math.min((height / 16) * 9, this.defaultLabelSize) : this.defaultLabelSize * scale;

      this.wrapper.style.width = `${width}px`;
      this.wrapper.style.height = `${height}px`;
    } else {
      const width = this.width - this.padding * 2;
      const height = scale === null ? Math.min((width / 16) * 9, this.defaultLabelSize) : this.defaultLabelSize * scale;

      this.wrapper.style.width = `${width}px`;
      this.wrapper.style.height = `${height}px`;
    }
  }

  private resizeLabel(scale: number) {
    const lineHeight =
      this.labelOrientation === GroupOrientation.Vertical ? this.wrapper.offsetWidth : this.wrapper.offsetHeight;
    this.label.style.width = `100%`;
    this.label.style.height = `100%`;
    this.label.style.fontSize = `${12 * scale}px`;
    this.label.style.lineHeight = `${lineHeight}px`;
    this.label.style.borderRadius = `${5 * scale}px`;
    this.label.style.boxShadow = `0px ${4 * scale}px ${4 * scale}px 0px rgba(0, 0, 0, 0.2509803922)`;
  }

  private resizeInfoIcon(scale: number) {
    this.infoIconRef.style.transform = `translate(-50%, -50%) scale(${scale})`;
    if (this.labelOrientation === GroupOrientation.Vertical) {
      this.infoIconRef.style.top = `${-13 * scale}px`;
      this.infoIconRef.style.left = `${this.wrapper.offsetWidth / 2}px`;
    } else {
      this.infoIconRef.style.top = `${this.wrapper.offsetHeight / 2}px`;
      this.infoIconRef.style.left = `${-13 * scale}px`;
    }
  }

  private resizeQuickStatsIcon(scale: number) {
    this.quickStatsIcon.style.transform = `translate(-50%, -50%) scale(${scale})`;
    if (this.labelOrientation === GroupOrientation.Vertical) {
      this.quickStatsIcon.style.top = `${-13 * scale}px`;
      if (this.dockingSide === DockingSide.Right) {
        this.quickStatsIcon.style.left = `${this.wrapper.offsetWidth / 2 + 15 * scale}px`;
      } else {
        this.quickStatsIcon.style.left = `${this.wrapper.offsetWidth / 2 - 15 * scale}px`;
      }
    } else {
      if (this.dockingSide === DockingSide.Bottom) {
        this.quickStatsIcon.style.top = `${this.wrapper.offsetHeight / 2 + 15 * scale}px`;
      } else {
        this.quickStatsIcon.style.top = `${this.wrapper.offsetHeight / 2 - 15 * scale}px`;
      }
      this.quickStatsIcon.style.left = `${-13 * scale}px`;
    }
  }

  private resizeHandler(scale: number) {
    if (this.labelOrientation === GroupOrientation.Vertical) {
      this.handler.style.width = `${4 * scale}px`;
      this.handler.style.height = `calc(100% + ${12 * scale}px)`;
      this.handler.style.top = `${-6 * scale}px`;
      this.handler.style.left = `${this.wrapper.offsetWidth / 2 - 2 * scale}px`;
    } else {
      this.handler.style.width = `calc(100% + ${12 * scale}px)`;
      this.handler.style.height = `${4 * scale}px`;
      this.handler.style.top = `${this.wrapper.offsetHeight / 2 - 2 * scale}px`;
      this.handler.style.left = `${-6 * scale}px`;
    }
  }

  public setPositionHorizontal(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.wrapper.classList.add('horizontal');
    this.wrapper.classList.remove('vertical');
    this.wrapper.style.left = `${x + this.padding}px`;
    this.wrapper.style.top = `${y + this.labelSize / 2}px`;
  }

  public setPositionVertical(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.wrapper.classList.remove('horizontal');
    this.wrapper.classList.add('vertical');
    if (this.dockingSide === DockingSide.Right) {
      this.wrapper.style.left = `${x}px`;
    } else {
      this.wrapper.style.left = `${x - this.labelSize}px`;
    }
    this.wrapper.style.top = `${y + this.padding}px`;
  }

  setInfoLockedState(locked: boolean = null) {
    this.infoLocked = locked === null ? !this.infoLocked : locked;
    if (this.infoLocked) {
      this.infoIconRef.classList.add('locked');
      this.infoIconRef.classList.remove('active');
      // gsap.to(this.infoIconRef, { duration: 0.25, width: this.activeInfoIconSize, height: this.activeInfoIconSize });
    } else {
      this.infoIconRef.classList.remove('locked');
      // gsap.to(this.infoIconRef, { duration: 0.25, width: this.infoIconSize, height: this.infoIconSize });
    }
  }

  public getX(): number {
    return this.x;
  }

  public getY(): number {
    return this.y;
  }

  public setY(y) {
    this.y = y;
    this.wrapper.style.top = `${y}px`;
  }

  public setX(x) {
    this.x = x;
    this.wrapper.style.left = `${x}px`;
  }

  public hide() {
    this.handler.style.left = `-1000px`;
    this.handler.style.top = `-1000px`;
    this.label.style.left = `-1000px`;
    this.label.style.top = `-1000px`;
    this.infoIconRef.style.left = `-1000px`;
    this.infoIconRef.style.top = `-1000px`;
  }

  public showAnimation(animationTime: number) {
    if (this.isHiddenAll) return;
    const sharedSettings = { duration: animationTime, delay: this.level * animationTime };
    const handlerStartSettings = this.labelOrientation === GroupOrientation.Vertical ? { height: 0 } : { width: 0 };
    const handlerEndSettings =
      this.labelOrientation === GroupOrientation.Vertical
        ? { height: this.handler.offsetHeight }
        : { width: this.handler.offsetWidth };
    gsap.fromTo(this.handler, handlerStartSettings, { ...sharedSettings, ...handlerEndSettings });
    gsap.from(this.label, { ...sharedSettings, opacity: 0 });
  }

  public getHandlerRef(): HTMLElement {
    return this.handler;
  }

  createInfoOverlay() {
    this.infoOverlay = new GroupInfoOverlay(this.groupContRef);
  }

  showInfo(
    hoverInfoConfig: Array<IHoverInfoFieldModel>,
    customFormattings: IMetricsCustomFormattings,
    animate: boolean,
    forceChangeImageOverlaping = false
  ) {
    const prevInfoShown = this.infoShown;
    this.infoShown = true;
    this.updateGroupInfoText(hoverInfoConfig, customFormattings);
    this.groupContRef.getAllImages().forEach(image => {
      image.interactive = false;
      if (forceChangeImageOverlaping || !this.infoLocked || (this.infoLocked && !prevInfoShown))
        image.changeOverlappedState(true, this.getLabelId());
    });
    this.infoOverlay.updateText(this.lastHoverInfo); // to calculate correct scale for labels
    this.infoOverlay.show(animate);
  }

  hideInfo(animate, forceChangeImageOverlaping = false) {
    const prevInfoShown = this.infoShown;
    this.infoShown = false;
    this.groupContRef.getAllImages().forEach(image => {
      image.interactive = true;
      if (forceChangeImageOverlaping || !this.infoLocked || (!this.infoLocked && prevInfoShown))
        image.changeOverlappedState(false, this.getLabelId());
    });
    this.infoOverlay.hide(animate);
  }

  showQuickStats(
    animate: boolean,
    hoverInfoConfig?: Array<IHoverInfoFieldModel>,
    customFormattings?: IMetricsCustomFormattings
  ) {
    this.quickStatsIcon.classList.add('active');
    if (hoverInfoConfig && customFormattings) this.updateGroupQuickStatsText(hoverInfoConfig, customFormattings);
    this.quickStats.show(animate);
    this.refreshInfoOverlay();
    if (this.infoShown) {
      // fix: refreshInfoOverlay() could hide HoverInfo, so show it again
      this.infoOverlay.updateText(this.lastHoverInfo);
      this.infoOverlay.show(animate);
    }
  }

  hideQuickStats(animate: boolean) {
    this.quickStatsIcon.classList.remove('active');
    this.quickStats.hide(animate);
  }

  public getQuickStatsShownState(): boolean {
    return this.quickStats.getQuickStatsShownState();
  }

  public getQuickStats(): GroupQuickStats {
    return this.quickStats;
  }

  public showTooltip() {
    gsap.killTweensOf(this.labelTooltip);
    this.labelTooltip.style.left = `${
      this.wrapper.offsetLeft - this.labelTooltip.offsetWidth / 2 + this.wrapper.offsetWidth / 2
    }px`;
    this.labelTooltip.style.top = `${this.wrapper.offsetTop - this.labelTooltip.offsetHeight - 15}px`;
    gsap.to(this.labelTooltip, { duration: 0.1, opacity: 1 });
  }

  public hideTooltip() {
    gsap.killTweensOf(this.labelTooltip);
    this.labelTooltip.style.left = `-1000px`;
    this.labelTooltip.style.top = `-1000px`;
    gsap.to(this.labelTooltip, { delay: 0.1, duration: 0.1, opacity: 0 });
  }

  public updateGroupInfoText(
    hoverInfoConfig: Array<IHoverInfoFieldModel>,
    customFormattings: IMetricsCustomFormattings
  ) {
    this.updateHoverInfo(hoverInfoConfig, customFormattings);
  }

  public updateGroupQuickStatsText(
    hoverInfoConfig: Array<IHoverInfoFieldModel>,
    customFormattings: IMetricsCustomFormattings
  ) {
    this.updateHoverInfo(hoverInfoConfig, customFormattings);
  }

  private updateHoverInfo(hoverInfoConfig: Array<IHoverInfoFieldModel>, customFormattings: IMetricsCustomFormattings) {
    const imagesData = this.groupContRef.getAllImages().map(imageCont => imageCont.getData());

    if (imagesData.some(data => data.hoverInfo === null)) {
      if (hoverInfoConfig.length === 0) {
        this.lastHoverInfo = [];
        this.infoOverlay.updateText([]);
        this.quickStats.updateText([]);
      } else {
        this.showCalculatingText();
        this.refreshInfoOverlay();
      }
    } else {
      const imagesDataIDs = imagesData.map(item => item.prodId).join('|');
      const customFormattingsJSON = JSON.stringify(customFormattings);

      // ready to call API
      if (
        this.lastHoverInfoConfig !== hoverInfoConfig ||
        this.lastCustomFormattings !== customFormattingsJSON ||
        this.lastImagesDataIDs !== imagesDataIDs ||
        !this.lastHoverInfo
      ) {
        this.lastHoverInfoConfig = hoverInfoConfig;
        this.lastCustomFormattings = customFormattingsJSON;
        this.lastImagesDataIDs = imagesDataIDs;
        if (hoverInfoConfig.length === 0) {
          this.lastHoverInfo = [];
          this.infoOverlay.updateText([]);
          this.quickStats.updateText([]);
          this.refreshInfoOverlay();
        } else {
          this.showCalculatingText();
          this.dataService.getFrameAggregatedHoverInfo(imagesData, 0, hoverInfo => {
            this.lastHoverInfo = hoverInfo;
            this.infoOverlay.updateText(hoverInfo);
            this.quickStats.updateText(hoverInfo.slice(0, 3)); // show only first 3 elements
            this.refreshInfoOverlay();
          });
        }
      } else {
        this.refreshInfoOverlay();
        if (this.infoShown) this.infoOverlay.show(false);
      }
    }
  }

  // update InfoOverlay with correct scale for labels
  private refreshInfoOverlay() {
    if (this.infoShown) {
      this.infoOverlay.hide(false);
      this.infoOverlay.resize(true);
      this.infoOverlay.show(false);
    }
  }

  private showCalculatingText() {
    this.lastHoverInfo = null;
    this.infoOverlay.updateText(null); // show "Calculating..."
    this.quickStats.updateText(null); // show "Calculating..."
  }

  public setOverlapLevel(overlapLevel: number, overlapped: boolean): void {
    this.infoOverlay.setOverlapLevel(overlapLevel, overlapped);
  }

  public getOverlapLevel(): number {
    return this.infoOverlay.getOverlapLevel();
  }

  public restoreOverlapLevel(): void {
    this.infoOverlay.restoreOverlapLevel();
  }

  public setMouseWasOverLabelRectState(value: boolean): void {
    this.mouseWasOverLabelRect = value;
  }

  public getMouseWasOverLabelRectState(): boolean {
    return this.mouseWasOverLabelRect;
  }

  public setMouseWasOverLabelState(value: boolean): void {
    this.mouseWasOverLabel = value;
  }

  public getMouseWasOverLabelState(): boolean {
    return this.mouseWasOverLabel;
  }

  public isMouseOverLabelRect(event: InteractionEvent, scale: number): boolean {
    const { x, y } = event.data.global;
    let { top, left, bottom, right } = this.wrapper.getBoundingClientRect();
    if (this.labelOrientation === GroupOrientation.Vertical) {
      top -= 20 * scale;
    } else {
      left -= 20 * scale;
    }
    return (x > left && x < right && y > top && y < bottom) || this.isMouseOverQuickStats(event);
  }

  public isMouseOverLabel(event: InteractionEvent): boolean {
    const { x, y } = event.data.global;
    const { top, left, bottom, right } = this.label.getBoundingClientRect();
    return x > left && x < right && y > top && y < bottom;
  }

  public isMouseOverHandler(event: InteractionEvent): boolean {
    const { x, y } = event.data.global;
    const { top, left, bottom, right } = this.handler.getBoundingClientRect();
    return x > left && x < right && y > top && y < bottom;
  }

  public isMouseOverInfo(event: InteractionEvent): boolean {
    const { x, y } = event.data.global;
    const { top, left, width } = this.infoIconRef.getBoundingClientRect();
    const infoIconRadius = width / 2;
    const infoIconCenter = {
      x: left + infoIconRadius,
      y: top + infoIconRadius
    };
    const distanceBetweenCenterAndMouse = Math.sqrt((x - infoIconCenter.x) ** 2 + (y - infoIconCenter.y) ** 2);
    return distanceBetweenCenterAndMouse <= infoIconRadius;
  }

  public isMouseOverQuickStats(event: InteractionEvent): boolean {
    const { x, y } = event.data.global;
    const { top, left, bottom, right } = this.quickStatsIcon.getBoundingClientRect();
    return x > left && x < right && y > top && y < bottom;
  }

  public setRestoreInfoState(restore: boolean) {
    this.restoreInfo = restore;
  }

  public showIcons() {
    gsap.killTweensOf(this.infoIconRef);
    gsap.killTweensOf(this.quickStatsIcon);
    gsap.to(this.infoIconRef, {
      duration: 0.1,
      opacity: 1
    });
    gsap.to(this.quickStatsIcon, {
      duration: 0.1,
      opacity: 1
    });
  }

  public hideIcons() {
    gsap.killTweensOf(this.infoIconRef);
    gsap.killTweensOf(this.quickStatsIcon);
    gsap.to(this.infoIconRef, {
      delay: 0.28,
      duration: 0.1,
      opacity: 0
    });
    gsap.to(this.quickStatsIcon, {
      delay: 0.28,
      duration: 0.1,
      opacity: 0
    });
  }

  public delete(): void {
    this.removeTooltipLabel();
    this.quickStats?.remove();
    this.quickStats = null;
    this.infoOverlay?.remove();
    this.infoOverlay = null;
    this.wrapper?.remove();
    this.wrapper = null;
    this.handler?.remove();
    this.handler = null;
    this.label?.remove();
    this.label = null;
    this.infoIconRef?.remove();
    this.infoIconRef = null;
  }
}
