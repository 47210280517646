import { InjectionToken } from '@angular/core';

/**
 * Use Window and Document object only via injection token:
 * in constructor: @Inject(WINDOW) private window: Window
 * in component this.window, this.window.document
 */
export const WINDOW = new InjectionToken('WINDOW', {
  providedIn: 'root',
  factory: windowFactory
});

function windowFactory(): Window {
  return window;
}
