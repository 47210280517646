import { NgModule } from '@angular/core';
import { AuthPipe, canActivate, emailVerified, loggedIn } from '@angular/fire/auth-guard';
import { Routes, RouterModule } from '@angular/router';
import { AuthActionComponent } from '@app/components/auth/auth-action/auth-action.component';
import { LoginComponent } from '@app/components/auth/login/login.component';
import { RegisterComponent } from '@app/components/auth/register/register.component';
import { UnverifiedComponent } from '@app/components/auth/unverified/unverified.component';
import { UpdateEmailComponent } from '@app/components/auth/update-email/update-email.component';
import { User } from '@angular/fire/auth';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationComponent } from './authentication.component';

const notLoggedIn: AuthPipe = map((user: User) => {
  if (user) {
    return user.emailVerified ? ['home'] : ['auth', 'login'];
  }
  return true;
});

const emailUnverified: AuthPipe = pipe(
  emailVerified,
  map(verified => !verified || ['home'])
);

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'register',
        component: RegisterComponent,
        ...canActivate(() => notLoggedIn)
      },
      { path: 'login', component: LoginComponent, ...canActivate(() => notLoggedIn) },
      { path: 'verify-email', component: UnverifiedComponent, ...canActivate(() => emailUnverified) },
      { path: 'update-email', component: UpdateEmailComponent, ...canActivate(() => loggedIn) },
      { path: 'action', component: AuthActionComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
