import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { ImageContainer } from './image-container';
import gsap from 'gsap/all';

export class ImageInfoOverlay {
  private infoHtmlRef: HTMLDivElement;
  public imageContRef: ImageContainer;
  private isTooltip = false;
  private pad = 10;

  constructor() {
    this.infoHtmlRef = document.createElement('div');
    this.infoHtmlRef.classList.add('imageInfo');
    getMatrixOverlayContainer().appendChild(this.infoHtmlRef);
  }

  updateText(image: ImageContainer) {
    this.imageContRef = image;
    this.pad = 10;
    let innerHTML = `
        <p class="title">${image.getDisplayName()}</p>
      `;
    const data = image.getData();

    if (data) {
      if (data.hoverInfo === null) {
        innerHTML += `
        <p class="calculating-message">Calculating...</p>
      `;
      } else {
        data.hoverInfo.forEach(info => {
          innerHTML += `<p class="field-name">${info.fieldName}</p>`;
          info.fieldValues.forEach(val => {
            innerHTML += `<p class="field-value">${val}</p>`;
          });
        });
      }
      if (data.rank) {
        innerHTML += ` <p class="rank">#${data.rank}</p>`;
        this.pad = 20;
      }
    }

    this.infoHtmlRef.innerHTML = innerHTML;
  }

  show(animate = true) {
    gsap.killTweensOf(this.infoHtmlRef);

    this.updateSizeAndPosition();

    if (animate) {
      gsap.to(this.infoHtmlRef, { duration: 0.25, opacity: 1 });
    } else {
      this.infoHtmlRef.style.opacity = '1';
    }
  }

  hide(animate = true) {
    gsap.killTweensOf(this.infoHtmlRef);
    if (animate) {
      gsap.to(this.infoHtmlRef, {
        duration: 0.25,
        opacity: 0,
        onComplete: () => {
          this.moveOutOfScreen();
          this.infoHtmlRef.style.display = `none`;
        }
      });
    } else {
      this.infoHtmlRef.style.display = `none`;
      this.infoHtmlRef.style.opacity = '0';
      this.moveOutOfScreen();
    }
  }

  updateSizeAndPosition() {
    const bounds = this.imageContRef.getImageBounds();
    this.infoHtmlRef.style.width = `auto`;
    this.infoHtmlRef.style.height = `auto`;
    this.infoHtmlRef.style.display = `flex`;
    this.infoHtmlRef.style.overflow = 'visible';
    const width = this.infoHtmlRef.clientWidth + 2 * this.pad;
    const height = this.infoHtmlRef.clientHeight + 2 * this.pad;
    this.isTooltip = width > bounds.width || height > bounds.height;
    this.infoHtmlRef.style.overflow = 'hidden';

    if (this.isTooltip) {
      this.infoHtmlRef.style.width = `${width}px`;
      this.infoHtmlRef.style.height = `${height}px`;
      this.infoHtmlRef.style.borderRadius = '5px';
      this.infoHtmlRef.style.left = `${bounds.x + (bounds.width - width) / 2}px`;
      this.infoHtmlRef.style.top = `${bounds.y - height - 10}px`;
    } else {
      this.infoHtmlRef.style.width = `${bounds.width}px`;
      this.infoHtmlRef.style.height = `${height}px`;
      this.infoHtmlRef.style.borderRadius = '0';
      this.infoHtmlRef.style.left = `${bounds.x}px`;
      this.infoHtmlRef.style.top = `${bounds.y}px`;
    }
  }

  private moveOutOfScreen() {
    this.infoHtmlRef.style.width = `0px`;
    this.infoHtmlRef.style.height = `0px`;
    this.infoHtmlRef.style.left = `-1000px`;
    this.infoHtmlRef.style.top = `-1000px`;
  }
}
