<h2 mat-dialog-title>
  <div class="icon">
    <div class="icon-none" *ngIf="icon === 'none'"></div>
    <svg-icon *ngIf="icon === 'info'" svgClass="icon" name="icon-confirm-info"></svg-icon>
    <svg-icon *ngIf="icon === 'warning'" svgClass="icon" name="icon-confirm-warning"></svg-icon>
    <svg-icon *ngIf="icon === 'success'" svgClass="icon" name="icon-confirm-success"></svg-icon>
    <svg-icon *ngIf="icon === 'error'" svgClass="icon" name="icon-confirm-error"></svg-icon>
    <svg-icon *ngIf="icon === 'question'" svgClass="icon" name="icon-confirm-question"></svg-icon>
  </div>
  <div class="title">{{ title }}</div>
</h2>
<div mat-dialog-content class="scrollable">
  <div class="message" *ngIf="message">{{ message }}</div>
  <div class="message-body" *ngIf="html" [innerHTML]="html"></div>
  <mat-radio-group *ngIf="options.length > 0">
    <mat-radio-button
      *ngFor="let option of options; let i = index"
      [value]="i"
      [checked]="i === selectedOptionIndex"
      [disabled]="option.disabled"
      (change)="selectedOptionIndex = i"
    >
      <span [innerHTML]="option.title"></span>
    </mat-radio-button>
  </mat-radio-group>
  <div class="notes" *ngIf="notes" [innerHTML]="notes"></div>
</div>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button *ngFor="let button of buttons; let i = index" mat-flat-button color="accent" (click)="onClickButton(i)">
    {{ button }}
  </button>
</mat-dialog-actions>
