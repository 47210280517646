<div class="explore-bar-filtering-container">
  <button
    class="explore-bar-filtering-container__filter-button"
    matTooltip="Filter"
    matTooltipPosition="above"
    matTooltipClass="above"
    matRipple
    [matRippleCentered]="true"
    [matRippleUnbounded]="false"
    (click)="addFilterItem($event)"
  >
    <svg-icon
      [matBadge]="userFilters.length"
      matBadgeSize="small"
      matBadgeColor="accent"
      [matBadgeHidden]="userFilters.length === 0"
      svgClass="icon"
      name="icon-filter-for-chip"
    ></svg-icon>
  </button>

  <app-filter-box
    #filterBoxComponentRef
    [showFilterBox]="showFilterBox"
    [showBusinessFilters]="showBusinessFilters"
    [showVisualFilters]="showVisualFilters"
    [filtersToShow]="filtersToShow"
    [filtering]="filtering"
    [expandedFilterIndex]="expandedFilterIndex"
    [settings]="settings"
    [prefix]="filterIdPrefix"
    (resizing)="onResizeFilterBox($event)"
    (onToggleFilterType)="onToggleFilterType($event)"
    (onSelectAll)="onSelectAllCheckboxValueChange($event.filter, $event.event)"
    (onCheckboxChange)="onCheckboxValueChange($event.filter, $event.index, $event.event)"
    (onMinMaxChange)="onMinMaxValueChange($event.filter, $any($event.min), $any($event.max))"
    (onStringChange)="onStringValueChange($event.filter, $event.inputValue)"
  ></app-filter-box>
</div>
