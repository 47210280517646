import { Injectable, OnInit } from '@angular/core';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkStateService {
  public isConnected$: Subject<boolean> = new Subject<boolean>();

  public get isConnected(): boolean {
    return this._isConnected;
  }

  private _isConnected: boolean = false;

  constructor() {
    this._isConnected = navigator.onLine;

    merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        // --> console.log('--> Network status', status);
        this._isConnected = status;
        this.isConnected$.next(this.isConnected);
      });
  }
}
