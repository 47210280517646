import { CalcOperationType, HoverInfoAggregationTypeEnum, IHoverInfoFieldModel } from './study-setting.model';
import { ColumnType, IColumnTypeSettings } from '../shared/utils/parse-data-file';

/**
 * WARNING: Old version of FilterModel, don't change it
 * Need to convert from old format into the next Version 3
 */
export interface IFiltersModelOldVersion2 extends IFiltersModel {
  isFiltering?: boolean; // <----- old field, deleted in Study Version 3
}

/**
 * Last version of IFiltersModel for Study version 3
 */
export interface IFiltersModel {
  name: string;
  type?: FiltersTypeEnum;
  dataType?: ColumnType;
  dataSettings?: IColumnTypeSettings;
  isMeasure?: boolean; // 'number' || 'currency' || 'percent'
  isVisual?: boolean;
  discreteValues?: Array<IFilterDiscreteValue>;
  isAllDiscreteValuesEmpty?: boolean;
  minMaxValue?: IFilterMinMaxValue;
  stringValue?: string;
  currentMinMaxValue?: IFilterMinMaxValue;
  isFrameTitle?: boolean;
  isAllChecked?: boolean;
  children?: Array<IFiltersModel>; // uses when Filter has child Numerical item, current implementation allows only one child, and not Calculated
  temp?: boolean; // uses to support CDK Drag&Drop work around: Don't move source item

  // Support Custom Attributes Filter
  isCustomAttr?: boolean; // is filter based on custom Attributes
  // flag is required to identify that unselected discreteValues saved
  isExcluded?: boolean;

  // Support Custom Calculation Filter
  isCustomCalc?: boolean; // is filter based on custom Calculations
  customCalcField?: IHoverInfoFieldModel; // source code for this Custom Calc Filter
}

export enum FiltersTypeEnum {
  Boolean = 'Boolean',
  Continuous = 'Continuous',
  Discrete = 'Discrete',
  UniqueString = 'UniqueString',
  Date = 'Date', // TODO: better remove it, and use ColumnType, also Date is Continuous
  Calculated = 'Calculated'
}

// useful to sort items by type
export function filterType2Number(value: FiltersTypeEnum): number {
  switch (value) {
    case FiltersTypeEnum.UniqueString:
      return 0;
    case FiltersTypeEnum.Discrete:
      return 1;
    case FiltersTypeEnum.Boolean:
      return 2;
    case FiltersTypeEnum.Date:
      return 3;
    case FiltersTypeEnum.Continuous:
      return 4;
  }
  return 5;
}

export interface IFilterDiscreteValue {
  name: string;
  checked?: boolean;
  disabled?: boolean; // could be disabled by Filtering filter
  productsLength?: number;
}

export interface IFilterMinMaxValue {
  min: number | Date;
  max: number | Date;
}

export interface IHoverInfoFilterModel {
  name: string;
  type?: FiltersTypeEnum;
  isMeasure?: boolean;
  discreteValues?: Array<IFilterDiscreteValue>;
  minMaxValue?: IFilterMinMaxValue;
  aggregationType?: HoverInfoAggregationTypeEnum;
  preview?: string; // text preview for field

  // two or more arguments
  calcOperation?: CalcOperationType;
  calcArguments?: Array<IHoverInfoFilterModel>;
  calcId?: string;
  isCalcGroup?: boolean; // if true, this element accepting children calcArguments, which should be calculated before using with other external arguments

  children?: Array<IHoverInfoFilterModel>;

  isDistinctCount?: boolean;

  // fields to show errors
  isInvalid?: boolean;
  errors?: {
    nameRequired?: boolean;
    equalName?: boolean;
    needArgument?: boolean;
  };

  isDuplicate?: boolean; // to mark as duplicated, which must be deleted
  isEditingTitle?: boolean; // true when user changing name of filter, in this case the filter cannot be dragging

  // Support Rank absolute mode: when need to get Custom Calc values for sortByValue, but don't show in the HoverInfo
  isDontShowHoverInfo?: boolean; // Aggregate data, but don't show in hover info
}

export type FilterDataType = 'business' | 'visual';

export type ExploreBarType = 'sorting' | 'grouping' | 'filtering' | 'highlighting';
