import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from '@app/shared/components/shared.module';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { MatListModule } from '@angular/material/list';
import { HomeStudyCardComponent } from '@app/components/home/home-card/home-study-card/home-study-card.component';
import { HomeListComponent } from '@app/components/home/home-list/home-list.component';
import { HomeCardHeaderComponent } from '@app/components/home/home-card-header/home-card-header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatRippleModule } from '@angular/material/core';
import { HomeCardBodyComponent } from '@app/components/home/home-card-body/home-card-body.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HomeNewCardComponent } from '@app/components/home/home-card/home-new-card/home-new-card.component';
import { HomeListSearchComponent } from '@app/components/home/home-list-search/home-list-search.component';
import { HomeCollageCardComponent } from '@app/components/home/home-card/home-collage-card/home-collage-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeConnectionCardComponent } from '@app/components/home/home-card/home-connection-card/home-connection-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HomeComponent,
    HomeListComponent,
    HomeListSearchComponent,
    HomeStudyCardComponent,
    HomeCollageCardComponent,
    HomeConnectionCardComponent,
    HomeNewCardComponent,
    HomeCardHeaderComponent,
    HomeCardBodyComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatListModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTooltipModule,
    AngularSvgIconModule
  ],
  exports: [HomeListComponent],
  providers: [ProjectDataService]
})
export class HomeModule {}
