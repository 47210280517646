<div
  #imagePopper
  class="image-popper-menu"
  [class.hide]="!showPopup"
  [style.left.px]="popupPosition.left"
  [style.top.px]="popupPosition.top"
>
  <div class="btn-icon harvest" (click)="onHarvestClick()">
    <svg-icon svgClass="icon" name="icon-popper-harvest"></svg-icon>
  </div>
  <div class="divider"></div>
  <div class="btn-icon image" (click)="onImageClick()">
    <svg-icon svgClass="icon" name="icon-popper-image"></svg-icon>
  </div>

  <div
    #imageViewMenu
    class="image-view-selector"
    [@openCloseWidthFadeScale]="showImageViewSelector ? 'openWithFlex' : 'closed'"
  >
    <div class="content scrollable">
      <img
        *ngFor="let image of imageViewUrls"
        [src]="image.url"
        alt=""
        (click)="onImageViewClick(image.view)"
        [ngClass]="{ active: selectedImageView === image.view }"
      />
      <span *ngIf="imageViewUrls.length === 0" class="empty">No image available</span>
    </div>
  </div>
</div>
