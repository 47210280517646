<ng-container *ngIf="loading === false; else calculatingTemp">
  <ng-container *ngIf="allHighlightedPercent && insightsForHighlights; else insightsByGroupingTemp">
    <div class="insight">
      <div class="insight__label">
        Highlight represents <span class="fw-500">{{ allHighlightedPercent }}%</span> of all BIDs in view:
      </div>
      <div class="insight__value">
        <mat-progress-bar
          class="insight-progress-bar"
          mode="determinate"
          [value]="allHighlightedPercent"
        ></mat-progress-bar>
      </div>
    </div>
    <div class="insight">
      <div class="insight__label">
        Within <span class="fw-500">{{ rankDirectionTop ? 'Top' : 'Bottom' }} </span>
        <span class="fw-500">{{ numEntries }}</span> of <span class="fw-500">{{ metric }}</span> Highlight represents:
      </div>
      <div class="insight__groups">
        <ng-container *ngTemplateOutlet="insightGroupsTemp; context: { groups: insightsForHighlights }"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #insightsByGroupingTemp>
    <ng-container
      *ngIf="
        selectedGroupingName && insightsByGrouping && insightsByGrouping[selectedGroupingName];
        else calculatingTemp
      "
    >
      <div class="insight">
        <div class="insight__label">
          Percent of <span class="fw-500">{{ metric }}</span> of
          <span class="fw-500">{{ rankDirectionTop ? 'Top' : 'Bottom' }} {{ numEntries }}</span> by
          <button class="selected-group" [ngClass]="{ opened: menu.closed }" [matMenuTriggerFor]="menu">
            {{ selectedGroupingName }}
          </button>
          <mat-menu #menu="matMenu" hasBackdrop="false" class="selected-group-menu">
            <button *ngFor="let grouping of groupings" mat-menu-item (click)="selectedGroupingName = grouping">
              <span>{{ grouping }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="insight__groups">
          <ng-container
            *ngTemplateOutlet="insightGroupsTemp; context: { groups: insightsByGrouping[selectedGroupingName] }"
          ></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #calculatingTemp>
  <div class="calculating">Calculating...</div>
</ng-template>

<ng-template #insightGroupsTemp let-groups="groups">
  <div *ngFor="let group of groups; trackBy: trackByName" class="insight-group">
    <div class="insight-group__label">{{ group.name }}</div>
    <div class="insight-group__value">
      <div class="insight-group__value__number">{{ group.value }}%</div>
      <div class="insight-group__value__bar">
        <mat-progress-bar
          class="insight-group-progress-bar"
          mode="determinate"
          [value]="group.value"
        ></mat-progress-bar>
      </div>
    </div>
  </div>
</ng-template>
