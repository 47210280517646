<div class="left-sidebar sidebar">
  <app-left-header></app-left-header>
</div>
<div class="right-sidebar sidebar">
  <app-right-header></app-right-header>
</div>
<div class="home-container" app-fade-in-out fadeOutEvent="LeavePage" fadeInEvent="ChangedPage">
  <div class="home-container__menu">
    <mat-selection-list [multiple]="false" (selectionChange)="onSelectMenuItem($event)" class="home-selection-list">
      <mat-list-option
        *ngFor="let item of menuItems"
        [value]="item.name"
        [selected]="item.selected"
        [disabled]="item.disabled"
        >{{ item.name }}</mat-list-option
      >
    </mat-selection-list>
  </div>
  <div class="home-container__list">
    <app-home-list [type]="selectedItemName"></app-home-list>
  </div>
</div>
