import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LookupTableService } from '@components/lookup-table';
import { DataService } from '@app/services/data/data.service';
import { DemoDataService } from '@app/services/demo-data/demo-data.service';
import { SetFilteredRows, SetUserData, UpdateDimensions } from '@app/state/user-data/user-data.actions';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { Actions, ofActionCompleted, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatrixToolEnum, ValidMatrix } from '@app/state/tools/tools.model';
import { SetDisplayData } from '@app/state/display-data/display-data.action';
import {
  debounce,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { IUserDataStateModel } from '@app/state/user-data/user-data.model';
import { IAxesShelfModel, IWorkspace } from '@app/models/workspace-list.model';
import { CollageState } from '@app/state/collage/collage.state';
import { StudyState } from '@app/state/study/study.state';
import { ICustomAttributes, IStudy } from '@app/state/study/study.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import {
  AddWorkspace,
  DeleteActiveWorkspace,
  DuplicateWorkspace,
  ResetParetoGroupBoundIndexes,
  SetActiveStudy,
  SetActiveWorkspaceId,
  SetAxesXY,
  SetCustomAttributeFilters,
  SetMatrixType,
  SetParetoGroupBoundIndexes
} from '@app/state/study/study.actions';
import { BusinessConnectionState } from '@app/state/connection/business/business-connection.state';
import {
  DatabaseConnectionType,
  IBusinessConnection,
  ICSVConnectionSettings
} from '@app/state/connection/business/business-connection.model';
import { IImageConnection, ImageManagerType } from '@app/state/connection/image/image-connection.model';
import { ImageConnectionState } from '@app/state/connection/image/image-connection.state';
import { ICollage } from '@app/state/collage/collage.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IHoverInfoFieldModel } from '../../models/study-setting.model';
import { AppLoaderService } from '../../shared/components/loader/app-loader.service';
import { isMac, isWin } from '../../utils/os.utils';
import { ToolsState } from '@app/state/tools/tools.state';
import { FriendlyNameService, IFriendlyName } from '../../services/friendly-name/friendly-name.service';
import { IFiltersModel } from '@app/models/filters.model';
import { ICustomCalculationResults } from '../../state/user-data/user-data.model';
import {
  ResetNeedRebuildMatrix,
  SetIsAggregating,
  UpdateCustomAttributeFilters
} from '../../state/study/study.actions';
import { SetToolboxStateByMatrixType } from '../../state/tools/tools.actions';
import { FiltersService } from '@app/services/filters/filters.service';
import { UpdateMeasures } from '../../state/user-data/user-data.actions';
import { IBusinessData } from '../../state/connection/business/business-connection.model';
import { CURRENT_STUDY_DATA_VERSION } from '../../services/project-data/project-data.service';
import { MatrixService } from '@app/services/matrix/matrix.service';
import { environment } from 'src/environments/environment';
import { BackwardFilteringService } from '../../services/backward-filtering/backward-filtering.service';

@UntilDestroy()
@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss']
})
export class BuildComponent implements OnInit, OnDestroy {
  public readonly MatrixToolEnum = MatrixToolEnum;
  @Select(BusinessConnectionState)
  public readonly businessConnection$: Observable<IBusinessConnection>;
  @Select(ImageConnectionState)
  public readonly imageConnection$: Observable<IImageConnection>;
  @Select(CollageState)
  public readonly collage$: Observable<ICollage>;
  @Select(StudyState)
  public readonly study$: Observable<IStudy>;
  @Select(StudyState.getWorkspaceList)
  public readonly workspaceList$: Observable<Array<IWorkspace>>;

  @Select(StudyState.getMatrixType)
  public matrixType$: Observable<ValidMatrix>;
  @Select(StudyState.getHoverInfoConfig)
  public hoverInfoConfig$: Observable<IHoverInfoFieldModel>;
  @Select(StudyState.getParetoGroupBoundIndexes)
  public paretoGroupBound$: Observable<number[]>;
  @Select(ToolsState.getSelectedTool)
  public selectedTool$: Observable<MatrixToolEnum>;
  @Select(UserDataState.getDimensions)
  public dimensions$: Observable<Array<IFiltersModel>>;
  @Select(StudyState.getCustomAttributeFilters)
  public customAttributeFilters$: Observable<Array<IFiltersModel>>;
  @Select(StudyState.getCustomAttributes)
  public customAttributes$: Observable<ICustomAttributes>;
  @Select(UserDataState.getCalculationResults)
  public customCalculationResults$: Observable<ICustomCalculationResults>;
  @Select(UserDataState.getHeader)
  public headers$: Observable<Array<string>>;
  @Select(CollageState.getBusinessConnectionField)
  public businessConnectionField$: Observable<string>;

  matrixType: string;
  filtersSelected: boolean;

  makeBackwardFilteringWorker: Worker = null;

  @HostListener('wheel', ['$event']) onWheel(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  @HostListener('document:keydown.meta.=', ['$event', `'meta'`])
  @HostListener('document:keydown.meta.-', ['$event', `'meta'`])
  @HostListener('document:keydown.control.=', ['$event', `'control'`])
  @HostListener('document:keydown.control.-', ['$event', `'control'`])
  onKeyDown(event: KeyboardEvent, key: 'meta' | 'control'): void {
    if ((isMac && key === 'meta') || (isWin && key === 'control')) {
      event.preventDefault();
    }
  }

  @HostListener('document:contextmenu', ['$event']) onContextMenuOpen(event: WheelEvent): void {
    event.preventDefault();
  }

  public isShowLoaderTitle: boolean;

  private lastCollage: ICollage;

  constructor(
    private demoData: DemoDataService,
    private dataService: DataService,
    private store: Store,
    private lookupTable: LookupTableService,
    private router: Router,
    private route: ActivatedRoute,
    private projectDataService: ProjectDataService,
    private snackBar: MatSnackBar,
    private appLoaderService: AppLoaderService,
    private friendlyNameService: FriendlyNameService,
    private actions$: Actions,
    private filtersService: FiltersService,
    private matrixService: MatrixService,
    private backwardFilteringService: BackwardFilteringService // don't remove, it should be there to init BackwardFiltering
  ) {}

  ngOnInit() {
    this.subCollage();
    this.subMatrixType();
    this.subParetoBoundIndexes();
    this.subFilters();
    this.setActiveStudy();
  }

  ngOnDestroy() {
    this.lookupTable.reset();
    this.makeBackwardFilteringWorker?.terminate();
  }

  public setActiveStudy(): void {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(),
        map(({ studyId }) => studyId),
        filter(Boolean),
        tap(data => this.appLoaderService.setShowLoader('data', true, 'Loading Study...')),
        switchMap((studyId: string) => this.projectDataService.getStudy(studyId).pipe(take(1))),
        switchMap((study: IStudy) => {
          if (!study.version || study.version < CURRENT_STUDY_DATA_VERSION) {
            return this.projectDataService.migrateToLastVersion(study);
          }
          return this.projectDataService.getStudyWithWorkspaces(study);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (study: IStudy) => {
          this.store.dispatch(new SetActiveStudy(study));
        },
        error => {
          this.snackBar.open(
            'Cannot open Study. It could be deleted, wrong study ID, or you have insufficient permissions.',
            '',
            {
              panelClass: 'error',
              duration: 3000
            }
          );
          this.router.navigate(['/home']);
        }
      );
  }

  private subCollage(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(SetActiveStudy),
        switchMap(() => combineLatest([this.study$, this.collage$, this.imageConnection$, this.businessConnection$])),
        filter(
          ([study, collage, imageConnection, businessConnection]: [
            IStudy,
            ICollage,
            IImageConnection,
            IBusinessConnection
          ]) => {
            return (
              study &&
              collage &&
              imageConnection &&
              businessConnection &&
              study.collageId === collage.id &&
              collage.imageConnectionId === imageConnection.id &&
              collage.businessConnectionId === businessConnection.id
            );
          }
        ),
        untilDestroyed(this)
      )
      .subscribe(
        ([study, collage, imageConnection, businessConnection]: [
          IStudy,
          ICollage,
          IImageConnection,
          IBusinessConnection
        ]) => {
          if (this.lastCollage?.id === collage.id) {
            // collage data already loaded or still loading
            // so, don't allow load it again and again
            return;
          }
          this.lastCollage = collage;
          if (businessConnection.settings.databaseType === DatabaseConnectionType.CSV) {
            this.loadCSV(businessConnection, collage, imageConnection);
          } else {
            this.snackBar.open('Unsupported format of Business Data Connection', '', {
              panelClass: 'error',
              duration: 3000
            });
            this.router.navigate(['/home']);
          }
        }
      );
  }

  /**
   * Load parsed Business Data, if not parsed yet - parse it via Firebase Functions
   */
  private loadCSV(businessConnection, collage, imageConnection) {
    this.isShowLoaderTitle = false;
    const fields = businessConnection.settings.fields as ICSVConnectionSettings;
    let businessData = null;

    if (businessConnection.businessDataId) {
      // file already parsed
      this.appLoaderService.setShowLoader('data', true, 'Loading CSV data...');
      this.dataService
        .getBusinessData(businessConnection.businessDataId)
        .pipe(
          take(1),
          switchMap(data => {
            businessData = data;
            const indexOfProdId = businessData.headers.indexOf(collage.businessConnectionField);
            return this.dataService
              .getBusinessUniqueProdIds(businessConnection.businessDataId, indexOfProdId)
              .pipe(take(1));
          })
        )
        .subscribe(uniqueProdIds => {
          this.appLoaderService.setShowLoader('data', false);
          this.loadUserData(businessData, uniqueProdIds, collage, imageConnection, businessConnection.id);
        });
    } else {
      // file not parsed yet - parse and save results in BusinessConnection
      this.appLoaderService.setShowLoader('data', true, 'Parsing CSV... could take a few minutes', null, true);
      const fileURL = fields.csvFile.fileRef || this.dataService.extractFileRefFromURL(fields.csvFile.uploadedURL);
      const csvEncoding = fields.encoding;
      const fileName = fields.csvFile.fileName;

      // TODO: show some UI about Parsing process, which could take a lot of time
      this.dataService
        .createNewBusinessData(fileURL, csvEncoding, fileName)
        .pipe(take(1))
        .subscribe(result => {
          this.dataService
            .parseBusinessDataFile(result.id)
            .pipe(
              take(1),
              switchMap(parsedData => {
                // save parsed data into the Connection object
                businessConnection.businessDataId = result.id;
                this.projectDataService.updateConnection(businessConnection.id, businessConnection);

                // load prepared Business Data and Uniq ProdIDs
                return this.dataService.getBusinessData(result.id).pipe(
                  take(1),
                  switchMap(justParsedBusinessData => {
                    if (justParsedBusinessData.headers.length === 1) {
                      this.snackBar.open('Seems like incorrect format of Business Data file', '', {
                        panelClass: 'error',
                        duration: 3000
                      });
                    }
                    businessData = justParsedBusinessData;
                    const indexOfProdId = justParsedBusinessData.headers.indexOf(collage.businessConnectionField);
                    return this.dataService
                      .getBusinessUniqueProdIds(businessConnection.businessDataId, indexOfProdId)
                      .pipe(take(1));
                  })
                );
              })
            )
            .subscribe(
              uniqueProdIds => {
                this.appLoaderService.setShowLoader('data', false);
                this.loadUserData(businessData, uniqueProdIds, collage, imageConnection, businessConnection.id);
              },
              error => {
                this.appLoaderService.setShowLoader('data', false);
                console.error(error);
                this.snackBar.open('Cannot parse Business data file', '', {
                  panelClass: 'error',
                  duration: 3000
                });
              }
            );
          this.getParsingStatus(result.id); // get Process status first time
        });
    }
  }

  private getParsingStatus(businessDataID: string) {
    this.dataService
      .getBusinessDataProgress(businessDataID)
      .pipe(take(1))
      .subscribe(businessData => {
        if (businessData.isFailed) {
          console.error(businessData.errorMessage);
          this.snackBar.open('Cannot parse Business Data file', '', {
            panelClass: 'error',
            duration: 3000
          });
        } else {
          if (businessData.isParsing) {
            console.log(`=======> ${businessData.parsingPercent}`);
            this.appLoaderService.setShowLoader(
              'data',
              true,
              `${businessData.parsingMessage}... ${businessData.parsingPercent}%`,
              null,
              true
            );
            setTimeout(() => {
              this.getParsingStatus(businessDataID); // wait for 2 sec and try again to get Process status
            }, 2000);
          } else {
            console.log(`=======> 100%`);
            this.appLoaderService.setShowLoader('data', true, `Parsing CSV data, done 100%`, null, true);
          }
        }
      });
  }

  public subMatrixType() {
    this.matrixType$.pipe(untilDestroyed(this)).subscribe(type => {
      this.matrixType = type;
    });
  }

  private subParetoBoundIndexes() {
    this.actions$
      .pipe(
        ofActionSuccessful(SetParetoGroupBoundIndexes),
        debounce(() => this.lookupTable.created$.pipe(filter(Boolean), take(1))),
        switchMap(() => this.paretoGroupBound$),
        filter((boundIndexes: Array<number>) => Array.isArray(boundIndexes) && boundIndexes.length > 0),
        withLatestFrom(this.filtersService.preparedUserFilters$),
        untilDestroyed(this)
      )
      .subscribe(([boundIndexes, axes]: [Array<number>, IAxesShelfModel]) => {
        if (this.matrixType === ValidMatrix.Pareto) {
          this.setParetoDisplayData(axes, boundIndexes);
        }
      });
  }

  private subFilters() {
    // subscribe to the last action, when Matrix ready to Display data, so need to hide Loader
    this.actions$
      .pipe(ofActionCompleted(SetDisplayData, SetToolboxStateByMatrixType), delay(500), untilDestroyed(this))
      .subscribe(() => {
        this.appLoaderService.setShowLoader('data', false);
      });

    // subscribe to actions, when we need to rebuild Workspace/Matrix
    let currentAction = null;
    this.actions$
      .pipe(
        ofActionSuccessful(
          SetActiveStudy,
          SetActiveWorkspaceId,
          DuplicateWorkspace,
          DeleteActiveWorkspace,
          SetAxesXY,
          AddWorkspace
        ),
        tap(action => {
          currentAction = action;
        }),
        map(
          action =>
            action instanceof SetActiveStudy ||
            action instanceof SetActiveWorkspaceId ||
            action instanceof DuplicateWorkspace ||
            action instanceof DeleteActiveWorkspace
        ),
        debounce(() => this.lookupTable.created$.pipe(filter(Boolean), take(1))),
        switchMap((restore: boolean) => {
          return this.filtersService.preparedUserFilters$.pipe(
            map((axes: IAxesShelfModel) => ({
              axes,
              restore
            })),
            take(1)
          );
        }),
        tap(({ axes, restore }) => {
          if (
            !(currentAction instanceof AddWorkspace) &&
            (axes.x?.length > 0 || axes.y?.length > 0 || axes.filtering?.length > 0)
          ) {
            this.store.dispatch(new SetIsAggregating(true));
            this.appLoaderService.setShowLoader('aggregation', true, 'Preparing data...', null, true);
          }
        }),
        delay(350), // timeout to render Loader
        untilDestroyed(this)
      )
      .subscribe(({ axes, restore }) => {
        if (restore) {
          this.dataService.apiCreateFiltersFromCustomCalculations().pipe(take(1)).subscribe();
          // this.dataService.createFiltersFromCustomCalculations();
        }
        if (this.store.selectSnapshot(StudyState.isNeedRebuildMatrix)) {
          restore = false; // need to Rebuild images
          this.store.dispatch(new ResetNeedRebuildMatrix()); // only once
        }
        if (axes) {
          // --> console.log('... build.subFilters', this.matrixType, axes);
          switch (this.matrixType) {
            case ValidMatrix.Rank: {
              this.filtersSelected = axes.x.length > 0;
              let filters: any[] = [];
              if (axes?.x.length > 0) {
                filters.push(axes.x[0]);
              }
              if (axes?.y.length > 0) {
                axes.y.forEach(item => filters.push(item));
              }
              const filtering = axes.filtering;
              this.filtersSelected = !!filters.length;
              this.dataService.apiAggregateRank(filters, filtering).subscribe(result => {
                this.aggregatingDone();
                this.store.dispatch(new SetDisplayData(result.data || []));
              });
              break;
            }
            case ValidMatrix.Freestyle: {
              let filters: any[];
              // If block is necessary for older workspaces where value was saved on AxesX
              if (axes?.x.length > 0) {
                filters = [...axes.x];
              } else {
                filters = axes ? axes.y : [];
              }
              this.filtersSelected = !!filters.length;
              this.dataService.apiAggregateByFilters(filters).subscribe(result => {
                this.aggregatingDone();
                this.store.dispatch(new SetDisplayData(result.data?.imageData || []));
                this.store.dispatch(new SetFilteredRows(result.data?.filteredRows || []));
              });
              break;
            }
            case ValidMatrix.GroupBy: {
              const filters: any[] = axes ? axes.y : [];
              const filtering = axes.filtering;
              this.filtersSelected = !!filters.length;
              this.matrixService.setGroupByIsCreatingGroups(true); // disable updateImageData() during aggregateGroupBy()

              // stop all requests which not completed yet
              this.dataService.stopActiveImageAggregateRequests();

              this.dataService.apiAggregateGroupBy(filters, filtering).subscribe(result => {
                this.aggregatingDone();
                this.store.dispatch(new SetDisplayData(result.data || []));
                this.matrixService.setGroupByIsCreatingGroups(false);
              });
              break;
            }
            case ValidMatrix.Pareto: {
              this.filtersSelected = axes.x.length > 0;
              if (restore) {
                const boundIndexes = this.store.selectSnapshot(StudyState.getParetoGroupBoundIndexes);
                this.setParetoDisplayData(axes, boundIndexes);
              } else {
                this.store.dispatch(new ResetParetoGroupBoundIndexes());
                this.setParetoDisplayData(axes);
              }
              break;
            }
            default: {
              this.aggregatingDone();
              this.filtersSelected = false;
            }
          }
        }
      });
  }

  private setParetoDisplayData(axes: IAxesShelfModel, boundIndexes: Array<number> = []): void {
    let filters: any[] = [];
    if (axes?.x.length > 0) {
      filters.push(axes.x[0]);
    }
    if (axes?.y.length > 0) {
      axes.y.forEach(item => filters.push(item));
    }
    const filtering = axes.filtering;

    const animate = boundIndexes && boundIndexes.length > 0;

    this.dataService.apiAggregatePareto(filters, filtering, boundIndexes).subscribe(result => {
      this.aggregatingDone();
      if (result.data) result.data.animate = animate;
      this.store.dispatch(new SetDisplayData(result.data || []));
      // -- why we don't update filtered rows?
      // -- this.store.dispatch(new SetFilteredRows(result.data?.filteredRows || []));
    });

    // ----- NOTE: old Frontend version - useful to compare old version OR test new changes without changing Firebase Function
    // const axesCopy = clone(axes);
    // const data = this.dataService.aggregatePareto(axesCopy.x, axesCopy.y, axesCopy.filtering, boundIndexes);
    // if (data) data.animate = animate;
    // this.store.dispatch(new SetDisplayData(data || []));
    // ----- end of NOTE
  }

  // public loadDemo(collage: ICollage, imageConnection: IImageConnection, businessConnection: IBusinessConnection) {
  //   this.appLoaderService.setShowLoader('data', true, 'Loading data...');
  //   const url = `${environment.rootUrl}/api/Levite`;
  //   this.demoData.getJSON(url).then(data => {
  //     const preparedData = this.jsonArrayTo2D(data);
  //     const rows = preparedData.filter(item => item.length !== 1 && item[0] !== '');
  //     const headers = rows.shift().map(item => item.replace(/([^ A-Z])([A-Z])/g, '$1 $2').trim());
  //     this.loadUserData(headers, rows, collage, imageConnection, businessConnection.id);
  //   });
  // }

  private loadUserData(
    businessData: IBusinessData,
    uniqueProdIds: Array<any>,
    collage: ICollage,
    imageConnection: IImageConnection,
    businessConnectionId: string
  ) {
    // --> console.log('!!!!! loadUserData()');

    // init friendly name service for custom header titles
    this.friendlyNameService.clear();
    const headerTitles: Array<IFriendlyName> = this.store.selectSnapshot(StudyState.getHeaderTitles);
    if (headerTitles) {
      businessData.headers.forEach(header => {
        const title = headerTitles.find(item => item.header === header);
        if (title) {
          this.friendlyNameService.setFriendlyName(header, title.friendlyName);
        }
      });
    }

    const userData: IUserDataStateModel = {
      headers: businessData.headers,
      // rows,
      filteredRows: this.store.selectSnapshot(UserDataState.getFilteredRows)
    };
    // note: don't change order of next three lines
    this.store.dispatch(new SetUserData(userData));

    this.updateCustomFilters(userData);

    this.createDefaultRender();

    this.store.dispatch([new UpdateDimensions(businessData.dimensions), new UpdateMeasures(businessData.measures)]);

    const indexOfProdId = businessData.headers.indexOf(collage.businessConnectionField);

    if (indexOfProdId < 0) {
      // TODO: show error message that ProdId not found by collage.businessConnectionField
      return;
    }

    // const uniqueProdIds = Array.from(new Set(rows.map(row => row[indexOfProdId])));

    // show Loader text if we have a lot of data for loading
    this.isShowLoaderTitle = uniqueProdIds.length > 1000;

    // Create Lookup Table
    const { dataAccessType } = imageConnection.settings;
    let progress$: Subject<number> = null;
    switch (dataAccessType) {
      case ImageManagerType.ColumbiaS7:
      case ImageManagerType.Nike:
      case ImageManagerType.CollagiaImageLibrary:
        progress$ = this.lookupTable.createLookupTableForImagesDAM(uniqueProdIds, dataAccessType, businessConnectionId);
        break;
      default:
        this.demoData.getJSON(`${environment.leviteApiUrl}/api/OrbFilenames`).then(imageData => {
          this.lookupTable.createLookupTableForOrbImages(imageData, uniqueProdIds);
        });
    }
    if (progress$) {
      progress$.subscribe(percent => {
        if (percent >= 0) {
          const closeAfter = percent === 100 ? 400 : null;
          const message = percent === 0 ? 'Loading Image data' : `Loading Image data ${percent}%`;
          this.appLoaderService.setShowLoader('images', true, message, closeAfter);
        } else {
          // if percent < 0, then loading stopped by user
          this.appLoaderService.setShowLoader('images', false);
        }
      });
    }
  }

  private createDefaultRender() {
    const primaryMetricField = this.store.selectSnapshot(CollageState.getPrimaryMetricField);
    const isStudyEdited = this.store.selectSnapshot(StudyState.isStudyEdited);
    if (primaryMetricField && isStudyEdited === false) {
      const dimsPrepared$ = this.actions$.pipe(
        ofActionSuccessful(UpdateDimensions),
        debounce(() => this.lookupTable.created$.pipe(filter(Boolean), take(1))),
        untilDestroyed(this)
      );
      dimsPrepared$.pipe(first()).subscribe(() => {
        this.store.dispatch(new SetMatrixType(ValidMatrix.Rank));
      });
    }
  }

  /**
   * Create Custom Filters (for Attributes and Calculations)
   * Warning:
   *  - Dimensions and Measures must be updated AFTER Custom Filters to correct build backward filtering
   *  - Backward filtering waiting for Dimensions for first initialization
   */
  private updateCustomFilters(data: IUserDataStateModel): void {
    this.dataService.createFiltersFromCustomAttr();
    this.dataService.apiCreateFiltersFromCustomCalculations().pipe(take(1)).subscribe();
    // this.dataService.createFiltersFromCustomCalculations();
  }

  private jsonArrayTo2D(arrayOfObjects) {
    const headers = [];
    const AoA = [];
    arrayOfObjects.forEach(obj => {
      // Ensure header includes all values in this object.
      // Previous rows will not have empty value in any new columns created
      Object.keys(obj).forEach(key => headers.includes(key) || headers.push(key));
      const thisRow = new Array(headers.length);
      headers.forEach((col, i) => {
        thisRow[i] = obj[col] || '';
      });
      AoA.push(thisRow);
    });
    AoA.unshift(headers);
    return AoA;
  }

  private aggregatingDone() {
    this.store.dispatch(new SetIsAggregating(false));
    this.appLoaderService.setShowLoader('aggregation', false);
  }
}
