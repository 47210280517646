import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ILoginData } from '@app/models/login-data.model';
import { IRegisterData } from '@app/models/register-data.model';
import { IWhitelist } from '@app/models/whitelist.model';
import {
  User,
  EmailAuthProvider,
  updateEmail,
  reauthenticateWithCredential,
  sendEmailVerification,
  signInWithEmailAndPassword,
  Auth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  confirmPasswordReset,
  applyActionCode
} from '@angular/fire/auth';
import { ProjectDataService } from '../project-data/project-data.service';
import { take } from 'rxjs/operators';
import { UserGroup } from '@app/models/user-data.model';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth = inject(Auth);
  public get currentUser(): User | null {
    return this.auth.currentUser;
  }

  constructor(private router: Router, private snackBar: MatSnackBar, private projectDataService: ProjectDataService) {
    // subscribe for User changes and send user email to Sentry
    this.auth.onAuthStateChanged(user => {
      this.updateSentryUser(this.currentUser);
    });
  }

  updateSentryUser(user: User) {
    if (user.email) {
      Sentry.setUser({ email: user.email });
    } else {
      Sentry.setUser(null);
    }
  }

  public login({ email, password }: ILoginData): Promise<void> {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public forgotPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        this.snackBar.open('Password reset email sent, check your inbox.', '', {
          duration: 3000
        });
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public register({ email, password, fullName }: IRegisterData): Promise<void> {
    email = email.toLowerCase().trim();
    return this.isEmailWhitelisted(email)
      .then((groups: Array<UserGroup>) => {
        return createUserWithEmailAndPassword(this.auth, email, password).then(() => {
          return Promise.all([
            updateProfile(this.currentUser, { displayName: fullName }),
            this.projectDataService.addUserData(this.currentUser.uid, { email, groups })
          ]).then(() => {
            this.router.navigate(['auth', 'verify-email']);
            return this.sendEmailVerification();
          });
        });
      })
      .then(() => {
        this.snackBar.open(
          'Your account successfully registered. A verification link have been sent to your email.',
          '',
          {
            duration: 3000
          }
        );
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public logout(): Promise<void> {
    return this.auth
      .signOut()
      .then(() => {
        this.snackBar.open('You have successfully logged out.', '', { duration: 3000 });
        this.router.navigate(['/auth', 'login']);
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public resetPassword(oobCode: string, password: string): Promise<void> {
    return confirmPasswordReset(this.auth, oobCode, password)
      .then(() => {
        this.router.navigate(['/auth', 'login']);
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public verifyEmail(oobCode: string): Promise<void | [void, string]> {
    return applyActionCode(this.auth, oobCode)
      .then(() => Promise.all([this.currentUser.reload(), this.currentUser.getIdToken(true)]))
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  public sendEmailVerification() {
    return sendEmailVerification(this.currentUser);
  }

  public reauthenticate(password: string) {
    const credential = EmailAuthProvider.credential(this.currentUser.email, password);
    return reauthenticateWithCredential(this.currentUser, credential);
  }

  public updateEmail(email: string, password: string, redirect: Array<any> = ['auth', 'verify-email']) {
    email = email.toLowerCase().trim();
    return this.isEmailWhitelisted(email)
      .then((groups: Array<UserGroup>) => {
        this.projectDataService.updateUserData(this.currentUser.uid, groups);
        return this.reauthenticate(password);
      })
      .then(() => {
        return updateEmail(this.currentUser, email);
      })
      .then(() => {
        this.router.navigate(redirect);
        return this.sendEmailVerification();
      })
      .then(() => {
        this.snackBar.open(
          'Your email address successfully updated. A verification link have been sent to your email.',
          '',
          {
            duration: 3000
          }
        );
      })
      .catch(error => {
        this.snackBar.open(error.message, '', {
          panelClass: 'error',
          duration: 3000
        });
      });
  }

  private isEmailWhitelisted(email: string): Promise<Array<UserGroup>> {
    email = email.toLowerCase().trim();
    const domain = email.split('@')[1];
    return this.projectDataService
      .getDomainWhiteList()
      .pipe(take(1))
      .toPromise()
      .then((whitelist: IWhitelist) => {
        return new Promise((resolve, reject) => {
          const indexOfEmail = whitelist.individuals.findIndex(item => item.value.toLowerCase().trim() === email);
          if (indexOfEmail !== -1) {
            resolve(whitelist.individuals[indexOfEmail].groups);
            return;
          }

          const indexOfDomain = whitelist.domains.findIndex(item => item.value.toLowerCase().trim() === domain);
          if (indexOfDomain !== -1) {
            resolve(whitelist.domains[indexOfDomain].groups);
            return;
          }

          const guestDomainIndex = whitelist.domains.findIndex(item => item.value === '*');
          if (guestDomainIndex !== -1) {
            resolve(whitelist.domains[guestDomainIndex].groups);
          }

          reject(new Error('Error: Your email domain is not whitelisted.'));
        });
      });
  }
}
