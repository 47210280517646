import {
  DockingSide,
  GroupOrientation,
  IHoverInfoFieldModel,
  IMetricsCustomFormattings
} from '@app/models/study-setting.model';
import { IPosition } from '@app/models/workspace-list.model';
import { getMatrixOverlayContainer } from '@app/utils/matrix-overlay-container';
import { GroupContainer } from './group-container';
import { DEFAULT_LABEL_SIZE, GroupLabel, PARENT_LABEL_SIZE } from './group-label';
import gsap from 'gsap';
import { DataService } from '@services/data/data.service';

export class GroupLabelsContainer {
  public labels: { [key: string]: GroupLabel } = {};
  public labelsByLevel: Array<Array<GroupLabel>> = [];
  public offsetByLevel: Array<number> = [];

  private labelTooltip: HTMLSpanElement;

  public dockedDistanceBetweenLabels = 3;
  public distanceBetweenLabels = 132;
  public scale = 1;

  // paddings for Horizontal mode
  private readonly padBottom = 150;
  private readonly padTop = 70;

  // paddings for Vertical mode
  private readonly padLeft = 80;
  private readonly padRight = 30;

  private groupLabelsRefByOverlapLevel: Array<Array<GroupLabel>> = [];

  private labelsOrientation: GroupOrientation = GroupOrientation.Vertical;
  private dockingSide: DockingSide = DockingSide.Bottom;

  private mouseWasOverQuickStats = -1;

  private restoreQuickStatsState: Array<number> = [];

  private dataService: DataService;

  constructor(dataService: DataService) {
    this.dataService = dataService;
  }

  getDockingSide() {
    return this.dockingSide;
  }

  getLabelsOrientation() {
    return this.labelsOrientation;
  }

  setDockingSide(rankDockingTopOrRight: boolean) {
    if (this.labelsOrientation === GroupOrientation.Horizontal) {
      this.dockingSide = rankDockingTopOrRight ? DockingSide.Top : DockingSide.Bottom;
    } else {
      this.dockingSide = rankDockingTopOrRight ? DockingSide.Right : DockingSide.Left;
    }
  }

  public updateDockingSide(): void {
    Object.keys(this.labels).forEach(id => {
      this.labels[id].updateDockingSide(this.dockingSide);
    });
  }

  createTooltipLabel() {
    this.labelTooltip = document.createElement('span');
    this.labelTooltip.innerText = '';
    this.labelTooltip.classList.add('groupLabelTooltip');
    getMatrixOverlayContainer().appendChild(this.labelTooltip);
  }

  public clearGroupLabels() {
    this.labelsByLevel = [];
    Object.keys(this.labels).forEach(id => {
      this.labels[id].delete();
      this.labels[id] = null;
    });
    this.labels = {};
    this.groupLabelsRefByOverlapLevel = [];
  }

  public createLabel(
    group: GroupContainer,
    maxLevel: number,
    quickStatsHidden: boolean,
    isHidden: boolean
  ): GroupLabel {
    const labelCont = new GroupLabel(
      group,
      maxLevel,
      this.dockingSide,
      this.labelsOrientation,
      quickStatsHidden,
      isHidden,
      this.dataService
    );
    this.labels[group.getId()] = labelCont;
    if (!this.labelsByLevel[group.getLevel()]) this.labelsByLevel[group.getLevel()] = [];
    this.labelsByLevel[group.getLevel()].push(labelCont);
    return labelCont;
  }

  public hideAllQuickStatsIcons() {
    const allLabels = Object.keys(this.labels).map(id => this.labels[id]);
    if (allLabels.every(label => !label.isQuickStatsHidden())) {
      allLabels.forEach(label => {
        if (!label.isQuickStatsHidden()) {
          // label.hideQuickStatsIcon(); Uncomment and comment below line if you want to hide quickstats icon while its clear.
          label.showQuickStatsIcon();
        }
      });
    }
  }

  public showAllQuickStatsIcons() {
    const allLabels = Object.keys(this.labels).map(id => this.labels[id]);
    if (allLabels.every(label => label.isQuickStatsHidden())) {
      allLabels.forEach(label => {
        if (label.isQuickStatsHidden()) {
          label.showQuickStatsIcon();
        }
      });
    }
  }

  public hideAllOpenedQuickStats() {
    this.labelsByLevel.forEach(labels => {
      const shownState = labels.every(label => label.getQuickStatsShownState());
      if (shownState) {
        const level = labels[0].getLevel();
        this.hideGroupsQuickStats(level, true);
      }
    });
  }

  public setMouseWasOverQuickStatsState(value: number): void {
    this.mouseWasOverQuickStats = value;
  }

  public getMouseWasOverQuickStatsState(): number {
    return this.mouseWasOverQuickStats;
  }

  public resetLabelsByLevel(maxLevel: number): void {
    this.labelsByLevel = new Array(maxLevel + 1);
    this.offsetByLevel = new Array(maxLevel + 1).fill(0);
  }

  public moveLabels(groupBaseSize: number, groupBasePosition: IPosition, groups: Array<GroupContainer>) {
    if (Object.keys(this.labels).length === 0) return;

    Object.keys(this.labels).forEach(id => {
      this.labels[id].setGroupBases(groupBaseSize, groupBasePosition);
    });

    groups.forEach(group => {
      this.moveLabel(group);
      this.labels[group.getId()].getInfoOverlay().move();
    });
  }

  public moveLabel(group: GroupContainer) {
    if (this.labelsOrientation === GroupOrientation.Vertical) {
      this.updateVerticalLabelsPosition(group);
    } else {
      this.updateHorizontalLabelsPosition(group);
    }
  }

  private updateHorizontalLabelsPosition(group: GroupContainer) {
    const pos = this.getLabelPosition(group);
    const label = this.labels[group.getId()];
    label.setPositionHorizontal(pos.x, pos.y);

    if (this.checkHorizontalDocking(label)) {
      this.dockLabelYPos(label);
    }
  }

  private updateVerticalLabelsPosition(group: GroupContainer) {
    const pos = this.getLabelPosition(group);
    const label = this.labels[group.getId()];
    label.setPositionVertical(pos.x, pos.y);
    if (this.checkVerticalDocking(label)) {
      this.dockLabelXPos(label);
    }
  }

  public setLabelsOrientation(groupOrientation: GroupOrientation): void {
    this.labelsOrientation =
      groupOrientation === GroupOrientation.Vertical ? GroupOrientation.Horizontal : GroupOrientation.Vertical;
  }

  public updateLabelsOrientation(): void {
    Object.keys(this.labels).forEach(id => {
      this.labels[id].updateLabelOrientation(this.labelsOrientation);
    });
  }

  public getLabelPosition(group: GroupContainer) {
    const bounds = group.getCustomBounds();
    return {
      x:
        this.labelsOrientation === GroupOrientation.Vertical
          ? this.labels[group.getId()].getXForVertical(this.distanceBetweenLabels, this.scale)
          : bounds.x,
      y:
        this.labelsOrientation === GroupOrientation.Vertical
          ? bounds.y
          : this.labels[group.getId()].getYForHorizontal(this.distanceBetweenLabels, this.scale)
    };
  }

  public zoomLabels(groupBaseSize: number, groupBasePosition: IPosition, distanceBetweenLabels: number) {
    this.distanceBetweenLabels = distanceBetweenLabels;
    if (Object.keys(this.labels).length === 0) return;
    Object.keys(this.labels).forEach(id => {
      this.labels[id].setGroupBases(groupBaseSize, groupBasePosition);
    });
    const label = this.labelsByLevel[this.labelsByLevel.length - 1][0];
    this.scale = this.zoomLabel(label);

    Object.keys(this.labels).forEach(id => {
      this.zoomLabel(this.labels[id], this.scale);
    });
  }

  private zoomLabel(label: GroupLabel, scale: number = null) {
    const bounds = label.getGroupContainer().getCustomBounds();
    if (scale === null) {
      scale = label.resize(bounds);
      return scale;
    } else {
      label.resize(bounds, scale);
    }
    for (let level = 0; level < this.offsetByLevel.length; level++) {
      if (this.offsetByLevel[level] !== 0) this.setLevelOffset(level);
    }
    for (let level = 0; level < this.offsetByLevel.length; level++) {
      // this.updateLabelsOffsetByLevel(level, false);
      this.animateOffset(level, 0);
    }
    if (this.labelsOrientation === GroupOrientation.Vertical) {
      const x = label.getXForVertical(this.distanceBetweenLabels, scale);
      label.setPositionVertical(x, bounds.y);
      if (this.checkVerticalDocking(label)) {
        this.dockLabelXPos(label);
      }
    } else {
      const y = label.getYForHorizontal(this.distanceBetweenLabels, scale);
      label.setPositionHorizontal(bounds.x, y);
      if (this.checkHorizontalDocking(label)) {
        this.dockLabelYPos(label);
      }
    }
    return scale;
  }

  /**
   * Hide label text for each label
   * @param isHideAll if true, hide all label, not only text
   */
  public hideLabels(isHideAll: boolean) {
    Object.keys(this.labels).forEach(id => {
      this.labels[id].hideLabel(isHideAll);
    });
  }

  /**
   * Show label, text visible
   */
  public showLabels() {
    Object.keys(this.labels).forEach(id => {
      this.labels[id].showLabel();
    });
  }

  public showLabelsAnimation(animationTime: number) {
    Object.keys(this.labels).forEach(id => {
      this.labels[id].showAnimation(animationTime);
    });
  }

  private checkHorizontalDocking(label: GroupLabel): boolean {
    const level = label.getLevel();
    const labelSize = label.getLabelSize();
    return this.dockingSide === DockingSide.Top
      ? label.y - (level + 1) * labelSize + labelSize / 2 - level * this.dockedDistanceBetweenLabels < this.padTop
      : label.y + (level + 1) * labelSize - labelSize / 2 + level * this.dockedDistanceBetweenLabels >
          window.innerHeight - this.padBottom;
  }

  private dockLabelYPos(label: GroupLabel) {
    const level = label.getLevel();
    const labelSize = label.getLabelSize();
    const y =
      this.dockingSide === DockingSide.Top
        ? this.padTop + level * labelSize + level * this.dockedDistanceBetweenLabels
        : window.innerHeight - this.padBottom - (level + 1) * labelSize - level * this.dockedDistanceBetweenLabels;
    label.setY(y + labelSize);
  }

  private checkVerticalDocking(label: GroupLabel): boolean {
    const level = label.getLevel();
    const labelSize = label.getLabelSize();
    const parentLevelSize = PARENT_LABEL_SIZE * this.scale;
    if (this.dockingSide === DockingSide.Right) {
      let levelShift = labelSize;
      if (level > 0) {
        levelShift = parentLevelSize + level * labelSize + level * this.dockedDistanceBetweenLabels;
      }
      return window.innerWidth - this.padRight - (label.x + levelShift) < 0;
    }

    // Left Docking
    let levelShift = parentLevelSize;
    if (level > 0) {
      levelShift = parentLevelSize + level * labelSize + level * this.dockedDistanceBetweenLabels;
    }
    return label.x - levelShift - this.padLeft < 0;
  }

  private dockLabelXPos(label: GroupLabel) {
    const level = label.getLevel();
    const labelSize = label.getLabelSize();
    const parentLevelSize = PARENT_LABEL_SIZE * this.scale;
    if (this.dockingSide === DockingSide.Right) {
      let levelShift = labelSize;
      if (level > 0) {
        levelShift = parentLevelSize + level * labelSize + level * this.dockedDistanceBetweenLabels;
      }
      const x = window.innerWidth - this.padRight - levelShift;
      label.setX(x);
    } else {
      // Left Docking
      let levelShift = 0;
      if (level > 0) {
        levelShift = parentLevelSize + (level - 1) * labelSize + level * this.dockedDistanceBetweenLabels;
      }
      const x = this.padLeft + levelShift;
      label.setX(x);
    }
  }

  public updateGroupInfosOverlapLevel(groupLabel: GroupLabel): void {
    if (groupLabel.getInfoShownState()) {
      if (
        this.groupLabelsRefByOverlapLevel.some(level =>
          level.some(item => item.getLabelId() === groupLabel.getLabelId())
        )
      )
        return;
      if (this.groupLabelsRefByOverlapLevel.length === 0) {
        this.groupLabelsRefByOverlapLevel[0] = [groupLabel];
        return;
      }
      const allShownRelatives = groupLabel.getAllShownRelatives();
      if (allShownRelatives.length > 0) {
        let maxLevelIndex = 0;
        for (const relative of allShownRelatives) {
          const index = this.groupLabelsRefByOverlapLevel.findIndex(
            levelItem => levelItem.findIndex(item => item.getLabelId() === relative.getLabelId()) !== -1
          );
          if (index > maxLevelIndex) maxLevelIndex = index;
        }
        if (maxLevelIndex < this.groupLabelsRefByOverlapLevel.length - 1) {
          this.groupLabelsRefByOverlapLevel[maxLevelIndex + 1].push(groupLabel);
        } else {
          this.groupLabelsRefByOverlapLevel.push([groupLabel]);
        }
      } else {
        this.groupLabelsRefByOverlapLevel[0].push(groupLabel);
      }
    } else {
      if (
        this.groupLabelsRefByOverlapLevel.every(level =>
          level.every(item => item.getLabelId() !== groupLabel.getLabelId())
        )
      )
        return;
      const levelIndex = this.groupLabelsRefByOverlapLevel.findIndex(
        level => level.findIndex(item => item.getLabelId() === groupLabel.getLabelId()) !== -1
      );
      if (levelIndex !== -1) {
        this.groupLabelsRefByOverlapLevel[levelIndex] = this.groupLabelsRefByOverlapLevel[levelIndex].filter(
          item => item.getLabelId() !== groupLabel.getLabelId()
        );
        if (this.groupLabelsRefByOverlapLevel[levelIndex].length === 0) {
          this.groupLabelsRefByOverlapLevel.splice(levelIndex, 1);
        }
      }
      groupLabel.setOverlapLevel(0, false);
    }
    this.groupLabelsRefByOverlapLevel.forEach((level: Array<GroupLabel>, index: number) => {
      level.forEach(item => {
        let overlapped;
        if (index === this.groupLabelsRefByOverlapLevel.length - 1) {
          overlapped = false;
        } else {
          const highestLevels = this.groupLabelsRefByOverlapLevel.slice(
            index + 1,
            this.groupLabelsRefByOverlapLevel.length
          );
          overlapped = highestLevels.some(highestLevel => highestLevel.some(levelItem => item.isRelative(levelItem)));
        }
        item.setOverlapLevel(index, overlapped);
      });
    });
  }

  public showGroupsQuickStats(
    level: number,
    animate: boolean,
    hoverInfoConfig?: Array<IHoverInfoFieldModel>,
    customFormattings?: IMetricsCustomFormattings
  ) {
    this.labelsByLevel[level].forEach(groupLabel => {
      groupLabel.showQuickStats(animate, hoverInfoConfig, customFormattings);
    });
    this.setLevelOffset(level);
    for (let l = level - 1; l > -1; l--) {
      this.animateOffset(l, animate ? 0.25 : 0);
    }
  }

  public hideGroupsQuickStats(level: number, animate: boolean) {
    this.labelsByLevel[level].forEach(groupLabel => {
      groupLabel.hideQuickStats(animate);
    });
    this.offsetByLevel[level] = 0;
    for (let l = level - 1; l > -1; l--) {
      this.animateOffset(l, animate ? 0.25 : 0);
    }
  }

  public hideAllGroupsQuickStats() {
    this.labelsByLevel.forEach((labels, level) => {
      labels.forEach(label => {
        label.hideQuickStats(false);
      });
      this.offsetByLevel[level] = 0;
    });

    this.labelsByLevel.forEach((_, level) => {
      this.animateOffset(level, 0);
    });
  }

  public showAllGroupsQuickStats() {
    this.labelsByLevel.forEach((labels, level) => {
      labels.forEach(label => {
        label.hideQuickStats(false);
      });
      this.setLevelOffset(level);
    });

    this.labelsByLevel.forEach((_, level) => {
      this.animateOffset(level, 0);
    });
  }

  public storeAllGroupsQuickStatsStateAndHide(): void {
    this.restoreQuickStatsState = [];
    this.labelsByLevel.forEach((labels, level) => {
      if (labels.every(label => label.getQuickStatsShownState())) {
        this.hideGroupsQuickStats(level, false);
        this.restoreQuickStatsState.push(level);
      }
    });
  }

  public restoreAllGroupsQuickStatsState(
    hoverInfoConfig: Array<IHoverInfoFieldModel>,
    customFormattings: IMetricsCustomFormattings
  ) {
    if (this.restoreQuickStatsState.length > 0) {
      this.restoreQuickStatsState.forEach(level => {
        this.showGroupsQuickStats(level, false, hoverInfoConfig, customFormattings);
      });
      this.restoreQuickStatsState = [];
    }
  }

  public setLevelOffset(level: number) {
    const maxSize = this.getMaxQuickStatsSize(level);
    this.offsetByLevel[level] = maxSize;
  }

  private getMaxQuickStatsSize(level: number): number {
    let max = 0;
    this.labelsByLevel[level].forEach(groupLabel => {
      const size = groupLabel.getQuickStats().getSize();
      if (max < size) max = size;
    });
    return max;
  }

  private animateOffset(level: number, duration: number = 0.25) {
    let offset = 0;
    for (let l = level + 1; l < Object.keys(this.labelsByLevel).length; l++) {
      if (this.offsetByLevel[l] > 0) offset += this.offsetByLevel[l] + 2;
    }
    this.labelsByLevel[level].forEach(groupLabel => {
      this.setElementOffset(groupLabel.getWrapper(), offset, duration);
    });
  }

  private setElementOffset(element: HTMLElement, offset: number, duration: number) {
    gsap.killTweensOf(element);
    if (this.labelsOrientation === GroupOrientation.Vertical) {
      gsap.to(element, { duration, x: this.dockingSide === DockingSide.Right ? offset : -offset });
    } else {
      gsap.to(element, { duration, y: this.dockingSide === DockingSide.Top ? -offset : offset });
    }
  }
}
