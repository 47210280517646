import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {
  public email: FormControl;

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { email: string }
  ) {
    this.email = new FormControl(this.data.email, [Validators.required, Validators.email]);
  }

  public sendResetPasswordEmail(): void {
    this.dialogRef.close(this.email.value);
  }
}
