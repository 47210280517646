import { Pipe, PipeTransform } from '@angular/core';
import { FiltersTypeEnum, IFiltersModel } from '@app/models/filters.model';

@Pipe({
  name: 'filterAllIndeterminate'
})
export class FilterAllIndeterminatePipe implements PipeTransform {
  transform(filter: IFiltersModel): boolean {
    if (filter.type === FiltersTypeEnum.Continuous) {
      return (
        filter.currentMinMaxValue &&
        (Number.parseFloat(filter.currentMinMaxValue?.min.toString()) !==
          Number.parseFloat(filter.minMaxValue?.min.toString()) ||
          Number.parseFloat(filter.currentMinMaxValue?.max.toString()) !==
            Number.parseFloat(filter.minMaxValue?.max.toString()))
      );
    }
    if (filter.type === FiltersTypeEnum.Date) {
      return (
        filter.currentMinMaxValue &&
        (filter.currentMinMaxValue?.min !== filter.minMaxValue?.min ||
          filter.currentMinMaxValue?.max !== filter.minMaxValue?.max)
      );
    }
    return (
      filter.isAllChecked &&
      filter.discreteValues.some(val => val.checked) &&
      filter.discreteValues.some(val => !val.checked)
    );
  }
}
