import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDataService } from '@app/services/project-data/project-data.service';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { ICollage } from '@app/state/collage/collage.model';
import { ConnectionType, IConnection } from '@app/state/connection/connection.model';
import { IStudy } from '@app/state/study/study.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss']
})
export class HomeListComponent implements OnInit, OnChanges {
  public readonly PortalMenuItem = PortalMenuItem;
  public readonly ConnectionType = ConnectionType;

  @Input() public type: PortalMenuItem;
  @Input() public isHideAddNew: boolean;
  @Output() public select: EventEmitter<IStudy | ICollage | IConnection> = new EventEmitter();

  public studyList: Array<IStudy>;
  public collageList: Array<ICollage>;
  public connectionList: { business: Array<IConnection>; image: Array<IConnection> };
  public counts = {
    [PortalMenuItem.Studies]: 0,
    [PortalMenuItem.Collages]: 0,
    [PortalMenuItem.Connections]: 0
  };
  public search$: Subject<string> = new Subject();
  public loading: boolean = false;
  private connectionTypes: Array<ConnectionType>;
  public showImageConnections: boolean = true;
  public showBusinessConnections: boolean = true;

  constructor(private projectDataService: ProjectDataService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.search$
      .pipe(debounceTime(200))
      .pipe(untilDestroyed(this))
      .subscribe((search: string) => {
        this.router.navigate([], {
          queryParams: {
            search: search || null
          },
          queryParamsHandling: 'merge'
        });
      });

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(({ connectionType }) => {
      this.connectionTypes = connectionType
        ? connectionType.split(',').map(Number)
        : [ConnectionType.Image, ConnectionType.Business];
      this.showImageConnections = this.connectionTypes.includes(ConnectionType.Image);
      this.showBusinessConnections = this.connectionTypes.includes(ConnectionType.Business);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      switch (changes.type.currentValue) {
        case PortalMenuItem.Studies:
          this.route.queryParams
            .pipe(
              tap(() => {
                this.loading = true;
              }),
              switchMap(({ search }) =>
                this.projectDataService
                  .getStudyList()
                  .pipe(map((studyList: Array<IStudy>) => this.searchByName(studyList, search)))
              ),
              untilDestroyed(this)
            )
            .subscribe((studyList: Array<IStudy>) => {
              this.studyList = studyList;
              this.counts[PortalMenuItem.Studies] = studyList.length;
              this.loading = false;
            });
          break;
        case PortalMenuItem.Collages:
          this.route.queryParams
            .pipe(
              tap(() => {
                this.loading = true;
              }),
              switchMap(({ search }) =>
                this.projectDataService
                  .getCollageList()
                  .pipe(map((collageList: Array<ICollage>) => this.searchByName(collageList, search)))
              ),
              untilDestroyed(this)
            )
            .subscribe((collageList: Array<ICollage>) => {
              this.collageList = collageList;
              this.counts[PortalMenuItem.Collages] = collageList.length;
              this.loading = false;
            });
          break;
        case PortalMenuItem.Connections:
          this.route.queryParams
            .pipe(
              tap(() => {
                this.loading = true;
              }),
              switchMap(({ search }) =>
                combineLatest([
                  this.projectDataService.getBusinessConnectionList(),
                  this.projectDataService.getImageConnectionList()
                ]).pipe(
                  map(([businessList, imageList]: [Array<IConnection>, Array<IConnection>]) => ({
                    business: this.searchByName(businessList, search),
                    image: this.searchByName(imageList, search)
                  }))
                )
              ),
              untilDestroyed(this)
            )
            .subscribe((connectionList: { business: Array<IConnection>; image: Array<IConnection> }) => {
              this.connectionList = connectionList;
              this.counts[PortalMenuItem.Connections] = connectionList.business.length + connectionList.image.length;
              this.loading = false;
            });
          break;
      }
    }
  }

  private searchByName<T extends { metadata: { name: string } }>(list: Array<T>, search: string): Array<T> {
    return list.filter(item => item.metadata.name.toLowerCase().includes(search ? search.toLowerCase() : ''));
  }

  public onSelect(item: any) {
    this.select.emit(item);
  }
}
