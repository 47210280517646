import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PortalMenuItem } from '@app/shared/enums/portal-menu-item.enum';
import { IStudy } from '@app/state/study/study.model';

@Component({
  selector: 'app-home-study-card',
  templateUrl: './home-study-card.component.html',
  styleUrls: ['./home-study-card.component.scss']
})
export class HomeStudyCardComponent {
  @Input() public study: IStudy;
  public readonly PortalMenuItem = PortalMenuItem;

  @HostListener('click')
  public onClick(): void {
    this.router.navigate(['/build'], { queryParams: { studyId: this.study.id } });
  }

  constructor(private router: Router) {}
}
