export interface IUserData {
  email: string;
  groups: Array<UserGroup>;
}

export enum UserGroup {
  Guest = 'guest',
  Collagia = 'collagia',
  Columbia = 'columbia',
  Nike = 'nike'
}
