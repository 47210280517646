import { IFrameTitleChangeEvent } from '@app/models/frame-title-change-event.model';
import { IHoverInfoField } from '@app/models/image.model';
import { Rectangle } from 'pixi.js';
import { FrameContainer } from './frame-container';
import { InfoOverlayBase } from './info-overlay-base';

export class FrameInfoOverlay extends InfoOverlayBase {
  constructor(private frameContRef: FrameContainer) {
    super('frameInfo');
  }

  protected getBounds(): Rectangle {
    return this.frameContRef.getFrameBounds();
  }

  public resize(): void {
    const bounds = this.getBounds();
    this.setSizeAndPosition(bounds);
    this.scaleWrapper();
  }

  public updateText(hoverInfo: Array<IHoverInfoField>, title?: IFrameTitleChangeEvent): void {
    const titleName =
      title?.featureName === undefined
        ? this.frameContRef.getFriendlyName(this.frameContRef.getFeatureName())
        : this.frameContRef.getFriendlyName(title.featureName);
    const titleValue = title?.featureValue === undefined ? this.frameContRef.getFeatureValue() : title.featureValue;
    const titleClass = titleValue ? '' : 'unknown';
    let innerHTML = `
      <div class="frameInfo-wrapper">
        <p class="title ${titleClass}">
          ${titleName}: <span>${titleValue || 'undefined'}</span>
        </p>
      `;
    if (hoverInfo === null) {
      innerHTML += `
        <p class="calculating-message">Calculating...</p>
      `;
    } else {
      hoverInfo?.forEach(info => {
        innerHTML += `<p class="field-name">${info.fieldName}</p>`;
        info.fieldValues.forEach(val => {
          innerHTML += `<p class="field-value">${val}</p>`;
        });
      });
    }
    innerHTML += '</div>';
    this.infoHtmlRef.innerHTML = innerHTML;
    this.wrapperHeight = null;
    this.wrapperHeight = null;
  }
}
