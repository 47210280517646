import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ExploreBarType, FiltersTypeEnum, IFilterDiscreteValue, IFiltersModel } from '@app/models/filters.model';

@Component({
  selector: 'app-filter-box-item',
  templateUrl: './filter-box-item.component.html',
  styleUrls: ['./filter-box-item.component.scss']
})
export class FilterBoxItemComponent implements OnChanges {
  public readonly FiltersTypeEnum = FiltersTypeEnum;

  @Input() public filter: IFiltersModel;
  @Input() public filtering: Array<IFiltersModel> = [];
  @Input() public prefix: ExploreBarType;
  @Input() public index: number;
  @Input() public expanded: boolean;
  @Input() public enableExpansion: boolean;
  // Only for sorting
  @Input() public selected: boolean = false;
  @Input() public searchValue: string;

  @Output() public onExpand: EventEmitter<number> = new EventEmitter();
  @Output() public onCheckboxChange = new EventEmitter<{
    filter: IFiltersModel;
    index: number;
    event: Partial<MatCheckboxChange>;
  }>();
  @Output() public onSelectAll = new EventEmitter<{ filter: IFiltersModel; event: Partial<MatCheckboxChange> }>();
  @Output() public onMinMaxChange = new EventEmitter<{
    filter: IFiltersModel;
    min: number | Date;
    max: number | Date;
  }>();

  public dateRange: FormGroup;
  public expandedState: boolean = false;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.dateRange = this.formBuilder.group({
      start: null,
      end: null
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchValue) {
      this.expandedState = this.searchValue
        ? this.filter.discreteValues?.some(({ name }) =>
            name.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase())
          )
        : this.expanded;
      this.setDateValue();
    }
    if (changes.expanded) {
      this.expandedState = this.expanded;
      this.setDateValue();
    }
    if (changes.filter || changes.filtering) {
      this.uncheckDisabledByFiltering();
    }
  }

  // TODO check for performance drop
  public checkSelectAllIndeterminate(filter: IFiltersModel): boolean {
    if (filter.type === FiltersTypeEnum.Continuous) {
      return (
        filter.currentMinMaxValue &&
        (Number.parseFloat(filter.currentMinMaxValue?.min.toString()) !==
          Number.parseFloat(filter.minMaxValue?.min.toString()) ||
          Number.parseFloat(filter.currentMinMaxValue?.max.toString()) !==
            Number.parseFloat(filter.minMaxValue?.max.toString()))
      );
    }
    if (filter.type === FiltersTypeEnum.Date) {
      return (
        filter.currentMinMaxValue &&
        (filter.currentMinMaxValue?.min !== filter.minMaxValue?.min ||
          filter.currentMinMaxValue?.max !== filter.minMaxValue?.max)
      );
    }
    return (
      filter.isAllChecked &&
      filter.discreteValues.some(val => val.checked) &&
      filter.discreteValues.some(val => !val.checked)
    );
  }

  public onMinMaxValueChange(): void {
    this.cdr.detectChanges(); // update [min]/[max] attributes for minValueInputRef and maxValueInputRef
  }

  public onCheckboxValueChange(name: string, event: MatCheckboxChange) {
    const index = this.filter.discreteValues.findIndex(value => value.name === name);
    this.onCheckboxChange.emit({ filter: this.filter, index, event });
  }

  public onWrapperClick(): void {
    this.onSelectAll.emit({ filter: this.filter, event: { checked: true } });
  }

  public trackByName(index: number, value: IFilterDiscreteValue): string {
    return value.name;
  }

  public toggleExpand(index: number): void {
    if (this.searchValue) {
      this.expandedState = !this.expandedState;
    } else {
      this.onExpand.emit(index);
    }
  }

  private setDateValue(): void {
    if (this.expandedState && this.filter.type === FiltersTypeEnum.Date) {
      this.dateRange.get('start').setValue(this.filter.currentMinMaxValue?.min || null);
      this.dateRange.get('end').setValue(this.filter.currentMinMaxValue?.max || null);
    }
  }

  private uncheckDisabledByFiltering() {
    if (this.filter && this.filter.discreteValues) {
      if (this.filtering?.length > 0) {
        const filtering = this.filtering.find(item => item.name === this.filter.name);
        if (filtering) {
          let isSomethingChanged = false;
          this.filter.discreteValues?.forEach((value, index) => {
            let isNeedDisable;
            if (filtering.isExcluded) {
              isNeedDisable = filtering.discreteValues?.findIndex(v => v.name === value.name) >= 0;
            } else {
              isNeedDisable = filtering.discreteValues?.findIndex(v => v.name === value.name) < 0;
            }
            if (isNeedDisable) {
              isSomethingChanged = isSomethingChanged || value.checked;
              value.checked = false;
              value.disabled = true;
            }
          });
          if (isSomethingChanged && !this.filter.discreteValues.some(item => item.checked)) {
            // there are no any selected discrete value - Remove Filter!
            this.onSelectAll.emit({ filter: this.filter, event: { checked: false } });
          }
        }
      }
    }
  }
}
