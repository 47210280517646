import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(value: number): string {
    if (typeof value !== 'number') {
      return '';
    }

    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(2)} GB`;
    }

    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(2)} MB`;
    }

    return `${(value / 1000).toFixed(2)} KB`;
  }
}
