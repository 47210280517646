export interface IToolsStateModel {
  data?: {
    bottomN?: IToolSettingsModel;
    bottomNP?: IToolSettingsModel;
    topN?: IToolSettingsModel;
    topNP?: IToolSettingsModel;
    dimensions?: Array<IDimension>;
    metrics?: Array<IMetric>;
  };
  visual?: {
    spacing?: IToolSettingsModel;
  };
  activeMatrixHighlight?: boolean;
  selectedTool: MatrixToolEnum;
  selectedView: string;
  toolbox: ToolboxModel;
}

export type ToolboxModel = {
  [key in MatrixToolEnum]: MatrixToolState;
};

export interface IToolSettingsModel {
  name: string;
  value?: number;
  valueArray?: [any];
}

export interface IDimension {
  name: string;
  index: number;
  value: string;
}

export interface IMetric extends IDimension {}

/**
 * Updating ManualHarvest to Freestyle but keeping actual value to old one to keep old studies working
 */
export enum ValidMatrix {
  ValuableInsights = 'valuableInsights',
  GroupBy = 'groupBy',
  Freestyle = 'manualHarvest',
  ImageSearch = 'imageSearch',
  Genome = 'genome',
  New = 'new',
  SpecialUse = 'specialUse',
  Sort = 'sort',
  ChangeOverTime = 'changeOverTime',
  ThreeD = '3d',
  Custom = 'custom',
  Rank = 'rank',
  RankPercent = 'rank%',
  Pareto = 'pareto'
}

export enum MatrixToolEnum {
  Cursor = 'cursor',
  Highlighter = 'highlighter',
  HoverInfoConfig = 'hoverInfoConfig',
  Image = 'image',
  Frame = 'frame',
  Magnify = 'magnify',
  PresentationMode = 'presentationMode'
}

export type MatrixToolState = 'disabled' | 'hidden' | undefined;

export function getTextForValidMatrix(value: ValidMatrix): string {
  switch (value) {
    case ValidMatrix.GroupBy:
      return 'GroupBy';
    case ValidMatrix.Pareto:
      return 'Pareto';
    case ValidMatrix.Rank:
      return 'Rank';
    default:
      return 'Freestyle';
  }
}
