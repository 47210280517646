import { Pipe, PipeTransform } from '@angular/core';
import { IFiltersModel } from '../models/filters.model';

@Pipe({
  name: 'filterTypeIcon'
})
export class FilterTypeIconPipe implements PipeTransform {
  transform(value: IFiltersModel): string {
    if (value.isCustomCalc) {
      return `icon-filter-type-calculator`;
    }
    if (value.dataType) {
      return `icon-filter-type-${value.dataType}`;
    }
    return 'icon-filter-type-string';
  }
}
