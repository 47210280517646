import { MatrixToolEnum, MatrixToolState, ValidMatrix } from '@app/state/tools/tools.model';

export class SetToolboxStateByMatrixType {
  static readonly type = '[TOOLS] SetToolboxStateByMatrixType';
  constructor(public payload: ValidMatrix | undefined) {}
}

export class ResetToolboxState {
  static readonly type = '[TOOLS] ResetToolboxState';
}

export class SetSelectedTool {
  static readonly type = '[TOOLS] SetSelectedTool';
  constructor(public payload: MatrixToolEnum) {}
}

export class SetSelectedView {
  static readonly type = '[TOOLS] SetSelectedView';
  constructor(public payload: string) {}
}

export class RestoreSelectedView {
  static readonly type = '[TOOLS] RestoreSelectedView';
  constructor(public payload: string) {}
}

export class StartMatrixHighlighter {
  static readonly type = '[TOOLS] StartMatrixHighlighter';
}

export class StopMatrixHighlighter {
  static readonly type = '[TOOLS] StopMatrixHighlighter';
}

export class SetToolboxState {
  static readonly type = '[TOOLS] SetTool';
  constructor(public payload?: Partial<Record<MatrixToolEnum, MatrixToolState>>) {}
}
