import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent {
  public changeEmailForm: FormGroup;

  public get newEmail(): FormControl {
    return this.changeEmailForm.get('newEmail') as FormControl;
  }

  public get password(): FormControl {
    return this.changeEmailForm.get('password') as FormControl;
  }

  constructor(private authService: AuthService, private formBuilder: FormBuilder, public location: Location) {
    this.changeEmailForm = this.formBuilder.group({
      newEmail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  public onChangeEmail(): void {
    const { newEmail, password } = this.changeEmailForm.value;
    this.authService.updateEmail(newEmail, password);
  }
}
