<div class="content">
  <ng-content></ng-content>
</div>
<div class="footer">
  <div class="footer__created">
    <div class="footer__created__author">Created by: {{ authorName }}</div>
    <div class="footer__created__date">{{ created | date: 'MMM d y' }}</div>
  </div>
  <div *ngIf="!isDefault" class="footer__action">
    <button
      class="icon-button"
      matRipple
      [matRippleColor]="PortalMenuItemCollorMapper[type]"
      [matRippleCentered]="true"
      [matRippleRadius]="matRippleRadius"
      [matMenuTriggerFor]="cardMenu"
      (click)="$event.stopPropagation()"
    >
      <svg-icon svgClass="icon" name="icon-elipsis-vertical"></svg-icon>
    </button>
    <mat-menu #cardMenu="matMenu">
      <button mat-menu-item (click)="onDelete()">
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>
</div>
