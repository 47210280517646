<app-dialog-base-component (close)="closeDialog()">
  <mat-dialog-content>
    <form [formGroup]="form">
      <input
        #element
        class="editable-title"
        [placeholder]="collageNamePlaceholder"
        (keydown.enter)="element.blur()"
        formControlName="name"
      />

      <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="tabIndex" (selectedIndexChange)="onTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <svg-icon svgClass="icon tab-icon" name="icon-collection"></svg-icon>
            <span class="tab-title">Images</span>
          </ng-template>

          <div class="tab-content tab-images">
            <div class="content-columns row">
              <div class="content-column aside col-3 scrollable">
                <h2>Source</h2>
                <mat-selection-list class="connections-source-list" [multiple]="false">
                  <mat-list-option [selected]="!activeImageConnection" class="color-grey" (click)="resetImageSource()">
                    + New
                  </mat-list-option>
                  <ng-container *ngIf="imageConnectionList$ | async as imageConnectionList">
                    <mat-list-option
                      *ngFor="let connection of imageConnectionList"
                      [selected]="connection.id === activeImageConnection?.id"
                      (click)="selectImageCollection(connection)"
                      [matTooltip]="connection.metadata.name"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipShowDelay]="600"
                    >
                      <span class="item">{{ connection.metadata.name }}</span>
                    </mat-list-option>
                  </ng-container>
                </mat-selection-list>
              </div>

              <div class="content-column col-9 col-last scrollable">
                <ng-container [formGroup]="imageForm">
                  <div class="row">
                    <mat-form-field class="wide-form-field col-6">
                      <mat-label>Connection Name</mat-label>
                      <input matInput formControlName="connection" app-disable-autocomplete-input />
                    </mat-form-field>
                    <mat-form-field class="wide-form-field col-6">
                      <mat-label>DAM Type</mat-label>
                      <mat-select
                        formControlName="type"
                        [disabled]="!!activeImageConnection?.id"
                        (selectionChange)="onImageManagerSelectionChange()"
                      >
                        <mat-option
                          *ngIf="
                            data.currentUserData && data.currentUserData.groups
                              | hasOne : [UserGroup.Collagia, UserGroup.Nike]
                          "
                          [value]="ImageManagerType.Nike"
                          >Nike</mat-option
                        >
                        <mat-option
                          *ngIf="
                            data.currentUserData && data.currentUserData.groups
                              | hasOne : [UserGroup.Collagia, UserGroup.Columbia]
                          "
                          [value]="ImageManagerType.ColumbiaS7"
                          >Columbia</mat-option
                        >
                        <mat-option [value]="ImageManagerType.AdobeExperienceManager"
                          >Adobe Experience Manager</mat-option
                        >
                        <mat-option [value]="ImageManagerType.CollagiaImageLibrary">Collagia Image Library</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <ng-container *ngIf="imageForm.get('type').value !== null">
                    <div class="row">
                      <mat-form-field class="col-6">
                        <mat-label>Host</mat-label>
                        <input matInput formControlName="host" app-disable-autocomplete-input />
                      </mat-form-field>
                      <mat-form-field class="col-3">
                        <mat-label>Port</mat-label>
                        <input matInput formControlName="port" app-disable-autocomplete-input />
                      </mat-form-field>
                      <mat-form-field class="col-3">
                        <mat-label>Timeout (ms)</mat-label>
                        <input matInput formControlName="timeout" app-disable-autocomplete-input />
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <mat-form-field class="wide-form-field" class="col-6">
                        <mat-label>Path to folder</mat-label>
                        <input matInput formControlName="path" app-disable-autocomplete-input />
                      </mat-form-field>
                      <mat-form-field class="wide-form-field" class="col-6">
                        <mat-label>Image file filter</mat-label>
                        <input matInput formControlName="filter" app-disable-autocomplete-input />
                      </mat-form-field>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab [disabled]="imageForm.invalid">
          <ng-template mat-tab-label>
            <svg-icon svgClass="icon tab-icon" name="icon-business"></svg-icon>
            <span class="tab-title">Business</span>
          </ng-template>

          <div class="tab-content tab-business">
            <div class="content-columns row">
              <div class="content-column aside col-3 scrollable">
                <h2>Source</h2>
                <mat-selection-list class="connections-source-list" [multiple]="false">
                  <mat-list-option
                    [selected]="!activeBusinessConnection"
                    class="color-grey"
                    (click)="resetBusinessSource()"
                  >
                    + New
                  </mat-list-option>
                  <ng-container *ngIf="businessConnectionList$ | async as businessConnectionList">
                    <mat-list-option
                      *ngFor="let connection of businessConnectionList"
                      [selected]="connection.id === activeBusinessConnection?.id"
                      (click)="selectBusinessConnection(connection)"
                      [matTooltip]="connection.metadata.name"
                      matTooltipPosition="below"
                      matTooltipClass="below"
                      [matTooltipShowDelay]="600"
                    >
                      <span class="item">{{ connection.metadata.name }}</span>
                    </mat-list-option>
                  </ng-container>
                </mat-selection-list>
              </div>

              <div class="content-column col-9 col-last scrollable" #contentBusinessPanel>
                <ng-container [formGroup]="businessForm">
                  <div class="row">
                    <mat-form-field class="wide-form-field col-6">
                      <mat-label>Connection Name</mat-label>
                      <input #connectionName matInput formControlName="connection" app-disable-autocomplete-input />
                    </mat-form-field>

                    <mat-form-field class="wide-form-field col-6">
                      <mat-label>Select Database</mat-label>
                      <mat-select
                        formControlName="database"
                        [disabled]="!!activeBusinessConnection?.id"
                        (selectionChange)="onDatabaseSelectionChange()"
                      >
                        <mat-option [value]="DatabaseType.CSV">CSV / Excel File</mat-option>
                        <mat-option [value]="DatabaseType.Snowflake">Snowflake</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <ng-container *ngIf="databaseType === DatabaseType.CSV" [formGroup]="$any(businessForm.get('csv'))">
                    <div class="file-upload" [class.preparing]="isCSVPreparing">
                      <div class="upload-area row">
                        <app-upload-file
                          class="col-12"
                          #UploadFileComponent
                          formControlName="csvFile"
                          [accept]="'.csv,.xls,.xlsx'"
                          [isMultiple]="false"
                          [isNeedUpload]="true"
                          [isDisableDelete]="!!activeBusinessConnection?.id"
                          (onSelectFiles)="selectCSVFile($event)"
                          (onDeleteFile)="onDeleteFile($event)"
                          (onFileUploaded)="onFileUploaded($event)"
                        ></app-upload-file>
                      </div>

                      <div
                        *ngIf="!isCSVPreparing && selectedCSVFile"
                        class="row"
                        style="justify-content: space-between"
                      >
                        <div *ngIf="!isCSVUploading" class="select-encoding-button" (click)="selectEncoding()">
                          Encoding: <span>{{ csvEncoding }}</span>
                        </div>
                        <div *ngIf="csvData && totalRows" class="total-rows">
                          First <span>{{ csvData.length }}</span> rows from <span>{{ totalRows }}</span>
                        </div>
                      </div>

                      <div *ngIf="selectedCSVFile" class="row">
                        <div class="csv-preview row">
                          <div class="processing" *ngIf="isCSVPreparing">
                            <mat-progress-bar
                              mode="determinate"
                              class="file-upload-progress-bar"
                              [value]="parsingProgress"
                            ></mat-progress-bar>
                            <div class="error">{{ parsingMessage }}</div>
                          </div>
                          <div class="loading" *ngIf="isCSVPreparing">
                            <mat-spinner></mat-spinner>
                            <div class="approximately-time" #approximatelyTime></div>
                          </div>
                          <app-csv-preview
                            class="col-12"
                            [containerHeight]="csvPreviewHeight"
                            [csvData]="csvData"
                            [csvHeaders]="headers"
                            [csvSettings]="csvSettings"
                          ></app-csv-preview>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="databaseType !== null && databaseType !== DatabaseType.CSV"
                    [formGroup]="$any(businessForm.get('snowflake'))"
                  >
                    <div class="row">
                      <mat-form-field class="col-6">
                        <mat-label>Authentication Type</mat-label>
                        <input matInput formControlName="auth" app-disable-autocomplete-input />
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <mat-form-field class="col-6">
                        <mat-label>User Name</mat-label>
                        <input matInput formControlName="user" app-disable-autocomplete-input />
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" app-disable-autocomplete-input />
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <mat-form-field class="col-6">
                        <mat-label>Host</mat-label>
                        <input matInput formControlName="host" app-disable-autocomplete-input />
                      </mat-form-field>
                      <mat-form-field class="col-3">
                        <mat-label>Port</mat-label>
                        <input matInput formControlName="port" app-disable-autocomplete-input />
                      </mat-form-field>
                      <mat-form-field class="col-3">
                        <mat-label>Timeout (ms)</mat-label>
                        <input matInput formControlName="timeout" app-disable-autocomplete-input />
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <mat-form-field class="wide-form-field col-6">
                        <mat-label>Warehouse</mat-label>
                        <mat-select formControlName="warehouse">
                          <mat-option [value]="1">Warehouse 1</mat-option>
                          <mat-option [value]="2">Warehouse 2</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="wide-form-field col-6">
                        <mat-label>Schema</mat-label>
                        <mat-select formControlName="schema">
                          <mat-option [value]="1">Schema 1</mat-option>
                          <mat-option [value]="2">Schema 2</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab [disabled]="imageForm.invalid || businessForm.invalid">
          <ng-template mat-tab-label>
            <svg-icon svgClass="icon tab-icon" name="icon-combine"></svg-icon>
            <span class="tab-title">Combine</span>
          </ng-template>

          <div class="tab-content tab-combine">
            <div class="content-columns">
              <div class="combine">
                <ng-container [formGroup]="combineForm">
                  <div class="row">
                    <mat-form-field class="wide-form-field col-4">
                      <mat-label>Business Join Field (BID)</mat-label>
                      <mat-select
                        formControlName="businessConnectionField"
                        [disableOptionCentering]="true"
                        (openedChange)="onBIDFieldOpen()"
                        (selectionChange)="businessConnectionFieldSearch.reset()"
                      >
                        <input
                          class="select-search"
                          #bidSearch
                          [formControl]="businessConnectionFieldSearch"
                          placeholder="Search..."
                        />
                        <mat-option
                          *ngFor="let header of filteredBIDHeaders$ | async; let i = index"
                          [value]="header"
                          [id]="'bid-option-' + i"
                        >
                          {{ header }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          combineForm.get('businessConnectionField').touched &&
                          combineForm.get('businessConnectionField').hasError('required')
                        "
                      >
                        Business Join Field is required.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          combineForm.get('businessConnectionField').touched &&
                          !combineForm.get('businessConnectionField').hasError('required') &&
                          combineForm.get('businessConnectionField').hasError('selectionRequired')
                        "
                      >
                        No match for that value
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="wide-form-field col-4">
                      <mat-label>Display Name</mat-label>
                      <mat-select
                        formControlName="displayNameField"
                        [disableOptionCentering]="true"
                        (openedChange)="onDisplayNameOpen()"
                        (selectionChange)="displayNameFieldSearch.reset()"
                      >
                        <input
                          class="select-search"
                          #dnSearch
                          [formControl]="displayNameFieldSearch"
                          placeholder="Search..."
                        />
                        <mat-option
                          *ngFor="let header of filteredDisplayHeaders$ | async; let i = index"
                          [value]="header"
                          [id]="'display-name-option-' + i"
                        >
                          {{ header }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          combineForm.get('displayNameField').touched &&
                          combineForm.get('displayNameField').hasError('required')
                        "
                      >
                        Display Name is required.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          combineForm.get('displayNameField').touched &&
                          !combineForm.get('displayNameField').hasError('required') &&
                          combineForm.get('displayNameField').hasError('selectionRequired')
                        "
                      >
                        No match for that value
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="wide-form-field col-4">
                      <mat-label>Primary Metric</mat-label>
                      <mat-select
                        formControlName="primaryMetricField"
                        [disabled]="measureHeaders.length === 0"
                        [disableOptionCentering]="true"
                        (openedChange)="onPrimaryMetricOpen()"
                        (selectionChange)="primaryMetricFieldSearch.reset()"
                      >
                        <input
                          class="select-search"
                          #pmSearch
                          [formControl]="primaryMetricFieldSearch"
                          placeholder="Search..."
                        />
                        <mat-option
                          *ngFor="let header of filteredMeasureHeaders$ | async; let i = index"
                          [value]="header"
                          [id]="'primary-metric-option-' + i"
                        >
                          {{ header }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          combineForm.get('primaryMetricField').touched &&
                          combineForm.get('primaryMetricField').hasError('selectionRequired')
                        "
                      >
                        No match for that value
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="row">
                    <div
                      class="required-bid-field-message"
                      *ngIf="combineForm.get('businessConnectionField').hasError('required')"
                    >
                      Please select the fields above.
                    </div>
                    <ng-container *ngIf="!combineForm.get('businessConnectionField').hasError('required')">
                      <div class="images-loader" *ngIf="imagesLoading">
                        <mat-spinner></mat-spinner>
                      </div>
                      <ng-container *ngIf="!imagesLoading">
                        <ng-container
                          *ngIf="placeholderByBusinessConnectionField[businessConnectionField] as placeholder"
                        >
                          <div class="msg-error" *ngIf="placeholder.images.length === 0">
                            Can't join fields, please choose correct BID
                          </div>

                          <div *ngIf="placeholder.images.length > 0">
                            <span class="carousel-label">Default View</span>

                            <div class="carousel-container">
                              <app-carousel
                                *ngIf="placeholder.images.length > 0"
                                #carousel
                                [images]="placeholder.images"
                                [displayName]="placeholder.displayName"
                                [cellWidth]="300"
                                [cellHeight]="300"
                                [cellGap]="10"
                                (viewSelected)="changeDefaultView($event)"
                              ></app-carousel>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <mat-dialog-actions align="end">
        <ng-container [ngSwitch]="tabIndex">
          <button
            *ngSwitchCase="0"
            [disabled]="imageForm.invalid"
            mat-flat-button
            (click)="tabIndex = 1"
            cdkFocusInitial
          >
            Next
          </button>
          <button *ngSwitchCase="1" [disabled]="businessForm.invalid" mat-flat-button (click)="tabIndex = 2">
            Next
          </button>
          <button
            *ngSwitchCase="2"
            [disabled]="combineForm.invalid"
            mat-flat-button
            (click)="form.controls.name.hasError('required') ? highlightUntitledCollageError() : getDialogResult()"
          >
            Done
          </button>
        </ng-container>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</app-dialog-base-component>
