<div
  class="rankAbsolutePlottingLabel"
  [ngStyle]="labelStyle"
  mwlResizable
  [validateResize]="validateResize.bind(this)"
  (resizing)="onResizing($event)"
  (resizeEnd)="onResizeEnd($event)"
>
  <div *ngIf="!isHorizontal" class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
  <div *ngIf="!isHorizontal" class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div>
  <div *ngIf="isHorizontal" class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
  <div *ngIf="isHorizontal" class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>

  <div class="points-wrapper" [ngStyle]="pointsWrapperStyle">
    <div
      class="point"
      *ngFor="let point of points; let i = index"
      [ngStyle]="getPointStyle(point)"
      [class.active]="i == 0 || i == points.length - 1 || point == activePoint"
      (mouseover)="onPointMouseOver(point)"
      (mouseout)="onPointMouseOut()"
    >
      <span class="dot"></span>
      <p [ngStyle]="pointValueStyle">{{ formatPoint(point) }}</p>
    </div>
    <span class="line" *ngIf="showLine" [ngStyle]="lineStyle"></span>
  </div>
</div>
