<div class="home-list">
  <div class="home-list__search">
    <app-home-list-search
      [type]="type"
      [resultCount]="counts[type]"
      (search)="search$.next($event)"
    ></app-home-list-search>
  </div>
  <div class="home-list__content scrollable">
    <ng-container [ngSwitch]="loading">
      <ng-container *ngSwitchCase="false">
        <ng-container [ngSwitch]="type">
          <div *ngSwitchCase="PortalMenuItem.Studies" class="home-list__content__items">
            <app-home-new-card *ngIf="!isHideAddNew" [type]="type"></app-home-new-card>
            <app-home-study-card
              *ngFor="let study of studyList"
              [study]="study"
              (click)="onSelect(study)"
            ></app-home-study-card>
          </div>
          <div *ngSwitchCase="PortalMenuItem.Collages" class="home-list__content__items">
            <app-home-new-card *ngIf="!isHideAddNew" [type]="type"></app-home-new-card>
            <app-home-collage-card
              *ngFor="let collage of collageList"
              [collage]="collage"
              (click)="onSelect(collage)"
            ></app-home-collage-card>
          </div>
          <ng-container *ngSwitchCase="PortalMenuItem.Connections">
            <div *ngIf="connectionList?.image && showImageConnections" class="home-list__content__items">
              <app-home-connection-card
                *ngFor="let connection of connectionList.image"
                [connection]="connection"
                (click)="onSelect(connection)"
              ></app-home-connection-card>
            </div>
            <div *ngIf="connectionList?.business && showBusinessConnections" class="home-list__content__items">
              <app-home-connection-card
                *ngFor="let connection of connectionList.business"
                [connection]="connection"
                (click)="onSelect(connection)"
              ></app-home-connection-card>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <div *ngSwitchCase="true" class="loader">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
