import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserDataState } from '@app/state/user-data/user-data.state';
import { IFiltersModel, FiltersTypeEnum } from '@app/models/filters.model';
import { IGroupByFrameModel, IRankGroupModel } from '@app/models/group-model';
import { IImageData } from '@app/models/image.model';
import { CollageState } from '@app/state/collage/collage.state';
import { SetCustomAttributeFilters } from '@app/state/study/study.actions';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { StudyState } from '@app/state/study/study.state';
import { IImageAggregatedHoverInfoResult } from '../../utils/image-data.utils';
import { IParetoDataModel } from '@app/models/pareto-model';
import { FriendlyNameService, IFriendlyName } from '../friendly-name/friendly-name.service';
import { IMetricCustomFormatting } from '@app/models/study-setting.model';
import { formatBigNumber } from '@app/utils/string';
import { UpdateCalculationResults } from '../../state/user-data/user-data.actions';
import {
  SetCustomCalculationFilters,
  UpdateCustomCalculationFiltersInWorkspaces
} from '../../state/study/study.actions';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IBusinessData } from '../../state/connection/business/business-connection.model';
import { BusinessConnectionState } from '../../state/connection/business/business-connection.state';
import { ICustomCalculationResults } from '../../state/user-data/user-data.model';
import { IMakeBackwardFilteringResult } from '../../workers/worker.type';
import { ColumnType, IColumnTypeSettings } from '../../shared/utils/parse-data-file';
import { ICustomAttributes } from '../../state/study/study.model';
import { ISelectedRowsForServerQuery } from '../../components/build/business-data-grid/select-rows.service';
import { IHoverInfoField, IValuesMap } from '../../models/image.model';

export interface IParsedBusinessData {
  id: string;
  rowsTotal: number;
  measures: Array<IFiltersModel>;
  dimensions: Array<IFiltersModel>;
  headers: Array<string>;
  settings: {
    fileURL: string;
    encoding: string;
    filename: string;
  };
}

export interface IBusinessRows {
  id: string;
  rows: Array<Array<any>>;
  offset: number;
  limit: number;
  total: number;
}

export interface IAggregateByFiltersResult {
  success: boolean;
  message: string;
  data: {
    imageData: any;
    filteredRows: any;
  };
  businessConnectionField: string;
  indexOfProdId: string;
}

export interface IAggregateRankResult {
  success: boolean;
  message: string;
  data: Array<IRankGroupModel>;
  businessConnectionField: string;
  indexOfProdId: string;
}

export interface IAggregateParetoResult {
  success: boolean;
  message: string;
  data: IParetoDataModel;
  businessConnectionField: string;
  indexOfProdId: string;
}

export interface IAggregateGroupByResult {
  success: boolean;
  message: string;
  data: Array<IGroupByFrameModel>;
  businessConnectionField: string;
  indexOfProdId: string;
}

export interface ICreateCustomCalculationFiltersResult {
  success: boolean;
  message: string;
  data: {
    customCalculationFilters: Array<IFiltersModel>; // full list of filters
    calculatedResults: ICustomCalculationResults; // custom calculated results for each productID
    changedFilters: Array<IFiltersModel>; // list of updated filters
  };
  businessConnectionField: string;
  indexOfProdId: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public dimensions = [];
  public currentData = [];
  public header = [];
  public $matDims: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public measures = [];
  public sampleData = [];

  public dataSubject: Subject<string[][]> = new Subject();
  public sampleSubject: Subject<string[][]> = new Subject();

  public placeHolderCount = 0;

  constructor(private http: HttpClient, private store: Store, private friendlyNameService: FriendlyNameService) {}

  /**
   * Create new BusinessData object before starting to Parse CSV or XLS file
   * Next step is call parseBusinessDataFile(businessDataId)
   * @param fileURL could be like external URL (https://...) or bucket fileRef (/uploads/fileName.csv)
   * @param encoding 'utf-8' or something like this
   * @param filename file name to show in UI (fileName.csv)
   */
  public createNewBusinessData(fileURL: string, encoding: string, filename: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/createNewBusinessData`, {
        data: {
          fileURL,
          encoding,
          filename
        }
      })
      .pipe(map((data: any) => data.result));
  }

  /**
   * Parse CSV or XLS file for BusinessData
   * @param businessDataId
   */
  public parseBusinessDataFile(businessDataId: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/parseDataFileTask`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(map((data: any) => data.result));
  }

  /**
   * Get status of Parsing CSV file for BusinessData
   * @param businessDataId
   */
  public getBusinessDataProgress(businessDataId: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessDataProgress`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(
        map((data: any) => {
          return data.result;
        })
      );
  }

  /**
   * Cancel parsing CSV/XLSX file AND delete BusinessData object
   * Parsing of BusinessData started by parseDataFileTask and take a lot of time
   * So, this endpoint to cancel processing CSV/XLSX file by User
   * @param businessDataId
   */
  public cancelParsingBusinessData(businessDataId: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/cancelParsingBusinessData`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(
        map((data: any) => {
          return data.result;
        })
      );
  }

  public getBusinessData(businessDataId: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessData`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(
        map((data: any) => {
          const result = data.result;
          result.dimensions.forEach(dimension => {
            dimension.discreteValues = dimension.discreteValues.map(value => {
              return { name: value };
            });
          });
          return result;
        })
      );
  }

  public deleteBusinessData(businessDataId: string): Observable<IBusinessData> {
    return this.http
      .post(`${environment.dataAPIUrl}/deleteBusinessData`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(map((data: any) => data.result));
  }

  public getBusinessRows(businessDataId: string, offset: number, limit: number): Observable<IBusinessRows> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessRows`, {
        data: {
          id: businessDataId,
          offset,
          limit
        }
      })
      .pipe(map((data: any) => data.result));
  }

  public getBusinessRowsForProducts(
    businessDataId: string,
    productIDs: string[], // array of images/productIDs visible on Matrix
    indexProductID: number,
    sortColumnIndex: number,
    sort: string,
    columnType: ColumnType,
    columnSettings: IColumnTypeSettings,
    customAttributes: ICustomAttributes, // for case, when user sort by Custom Attributes column, otherwise = null
    selectedRowIndexes: ISelectedRowsForServerQuery, // uses when need to export selected rows (Clipboard or download in CSV format)
    offset: number,
    limit: number
  ): Observable<IBusinessRows> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessRowsForProducts`, {
        data: {
          id: businessDataId,
          productIDs,
          indexProductID,
          sortColumnIndex,
          sort,
          columnType,
          columnSettings,
          customAttributes,
          selectedRowIndexes,
          offset,
          limit
        }
      })
      .pipe(map((data: any) => data.result));
  }

  public getBusinessRowsAll(businessDataId: string): Observable<IBusinessRows> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessRows`, {
        data: {
          id: businessDataId
        }
      })
      .pipe(map((data: any) => data.result));
  }

  public getBusinessUniqueProdIds(businessDataId: string, indexProductID: number): Observable<Array<any>> {
    return this.http
      .post(`${environment.dataAPIUrl}/getBusinessUniqueProdIds`, {
        data: {
          id: businessDataId,
          indexProductID
        }
      })
      .pipe(map((data: any) => data.result.uniqueProdIds));
  }

  public waitForCustomCalcResults(filters: Array<IFiltersModel> | boolean, canceled$?: Subject<void>): Observable<any> {
    if (
      (filters as IFiltersModel[]).some(
        item => item.customCalcField || (item.children && item.children[0]?.customCalcField)
      )
    ) {
      if (canceled$) {
        return this.store.select(UserDataState.getCalculationResults).pipe(
          takeUntil(canceled$),
          filter(value => value && Object.keys(value).length > 0),
          take(1)
        );
      }
      return this.store.select(UserDataState.getCalculationResults).pipe(
        filter(value => value && Object.keys(value).length > 0),
        take(1)
      );
    }
    return of(null);
  }

  // Subjects to cancel previous (not completed yet)  API calls
  private stopApiAggregateByFiltersSubject$: Subject<void> = new Subject();
  private stopApiAggregateRankSubject$: Subject<void> = new Subject();
  private stopApiAggregateGroupBySubject$: Subject<void> = new Subject();
  private stopApiAggregateParetoSubject$: Subject<void> = new Subject();
  private stopApiGetHighlightedProductsSubject$: Subject<void> = new Subject();
  private stopApiCreateFiltersFromCustomCalculationsSubject$: Subject<void> = new Subject();
  private stopApiGetBackwardFilteringSubject$: Subject<void> = new Subject();

  public apiAggregateByFilters(filters: Array<IFiltersModel>): Observable<IAggregateByFiltersResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.getAllFriendlyNames();

    this.stopApiAggregateByFiltersSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiAggregateByFiltersSubject$;

    return this.waitForCustomCalcResults(filters, canceled$).pipe(
      switchMap(() => {
        const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);
        return this.http
          .post(`${environment.dataAPIUrl}/aggregateByFilters`, {
            data: {
              id: businessDataId,
              businessConnectionField,
              displayNameField,
              customAttributes,
              customCalculationResults,
              filters,
              filtering: null, // filters uses as filtering for Manual matrix
              friendlyNames
            }
          })
          .pipe(
            take(1),
            takeUntil(canceled$),
            map((data: any) => data.result)
          );
      })
    );
  }

  public cancelApiGetBackwardFiltering() {
    this.stopApiGetBackwardFilteringSubject$.next(); // stop all requests which not completed yet
  }

  public apiGetBackwardFiltering(
    filters: Array<IFiltersModel>,
    userFilters: Array<IFiltersModel>
  ): Observable<IMakeBackwardFilteringResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);

    // -- Don't stop previous requests, should be stopped manually by cancelApiGetBackwardFiltering()
    // -- this.stopApiGetBackwardFilteringSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiGetBackwardFilteringSubject$;

    return this.http
      .post(`${environment.dataAPIUrl}/getBackwardFiltering`, {
        data: {
          id: businessDataId,
          businessConnectionField,
          displayNameField,
          customAttributes,
          customCalculationResults,
          filters,
          userFilters
        }
      })
      .pipe(
        take(1),
        takeUntil(canceled$),
        map((response: any) => response.result.data)
      );
  }

  /**
   * Note: filters contain both Axes: first item is Axes.x[0], other items are Axes.y
   */
  public apiAggregateRank(
    filters: Array<IFiltersModel>,
    filtering: Array<IFiltersModel>
  ): Observable<IAggregateRankResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const metricsCustomFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.getAllFriendlyNames();

    let hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    if (filters.length && filters[0]?.customCalcField) {
      // First filter is grouping and Custom Calc
      // Need to be sure, that is hoverInfoConfig include this Custom Calc
      // Because hoverInfoConfig uses to aggregate data for each image,
      // and value of Custom Calc will be used for Absolute plotting (see field Image.data.sortBy)
      if (!hoverInfoConfig.some(config => config.name === filters[0].name)) {
        // current hoverInfoConfig doesn't include customCalcField, so add it to the first position
        const customCalcFilters = this.store.selectSnapshot(StudyState.getCustomCalculationFilters);
        const calcFilter = customCalcFilters.find(f => f.name === filters[0].name);
        if (calcFilter) {
          const customCalcField = calcFilter.customCalcField;
          customCalcField.isDontShowHoverInfo = true; // aggregate Custom Calc values in sortByValue, but don't show in Hover Info
          hoverInfoConfig = [customCalcField, ...hoverInfoConfig];
        }
      }
    }

    this.stopApiAggregateRankSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiAggregateRankSubject$;

    return this.waitForCustomCalcResults(filters, canceled$).pipe(
      switchMap(() => {
        const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);
        return this.http
          .post(`${environment.dataAPIUrl}/aggregateRank`, {
            data: {
              id: businessDataId,
              businessConnectionField,
              displayNameField,
              customAttributes,
              customCalculationResults,
              filters,
              filtering,
              friendlyNames,
              hoverInfoConfig,
              metricsCustomFormattings
            }
          })
          .pipe(
            take(1),
            takeUntil(canceled$),
            map((data: any) => {
              const result = data.result as IAggregateRankResult;

              // convert Array() to Set() in data.attributes
              if (result.data) {
                result.data.forEach(group => {
                  this.convertGroupByFrameModel(group);
                });
              }

              return result;
            })
          );
      })
    );
  }

  /**
   * Convert "Image > data > attributes" from Array[] to Set()
   * Set() cannot be sent as JSON via HTTP, so convert it here from Array
   * @param image
   */
  convertAttributesArrayToSet(imageData: IImageData) {
    Object.keys(imageData.attributes).forEach(attr => {
      if (imageData.attributes[attr] instanceof Array) {
        Object.keys(imageData.attributes[attr]).forEach(key => {
          //          imageData.attributes[attr][key] = new Set(imageData.attributes[attr][key]);
        });
      }
    });
  }

  public apiAggregateGroupBy(
    filters: Array<IFiltersModel>,
    filtering: Array<IFiltersModel>
  ): Observable<IAggregateGroupByResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    const metricsCustomFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.getAllFriendlyNames();

    this.stopApiAggregateGroupBySubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiAggregateGroupBySubject$;

    return this.waitForCustomCalcResults(filters, canceled$).pipe(
      switchMap(() => {
        const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);
        return this.http
          .post(`${environment.dataAPIUrl}/aggregateGroupBy`, {
            data: {
              id: businessDataId,
              businessConnectionField,
              displayNameField,
              customAttributes,
              customCalculationResults,
              filters,
              filtering,
              friendlyNames,
              hoverInfoConfig,
              metricsCustomFormattings
            }
          })
          .pipe(
            take(1),
            takeUntil(canceled$),
            map((data: any) => {
              const result = data.result as IAggregateGroupByResult;

              // convert Array() to Set() in data.attributes
              if (result.data) {
                result.data.forEach(group => {
                  this.convertGroupByFrameModel(group);
                });
              }

              return result;
            })
          );
      })
    );
  }

  private convertGroupByFrameModel(group: IGroupByFrameModel | IRankGroupModel) {
    if (group.groups && group.groups.length > 0) {
      group.groups.forEach(subGroup => this.convertGroupByFrameModel(subGroup));
    }
    group.images?.forEach(image => this.convertAttributesArrayToSet(image.data));
  }

  public apiGetHighlightedProducts(filters: IFiltersModel[], shownProductIds: string[]): Observable<Set<string>> {
    if (filters?.length === 0) {
      return of(new Set([]));
    }
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);

    this.stopApiGetHighlightedProductsSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiGetHighlightedProductsSubject$;

    return this.http
      .post(`${environment.dataAPIUrl}/filterHighlights`, {
        data: {
          id: businessDataId,
          businessConnectionField,
          displayNameField,
          customAttributes,
          customCalculationResults,
          filtering: filters,
          shownProductIds
        }
      })
      .pipe(
        take(1),
        takeUntil(canceled$),
        map((response: any) => {
          return new Set(response.result.data);
        })
      );
  }

  /**
   * Note: filters contain both Axes: first item is Axes.x[0], other items are Axes.y
   */
  public apiAggregatePareto(
    filters: Array<IFiltersModel>,
    filtering: Array<IFiltersModel>,
    groupBoundIndexes: Array<number> = []
  ): Observable<IAggregateParetoResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    const metricsCustomFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.getAllFriendlyNames();

    this.stopApiAggregateParetoSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiAggregateParetoSubject$;

    return this.waitForCustomCalcResults(filters, canceled$).pipe(
      switchMap(() => {
        const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);
        return this.http
          .post(`${environment.dataAPIUrl}/aggregatePareto`, {
            data: {
              id: businessDataId,
              businessConnectionField,
              displayNameField,
              customAttributes,
              customCalculationResults,
              filters,
              filtering,
              friendlyNames,
              hoverInfoConfig,
              metricsCustomFormattings,
              groupBoundIndexes
            }
          })
          .pipe(
            take(1),
            takeUntil(canceled$),
            map((data: any) => {
              const result = data.result as IAggregateParetoResult;

              // convert Array() to Set() in data.attributes
              if (result.data) {
                result.data?.displayData.forEach(group => {
                  group.products.forEach(product => {
                    // @ts-ignore
                    this.convertAttributesArrayToSet(product.imageData.data);
                  });
                });
              }

              return result;
            })
          );
      })
    );
  }

  /**
   * TODO (HE):
   * Remove if not usefull after full integration
   */
  createFiltersFromCustomAttr() {
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    if (!customAttributes) return;

    const frameFilters: IFiltersModel[] = [];
    const filterNames = Object.keys(customAttributes);
    filterNames.forEach(name => {
      const values = Object.keys(customAttributes[name]);
      frameFilters.push({
        name,
        type: FiltersTypeEnum.Discrete,
        discreteValues: values.map(value => {
          return { name: `${value}`, checked: false };
        }),
        isFrameTitle: true,
        isCustomAttr: true,
        isCustomCalc: false
      });
    });

    this.store.dispatch(new SetCustomAttributeFilters(frameFilters));
  }

  getCustomFormattedNumber(number: number, formatting: IMetricCustomFormatting): string {
    return `${formatting?.prefix ? formatting.prefix : ''}${formatBigNumber(
      formatting?.multiplier ? number * formatting.multiplier : number,
      formatting?.fractionDigit
    )}${formatting?.suffix ? formatting.suffix : ''}`;
  }

  public apiCreateFiltersFromCustomCalculations() {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customCalcFilters: IFiltersModel[] = this.store.selectSnapshot(StudyState.getCustomCalculationFilters);
    const hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);

    // collect all Custom Calc (from root or in children)
    const customCalcHoverInfo = [];
    hoverInfoConfig.forEach(config => {
      if (config.type === FiltersTypeEnum.Calculated) {
        customCalcHoverInfo.push(config);
      } else {
        if (config.type === FiltersTypeEnum.UniqueString && config.children && config.children[0]?.calcId) {
          customCalcHoverInfo.push(config.children[0]);
        }
      }
    });

    this.stopApiCreateFiltersFromCustomCalculationsSubject$.next(); // stop all requests which not completed yet
    const canceled$ = this.stopApiCreateFiltersFromCustomCalculationsSubject$;

    return this.http
      .post(`${environment.dataAPIUrl}/createCustomCalculationFilters`, {
        data: {
          id: businessDataId,
          businessConnectionField,
          displayNameField,
          customCalcHoverInfo,
          customCalcFilters
        }
      })
      .pipe(
        take(1),
        takeUntil(canceled$),
        map((response: any) => {
          const data = (response.result as ICreateCustomCalculationFiltersResult).data;
          this.store.dispatch([
            new SetCustomCalculationFilters(data.customCalculationFilters),
            new UpdateCalculationResults(data.calculatedResults)
          ]);

          // Mark each workspace, where uses changed Calc filter as "needRebuild"
          if (data.changedFilters.length > 0) {
            this.store.dispatch(new UpdateCustomCalculationFiltersInWorkspaces(data.changedFilters));
          }

          return data;
        })
      );
  }

  /**
   *  After every filtering calculates total sales and units
   *  creates filters map for each product(image)
   */
  public getImageData(rows: any[]): Array<IImageData> {
    const headers = this.store.selectSnapshot(UserDataState.getHeader);
    const prodIdHeader = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const indexOfProdId = headers.indexOf(prodIdHeader);
    const indexOfDisplayName = headers.indexOf(displayNameField);

    const imageData = [];

    rows.forEach(row => {
      const id: string = row[indexOfProdId];
      if (id !== undefined && id !== null && imageData.findIndex(item => item.prodId === id) === -1) {
        imageData.push({
          prodId: id,
          displayName: row[indexOfDisplayName],
          attributes: headers.reduce(
            (prev, name) => ({
              ...prev,
              [name]: new Set()
            }),
            {}
          )
        });
      }
    });

    return imageData;
  }

  public addMatDim(dim) {
    this.$matDims.next(this.$matDims.getValue().concat(dim));
  }
  public topNBy(n, sortColumn, rows) {
    // VERY simple TopN Does not account for equal values of item 11+
    const header = this.store.selectSnapshot(UserDataState.getHeader);
    const sortIndex = header.indexOf(sortColumn);
    rows = rows.sort((a, b) => b[sortIndex] - a[sortIndex]);
    return rows.slice(0, n);
  }
  public topPBy(n, sortColumn, rows) {
    const header = this.store.selectSnapshot(UserDataState.getHeader);
    const sortIndex = header.indexOf(sortColumn);
    rows = rows.sort((a, b) => b[sortIndex] - a[sortIndex]);
    const qtyReturn = Math.ceil(rows.length * (n / 100));
    return rows.slice(0, qtyReturn);
  }
  public topPercent(percent, index, rows) {
    const qtyToReturn = Math.ceil(rows.length * percent * 0.01);
    rows = rows.sort((a, b) => b[index] - a[index]);
    return rows.slice(0, qtyToReturn);
  }
  public dropHandler(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      // No need for re-ordering within container
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (event.container.data.includes(event.previousContainer.data[event.previousIndex])) {
      // Duplicate item, do nothing
    } else if (/dimension|measures/i.test(event.previousContainer.id)) {
      event.container.data.push(event.previousContainer.data[event.previousIndex]);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
  public getHeaderSnapshot() {
    return this.store.selectSnapshot(UserDataState.getHeader);
  }
  private jsonArrayTo2D(arrayOfObjects) {
    const header = [];
    const AoA = [];
    arrayOfObjects.forEach(obj => {
      // Ensure header includes all values in this object.
      // Previous rows will not have empty value in any new columns created
      Object.keys(obj).forEach(key => header.includes(key) || header.push(key));
      const thisRow = new Array(header.length);
      header.forEach((col, i) => {
        thisRow[i] = obj[col] || '';
      });
      AoA.push(thisRow);
    });
    AoA.unshift(header);
    return AoA;
  }

  // trigger to stop all active getImageAggregateHoverInfo requests
  private stopImageAggregateHoverInfoSubject$: Subject<void> = new Subject();

  /**
   * Stop all active ImageAggregateHoverInfo Requests which not completed yet
   * Should be called ONLY once before calling getImageAggregatedHoverInfo()
   * - when Data set changed (by filters or by XXX_Matrix settings)
   * If Matrix contain frames/groups, then call this method ONCE before processing all of them
   */
  public stopActiveImageAggregateRequests() {
    this.stopImageAggregateHoverInfoSubject$.next();
    // --> console.log('-->> getImageAggregatedHoverInfo > stopActiveImageAggregateRequests: ', this.activeImageAggregateRequests.size);
  }

  public getImageAggregatedHoverInfo(
    filteredRows: any[],
    sortBy?: string,
    callback?: (result: IImageAggregatedHoverInfoResult) => void
  ) {
    // --> const startTime = new Date().getTime();
    // --> console.log('-->> getImageAggregatedHoverInfo start', data);

    if (!filteredRows) {
      return;
    }

    const filteredRowIndexes = [];
    filteredRows.forEach(row => {
      filteredRowIndexes.push(row[row.length - 1]); // last item in each row it's a rowIndex
    });

    // Web Worker is not supported. So make everything in Main thread
    this.apiGetImageAggregatedHoverInfo(filteredRowIndexes, sortBy).subscribe(imageData => {
      // --> console.log('-->> getImageAggregatedHoverInfo done', new Date().getTime() - startTime, imageData);
      if (callback) {
        callback(imageData);
      }
    });
  }

  private apiGetImageAggregatedHoverInfo(
    filteredRowIndexes: any[],
    sortBy?: string
  ): Observable<IImageAggregatedHoverInfoResult> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    const businessConnectionField = this.store.selectSnapshot(CollageState.getBusinessConnectionField);
    const displayNameField = this.store.selectSnapshot(CollageState.getDisplayNameField);
    const customAttributes = this.store.selectSnapshot(StudyState.getCustomAttributes);
    const customCalculationResults = this.store.selectSnapshot(UserDataState.getCalculationResults);
    const hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    const metricsCustomFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.isShowOriginalHeaders
      ? this.friendlyNameService.getAllFriendlyNames()
      : [];
    const canceled$ = this.stopImageAggregateHoverInfoSubject$;

    return this.http
      .post(`${environment.dataAPIUrl}/getImageAggregatedHoverInfo`, {
        data: {
          id: businessDataId,
          businessConnectionField,
          displayNameField,
          customAttributes,
          customCalculationResults,
          friendlyNames,
          hoverInfoConfig,
          metricsCustomFormattings,
          filteredRowIndexes,
          sortBy
        }
      })
      .pipe(
        take(1),
        takeUntil(canceled$),
        map((response: any) => {
          const data = response.result.data as IImageAggregatedHoverInfoResult;

          // convert Array() to Set() in data.attributes
          Object.keys(data).forEach(image => {
            this.convertAttributesArrayToSet(data[image]);
          });

          return data;
        })
      );
  }

  public getFrameAggregatedHoverInfo(
    imagesData: IImageData[],
    countFirstHoverInfoConfigs: number = 0, // if > 0, will calc only first N configs
    callback?: (result: IHoverInfoField[]) => void
  ) {
    // --> const startTime = new Date().getTime();
    // --> console.log(`-->> getFrameAggregatedHoverInfo start ${startTime}`, imagesData);

    if (imagesData.some(data => data.hoverInfo === null)) {
      // Hover Info still calculating
      if (callback) {
        callback(null); // not ready to calc HoverInfo; show "Calculating..." for Frame
      }
      return;
    }

    const allImageAttributes = [];
    imagesData.forEach(data => {
      allImageAttributes.push(data.attributes); // we need only attributes field (aggregated data)
    });

    // Web Worker is not supported. So make everything in Main thread
    this.apiGetFrameAggregatedHoverInfo(allImageAttributes, countFirstHoverInfoConfigs).subscribe(imageData => {
      // --> console.log(`-->> getFrameAggregatedHoverInfo done ${startTime}`, new Date().getTime() - startTime, imageData);
      if (callback) {
        callback(imageData);
      }
    });
  }

  // trigger to stop all active getFrameAggregatedHoverInfo requests
  private stopFrameAggregateHoverInfoSubject$: Subject<void> = new Subject();

  /**
   * Stop all active FrameAggregateHoverInfo Requests which not completed yet
   * Should be called ONLY once before calling getFrameAggregatedHoverInfo()
   * - when Data set changed (by filters or by XXX_Matrix settings)
   * If Matrix contain frames/groups, then call this method ONCE before processing all of them
   */
  public stopActiveFrameAggregateRequests() {
    this.stopFrameAggregateHoverInfoSubject$.next();
    // --> console.log('-->> getImageAggregatedHoverInfo > stopActiveFrameAggregateRequests: ', this.activeFrameAggregateRequests.size);
  }

  private apiGetFrameAggregatedHoverInfo(
    imagesData: Array<IValuesMap> = [],
    countFirstHoverInfoConfigs: number = 0
  ): Observable<IHoverInfoField[]> {
    const businessDataId = this.store.selectSnapshot(BusinessConnectionState.getActiveBusinessDataId);
    let hoverInfoConfig = this.store.selectSnapshot(StudyState.getHoverInfoConfig);
    if (countFirstHoverInfoConfigs > 0) {
      hoverInfoConfig.slice(0, countFirstHoverInfoConfigs);
    }
    const metricsCustomFormattings = this.store.selectSnapshot(StudyState.getMetricsCustomFormattings);
    const friendlyNames: IFriendlyName[] = this.friendlyNameService.isShowOriginalHeaders
      ? this.friendlyNameService.getAllFriendlyNames()
      : [];
    const canceled$ = this.stopFrameAggregateHoverInfoSubject$;

    return this.http
      .post(`${environment.dataAPIUrl}/getFrameAggregatedHoverInfo`, {
        data: {
          id: businessDataId,
          friendlyNames,
          hoverInfoConfig,
          metricsCustomFormattings,
          imagesData
        }
      })
      .pipe(
        take(1),
        takeUntil(canceled$),
        map((response: any) => {
          const data = response.result.data as IHoverInfoField[];
          return data;
        })
      );
  }

  public extractFileRefFromURL(url: string) {
    return url && decodeURIComponent(url).split('/o/')[1]?.split('?')[0];
  }
}
