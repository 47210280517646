<div class="ag-boolean-cell" [class.ag-loading-cell]="isLoading">
  <ng-container *ngIf="isLoading">
    <svg-icon svgClass="icon icon-loading-rows" name="icon-loading-rows"></svg-icon>
    <span>One moment please...</span>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <span *ngIf="!isRowSelected()" class="value">{{ title }}</span>
    <span class="checkbox">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? toggleRow() : null"
        [checked]="isRowSelected()"
      >
      </mat-checkbox>
    </span>
  </ng-container>
</div>
