import { IConnection } from '@app/state/connection/connection.model';

export interface IImageConnection extends IConnection {
  id: string;
  settings: {
    dataAccessType: ImageManagerType;
    fields: IBaseImageManagerConnectionSettings;
  };
}

export interface IBaseImageManagerConnectionSettings {
  host: string;
  port: number;
  timeout: number;
  path: string;
  filter: string;
}

export enum ImageManagerType {
  ColumbiaS7 = 0,
  AdobeExperienceManager = 1,
  Nike = 2,
  // Hoka = 3,
  // Brooks = 4,
  CollagiaImageLibrary = 5
}
