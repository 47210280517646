import { Timestamp } from '@angular/fire/firestore';

export enum ConnectionType {
  Image = 1,
  Business = 2
}

export interface IConnection {
  id: string;
  type: ConnectionType;
  metadata: IConnectionMetadata;
  settings: any; // depend on the ConnectionType
  owner: string;
  lastViewed: Timestamp;
  created: Timestamp;
  favorite: boolean;
}

export interface IConnectionMetadata {
  name: string;
  authorName: string;
}
