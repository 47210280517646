import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { ExploreBarType } from '../../../models/filters.model';
import { ExploreBarBase } from '../explore-bar/explore-bar-base.component';
import { WINDOW } from '../../../shared/utils/window';
import { SetHighlightFilters } from '../../../state/study/study.actions';
import { ResizeEvent } from 'angular-resizable-element';
import { clone } from '../../../shared/utils/object';
import { FilterBoxComponent } from '../filter-box/filter-box.component';
import { CursorStyles } from '../../../shared/enums/cursor-styles.enum';

@Component({
  selector: 'app-highlighter-filter-box',
  templateUrl: './highlighter-filter-box.component.html',
  styleUrls: ['./highlighter-filter-box.component.scss']
})
export class HighlighterFilterBoxComponent extends ExploreBarBase implements OnInit {
  public readonly filterIdPrefix: ExploreBarType = 'highlighting';

  @ViewChild('actionButtonsContainerRef', { static: true })
  private readonly actionButtonsContainerRef: ElementRef;

  constructor(
    protected override store: Store,
    @Inject(WINDOW)
    protected override readonly window: Window,
    protected override readonly cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    super(store, window, cdr);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // bugfix: when filter box opens first time, need to Start already existing filters
    // we refresh Axis here, which triggering to start filterService.preparedHighlightFilters$
    // and as result, products highlighted on the Matrix
    // otherwise, highlights will not be refreshed for first time
    this.setAxis();
  }

  onValidateResize($event: ResizeEvent): boolean {
    const newWidth = $event.rectangle.width;
    const newHeight = $event.rectangle.height;
    const top = this.filterBoxRef.nativeElement.offsetTop;
    const left = this.filterBoxRef.nativeElement.offsetLeft;
    if (
      newWidth > 800 ||
      newWidth < 250 ||
      newHeight > 500 ||
      newHeight < 300 ||
      top + newHeight > this.window.innerHeight - 200 ||
      left + newWidth > this.window.innerWidth - 100
    ) {
      return false;
    }
    return true;
  }

  public override onResizeFilterBox(filterBox: FilterBoxComponent) {
    super.onResizeFilterBox(filterBox);
    if (!filterBox.isResizing) {
      document.body.style.cursor = CursorStyles.Highlighter;
    }
  }

  protected setAxis() {
    let filters = clone(this.highlightFilters || []);
    this.store.dispatch(new SetHighlightFilters(filters));
  }

  onClearAll() {
    this.highlightFilters = [];
    this.setAxis();
  }

  onClose() {
    this.showFilterBox = false;
    this.setAxis();
  }
}
