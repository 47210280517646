import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * Directive to copy any text to the clipboard. Event (copied) generated when user copied text into the Clipboard.
 * Usage:
 * <button [appCopyToClipboard]=" 'text to copy' " (copied)="alert('text has been copied to Clipboard')">
 *   Copy text to Clipboard
 * </button>
 */

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input('appCopyToClipboard')
  public payload: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.payload) {
      return;
    }

    const listener = (e: ClipboardEvent): void => {
      const clipboard = e.clipboardData || (window as any).clipboardData;
      clipboard.setData('text', this.payload.toString());
      e.preventDefault();

      this.copied.emit(this.payload);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }
}
