import { coerceElement } from '@angular/cdk/coercion';
import { DragRef, DropListRef } from '@angular/cdk/drag-drop';

/**
 * CDK Patch for Drag & Drop Nested items
 * Issue: https://github.com/angular/components/issues/16671
 * Short description:
 *   If you launched the application and tried moving the items, you probably noticed, it’s very difficult to use.
 *   The items keep snapping to wrong elements, items get wrapped before getting a chance to put it in a deeper list,
 *   and the problems get more profound the deeper in the nested three you go.
 *   That’s because even though the lists are connected, the CDK work by putting dragged element into the first
 *   acceptable list within the connected list array.
 *   But with the nested list, there can be multiple available lists overlaying the same position.
 *   And the higher-order list it is, the more prioritized it will be because of the position within the
 *   cdkDropListConnectedTo array.
 *
 * Solution:
 *   See @shesse on this github issue: https://github.com/angular/components/issues/16671
 *   Overriding default CDK behaviour, and taking advantage Element.getBoundingClientRect() and DOM depth,
 *   to limit the priority and consideration of viable options.
 *   Include the cdk-patch.ts within your project and add installPatch() within your main.ts.
 *
 * Note (Ivan Ormanzhi, ivan.ormanzhi@gmail.com):
 *   - I have updated patch to solve issues, see comments marked by '###'
 */

export function installCDKPatch() {
  DropListRef.prototype._getSiblingContainerFromPosition = function (
    item: DragRef,
    x: number,
    y: number
  ): DropListRef | undefined {
    // Possible targets include siblings and 'this'
    // @ts-ignore
    const targets = Array.from(new Set([this, ...this._siblings])); // ### remove duplicated targets

    // Only consider targets where the drag postition is within the client rect
    // (this avoids calling enterPredicate on each possible target)
    const matchingTargets = targets.filter(ref => {
      ref._clientRect = ref?.element?.getBoundingClientRect(); // ### the _clientRect can have dynamic DOM structure, so need to refresh it each time
      return ref._clientRect && isInsideClientRect(ref._clientRect, x, y);
    });

    // Stop if no targets match the coordinates
    if (matchingTargets.length === 0) {
      return undefined;
    }

    // Order candidates by DOM hierarchy and z-index
    const orderedMatchingTargets = orderByHierarchy(matchingTargets);

    // The drop target is the last matching target in the list
    const matchingTarget = orderedMatchingTargets[orderedMatchingTargets.length - 1];

    // Only return matching target if it is a sibling
    if (matchingTarget === this) {
      return undefined;
    }

    // Can the matching target receive the item?
    if (!matchingTarget._canReceive(item, x, y) || matchingTarget.disabled) {
      return undefined;
    }

    // Return matching target
    return matchingTarget;
  };
}

// Not possible to improt isInsideClientRect from @angular/cdk/drag-drop/client-rect
function isInsideClientRect(clientRect: ClientRect, x: number, y: number) {
  const { top, bottom, left, right } = clientRect;
  return y >= top && y <= bottom && x >= left && x <= right;
}

// Order a list of DropListRef so that for nested pairs, the outer DropListRef
// is preceding the inner DropListRef. Should probably be ammended to also
// sort by Z-level.
function orderByHierarchy(refs: DropListRef[]) {
  // Build a map from HTMLElement to DropListRef
  const refsByElement: Map<HTMLElement, DropListRef> = new Map();
  refs.forEach(ref => {
    refsByElement.set(coerceElement(ref.element), ref);
  });

  // Function to identify the closest ancestor among th DropListRefs
  const findAncestor = (ref: DropListRef) => {
    let ancestor = coerceElement(ref.element).parentElement;

    while (ancestor) {
      if (refsByElement.has(ancestor)) {
        return refsByElement.get(ancestor);
      }
      ancestor = ancestor.parentElement;
    }

    return undefined;
  };

  // Node type for tree structure
  type NodeType = { ref: DropListRef; parent?: NodeType; children: NodeType[] };

  // Add all refs as nodes to the tree
  const tree: Map<DropListRef, NodeType> = new Map();
  refs.forEach(ref => {
    tree.set(ref, { ref, children: [] });
  });

  // Build parent-child links in tree
  refs.forEach(ref => {
    const parent = findAncestor(ref);

    if (parent) {
      const node = tree.get(ref);
      const parentNode = tree.get(parent);

      node!.parent = parentNode;
      parentNode!.children.push(node!);
    }
  });

  // Find tree roots
  const roots = Array.from(tree.values()).filter(node => !node.parent);

  // Function to recursively build ordered list from roots and down
  const buildOrderedList = (nodes: NodeType[], list: DropListRef[]) => {
    list.push(...nodes.map(node => node.ref));
    nodes.forEach(node => {
      buildOrderedList(node.children, list);
    });
  };

  // Build and return the ordered list
  const ordered: DropListRef[] = [];
  buildOrderedList(roots, ordered);
  return ordered;
}
