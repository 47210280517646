<div class="canvas-wrapper" [@expendTopCompassAnimation]="expanded ? 'expanded' : 'closed'">
  <app-compass-canvas></app-compass-canvas>
</div>
<div class="bottom-bar">
  <div>
    <button
      *ngIf="(matrixType$ | async) && (canAddWorkspace$ | async)"
      class="icon-button add-workspace"
      matRipple
      matTooltip="New workspace"
      matTooltipPosition="above"
      matTooltipClass="above"
      (click)="createWorkspace()"
    >
      <svg-icon svgClass="icon-add-workspace" name="icon-show-add"></svg-icon>
    </button>
    <span class="add-workspace-wrapper">
      <button
        *ngIf="!(matrixType$ | async) || !(canAddWorkspace$ | async)"
        class="icon-button add-workspace"
        [disabled]="!(matrixType$ | async) || !(canAddWorkspace$ | async)"
        [matTooltip]="!(matrixType$ | async) ? '' : 'Limit reached'"
        matTooltipPosition="above"
        matTooltipClass="above"
      >
        <svg-icon svgClass="icon-add-workspace" name="icon-show-add"></svg-icon>
      </button>
    </span>
    <div class="btn-wrapper">
      <button
        [disabled]="isUpdatingWorkspaces || isNoMatrixAvailable || (isUpdatingWorkspaceInStore$ | async)"
        class="icon-button switch-workspace"
        matRipple
        [matTooltip]="!(matrixType$ | async) ? '' : getWorkspaceSelectorBtnTooltip()"
        matTooltipPosition="above"
        matTooltipClass="above"
        (click)="onWorkspaceSelectorButtonClick()"
      >
        <svg-icon svgClass="icon-switch-workspace" name="icon-switch-workspace"></svg-icon>
      </button>
      <div class="need-rebuild" *ngIf="isNeedRebuildMatrices"></div>
    </div>
  </div>

  <div>
    <button
      class="icon-button"
      [matTooltip]="expanded ? 'Hide compass' : 'Show compass'"
      matTooltipPosition="above"
      [matTooltipClass]="['above', 'toggle-compass']"
    >
      <svg-icon
        *ngIf="!expanded"
        (click)="expanded = !expanded"
        svgClass="icon-compass-expand"
        name="icon-compass-expand"
      ></svg-icon>
      <svg-icon
        *ngIf="expanded"
        (click)="expanded = !expanded"
        svgClass="icon-compass-minimize"
        name="icon-compass-minimize"
      ></svg-icon>
    </button>
  </div>
</div>
