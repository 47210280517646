<h2 mat-dialog-title>Send reset password email</h2>
<mat-dialog-content>
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput [formControl]="email" />
    <mat-error *ngIf="email.invalid">Input valid email address</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>CANCEL</button>
  <button mat-flat-button color="accent" (click)="email.valid && sendResetPasswordEmail()">SEND</button>
</mat-dialog-actions>
