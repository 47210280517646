import { UserDataState } from '@app/state/user-data/user-data.state';
import { DataService } from 'src/app/services/data/data.service';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { ResizeEvent } from 'angular-resizable-element';
import { WINDOW } from '../../../shared/utils/window';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LookupTableService } from '@app/components/lookup-table';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { filter, switchMap } from 'rxjs/operators';
import { expendStatsBarAnimation } from '../../animations/animations';
import * as Sentry from '@sentry/angular';
import { SetShowOriginalHeaders } from '@app/state/study/study.actions';
import { IHeaderRowStatItem } from '@app/models/stats.model';
import { MatrixService } from '@app/services/matrix/matrix.service';

@UntilDestroy()
@Component({
  selector: 'app-stats-bar',
  templateUrl: './stats-bar.component.html',
  styleUrls: ['./stats-bar.component.scss'],
  animations: [expendStatsBarAnimation]
})
export class StatsBarComponent implements OnInit {
  @Select(StudyState.isFriendlyNamesNotEmpty) isFriendlyNamesNotEmpty$: Observable<boolean>;
  @Select(StudyState.isShowOriginalHeaders) isShowOriginalHeaders$: Observable<boolean>;
  isShowOriginalHeaders: boolean;

  @Select(UserDataState.getTotalRowsInView) totalRowsInView$: Observable<number>;

  public productCount = this.ds.currentData.length;
  public placeHolderCount;

  public isStatsExpanded = false;
  public isShowColumnList = false;

  public minHeight: number = 260;
  public maxHeight: number = 800;
  public currentHeight: number = 500;
  public tableHeight: number = 400;
  public matrixType: ValidMatrix;

  public productsToShowOnMatrix: Set<string> = new Set();
  public headerRowStats: Array<IHeaderRowStatItem> = [];

  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    this.updateMaxHeight($event.target.innerHeight);
    this.updateCurrentHeight(this.currentHeight);
  }

  constructor(
    @Inject(WINDOW)
    protected readonly window: Window,
    private ds: DataService,
    protected readonly cdr: ChangeDetectorRef,
    public lookupTable: LookupTableService,
    private matrixService: MatrixService,
    private store: Store
  ) {}

  ngOnInit() {
    // Hack for primitive type being used for this
    setTimeout(() => {
      this.placeHolderCount = this.ds.placeHolderCount;
    }, 200);

    this.updateMaxHeight(this.window.innerHeight);
    this.updateCurrentHeight(this.currentHeight);

    this.subscribeImagesOnMatrix();

    this.isShowOriginalHeaders$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isShowOriginalHeaders = value;
    });
  }

  subscribeImagesOnMatrix() {
    this.store
      .select(StudyState.getMatrixType)
      .pipe(
        filter(value => !!value),
        switchMap((matrixType: ValidMatrix) => (matrixType ? this.matrixService.productsToShowSubject : of(new Set()))),
        untilDestroyed(this)
      )
      .subscribe((productsToShowOnMatrix: Set<string>) => {
        this.productsToShowOnMatrix = productsToShowOnMatrix;
        this.updateHeaderRowStats();
      });
  }

  updateHeaderRowStats() {
    if (this.productsToShowOnMatrix.size === 0) {
      this.headerRowStats = [];
      return;
    }

    const additionalStats = this.lookupTable.getStats(this.productsToShowOnMatrix);
    const stats = [];
    stats.push({ label: 'BID in View', value: this.productsToShowOnMatrix.size });
    if (additionalStats) {
      stats.push(
        ...[
          { label: 'Avg Images per BID', value: additionalStats.avg },
          { label: 'BIDs with images', value: `${additionalStats.hasImages}%` }
        ]
      );
    }
    this.headerRowStats = [...stats];
  }

  onStatsClick() {
    this.isStatsExpanded = !this.isStatsExpanded;
    if (this.isShowColumnList && !this.isStatsExpanded) {
      this.toggleColumnList(); // hide column list if stats collapsed
    }
  }

  onResizing($event: ResizeEvent) {
    this.updateCurrentHeight($event.rectangle.height);
  }

  validateResize($event: ResizeEvent): boolean {
    const newHeight = $event.rectangle.height;
    if (newHeight > this.maxHeight || newHeight < this.minHeight) {
      return false;
    }
    return true;
  }

  toggleColumnList(): void {
    this.isShowColumnList = !this.isShowColumnList;
    Sentry.addBreadcrumb({
      level: 'log',
      message: 'toggleColumnList',
      data: { isShowColumnList: this.isShowColumnList }
    });
  }

  private updateMaxHeight(windowHeight: number) {
    this.maxHeight = windowHeight - 200;
  }

  private updateCurrentHeight(newHeight: number) {
    this.currentHeight = Number(newHeight);
    this.currentHeight = Math.min(this.currentHeight, this.maxHeight);
    this.currentHeight = Math.max(this.currentHeight, this.minHeight);
    this.tableHeight = this.currentHeight - 80;
  }

  toggleFriendlyNames() {
    this.isShowOriginalHeaders = !this.isShowOriginalHeaders;
    this.store.dispatch(new SetShowOriginalHeaders(this.isShowOriginalHeaders));
  }
}
