import { animate, AnimationBuilder, AnimationFactory, style } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { carouselCellTitleAnimation, carouselCellImgAnimation } from '@app/components/animations/animations';

export interface ICarouselImage {
  id: string;
  view: string;
  url: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [carouselCellTitleAnimation, carouselCellImgAnimation]
})
export class CarouselComponent implements OnInit, OnChanges {
  @ViewChild('carousel', { static: true })
  private carousel: ElementRef;

  @Input() images: Array<ICarouselImage> = [];
  @Input() displayName: string;
  @Input() cellWidth = 300;
  @Input() cellHeight = 300;
  @Input() cellGap = 10;

  @Output() public viewSelected: EventEmitter<string> = new EventEmitter();

  carouselWidth: number;
  imagesToShow: Array<ICarouselImage> = [];
  numSlidesVisible = 3;
  leftCell = 0;
  centerCell = 2;
  recentCenterCell = 1;

  public get cellStyles() {
    return {
      'width.px': this.cellWidth,
      'height.px': this.cellHeight,
      margin: `0 ${this.cellGap / 2}px`
    };
  }

  constructor(private animationBuilder: AnimationBuilder) {}

  ngOnInit(): void {
    this.updateSlidesToShow();
    this.carouselWidth = this.cellWidth * this.numSlidesVisible + this.cellGap * (this.numSlidesVisible - 1);
    this.transitionCarousel(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images && changes.images.currentValue?.length > 0) {
      if (changes.images.currentValue.length <= 2) {
        this.leftCell = -1;
        this.centerCell = 0;
        this.recentCenterCell = 0;
      }
      this.viewSelected.emit(changes.images.currentValue[this.recentCenterCell].view);
    }
  }

  prev() {
    if (this.imagesToShow.length > 2) {
      if (this.leftCell === 0) {
        this.images.unshift(this.images.pop());
        this.updateSlidesToShow();
        this.leftCell++;
        this.transitionCarousel(false);
      }
      this.leftCell = (this.leftCell - 1 + this.images.length) % this.images.length;
      this.centerCell = this.leftCell + 2;
      this.recentCenterCell = this.centerCell - 1;
    } else if (this.leftCell === 0) {
      this.leftCell = -1;
      this.centerCell = 0;
      this.recentCenterCell = 0;
    }
    this.transitionCarousel();
    this.viewSelected.emit(this.images[this.recentCenterCell].view);
  }

  next() {
    if (this.imagesToShow.length > 2) {
      if (this.leftCell === this.images.length - this.numSlidesVisible) {
        this.images.push(this.images.shift());
        this.updateSlidesToShow();
        this.leftCell--;
        this.transitionCarousel(false);
      }
      this.leftCell = (this.leftCell + 1) % this.images.length;
      this.centerCell = this.leftCell + 2;
      this.recentCenterCell = this.centerCell - 1;
    } else if (this.leftCell === -1) {
      this.leftCell = 0;
      this.centerCell = 1;
      this.recentCenterCell = 1;
    }
    this.transitionCarousel();
    this.viewSelected.emit(this.images[this.recentCenterCell].view);
  }

  private transitionCarousel(animation = true) {
    const offset = -(this.leftCell + 1) * (this.cellWidth + this.cellGap) - this.cellGap / 2;
    const myAnimation: AnimationFactory = this.buildAnimation(offset, animation);
    myAnimation.create(this.carousel.nativeElement).play();
  }

  private buildAnimation(offset: number, animation: boolean = true): AnimationFactory {
    return this.animationBuilder.build([animate(animation ? 200 : 0, style({ left: `${offset}px` }))]);
  }

  private updateSlidesToShow() {
    if (this.images.length > 2) {
      this.imagesToShow = [this.images[this.images.length - 1], ...this.images, this.images[0]];
    } else if (this.images.length > 0) {
      this.imagesToShow = [...this.images];
    } else {
      this.imagesToShow = [];
    }
  }

  getAnimationState(i) {
    return i === this.centerCell ? 'active' : i === this.recentCenterCell ? 'recentlyActive' : 'idle';
  }
}
