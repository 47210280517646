import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LookupTableService } from '@app/components/lookup-table';
import {
  IFramePresentationState,
  IGroupPresentationState,
  IImageInfoPanelPresentationState,
  IImagePresentationState,
  IParetoDataState,
  IPresentationState,
  IRankDataState
} from '@app/models/presentation.models';
import { DockingSide, GroupOrientation, InfoPanelPosition } from '@app/models/study-setting.model';
import { IDimensions, IFrameStateModel, IPosition } from '@app/models/workspace-list.model';
import { PresentationService } from '@app/services/presentation/presentation.service';
import { imageBaseSize } from '@app/shared/constants/viewport';
import { StudyState } from '@app/state/study/study.state';
import { ValidMatrix } from '@app/state/tools/tools.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { getSum } from '@app/utils/image-data.utils';
import { IHoverInfoField } from '@app/models/image.model';
import { MatrixService } from '@app/services/matrix/matrix.service';

@Component({
  selector: 'app-presentation-mode',
  templateUrl: './presentation-mode.component.html',
  styleUrls: ['./presentation-mode.component.scss']
})
export class PresentationModeComponent implements AfterViewInit {
  private readonly slideRatio = {
    width: 16,
    height: 9
  };
  @ViewChild('matrix', { read: ElementRef, static: true })
  public matrixRef: ElementRef<HTMLDivElement>;

  @Select(StudyState.getMatrixType)
  private matrixType$: Observable<ValidMatrix>;

  private state: IPresentationState;
  private dimensions: IDimensions;
  private defaultDimensions: IDimensions;
  private matrixDimensions: IDimensions;
  private offset: IPosition;
  private scale: number;
  private matrixPaddings: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  } = {
    top: 5,
    right: 5,
    bottom: 5,
    left: 5
  };
  private groupQuicStatsSizeByLevel: Array<number> = [];

  constructor(
    public dialogRef: MatDialogRef<PresentationModeComponent, any>,
    private renderer: Renderer2,
    private lookupTable: LookupTableService,
    private store: Store,
    private presentationService: PresentationService,
    private matrixService: MatrixService
  ) {}

  ngAfterViewInit(): void {
    this.dialogRef
      .afterOpened()
      .pipe(
        switchMap(() => this.matrixType$),
        take(1)
      )
      .subscribe(matrixType => {
        this.defaultDimensions = {
          width: this.matrixRef.nativeElement.offsetWidth,
          height: this.matrixRef.nativeElement.offsetHeight
        };
        this.state = this.matrixService.getStateForPresentationMode();
        this.setMatrixPaddings(matrixType, this.state.rankData, this.state.paretoData);
        let { min, max } = this.getMinAndMaxPositions(this.state);

        if (this.state.infoPanel) {
          if (this.state.rankData.labelOrientation === GroupOrientation.Horizontal) {
            if (this.state.infoPanel.position === InfoPanelPosition.Left) {
              min.x -= this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
            }
            if (this.state.infoPanel.position === InfoPanelPosition.Right) {
              max.x += this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
            }
          }
          if (this.state.rankData.labelOrientation === GroupOrientation.Vertical) {
            if (this.state.infoPanel.position === InfoPanelPosition.Bottom) {
              max.y += this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
            }
          }
        }
        this.offset = { ...min };
        this.matrixDimensions = {
          width: max.x - min.x,
          height: max.y - min.y
        };
        if (this.state.paretoData) {
          const { columnSizes, groups, distanceBetweenColumns, padding } = this.state.paretoData;
          const matrixSize = getSum(columnSizes) + distanceBetweenColumns * (groups.length - 1);
          if (this.state.paretoData.groupOrientation === GroupOrientation.Vertical) {
            this.matrixDimensions.width = matrixSize;
            this.offset.x -= padding;
          } else {
            this.matrixDimensions.height = matrixSize;
            this.offset.y -= padding;
          }
        }

        if (this.state.infoPanel) {
          if (this.state.rankData.labelOrientation === GroupOrientation.Vertical) {
            if (
              this.state.rankData.dockingSide === DockingSide.Left &&
              this.state.infoPanel.position === InfoPanelPosition.Left
            ) {
              this.offset.x -= this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
            }
            if (
              this.state.rankData.dockingSide === DockingSide.Right &&
              this.state.infoPanel.position === InfoPanelPosition.Right
            ) {
              this.offset.x += this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
            }
          }
        }
        this.scale = this.getScale(this.dimensions, this.matrixDimensions);
        this.renderGroups(this.state.groupsByLevel, this.state.rankData);
        this.renderFrames(this.state.frames);
        this.renderImages(this.state.images, this.state.infoPanel);
        if (this.state.paretoData) {
          this.renderParetoSubtotal(this.state.paretoData);
        }
      });
  }

  private renderGroups(groupsByLevel: Array<Array<IGroupPresentationState>>, rankData: IRankDataState): void {
    const groupItemsByLevel = new Array(groupsByLevel.length);
    for (let level = groupsByLevel.length - 1; level >= 0; level--) {
      for (const group of groupsByLevel[level]) {
        if (!groupItemsByLevel[level]) groupItemsByLevel[level] = [];
        groupItemsByLevel[level].push(this.renderGroup(group, rankData));
      }
    }
    for (const item of groupItemsByLevel) {
      const scale = this.getMinTextScaleForElements(item, 2);
      this.setElementsSize(item, scale);
    }
  }

  private renderGroup(
    group: IGroupPresentationState,
    rankData: IRankDataState
  ): { state: IGroupPresentationState; element: HTMLDivElement; dimensions: IDimensions } {
    if (
      this.state.infoPanel &&
      this.state.infoPanel.position === InfoPanelPosition.Left &&
      rankData.dockingSide === DockingSide.Left
    ) {
      const infoOnCanvasPadding = this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
      group.position.x -= infoOnCanvasPadding;
    }
    if (
      this.state.infoPanel &&
      this.state.infoPanel.position === InfoPanelPosition.Right &&
      rankData.dockingSide === DockingSide.Right
    ) {
      const infoOnCanvasPadding = this.state.infoPanel.isHalfSize ? imageBaseSize : imageBaseSize * 2;
      group.dimensions.width += infoOnCanvasPadding;
    }
    const { position, dimensions } = this.getGroupLabelPositionAndDimensions(group, rankData);
    const element = this.renderer.createElement('div');
    this.renderer.setStyle(element, 'left', `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(element, 'top', `${this.pxToPercents(position.y, this.defaultDimensions.height)}%`);

    if (rankData.labelOrientation === GroupOrientation.Vertical)
      this.renderer.setStyle(element, 'writing-mode', 'vertical-rl');
    this.renderer.addClass(element, 'group-label');
    const labelText = this.renderer.createElement('span');
    this.renderer.setProperty(labelText, 'innerText', group.label.value);
    this.renderer.appendChild(element, labelText);
    this.renderer.appendChild(this.matrixRef.nativeElement, element);
    if (group.quickStats) {
      this.renderGroupQuickStats(group, rankData, position, dimensions);
    }
    return {
      state: group,
      element,
      dimensions:
        rankData.labelOrientation === GroupOrientation.Horizontal
          ? {
              width: dimensions.width,
              height: 30
            }
          : {
              width: 30,
              height: dimensions.height
            }
    };
  }

  private getGroupLabelPositionAndDimensions(
    group: IGroupPresentationState,
    rankData: IRankDataState
  ): { position: IPosition; dimensions: IDimensions } {
    const { position, dimensions } = this.getElementPositionAndDimensions(group.position, group.dimensions);
    return {
      position: {
        x:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? position.x
            : this.getGroupLabelXForVertical(group.level, rankData, position.x, dimensions.width),
        y:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? this.getGroupLabelYForHorizontal(group.level, rankData, position.y, dimensions.height)
            : position.y
      },
      dimensions: {
        width: rankData.labelOrientation === GroupOrientation.Horizontal ? dimensions.width : 30,
        height: rankData.labelOrientation === GroupOrientation.Horizontal ? 30 : dimensions.height
      }
    };
  }

  private getGroupLabelXForVertical(level: number, rankData: IRankDataState, positionX: number, width: number): number {
    const levelOnMatrix = rankData.maxLevel - level;
    const quickStatsPadding = this.groupQuicStatsSizeByLevel
      .slice(level + 1, rankData.maxLevel)
      .reduce((acc, curr) => acc + curr, 0);
    return rankData.dockingSide === DockingSide.Top || rankData.dockingSide === DockingSide.Left
      ? positionX - 35 * levelOnMatrix - quickStatsPadding
      : positionX + width + 30 * (levelOnMatrix - 1) + 5 * levelOnMatrix + quickStatsPadding;
  }

  private getGroupLabelYForHorizontal(
    level: number,
    rankData: IRankDataState,
    positionY: number,
    height: number
  ): number {
    const levelOnMatrix = rankData.maxLevel - level;
    const quickStatsPadding = this.groupQuicStatsSizeByLevel
      .slice(level + 1, rankData.maxLevel)
      .reduce((acc, curr) => acc + curr, 0);
    return rankData.dockingSide === DockingSide.Top || rankData.dockingSide === DockingSide.Left
      ? positionY - 35 * levelOnMatrix - quickStatsPadding
      : positionY + height + 30 * (levelOnMatrix - 1) + 5 * levelOnMatrix + quickStatsPadding;
  }

  renderGroupQuickStats(
    group: IGroupPresentationState,
    rankData: IRankDataState,
    labelPosition: IPosition,
    labelDimensions: IDimensions
  ): void {
    const { position, dimensions } = this.getGroupQuickStatsPositionAndDimensions(
      rankData,
      labelPosition,
      labelDimensions
    );
    const element = this.renderer.createElement('div');
    this.renderer.setStyle(element, 'left', `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(element, 'top', `${this.pxToPercents(position.y, this.defaultDimensions.height)}%`);
    this.renderer.addClass(element, 'group-quick-stats');
    const textElement = this.getQuickStatsText(group.quickStats.info);

    const clone = element.cloneNode();
    this.renderer.appendChild(clone, textElement);

    this.renderer.setStyle(element, 'width', `${this.pxToPercents(dimensions.width, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(
      element,
      'height',
      `${this.pxToPercents(dimensions.height, this.defaultDimensions.height)}%`
    );
    this.renderer.appendChild(this.matrixRef.nativeElement, element);
    this.renderer.appendChild(this.matrixRef.nativeElement, clone);

    const widthScale = element.offsetWidth / clone.offsetWidth;
    const heightScale = element.offsetHeight / clone.offsetHeight;
    const scale = Math.min(widthScale, heightScale, 1);
    this.renderer.appendChild(element, textElement);
    group.quickStats.fontSize *= scale;
    this.renderer.setStyle(element, 'font-size', `${group.quickStats.fontSize}px`);
    this.renderer.setStyle(element, 'line-height', `${14 * scale}px`);
    const fieldNames = element.querySelectorAll('.field-name') as NodeListOf<HTMLParagraphElement>;
    fieldNames.forEach((item, key) => {
      if (key !== 0) this.renderer.setStyle(item, 'margin-top', `${10 * scale}px`);
    });
    this.renderer.removeChild(this.matrixRef.nativeElement, clone);
  }

  private getGroupQuickStatsPositionAndDimensions(
    rankData: IRankDataState,
    labelPosition: IPosition,
    labelDimensions: IDimensions
  ): {
    position: IPosition;
    dimensions: IDimensions;
  } {
    return {
      position: {
        x:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? labelPosition.x
            : this.getGroupQuickStatsXForVertical(rankData.dockingSide, labelPosition.x),
        y:
          rankData.labelOrientation === GroupOrientation.Horizontal
            ? this.getGroupQuickStatsYForHorizontal(rankData.dockingSide, labelPosition.y)
            : labelPosition.y
      },
      dimensions: {
        width: rankData.labelOrientation === GroupOrientation.Horizontal ? labelDimensions.width : 60,
        height: rankData.labelOrientation === GroupOrientation.Horizontal ? 60 : labelDimensions.height
      }
    };
  }

  private getGroupQuickStatsXForVertical(dockingSide: DockingSide, positionX: number): number {
    return dockingSide === DockingSide.Left ? positionX - 60 : positionX + 30;
  }

  private getGroupQuickStatsYForHorizontal(dockingSide: DockingSide, positionXOrY: number): number {
    return dockingSide === DockingSide.Top ? positionXOrY - 60 : positionXOrY + 30;
  }

  private getQuickStatsText(hoverInfo: Array<IHoverInfoField>): HTMLSpanElement {
    const element = this.renderer.createElement('span');
    if (hoverInfo === null) {
      this.addQuickStatsRow(element, 'calculating-message', 'Calculating...');
    } else if (hoverInfo.length === 0) {
      this.addQuickStatsRow(element, 'calculating-message', 'Add Statistics');
    } else {
      hoverInfo?.forEach(info => {
        this.addQuickStatsRow(element, 'field-name', info.fieldName);
        info.fieldValues.forEach(val => {
          this.addQuickStatsRow(element, 'field-value', val);
        });
      });
    }
    return element;
  }

  private addQuickStatsRow(parent: HTMLSpanElement, elementClass: string, text: string): void {
    const element = this.renderer.createElement('p');
    this.renderer.addClass(element, elementClass);
    const textElement = this.renderer.createText(text);
    this.renderer.appendChild(element, textElement);
    this.renderer.appendChild(parent, element);
  }

  private renderFrames(frames: Array<IFramePresentationState>): void {
    for (const frame of frames) {
      this.renderFrame(frame);
    }
  }

  private renderFrame(frame: IFramePresentationState): void {
    const frameElement = this.createElement(frame);
    this.renderer.addClass(frameElement, 'frame-element');
    this.renderFrameTitle(frame);
    this.renderFrames(frame.frames);
    this.renderImages(frame.images);
    this.renderer.appendChild(this.matrixRef.nativeElement, frameElement);
  }

  private renderFrameTitle(frame: IFramePresentationState): void {
    const { dimensions, position } = this.getElementPositionAndDimensions(frame.position, frame.dimensions);
    const frameTitleElement = this.renderer.createElement('div');
    const frameTitleText = `${frame.title.featureName}: ${frame.title.featureValue || 'undefined'} | ${
      frame.numImages
    }`;
    const fontSize = Math.max(this.getFontSize(frame, dimensions.width, frameTitleText), 1);
    this.renderer.setStyle(
      frameTitleElement,
      'top',
      `${this.pxToPercents(position.y - fontSize * 1.5, this.defaultDimensions.height)}%`
    );
    this.renderer.setStyle(
      frameTitleElement,
      'left',
      `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`
    );
    this.renderer.setStyle(
      frameTitleElement,
      'width',
      `${this.pxToPercents(dimensions.width, this.defaultDimensions.width)}%`
    );
    this.renderer.addClass(frameTitleElement, 'frame-title');
    this.renderer.setProperty(frameTitleElement, 'innerText', frameTitleText);
    this.renderer.setStyle(frameTitleElement, 'font-size', `${fontSize}px`);
    this.renderer.appendChild(this.matrixRef.nativeElement, frameTitleElement);
  }

  private getFontSize<T extends { fontSize: number }>(
    item: T,
    widthOrHeight: number,
    text: string,
    padding: number = 0
  ): number {
    const textSpan: HTMLSpanElement = this.renderer.createElement('span');
    this.renderer.setProperty(textSpan, 'innerText', text);
    let { fontSize } = item;
    this.renderer.setStyle(textSpan, 'display', 'inline');
    this.renderer.setStyle(textSpan, 'position', 'absolute');
    this.renderer.setStyle(textSpan, 'white-space', 'nowrap');
    this.renderer.setStyle(textSpan, 'font-size', `${fontSize}px`);
    this.renderer.appendChild(this.matrixRef.nativeElement, textSpan);
    if (widthOrHeight < textSpan.offsetWidth + padding) {
      fontSize = Math.trunc((fontSize * widthOrHeight) / (textSpan.offsetWidth + padding));
    }
    item.fontSize = fontSize / this.scale;
    this.renderer.removeChild(this.matrixRef.nativeElement, textSpan);
    return fontSize;
  }

  private renderImages(images: Array<IImagePresentationState>, infoPanel?: IImageInfoPanelPresentationState) {
    for (const image of images) {
      this.renderImage(image);
    }
    if (infoPanel) this.renderInfoPanels(images, infoPanel);
  }

  private renderImage(image: IImagePresentationState) {
    const { position, dimensions } = this.getElementPositionAndDimensions(image.position, image.dimensions);
    const imageElement = this.renderer.createElement('div');
    this.renderer.setStyle(imageElement, 'top', `${this.pxToPercents(position.y, this.defaultDimensions.height)}%`);
    this.renderer.setStyle(imageElement, 'left', `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(
      imageElement,
      'width',
      `${this.pxToPercents(dimensions.width, this.defaultDimensions.width)}%`
    );
    this.renderer.setStyle(
      imageElement,
      'height',
      `${this.pxToPercents(dimensions.height, this.defaultDimensions.height)}%`
    );
    this.renderer.setStyle(
      imageElement,
      'background-image',
      `url("${this.lookupTable.getProductImage(image.id, image.view, 1024) || this.lookupTable.getPlaceholderUrl()}")`
    );
    this.renderer.addClass(imageElement, 'image-element');
    this.renderer.appendChild(this.matrixRef.nativeElement, imageElement);
  }

  private renderInfoPanels(images: Array<IImagePresentationState>, infoPanel: IImageInfoPanelPresentationState) {
    const infoPanels = [];
    if (infoPanel) {
      for (const image of images) {
        infoPanels.push(this.renderInfoPanel(image, infoPanel));
      }
      const scale = this.getMinTextScaleForElements(infoPanels);
      this.setElementsSize(infoPanels, scale, true);
    }
  }

  private getMinTextScaleForElements(
    items: Array<{
      element: HTMLElement;
      dimensions: IDimensions;
    }>,
    padding: number = 0
  ): number {
    return Math.min(
      ...items.map(({ element, dimensions }) => this.getTextScaleForElement(element, dimensions, padding))
    );
  }

  private getTextScaleForElement(element: HTMLElement, dimensions: IDimensions, padding: number): number {
    const widthScale = (dimensions.width - padding) / element.offsetWidth;
    const heightScale = (dimensions.height - padding) / element.offsetHeight;
    return Math.min(widthScale, heightScale, 1);
  }

  private setElementsSize<T extends { fontSize: number }>(
    items: Array<{ state: T; element: HTMLElement; dimensions: IDimensions }>,
    scale: number,
    once: boolean = false
  ): void {
    let textScale = null;
    for (const { element, dimensions, state } of items) {
      this.renderer.setStyle(element, 'width', `${this.pxToPercents(dimensions.width, this.defaultDimensions.width)}%`);
      this.renderer.setStyle(
        element,
        'height',
        `${this.pxToPercents(dimensions.height, this.defaultDimensions.height)}%`
      );
      if (!once || textScale === null) {
        textScale = scale;
        state.fontSize *= textScale;
      }
      this.renderer.setStyle(element, 'font-size', `${state.fontSize}px`);
    }
  }

  private renderInfoPanel(
    image: IImagePresentationState,
    infoPanel: IImageInfoPanelPresentationState
  ): { state: IImageInfoPanelPresentationState; element: HTMLDivElement; dimensions: IDimensions } {
    const infoPanelDimensions = {
      width: infoPanel.isHalfSize ? image.dimensions.width : image.dimensions.width * 2,
      height: image.dimensions.height
    };
    const infoPanelPosition = this.getInfoPanelCoordinates(
      infoPanel.position,
      image.position,
      infoPanelDimensions,
      infoPanel.isHalfSize
    );
    const { position, dimensions } = this.getElementPositionAndDimensions(infoPanelPosition, infoPanelDimensions);
    const infoPanelElement = this.renderer.createElement('div');
    this.renderer.setStyle(infoPanelElement, 'top', `${this.pxToPercents(position.y, this.defaultDimensions.height)}%`);
    this.renderer.setStyle(infoPanelElement, 'left', `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`);
    this.renderer.addClass(infoPanelElement, 'info-panel');
    if (infoPanel.position !== InfoPanelPosition.Left) this.renderer.addClass(infoPanelElement, 'right-side');

    const infoPanelTitle = this.renderer.createElement('p');
    this.renderer.setProperty(infoPanelTitle, 'innerText', image.data.displayName);
    this.renderer.addClass(infoPanelTitle, 'title');
    this.renderer.appendChild(infoPanelElement, infoPanelTitle);

    for (const { fieldName, fieldValues } of image.data.hoverInfo.slice(0, 2)) {
      const fieldElement = this.renderer.createElement('div');
      this.renderer.addClass(fieldElement, 'field');
      const fieldNameElement = this.renderer.createElement('p');
      this.renderer.addClass(fieldNameElement, 'field-name');
      this.renderer.setProperty(fieldNameElement, 'innerText', fieldName);
      this.renderer.appendChild(fieldElement, fieldNameElement);
      for (const value of fieldValues) {
        const fieldValueElement = this.renderer.createElement('p');
        this.renderer.addClass(fieldValueElement, 'field-value');
        this.renderer.setProperty(fieldValueElement, 'innerText', value);
        this.renderer.appendChild(fieldElement, fieldValueElement);
      }
      this.renderer.appendChild(infoPanelElement, fieldElement);
    }

    this.renderer.appendChild(this.matrixRef.nativeElement, infoPanelElement);
    return { state: infoPanel, element: infoPanelElement, dimensions };
  }

  private getInfoPanelCoordinates(
    position: InfoPanelPosition,
    imagePos: IPosition,
    infoPanelDimensions: IDimensions,
    isHalfSize: boolean
  ): IPosition {
    const coordinates = {
      x: 0,
      y: 0
    };

    switch (position) {
      case InfoPanelPosition.Left:
        coordinates.x = imagePos.x - infoPanelDimensions.width - 5;
        coordinates.y = imagePos.y;
        break;
      case InfoPanelPosition.Right:
        coordinates.x = imagePos.x + (isHalfSize ? infoPanelDimensions.width : infoPanelDimensions.width / 2) + 5;
        coordinates.y = imagePos.y;
        break;
      case InfoPanelPosition.Bottom:
        coordinates.x = imagePos.x;
        coordinates.y = imagePos.y + (isHalfSize ? infoPanelDimensions.width : infoPanelDimensions.width / 2) + 5;
        break;
    }

    return coordinates;
  }

  private createElement<T extends { position: IPosition; dimensions: IDimensions }>(item: T): HTMLDivElement {
    const { position, dimensions } = this.getElementPositionAndDimensions(item.position, item.dimensions);
    const element: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.setStyle(element, 'top', `${this.pxToPercents(position.y, this.defaultDimensions.height)}%`);
    this.renderer.setStyle(element, 'left', `${this.pxToPercents(position.x, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(element, 'width', `${this.pxToPercents(dimensions.width, this.defaultDimensions.width)}%`);
    this.renderer.setStyle(
      element,
      'height',
      `${this.pxToPercents(dimensions.height, this.defaultDimensions.height)}%`
    );
    return element;
  }

  private renderParetoSubtotal(paretoData: IParetoDataState): void {
    const paretoSubtotals = [];
    let { x, y } = this.getParetoSubtotalPosition(paretoData);
    for (let i = 0; i < paretoData.groups.length; i++) {
      const group = paretoData.groups[i];
      const { width, height } = this.getParetoSubtotalDimensions(paretoData, i);
      const text = `${group.products.length} (${group.subtotal.toFixed(1)}%)`;
      const subtotalItemElement = this.renderer.createElement('div');
      this.renderer.setStyle(subtotalItemElement, 'top', `${this.pxToPercents(y, this.defaultDimensions.height)}%`);
      this.renderer.setStyle(subtotalItemElement, 'left', `${this.pxToPercents(x, this.defaultDimensions.width)}%`);
      if (!paretoData.detailsBarFixedPosition && paretoData.groupOrientation === GroupOrientation.Horizontal)
        this.renderer.setStyle(subtotalItemElement, 'writing-mode', 'vertical-rl');
      this.renderer.setProperty(subtotalItemElement, 'innerText', text);
      this.renderer.addClass(subtotalItemElement, 'pareto-subtotal');
      this.renderer.appendChild(this.matrixRef.nativeElement, subtotalItemElement);
      paretoSubtotals.push({
        state: paretoData,
        element: subtotalItemElement,
        dimensions: {
          width,
          height
        }
      });
      if (!paretoData.detailsBarFixedPosition) {
        if (paretoData.groupOrientation === GroupOrientation.Vertical) {
          x += width + paretoData.distanceBetweenColumns * this.scale;
        } else {
          y += height + paretoData.distanceBetweenColumns * this.scale;
        }
      } else {
        y += 24;
      }
    }
    const scale = this.getMinTextScaleForElements(paretoSubtotals);
    this.setElementsSize(paretoSubtotals, scale, true);
  }

  private getParetoSubtotalPosition(paretoData: IParetoDataState): IPosition {
    if (paretoData.detailsBarFixedPosition) {
      return {
        x: 5 + (this.dimensions.width - this.matrixDimensions.width * this.scale) / 2,
        y: (this.dimensions.height - (19 * paretoData.groups.length + 5 * (paretoData.groups.length - 1))) / 2
      };
    }
    return paretoData.groupOrientation === GroupOrientation.Vertical
      ? {
          y:
            this.matrixDimensions.height * this.scale +
            this.matrixPaddings.top +
            (this.dimensions.height - this.matrixDimensions.height * this.scale) / 2 +
            17,
          x: this.matrixPaddings.left + (this.dimensions.width - this.matrixDimensions.width * this.scale) / 2
        }
      : {
          x: (this.dimensions.width - this.matrixDimensions.width * this.scale) / 2,
          y: this.matrixPaddings.top + (this.dimensions.height - this.matrixDimensions.height * this.scale) / 2
        };
  }

  private getParetoSubtotalDimensions(paretoData: IParetoDataState, columnIndex: number): IDimensions {
    if (paretoData.detailsBarFixedPosition || paretoData.groupOrientation === GroupOrientation.Vertical) {
      return {
        width: !paretoData.detailsBarFixedPosition ? paretoData.columnSizes[columnIndex] * this.scale : 90,
        height: 19
      };
    }
    return {
      width: 19,
      height: paretoData.columnSizes[columnIndex] * this.scale
    };
  }

  private getElementPositionAndDimensions(
    position: IPosition,
    dimensions: IDimensions
  ): {
    position: IPosition;
    dimensions: IDimensions;
  } {
    return {
      position: this.getElementPosition(position),
      dimensions: this.getElementDimensions(dimensions)
    };
  }

  private getElementPosition(position: IPosition): IPosition {
    return {
      x:
        (position.x - this.offset.x) * this.scale +
        this.matrixPaddings.left +
        (this.dimensions.width - this.matrixDimensions.width * this.scale) / 2,
      y:
        (position.y - this.offset.y) * this.scale +
        this.matrixPaddings.top +
        (this.dimensions.height - this.matrixDimensions.height * this.scale) / 2
    };
  }

  private getElementDimensions(dimensions: IDimensions): IDimensions {
    return {
      width: dimensions.width * this.scale,
      height: dimensions.height * this.scale
    };
  }

  private getMinAndMaxPositions({ images, frames, groupsByLevel }: IPresentationState): {
    min: IPosition;
    max: IPosition;
  } {
    const groups = groupsByLevel.reduce((acc, curr) => [...acc, ...curr], []);
    const allContainers = [...images, ...frames, ...groups];
    const res = {
      min: {
        x: null,
        y: null
      },
      max: {
        x: null,
        y: null
      }
    };
    for (const { position, dimensions } of allContainers) {
      if (res.min.x == null || res.min.x > position.x) {
        res.min.x = position.x;
      }
      if (res.min.y == null || res.min.y > position.y) {
        res.min.y = position.y;
      }
      if (res.max.x == null || res.max.x < position.x + dimensions.width) {
        res.max.x = position.x + dimensions.width;
      }
      if (res.max.y == null || res.max.y < position.y + dimensions.height) {
        res.max.y = position.y + dimensions.height;
      }
    }
    return res;
  }

  private getScale<T extends { width: number; height: number }>(parent: T, child: T): number {
    const scaleX = parent.width / child.width;
    const scaleY = parent.height / child.height;
    return Math.min(scaleX, scaleY);
  }

  private getAllImagesInFrame(frame: IFrameStateModel) {
    const images = [...frame.images];
    frame.frames.forEach(item => {
      images.push(...this.getAllImagesInFrame(item));
    });
    return images;
  }

  private setMatrixPaddings(matrixType: ValidMatrix, rankData: IRankDataState, paretoData: IParetoDataState): void {
    switch (matrixType) {
      case ValidMatrix.Freestyle:
      case ValidMatrix.GroupBy:
        this.matrixPaddings = {
          top: 30,
          right: 5,
          bottom: 5,
          left: 5
        };
        break;
      case ValidMatrix.Rank:
        this.matrixPaddings = {
          top: 5,
          right: 5,
          bottom: 5,
          left: 5
        };
        this.matrixPaddings[rankData.dockingSide] =
          30 * rankData.maxLevel + 5 * (rankData.maxLevel + 1) + this.getQuickStatsPadding();
        break;
      case ValidMatrix.Pareto:
        this.matrixPaddings = {
          top: 5,
          right: 5,
          bottom: 5,
          left: 5
        };
        if (!paretoData.detailsBarFixedPosition) {
          if (paretoData.groupOrientation === GroupOrientation.Horizontal) {
            this.matrixPaddings.left = 50;
          } else {
            this.matrixPaddings.bottom = 50;
          }
        } else {
          this.matrixPaddings.left = 100;
        }
        break;
    }
    this.dimensions = {
      width: this.defaultDimensions.width - this.matrixPaddings.left - this.matrixPaddings.right,
      height: this.defaultDimensions.height - this.matrixPaddings.top - this.matrixPaddings.bottom
    };
  }

  getQuickStatsPadding(): number {
    let allQuickStatsSize = 0;
    for (const groupsWithSameLevel of this.state.groupsByLevel) {
      if (groupsWithSameLevel.every(group => !!group.quickStats)) {
        this.groupQuicStatsSizeByLevel.push(60);
        allQuickStatsSize += 60;
      } else {
        this.groupQuicStatsSizeByLevel.push(0);
      }
    }
    return allQuickStatsSize;
  }

  public exportMatrixAsPptx() {
    this.presentationService.exportMatrixAsPptx(
      this.state,
      this.matrixDimensions,
      this.offset,
      this.matrixPaddings,
      this.groupQuicStatsSizeByLevel
    );
  }

  private pxToPercents(axisOrDimension: number, slideDimension: number) {
    return (axisOrDimension * 100) / slideDimension;
  }
}
