import { Pipe, PipeTransform } from '@angular/core';
import { FriendlyNameService } from '../services/friendly-name/friendly-name.service';

@Pipe({
  name: 'friendlyName',
  pure: false
})
export class FriendlyNamePipe implements PipeTransform {
  constructor(private service: FriendlyNameService) {}

  transform(value: string): unknown {
    return this.service.getFriendlyName(value);
  }
}
