<div class="csv-settings" *ngIf="csvSettings">
  <span>
    Delimiter: <b>{{ csvSettings.delimiter }}</b>
  </span>
  <span>
    Line break: <b>{{ lineBreak }}</b>
  </span>
  <span>
    Cols: <b>{{ csvHeaders?.length || 0 }}</b>
  </span>
  <span>
    Rows: <b>{{ csvData?.length || 0 }}</b>
  </span>
</div>

<div class="table-wrapper">
  <cdk-virtual-scroll-viewport
    tvsItemSize="48"
    headerHeight="56"
    [style.height]="containerHeight + 'px'"
    class="wrapper mat-elevation-z2 scrollable"
  >
    <table mat-table [dataSource]="dataSource" [hidden]="rows.length === 0">
      <!-- Stuck Header -->
      <tr mat-header-row *matHeaderRowDef="displayedColumnDefs; sticky: true"></tr>

      <!-- Columns -->
      <ng-container matColumnDef="{{ header.column }}" *ngFor="let header of displayedColumns">
        <th mat-header-cell *matHeaderCellDef>
          <span [class.unique]="header.isAllUnique">{{ header.title }}</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element[header.dataIndex] }}</td>
      </ng-container>

      <!-- Rows -->
      <tr mat-row *matRowDef="let element; columns: displayedColumnDefs"></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
